import React from 'react';
import useSWR from 'swr';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { EOrderBumpType } from '@domain/enums/common/orderBump/EOrderBumpType';
import { IVariant } from '@domain/interfaces/common/variant/IVariant';
import { IUpdateOrderBumpProvider } from '@domain/interfaces/store/common/orderBump/IUpdateOrderBumpProvider';
import { ISwr } from '@domain/interfaces/common/swr/ISwr';
import {
  IOrderBump,
  IOrderBumpOfferProduct,
  IOrderBumpTriggerProduct,
} from '@domain/interfaces/common/orderBump/IOrderBump';

import { orderBumpSchema } from '@helpers/validators/pages/dashboard/orderBump/orderBumpSchema';

import { useErrorHandler } from '@store/context/common/ErrorHandlerContext';
import { useOrderBumpService } from '@hooks/services/orderBump/useOrderBumpService';
import { useAnalytics } from '@store/context/common/AnalyticsContext';

import { dashboardInstance } from '@services/common/dashboardInstance';

import { DASHBOARD_API_URL } from '@constants/services/apiUrl';
import { numberFormatter } from '@helpers/utils/common/numberFormatter';

const UpdateOrderBumpContext = React.createContext<IUpdateOrderBumpProvider | null>(null);

export const UpdateOrderBumpProvider: React.FC = ({ children }) => {
  const navigate = useNavigate();
  const { analytics } = useAnalytics();
  const { handleError } = useErrorHandler();
  const { updateOrderBump } = useOrderBumpService();
  const { accountId, checkoutId, orderBumpId } = useParams();

  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
    setValue,
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(orderBumpSchema),
  });

  const nameRegister = register('name');
  const priceRegister = register('price');
  const promotionalPriceRegister = register('promotional_price');
  const ctaTextRegister = register('cta_text');
  const titleRegister = register('title');
  const descriptionRegister = register('description');

  dashboardInstance.defaults.headers.common['x-zouti-account'] = accountId;

  const ORDER_BUMP_URL = `${DASHBOARD_API_URL}/accounts/${accountId}/checkouts/${checkoutId}/orderbumps/${orderBumpId}`;

  const {
    data: orderBumpData,
    isLoading: isLoadingOrderBump,
    isValidating: isValidatingOrderBump,
    error: orderBumpError,
  } = useSWR<ISwr>(ORDER_BUMP_URL, dashboardInstance);

  const [isUpdatingOrderBump, setIsUpdatingOrderBump] = React.useState<boolean>(false);
  const [selectedOfferProduct, setSelectedOfferProduct] = React.useState<
    IOrderBumpOfferProduct | undefined
  >(undefined);
  const [selectedTriggerProduct, setSelectedTriggerProduct] = React.useState<
    IOrderBumpTriggerProduct | undefined
  >(undefined);
  const [selectedVariant, setSelectedVariant] = React.useState<IVariant | undefined>(undefined);
  const [selectedPrimaryColor, setSelectedPrimaryColor] = React.useState<string | undefined>(
    undefined,
  );
  const [selectedTextColor, setSelectedTextColor] = React.useState<string | undefined>(undefined);
  const [hasSelectedAllVariants, setHasSelectedAllVariants] = React.useState<boolean>(false);
  const [orderBump, setOrderBump] = React.useState<IOrderBump | undefined>(undefined);

  const handleSelectedOfferProduct = React.useCallback(
    offerProduct => setSelectedOfferProduct(offerProduct),
    [],
  );

  const handleSelectedTriggerProduct = React.useCallback(
    triggerProduct => setSelectedTriggerProduct(triggerProduct),
    [],
  );

  const handleSelectedVariant = React.useCallback(variant => {
    if (variant === 'all') {
      setHasSelectedAllVariants(true);
      setSelectedVariant(undefined);
    } else {
      setHasSelectedAllVariants(false);
      setSelectedVariant(variant);
    }
  }, []);

  const handleSelectedPrimaryColor = React.useCallback(
    primaryColor => setSelectedPrimaryColor(primaryColor),
    [],
  );

  const handleSelectedTextColor = React.useCallback(
    textColor => setSelectedTextColor(textColor),
    [],
  );

  const updateCurrentOrderBump = React.useCallback(
    async data => {
      setIsUpdatingOrderBump(true);

      try {
        await updateOrderBump({ payload: data, accountId, checkoutId, orderBumpId });

        analytics.track('Order Bump Updated');

        setIsUpdatingOrderBump(false);

        navigate(`/${accountId}/${checkoutId}/dashboard/marketing/order-bumps`);
      } catch (responseError: any) {
        setIsUpdatingOrderBump(false);
        handleError(responseError);
      }
    },
    [updateOrderBump, handleError, navigate, accountId, checkoutId, orderBumpId, analytics],
  );

  React.useEffect(() => {
    if (orderBumpError) {
      handleError(orderBumpError);
      return;
    }

    if (orderBumpData?.data.object) {
      setOrderBump(orderBumpData.data.object);
    }
  }, [orderBumpError, handleError, orderBumpData]);

  React.useEffect(() => {
    if (orderBump) {
      setValue('name', orderBump.name);
      setValue('price', numberFormatter(orderBump.price / 100, 2));
      setValue('promotional_price', numberFormatter(orderBump.promotional_price / 100, 2));
      setValue('cta_text', orderBump.cta_text);
      setValue('title', orderBump.title);
      setValue('description', orderBump.description);
      setSelectedPrimaryColor(orderBump.primary_hex_color);
      setSelectedTextColor(orderBump.text_hex_color);
      setSelectedTriggerProduct(orderBump.trigger_product);
      setSelectedOfferProduct(orderBump.offer_product);

      if (orderBump.type === EOrderBumpType.ALL_VARIANTS) {
        setHasSelectedAllVariants(true);
      } else {
        setSelectedVariant(orderBump.offer_product.variants?.[0]);
      }
    }
  }, [orderBump, setValue]);

  const isLoading = isLoadingOrderBump || isValidatingOrderBump;

  return (
    <UpdateOrderBumpContext.Provider
      value={{
        updateCurrentOrderBump,
        handleSelectedOfferProduct,
        handleSelectedTriggerProduct,
        handleSelectedVariant,
        selectedOfferProduct,
        selectedTriggerProduct,
        selectedVariant,
        ctaTextRegister,
        descriptionRegister,
        handleSubmit,
        nameRegister,
        priceRegister,
        promotionalPriceRegister,
        titleRegister,
        watch,
        handleSelectedPrimaryColor,
        handleSelectedTextColor,
        selectedPrimaryColor,
        selectedTextColor,
        hasSelectedAllVariants,
        errors,
        isUpdatingOrderBump,
        orderBump,
        isLoading,
      }}
    >
      {children}
    </UpdateOrderBumpContext.Provider>
  );
};

export const useUpdateOrderBump = (): IUpdateOrderBumpProvider => {
  const context = React.useContext(UpdateOrderBumpContext);

  if (!context) {
    throw new Error('useUpdateOrderBump must be used within provider');
  }

  return context;
};
