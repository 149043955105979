import styled from 'styled-components/macro';
import { ArrowUpRight, CheckCircle, Circle, Rocket } from 'phosphor-react';

import HeadingBase from '@components/common/DataDisplay/Heading';
import ImageBase from '@components/common/DataDisplay/Image';
import LinkBase from '@components/common/Navigation/Link';
import TextBase from '@components/common/DataDisplay/Text';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Banner = styled.img`
  width: 100%;
  max-height: 200px;
  border-radius: 8px;
`;

export const WrapperBanner = styled.div`
  position: relative;
`;
export const WrapperBannerContent = styled.div`
  position: absolute;
  width: 58%;
  top: 13%;
  left: 5%;
`;

export const WrapperBannerText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 18px 0;
`;

export const LogoProfitfy = styled.img``;

export const BannerTitle = styled(HeadingBase)`
  color: ${({ theme }) => theme.colors.neutral.white};
`;

export const BannerDescription = styled(TextBase)`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.neutral.white};
`;

export const BannerLink = styled(LinkBase)`
  font-weight: 400;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.positive.base};
  text-decoration: none !important;
`;

export const WrapperBannerLinkAndIcon = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const ArrowUpRightIconBanner = styled(ArrowUpRight).attrs({
  weight: 'bold',
})`
  min-width: 14px;
  min-height: 14px;
  color: ${({ theme }) => theme.colors.positive.base};
`;

export const StepCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  background-color: ${({ theme }) => theme.colors.neutral.white};
`;

export const HeaderStepCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  padding-bottom: 16px;
`;

export const HeaderTitle = styled(HeadingBase)`
  font-weight: 600;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.primary.lemon_20};
`;

export const RocketIcon = styled(Rocket)`
  color: ${({ theme }) => theme.colors.primary.dark_green_40};
`;

export const WrapperHeaderIconAndTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
`;

export const CheckCircleIcon = styled(CheckCircle).attrs({
  weight: 'fill',
})`
  min-width: 24px;
  min-height: 24px;
  color: ${({ theme }) => theme.colors.positive.base};
`;

export const StepCardRow = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 8px 16px;
  cursor: pointer;
`;

export const WrapperRows = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;

  ${StepCardRow}:hover {
    background-color: ${({ theme }) => theme.colors.neutral.gray_white};
    border-radius: 8px;
  }
`;

export const WrapperConfig = styled.div`
  width: 100px;
  margin-left: auto;
`;

export const WrapperConfigAndArrowIcon = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const WrapperText = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  width: 100%;
`;

export const Title = styled(TextBase)`
  font-weight: 500;
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.neutral.darkest};
`;

export const Description = styled(TextBase)`
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.neutral.dark};
`;

export const Link = styled(LinkBase)`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 1.6rem;
  font-weight: 500;
  text-decoration: none !important;
`;

export const TextLink = styled(TextBase)`
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.primary.green_lemon};
`;

export const ArrowUpRightIcon = styled(ArrowUpRight).attrs({
  weight: 'bold',
})`
  min-width: 10px;
  min-height: 10px;
  color: ${({ theme }) => theme.colors.primary.green_lemon};
`;

export const CircleIcon = styled(Circle).attrs({})`
  min-width: 20px;
  min-height: 20px;
  color: ${({ theme }) => theme.colors.neutral.lighter};
`;

export const WrapperLinearProgress = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TextStep = styled(TextBase)`
  font-size: 1.2rem;
  font-weight: 500;
  align-self: flex-end;
  color: ${({ theme }) => theme.colors.neutral.dark};
`;

export const SecondStepDoneCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  padding: 64px 82px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  background-color: ${({ theme }) => theme.colors.neutral.white};
`;
export const SecondStepDoneImage = styled(ImageBase)`
  width: 142px;
  height: 160px;
`;
export const SecondStepDoneTitle = styled(TextBase)`
  font-size: 2.3rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.neutral.darker};
`;
export const SecondStepDoneDescription = styled(TextBase)`
  font-size: 1.8rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.neutral.dark};
`;
