import React from 'react';

import { useTheme } from 'styled-components';

import { IProduct } from '@domain/interfaces/common/product/IProducts';

import { EHeadAlign } from '@domain/enums/components/common/ETable';

import { useListProducts } from '@store/common/product/ListProductsContext';

// import { EChipColor } from '@domain/enums/components/common/EChip';

import * as S from './styles';

interface ITableRowProps {
  product: IProduct;
}

const TableRow: React.FC<ITableRowProps> = ({ product }) => {
  // const theme = useTheme();

  const { handleSelectedProduct } = useListProducts();

  const variantsNumber = product.variants.length;

  const variantStockQuantitys = product.variants.map(variant => variant.stock_quantity);

  const sumOfAllStockQuantitys =
    variantStockQuantitys.length > 0
      ? variantStockQuantitys.reduce((currentValue, nextValue) => {
          return currentValue + nextValue;
        })
      : 0;

  const variantsNumberGreatherThanOne = variantsNumber > 1;
  const variantsText = variantsNumberGreatherThanOne ? 'variações' : 'variação';

  const stockNumberGreatherThanOne = sumOfAllStockQuantitys > 1 || sumOfAllStockQuantitys < -1;
  const stockText = stockNumberGreatherThanOne ? 'unidades' : 'unidade';

  const hasProductImage = product.images?.length > 0;
  const productImage = product.images?.length > 0 ? product.images[0]?.url : '';

  return (
    <S.Table.Row borderBottom>
      <S.Table.Data>
        <S.ProductIdContent>
          {/* <S.Checkbox /> */}

          {product.id}
        </S.ProductIdContent>
      </S.Table.Data>

      <S.Table.Data>
        <S.ProductNameContent>
          {!hasProductImage && <S.EmptyImage />}

          {hasProductImage && <S.ProductImage src={productImage} alt="Zouti Product" />}

          <S.ProductNameText>{product.name}</S.ProductNameText>
        </S.ProductNameContent>
      </S.Table.Data>

      <S.TableDataVariant align={EHeadAlign.CENTER}>
        <S.VariantsTextWrapper onClick={() => handleSelectedProduct(product)}>
          {`${variantsNumber} ${variantsText}`}
        </S.VariantsTextWrapper>
      </S.TableDataVariant>

      <S.Table.Data>{`${sumOfAllStockQuantitys} ${stockText}`}</S.Table.Data>

      {/* <S.Table.Data align={EHeadAlign.CENTER}>
        <S.Chip backgroundColor={EChipColor.LIGHT_GREEN} textColor={theme.colors.positive.darker}>
          teste
        </S.Chip>
      </S.Table.Data> */}

      <S.Table.Data />
      <S.Table.Data />
    </S.Table.Row>
  );
};

export default TableRow;
