import styled, { css } from 'styled-components/macro';

import TableBase from '@components/common/utils/Table';
import TextBase from '@components/common/DataDisplay/Text';

export const Table = styled(TableBase)``;

export const Text = styled(TextBase)`
  overflow: hidden;
  font-weight: 500;
  text-overflow: ellipsis;
`;
export const TableDataAmount = styled(TableBase.Data)`
  display: flex;
  flex-direction: column;
`;

export const TextAmount = styled(TextBase)<{ isnegative?: boolean }>`
  overflow: hidden;
  font-weight: 700;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.positive.dark};

  ${({ theme, isnegative }) =>
    isnegative &&
    css`
      color: ${theme.colors.negative.dark};
    `}
`;

export const TextInstallments = styled(TextBase)`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.neutral.dark};
`;
