import styled from 'styled-components/macro';

import ButtonBase from '@components/common/Inputs/Button';
import TextFieldBase from '@components/common/Inputs/TextField';

export const Wrapper = styled.div`
  display: flex;
  margin-bottom: 24px;
  gap: 16px;
`;

export const FilterButton = styled(ButtonBase)`
  border-color: ${({ theme }) => theme.colors.neutral.lightest};
  & > span {
    font-size: 1.4rem;
    color: ${({ theme }) => theme.colors.neutral.darker} !important;
  }
`;

export const InputSearch = styled(TextFieldBase)`
  width: 40%;
`;
