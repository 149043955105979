import React from 'react';

import { LoadingProvider } from '@store/context/loading/LoadingContext';

import Wrapper from '@components/pages/Loading/Wrapper';

const Loading: React.FC = () => {
  return (
    <LoadingProvider>
      <Wrapper />
    </LoadingProvider>
  );
};

export default Loading;
