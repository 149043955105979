import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components/macro';
import { PlusCircle } from 'phosphor-react';

import { useGetPixel } from '@store/common/pixel/GetPixelContext';

import { EButtonVariant } from '@domain/enums/components/common/EButton';

import PageHeader from '@components/common/utils/PageHeader';

import * as S from './styles';

const Header: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { totalPixels } = useGetPixel();
  const { accountId, checkoutId } = useParams();

  const onNewPixelButtonClick = (): void => {
    navigate(`/${accountId}/${checkoutId}/dashboard/marketing/pixel/new`);
  };

  const result = totalPixels > 1 ? `${totalPixels} resultados` : `${totalPixels} resultado`;

  return (
    <S.Wrapper>
      <PageHeader
        title="Pixels"
        description="Selecione a plataforma e configure os eventos que desejar."
        quantityText={result}
      />

      <S.NewPixelButton variant={EButtonVariant.SECONDARY} onClick={onNewPixelButtonClick}>
        <PlusCircle size={20} weight="bold" color={theme.colors.neutral.darker} /> Novo pixel
      </S.NewPixelButton>
    </S.Wrapper>
  );
};

export default Header;
