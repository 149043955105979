import React from 'react';
import * as DropDownMenu from '@radix-ui/react-dropdown-menu';

import * as S from './styles';

const Label: React.FC<
  (DropDownMenu.DropdownMenuLabelProps & React.RefAttributes<HTMLDivElement>) | null | undefined
> = ({ children, ...rest }) => {
  return <S.Wrapper {...rest}>{children}</S.Wrapper>;
};

export default Label;
