import React from 'react';
import useSWR from 'swr';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';

import { ISwr } from '@domain/interfaces/common/swr/ISwr';

import { useErrorHandler } from '@store/context/common/ErrorHandlerContext';
import { useToast } from '@store/context/common/ToastContext';
import { useAnalytics } from '@store/context/common/AnalyticsContext';
import { INewPayoutProvider } from '@domain/interfaces/store/common/payouts/INewPayoutProvider';
import { payoutSchema } from '@helpers/validators/pages/dashboard/payout/payoutSchema';
import { dashboardInstance } from '@services/common/dashboardInstance';
import { DASHBOARD_API_URL } from '@constants/services/apiUrl';
import { IListBankAccountResponse } from '@domain/interfaces/hooks/services/bankAccount/IBankAccountService';
import { IBankAccount } from '@domain/interfaces/common/bankAccount/IBankAccount';
import { usePayoutService } from '@hooks/services/payout/usePayoutService';
import { currencyToNumber } from '@helpers/utils/common/number';
import { ISimulatePayoutResponse } from '@domain/interfaces/hooks/services/payouts/IPayoutsService';

const NewPayoutContext = React.createContext<INewPayoutProvider | null>(null);

export interface IBankAccountSelectedProps {
  id: string;
  bank_code: string;
  account_number: string;
  routing_number: string;
}

export interface ISimulateResponse {
  transferAmount: number;
  transferFee: number;
  willReceive: number;
}

export const NewPayoutProvider: React.FC = ({ children }) => {
  const { toast } = useToast();
  const { analytics } = useAnalytics();
  const { handleError } = useErrorHandler();
  const { accountId } = useParams();
  const { createPayout, simulatePayout } = usePayoutService();

  const [nextStep, setNextStep] = React.useState<number>(0);
  const [bankAccounts, setBankAccounts] = React.useState<IBankAccount[]>([]);
  const [simulateResponse, setSimulateResponse] = React.useState<ISimulatePayoutResponse['object']>(
    {} as ISimulatePayoutResponse['object'],
  );
  const [isLoadingCreatePayout, setIsLoadingCreatePayout] = React.useState<boolean>(false);
  const [isLoadingSimulate, setIsLoadingSimulate] = React.useState<boolean>(false);
  const [isOpenModal, setIsOpenModal] = React.useState<boolean>(false);
  const [bankAccountSelected, setBankAccountSelected] = React.useState<IBankAccountSelectedProps>({
    id: '',
    bank_code: '',
    account_number: '',
    routing_number: '',
  });

  const BANK_ACCOUNT_URL = `${DASHBOARD_API_URL}/accounts/${accountId}/bank_accounts`;

  const { data: bankAccountsData, error } = useSWR<ISwr<IListBankAccountResponse>>(
    BANK_ACCOUNT_URL,
    dashboardInstance,
  );

  const {
    reset,
    watch,
    control,
    register,
    setValue,
    getValues,
    resetField,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(payoutSchema),
  });

  const transferValueRegister = register('transfer_value');

  const handleSelectedBankAccount = ({
    id,
    bank_code,
    account_number,
    routing_number,
  }: IBankAccountSelectedProps): void => {
    document.dispatchEvent(new KeyboardEvent('keydown', { key: 'Escape' }));
    setBankAccountSelected({
      id,
      bank_code,
      account_number,
      routing_number,
    });
    setValue('bank_code', bank_code, { shouldValidate: true });
    setValue('account_number', account_number, { shouldValidate: true });
    setValue('routing_number', routing_number, { shouldValidate: true });
  };

  const onSubmit = React.useCallback(
    async (data: any) => {
      setIsLoadingCreatePayout(true);
      try {
        await createPayout({
          accountId,
          payload: {
            ...data,
          },
        });
        setIsLoadingCreatePayout(false);
        setIsOpenModal(false);
        toast.success('Transferência criado com sucesso');
      } catch (errorCreatePayout) {
        setIsLoadingCreatePayout(false);
        handleError(errorCreatePayout);
      }
    },
    [accountId, createPayout, handleError, toast],
  );

  const createSimulatePayout = React.useCallback(
    async (data: { type: string }) => {
      setIsLoadingSimulate(true);
      const { transfer_value } = getValues();

      const payload = {
        amount: Math.round(currencyToNumber(transfer_value) * 100),
        type: data?.type,
        bank_id: bankAccountSelected?.id,
      };

      try {
        const response = await simulatePayout({
          accountId,
          payload,
        });
        setSimulateResponse(response.data.object);
        setIsLoadingSimulate(false);
        setNextStep(1);
      } catch (errorCreatePayout) {
        setIsLoadingSimulate(false);
        handleError(errorCreatePayout);
      }
    },
    [accountId, getValues, simulatePayout, bankAccountSelected, handleError],
  );

  React.useEffect(() => {
    if (error) {
      handleError(error);
    }

    if (bankAccountsData?.data?.object) {
      setBankAccounts(bankAccountsData.data.object);
    }
  }, [handleError, error, bankAccountsData?.data?.object]);

  return (
    <NewPayoutContext.Provider
      value={{
        watch,
        reset,
        errors,
        control,
        isValid,
        nextStep,
        register,
        setValue,
        onSubmit,
        getValues,
        resetField,
        setNextStep,
        isOpenModal,
        handleSubmit,
        bankAccounts,
        setIsOpenModal,
        setBankAccounts,
        simulateResponse,
        isLoadingSimulate,
        bankAccountSelected,
        transferValueRegister,
        setBankAccountSelected,
        handleSelectedBankAccount,
        createSimulatePayout,
        isLoadingCreatePayout,
      }}
    >
      {children}
    </NewPayoutContext.Provider>
  );
};

export const useNewPayout = (): INewPayoutProvider => {
  const context = React.useContext(NewPayoutContext);

  if (!context) {
    throw new Error('useNewPayout must be used within NewPayoutProvider');
  }

  return context;
};
