import React from 'react';
import useSWR from 'swr';
import { useNavigate, useParams } from 'react-router-dom';

import { ISwr } from '@domain/interfaces/common/swr/ISwr';
import { EWebhookEvent } from '@domain/enums/common/webhook/EWebhook';
import { IEditWebhookProvider } from '@domain/interfaces/store/common/webhook/IEditWebhookProvider';
import {
  IGetWebhookResponse,
  IUpdateWebhookData,
} from '@domain/interfaces/hooks/services/webhook/IWebhookService';

import { DASHBOARD_API_URL } from '@constants/services/apiUrl';
import { dashboardInstance } from '@services/common/dashboardInstance';

import { useToast } from '@store/context/common/ToastContext';
import { useErrorHandler } from '@store/context/common/ErrorHandlerContext';
import { useWebhookService } from '@hooks/services/webhook/useWebhookService';
import { useAnalytics } from '@store/context/common/AnalyticsContext';

const EditWebhookContext = React.createContext<IEditWebhookProvider | null>(null);

export const EditWebhookProvider: React.FC = ({ children }) => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const { accountId, webhookId, checkoutId } = useParams();
  const { handleError } = useErrorHandler();
  const { updateWebhook } = useWebhookService();
  const { analytics } = useAnalytics();

  const [webhookName, setWebhookName] = React.useState<string>('');
  const [webhookUrl, setWebhookUrl] = React.useState<string>('');
  const [webhookEvents, setWebhookEvents] = React.useState<Array<EWebhookEvent>>([]);
  const [isUpdatingWebhook, setIsUpdatingWebhook] = React.useState<boolean>(false);

  dashboardInstance.defaults.headers.common['x-zouti-account'] = accountId;

  const WEBHOOK_URL = `${DASHBOARD_API_URL}/accounts/${accountId}/webhooks/${webhookId}`;

  const { data, isLoading, isValidating, error } = useSWR<ISwr<IGetWebhookResponse>>(
    WEBHOOK_URL,
    dashboardInstance,
  );

  const onWebhookNameChange = React.useCallback(value => {
    setWebhookName(value);
  }, []);

  const onWebhookUrlChange = React.useCallback(value => {
    setWebhookUrl(value);
  }, []);

  const onWebhookEventsChange = React.useCallback(events => {
    setWebhookEvents(events);
  }, []);

  const onSubmitClick = React.useCallback(async () => {
    const webhookData: IUpdateWebhookData = {
      name: webhookName,
      url: webhookUrl,
      event_types: webhookEvents,
    };

    setIsUpdatingWebhook(true);

    try {
      await updateWebhook({ webhookId, accountId, payload: { ...webhookData } });

      analytics.track('Webhook Updated', { event_types: webhookEvents });

      toast.success('Webhook atualizado com sucesso!');
      setIsUpdatingWebhook(false);

      navigate(`/${accountId}/${checkoutId}/dashboard/apps/webhooks`);
    } catch (apiError: any) {
      setIsUpdatingWebhook(false);
      handleError(apiError);
      toast.error('Erro ao atualizar webhook!');
    }
  }, [
    webhookName,
    webhookUrl,
    webhookEvents,
    accountId,
    navigate,
    updateWebhook,
    handleError,
    webhookId,
    toast,
    analytics,
    checkoutId,
  ]);

  const onCancelClick = React.useCallback(() => {
    navigate(`/${accountId}/${checkoutId}/dashboard/apps/webhooks`);
  }, [navigate, accountId, checkoutId]);

  React.useEffect(() => {
    if (error) {
      handleError(error);
    }

    if (data?.data?.object) {
      setWebhookName(data.data.object.name);
      setWebhookUrl(data.data.object.url);
      setWebhookEvents(data.data.object.event_types);
    }
  }, [data, error, handleError]);

  const isLoadingWebhook = isLoading || isValidating;
  const isWebhookError = Boolean(error);

  return (
    <EditWebhookContext.Provider
      value={{
        onCancelClick,
        onSubmitClick,
        onWebhookEventsChange,
        onWebhookNameChange,
        onWebhookUrlChange,
        webhookEvents,
        webhookName,
        webhookUrl,
        isLoadingWebhook,
        isUpdatingWebhook,
        isWebhookError,
      }}
    >
      {children}
    </EditWebhookContext.Provider>
  );
};

export const useEditWebhook = (): IEditWebhookProvider => {
  const context = React.useContext(EditWebhookContext);

  if (!context) {
    throw new Error('useEditWebhook must be used within provider');
  }

  return context;
};
