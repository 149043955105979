import React from 'react';
import { Info } from 'phosphor-react';
import { useTheme } from 'styled-components/macro';

import { EHeadAlign } from '@domain/enums/components/common/ETable';

import { useListProducts } from '@store/common/product/ListProductsContext';
import { useAnalytics } from '@store/context/common/AnalyticsContext';

import TableRow from './TableRow';
import VariantsSideModalContent from './VariantsSideModalContent';

import * as S from './styles';

const Body: React.FC = () => {
  const theme = useTheme();
  const { analytics } = useAnalytics();
  const { products, variantSideModalOpen, handleVariantsSideModalOpen } = useListProducts();

  React.useEffect(() => {
    analytics.track('Products Page Viewed');
  }, [analytics]);

  return (
    <S.Wrapper>
      {Boolean(products.length) && (
        <S.Table>
          <S.Table.Header>
            <S.Table.Row>
              <S.Table.Head>
                <S.IdContent>
                  {/* <S.Checkbox /> */}
                  ID #
                </S.IdContent>
              </S.Table.Head>

              <S.Table.Head>Nome</S.Table.Head>

              <S.Table.Head align={EHeadAlign.CENTER}>Variantes</S.Table.Head>

              <S.Table.Head>
                <S.StockContent>
                  Estoque
                  <S.Tooltip>
                    <S.Tooltip.Trigger>
                      <Info size={18} color={theme.colors.neutral.lighter} weight="fill" />
                    </S.Tooltip.Trigger>

                    <S.TooltipContent side="top" sideOffset={5}>
                      <S.TooltipText>
                        Soma dos estoques <br /> de todas as variantes
                      </S.TooltipText>
                      <S.Tooltip.Arrow />
                    </S.TooltipContent>
                  </S.Tooltip>
                </S.StockContent>
              </S.Table.Head>

              {/* <S.Table.Head align={EHeadAlign.CENTER}>Status</S.Table.Head> */}

              <S.Table.Head />
              <S.Table.Head />
            </S.Table.Row>
          </S.Table.Header>

          <S.Table.Body>
            {products.map(product => {
              return <TableRow key={product.id} product={product} />;
            })}
          </S.Table.Body>
        </S.Table>
      )}

      <S.VariantsSideModal
        isOpen={variantSideModalOpen}
        toggle={handleVariantsSideModalOpen}
        title="Variantes"
      >
        <VariantsSideModalContent />
      </S.VariantsSideModal>
    </S.Wrapper>
  );
};

export default Body;
