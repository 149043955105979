import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  z-index: 999;

  .react-datepicker {
    display: flex;
  }

  .react-datepicker__aria-live {
    display: none;
  }

  .react-datepicker__navigation {
    position: absolute;
  }

  .react-datepicker__navigation--next {
    right: 12px;
  }

  .react-datepicker__month-container {
    padding: 24px 8px 0px 8px;

    .react-datepicker__header {
      .react-datepicker__current-month {
        text-align: center;
        font-size: 1rem;
        font-family: ${({ theme }) => theme.fonts.text};
        color: ${({ theme }) => theme.colors.primary.green_lemon};
        font-weight: 500;
      }

      .react-datepicker__day-names {
        display: flex;
        gap: 8px;
        margin-bottom: 10px;

        .react-datepicker__day-name {
          width: 40px;
          font-size: 1.4rem;
          font-weight: 700;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: ${({ theme }) => theme.fonts.text};
          color: ${({ theme }) => theme.colors.primary.green_lemon};

          @media only screen and (max-width: 512px) {
            width: 39px;
          }

          @media only screen and (max-width: 400px) {
            width: 36px;
          }
        }
      }
    }

    .react-datepicker__month {
      .react-datepicker__week {
        display: flex;
        /* gap: 8px; */

        .react-datepicker__day {
          width: 47px;
          height: 40px;
          border-right: none;
          font-family: ${({ theme }) => theme.fonts.text};
          font-size: 1.4rem;
          color: ${({ theme }) => theme.colors.neutral.black};
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          transition: all 0.3s;

          @media only screen and (max-width: 512px) {
            width: 39px;
          }

          @media only screen and (max-width: 400px) {
            width: 35px;
          }

          &:hover {
            background-color: ${({ theme }) => theme.colors.primary.green_lemon};
            border-radius: 4px;
          }

          &:last-child {
          }
        }

        .react-datepicker__day--outside-month {
          border: none;
          z-index: -1;
          opacity: 0;

          @media only screen and (max-width: 512px) {
            width: 39px;
          }

          @media only screen and (max-width: 400px) {
            width: 36px;
          }

          &:hover {
            background-color: ${({ theme }) => theme.colors.primary.green_lemon};
            color: ${({ theme }) => theme.colors.neutral.darker};
            font-weight: 700;
          }
        }

        .react-datepicker__day--in-range {
          background-color: ${({ theme }) => `${theme.colors.primary.green_lemon}33`};
          color: ${({ theme }) => theme.colors.neutral.darker};
        }

        .react-datepicker__day--in-selecting-range {
          background-color: ${({ theme }) => `${theme.colors.primary.green_lemon}33`};
          color: ${({ theme }) => theme.colors.neutral.darker};
        }

        .react-datepicker__day--range-start {
          background-color: ${({ theme }) => theme.colors.primary.green_lemon};
          color: ${({ theme }) => theme.colors.neutral.darker};
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }

        .react-datepicker__day--range-end {
          background-color: ${({ theme }) => theme.colors.primary.green_lemon};
          color: ${({ theme }) => theme.colors.neutral.darker};
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }

        .react-datepicker__day--selected {
          background-color: ${({ theme }) => theme.colors.primary.green_lemon};
          color: ${({ theme }) => theme.colors.neutral.darker};

          &.react-datepicker__day--selecting-range-start {
            border-radius: unset;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
          }
        }

        .react-datepicker__day--disabled {
          cursor: not-allowed;
          color: ${({ theme }) => theme.colors.neutral.lighter};

          &:hover {
            background-color: unset;
          }
        }
      }

      .react-datepicker__week:first-child {
        .react-datepicker__day--outside-month {
          height: 32px;
        }
      }

      .react-datepicker__week:not(:first-child) {
        .react-datepicker__day {
          border-top: none;
        }
      }

      .react-datepicker__week:last-child {
        .react-datepicker__day--028
          ~ .react-datepicker__day--001.react-datepicker__day--outside-month {
        }

        .react-datepicker__day--029
          ~ .react-datepicker__day--001.react-datepicker__day--outside-month {
        }

        .react-datepicker__day--030
          ~ .react-datepicker__day--001.react-datepicker__day--outside-month {
        }

        .react-datepicker__day--031
          ~ .react-datepicker__day--001.react-datepicker__day--outside-month {
        }

        .react-datepicker__day--outside-month {
          &:last-child {
            border: none;
          }
        }
      }
    }
  }
`;
