import React from 'react';
import { useTheme } from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';

import { IUpsell } from '@domain/interfaces/common/upsell/IUpsell';

import { useListUpsells } from '@store/common/upsell/ListUpsellContext';

import * as S from './styles';

interface ITableRowProps {
  upsell: IUpsell;
}

const TableRow: React.FC<ITableRowProps> = ({ upsell }) => {
  const { removeUpsell, inactivateSelectedUpsell, activateSelectedUpsell } = useListUpsells();
  const { accountId, checkoutId } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();

  const [isActive, setIsActive] = React.useState<boolean>(Boolean(!upsell.inactivated_at));

  const onCheckboxChange = React.useCallback(
    event => {
      setIsActive(event.target.checked);

      if (event.target.checked) {
        activateSelectedUpsell(upsell.id);
      } else {
        inactivateSelectedUpsell(upsell.id);
      }
    },
    [activateSelectedUpsell, inactivateSelectedUpsell, upsell],
  );

  const onEditOrderBumpClick = React.useCallback(() => {
    navigate(`/${accountId}/${checkoutId}/dashboard/marketing/upsells/${upsell?.id}`);
  }, [navigate, accountId, checkoutId, upsell]);

  const onUpsellClick = React.useCallback(() => {
    removeUpsell(upsell);
  }, [removeUpsell, upsell]);

  const upsellId = upsell.id;
  const upsellName = upsell?.name;
  const offerProductName = upsell?.offer_product?.variants?.[0].name;
  const offerProductImage =
    upsell?.offer_product?.images?.length > 0 ? upsell?.offer_product?.images[0]?.url : '';
  const triggerProductName =
    upsell?.trigger_product?.variants?.[0].name || upsell?.trigger_product?.product?.name;
  const triggerProductImage =
    upsell?.offer_product?.images?.length > 0 ? upsell?.trigger_product?.images[0]?.url : '';

  return (
    <S.Table.Row borderBottom>
      <S.Table.Data>
        <S.Switch id={upsellId} name={upsellId} checked={isActive} onChange={onCheckboxChange} />
      </S.Table.Data>

      <S.Table.Data>
        <S.TextDescription>{upsellName}</S.TextDescription>
      </S.Table.Data>

      <S.Table.Data>
        <S.ImageWithText>
          <S.ImageFile>
            <S.ImageSelect src={triggerProductImage} />
          </S.ImageFile>
          <S.Text>{triggerProductName}</S.Text>
        </S.ImageWithText>
      </S.Table.Data>

      <S.Table.Data>
        <S.ImageWithText>
          <S.ImageFile>
            <S.ImageSelect src={offerProductImage} />
          </S.ImageFile>
          <S.Text>{offerProductName}</S.Text>
        </S.ImageWithText>
      </S.Table.Data>

      <S.Table.Data>
        <S.PencilSimpleIcon
          weight="bold"
          size={24}
          color={theme.colors.neutral.darker}
          onClick={onEditOrderBumpClick}
        />
        <S.TrashIcon
          weight="bold"
          size={24}
          color={theme.colors.neutral.darker}
          onClick={onUpsellClick}
        />
      </S.Table.Data>

      <S.Table.Data />
    </S.Table.Row>
  );
};

export default TableRow;
