import * as yup from 'yup';

import { EStoreSegment } from '@domain/enums/common/onboarding/EStore';

export const storeSegmentSchema = yup.object().shape({
  store_segment: yup.string(),
  store_segment_value: yup.string().when('store_segment', {
    is: (value: string) => value === EStoreSegment.OTHER,
    then: schema =>
      schema.required('É necessário informar esse campo.').min(2, 'Informe uma resposta válida.'),
  }),
});
