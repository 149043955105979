import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ILoadingContext } from '@domain/interfaces/store/context/loading/ILoadingContext';

import { useConfig } from '@store/context/common/ConfigContext';

import { useAuthService } from '@hooks/services/auth/useAuthService';
import { useCheckoutService } from '@hooks/services/checkout/useCheckoutService';
import { useAccountService } from '@hooks/services/account/useAccountService';
import { useQuery } from '@hooks/common/useQuery';
import { useErrorHandler } from '../common/ErrorHandlerContext';

const LoadingContext = React.createContext<ILoadingContext | null>(null);

export const LoadingProvider: React.FC = ({ children }) => {
  const query = useQuery();
  const navigate = useNavigate();
  const { handleUser } = useConfig();
  const { getUserMe } = useAuthService();
  const { handleError } = useErrorHandler();
  const { listAccounts } = useAccountService();
  const { listCheckouts } = useCheckoutService();

  const membershipInviteToken = query?.get('membership_invite_token');
  const isUserInvited = Boolean(membershipInviteToken);
  const originPath = query?.get('origin_path');
  const listAccountsRef = React.useRef(listAccounts);
  const getUserMeRef = React.useRef(getUserMe);
  const listCheckoutsRef = React.useRef(listCheckouts);

  const loadData = React.useCallback(async () => {
    try {
      const { data: userData } = await getUserMeRef.current();
      const { data: accountsData } = await listAccountsRef.current();

      handleUser(userData.object);

      if (!accountsData.object[0]) {
        navigate(`/onboarding/user`);
        return;
      }

      const { data: checkoutsData } = await listCheckoutsRef.current({
        account_id: accountsData.object[0]?.id,
      });

      if (isUserInvited && originPath === 'accept-invite') {
        navigate(`/accept-invite?membership_invite_token=${membershipInviteToken}`);
        return;
      }

      if (isUserInvited) {
        navigate(`/${accountsData.object[0].id}/${checkoutsData.object[0].id}/dashboard/home`);
        return;
      }

      if (userData?.object?.is_invited_user) {
        navigate(`/${accountsData.object[0].id}/${checkoutsData.object[0].id}/dashboard/home`);
        return;
      }

      if (!userData?.object?.onboarding_finished_at) {
        navigate('/onboarding/user');
        return;
      }

      if (checkoutsData.object.length === 0) {
        navigate('/onboarding/store');
      } else {
        navigate(`/${accountsData.object[0].id}/${checkoutsData.object[0].id}/dashboard/home`);
      }
    } catch (error) {
      handleError(error);
    }
  }, [navigate, handleUser, handleError, isUserInvited, membershipInviteToken, originPath]);

  React.useEffect(() => {
    loadData();
  }, [loadData]);

  return <LoadingContext.Provider value={{ teste: 1 }}>{children}</LoadingContext.Provider>;
};

export const useLoadingProvider = (): ILoadingContext => {
  const context = React.useContext(LoadingContext);

  if (!context) {
    throw new Error('useLoadingProvider must be used within provider');
  }

  return context;
};
