import React from 'react';
import { useParams } from 'react-router-dom';

import { IAccountData } from '@domain/interfaces/common/accountData/IAccountData';
import { IUpdateAccountDataProvider } from '@domain/interfaces/store/common/accountData/IUpdateAccountData';

import { useToast } from '@store/context/common/ToastContext';
import { useErrorHandler } from '@store/context/common/ErrorHandlerContext';
import { useAccountDataService } from '@hooks/services/AccountData/useAccountDataService';
import { useAnalytics } from '@store/context/common/AnalyticsContext';

import { STORE_PROVIDERS } from '@constants/common/providers/providers';
import { useUserCheckout } from '@store/context/common/UserCheckout';

const UpdateAccountDataContext = React.createContext<IUpdateAccountDataProvider | null>(null);

export const UpdateAccountDataProvider: React.FC = ({ children }) => {
  const { updateAccountData, updateAccountLanguage } = useAccountDataService();
  const { toast } = useToast();
  const { analytics } = useAnalytics();
  const { handleError } = useErrorHandler();
  const { accountId, checkoutId } = useParams();
  const { mutateCheckout, currentUserAccount, currentUserCheckout } = useUserCheckout();

  const [isUpdatingAccountData, setIsUpdatingAccountData] = React.useState<boolean>(false);
  const [accountData, setAccountData] = React.useState<IAccountData>({} as IAccountData);
  const [selectedTab, setSelectedTab] = React.useState<number>(0);

  const onChangeTab = React.useCallback(index => setSelectedTab(index), []);

  const updateCurrentAccountLanguage = React.useCallback(
    async payload => {
      setIsUpdatingAccountData(true);

      try {
        await updateAccountLanguage({ accountId, checkoutId, payload });

        analytics.track('Account Language Updated', { ...payload });

        toast.success('Idioma/Moeda alterado com sucesso!');

        await mutateCheckout();

        setIsUpdatingAccountData(false);

        const foundProvider = STORE_PROVIDERS.find(provider =>
          currentUserAccount?.integrations.includes(provider.id),
        );

        if (foundProvider) {
          foundProvider.syncProducts({ accountId, checkoutId });
        }
      } catch (err: any) {
        setIsUpdatingAccountData(false);
        handleError(err);
      }
    },
    [
      mutateCheckout,
      accountId,
      analytics,
      handleError,
      toast,
      checkoutId,
      updateAccountLanguage,
      currentUserAccount,
    ],
  );

  const updateCurrentAccountData = React.useCallback(
    async formData => {
      setIsUpdatingAccountData(true);

      try {
        await updateAccountData({ accountId, checkoutId, ...formData });

        analytics.track('Account Data Updated');

        setIsUpdatingAccountData(false);
        toast.success('Dados da empresa atualizados com sucesso!');

        await mutateCheckout();
      } catch (err) {
        setIsUpdatingAccountData(false);
        handleError(err);
      } finally {
        setIsUpdatingAccountData(false);
      }
    },
    [updateAccountData, handleError, toast, accountId, mutateCheckout, analytics],
  );

  React.useEffect(() => {
    if (currentUserAccount) {
      setAccountData(currentUserAccount as IAccountData);
    }
  }, [currentUserAccount]);

  const isLoadingAccountData = !currentUserAccount;

  return (
    <UpdateAccountDataContext.Provider
      value={{
        accountData,
        currentUserCheckout,
        isLoadingAccountData,
        isUpdatingAccountData,
        updateCurrentAccountData,
        onChangeTab,
        selectedTab,
        updateCurrentAccountLanguage,
      }}
    >
      {children}
    </UpdateAccountDataContext.Provider>
  );
};

export const useUpdateAccountData = (): IUpdateAccountDataProvider => {
  const context = React.useContext(UpdateAccountDataContext);

  if (!context) {
    throw new Error('useUpdateAccountData must be used within provider');
  }

  return context;
};
