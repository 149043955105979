import React from 'react';

import * as S from './styles';

interface IBadgeProps {
  status: string;
}

enum EStatus {
  EXPIRED = 'EXPIRED',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

const Badge: React.FC<IBadgeProps> = ({ status }) => {
  let mappedStatus = EStatus.ACTIVE;
  let badgeText;

  if (status === EStatus.EXPIRED) {
    badgeText = 'Expirado';
    mappedStatus = EStatus.EXPIRED;
  } else if (status === EStatus.ACTIVE) {
    badgeText = 'Ativo';
    mappedStatus = EStatus.ACTIVE;
  } else if (status === EStatus.INACTIVE) {
    badgeText = 'Inativo';
    mappedStatus = EStatus.INACTIVE;
  }

  return <S.Badge status={mappedStatus}>{badgeText}</S.Badge>;
};

export default Badge;
