import * as Yup from 'yup';

const appearanceCheckoutCustomizationSchema = Yup.object().shape({
  primary_hex_color: Yup.string().nonNullable(),
  title_hex_color: Yup.string().nonNullable(),
  label_hex_color: Yup.string().nonNullable(),
  chip_hex_color: Yup.string().nonNullable(),
  button_hex_color: Yup.string().nonNullable(),
  button_text_hex_color: Yup.string().nonNullable(),
  title_font_family: Yup.string().nonNullable(),
  is_title_uppercase: Yup.boolean().nonNullable(),
  text_font_family: Yup.string().nonNullable(),
  is_button_uppercase: Yup.boolean().nonNullable(),
  metadata: Yup.object().shape({
    button_font_family: Yup.string().nonNullable(),
    is_text_uppercase: Yup.boolean().nonNullable(),
  }),
});

export default appearanceCheckoutCustomizationSchema;
