import React from 'react';

import { FieldValues, useForm } from 'react-hook-form';

import { usePaymentCredential } from '@store/common/paymentCredential/PaymentCredentialContext';

import { IUpdatePaymentCredentialData } from '@domain/interfaces/hooks/services/paymentCredential/IPaymentCredentialService';

import SaveHeaderBar from '@components/common/utils/SaveHeaderBar';

import { ETypographySize, ETypographyWeight } from '@domain/enums/components/common/ETypography';

import * as S from './styles';

type PaymentCredentialsCancelButtonClick = () => void;
type PaymentCredentialsSubmitButtonClick = () => void;

const CardCredentials: React.FC = () => {
  const {
    paymentCredential,
    updateCurrentPaymentCredential,
    createNewPaymentCredential,
    isUpdatingPaymentCredential,
    IsCreatingPaymentCredential,
  } = usePaymentCredential();
  const { register, handleSubmit, setValue } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const secretKey = register('secretKey');
  const publicKey = register('publicKey');

  const onSubmit = async (formData: FieldValues): Promise<void> => {
    const data: IUpdatePaymentCredentialData = {
      secret_key: formData.secretKey,
      public_key: formData.publicKey,
    };

    if (paymentCredential && paymentCredential.id) {
      await updateCurrentPaymentCredential({
        paymentCredentialId: paymentCredential.id,
        payload: data,
      });
    } else {
      await createNewPaymentCredential(data as any);
    }
  };

  const CARD_TITLE = 'Credenciais';

  const onPaymentCredentialsCancelButtonClick: PaymentCredentialsCancelButtonClick = () => {
    console.log('cancel');
  };

  const onPaymentCredentialsSubmitButtonClick: PaymentCredentialsSubmitButtonClick = () => {
    console.log('submit');
  };

  React.useEffect(() => {
    if (paymentCredential) {
      setValue('secretKey', paymentCredential.secret_key);
      setValue('publicKey', paymentCredential.public_key);
    }
  }, [setValue, paymentCredential]);

  return (
    <S.Form onSubmit={handleSubmit(onSubmit)}>
      <S.Credentials>
        <SaveHeaderBar
          onCancelClick={onPaymentCredentialsCancelButtonClick}
          onSubmitClick={onPaymentCredentialsSubmitButtonClick}
          isLoading={isUpdatingPaymentCredential || IsCreatingPaymentCredential}
        />
        <S.CredentialsTitle
          size={ETypographySize.h6_button_medium}
          weight={ETypographyWeight.medium}
        >
          {CARD_TITLE}
        </S.CredentialsTitle>

        <S.InputGroup>
          <S.Label>Chave secreta</S.Label>
          <S.Input {...secretKey} type="text" placeholder="Insira a Chave Secreta" />
        </S.InputGroup>

        <S.InputGroup>
          <S.Label>Chave pública</S.Label>
          <S.Input {...publicKey} type="text" placeholder="Insira a Chave Pública" />
        </S.InputGroup>
      </S.Credentials>
    </S.Form>
  );
};

export default CardCredentials;
