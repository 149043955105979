import * as Yup from 'yup';

export const payoutSchema = Yup.object().shape({
  transfer_value: Yup.string().required('Este campo é obrigatório!'),
  transfer_type: Yup.string().required('Este campo é obrigatório!'),
  bank_code: Yup.string().when('transfer_type', {
    is: (value: string) => value === 'BANK_ACCOUNT',
    then: schema => schema.required('Este campo é obrigatório!'),
  }),
  account_number: Yup.string().when('transfer_type', {
    is: (value: string) => value === 'BANK_ACCOUNT',
    then: schema => schema.required('Este campo é obrigatório!'),
  }),
  routing_number: Yup.string().when('transfer_type', {
    is: (value: string) => value === 'BANK_ACCOUNT',
    then: schema => schema.required('Este campo é obrigatório!'),
  }),
});
