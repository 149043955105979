import React from 'react';
import { useTheme } from 'styled-components';
import * as S from './styles';

const ChartTooltip: React.FC = ({ active, payload }: any) => {
  const theme = useTheme();

  const percentage = payload[0]?.value ? payload[0]?.value / 100 : 0;

  const percentFormatted = percentage?.toLocaleString('pt-BR', {
    style: 'percent',
    maximumFractionDigits: 2,
  });

  if (active && payload && payload.length) {
    return (
      <S.TooltipCard>
        <S.LabelWrapper key={payload[1].dataKey}>
          <S.Values color={theme.colors.neutral.darker}>
            {payload[0]?.payload?.quantity ? payload[0]?.payload?.quantity : '0'}
          </S.Values>
          <S.LabelText>Quantidade</S.LabelText>
        </S.LabelWrapper>

        <S.LabelWrapper key={payload[0].dataKey}>
          <S.Values color={theme.colors.neutral.darker}>{percentFormatted}</S.Values>
          <S.LabelText>Conversão</S.LabelText>
        </S.LabelWrapper>
      </S.TooltipCard>
    );
  }
  return null;
};
export default ChartTooltip;
