import React from 'react';
import { WarningOctagon } from '@phosphor-icons/react';
import { useTheme } from 'styled-components';

import { useGetOrder } from '@store/common/order/GetOrder';
import { useUserSession } from '@store/context/common/UserSession';

import * as S from './styles';

const RefundModal: React.FC = () => {
  const { colors } = useTheme();
  const { user } = useUserSession();
  const {
    isRefundModalOpen,
    handleRefundModalOpen,
    isRefundingOrder,
    refundOrder,
    refundWithFee,
    handleRefundWithFee,
  } = useGetOrder();

  const isAdmin = user?.user?.type === 'ADMIN';

  return (
    <S.Modal
      isOpen={isRefundModalOpen}
      toggle={handleRefundModalOpen}
      title="Estornar pedido"
      icon={<WarningOctagon size={24} weight="fill" color={colors.negative.base} />}
      onSuccess={refundOrder}
      colorSuccessButton={colors.negative.base}
      colorSuccessButtonText={colors.neutral.white}
      successText="Estornar pedido"
      cancelText="Cancelar"
      isSuccessButtonLoading={isRefundingOrder}
    >
      <S.Description>
        Você está prestes a estornar esse pedido. Essa ação é irreversível. Deseja continuar?
      </S.Description>

      {isAdmin && (
        <>
          <S.Divider />

          <S.RefundWithFeeWrapper>
            <S.Switch
              defaultChecked={refundWithFee}
              onChange={handleRefundWithFee}
              name="refund-with-fee"
              id="refund-with-fee"
            />

            <S.RefundWithLabelFee>Descontar taxa pré-chargeback</S.RefundWithLabelFee>
          </S.RefundWithFeeWrapper>
        </>
      )}
    </S.Modal>
  );
};

export default RefundModal;
