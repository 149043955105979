import React from 'react';
import { useTheme } from 'styled-components/macro';

import { Warning } from 'phosphor-react';

import { useShopify } from '@store/common/shopify/ShopifyContext';

import ScopeErrorContent from './ScopeErrorContent';
import CurrencyErrorContent from './CurrencyErrorContent';

import * as S from './styles';

const ErrorModal: React.FC = () => {
  const theme = useTheme();
  const { isOpenErrorModal, setIsOpenErrorModal } = useShopify();
  const title = isOpenErrorModal?.currency ? 'Divergência de moeda' : 'Escopo de acesso incompleto';

  return (
    <S.Modal
      icon={<Warning size={24} weight="fill" color={theme?.colors.warning.base} />}
      title={title}
      toggle={() => setIsOpenErrorModal({ ...isOpenErrorModal, currency: false, scope: false })}
      isOpen={isOpenErrorModal?.currency || isOpenErrorModal?.scope}
      isScopeError={Boolean(isOpenErrorModal?.scope)}
    >
      {isOpenErrorModal?.currency && <CurrencyErrorContent />}
      {isOpenErrorModal?.scope && <ScopeErrorContent />}
    </S.Modal>
  );
};

export default ErrorModal;
