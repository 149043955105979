import React from 'react';
import { WarningOctagon } from 'phosphor-react';
import { useTheme } from 'styled-components/macro';

import { ETypographySize } from '@domain/enums/components/common/ETypography';

import { useShopify } from '@store/common/shopify/ShopifyContext';
import * as S from './styles';

interface IDeleteCredentialModalProps {
  isOpen: boolean;
  toggle: () => void;
  isSuccessButtonLoading?: boolean;
  contentText?: string;
}

const DeleteCredentialModal: React.FC<IDeleteCredentialModalProps> = ({
  isOpen,
  toggle,
  isSuccessButtonLoading,
  contentText,
}) => {
  const theme = useTheme();
  const { deleteCurrentShopifyCredential } = useShopify();
  return (
    <S.Modal
      onSuccess={deleteCurrentShopifyCredential}
      isOpen={isOpen}
      toggle={toggle}
      icon={<WarningOctagon size={24} weight="fill" color={theme?.colors.negative.base} />}
      title="Excluir credenciais"
      cancelText="Cancelar"
      successButtonType="submit"
      colorSuccessButton={theme?.colors.negative.base}
      colorSuccessButtonText={theme?.colors.neutral.white}
      successText="Sim, excluir"
      isSuccessButtonLoading={isSuccessButtonLoading}
    >
      <S.Content size={ETypographySize.h6_button_medium} weight="500">
        {contentText}
      </S.Content>
    </S.Modal>
  );
};

export default DeleteCredentialModal;
