import React from 'react';
import SignUpUserInvited from '@components/pages/Public/SignUpUserInvited';
import { SignUpUserInvitedProvider } from '@store/context/public/signUpUserInvited/SignUpUserInvitedContext';

const SignUpUserInvitedPage: React.FC = () => {
  return (
    <SignUpUserInvitedProvider>
      <SignUpUserInvited />
    </SignUpUserInvitedProvider>
  );
};

export default SignUpUserInvitedPage;
