import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { IUpdatePixelProvider } from '@domain/interfaces/store/common/pixel/IUpdatePixelProvider';
import { ISwr } from '@domain/interfaces/common/swr/ISwr';
import { IPixel } from '@domain/interfaces/common/pixel/IPixel';
import {
  IListPixelPromiseResponse,
  IUpdatePixelData,
} from '@domain/interfaces/hooks/services/pixel/IPixelService';

import useSWR from 'swr';
import { DASHBOARD_API_URL } from '@constants/services/apiUrl';
import { dashboardInstance } from '@services/common/dashboardInstance';

import { usePixelService } from '@hooks/services/pixel/usePixelService';
import { useToast } from '@store/context/common/ToastContext';
import { useErrorHandler } from '@store/context/common/ErrorHandlerContext';
import { useAnalytics } from '@store/context/common/AnalyticsContext';

import { getPixelSchemaValidation } from '@helpers/validators/pages/dashboard/pixel/pixelSchemaValidation';

const UpdatePixelContext = React.createContext<IUpdatePixelProvider | null>(null);

export const UpdatePixelProvider: React.FC = ({ children }) => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const { analytics } = useAnalytics();
  const { handleError } = useErrorHandler();
  const { updatePixel } = usePixelService();
  const { pixelId, accountId, checkoutId } = useParams();

  const [pixel, setPixel] = React.useState<IPixel>({} as IPixel);
  const [isUpdatingPixel, setIsUpdatingPixel] = React.useState<boolean>(false);

  dashboardInstance.defaults.headers.common['x-zouti-account'] = accountId;

  const PIXEL_UPDATE_URL = `${DASHBOARD_API_URL}/accounts/${accountId}/checkouts/${checkoutId}/pixels/${pixelId}`;

  const { data, isLoading, isValidating, mutate, error } = useSWR<ISwr<IListPixelPromiseResponse>>(
    PIXEL_UPDATE_URL,
    dashboardInstance,
  );

  React.useEffect(() => {
    if (error) {
      handleError(error);
    }

    if (data?.data.object) {
      setPixel(data.data.object);
    }
  }, [data, handleError, error]);
  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(getPixelSchemaValidation(pixel?.provider)),
    defaultValues: {
      pixelName: pixel?.name,
      pixelId: pixel?.provider_id,
      send_credit_card_purchase: pixel?.send_credit_card_purchase,
      send_boleto_purchase: pixel?.send_boleto_purchase,
      send_pix_purchase: pixel?.send_pix_purchase,
    },
  });

  const updateCurrentPixel = React.useCallback(
    async currentPixel => {
      setIsUpdatingPixel(true);

      try {
        await updatePixel({ ...currentPixel, pixelId: pixel.id, accountId, checkoutId });

        analytics.track('Pixel Updated');

        setIsUpdatingPixel(false);

        await mutate();
        toast.success('Pixel atualizado com sucesso');
        navigate(`/${accountId}/${checkoutId}/dashboard/marketing/pixels`);
      } catch (err) {
        handleError(err);
        setIsUpdatingPixel(false);
      }
    },
    [toast, mutate, updatePixel, handleError, pixel, navigate, accountId, checkoutId, analytics],
  );

  const onSubmit = async (formData: FieldValues): Promise<void> => {
    const updateData: IUpdatePixelData = {
      name: formData.pixelName.trim(),
      provider: pixel?.provider,
      provider_id: formData?.pixelId.trim(),
      send_boleto_purchase: formData?.send_boleto_purchase,
      send_pix_purchase: formData?.send_pix_purchase,
      send_credit_card_purchase: formData?.send_credit_card_purchase,
      inactivated_at: formData?.inactivated_at,
      conversion_tag: formData?.conversionTag?.trim(),
    };

    await updateCurrentPixel({ payload: updateData, pixelId: pixel?.id });
  };

  const isLoadingPixel = isLoading || isValidating;
  const isPixelError = Boolean(error);

  return (
    <UpdatePixelContext.Provider
      value={{
        pixel,
        isLoadingPixel,
        isPixelError,
        mutate,
        updateCurrentPixel,
        isUpdatingPixel,
        register,
        control,
        handleSubmit,
        errors,
        onSubmit,
        setValue,
      }}
    >
      {children}
    </UpdatePixelContext.Provider>
  );
};

export const useUpdatePixel = (): IUpdatePixelProvider => {
  const context = React.useContext(UpdatePixelContext);

  if (!context) {
    throw new Error('useUpdatePixel must be used within provider');
  }

  return context;
};
