import React from 'react';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import * as S from './styles';

const SkeletonLoading: React.FC = () => {
  const TABLE_HEADER_TD = 4;
  const TABLE_BODY_ROW = 4;

  return (
    <S.Wrapper>
      <S.GetCheckoutHeaderWrapper>
        <S.TitleAndButtonWrapper>
          <Skeleton width="160px" height="40px" />
          <Skeleton width="140px" height="40px" />
        </S.TitleAndButtonWrapper>

        <Skeleton width="100%" height="25px" />
      </S.GetCheckoutHeaderWrapper>

      <S.TableWrapper>
        <S.Table>
          <S.Table.Header>
            <S.Table.Row>
              {Array.from({ length: TABLE_HEADER_TD }).map((_, index) => (
                <S.Table.Head key={index.toFixed()}>
                  <Skeleton width="100px" height="25px" />
                </S.Table.Head>
              ))}
            </S.Table.Row>
          </S.Table.Header>

          <S.Table.Body>
            {Array.from({ length: TABLE_BODY_ROW }).map((_, index) => (
              <S.Table.Row key={index.toFixed()}>
                <S.Table.Data>
                  <S.SwitchSkeleton />
                </S.Table.Data>
                <S.Table.Data>
                  <Skeleton width="150px" height="20px" />
                </S.Table.Data>
                <S.Table.Data>
                  <Skeleton width="120px" height="20px" />
                </S.Table.Data>
                <S.Table.Data>
                  <Skeleton width="120px" height="20px" />
                </S.Table.Data>
              </S.Table.Row>
            ))}
          </S.Table.Body>
        </S.Table>
      </S.TableWrapper>
    </S.Wrapper>
  );
};

export default SkeletonLoading;
