import React from 'react';
import useSWR from 'swr';
import { useParams } from 'react-router-dom';
import { isBefore, isSameDay } from 'date-fns';

import { IPromocode } from '@domain/interfaces/common/promocode/IPromocode';
import { IListPromocodesResponse } from '@domain/interfaces/hooks/services/promocode/IPromocodeService';
import { IGetPromocodeProvider } from '@domain/interfaces/store/common/promocode/IGetPromocodeProvider';
import { ISwr } from '@domain/interfaces/common/swr/ISwr';

import { DASHBOARD_API_URL } from '@constants/services/apiUrl';

import { dashboardInstance } from '@services/common/dashboardInstance';

import { usePromocodeService } from '@hooks/services/promocode/usePromocodeService';
import { useToast } from '@store/context/common/ToastContext';

import { useErrorHandler } from '@store/context/common/ErrorHandlerContext';
import { useAnalytics } from '@store/context/common/AnalyticsContext';

const GetPromocodeContext = React.createContext<IGetPromocodeProvider | null>(null);

export const GetPromocodeProvider: React.FC = ({ children }) => {
  const { toast } = useToast();
  const { updatePromocode, deletePromocode, inactivatePromocode, activatePromocode } =
    usePromocodeService();
  const { accountId, checkoutId } = useParams();
  const { handleError } = useErrorHandler();
  const { analytics } = useAnalytics();

  const [isUpdatingPromocode, setIsUpdatingPromocode] = React.useState<boolean>(false);
  const [isDeletingPromocode, setIsDeletingPromocode] = React.useState<boolean>(false);
  const [promocodes, setPromocodes] = React.useState<Array<IPromocode>>([]);
  const [page] = React.useState<number>(0);
  const [totalPages] = React.useState<number>(1);

  dashboardInstance.defaults.headers.common['x-zouti-account'] = accountId;

  const PROMOCODE_URL = `${DASHBOARD_API_URL}/accounts/${accountId}/checkouts/${checkoutId}/promocodes?page=0&total=12`;

  const { data, isLoading, isValidating, mutate, error } = useSWR<ISwr<IListPromocodesResponse>>(
    PROMOCODE_URL,
    dashboardInstance,
  );

  React.useEffect(() => {
    if (error) {
      handleError(error);
    }

    if (data?.data) {
      setPromocodes(data.data.object);
    }
  }, [data, handleError, error]);

  const updateCurrentPromocode = React.useCallback(
    async promocode => {
      setIsUpdatingPromocode(true);

      try {
        await updatePromocode({
          promocodeId: promocode.id,
          payload: promocode,
          accountId,
          checkoutId,
        });

        analytics.track('Coupon Updated');

        setIsUpdatingPromocode(false);
      } catch (errorPromocode) {
        setIsUpdatingPromocode(false);
        handleError(errorPromocode);
      }
    },
    [updatePromocode, handleError, accountId, checkoutId, analytics],
  );

  const deleteCurrentPromocode = React.useCallback(
    async couponId => {
      setIsDeletingPromocode(true);

      try {
        await deletePromocode({ promocodeId: couponId, accountId, checkoutId });

        analytics.track('Coupon Deleted');

        toast.success('Cupom deletado com sucesso!');
        await mutate();
        setIsDeletingPromocode(false);
      } catch (errorDeletePromocode) {
        toast.error('Erro ao deletar o cupom!');
        setIsDeletingPromocode(false);
        handleError(errorDeletePromocode);
      }
    },
    [deletePromocode, handleError, mutate, accountId, checkoutId, toast, analytics],
  );

  const activateCurrentPromocode = React.useCallback(
    async (promocodeId: string) => {
      try {
        await activatePromocode({ promocodeId, accountId, checkoutId });

        analytics.track('Coupon Updated');

        await mutate();
      } catch (errorActivatePromocode) {
        toast.error('Erro ao ativar cupom!');
      }
    },
    [activatePromocode, toast, mutate, accountId, checkoutId, analytics],
  );

  const inactivateCurrentPromocode = React.useCallback(
    async (promocodeId: string) => {
      try {
        await inactivatePromocode({ promocodeId, accountId, checkoutId });

        analytics.track('Coupon Updated');

        await mutate();
      } catch (errorInactivatePromocode) {
        toast.error('Erro ao inativar cupom!');
      }
    },
    [inactivatePromocode, toast, mutate, accountId, checkoutId, analytics],
  );

  const getPromocodeStatus = (promocode: IPromocode): 'ACTIVE' | 'EXPIRED' | 'INACTIVE' => {
    const { end_at, max_quantity, inactivated_at, used_quantity, deleted_at } = promocode;

    const USED_QUANTITY_IS_EQUAL_OR_GREATER_THAN_MAX_QUANTITY = used_quantity >= max_quantity;
    const END_AT_IS_EQUAL_OR_LESS_THAN_CURRENT_DATE =
      isBefore(new Date(end_at), new Date()) || isSameDay(new Date(end_at), new Date());
    const IS_DELETED = !!deleted_at;
    const IS_INACTIVATED = !!inactivated_at;

    let status: 'ACTIVE' | 'EXPIRED' | 'INACTIVE' = 'ACTIVE';

    if (USED_QUANTITY_IS_EQUAL_OR_GREATER_THAN_MAX_QUANTITY) {
      status = 'EXPIRED';
    }

    if (END_AT_IS_EQUAL_OR_LESS_THAN_CURRENT_DATE) {
      status = 'EXPIRED';
    }

    if (IS_DELETED) {
      status = 'EXPIRED';
    }

    if (IS_INACTIVATED) {
      status = 'INACTIVE';
    }

    return status;
  };

  const isLoadingPromocodes = isLoading || isValidating;
  const isPromocodesError = Boolean(error);
  const totalPromocodes = data?.data?.object?.length || 0;

  return (
    <GetPromocodeContext.Provider
      value={{
        promocodes,
        getPromocodeStatus,
        isLoadingPromocodes,
        isPromocodesError,
        mutate,
        page,
        totalPages,
        totalPromocodes,
        isUpdatingPromocode,
        updateCurrentPromocode,
        isDeletingPromocode,
        deleteCurrentPromocode,
        activateCurrentPromocode,
        inactivateCurrentPromocode,
      }}
    >
      {children}
    </GetPromocodeContext.Provider>
  );
};

export const useGetPromocode = (): IGetPromocodeProvider => {
  const context = React.useContext(GetPromocodeContext);

  if (!context) {
    throw new Error('useGetPromocodeContext must be used within provider');
  }

  return context;
};
