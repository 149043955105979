import React from 'react';

import { NewPixelProvider } from '@store/common/pixel/NewPixelContext';

import Content from '@components/pages/Dashboard/Pixel/NewPixel';

const NewPixel: React.FC = () => {
  return (
    <NewPixelProvider>
      <Content />
    </NewPixelProvider>
  );
};

export default NewPixel;
