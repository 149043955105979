import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';

import { IListShippingsResponse } from '@domain/interfaces/hooks/services/shipping/IShippingService';
import { IShipping } from '@domain/interfaces/common/shipping/IShipping';
import { ISwr } from '@domain/interfaces/common/swr/ISwr';
import { IUpdateShippingProvider } from '@domain/interfaces/store/common/shipping/IUpdateShippingProvider';

import { DASHBOARD_API_URL } from '@constants/services/apiUrl';
import { dashboardInstance } from '@services/common/dashboardInstance';

import { useErrorHandler } from '@store/context/common/ErrorHandlerContext';
import { useShippingService } from '@hooks/services/shipping/useShippingService';
import { useToast } from '@store/context/common/ToastContext';
import { useAnalytics } from '@store/context/common/AnalyticsContext';

const UpdateShippingContext = React.createContext<IUpdateShippingProvider | null>(null);

export const UpdateShippingProvider: React.FC = ({ children }) => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const { analytics } = useAnalytics();
  const { handleError } = useErrorHandler();
  const { shippingId, accountId, checkoutId } = useParams();
  const { updateShipping, deleteShipping, activateShipping, inactivateShipping } =
    useShippingService();

  const [isOpenModal, setIsOpenModal] = React.useState<boolean>(false);
  const [shippingDeletedInfo, setShippingDeletedInfo] = React.useState({
    shippingId: '',
    name: '',
  });
  const [isInactivatedAt, setInactivatedAt] = React.useState<boolean>(false);
  const [isLoadingDeleteShipping, setIsLoadingDeleteShipping] = React.useState<boolean>(false);
  const [isLoadingUpdateShipping, setIsLoadingUpdateShipping] = React.useState<boolean>(false);
  const [shipping, setShipping] = React.useState<IShipping>({} as IShipping);
  const [isFreeShipping, setIsFreeShipping] = React.useState<boolean>(false);
  const [period, setPeriod] = React.useState<number>(1);

  dashboardInstance.defaults.headers.common['x-zouti-account'] = accountId;

  const SHIPPING_URL = `${DASHBOARD_API_URL}/accounts/${accountId}/checkouts/${checkoutId}/freights?page=0&limit=10`;

  const { data, isLoading, isValidating, mutate, error } = useSWR<ISwr<IListShippingsResponse>>(
    SHIPPING_URL,
    dashboardInstance,
  );

  React.useEffect(() => {
    if (error) {
      handleError(error);
    }

    if (!data?.data) return;

    const foundShipping = data.data.object.find(shippingToFind => shippingToFind.id === shippingId);

    if (!foundShipping) return;

    setShipping(foundShipping);
    setPeriod(foundShipping.delivery_time_in_days);
    setIsFreeShipping(foundShipping.is_free);
    const isInactivated = Object.prototype.hasOwnProperty.call(shipping, 'inactivated_at');
    setInactivatedAt(!isInactivated);
  }, [data, shipping, shippingId, handleError, error]);

  const handleIsFreeShipping = React.useCallback(() => {
    setIsFreeShipping(previousState => !previousState);
  }, []);

  const increasePeriod = React.useCallback(() => {
    setPeriod(state => state + 1);
  }, []);

  const decreasePeriod = React.useCallback(() => {
    if (period === 1) return;

    setPeriod(state => state - 1);
  }, [period]);

  const onChangePeriod = React.useCallback(event => {
    setPeriod(event.target.value);
  }, []);

  const updateCurrentShipping = React.useCallback(
    async payload => {
      if (payload?.payload?.amount === 0 && !payload?.payload?.is_free) {
        toast.error('O valor do frete não pode ser zero!');
        return;
      }

      const payloadData = { ...payload.payload };

      try {
        setIsLoadingUpdateShipping(true);

        await updateShipping({
          shippingId: shipping.id,
          payload: payloadData,
          accountId,
          checkoutId,
        });

        analytics.track('Shipping Updated', {
          is_free_shipping: Boolean(payload?.payload?.is_free),
        });

        toast.success('Frete atualizado com sucesso!');
        await mutate();
        navigate(`/${accountId}/${checkoutId}/dashboard/checkout/shipping`);
      } catch (errorShipping) {
        handleError(errorShipping);
        setIsLoadingUpdateShipping(false);
      } finally {
        setIsLoadingUpdateShipping(false);
      }
    },
    [
      updateShipping,
      shipping,
      handleError,
      mutate,
      navigate,
      toast,
      accountId,
      checkoutId,
      analytics,
    ],
  );

  const deleteCurrentShipping = React.useCallback(
    async shippingIdData => {
      setIsLoadingDeleteShipping(true);
      try {
        await deleteShipping({ shippingId: shippingIdData, accountId, checkoutId });
        toast.success('Frete excluído com sucesso.');
        navigate(`/${accountId}/${checkoutId}/dashboard/checkout/shipping`);
        await mutate();
      } catch (errorDeleteShipping) {
        setIsLoadingDeleteShipping(false);
        handleError(errorDeleteShipping);
      } finally {
        setIsLoadingDeleteShipping(false);
      }
    },
    [deleteShipping, toast, handleError, mutate, navigate, accountId, checkoutId],
  );

  const inactivateCurrentShipping = React.useCallback(
    async (shippingInactivateId: string) => {
      try {
        await inactivateShipping({ shippingId: shippingInactivateId, accountId, checkoutId });
        await mutate();
      } catch (errorInactivateShipping) {
        handleError(errorInactivateShipping);
      }
    },
    [inactivateShipping, handleError, mutate, accountId, checkoutId],
  );
  const activateCurrentShipping = React.useCallback(
    async (shippingActivateId: string) => {
      try {
        await activateShipping({ shippingId: shippingActivateId, accountId, checkoutId });
        await mutate();
      } catch (errorActivateShipping) {
        handleError(errorActivateShipping);
      }
    },
    [activateShipping, handleError, mutate, accountId, checkoutId],
  );

  const isLoadingShipping = isLoading || isValidating;
  const isShippingError = Boolean(error);

  return (
    <UpdateShippingContext.Provider
      value={{
        shipping,
        handleIsFreeShipping,
        isFreeShipping,
        isLoadingShipping,
        isShippingError,
        period,
        decreasePeriod,
        increasePeriod,
        onChangePeriod,
        updateCurrentShipping,
        isLoadingUpdateShipping,
        deleteCurrentShipping,
        isLoadingDeleteShipping,
        isOpenModal,
        setIsOpenModal,
        shippingDeletedInfo,
        setShippingDeletedInfo,
        isInactivatedAt,
        setInactivatedAt,
        inactivateCurrentShipping,
        activateCurrentShipping,
      }}
    >
      {children}
    </UpdateShippingContext.Provider>
  );
};

export const useUpdateShipping = (): IUpdateShippingProvider => {
  const context = React.useContext(UpdateShippingContext);

  if (!context) {
    throw new Error('useUpdateShipping must be used within provider');
  }

  return context;
};
