import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';

import { useUpdatePixel } from '@store/common/pixel/UpdatePixelContext';
import Text from '@components/common/DataDisplay/Text';

import * as S from './styles';

const GoogleForm: React.FC = () => {
  const { pixel, errors, setValue, register, control } = useUpdatePixel();

  const {
    name,
    provider_id,
    conversion_tag,
    send_boleto_purchase,
    send_pix_purchase,
    send_credit_card_purchase,
  } = pixel;

  const pixelName = register('pixelName');
  const pixelId = register('pixelId');
  const conversionTag = register('conversionTag');

  const onPixelIdChange = React.useCallback(
    event => {
      const pixelIdValue = event.target.value;

      const parsedPixelIdValue = pixelIdValue.replace(/^(?!AW-)/, 'AW-').replace(/\s+/g, '');

      event.target.value = parsedPixelIdValue;

      pixelId.onChange(event);
    },
    [pixelId],
  );

  useEffect(() => {
    setValue('pixelName', name);
    setValue('pixelId', provider_id);
    setValue('conversionTag', conversion_tag);
  }, [conversion_tag, provider_id, name, setValue]);

  return (
    <S.Wrapper>
      <S.InputGroup>
        <S.Label>Nome do Pixel</S.Label>
        <S.Input {...pixelName} type="text" />
        {errors?.pixelName && <Text isFeedbackError>{errors?.pixelName?.message}</Text>}
      </S.InputGroup>

      <S.InputGroup>
        <S.Label>Id de conversão</S.Label>
        <S.Input {...pixelId} onChange={onPixelIdChange} type="text" />
        {errors?.pixelId && <Text isFeedbackError>{errors?.pixelId?.message}</Text>}
      </S.InputGroup>

      <S.InputGroup>
        <S.Label>Rótulo de conversão</S.Label>
        <S.Input {...conversionTag} type="text" />
      </S.InputGroup>

      <S.CheckboxGroup>
        <Controller
          control={control}
          name="send_credit_card_purchase"
          defaultValue={send_credit_card_purchase}
          render={({ field }) => (
            <S.Checkbox
              checked={send_credit_card_purchase}
              value={field?.value?.toString()}
              onChange={() => field?.onChange(!field.value)}
            />
          )}
        />
        <S.CheckboxLabel htmlFor="send_credit_card_purchase">
          Marcar compras no cartão
        </S.CheckboxLabel>
      </S.CheckboxGroup>

      <S.CheckboxGroup>
        <Controller
          control={control}
          name="send_pix_purchase"
          defaultValue={send_pix_purchase}
          render={({ field }) => (
            <S.Checkbox
              checked={send_pix_purchase}
              value={field?.value?.toString()}
              onChange={() => field?.onChange(!field.value)}
            />
          )}
        />
        <S.CheckboxLabel htmlFor="send_pix_purchase">Marcar compras no pix</S.CheckboxLabel>
      </S.CheckboxGroup>

      <S.CheckboxGroup>
        <Controller
          control={control}
          name="send_boleto_purchase"
          defaultValue={send_boleto_purchase}
          render={({ field }) => (
            <S.Checkbox
              checked={send_boleto_purchase}
              value={field?.value?.toString()}
              onChange={() => field?.onChange(!field.value)}
            />
          )}
        />
        <S.CheckboxLabel htmlFor="send_boleto_purchase">Marcar compras no boleto</S.CheckboxLabel>
      </S.CheckboxGroup>
    </S.Wrapper>
  );
};

export default GoogleForm;
