import React from 'react';
import { ResponsiveContainer, AreaChart as Chart, Area, XAxis, CartesianGrid } from 'recharts';
import { Package, Ticket } from 'phosphor-react';
import { useTheme } from 'styled-components/macro';

import { useGetGeneral } from '@store/common/general/GetGeneralContext';

import { mapMetrics } from '@utils/pages/dashboard/reports/metrics';

import { numberFormatter } from '@helpers/utils/common/numberFormatter';

import * as S from './styles';
import MetricsSkeleton from '../Skeletons/Metrics';

const Metrics: React.FC = () => {
  const theme = useTheme();
  const { overviewData, isLoadingCheckoutMetrics } = useGetGeneral();

  const paidOrders = overviewData?.paid_orders?.value || 0;
  const averageTicket = overviewData?.average_ticket?.value || 0;

  const isPaidOrdersEmpty = paidOrders === 0;
  const isAverageTicketEmpty = averageTicket === 0;
  const formattedPaidOrders = numberFormatter(paidOrders, 0);
  const formattedAverageTicket = numberFormatter(averageTicket / 100, 2);

  const mappedPaidOrders = mapMetrics(overviewData?.paid_orders.chart);
  const mappedAverageTicket = mapMetrics(overviewData?.average_ticket.chart);

  if (isLoadingCheckoutMetrics) {
    return <MetricsSkeleton />;
  }

  return (
    <S.Wrapper>
      <S.Title>Métricas gerais</S.Title>

      <S.MetricsWrapper>
        <S.SingleMetricsWrapper>
          <S.IconContainer>
            <Package size={22} color={theme.colors.primary.dark_green_60} />
          </S.IconContainer>

          <S.ChartWrapper>
            <ResponsiveContainer width="100%" height="100%">
              <Chart data={mappedPaidOrders}>
                <defs>
                  <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="0%" stopColor={theme.colors.primary.lightest} stopOpacity={2} />
                    <stop offset="100%" stopColor={theme.colors.primary.dark} stopOpacity={0.1} />
                  </linearGradient>
                </defs>

                <CartesianGrid
                  strokeDasharray="10 10"
                  horizontal={false}
                  stroke={theme.colors.neutral.lightest}
                />

                <XAxis
                  dataKey="name"
                  tick={{ dy: 8 }}
                  strokeDasharray="10 10"
                  axisLine={{ stroke: theme.colors.neutral.lightest }}
                  tickLine={false}
                />

                <Area
                  type="linear"
                  dataKey="value"
                  strokeWidth={2}
                  stroke={isPaidOrdersEmpty ? 'none' : theme.colors.primary.green_lemon}
                  fill="url(#colorUv)"
                />
              </Chart>
            </ResponsiveContainer>

            {isPaidOrdersEmpty && <S.EmptyText>Nenhum dado disponível</S.EmptyText>}
          </S.ChartWrapper>

          <S.InfoWrapper>
            <S.Value isEmptyData={isPaidOrdersEmpty}>{formattedPaidOrders}</S.Value>

            <S.Text>Pedidos pagos</S.Text>
          </S.InfoWrapper>
        </S.SingleMetricsWrapper>

        <S.SingleMetricsWrapper>
          <S.IconContainer>
            <Ticket size={22} color={theme.colors.primary.dark_green_60} />
          </S.IconContainer>

          <S.ChartWrapper>
            <ResponsiveContainer width="100%" height="100%">
              <Chart data={mappedAverageTicket}>
                <defs>
                  <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="0%" stopColor={theme.colors.primary.lightest} stopOpacity={2} />
                    <stop offset="100%" stopColor={theme.colors.primary.dark} stopOpacity={0.1} />
                  </linearGradient>
                </defs>

                <CartesianGrid
                  strokeDasharray="10 10"
                  horizontal={false}
                  stroke={theme.colors.neutral.lightest}
                />

                <XAxis
                  dataKey="name"
                  tick={{ dy: 8 }}
                  strokeDasharray="10 10"
                  axisLine={{ stroke: theme.colors.neutral.lightest }}
                  tickLine={false}
                />
                <Area
                  type="linear"
                  dataKey="value"
                  strokeWidth={2}
                  stroke={isAverageTicketEmpty ? 'none' : theme.colors.primary.green_lemon}
                  fill="url(#colorUv)"
                />
              </Chart>
            </ResponsiveContainer>
            {isAverageTicketEmpty && <S.EmptyText>Nenhum dado disponível</S.EmptyText>}
          </S.ChartWrapper>

          <S.InfoWrapper>
            <S.Value isEmptyData={isAverageTicketEmpty}>{`R$ ${formattedAverageTicket}`}</S.Value>

            <S.Text>Ticket Médio</S.Text>
          </S.InfoWrapper>
        </S.SingleMetricsWrapper>
      </S.MetricsWrapper>
    </S.Wrapper>
  );
};

export default Metrics;
