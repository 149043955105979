import React from 'react';

import { EditWebhookProvider } from '@store/common/webhook/EditWebhookContext';

import Wrapper from '@components/pages/Dashboard/Webhook/EditWebhook';

const EditWebhook: React.FC = () => {
  return (
    <EditWebhookProvider>
      <Wrapper />
    </EditWebhookProvider>
  );
};

export default EditWebhook;
