import styled from 'styled-components/macro';

import HeadingBase from '@components/common/DataDisplay/Heading';

export const Wrapper = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.lighter};
  padding-bottom: 16px;
  padding-left: 18px;
  margin-bottom: 32px;
`;

export const Title = styled(HeadingBase)`
  color: ${({ theme }) => theme.colors.neutral.darkest};
`;
