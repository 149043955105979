import React from 'react';

import { useTheme } from 'styled-components';

import { ETypographySize, ETypographyWeight } from '@domain/enums/components/common/ETypography';
import { MarginProps } from 'styled-system';

import * as S from './styles';

interface ITypographyProps extends MarginProps {
  size: ETypographySize | string;
  weight?: ETypographyWeight | string;
}

const Typography: React.FC<ITypographyProps> = ({
  children,
  size,
  weight = ETypographyWeight.regular,
  ...rest
}) => {
  const theme = useTheme();

  return (
    <S.Typography fontFamily={theme.fonts.typography} fontSize={size} fontWeight={weight} {...rest}>
      {children}
    </S.Typography>
  );
};

export default Typography;
