import React from 'react';

import { ListProductsProvider } from '@store/common/product/ListProductsContext';

import Content from '@components/pages/Dashboard/Products';

const Products: React.FC = () => {
  return (
    <ListProductsProvider>
      <Content />
    </ListProductsProvider>
  );
};

export default Products;
