import styled from 'styled-components/macro';

export const Header = styled.thead`
  background-color: #fbfbfb;

  & > tr {
    & > th {
      padding: 8px 24px;
    }
  }
`;
