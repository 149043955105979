import React from 'react';
import useSWR from 'swr';
import { useParams } from 'react-router-dom';
import { ISwr } from '@domain/interfaces/common/swr/ISwr';
import { IGetMyRatesProvider } from '@domain/interfaces/store/common/myRates/IGetMyRatesProvider';

import { DASHBOARD_API_URL } from '@constants/services/apiUrl';
import { dashboardInstance } from '@services/common/dashboardInstance';

import { useErrorHandler } from '@store/context/common/ErrorHandlerContext';
import { IMyRates, IMyRatesResponse } from '@domain/interfaces/common/MyRates/IMyRates';
import { IListReserveResponse, IReserve } from '@domain/interfaces/common/reserve/IReserve';

const GetMyRatesContext = React.createContext<IGetMyRatesProvider | null>(null);

export const GetMyRatesProvider: React.FC = ({ children }) => {
  const { accountId } = useParams();
  const { handleError } = useErrorHandler();

  const [reserves, setReserves] = React.useState<IReserve>({} as IReserve);
  const [rates, setRates] = React.useState<IMyRates>({} as IMyRates);

  const ACCOUNT_STEP_URL = `${DASHBOARD_API_URL}/accounts/${accountId}/current/plans`;
  const RESERVE_STEP_URL = `${DASHBOARD_API_URL}/accounts/${accountId}/current/reserves`;

  const { data, isLoading, isValidating, error } = useSWR<ISwr<IMyRatesResponse>>(
    ACCOUNT_STEP_URL,
    dashboardInstance,
  );

  const {
    data: reserveData,
    isLoading: isLoadingReserve,
    error: isErrorReserve,
  } = useSWR<ISwr<IListReserveResponse>>(RESERVE_STEP_URL, dashboardInstance);

  React.useEffect(() => {
    if (error) {
      handleError(error);
    }

    if (isErrorReserve) {
      handleError(isErrorReserve);
    }

    if (data?.data?.object) {
      setRates(data.data.object);
    }

    if (reserveData?.data?.object) {
      setReserves(reserveData.data.object);
    }
  }, [handleError, error, data, isErrorReserve, reserveData]);

  const isLoadingGetMyRates = isLoading || isValidating || isLoadingReserve;
  const isGetMyRatesError = Boolean(error);

  return (
    <GetMyRatesContext.Provider
      value={{
        rates,
        reserves,
        isLoadingGetMyRates,
        isGetMyRatesError,
      }}
    >
      {children}
    </GetMyRatesContext.Provider>
  );
};

export const useGetMyRatesContext = (): IGetMyRatesProvider => {
  const context = React.useContext(GetMyRatesContext);

  if (!context) {
    throw new Error('useGetMyRatesContext must be used within provider');
  }

  return context;
};
