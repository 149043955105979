import React from 'react';

import 'react-loading-skeleton/dist/skeleton.css';

import * as S from './styles';

const SkeletonLoading: React.FC = () => {
  return (
    <S.Wrapper>
      {Array.from({ length: 6 }).map((_, index) => (
        <S.WrapperSkeleton key={index.toFixed()}>
          <S.SwitchSkeleton />
          <S.EmailTypeSkeleton />
        </S.WrapperSkeleton>
      ))}
    </S.Wrapper>
  );
};

export default SkeletonLoading;
