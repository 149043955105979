import { AxiosResponse } from 'axios';

import {
  ICreateUserInvitedProps,
  ISignUpUserInvitedService,
  IAcceptInviteProps,
} from '@domain/interfaces/hooks/services/signUpUserInvited/ISignUpUserInvitedService';
import { ICreateUserInvitedResponse } from '@domain/interfaces/common/SignUpUserInvited/ISignUpUserInvited';

import { authInstance } from '@services/common/authInstance';

export const useSignUpUserInvitedService = (): ISignUpUserInvitedService => {
  const createUserInvited = async ({
    payload,
  }: ICreateUserInvitedProps): Promise<AxiosResponse<ICreateUserInvitedResponse>> => {
    return authInstance.post(`/users`, { ...payload });
  };
  const acceptInvite = async ({
    userId,
    membershipInviteToken,
  }: IAcceptInviteProps): Promise<AxiosResponse<ICreateUserInvitedResponse>> => {
    return authInstance.put(
      `/accounts/${userId}/membership_groups/accept_invite?membership_invite_token=${membershipInviteToken}`,
    );
  };

  return {
    createUserInvited,
    acceptInvite,
  };
};
