import React from 'react';

import { states } from '@constants/common/states';

import { useUpdateAccountData } from '@store/common/accountData/UpdateAccountData';

import * as S from './styles';

const AddressData: React.FC = () => {
  const { accountData } = useUpdateAccountData();
  const { address } = accountData;

  return (
    <S.Form>
      <S.AddressSection>
        <S.Title>Endereço</S.Title>
        <S.InputGroup>
          <S.Label>CEP</S.Label>
          <S.Input disabled value={address?.cep} placeholder="Digite o CEP" type="text" />
        </S.InputGroup>
        <S.InputGroup>
          <S.Label>Endereço</S.Label>
          <S.Input disabled value={address?.street} placeholder="Digite o endereço" type="text" />
        </S.InputGroup>
        <S.InputGroup>
          <S.Label>Número</S.Label>
          <S.Input
            disabled
            value={address?.number}
            placeholder="Digite o número do endereço"
            type="text"
          />
        </S.InputGroup>
        <S.InputGroup>
          <S.Label>Bairro</S.Label>
          <S.Input disabled value={address?.hood} placeholder="Digite o bairro" type="text" />
        </S.InputGroup>
        <S.InputGroup>
          <S.Label>
            Complemento <S.Span>(opcional)</S.Span>
          </S.Label>
          <S.Input
            disabled
            value={address?.complement}
            placeholder="Digite um complemento"
            type="text"
          />
        </S.InputGroup>
        <S.InputGroup>
          <S.Label>Cidade</S.Label>
          <S.Input disabled value={address?.city} placeholder="Digite a cidade" type="text" />
        </S.InputGroup>
        <S.InputGroup>
          <S.Label>Estado</S.Label>
          <S.Select disabled name="states">
            {states?.map(item => {
              return (
                <S.Option key={item.id} value={item.code}>
                  {address?.state}
                </S.Option>
              );
            })}
          </S.Select>
        </S.InputGroup>
      </S.AddressSection>
    </S.Form>
  );
};

export default AddressData;
