import { AxiosResponse } from 'axios';

import {
  IUpdateRecoveryWhatsAppProps,
  IRecoveryTemplateService,
  IUpdateRecoveryResponse,
  IUpdateRecoverySMSProps,
  IInactivateRecoveryTemplateProps,
  IInactivateRecoveryTemplateResponse,
  IActivateRecoveryTemplateProps,
  IActivateRecoveryTemplateResponse,
} from '@domain/interfaces/hooks/services/recoveryTemplate/IRecoveryTemplateService';

import { dashboardInstance } from '@services/common/dashboardInstance';

export const useRecoveryTemplateService = (): IRecoveryTemplateService => {
  const updateRecoveryTemplateSMS = async ({
    accountId,
    checkoutId,
    templateId,
    payload,
  }: IUpdateRecoverySMSProps): Promise<AxiosResponse<IUpdateRecoveryResponse>> => {
    return dashboardInstance.put(
      `accounts/${accountId}/checkouts/${checkoutId}/recovery_templates/${templateId}`,
      {
        ...payload,
      },
      {
        headers: {
          'x-zouti-account': accountId,
        },
      },
    );
  };
  const updateRecoveryTemplateWhatsApp = async ({
    accountId,
    checkoutId,
    templateId,
    payload,
  }: IUpdateRecoveryWhatsAppProps): Promise<AxiosResponse<IUpdateRecoveryResponse>> => {
    return dashboardInstance.put(
      `accounts/${accountId}/checkouts/${checkoutId}/recovery_templates/${templateId}`,
      {
        ...payload,
      },
      {
        headers: {
          'x-zouti-account': accountId,
        },
      },
    );
  };

  const inactivateRecoveryTemplate = async ({
    templateId,
    accountId,
    checkoutId,
  }: IInactivateRecoveryTemplateProps): Promise<
    AxiosResponse<IInactivateRecoveryTemplateResponse>
  > => {
    return dashboardInstance.put(
      `accounts/${accountId}/checkouts/${checkoutId}/recovery_templates/${templateId}/inactivate`,
      {
        headers: {
          'x-zouti-account': accountId,
        },
      },
    );
  };
  const activateRecoveryTemplate = async ({
    templateId,
    accountId,
    checkoutId,
  }: IActivateRecoveryTemplateProps): Promise<AxiosResponse<IActivateRecoveryTemplateResponse>> => {
    return dashboardInstance.put(
      `accounts/${accountId}/checkouts/${checkoutId}/recovery_templates/${templateId}/activate`,
      {
        headers: {
          'x-zouti-account': accountId,
        },
      },
    );
  };

  return {
    updateRecoveryTemplateSMS,
    updateRecoveryTemplateWhatsApp,
    inactivateRecoveryTemplate,
    activateRecoveryTemplate,
  };
};
