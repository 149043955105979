import styled from 'styled-components/macro';

import ButtonBase from '@components/common/Inputs/Button';
import InputGroupBase from '@components/common/Inputs/InputGroup';
import LabelBase from '@components/common/DataDisplay/Label';
import RadioBase from '@components/common/Inputs/Radio';
import TextBase from '@components/common/DataDisplay/Text';
import TextFieldBase from '@components/common/Inputs/TextField';
import HeadingBase from '@components/common/DataDisplay/Heading';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Title = styled(HeadingBase)`
  color: ${({ theme }) => theme.colors.neutral.darker};
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-color: ${({ theme }) => theme.colors.neutral.white};
  padding: 24px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;

export const Label = styled(LabelBase)``;

export const InputGroup = styled(InputGroupBase)``;

export const MessageError = styled(TextBase)`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.negative.base};
`;

export const Input = styled(TextFieldBase)`
  width: 100%;
`;

export const Radio = styled(RadioBase)``;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: flex-end;
`;

export const SubmitButton = styled(ButtonBase)``;

export const CancelButton = styled(ButtonBase)`
  & > span {
    color: ${({ theme }) => theme.colors.neutral.dark} !important;
  }
`;

export const InputGroupWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const AccountWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 16px;

  div:first-child {
    grid-column: 1 / 3;
  }
`;

export const InputGroupButton = styled.div`
  display: flex;
  gap: 8px;
`;

export const WrapperInputAndTextError = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const GenerateCouponButton = styled(ButtonBase)``;
