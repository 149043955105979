import styled from 'styled-components/macro';

import ButtonBase from '@components/common/Inputs/Button';
import CheckboxBase from '@components/common/Inputs/Checkbox';
import HeadingBase from '@components/common/DataDisplay/Heading';
import InputColorBase from '@components/common/Inputs/InputColorCheckout';
import LabelBase from '@components/common/DataDisplay/Label';
import OptionBase from '@components/common/Inputs/Select/Option';
import SelectBase from '@components/common/Inputs/Select';

export const Form = styled.form``;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.neutral.white};
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  border-radius: 4px;
  padding: 24px;
`;

export const Divider = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.neutral.lightest};
  margin: 16px 0;
`;

export const Title = styled(HeadingBase)`
  color: ${({ theme }) => theme.colors.neutral.darkest};
`;

export const ColorSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const InputColorWrapper = styled.div`
  display: flex;
  gap: 16px;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

export const InputColor = styled(InputColorBase)``;

export const ButtonColorSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ButtonColorInputWrapper = styled.div`
  display: flex;
  gap: 16px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Label = styled(LabelBase)`
  font-weight: 500;
`;

export const TypographySection = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, auto);

  & > :nth-child(1) {
    grid-column: 1 / span 4;
    grid-row: 1;
  }

  & > div:nth-child(2) {
    grid-column: 1 / span 2;
    grid-row: 2;
  }

  & > div:nth-child(3) {
    grid-column: 3 / span 2;
    grid-row: 2;
  }

  & > div:nth-child(4) {
    grid-column: 1 / span 4;
    grid-row: 3;
  }
`;

export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const LabelAndSelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LabelAndCheckboxWrapper = styled(LabelAndSelectWrapper)``;

export const Select = styled(SelectBase)`
  min-width: 100%;
`;
export const Option = styled(OptionBase)`
  font-size: 1.6rem;
`;

export const Checkbox = styled(CheckboxBase)``;

export const CheckboxLabel = styled(LabelBase)`
  margin: 0;
  cursor: pointer;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.neutral.darkest};
`;

export const CheckboxGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 24px;
  gap: 16px;
`;

export const SubmitButton = styled(ButtonBase)``;

export const CancelButton = styled(ButtonBase)`
  & > span {
    color: ${({ theme }) => theme.colors.neutral.dark} !important;
  }
`;
