import React from 'react';

import * as TooltipPrimitive from '@radix-ui/react-tooltip';

import Content from './Content';
import Trigger from './Trigger';
import Arrow from './Arrow';

import * as S from './styles';

interface ICustomTooltip {
  Content: typeof Content;
  Trigger: typeof Trigger;
  Arrow: typeof Arrow;
}

const CustomTooltip: ICustomTooltip & React.FC<TooltipPrimitive.TooltipProps> = ({
  children,
  ...rest
}) => {
  return (
    <S.Provider delayDuration={100}>
      <S.Tooltip {...rest}>{children}</S.Tooltip>
    </S.Provider>
  );
};

CustomTooltip.Content = Content;
CustomTooltip.Trigger = Trigger;
CustomTooltip.Arrow = Arrow;

export default CustomTooltip;
