import React from 'react';

import { useNewPixel } from '@store/common/pixel/NewPixelContext';

import * as S from './styles';

export interface IProviderProps {
  text: string;
  icon: string;
  name: string;
}

export interface IProviderSelectProps {
  isDropdownOpen: boolean;
}

export interface IProviderOptionProps {
  provider: IProviderProps;
}

const ProviderOption: React.FC<any> = ({ provider }) => {
  const { handleSelectedProvider } = useNewPixel();
  const { icon, text, name } = provider;

  return (
    <S.ProviderOption onClick={() => handleSelectedProvider({ providerName: name })}>
      <S.ProviderIcon src={icon} />
      <S.ProviderName>{text}</S.ProviderName>
    </S.ProviderOption>
  );
};

export default ProviderOption;
