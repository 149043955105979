import styled, { css } from 'styled-components/macro';

import ModalBase from '@components/common/DataDisplay/Modal';

interface IModalProps {
  isScopeError: boolean;
}

export const Modal = styled(ModalBase)<IModalProps>`
  background-color: ${({ theme }) => theme.colors.neutral.white};
  max-width: 520px;

  ${({ isScopeError }) =>
    isScopeError &&
    css`
      max-width: unset;
    `}
`;
