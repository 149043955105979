import styled, { css } from 'styled-components/macro';

import { EChipColor } from '@domain/enums/components/common/EChip';

import Typography from '../Typography';

interface IChipProps {
  backgroundColor?: string;
}

interface IChipTextProps {
  textColor?: string;
}

export const ChipText = styled(Typography)<IChipTextProps>`
  color: unset;
  text-transform: uppercase;

  ${({ textColor }) =>
    textColor &&
    css`
      color: ${textColor} !important;
    `}
`;

export const Wrapper = styled.div<IChipProps>`
  width: fit-content;
  height: 23px;
  padding: 4px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: ${({ theme }) => theme.borderRadius.small};

  ${({ theme, backgroundColor }) =>
    backgroundColor === EChipColor.LIGHT_RED &&
    css`
      background-color: ${theme.colors.negative.lightest};

      & > ${ChipText} {
        color: ${theme.colors.neutral.darkest};
      }
    `}

  ${({ theme, backgroundColor }) =>
    backgroundColor === EChipColor.RED &&
    css`
      background-color: ${theme.colors.negative.darkest};

      & > ${ChipText} {
        color: ${theme.colors.neutral.gray_white};
      }
    `}

    ${({ theme, backgroundColor }) =>
    backgroundColor === EChipColor.LIGHT_BLUE &&
    css`
      background-color: ${theme.colors.info.lightest};

      & > ${ChipText} {
        color: ${theme.colors.neutral.darkest};
      }
    `}

    ${({ theme, backgroundColor }) =>
    backgroundColor === EChipColor.DARK_BLUE &&
    css`
      background-color: ${theme.colors.info.darkest};

      & > ${ChipText} {
        color: ${theme.colors.neutral.gray_white};
      }
    `}

    ${({ theme, backgroundColor }) =>
    backgroundColor === EChipColor.LIGHT_GREEN &&
    css`
      background-color: ${theme.colors.positive.lightest};

      & > ${ChipText} {
        color: ${theme.colors.neutral.darkest};
      }
    `}

    ${({ theme, backgroundColor }) =>
    backgroundColor === EChipColor.DARK_GREEN &&
    css`
      background-color: ${theme.colors.positive.darkest};

      & > ${ChipText} {
        color: ${theme.colors.neutral.gray_white};
      }
    `}

    ${({ theme, backgroundColor }) =>
    backgroundColor === EChipColor.GRAY &&
    css`
      background-color: ${theme.colors.neutral.lightest};

      & > ${ChipText} {
        color: ${theme.colors.neutral.darkest};
      }
    `}

    ${({ theme, backgroundColor }) =>
    backgroundColor === EChipColor.BLACK &&
    css`
      background-color: ${theme.colors.neutral.darkest};

      & > ${ChipText} {
        color: ${theme.colors.neutral.gray_white};
      }
    `}

    ${({ theme, backgroundColor }) =>
    backgroundColor === EChipColor.PRIMARY_LIGHT &&
    css`
      background-color: ${theme.colors.primary.lightest};

      & > ${ChipText} {
        color: ${theme.colors.neutral.darkest};
      }
    `}

    ${({ theme, backgroundColor }) =>
    backgroundColor === EChipColor.PRIMARY_DARK &&
    css`
      background-color: ${theme.colors.primary.darkest};

      & > ${ChipText} {
        color: ${theme.colors.neutral.gray_white};
      }
    `}

  ${({ theme, backgroundColor }) =>
    backgroundColor === EChipColor.WHITE_BLUE &&
    css`
      background-color: ${theme.colors.info.white_blue};

      & > ${ChipText} {
        color: ${theme.colors.info.darker};
      }
    `}
`;
