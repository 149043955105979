import { AxiosResponse } from 'axios';

import { dashboardInstance } from '@services/common/dashboardInstance';

import {
  ITransactionalEmailService,
  IVerifyEmailProps,
  IVerifyEmailResponse,
  IUpdateTransactionalEmailProps,
  IUpdateTransactionalEmailResponse,
  IChangeTypeStatusProps,
  IChangeTypeStatusResponse,
  ICreateTransactionalEmailResponse,
  IUpdateSenderNameResponse,
  IUpdateTransactionalEmailActiveStatus,
  IUpdateTransactionalEmailSenderName,
} from '@domain/interfaces/hooks/services/transactionalEmail/ITransactionaEmailService';

export const useTransactionalEmailService = (): ITransactionalEmailService => {
  const updateTransactionalEmailActiveStatus = async ({
    accountId,
    checkoutId,
    payload,
  }: IUpdateTransactionalEmailActiveStatus): Promise<
    AxiosResponse<ICreateTransactionalEmailResponse>
  > => {
    return dashboardInstance.put(
      `accounts/${accountId}/checkouts/${checkoutId}/transactional_mails/update_active_status`,
      payload,
    );
  };

  const updateTransactionalEmailSenderEmail = async ({
    accountId,
    checkoutId,
    payload,
  }: IUpdateTransactionalEmailProps): Promise<AxiosResponse<IUpdateTransactionalEmailResponse>> => {
    return dashboardInstance.put(
      `accounts/${accountId}/checkouts/${checkoutId}/transactional_mails/update_sender_email`,
      payload,
    );
  };

  const changeTypeStatusTransactionalEmail = async ({
    accountId,
    checkoutId,
    payload,
  }: IChangeTypeStatusProps): Promise<AxiosResponse<IChangeTypeStatusResponse>> => {
    return dashboardInstance.put(
      `accounts/${accountId}/checkouts/${checkoutId}/transactional_mails/change_type_status`,
      payload,
    );
  };

  const updateTransactionalEmailSenderName = async ({
    accountId,
    checkoutId,
    payload,
  }: IUpdateTransactionalEmailSenderName): Promise<AxiosResponse<IUpdateSenderNameResponse>> => {
    return dashboardInstance.put(
      `accounts/${accountId}/checkouts/${checkoutId}/transactional_mails/update_sender_name`,
      payload,
    );
  };

  const verifyTransactionalEmail = async ({
    accountId,
    checkoutId,
  }: IVerifyEmailProps): Promise<AxiosResponse<IVerifyEmailResponse>> => {
    return dashboardInstance.post(
      `accounts/${accountId}/checkouts/${checkoutId}/transactional_mails/validate`,
    );
  };

  return {
    verifyTransactionalEmail,
    updateTransactionalEmailSenderEmail,
    changeTypeStatusTransactionalEmail,
    updateTransactionalEmailActiveStatus,
    updateTransactionalEmailSenderName,
  };
};
