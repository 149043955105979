import styled, { css } from 'styled-components/macro';

import {
  ClockCountdown,
  CurrencyDollarSimple,
  CheckCircle,
  PiggyBank,
} from '@phosphor-icons/react';

import Text from '@components/common/DataDisplay/Text';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 32px;
  margin-bottom: 32px;
`;

export const CheckCircleIcon = styled(CheckCircle).attrs({
  weight: 'bold',
  size: 14,
})`
  color: ${({ theme }) => theme.colors.positive.dark};
`;

export const ClockCountDownIcon = styled(ClockCountdown).attrs({
  weight: 'bold',
  size: 14,
})`
  color: ${({ theme }) => theme.colors.warning.dark};
`;

export const CurrencyDollarSimpleIcon = styled(CurrencyDollarSimple).attrs({
  weight: 'bold',
  size: 14,
})`
  color: ${({ theme }) => theme.colors.neutral.darkest};
`;

export const PiggyBankIcon = styled(PiggyBank).attrs({
  weight: 'bold',
  size: 14,
})`
  color: ${({ theme }) => theme.colors.neutral.darkest};
`;

export const TextBase = styled(Text)`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.neutral.black};
`;
