import { endOfDay, format, startOfDay } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

export const getStartDateStartOfDay = (startDate: Date): Date => {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return zonedTimeToUtc(startOfDay(startDate), tz);
};

export const getEndDateEndOfDay = (endDate: Date): Date => {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return zonedTimeToUtc(endOfDay(endDate), tz);
};

export const formatDateParts = (date: string): string => {
  return `${format(new Date(date), 'dd/MM/yyyy')} às ${format(new Date(date), 'HH:mm')}`;
};
