import { Bank, CaretRight } from 'phosphor-react';
import styled from 'styled-components/macro';

import ButtonBase from '@components/common/Inputs/Button';
import CheckboxBase from '@components/common/Inputs/Checkbox';
import DropdownBase from '@components/common/utils/Dropdown';
import InputGroupBase from '@components/common/Inputs/InputGroup';
import LabelBase from '@components/common/DataDisplay/Label';
import RadioBase from '@components/common/Inputs/Radio';
import selectArrow from '@assets/images/components/select/CaretDown.svg';
import TextBase from '@components/common/DataDisplay/Text';
import TextFieldBase from '@components/common/Inputs/TextField';

interface IProviderSelectProps {
  isselectedbank: string;
}

export const Form = styled.form`
  display: flex;
  gap: 24px;
  width: 100%;
  flex-direction: column;
`;

export const Label = styled(LabelBase)`
  margin: 0;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.neutral.dark};
`;

export const Text = styled(TextBase)`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.neutral.black};
`;

export const AvailableBalanceGroup = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
`;

export const AvailableBalanceWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
`;

export const AvailableBalanceCurrencySymbol = styled(Text)`
  display: flex;
  align-self: end;
  margin-bottom: 3px;
`;

export const AvailableBalanceValue = styled(Text)`
  font-size: 2rem;
`;

export const CheckboxLabel = styled(Label)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.neutral.black};
`;

export const Input = styled(TextFieldBase)``;

export const Checkbox = styled(CheckboxBase)`
  & > div > svg {
    stroke: ${({ theme }) => theme.colors.neutral.darkest};
  }
`;

export const CheckboxWrapper = styled.div<{ checked?: boolean }>`
  display: flex;
  cursor: pointer;
  padding: 16px;
  border-radius: 8px;
  background-color: ${({ checked, theme }) =>
    checked ? theme.colors.primary.lemon_20 : 'transparent'};
  border: 1px solid
    ${({ checked, theme }) =>
      checked ? theme.colors.primary.green_light : theme.colors.neutral.lightest};
  justify-content: space-between;
`;

export const CheckboxGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Radio = styled(RadioBase)`
  width: 16px;
  height: 16px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.base};

  &:before {
    content: '';
    width: 8px;
    height: 8px;
    background-color: ${({ theme }) => theme.colors.neutral.darkest};
  }

  &:checked {
    border: 1px solid ${({ theme }) => theme.colors.neutral.darkest};
  }
`;

export const RadioLabelAndRadioButtonWrapper = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
  margin-bottom: 77px;
`;

export const RadioContainer = styled.div`
  display: flex;
  gap: 16px;
`;

export const RadioWrapper = styled.div<{ isSelected: boolean }>`
  display: flex;
  width: 100%;
  cursor: pointer;
  padding: 16px;
  border-radius: 8px;
  justify-content: space-between;
  background-color: ${({ isSelected, theme }) =>
    isSelected ? theme.colors.primary.lemon_20 : 'transparent'};
  border: 1px solid
    ${({ isSelected, theme }) =>
      isSelected ? theme.colors.primary.green_light : theme.colors.neutral.lightest};
`;

export const RadioGroup = styled(CheckboxGroup)``;

export const RadioLabel = styled(Label)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.neutral.black};
`;

export const ContentIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  width: 32px;
  height: 32px;
  background-color: ${({ theme }) => theme.colors.primary.lemon_30};
`;

export const CurrencyLabel = styled.div`
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  font-family: ${({ theme }) => theme.fonts.text};
  font-weight: 700;
  color: ${({ theme }) => theme.colors.neutral.darkest};
`;

export const ValueWrapperCouple = styled.div`
  display: flex;
  align-content: center;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  width: 100%;
  border-radius: 4px;

  & > div:nth-child(2) {
    border: unset;
    width: 82%;
    border-radius: unset;
    border-left: 1px solid ${({ theme }) => theme.colors.neutral.lightest};

    & > input {
      width: 100%;
      text-align: left;
    }
  }
`;

export const MessageError = styled(TextBase)`
  font-size: 1.4rem;
`;

export const InputGroup = styled(InputGroupBase)`
  gap: ${({ theme }) => theme.spacing.small_8};
`;

export const Info = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${({ theme }) => theme.colors.neutral.dark};
  font-family: ${({ theme }) => theme.fonts.typography};
`;

export const MessageInfo = styled(TextBase)`
  font-size: 1.4rem;
`;

export const DropdownContent = styled(DropdownBase.Content)`
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  z-index: 999999999;
`;

export const CareRightIcon = styled(CaretRight).attrs({ size: 12, weight: 'bold' })`
  margin-left: auto;
`;

export const BankIcon = styled(Bank).attrs({ size: 15, weight: 'bold' })``;

export const ContentBankIcon = styled(ContentIcon)`
  width: 24px;
  height: 24px;
  background-color: ${({ theme }) => theme.colors.neutral.lighter};
`;

export const AddNewBankAccount = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: 8px;
  padding: 16px;
  &:hover {
    background-color: ${({ theme }) => `${theme.colors.primary.lemon_20}`};
    & > span {
      color: ${({ theme }) => theme.colors.primary.dark_green_80};
    }

    ${CareRightIcon} {
      color: ${({ theme }) => theme.colors.primary.dark_green_80};
    }

    ${BankIcon} {
      color: ${({ theme }) => theme.colors.primary.green_light};
    }

    ${ContentBankIcon} {
      background-color: ${({ theme }) => theme.colors.neutral.darkest};
    }
  }
`;

export const AddNewBankAccountText = styled(TextBase)`
  font-weight: 500;
`;

export const DropdownTrigger = styled(DropdownBase.Trigger)<IProviderSelectProps>`
  display: flex;
  align-items: center;
  gap: 8px;
  height: ${({ isselectedbank }) => (isselectedbank ? '63px' : '47px')};
  background-color: ${({ theme }) => theme.colors.neutral.white};
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  padding: 16px;
  position: relative;
  text-align: left;
  font-size: 1.4rem;
  font-weight: 500;
  transition: all 0.3s;
  width: 100%;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.neutral.darker};

  &:after {
    content: url(${selectArrow});
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: ${({ theme }) => theme.spacing.small_16};
  }
`;

export const SelectedBankWrapper = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;
  align-items: flex-start;
`;

export const SelectedBankTextBase = styled(Text)`
  font-size: 1.2rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.neutral.base};
`;
export const SelectedBankAccountGroup = styled.div``;

export const AccountNumber = styled(SelectedBankTextBase)``;
export const RoutingNumber = styled(SelectedBankTextBase)``;

export const BankName = styled(TextBase)`
  font-size: 1.4rem;
`;

export const Dropdown = styled(DropdownBase)`
  width: 100%;
`;

export const ModalAFooter = styled.div`
  display: flex;
  padding: 16px 32px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  align-self: flex-end;
  gap: 16px;
  background-color: ${({ theme }) => theme.colors.neutral.gray_dark};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

export const ButtonsAction = styled.div`
  display: flex;
  position: relative;
  gap: 16px;
  margin-left: auto;
`;

export const SaveButton = styled(ButtonBase)``;

export const CancelButton = styled(ButtonBase)`
  background-color: ${({ theme }) => theme.colors.neutral.white};
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  > span {
    color: ${({ theme }) => theme.colors.neutral.darker};
  }
`;
