import styled from 'styled-components/macro';

import HeadingBase from '@components/common/DataDisplay/Heading';
import TextBase from '@components/common/DataDisplay/Text';

export const QuantityText = styled(TextBase)`
  font-size: 1.4rem;
`;

export const Description = styled(TextBase)`
  font-size: 1.6rem;
`;

export const Title = styled(HeadingBase)``;

export const GoBackButton = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

export const TitleAndQuantityWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 8px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
