import { AxiosResponse } from 'axios';

import { dashboardInstance } from '@services/common/dashboardInstance';
import {
  IBankAccountService,
  ICreateBankAccountProps,
  ICreateBankAccountResponse,
  IDeleteBankAccountProps,
  IDeleteBankAccountResponse,
} from '@domain/interfaces/hooks/services/bankAccount/IBankAccountService';

export const useBankAccountService = (): IBankAccountService => {
  const createBankAccount = async ({
    accountId,
    payload,
  }: ICreateBankAccountProps): Promise<AxiosResponse<ICreateBankAccountResponse>> => {
    return dashboardInstance.post(`/accounts/${accountId}/bank_accounts`, payload, {
      headers: {
        'x-zouti-account': accountId,
      },
    });
  };

  const deleteBankAccount = async ({
    accountId,
    bankAccountId,
  }: IDeleteBankAccountProps): Promise<AxiosResponse<IDeleteBankAccountResponse>> => {
    return dashboardInstance.delete(`/accounts/${accountId}/bank_accounts/${bankAccountId}`, {
      headers: {
        'x-zouti-account': accountId,
      },
    });
  };

  return { createBankAccount, deleteBankAccount };
};
