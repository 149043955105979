import styled from 'styled-components/macro';

import CheckboxBase from '@components/common/Inputs/Checkbox';
import InputGroupBase from '@components/common/Inputs/InputGroup';
import LabelBase from '@components/common/DataDisplay/Label';
import SwitchBase from '@components/common/Inputs/Switch';
import TextFieldBase from '@components/common/Inputs/TextField';
import ButtonBase from '@components/common/Inputs/Button';
import TextBase from '@components/common/DataDisplay/Text';

export const SubmitButton = styled(ButtonBase)``;

export const CancelButton = styled(ButtonBase)`
  & > span {
    color: ${({ theme }) => theme.colors.neutral.darkest};
  }
`;

export const PeriodButton = styled.button`
  background-color: unset;
  box-shadow: unset;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const PeriodWrapper = styled.div`
  display: flex;
  align-content: center;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  width: fit-content;
  border-radius: ${({ theme }) => theme.borderRadius.small};

  & > div:nth-child(2) {
    border: unset;
    width: 100px;
    border-radius: unset;
    border-left: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
    border-right: 1px solid ${({ theme }) => theme.colors.neutral.lightest};

    & > input {
      text-align: center;
    }
  }
`;

export const CurrencyLabel = styled.div`
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  font-family: ${({ theme }) => theme.fonts.text};
  font-weight: 700;
  color: ${({ theme }) => theme.colors.neutral.darkest};
`;

export const ValueWrapper = styled.div`
  display: flex;
  align-content: center;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  width: fit-content;
  border-radius: ${({ theme }) => theme.borderRadius.small};

  & > div:nth-child(2) {
    border: unset;
    width: 140px;
    border-radius: unset;
    border-left: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
    border-right: 1px solid ${({ theme }) => theme.colors.neutral.lightest};

    & > input {
      text-align: right;
    }
  }
`;

export const Text = styled(TextBase)`
  font-size: 1.4rem;
`;

export const SwitchAndText = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
`;

export const ValueInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: flex-end;
`;

export const Input = styled(TextFieldBase)``;

export const Switch = styled(SwitchBase)``;

export const Label = styled(LabelBase)``;

export const InputGroup = styled(InputGroupBase)``;

export const Form = styled.form`
  width: 100%;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-color: white;
  padding: 24px;
  border-radius: ${({ theme }) => theme.borderRadius.small};
  margin-bottom: 24px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
`;

export const Checkbox = styled(CheckboxBase)``;

export const CheckboxLabel = styled(LabelBase)`
  margin: 0;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.neutral.darkest};
  cursor: pointer;
`;

export const CheckboxGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
