import fonts from '@styles/themes/common/fonts';
import zIndex from '@styles/themes/common/zIndex';
import borderRadius from '@styles/themes/common/borderRadius';
import shadow from '@styles/themes/common/shadow';
import spacing from '@styles/themes/common/spacing';
import colors from '@styles/themes/light/colors';
import typography from '../common/typography';

export default {
  fonts,
  zIndex,
  colors,
  borderRadius,
  typography,
  shadow,
  spacing,
};
