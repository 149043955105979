import React from 'react';
import { Controller } from 'react-hook-form';

import { useNewPixel } from '@store/common/pixel/NewPixelContext';
import Text from '@components/common/DataDisplay/Text';

import * as S from './styles';

const TikTokForm: React.FC = () => {
  const { control, errors, register } = useNewPixel();

  const pixelName = register('pixelName');
  const pixelId = register('pixelId');

  return (
    <S.Wrapper>
      <S.InputGroup>
        <S.Label>Nome do Pixel</S.Label>
        <S.Input placeholder="Ex: Campanha de Remarketing" {...pixelName} type="text" />
        {errors?.pixelName && <Text isFeedbackError>{errors?.pixelName?.message}</Text>}
      </S.InputGroup>

      <S.InputGroup>
        <S.Label>ID do pixel</S.Label>
        <S.Input placeholder="Ex: ABCDEFGHIJK12345" {...pixelId} type="text" />
        {errors?.pixelId && <Text isFeedbackError>{errors?.pixelId?.message}</Text>}
      </S.InputGroup>

      <S.CheckboxGroup>
        <Controller
          control={control}
          name="send_credit_card_purchase"
          render={({ field }) => (
            <S.Checkbox
              value={field?.value?.toString()}
              onChange={() => field?.onChange(!field.value)}
            />
          )}
        />
        <S.CheckboxLabel htmlFor="send_credit_card_purchase">
          Marcar compras no cartão
        </S.CheckboxLabel>
      </S.CheckboxGroup>

      <S.CheckboxGroup>
        <Controller
          control={control}
          name="send_pix_purchase"
          render={({ field }) => (
            <S.Checkbox
              value={field?.value?.toString()}
              onChange={() => field?.onChange(!field.value)}
            />
          )}
        />
        <S.CheckboxLabel htmlFor="send_pix_purchase">Marcar compras no pix</S.CheckboxLabel>
      </S.CheckboxGroup>
      <S.CheckboxGroup>
        <Controller
          control={control}
          name="send_boleto_purchase"
          render={({ field }) => (
            <S.Checkbox
              value={field?.value?.toString()}
              onChange={() => field?.onChange(!field.value)}
            />
          )}
        />
        <S.CheckboxLabel htmlFor="send_boleto_purchase">Marcar compras no boleto</S.CheckboxLabel>
      </S.CheckboxGroup>
    </S.Wrapper>
  );
};

export default TikTokForm;
