import styled from 'styled-components/macro';
import { HeadingBase } from '@components/common/DataDisplay/Heading/styles';
import TextBase from '@components/common/DataDisplay/Text';
import ButtonBase from '@components/common/Inputs/Button';

export const Form = styled.form``;

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.neutral.white};
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  border-radius: 4px;
  padding: 24px;
`;
export const StyleEditor = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
`;
export const EditorSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Title = styled(HeadingBase)`
  color: ${({ theme }) => theme.colors.neutral.darkest};
`;

export const Divider = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.neutral.lightest};
  margin: 16px 0 24px 0;
`;

export const FaviconSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ContainerDropzone = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 24px;
  border: 2px dashed ${({ theme }) => theme.colors.primary.dark_green_40};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  background-color: ${({ theme }) => theme.colors.primary.lemon_20};
  transition: border 0.24s ease-in-out;
  cursor: pointer;

  svg {
    color: ${({ theme }) => theme.colors.primary.dark_green_40};
  }
`;

export const TextInfoAndTooltipWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const TextDropzone = styled(TextBase)`
  font-weight: 500;
`;
export const AcceptFormat = styled(TextBase)`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.neutral.dark};
`;

export const PreviewCardInfo = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(1, 1fr);
  border-radius: 4px;
  padding: 5px 24px;
  margin-bottom: 8px;
  background-color: ${({ theme }) => theme.colors.neutral.gray_white};

  & > img {
    grid-column: 1 / span 5;
    grid-row: 1;
  }

  & > span {
    grid-column: 1 / span 5;
    grid-row: 1;
    margin-left: 40px;
  }

  & > svg {
    cursor: pointer;
    align-self: center;
    justify-self: end;
    padding: 5px;
    color: ${({ theme }) => theme.colors.primary.dark_green_40};
    grid-column: 6;
    grid-row: 1;

    &:active {
      filter: brightness(0.9);
    }

    &:hover {
      background-color: ${({ theme }) => theme.colors.primary.lemon_20};
    }
  }
`;

export const Image = styled.img`
  width: 32px;
  height: 32px;
`;

export const FileName = styled(TextBase)`
  color: ${({ theme }) => theme.colors.neutral.darker};
  font-weight: 500;
`;

export const TextInfo = styled(TextBase)`
  font-size: 1.2rem;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 24px;
  gap: 16px;
`;

export const SubmitButton = styled(ButtonBase)``;

export const CancelButton = styled(ButtonBase)`
  & > span {
    color: ${({ theme }) => theme.colors.neutral.dark} !important;
  }
`;
