import { AxiosResponse } from 'axios';

import {
  IActivateOrderBumpProps,
  ICreateOrderBumpProps,
  ICreateOrderBumpResponse,
  IDeleteOrderBumpProps,
  IGetOrderBumpProps,
  IGetOrderBumpResponse,
  IInactivateOrderBumpProps,
  IListOrderBumpsProps,
  IListOrderBumpsResponse,
  IOrderBumpService,
  IUpdateOrderBumpProps,
  IUpdateOrderBumpResponse,
} from '@domain/interfaces/hooks/services/orderBump/IOrderBumpService';

import { dashboardInstance } from '@services/common/dashboardInstance';

export const useOrderBumpService = (): IOrderBumpService => {
  const listOrderBumps = async ({
    accountId,
    checkoutId,
  }: IListOrderBumpsProps): Promise<AxiosResponse<IListOrderBumpsResponse>> => {
    return dashboardInstance.get(`accounts/${accountId}/checkouts/${checkoutId}/orderbumps`, {
      headers: {
        'x-zouti-account': accountId,
      },
    });
  };

  const getOrderBump = async ({
    orderBumpId,
    accountId,
    checkoutId,
  }: IGetOrderBumpProps): Promise<AxiosResponse<IGetOrderBumpResponse>> => {
    return dashboardInstance.get(
      `accounts/${accountId}/checkouts/${checkoutId}/orderbumps/${orderBumpId}`,
      {
        headers: {
          'x-zouti-account': accountId,
        },
      },
    );
  };

  const createOrderBump = async ({
    payload,
    accountId,
    checkoutId,
  }: ICreateOrderBumpProps): Promise<AxiosResponse<ICreateOrderBumpResponse>> => {
    return dashboardInstance.post(
      `accounts/${accountId}/checkouts/${checkoutId}/orderbumps`,
      { ...payload },
      {
        headers: {
          'x-zouti-account': accountId,
        },
      },
    );
  };

  const updateOrderBump = async ({
    orderBumpId,
    payload,
    accountId,
    checkoutId,
  }: IUpdateOrderBumpProps): Promise<AxiosResponse<IUpdateOrderBumpResponse>> => {
    return dashboardInstance.put(
      `accounts/${accountId}/checkouts/${checkoutId}/orderbumps/${orderBumpId}`,
      { ...payload },
      {
        headers: {
          'x-zouti-account': accountId,
        },
      },
    );
  };

  const activateOrderBump = async ({
    orderBumpId,
    accountId,
    checkoutId,
  }: IActivateOrderBumpProps): Promise<AxiosResponse<void>> => {
    return dashboardInstance.put(
      `accounts/${accountId}/checkouts/${checkoutId}/orderbumps/${orderBumpId}/activate`,
      {},
      {
        headers: {
          'x-zouti-account': accountId,
        },
      },
    );
  };

  const inactivateOrderBump = async ({
    orderBumpId,
    accountId,
    checkoutId,
  }: IInactivateOrderBumpProps): Promise<AxiosResponse<void>> => {
    return dashboardInstance.put(
      `accounts/${accountId}/checkouts/${checkoutId}/orderbumps/${orderBumpId}/inactivate`,
      {},
      {
        headers: {
          'x-zouti-account': accountId,
        },
      },
    );
  };

  const deleteOrderBump = async ({
    orderBumpId,
    accountId,
    checkoutId,
  }: IDeleteOrderBumpProps): Promise<AxiosResponse<void>> => {
    return dashboardInstance.delete(
      `accounts/${accountId}/checkouts/${checkoutId}/orderbumps/${orderBumpId}`,
      {
        headers: {
          'x-zouti-account': accountId,
        },
      },
    );
  };

  return {
    listOrderBumps,
    getOrderBump,
    createOrderBump,
    updateOrderBump,
    deleteOrderBump,
    activateOrderBump,
    inactivateOrderBump,
  };
};
