import styled from 'styled-components/macro';

import ModalBase from '@components/common/DataDisplay/Modal';
import Typography from '@components/common/DataDisplay/Typography';

export const Modal = styled(ModalBase)`
  max-width: 520px;
`;

export const Content = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.darker};
`;
