import React from 'react';

import { useListOrders } from '@store/common/order/ListOrders';
import { useAnalytics } from '@store/context/common/AnalyticsContext';
import { useUserAccount } from '@store/context/common/UserAccount';
import { useUserCheckout } from '@store/context/common/UserCheckout';

import EmptyData from '@components/common/utils/EmptyData';

import { IAccount } from '@domain/interfaces/common/account/IAccount';
import { EBusinessModel } from '@domain/enums/common/onboarding/EUser';

import TableRow from './TableRow';

import * as S from './styles';

const Body: React.FC = () => {
  const { currentUserAccount } = useUserAccount();
  const { orders } = useListOrders();
  const { analytics } = useAnalytics();
  const { currentUserCheckout } = useUserCheckout();

  const isInfoproduct = currentUserCheckout?.business_type === EBusinessModel.INFOPRODUCT;

  React.useEffect(() => {
    analytics.track('Orders Page Viewed');
  }, [analytics]);

  return (
    <S.Wrapper>
      {Boolean(orders.length) && (
        <S.Table>
          <S.Table.Header>
            <S.Table.Row>
              <S.Table.Head>ID / Data</S.Table.Head>

              <S.Table.Head>Cliente</S.Table.Head>

              <S.Table.Head>Forma de pagamento</S.Table.Head>

              <S.Table.Head>Status</S.Table.Head>

              <S.Table.Head>Produto</S.Table.Head>

              {!isInfoproduct && <S.Table.Head>Envio</S.Table.Head>}

              <S.Table.Head />
            </S.Table.Row>
          </S.Table.Header>

          <S.Table.Body>
            {orders.map(order => {
              return (
                <TableRow
                  order={order}
                  account={currentUserAccount || ({} as IAccount)}
                  key={order.order.id}
                />
              );
            })}
          </S.Table.Body>
        </S.Table>
      )}

      {Boolean(!orders.length) && (
        <EmptyData
          title="Nenhum produto encontrado"
          description="Para visualizar seus produtos primeiro adicione sua loja à Zouti"
          buttonText="Adicionar loja"
        />
      )}
    </S.Wrapper>
  );
};

export default Body;
