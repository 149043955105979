import React from 'react';

import { NewWebhookProvider } from '@store/common/webhook/NewWebhookContext';

import Wrapper from '@components/pages/Dashboard/Webhook/NewWebhook';

const NewWebhook: React.FC = () => {
  return (
    <NewWebhookProvider>
      <Wrapper />
    </NewWebhookProvider>
  );
};

export default NewWebhook;
