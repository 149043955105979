import React from 'react';
import { EnvelopeSimple } from 'phosphor-react';

import { useRecoveryPassword } from '@store/context/public/RecoveryPassword/RecoveryPasswordContext';

import Text from '@components/common/DataDisplay/Text';

import * as S from './styles';

const Form: React.FC = () => {
  const { register, handleSubmit, onSubmit, errors, isLoading } = useRecoveryPassword();

  const emailRegister = register('email');

  return (
    <S.Form onSubmit={handleSubmit(onSubmit)}>
      <S.InputGroup>
        <S.Label>E-mail</S.Label>
        <S.TextField
          {...emailRegister}
          type="email"
          placeholder="Digite o seu e-mail"
          isError={Boolean(errors.email)}
          icon={EnvelopeSimple}
        />
        {errors.email && <Text isFeedbackError>{errors.email.message}</Text>}
      </S.InputGroup>

      <S.SubmitButton type="submit" isLoading={isLoading}>
        Enviar
      </S.SubmitButton>
    </S.Form>
  );
};

export default Form;
