import { AxiosResponse } from 'axios';

import {
  IAuthService,
  ICreateUserProps,
  IForgotPasswordProps,
  IGetUserProps,
  IResetPasswordProps,
} from '@domain/interfaces/hooks/services/auth/IAuthService';
import { ICreateUserResponse, IGetUserResponse } from '@domain/interfaces/common/auth/IAuth';

import { authInstance } from '@services/common/authInstance';

export const useAuthService = (): IAuthService => {
  const getUser = async ({ payload }: IGetUserProps): Promise<AxiosResponse<IGetUserResponse>> => {
    return authInstance.post(`/auth/login`, { ...payload });
  };

  const createUser = async ({
    payload,
  }: ICreateUserProps): Promise<AxiosResponse<ICreateUserResponse>> => {
    return authInstance.post(`/users`, { ...payload });
  };

  const forgotPassword = async ({
    payload,
  }: IForgotPasswordProps): Promise<AxiosResponse<void>> => {
    return authInstance.post(`/auth/forgot-password`, { ...payload });
  };

  const resetPassword = async ({ payload }: IResetPasswordProps): Promise<AxiosResponse<void>> => {
    return authInstance.post(`/auth/reset-password`, { ...payload });
  };

  const getUserMe = async (): Promise<AxiosResponse<any>> => {
    return authInstance.get(`/users/me`);
  };

  const removeUserSession = async (): Promise<AxiosResponse<void>> => {
    return authInstance.delete(`/sessions/me`);
  };

  const setZoutiAccountHeader = (zoutiAccount: string): void => {
    authInstance.defaults.headers.common['x-zouti-account'] = zoutiAccount;
  };

  const setToken = (token: string): void => {
    authInstance.defaults.headers.common.access_key = token;
  };

  return {
    getUser,
    createUser,
    getUserMe,
    removeUserSession,
    setZoutiAccountHeader,
    setToken,
    forgotPassword,
    resetPassword,
  };
};
