import { AxiosResponse } from 'axios';

import {
  ICreatePixelProps,
  ICreatePixelResponse,
  IUpdatePixelProps,
  IUpdatePixelResponse,
  IDeletePixelProps,
  IDeletePixelResponse,
  IPixelService,
  IInactivatePixelProps,
  IActivatePixelProps,
} from '@domain/interfaces/hooks/services/pixel/IPixelService';

import { dashboardInstance } from '@services/common/dashboardInstance';

export const usePixelService = (): IPixelService => {
  const createPixel = async ({
    payload,
    accountId,
    checkoutId,
  }: ICreatePixelProps): Promise<AxiosResponse<ICreatePixelResponse>> => {
    return dashboardInstance.post(
      `accounts/${accountId}/checkouts/${checkoutId}/pixels`,
      { ...payload },
      {
        headers: {
          'x-zouti-account': accountId,
        },
      },
    );
  };

  const updatePixel = async ({
    payload,
    pixelId,
    accountId,
    checkoutId,
  }: IUpdatePixelProps): Promise<AxiosResponse<IUpdatePixelResponse>> => {
    return dashboardInstance.put(
      `accounts/${accountId}/checkouts/${checkoutId}/pixels/${pixelId}`,
      { ...payload },
      {
        headers: {
          'x-zouti-account': accountId,
        },
      },
    );
  };

  const deletePixel = async ({
    pixelId,
    accountId,
    checkoutId,
  }: IDeletePixelProps): Promise<AxiosResponse<IDeletePixelResponse>> => {
    return dashboardInstance.delete(
      `accounts/${accountId}/checkouts/${checkoutId}/pixels/${pixelId}`,
      {
        headers: {
          'x-zouti-account': accountId,
        },
      },
    );
  };

  const inactivatePixel = async ({
    pixelId,
    accountId,
    checkoutId,
  }: IInactivatePixelProps): Promise<AxiosResponse<IUpdatePixelResponse>> => {
    return dashboardInstance.put(
      `accounts/${accountId}/checkouts/${checkoutId}/pixels/${pixelId}/inactivate`,
      {
        headers: {
          'x-zouti-account': accountId,
        },
      },
    );
  };
  const activatePixel = async ({
    pixelId,
    accountId,
    checkoutId,
  }: IActivatePixelProps): Promise<AxiosResponse<IUpdatePixelResponse>> => {
    return dashboardInstance.put(
      `accounts/${accountId}/checkouts/${checkoutId}/pixels/${pixelId}/activate`,
      {
        headers: {
          'x-zouti-account': accountId,
        },
      },
    );
  };

  return { createPixel, updatePixel, deletePixel, activatePixel, inactivatePixel };
};
