import styled from 'styled-components/macro';

import ButtonBase from '@components/common/Inputs/Button';

export const Wrapper = styled.div`
  padding-bottom: 24px;
  max-width: 1024px;
  margin: 0 auto;
  width: 100%;
`;

export const TitleAndButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const NewDomainButton = styled(ButtonBase)`
  align-self: flex-start;
  & > span {
    color: ${({ theme }) => theme.colors.neutral.darker};
  }
`;
