import React from 'react';
import { PlusCircle } from 'phosphor-react';
import { useTheme } from 'styled-components/macro';
import { useNavigate, useParams } from 'react-router-dom';

import { EButtonVariant } from '@domain/enums/components/common/EButton';

import { useListWebhooks } from '@store/common/webhook/ListWebhooksContext';
import PageHeader from '@components/common/utils/PageHeader';

import * as S from './styles';

const Header: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { accountId, checkoutId } = useParams();
  const { webhooksLength } = useListWebhooks();
  const quantityText =
    webhooksLength > 1 ? `${webhooksLength} resultados` : `${webhooksLength} resultado`;

  const onNewWebhookClick = (): void => {
    navigate(`/${accountId}/${checkoutId}/dashboard/apps/webhooks/new`);
  };

  return (
    <S.Wrapper>
      <PageHeader
        title="Webhooks"
        description="Gerencie os webhooks de sua loja."
        quantityText={quantityText}
        goBackButton
      />

      <S.NewWebhookButton variant={EButtonVariant.SECONDARY} onClick={onNewWebhookClick}>
        <PlusCircle size={20} color={theme.colors.neutral.darker} weight="bold" /> Novo Webhook
      </S.NewWebhookButton>
    </S.Wrapper>
  );
};

export default Header;
