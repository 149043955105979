import React from 'react';
import { useTheme } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { CircleSpinner } from 'react-spinners-kit';
import { useToast } from '@store/context/common/ToastContext';

import { useQuery } from '@hooks/common/useQuery';
import { useConfig } from '@store/context/common/ConfigContext';
import { useSignUpUserInvited } from '@store/context/public/signUpUserInvited/SignUpUserInvitedContext';

import * as S from './styles';

const AcceptInviteUserWithAccount: React.FC = () => {
  const theme = useTheme();
  const { toast } = useToast();
  const query = useQuery();
  const { user } = useConfig();
  const navigate = useNavigate();
  const { acceptCurrentInvite } = useSignUpUserInvited();

  const membershipInviteToken = query?.get('membership_invite_token');

  const isLoggedUser = Boolean(user);

  React.useEffect(() => {
    if (membershipInviteToken && !isLoggedUser) {
      navigate(`/?membership_invite_token=${membershipInviteToken}`);
      return;
    }

    if (membershipInviteToken) {
      acceptCurrentInvite(membershipInviteToken);
    }
  }, [acceptCurrentInvite, membershipInviteToken, isLoggedUser, navigate, toast]);

  return (
    <S.Wrapper>
      <CircleSpinner size={64} color={theme.colors.primary.dark_green_40} />
    </S.Wrapper>
  );
};

export default AcceptInviteUserWithAccount;
