import React from 'react';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import * as S from './styles';

const MetricsSkeleton: React.FC = () => {
  return (
    <S.Wrapper>
      <Skeleton width="180px" height="15px" />
      <S.WrapperItems>
        {Array.from({ length: 2 }).map((__, i) => (
          <S.ChartBarWrapper key={i.toFixed()}>
            <S.ContainerIcon baseColor="#FBFBFB" width="48px" height="48px" />
            <S.ChartBar width="189px" height="287px" />
            <S.Text baseColor="#FBFBFB" width="80px" height="8px" />
            <S.ChartBody>
              {Array.from({ length: 10 }).map((_, index) => (
                <S.Graphic key={index.toFixed()}>
                  <Skeleton
                    baseColor="#FBFBFB"
                    height={`${Math.random() * 100 + 50}px`}
                    width="8px"
                  />
                </S.Graphic>
              ))}
            </S.ChartBody>
          </S.ChartBarWrapper>
        ))}
      </S.WrapperItems>
    </S.Wrapper>
  );
};

export default MetricsSkeleton;
