import styled from 'styled-components/macro';
import {
  margin,
  color,
  layout,
  typography,
  MarginProps,
  ColorProps,
  LayoutProps,
  TypographyProps,
} from 'styled-system';

type ITypographyStyleProps = MarginProps & ColorProps & LayoutProps & TypographyProps;

export const Typography = styled('h1')<ITypographyStyleProps>(margin, color, layout, typography);
