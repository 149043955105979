import styled, { css } from 'styled-components/macro';

import { IOptionProps } from '@domain/interfaces/components/common/ITab';

export const OptionButton = styled.button<IOptionProps>`
  position: relative;
  margin-bottom: 12px;
  font-family: ${({ theme }) => theme.fonts.heading};
  font-weight: 500;
  padding: 0 16px;
  font-size: 14px;
  background-color: transparent;

  color: ${({ theme }) => theme.colors.neutral.darker};
  cursor: pointer;
  transition: all 0.3s;

  &::after {
    content: '';
    position: absolute;
    height: 3px;
    width: 100%;
    top: 29px;
    left: 0;
    border-bottom: 3px solid
      ${({ isSelected, theme }) =>
        isSelected ? theme.colors.primary.dark_green_60 : 'transparent'};
  }

  ${({ isSelected, theme }) =>
    isSelected &&
    css`
      color: ${theme.colors.primary.dark_green_60};
    `}
`;
