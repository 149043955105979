import styled from 'styled-components/macro';

import TypographyBase from '@components/common/DataDisplay/Typography';
import ChipBase from '@components/common/DataDisplay/Chip';
import VideoModalBase from '@components/common/DataDisplay/VideoModal';

export const TutorialWrapper = styled.div`
  width: 100%;
  height: 164px;
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lighter};
  padding: 16px;
  gap: 24px;
  box-sizing: border-box;
`;

export const TutorialBody = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TutorialChip = styled(ChipBase)`
  margin-bottom: 16px;
`;

export const TutorialTitle = styled(TypographyBase)`
  color: ${({ theme }) => theme.colors.neutral.darkest};
  margin-bottom: 32px;
`;

export const TutorialText = styled(TypographyBase)`
  color: ${({ theme }) => theme.colors.primary.green_lemon};
  text-align: left;
`;

export const PlayWrapper = styled.button`
  width: 100%;
  max-width: 130px;
  border: unset;
  padding: unset;
  background: unset;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    filter: brightness(0.9);
  }

  &:active {
    filter: brightness(0.8);
  }
`;

export const TutorialPlayImage = styled.img`
  width: 100%;
`;

export const VideoModal = styled(VideoModalBase)``;
