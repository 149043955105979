import * as Yup from 'yup';

export const bankAccountSchema = Yup.object().shape({
  type: Yup.string().required('Este campo é obrigatório!'),
  holderName: Yup.string().required('Este campo é obrigatório!'),
  accountDigit: Yup.string()
    .max(1, 'O dígito da conta deve ter no máximo 1 dígito!')
    .required('Este campo é obrigatório!'),
  holderDocument: Yup.string().required('Este campo é obrigatório!'),
  bankCode: Yup.string().required('Este campo é obrigatório!'),
  routingNumber: Yup.string()
    .matches(/^\d{1,4}$/, 'O número da agência deve ter entre 1 e 4 dígitos!')
    .required('Este campo é obrigatório!'),
  accountNumber: Yup.string()
    .matches(/^\d{3,10}$/, 'O número da conta deve ter entre 3 e 10 dígitos!')
    .required('Este campo é obrigatório!'),
});
