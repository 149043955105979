import styled from 'styled-components/macro';

import TableBase from '@components/common/utils/Table';
import SwitchBase from '@components/common/Inputs/Switch';
import TextBase from '@components/common/DataDisplay/Text';

export const Table = styled(TableBase)``;

export const Switch = styled(SwitchBase)``;

export const TextAndSubTextWrapper = styled.div`
  display: grid;
  max-width: 500px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const IconTextWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
`;

export const Text = styled(TextBase)`
  overflow: hidden;
  text-overflow: ellipsis;

  color: ${({ theme }) => theme.colors.neutral.darkest};
  font-size: 1.4rem;
`;

export const SubText = styled(TextBase)`
  overflow: hidden;
  text-overflow: ellipsis;

  color: ${({ theme }) => theme.colors.neutral.dark};
  font-size: 1.2rem;
`;

export const Icon = styled.img`
  width: 24px;
  height: 24px;
`;

export const ButtonActionsWrapper = styled.div`
  min-width: 79.5px;
  justify-content: right;
  display: flex;
  gap: 8px;
`;
