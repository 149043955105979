import React from 'react';
import useSWR from 'swr';
import { useParams } from 'react-router-dom';

import { ISwr } from '@domain/interfaces/common/swr/ISwr';

import { DASHBOARD_API_URL } from '@constants/services/apiUrl';

import { dashboardInstance } from '@services/common/dashboardInstance';

import { useToast } from '@store/context/common/ToastContext';

import { useErrorHandler } from '@store/context/common/ErrorHandlerContext';
import { useAnalytics } from '@store/context/common/AnalyticsContext';
import { useBankAccountService } from '@hooks/services/BankAccount/useBankAccountService';
import { IBankAccount } from '@domain/interfaces/common/bankAccount/IBankAccount';
import { IGetBankAccountProvider } from '@domain/interfaces/store/common/BankAccount/IGetBankAccountProvider';
import { IListBankAccountResponse } from '@domain/interfaces/hooks/services/bankAccount/IBankAccountService';

const GetBankAccountContext = React.createContext<IGetBankAccountProvider | null>(null);

export const GetBankAccountProvider: React.FC = ({ children }) => {
  const { toast } = useToast();
  const { accountId } = useParams();
  const { analytics } = useAnalytics();
  const { handleError } = useErrorHandler();
  const { deleteBankAccount } = useBankAccountService();

  const [isOpenModal, setIsOpenModal] = React.useState<{ isOpen: boolean; bankAccountId?: string }>(
    {
      isOpen: false,
      bankAccountId: '',
    },
  );
  const [page, setPage] = React.useState<number>(0);
  const [totalPages, setTotalPages] = React.useState<number>(1);
  const [bankAccountsTotal, setBankAccountsTotal] = React.useState<number>(0);
  const [bankAccounts, setBankAccounts] = React.useState<Array<IBankAccount>>([]);
  const [isDeletingBankAccount, setIsDeletingBankAccount] = React.useState<boolean>(false);

  dashboardInstance.defaults.headers.common['x-zouti-account'] = accountId;

  const BANK_ACCOUNT_URL = `${DASHBOARD_API_URL}/accounts/${accountId}/bank_accounts?page=${
    page + 1
  }&limit=10`;

  const { data, isLoading, isValidating, mutate, error } = useSWR<ISwr<IListBankAccountResponse>>(
    BANK_ACCOUNT_URL,
    dashboardInstance,
  );

  const handlePageChange = React.useCallback(selected => {
    setPage(selected);
  }, []);

  React.useEffect(() => {
    if (error) {
      handleError(error);
    }

    if (data?.data) {
      setBankAccounts(data.data.object);
      setTotalPages(data.data.total_pages);
      setBankAccountsTotal(data.data.total);
    }
  }, [data, handleError, error]);

  const deleteCurrentBankAccount = React.useCallback(
    async bankAccountId => {
      setIsDeletingBankAccount(true);

      try {
        await deleteBankAccount({ accountId, bankAccountId });

        analytics.track('Bank Account Deleted');

        toast.success('Conta bancária deletada com sucesso!');
        await mutate();
        setIsOpenModal({ isOpen: false, bankAccountId: '' });
        setIsDeletingBankAccount(false);
      } catch (errorDeleteBankAccount) {
        toast.error('Erro ao deletar a conta bancária!');
        setIsDeletingBankAccount(false);
        handleError(errorDeleteBankAccount);
      }
    },
    [deleteBankAccount, handleError, mutate, accountId, toast, analytics],
  );

  const isLoadingBankAccount = isLoading || isValidating;
  const isBankAccountError = Boolean(error);

  return (
    <GetBankAccountContext.Provider
      value={{
        page,
        totalPages,
        bankAccounts,
        handlePageChange,
        bankAccountsTotal,
        isDeletingBankAccount,
        deleteCurrentBankAccount,
        isLoadingBankAccount,
        isBankAccountError,
        setIsOpenModal,
        isOpenModal,
      }}
    >
      {children}
    </GetBankAccountContext.Provider>
  );
};

export const useGetBankAccount = (): IGetBankAccountProvider => {
  const context = React.useContext(GetBankAccountContext);

  if (!context) {
    throw new Error('useGetBankAccount must be used within GetBankAccountProvider');
  }

  return context;
};
