import styled from 'styled-components';

import TextBase from '@components/common/DataDisplay/Text';
import ButtonBase from '@components/common/Inputs/Button';

export const SignOutButton = styled(ButtonBase)`
  width: fit-content;
`;

export const Description = styled(TextBase)`
  font-size: 1.5rem;
  font-weight: 500;
`;

export const Title = styled(TextBase)`
  font-size: 2rem;
  font-weight: 700;
`;

export const IconWrapper = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  background-color: #f2f5d2;
`;

export const Wrapper = styled.div`
  max-width: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px 16px;
`;
