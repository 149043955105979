import React from 'react';

import Wrapper from '@components/pages/Dashboard/GA4/Wrapper';
import { GA4Provider } from '@store/common/GA4/GA4Context';

const GA4: React.FC = () => {
  return (
    <GA4Provider>
      <Wrapper />
    </GA4Provider>
  );
};

export default GA4;
