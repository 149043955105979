import styled from 'styled-components/macro';
import { WarningCircle } from 'phosphor-react';

import TextBase from '@components/common/DataDisplay/Text';
import PIX from '@components/icons/Pix';

export const Card = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  padding: 10px 16px;
  background-color: ${({ theme }) => theme.colors.warning.lighter_10};
`;

export const CardText = styled(TextBase)`
  font-size: 1.2rem;
  font-weight: 500;
  flex-wrap: wrap;
  color: ${({ theme }) => theme.colors.warning.dark};
`;

export const WarningCircleIcon = styled(WarningCircle).attrs({
  size: 20,
  weight: 'bold',
})`
  color: ${({ theme }) => theme.colors.warning.dark};
`;

export const PIXIcon = styled(PIX).attrs({
  size: '22',
  fillColor: '#936802',
})``;
