import React from 'react';

import Editor from './Editor';
import Preview from './Preview';

import * as S from './styles';

const Body: React.FC = () => {
  return (
    <S.Wrapper>
      <Editor />
      <Preview />
    </S.Wrapper>
  );
};

export default Body;
