import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  height: 100%;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  padding-bottom: 24px;
  max-width: 1024px;
`;

export const WrapperContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  background-color: ${({ theme }) => theme.colors.neutral.white};
  max-width: 1024px;
  margin: 0 auto;
`;

export const WrapperInput = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const InputGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
