import styled from 'styled-components/macro';
import { X } from 'phosphor-react';

import ButtonBase from '@components/common/Inputs/Button';
import ModalBase from '@components/common/DataDisplay/Modal';
import TextBase from '@components/common/DataDisplay/Text';

export const Modal = styled(ModalBase)`
  width: 520px;
  min-height: 228px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.neutral.white};
`;

export const ModalHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 32px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
`;

export const TitleAndWarningIconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.small_8};
`;

export const HeaderTitle = styled(TextBase)`
  color: ${({ theme }) => theme.colors.neutral.darkest};
  font-size: 2.3rem;
`;
export const HeaderCloseIcon = styled(X)`
  cursor: pointer;
`;

export const ModalContent = styled(TextBase)`
  padding: 16px 32px;
  font-size: 2rem;
`;
export const ModalAction = styled.div`
  display: flex;
  align-self: flex-end;
  padding: 16px 32px;
  gap: ${({ theme }) => theme.spacing.small_16};
`;

export const ConfirmButton = styled(ButtonBase)`
  background-color: ${({ theme }) => theme.colors.negative.base};
  border: none;
`;

export const CancelButton = styled(ButtonBase)`
  background-color: ${({ theme }) => theme.colors.neutral.white};
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  > span {
    color: ${({ theme }) => theme.colors.neutral.darker};
  }
`;
