import styled from 'styled-components/macro';
import Skeleton from 'react-loading-skeleton';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  padding: 24px;
  gap: 16px;
  background-color: ${({ theme }) => theme.colors.neutral.white};
`;

export const SwitchSkeleton = styled(Skeleton)`
  border-radius: 16px;
  height: 20px;
  width: 50px;
`;

export const EmailTypeSkeleton = styled(Skeleton)`
  height: 20px;
  width: 300px;
`;

export const WrapperSkeleton = styled.div`
  display: flex;
  gap: 200px;
`;
