import React from 'react';
import useSWR from 'swr';

import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { DASHBOARD_API_URL } from '@constants/services/apiUrl';
import { dashboardInstance } from '@services/common/dashboardInstance';

import { ISwr } from '@domain/interfaces/common/swr/ISwr';
import { IReportana } from '@domain/interfaces/common/Reportana/IReportana';
import { EReportanaEvent } from '@domain/enums/common/reportana/EReportana';
import { IReportanaProvider } from '@domain/interfaces/store/common/reportana/IReportanaProvider';

import { reportanaSchema } from '@helpers/validators/pages/dashboard/reportana/reportanaSchema';

import { useToast } from '@store/context/common/ToastContext';
import { useErrorHandler } from '@store/context/common/ErrorHandlerContext';
import { useAnalytics } from '@store/context/common/AnalyticsContext';
import { useReportanaService } from '@hooks/services/reportana/useReportanaService';

const ReportanaContext = React.createContext<IReportanaProvider | null>(null);

export const ReportanaProvider: React.FC = ({ children }) => {
  const { analytics } = useAnalytics();
  const { accountId, checkoutId } = useParams();
  const { toast } = useToast();
  const { handleError } = useErrorHandler();

  const { activateReportana, inactivateReportana, createReportana, updateReportana } =
    useReportanaService();

  const [isSubmittingForm, setIsSubmittingForm] = React.useState<boolean>(false);
  const [reportanaCredential, setReportanaCredential] = React.useState<IReportana | undefined>(
    undefined,
  );
  const [selectedEvents, setSelectedEvents] = React.useState<Array<EReportanaEvent>>([]);

  const REPORTANA_URL = `${DASHBOARD_API_URL}/accounts/${accountId}/checkouts/${checkoutId}/integrations/reportana`;

  const {
    data,
    isLoading: isLoadingReportana,
    error,
    mutate,
  } = useSWR<ISwr>(REPORTANA_URL, dashboardInstance);

  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(reportanaSchema),
  });

  const clientIdRegister = register('client_id');
  const clientSecretRegister = register('client_secret');
  const eventRegister = register('events');

  const onCreateReportanaCredential = React.useCallback(
    async formData => {
      setIsSubmittingForm(true);

      if (selectedEvents?.length === 0) {
        setIsSubmittingForm(false);
        toast.error('Selecione ao menos um evento');
        return;
      }

      const payload = {
        ...formData,
        client_id: formData.client_id.trim(),
        client_secret: formData.client_secret.trim(),
        events: selectedEvents,
      };

      try {
        await createReportana({ accountId, checkoutId, payload });

        analytics.track('Reportana Credential Created');
        toast.success('App instalado com sucesso');

        setIsSubmittingForm(false);
        await mutate();
      } catch (requestError) {
        setIsSubmittingForm(false);
        handleError(requestError);
      }
    },
    [accountId, checkoutId, toast, handleError, mutate, analytics, createReportana, selectedEvents],
  );

  const onReportanaCredentialUpdate = React.useCallback(
    async formData => {
      setIsSubmittingForm(true);

      if (selectedEvents?.length === 0) {
        setIsSubmittingForm(false);
        toast.error('Selecione ao menos um evento');
        return;
      }

      const payload = {
        ...formData,
        events: selectedEvents,
      };

      try {
        await updateReportana({
          accountId,
          checkoutId,
          reportanaId: reportanaCredential?.id,
          payload,
        });

        analytics.track('Reportana Credential Update');
        toast.success('App atualizado com sucesso');

        setIsSubmittingForm(false);
        await mutate();
      } catch (requestError) {
        setIsSubmittingForm(false);
        handleError(requestError);
      }
    },
    [
      accountId,
      checkoutId,
      toast,
      handleError,
      mutate,
      analytics,
      updateReportana,
      reportanaCredential,
      selectedEvents,
    ],
  );

  const onReportanaEventsChange = React.useCallback(
    events => {
      setSelectedEvents(events);
    },
    [setSelectedEvents],
  );

  const onSubmit = React.useCallback(
    async formData => {
      if (data?.data.object) {
        await onReportanaCredentialUpdate(formData);
      } else {
        await onCreateReportanaCredential(formData);
      }
    },
    [data, onReportanaCredentialUpdate, onCreateReportanaCredential],
  );

  const onActivateReportana = React.useCallback(async () => {
    try {
      await activateReportana({ accountId, checkoutId, reportanaId: reportanaCredential?.id });
    } catch (requestError) {
      handleError(requestError);
    }
  }, [accountId, checkoutId, handleError, activateReportana, reportanaCredential]);

  const onInactivateReportana = React.useCallback(async () => {
    try {
      await inactivateReportana({ accountId, checkoutId, reportanaId: reportanaCredential?.id });
    } catch (requestError) {
      handleError(requestError);
    }
  }, [accountId, checkoutId, handleError, inactivateReportana, reportanaCredential]);

  React.useEffect(() => {
    if (error) {
      handleError(error);
    }

    if (data?.data?.object) {
      setReportanaCredential(data.data.object);
      setSelectedEvents(data.data.object.events);
      setValue('client_id', data.data.object.client_id);
      setValue('client_secret', data.data.object.client_secret);
    }
  }, [data, error, handleError, setValue]);

  const isReportanaError = Boolean(error);

  return (
    <ReportanaContext.Provider
      value={{
        isSubmittingForm,
        errors,
        handleSubmit,
        watch,
        clientIdRegister,
        clientSecretRegister,
        eventRegister,
        onActivateReportana,
        onInactivateReportana,
        isReportanaError,
        isLoadingReportana,
        onSubmit,
        reportanaCredential,
        onReportanaEventsChange,
        selectedEvents,
        setSelectedEvents,
      }}
    >
      {children}
    </ReportanaContext.Provider>
  );
};

export const useReportanaContext = (): IReportanaProvider => {
  const context = React.useContext(ReportanaContext);

  if (!context) {
    throw new Error('useReportanaContext must be used within provider');
  }

  return context;
};
