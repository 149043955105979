import React from 'react';

import { useTheme } from 'styled-components';

import { EHeadAlign } from '@domain/enums/components/common/ETable';
import { IBankAccount } from '@domain/interfaces/common/bankAccount/IBankAccount';

import { useGetBankAccount } from '@store/common/BankAccount/GetBankAccountContext';
import { banks } from '@constants/common/banks';
import Badge from './Badge';

import * as S from './styles';

interface ITableRowProps {
  bankAccount: IBankAccount;
}

const TableRow: React.FC<ITableRowProps> = ({ bankAccount }) => {
  const theme = useTheme();

  const { setIsOpenModal } = useGetBankAccount();

  const [isVisibleActions, setIsVisibleActions] = React.useState<boolean>(false);

  const onRowMouseEnter = React.useCallback(() => {
    setIsVisibleActions(true);
  }, []);

  const onRowMouseLeave = React.useCallback(() => {
    setIsVisibleActions(false);
  }, []);

  const { holder_name, bank_code, routing_number, account_number, type, id } = bankAccount;

  const bankName = banks?.find(bank => bank.code?.toString() === bank_code)?.name;

  return (
    <S.Table.Row onMouseEnter={onRowMouseEnter} onMouseLeave={onRowMouseLeave}>
      <S.Table.Data>
        <S.Text>{holder_name}</S.Text>
      </S.Table.Data>

      <S.Table.Data>
        <S.Text>
          {bank_code} - {bankName}
        </S.Text>
      </S.Table.Data>

      <S.Table.Data>
        <S.Text>{routing_number}</S.Text>
      </S.Table.Data>

      <S.Table.Data>
        <S.Text>{account_number}</S.Text>
      </S.Table.Data>

      <S.Table.Data align={EHeadAlign.CENTER}>
        <Badge status={type} />
      </S.Table.Data>

      <S.TableDataAction align={EHeadAlign.RIGHT}>
        <S.Dropdown>
          <S.DropdownTrigger>
            <S.TableAction>
              {isVisibleActions && (
                <S.DotsThreeIcon color={theme.colors.neutral.darker} size={18} weight="bold" />
              )}
            </S.TableAction>
          </S.DropdownTrigger>
          <S.DropdownContent>
            <S.DropdownItem
              onClick={() =>
                setIsOpenModal(state => ({ isOpen: !state.isOpen, bankAccountId: id }))
              }
            >
              <S.TrashIcon />
              Excluir
            </S.DropdownItem>
          </S.DropdownContent>
        </S.Dropdown>
      </S.TableDataAction>
    </S.Table.Row>
  );
};

export default TableRow;
