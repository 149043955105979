import React from 'react';

import { STORE_PROVIDER } from '@constants/common/Integrations/storeProvider';

import { useIntegrations } from '@hooks/pages/dashboard/Apps/useIntegrations';

import IntegrationCard from '../../IntegrationCard';

import * as S from './styles';

const StoreProvider: React.FC = () => {
  const { pickIntegration } = useIntegrations();

  return (
    <S.Wrapper>
      {/* <S.Title size="15px" weight="500">
        Plataformas de e-commerce
      </S.Title> */}

      <S.CardsWrapper>
        {STORE_PROVIDER.map(integration => {
          return (
            <IntegrationCard
              onClick={() => pickIntegration(integration.alias)}
              key={integration.alias}
              icon={integration.icon}
              title={integration.name}
              description={integration.description}
            />
          );
        })}
      </S.CardsWrapper>
    </S.Wrapper>
  );
};

export default StoreProvider;
