import React from 'react';
import useSWR from 'swr';
import { useParams } from 'react-router-dom';

import { IGetProfileResponse } from '@domain/interfaces/hooks/services/profile/IProfileService';
import { IProfile } from '@domain/interfaces/common/profile/IProfile';
import { IUpdatePasswordProvider } from '@domain/interfaces/store/common/profile/IUpdatePasswordProvider';
import { ISwr } from '@domain/interfaces/common/swr/ISwr';

import { DASHBOARD_API_URL } from '@constants/services/apiUrl';
import { dashboardInstance } from '@services/common/dashboardInstance';

import { useErrorHandler } from '@store/context/common/ErrorHandlerContext';
import { useProfileService } from '@hooks/services/profile/useProfileService';
import { useToast } from '@store/context/common/ToastContext';

const UpdatePasswordContext = React.createContext<IUpdatePasswordProvider | null>(null);

export const UpdatePasswordProvider: React.FC = ({ children }) => {
  const { toast } = useToast();
  const { accountId } = useParams();
  const { handleError } = useErrorHandler();
  const { updateProfilePassword } = useProfileService();
  const [isUpdatingPassword, setIsUpdatingPassword] = React.useState<boolean>(false);
  const [profile, setProfile] = React.useState<IProfile>({} as IProfile);

  dashboardInstance.defaults.headers.common['x-zouti-account'] = accountId;

  const GET_PROFILE_DATA_URL = `${DASHBOARD_API_URL}/accounts/:accountId/profile`;

  const { data, isLoading, isValidating, error } = useSWR<ISwr<IGetProfileResponse>>(
    GET_PROFILE_DATA_URL,
    dashboardInstance,
  );

  React.useEffect(() => {
    if (error) {
      handleError(error);
    }

    if (data?.data?.profile) {
      setProfile(data.data.profile);
    }
  }, [data, handleError, error]);

  const updateCurrentPassword = React.useCallback(
    async passwordData => {
      setIsUpdatingPassword(true);

      try {
        await updateProfilePassword({
          profileId: profile.id,
          payload: passwordData,
        });
        setIsUpdatingPassword(false);
        toast.success('Senha atualizada com sucesso!');
      } catch (err) {
        setIsUpdatingPassword(false);
        handleError(err);
      } finally {
        setIsUpdatingPassword(false);
      }
    },
    [updateProfilePassword, handleError, toast, profile.id],
  );

  const isLoadingProfile = isLoading || isValidating;
  const isProfileError = Boolean(error);

  return (
    <UpdatePasswordContext.Provider
      value={{
        profile,
        isLoadingProfile,
        isProfileError,
        isUpdatingPassword,
        updateCurrentPassword,
      }}
    >
      {children}
    </UpdatePasswordContext.Provider>
  );
};

export const useUpdatePassword = (): IUpdatePasswordProvider => {
  const context = React.useContext(UpdatePasswordContext);

  if (!context) {
    throw new Error('useUpdatePassword must be used within provider');
  }

  return context;
};
