import React from 'react';

import { X as CloseIcon } from 'phosphor-react';
import { useTheme } from 'styled-components';

import { EButtonVariant } from '@domain/enums/components/common/EButton';

import * as S from './styles';

interface IModalProps {
  isOpen: boolean;
  toggle?: () => void;
  onSuccess?: () => void;
  blockBackgroundClick?: boolean;
  cancelText?: string;
  successText?: string;
  colorSuccessButton?: string;
  colorSuccessButtonText?: string;
  title?: string;
  icon?: React.ReactNode;
  successButtonType?: 'button' | 'submit' | 'reset';
  isSuccessButtonLoading?: boolean;
}

const Modal: React.FC<IModalProps> = ({
  toggle,
  isOpen,
  blockBackgroundClick = false,
  cancelText,
  successText,
  onSuccess,
  title,
  icon,
  children,
  successButtonType,
  colorSuccessButton,
  colorSuccessButtonText,
  isSuccessButtonLoading,
  ...rest
}) => {
  const theme = useTheme();

  const backgroundClick = React.useCallback(() => {
    if (!blockBackgroundClick && toggle) {
      toggle();
    }
  }, [toggle, blockBackgroundClick]);

  return (
    <>
      {isOpen && (
        <>
          <S.ModalBackground onClick={backgroundClick} />
          <S.ModalContent {...rest}>
            <S.Heading weight="500" size="20px">
              <S.Title>
                {icon}
                {title}
              </S.Title>

              <S.CloseModalButton onClick={toggle}>
                <CloseIcon size={18} color={theme.colors.neutral.darker} />
              </S.CloseModalButton>
            </S.Heading>

            <S.Content>{children}</S.Content>

            {onSuccess && (
              <S.ButtonsWrapper>
                <S.CancelButton onClick={toggle} variant={EButtonVariant.SECONDARY}>
                  {cancelText}
                </S.CancelButton>

                <S.SuccessButton
                  colorSuccessButton={colorSuccessButton}
                  colorSuccessButtonText={colorSuccessButtonText}
                  type={successButtonType}
                  onClick={onSuccess}
                  isLoading={isSuccessButtonLoading}
                >
                  {successText}
                </S.SuccessButton>
              </S.ButtonsWrapper>
            )}
          </S.ModalContent>
        </>
      )}
    </>
  );
};

export default Modal;
