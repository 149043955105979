import React from 'react';

import { TransactionalEmailProvider } from '@store/common/TransactionalEmail/TransactionalEmailContext';

import Tab from '@components/common/utils/Tab';
import Option from '@components/common/utils/Tab/Option';

import Configuration from './Tabs/Configuration';
import Emails from './Tabs/Emails';

const Body: React.FC = () => {
  const [selectedTab, setSelectedTab] = React.useState<number>(0);
  const onChangeTab = React.useCallback(index => setSelectedTab(index), []);

  return (
    <>
      <Tab defaultTab={selectedTab} onChangeTab={onChangeTab}>
        <Option>E-mails</Option>
        <Option>Configurações</Option>
      </Tab>

      <TransactionalEmailProvider>
        {selectedTab === 0 && <Emails />}
        {selectedTab === 1 && <Configuration />}
      </TransactionalEmailProvider>
    </>
  );
};

export default Body;
