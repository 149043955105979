import React from 'react';

import CalendarRange from './CalendarRange';

import * as S from './styles';

interface ICalendarProps {
  isShowPreDefinedDate?: boolean;
  isDisabledDaysAfterToday?: boolean;
}

const Calendar: React.FC<ICalendarProps> = ({ isShowPreDefinedDate, isDisabledDaysAfterToday }) => {
  return (
    <S.Wrapper>
      <CalendarRange
        isDisabledDaysAfterToday={isDisabledDaysAfterToday}
        isShowPreDefinedDate={isShowPreDefinedDate}
      />
    </S.Wrapper>
  );
};

export default Calendar;
