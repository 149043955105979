import styled from 'styled-components/macro';

import HeadingBase from '@components/common/DataDisplay/Heading';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.small_24};
  height: 100%;
  width: 100%;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  padding-bottom: 24px;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
`;

export const TitleAndButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  width: 100%;
`;

export const TitleAndSubtitleWrapper = styled(HeadingBase)`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const WrapperStep = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.small_24};
  padding: ${({ theme }) => theme.spacing.small_24};
  background-color: ${({ theme }) => theme.colors.neutral.white};
  max-width: 1024px;
  margin: 0 auto;
`;

export const WrapperTitleAndDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.small_8};
`;

export const WrapperInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.small_8};
`;

export const WrapperActionButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const WrapperTextAndIconInfo = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.small_8};
`;

export const WrapperInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.small_8};
`;
