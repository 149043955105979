export const states = [
  { id: 1, name: 'Acre', code: 'AC' },
  { id: 2, name: 'Alagoas', code: 'AL' },
  { id: 3, name: 'Amapá', code: 'AP' },
  { id: 4, name: 'Amazonas', code: 'AM' },
  { id: 5, name: 'Bahia', code: 'BA' },
  { id: 6, name: 'Ceará', code: 'CE' },
  { id: 7, name: 'Distrito Federal', code: 'DF' },
  { id: 8, name: 'Espírito Santo', code: 'ES' },
  { id: 9, name: 'Goiás', code: 'GO' },
  { id: 10, name: 'Maranhão', code: 'MA' },
  { id: 11, name: 'Mato Grosso', code: 'MT' },
  { id: 12, name: 'Mato Grosso do Sul', code: 'MS' },
  { id: 13, name: 'Minas Gerais', code: 'MG' },
  { id: 14, name: 'Pará', code: 'PA' },
  { id: 15, name: 'Paraíba', code: 'PB' },
  { id: 16, name: 'Paraná', code: 'PR' },
  { id: 17, name: 'Pernambuco', code: 'PE' },
  { id: 18, name: 'Piauí', code: 'PI' },
  { id: 19, name: 'Rio de Janeiro', code: 'RJ' },
  { id: 20, name: 'Rio Grande do Norte', code: 'RN' },
  { id: 21, name: 'Rio Grande do Sul', code: 'RS' },
  { id: 22, name: 'Rondônia', code: 'RO' },
  { id: 23, name: 'Roraima', code: 'RR' },
  { id: 24, name: 'Santa Catarina', code: 'SC' },
  { id: 25, name: 'São Paulo', code: 'SP' },
  { id: 26, name: 'Sergipe', code: 'SE' },
  { id: 27, name: 'Tocantins', code: 'TO' },
];
