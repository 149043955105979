import * as yup from 'yup';

export const personalDataSchema = yup.object().shape({
  full_name: yup
    .string()
    .required('Este campo é obrigatório.')
    .min(2, 'O nome precisa conter no mínimo 2 letras.')
    .max(30, 'O nome pode conter no máximo 80 letras.'),
  email: yup.string().email('Insira um e-mail válido.').required('Este campo é obrigatório.'),
  phone_number: yup.string().required('Este campo é obrigatório.'),
});
