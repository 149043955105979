import React from 'react';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import * as S from './styles';

const NetRevenueSkeleton: React.FC = () => {
  return (
    <S.Wrapper>
      <S.Header>
        <S.WrapperItems>
          <Skeleton width="130px" height="15px" />
          <Skeleton width="180px" height="15px" />
          <Skeleton width="135px" height="15px" />
        </S.WrapperItems>
        <S.LegendsWrapper>
          <S.Legends>
            <Skeleton circle width="15px" height="15px" />
            <Skeleton width="120px" height="10px" />
          </S.Legends>
          <S.Legends>
            <Skeleton circle width="15px" height="15px" />
            <Skeleton width="120px" height="10px" />
          </S.Legends>
        </S.LegendsWrapper>
      </S.Header>
      <S.ChartBody>
        {Array.from({ length: 15 }).map((_, index) => (
          <S.ChartBar key={index.toFixed()}>
            <Skeleton height={`${Math.random() * 100 + 50}px`} width="20px" />
          </S.ChartBar>
        ))}
      </S.ChartBody>
    </S.Wrapper>
  );
};

export default NetRevenueSkeleton;
