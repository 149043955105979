import styled from 'styled-components/macro';

import InputGroupBase from '@components/common/Inputs/InputGroup';
import LabelBase from '@components/common/DataDisplay/Label';
import DropdownBase from '@components/common/utils/Dropdown';

import TextBase from '@components/common/DataDisplay/Text';

import ButtonBase from '@components/common/Inputs/Button';

export interface IProviderSelectProps {
  isDropdownOpen: boolean;
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-color: #fff;
  padding: 24px;
  border-radius: ${({ theme }) => theme.borderRadius.small};
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
`;

export const Label = styled(LabelBase)``;

export const InputGroup = styled(InputGroupBase)``;

export const DropdownTrigger = styled(DropdownBase.Trigger)<IProviderSelectProps>`
  display: flex;
  align-items: center;
  gap: 8px;
  height: 40px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  padding: 8px 16px;
  position: relative;
  text-align: left;
  font-size: 1.4rem;
  transition: all 0.3s;
  width: 100%;
  color: ${({ theme }) => theme.colors.neutral.base};
`;

export const ProviderIcon = styled.img`
  width: 24px;
  height: 24px;
`;

export const ProviderName = styled(TextBase)`
  font-size: 1.4rem;
`;

export const Dropdown = styled(DropdownBase)`
  width: 100%;
`;
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: flex-end;
`;

export const SubmitButton = styled(ButtonBase)``;

export const CancelButton = styled(ButtonBase)`
  & > span {
    color: ${({ theme }) => theme.colors.neutral.darkest};
  }
`;
