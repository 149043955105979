import React from 'react';
import Tracker from '@openreplay/tracker';
import { ISessionContext } from '@domain/interfaces/store/context/common/ISessionContext';

const tracker = new Tracker({
  projectKey: 'RXF1ucFdo6PllLu7nbSU',
  ingestPoint: 'https://sessions.zouti.com.br/ingest',
});

if (process.env.REACT_APP_STAGE === 'prod') {
  tracker.start();
}

const SessionContext = React.createContext<ISessionContext | null>(null);

export const SessionProvider: React.FC = ({ children }) => {
  const identifyUser = React.useCallback(userId => {
    tracker.setUserID(userId);
  }, []);

  const addMetadata = React.useCallback((key, value) => {
    tracker.setMetadata(key, value);
  }, []);

  return (
    <SessionContext.Provider value={{ addMetadata, identifyUser }}>
      {children}
    </SessionContext.Provider>
  );
};

export const useSession = (): ISessionContext => {
  const context = React.useContext(SessionContext);

  if (!context) {
    throw new Error('useSession must be used within provider');
  }

  return context;
};
