import styled from 'styled-components/macro';

import TableBase from '@components/common/utils/Table';
import ChipBase from '@components/common/DataDisplay/Chip';
import CheckboxBase from '@components/common/Inputs/Checkbox';
import EmpytImageBase from '@components/common/utils/EmptyImage';
import SideModalBase from '@components/common/DataDisplay/SideModal';
import TextBase from '@components/common/DataDisplay/Text';

export const Table = styled(TableBase)``;

export const TableDataVariant = styled(TableBase.Data)`
  color: ${({ theme }) => theme.colors.primary.dark_green_60} !important;
`;

export const Chip = styled(ChipBase)`
  margin: 0 auto;
`;

export const Checkbox = styled(CheckboxBase)``;

export const ProductNameContent = styled.div`
  display: flex;
  max-width: 260px;
  align-items: center;
  gap: 12px;
`;

export const ProductNameText = styled(TextBase)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const ProductIdContent = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ProductImage = styled.img`
  min-width: 40px;
  height: 40px;
  border-radius: ${({ theme }) => theme.borderRadius.small};
`;

export const EmptyImage = styled(EmpytImageBase)`
  width: 40px;
  height: 40px;
`;

export const VariantsSideModal = styled(SideModalBase)`
  width: 100%;
  max-width: 754px;
`;

export const VariantsTextWrapper = styled.button`
  background: unset;
  border: unset;
  padding: unset;
  font-family: ${({ theme }) => theme.fonts.text};
  font-weight: 400;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.primary.dark_green_60};
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    filter: brightness(0.9);
  }

  &:active {
    filter: brightness(0.8);
  }
`;
