import React from 'react';
import { FieldValues } from 'react-hook-form';
import { ArrowsCounterClockwise } from '@phosphor-icons/react';
import { useTheme } from 'styled-components/macro';

import { EButtonVariant } from '@domain/enums/components/common/EButton';
import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/common/EHeading';

import { useTransactionalEmail } from '@store/common/TransactionalEmail/TransactionalEmailContext';

import DnsData from './DnsData';
import SkeletonLoading from './SkeletonLoading';

import * as S from '../../styles';

const Configuration: React.FC = () => {
  const theme = useTheme();
  const {
    transactionEmailData,
    isLoadingTransactionEmail,
    emailRegister,
    errors,
    handleSubmit,
    updateSenderEmail,
    isCreatingEmail,
    verifyEmail,
    isVerifyingEmail,
  } = useTransactionalEmail();
  const senderEmail = transactionEmailData?.sender_email;

  const hasEmail = Boolean(senderEmail);

  const onSubmit = async (formData: FieldValues): Promise<void> => {
    await updateSenderEmail(formData);
  };

  const isEmailVerified = !!transactionEmailData?.validated_at;

  if (isLoadingTransactionEmail) {
    return <SkeletonLoading />;
  }

  return (
    <>
      <S.Form onSubmit={handleSubmit(onSubmit)}>
        <S.SettingsWrapper>
          <>
            <S.Title size={EHeadingSize.H6} fontWeight={EHeadingWeight.MEDIUM}>
              Dados do remetente
            </S.Title>

            <S.Divider />

            <S.WrapperInputs>
              <S.InputGroup>
                <S.Label>E-mail do remetente</S.Label>
                <S.Input {...emailRegister} placeholder="Ex: contato@exemplo.com.br" type="text" />
                {errors.email && (
                  <S.MessageError isFeedbackError>{errors.email.message}</S.MessageError>
                )}
              </S.InputGroup>

              <S.Helper>
                Por favor, insira apenas endereços de e-mail associados aos seus domínios
                verificados. Evite utilizar serviços como Gmail, Yahoo, UOL, entre outros.
              </S.Helper>
            </S.WrapperInputs>

            <S.ButtonsGroup>
              <S.VerifyEmailButton
                variant={EButtonVariant.SECONDARY}
                type="submit"
                isLoading={isCreatingEmail}
              >
                <S.ButtonTitle>Salvar remetente</S.ButtonTitle>
              </S.VerifyEmailButton>

              {hasEmail && !isEmailVerified && (
                <S.VerifyEmailButton
                  variant={EButtonVariant.PRIMARY}
                  type="button"
                  onClick={verifyEmail}
                  isLoading={isVerifyingEmail}
                >
                  <ArrowsCounterClockwise size={15} color={theme.colors.neutral.darker} />
                  Verificar e-mail
                </S.VerifyEmailButton>
              )}
            </S.ButtonsGroup>
          </>
        </S.SettingsWrapper>
      </S.Form>

      {hasEmail && (
        <S.DnsData>
          <DnsData success={isEmailVerified} />
        </S.DnsData>
      )}
    </>
  );
};

export default Configuration;
