import styled from 'styled-components/macro';

import ButtonBase from '@components/common/Inputs/Button';
import HeadingBase from '@components/common/DataDisplay/Heading';
import ImageBase from '@components/common/DataDisplay/Image';
import TextBase from '@components/common/DataDisplay/Text';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.neutral.white};
`;

export const WrapperThemes = styled.div`
  display: flex;
  gap: 30px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const ThemeCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lighter};
`;

export const Image = styled(ImageBase)``;

export const Title = styled(HeadingBase)`
  color: ${({ theme }) => theme.colors.neutral.darkest};
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CardFooter = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding: 16px;
  border-top: 1px solid ${({ theme }) => theme.colors.neutral.lighter};
`;

export const Button = styled(ButtonBase)``;

export const NameTheme = styled(TextBase)`
  font-weight: 500;
`;

export const ThemeByText = styled(TextBase)`
  font-weight: 500;
  font-size: 1.2rem;
`;
