import React from 'react';

import { useGetStatementTransactions } from '@store/common/statementTransactions/GetStatementTransactionsContext';

import BottomPagination from '@components/common/utils/BottomPagination';

import Body from './Body';
import BalanceCard from './BalanceCard';

const Statements: React.FC = () => {
  const {
    page,
    balances,
    pageLimit,
    totalPages,
    handlePageLimit,
    handlePageChange,
    statementTransactionTotal,
  } = useGetStatementTransactions();

  return (
    <>
      <BalanceCard balances={balances} />

      <Body />

      <BottomPagination
        page={page}
        pageLimit={pageLimit}
        totalPages={totalPages}
        handlePageLimit={handlePageLimit}
        handlePageChange={handlePageChange}
        totalItems={statementTransactionTotal}
      />
    </>
  );
};

export default Statements;
