import React from 'react';
import PageHeader from '@components/common/utils/PageHeader';

import * as S from './styles';

const Header: React.FC = () => {
  return (
    <S.Wrapper>
      <PageHeader
        goBackButton
        title="Pixels"
        description="Selecione a plataforma e configure os eventos que desejar."
      />
    </S.Wrapper>
  );
};

export default Header;
