import React from 'react';

import { EStoreOnboardingStep, EStoreSegment } from '@domain/enums/common/onboarding/EStore';
import { IStoreOnboardingContext } from '@domain/interfaces/store/context/onboarding/store/IStoreOnboarding';
import { IAccount } from '@domain/interfaces/common/account/IAccount';

import { useErrorHandler } from '@store/context/common/ErrorHandlerContext';
import { useAccountService } from '@hooks/services/account/useAccountService';
import { useAnalytics } from '@store/context/common/AnalyticsContext';
import { useCheckoutService } from '@hooks/services/checkout/useCheckoutService';
import { ICheckout } from '@domain/interfaces/common/checkout/ICheckout';

const StoreOnboardingContext = React.createContext<IStoreOnboardingContext | null>(null);

export const StoreOnboardingProvider: React.FC = ({ children }) => {
  const { analytics } = useAnalytics();
  const { handleError } = useErrorHandler();
  const { listAccounts } = useAccountService();
  const { createCheckout } = useCheckoutService();

  const listAccountsRef = React.useRef(listAccounts);

  const [onboardingStep, setOnboardingStep] = React.useState<EStoreOnboardingStep>(
    EStoreOnboardingStep.FIRST_STEP,
  );
  const [storeSegmentAnswer, setStoreSegmentAnswer] = React.useState<EStoreSegment | undefined>(
    undefined,
  );
  const [storeSegmentValue, setStoreSegmentValue] = React.useState<string>('');
  const [storeNameValue, setStoreNameValue] = React.useState<string>('');
  const [isCreatingAccount, setIsCreatingAccount] = React.useState<boolean>(false);
  const [account, setAccount] = React.useState<{ object: IAccount } | undefined>(undefined);
  const [checkout, setCheckout] = React.useState<{ object: ICheckout } | undefined>(undefined);

  const handleOnboardingStep = React.useCallback(step => setOnboardingStep(step), []);

  const handleStoreSegmentAnswer = React.useCallback(answer => setStoreSegmentAnswer(answer), []);

  const handleStoreSegmentValue = React.useCallback(value => setStoreSegmentValue(value), []);

  const handleStoreNameValue = React.useCallback(value => setStoreNameValue(value), []);

  const finishStoreOnboarding = React.useCallback(
    async data => {
      setIsCreatingAccount(true);
      const { data: accountsData } = await listAccountsRef.current();
      const { contact, website, id: accountId } = accountsData.object[0];

      try {
        const payload = {
          name: data.store_name_value,
          contact,
          website,
          language: data.store_language_value,
          currency: data.store_currency_value,
          business_type: storeSegmentAnswer,
        };

        const { data: checkoutData } = await createCheckout({ accountId, payload });

        analytics.track('Checkout Created', {
          name: data.store_name_value,
          language: data.store_language_value,
          currency: data.store_currency_value,
        });

        setAccount({ object: accountsData.object[0] });
        setCheckout(checkoutData);

        handleOnboardingStep(EStoreOnboardingStep.THIRD_STEP);

        setIsCreatingAccount(false);
      } catch (error) {
        handleError(error);
        setIsCreatingAccount(false);
      }
    },
    [handleOnboardingStep, createCheckout, handleError, analytics, storeSegmentAnswer],
  );

  return (
    <StoreOnboardingContext.Provider
      value={{
        finishStoreOnboarding,
        handleOnboardingStep,
        handleStoreNameValue,
        handleStoreSegmentAnswer,
        handleStoreSegmentValue,
        onboardingStep,
        storeNameValue,
        storeSegmentValue,
        storeSegmentAnswer,
        isCreatingAccount,
        account,
        checkout,
      }}
    >
      {children}
    </StoreOnboardingContext.Provider>
  );
};

export const useStoreOnboarding = (): IStoreOnboardingContext => {
  const context = React.useContext(StoreOnboardingContext);

  if (!context) {
    throw new Error('useStoreOnboarding must be used within provider');
  }

  return context;
};
