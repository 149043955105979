export const getPathByScopePermission = (scope: string): string => {
  const normalizeScope = scope?.replace(/:(write|read)/, '');
  const scopeToPathMap: Record<string, string> = {
    report: 'reports/general',
    order: 'orders/all',
    abandoned_cart: 'orders/abandoned-cart',
    admin: 'admin',
    business: 'settings/account-data',
    product: 'products/all',
    promocode: 'marketing/coupon',
    upsell: 'marketing/upsells',
    orderbump: 'marketing/order-bumps',
    pixel: 'marketing/pixels',
    recovery: 'marketing/recovery',
    checkout_customization: 'checkout/customizations',
    domain: 'checkout/domains',
    freight: 'checkout/shipping',
    social: 'checkout/social-proofs',
    app: 'dashboard/apps',
    payment: 'payment-credential',
    // user: 'settings/users',
    transactional_mail: 'settings/transactional-email',
    webhooks: 'apps/webhooks',
  };

  return scopeToPathMap[normalizeScope] || '';
};
