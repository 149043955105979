import React from 'react';
import { useGetDomain } from '@store/common/domain/GetDomainContext';

import * as S from './styles';

const CustomModal: React.FC = () => {
  const {
    setIsOpenModal,
    isOpenModal,
    domainDeletedInfo,
    deleteCurrentDomain,
    isLoadingDeleteDomain,
  } = useGetDomain();
  const { domainId, domainUrl } = domainDeletedInfo;

  const onDeleteDomainButtonClick = (): void => {
    deleteCurrentDomain(domainId);
  };

  return (
    <S.Modal
      title="Excluir domínio"
      toggle={() => setIsOpenModal(state => !state)}
      isOpen={isOpenModal}
    >
      <S.Text>
        Você está prestes a remover o domínio <S.DomainUrl>{domainUrl}</S.DomainUrl>. Tem a certeza
        de que deseja prosseguir?
      </S.Text>
      <S.ModalAction>
        <S.CancelButton onClick={() => setIsOpenModal(state => !state)}>Cancelar</S.CancelButton>
        <S.ConfirmButton isLoading={isLoadingDeleteDomain} onClick={onDeleteDomainButtonClick}>
          Confirmar
        </S.ConfirmButton>
      </S.ModalAction>
    </S.Modal>
  );
};

export default CustomModal;
