import React from 'react';

import Content from '@components/pages/Dashboard/CheckoutCustomization/NewCheckoutCustomization';
import { NewCheckoutCustomizationProvider } from '@store/common/checkoutCustomization/NewCheckoutCustomizationContext';

const NewCheckoutCustomization: React.FC = () => {
  return (
    <NewCheckoutCustomizationProvider>
      <Content />
    </NewCheckoutCustomizationProvider>
  );
};

export default NewCheckoutCustomization;
