import React from 'react';

import { EHeadAlign } from '@domain/enums/components/common/ETable';
import { IPayout } from '@domain/interfaces/common/payout/IPayout';
import { useGetPayouts } from '@store/common/Payout/GetPayoutsContext';

import { formatCurrency } from '@utils/common/formatCurrency';
import PIX from '@components/icons/Pix';
import { Bank } from '@phosphor-icons/react';
import { formatDateParts } from '@utils/common/date';
import Badge from '../../Badge';

import * as S from './styles';

interface ITableRowProps {
  payouts: IPayout;
}

const TableRow: React.FC<ITableRowProps> = ({ payouts }) => {
  const { setIsOpenModal, setSelectedPayout } = useGetPayouts();
  const { id, created_at, fee, payout_status, amount, pix_key_id } = payouts;

  const transferType = pix_key_id ? 'PIX' : 'BANK_ACCOUNT';

  const transferTypeMap = {
    BANK_ACCOUNT: {
      icon: <Bank weight="bold" size={15} />,
      text: 'Transferência Bancária',
    },
    PIX: {
      icon: <PIX size="15" />,
      text: 'Chave Pix',
    },
  }[transferType];

  const createdAt = formatDateParts(created_at);
  const rateValueFormatted = formatCurrency(fee / 100);
  const transferredValueFormatted = formatCurrency(amount / 100);

  const handleClick = (): void => {
    setIsOpenModal(state => !state);
    setSelectedPayout(payouts);
  };

  return (
    <S.TableRow onClick={handleClick}>
      <S.Table.Data>
        <S.Text>{id}</S.Text>
      </S.Table.Data>

      <S.TransferValueData>
        <S.ContentIcon>{transferTypeMap?.icon}</S.ContentIcon>
        <S.Text>{transferredValueFormatted}</S.Text>
        <S.TextTransferType>{transferTypeMap?.text}</S.TextTransferType>
      </S.TransferValueData>

      <S.Table.Data align={EHeadAlign.CENTER}>
        <S.Text>{rateValueFormatted}</S.Text>
      </S.Table.Data>

      <S.Table.Data align={EHeadAlign.CENTER}>
        <Badge status={payout_status} />
      </S.Table.Data>

      <S.Table.Data>
        <S.Text>{createdAt}</S.Text>
      </S.Table.Data>
    </S.TableRow>
  );
};

export default TableRow;
