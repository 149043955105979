import { EStepsAbandonedCart } from '@domain/enums/common/abandonedCart/EStepsAbandonedCart';

export const getStepsAbandonedCart = (steps: EStepsAbandonedCart): string => {
  const stepsMappings: Record<EStepsAbandonedCart, string> = {
    [EStepsAbandonedCart.DELIVERY]: 'ENTREGA',
    [EStepsAbandonedCart.PAYMENT]: 'PAGAMENTO',
    [EStepsAbandonedCart.SHIPPING]: 'FRETE',
    [EStepsAbandonedCart.PERSONAL_DATA]: 'DADOS PESSOAIS',
  };

  return stepsMappings[steps];
};
