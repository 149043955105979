import React from 'react';

import { useUpdateAccountData } from '@store/common/accountData/UpdateAccountData';

import CompanyData from './CompanyData';
import AddressData from './AddressData';
import LanguageAndCurrencyData from './LanguageAndCurrencyData';

const Body: React.FC = () => {
  const { selectedTab } = useUpdateAccountData();

  return (
    <>
      {selectedTab === 0 && <CompanyData />}
      {selectedTab === 1 && <AddressData />}
      {selectedTab === 2 && <LanguageAndCurrencyData />}
    </>
  );
};

export default Body;
