import React from 'react';
import { format } from 'date-fns';

import { FieldValues, Controller, useForm, useWatch } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { useNewPromocode } from '@store/common/promocode/NewPromocodeContext';
import { useUserAccount } from '@store/context/common/UserAccount';

import { EButtonVariant } from '@domain/enums/components/common/EButton';
import { ICreatePromocodeData } from '@domain/interfaces/hooks/services/promocode/IPromocodeService';

import { currencyFormatter, currencyToNumber } from '@helpers/utils/common/number';
import ProductSelect from '@components/pages/Dashboard/Coupon/ProductSelect';

import { yupResolver } from '@hookform/resolvers/yup';
import { couponSchema } from '@helpers/validators/pages/dashboard/Coupon/couponSchema';
import { getCurrencySymbol } from '@utils/common/formatCurrency';

import * as S from './styles';

const Body: React.FC = () => {
  const navigate = useNavigate();
  const { accountId, checkoutId } = useParams();
  const { currentUserAccount } = useUserAccount();

  const { createNewPromocode, isCreatingPromocode, selectedOfferProduct, setSelectedOfferProduct } =
    useNewPromocode();
  const [startDate, setStartDate] = React.useState<Date>(new Date());
  const [endDate, setEndDate] = React.useState<Date>(new Date());

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<ICreatePromocodeData>({
    resolver: yupResolver(couponSchema),
    defaultValues: {
      free_shipment: false,
    },
  });

  // const promocodeType = useWatch({
  //   control,
  //   name: 'type',
  // });

  const promocodeAmountType = useWatch({
    control,
    name: 'amount_type',
  });

  const onChangeDate = React.useCallback(dates => {
    const [start, end] = dates;

    setStartDate(start);
    setEndDate(end);
  }, []);

  // const showProducts = promocodeType === 'PRODUCT';

  const showAmountPrice = promocodeAmountType !== 'SHIPPING';

  // const selectedProductsIds = selectedOfferProduct?.map(product => product.id);

  const generateRandomCode = (length: number): string => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let code = '';

    // eslint-disable-next-line
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      code += characters.charAt(randomIndex);
    }

    return code;
  };

  const onAmountChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    event.target.value = currencyFormatter(event.target.value);
  };

  const onSubmit = async (formData: FieldValues): Promise<void> => {
    const isFreeShipping = formData.amount_type === 'SHIPPING';

    const data: ICreatePromocodeData = {
      amount_type: formData.amount_type,
      amount: isFreeShipping ? 0 : Math.round(currencyToNumber(formData.amount) * 100),
      code: formData.code,
      description: formData.description,
      end_at: format(endDate, 'yyyy-MM-dd'),
      free_shipment: isFreeShipping,
      max_quantity: Number(formData.max_quantity),
      min_value: Math.round(currencyToNumber(formData.min_value) * 100),
      start_at: format(startDate, 'yyyy-MM-dd'),
      type: 'ORDER',
      product_ids: [],
    };

    await createNewPromocode({ payload: data });
  };

  const onCancelButtonClick = (): void => {
    navigate(`/${accountId}/${checkoutId}/dashboard/marketing/coupon`);
  };

  const onGenerateButtonClick = (): void => {
    setValue('code', generateRandomCode(13));
  };

  const currency = getCurrencySymbol(currentUserAccount?.language, currentUserAccount?.currency);

  return (
    <S.Wrapper>
      <S.Form onSubmit={handleSubmit(onSubmit)}>
        <S.Card>
          <S.InputGroup>
            <S.Label>Código</S.Label>
            <S.InputGroupButton>
              <S.WrapperInputAndTextError>
                <S.Input
                  {...register('code')}
                  type="text"
                  placeholder="Digite um código ou clique em gerar"
                  isError={!!errors?.code}
                />
                {errors?.code && (
                  <S.MessageError isFeedbackError>{errors?.code?.message}</S.MessageError>
                )}
              </S.WrapperInputAndTextError>
              <S.GenerateCouponButton
                variant={EButtonVariant.SECONDARY}
                onClick={onGenerateButtonClick}
                type="button"
              >
                Gerar
              </S.GenerateCouponButton>
            </S.InputGroupButton>
          </S.InputGroup>

          <S.InputGroup>
            <S.Label>
              Descrição <S.LabelHelper>(opcional)</S.LabelHelper>
            </S.Label>
            <Controller
              name="description"
              control={control}
              defaultValue=""
              render={({ field }) => <S.TextArea {...field} />}
            />
          </S.InputGroup>

          <S.WrapperMinPriceAndMaxQuantity>
            <S.InputGroup>
              <S.Label>Valor mínimo da compra</S.Label>
              <S.ValueWrapper>
                <S.CurrencyLabel>{currency}</S.CurrencyLabel>
                <S.Input
                  {...register('min_value')}
                  onChange={onAmountChange}
                  type="text"
                  placeholder="Valor minímo"
                />
              </S.ValueWrapper>
              {errors?.min_value && (
                <S.MessageError isFeedbackError>{errors?.min_value?.message}</S.MessageError>
              )}
            </S.InputGroup>

            <S.InputGroup>
              <S.Label>Nº de Cupons disponíveis</S.Label>
              <S.Input
                {...register('max_quantity')}
                type="text"
                placeholder="Insira a quantidade"
              />
              {errors?.max_quantity && (
                <S.MessageError isFeedbackError>{errors?.max_quantity?.message}</S.MessageError>
              )}
            </S.InputGroup>
          </S.WrapperMinPriceAndMaxQuantity>

          <S.InputGroup>
            <S.Label>Data de expiração</S.Label>
            <S.DatePicker
              isShowPreDefinedDate={false}
              onChange={onChangeDate}
              endDate={endDate}
              startDate={startDate}
            />
          </S.InputGroup>
        </S.Card>
        <S.Card>
          {/* <S.InputGroup> */}
          {/*  <S.Label>Aplicar para</S.Label> */}
          {/*  <S.InputGroupWrapper> */}
          {/*    <S.Radio {...register('type')} label="Pedido inteiro" labelFor="type" value="ORDER" /> */}

          {/*    <S.Radio */}
          {/*      {...register('type')} */}
          {/*      label="Produto" */}
          {/*      labelFor="amountTypeProduct" */}
          {/*      value="PRODUCT" */}
          {/*    /> */}
          {/*  </S.InputGroupWrapper> */}
          {/*  {errors?.type && ( */}
          {/*    <S.MessageError isFeedbackError>{errors?.type?.message}</S.MessageError> */}
          {/*  )} */}
          {/* </S.InputGroup> */}

          {/* {showProducts && <ProductSelect setSelectedOfferProduct={setSelectedOfferProduct} />} */}

          <>
            <S.InputGroup>
              <S.Label>Tipo de desconto</S.Label>
              <S.InputGroupWrapper>
                <S.Radio
                  {...register('amount_type')}
                  label="Valor fixo"
                  labelFor="typeFixed"
                  value="FIXED"
                />

                <S.Radio
                  {...register('amount_type')}
                  label="Porcentagem"
                  labelFor="typePercentage"
                  value="PERCENTAGE"
                />

                <S.Radio
                  {...register('amount_type')}
                  label="Frete grátis"
                  labelFor="typeShipping"
                  value="SHIPPING"
                />
              </S.InputGroupWrapper>
              {errors?.amount_type && (
                <S.MessageError isFeedbackError>{errors?.amount_type?.message}</S.MessageError>
              )}
            </S.InputGroup>

            {showAmountPrice && (
              <S.InputGroup>
                <S.Label>Valor do desconto</S.Label>
                <S.ValueWrapper>
                  {promocodeAmountType === 'PERCENTAGE' ? (
                    <S.CurrencyLabel>%</S.CurrencyLabel>
                  ) : (
                    <S.CurrencyLabel>{currency}</S.CurrencyLabel>
                  )}
                  <S.Input
                    {...register('amount')}
                    type="text"
                    placeholder="Insira o valor do desconto"
                    onChange={onAmountChange}
                  />
                </S.ValueWrapper>
              </S.InputGroup>
            )}
          </>

          {/* <S.CheckboxGroup>
            <Controller
              control={control}
              name="amount_type"
              render={({ field }) => (
                <S.Checkbox
                  id="amount_type"
                  value={field?.value?.toString()}
                  checked={promocodeAmountType === 'SHIPPING'}
                  onChange={() => {
                    field?.onChange(!field.value);
                    setValue('amount_type', !field.value ? 'SHIPPING' : '');
                  }}
                />
              )}
            />
            <S.CheckboxLabel htmlFor="amount_type">Aplicar frete grátis</S.CheckboxLabel>
          </S.CheckboxGroup> */}
        </S.Card>

        <S.ButtonsWrapper>
          <S.CancelButton
            onClick={onCancelButtonClick}
            variant={EButtonVariant.SECONDARY}
            type="button"
          >
            Cancelar
          </S.CancelButton>
          <S.SubmitButton type="submit" isLoading={isCreatingPromocode}>
            Salvar
          </S.SubmitButton>
        </S.ButtonsWrapper>
      </S.Form>
    </S.Wrapper>
  );
};

export default Body;
