import styled, { css } from 'styled-components/macro';

import ImageBase from '@components/common/DataDisplay/Image';
import TextBase from '@components/common/DataDisplay/Text';
import DropdownBase from '@components/common/utils/Dropdown';

interface IStoreWrapperProps {
  isCurrentCheckout: boolean;
}

export const StoreListName = styled(TextBase)`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.neutral.darker};
  font-weight: 500;
`;

export const StoreWrapper = styled.div<IStoreWrapperProps>`
  height: 40px;
  width: 100%;
  justify-content: space-between;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 12px;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary.lemon_20};
  }

  ${({ isCurrentCheckout, theme }) =>
    isCurrentCheckout &&
    css`
      & > div > span {
        color: ${theme.colors.primary.dark_green_40};
      }
    `};
`;

export const StoreImageAndNameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const AddStoreText = styled(TextBase)`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.neutral.darker};
  font-weight: 500;
`;

export const AddStoreWrapper = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 12px;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary.lemon_20};
  }
`;

export const DropdownContent = styled(DropdownBase.Content)`
  width: 240px;
  min-width: unset;
  padding: 8px 0;
  max-height: 60vh;
  overflow-y: auto;
`;

export const DropdownTrigger = styled(DropdownBase.Trigger)``;

export const Dropdown = styled(DropdownBase)``;

export const StoreName = styled(TextBase)`
  font-size: 1.5rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.neutral.darker};
`;

export const Image = styled(ImageBase)`
  border-radius: 50%;
  width: 24px;
  height: 24px;
`;

export const ImageAndNameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Wrapper = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 8px 8px;
  border-radius: 4px;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary.lemon_20};
  }
`;
