import React from 'react';

import * as S from './styles';

interface IInfoTextProps {
  transferType?: string;
}

const InfoText: React.FC<IInfoTextProps> = ({ transferType }) => {
  const items = {
    BANK_ACCOUNT: {
      text: 'A transferência acontecerá logo após a solicitação.',
      icon: <S.WarningCircleIcon />,
    },
    PIX: {
      text: 'O valor será enviado instantaneamente. Só é possível o envio via Pix se sua chave cadastrada for o CNPJ.',
      icon: <S.PIXIcon />,
    },
  }[transferType || 'BANK_ACCOUNT'];

  return (
    <S.Card>
      {items?.icon}
      <S.CardText>{items?.text}</S.CardText>
    </S.Card>
  );
};

export default InfoText;
