import React from 'react';
import useSWR from 'swr';
import { useParams } from 'react-router-dom';

import { ISwr } from '@domain/interfaces/common/swr/ISwr';

import { DASHBOARD_API_URL } from '@constants/services/apiUrl';

import { dashboardInstance } from '@services/common/dashboardInstance';

import { useErrorHandler } from '@store/context/common/ErrorHandlerContext';
import { useGetHopyTransactionsStatements } from '@store/common/hopyTransactionsStatements/GetHopyTransactionsStatements';

import { IStatementTransactions } from '@domain/interfaces/common/statementTransactions/IStatementTransactions';
import {
  IBalanceProps,
  IGetBalanceResponse,
  IListStatementTransactionResponse,
} from '@domain/interfaces/hooks/services/statementTransaction/IStatementTransactionService';
import {
  EBalanceType,
  IGetStatementTransactionsProvider,
} from '@domain/interfaces/store/common/statementTransactions/IGetStatementTransactionsProvider';

const GetStatementTransactionsContext =
  React.createContext<IGetStatementTransactionsProvider | null>(null);

export const GetStatementTransactionsProvider: React.FC = ({ children }) => {
  const { accountId } = useParams();
  const { handleError } = useErrorHandler();
  const { mutateHopyBalance } = useGetHopyTransactionsStatements();

  const [page, setPage] = React.useState<number>(0);
  const [pageLimit, setPageLimit] = React.useState<number>(10);
  const [totalPages, setTotalPages] = React.useState<number>(0);
  const [statementTransactionTotal, setStatementTransactionTotal] = React.useState<number>(0);
  const [statementTransactions, setStatementTransactions] = React.useState<
    Array<IStatementTransactions>
  >([]);
  const [balances, setBalances] = React.useState<IBalanceProps>({} as IBalanceProps);
  const [selectedBalance, setSelectedBalance] = React.useState<EBalanceType>(
    EBalanceType.Available,
  );
  const [showLegacyBalance, setShowLegacyBalance] = React.useState<boolean>(false);

  const STATEMENT_TRANSACTION_URL = `${DASHBOARD_API_URL}/accounts/${accountId}/statement?page=${
    page + 1
  }&limit=${pageLimit}&type=${selectedBalance}`;

  const GET_BALANCE_URL = `${DASHBOARD_API_URL}/accounts/${accountId}/balance`;

  const {
    data,
    isLoading: isLoadingStatement,
    error,
  } = useSWR<ISwr<IListStatementTransactionResponse>>(STATEMENT_TRANSACTION_URL, dashboardInstance);

  const {
    data: balanceData,
    isLoading: isLoadingBalance,
    error: isErrorBalance,
  } = useSWR<ISwr<IGetBalanceResponse>>(GET_BALANCE_URL, dashboardInstance);

  const handleShowLegacyBalance = React.useCallback(
    () => setShowLegacyBalance(previousState => !previousState),
    [],
  );

  const handleSelectedBalance = React.useCallback(newSelectedBalance => {
    setPage(0);
    setSelectedBalance(newSelectedBalance);
  }, []);

  const handlePageChange = React.useCallback(selected => {
    setPage(selected);
  }, []);

  const handlePageLimit = React.useCallback(newPageLimit => {
    setPageLimit(newPageLimit);
  }, []);

  React.useEffect(() => {
    if (showLegacyBalance) {
      mutateHopyBalance();
    }
  }, [showLegacyBalance, mutateHopyBalance]);

  React.useEffect(() => {
    if (error) {
      handleError(error);
    }

    if (isErrorBalance) {
      handleError(isErrorBalance);
    }

    if (data?.data?.object) {
      setStatementTransactions(data.data.object.data);
      setTotalPages(data.data.object.total_pages);
      setStatementTransactionTotal(data.data.object.total);
    }

    if (balanceData?.data?.object) {
      setBalances(balanceData.data.object);
    }
  }, [data, balanceData, handleError, error, isErrorBalance]);

  const isError = Boolean(error);
  const isEmpty = statementTransactions?.length === 0;
  const isLoading = isLoadingStatement || isLoadingBalance;

  return (
    <GetStatementTransactionsContext.Provider
      value={{
        page,
        isError,
        isEmpty,
        balances,
        pageLimit,
        isLoading,
        totalPages,
        handlePageLimit,
        handlePageChange,
        statementTransactions,
        statementTransactionTotal,
        handleSelectedBalance,
        selectedBalance,
        handleShowLegacyBalance,
        showLegacyBalance,
      }}
    >
      {children}
    </GetStatementTransactionsContext.Provider>
  );
};

export const useGetStatementTransactions = (): IGetStatementTransactionsProvider => {
  const context = React.useContext(GetStatementTransactionsContext);

  if (!context) {
    throw new Error('useGetStatementTransactions must be used within GetBankAccountProvider');
  }

  return context;
};
