export const currencyFormatter = (value: string): string => {
  let newValue = value;

  const parsedValue = value.replace(/\D/g, '');

  if (value.includes('.') && value.split('.')[1].length === 1) {
    newValue = `${value}0`;
  }

  if (parsedValue.length === 1 && parsedValue !== '0') {
    newValue = `0.0${value}`;
  }

  if (parsedValue.length === 2) {
    newValue = `0.${value}`;
  }

  if (parsedValue.length === 4 && parsedValue.startsWith('00')) {
    const removedInitial = parsedValue.replace('00', '');

    newValue = `0.${removedInitial}`;
  }

  if (parsedValue.length === 4 && parsedValue.startsWith('0')) {
    newValue = parsedValue.substring(1);
  }

  return newValue
    .replace(/\D/g, '')
    .replace(/(\d)(\d{2})$/, '$1,$2')
    .replace(/(?=(\d{3})+(\D))\B/g, '.');
};

export const numberToString = (number: number, digits: number, noDecimal?: boolean): string => {
  if (!number) {
    return '0';
  }

  if (number % 1 === 0 && noDecimal) {
    return number.toLocaleString('pt-br', { maximumFractionDigits: 0 });
  }

  return number.toLocaleString('pt-br', {
    maximumFractionDigits: digits,
    minimumFractionDigits: digits,
  });
};

export const globalCurrency = (number: number, locale?: string, currency?: string): string => {
  if (!number) {
    return '0';
  }

  return number.toLocaleString(locale || 'en-US', {
    style: 'currency',
    currency,
  });
};

export const getCurrencySymbol = (currency: string): string => {
  const currencySymbol: Record<string, string> = {
    USD: 'US$ 0,00',
    EUR: '0,00 €',
  };

  return currencySymbol[currency] || 'US$ 0,00';
};

export const currencyToNumber = (currencyToParse: string): number => {
  return Number(currencyToParse.replace('.', '').replace('.', '').replace(',', '.'));
};

export const currencyToNumberWithoutCharacter = (currencyToParse: string): number => {
  return Number(currencyToParse.replace(/[.,]/g, ''));
};
