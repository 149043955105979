import React from 'react';
import GTMIcon from '@assets/images/common/dashboard/integrations/gtm.svg';

import { useAnalytics } from '@store/context/common/AnalyticsContext';
import { useGTMContext } from '@store/common/GTM/GTMContext';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/common/EHeading';

import SaveForm from '../SaveForm';

import * as S from './styles';

const Body: React.FC = () => {
  const { analytics } = useAnalytics();
  const {
    errors,
    handleSubmit,
    onSubmit,
    watchTrackingId,
    trackingIdRegister,
    onActivateGTM,
    onInactivateGTM,
    gtmCredential,
    watch,
  } = useGTMContext();

  const [trackingIdValue] = watch(['tracking_id']);

  const [showSaveForm, setShowSaveForm] = React.useState<boolean>(false);
  const [isActivated, setIsActivated] = React.useState<boolean>(false);

  const handleStatusChange = React.useCallback(
    async event => {
      setIsActivated(event.target.checked);

      if (event.target.checked) {
        await onActivateGTM();
      } else {
        await onInactivateGTM();
      }
    },
    [onInactivateGTM, onActivateGTM],
  );

  const onIdChange = React.useCallback(
    event => {
      const idValue = event.target.value;

      const parsedIdValue = idValue.replace(/^(?!GTM-)/, 'GTM-').replace(/\s+/g, '');

      event.target.value = parsedIdValue;

      trackingIdRegister.onChange(event);
    },
    [trackingIdRegister],
  );

  React.useEffect(() => {
    if (watchTrackingId && !gtmCredential) {
      setShowSaveForm(true);
      return;
    }

    if (!gtmCredential) return;

    if (gtmCredential.tracking_id !== trackingIdValue) {
      setShowSaveForm(true);
    }
  }, [watchTrackingId, gtmCredential, trackingIdValue]);

  React.useEffect(() => {
    if (watchTrackingId) {
      setShowSaveForm(true);
    }
  }, [watchTrackingId]);

  React.useEffect(() => {
    if (gtmCredential) {
      setIsActivated(Boolean(!gtmCredential.inactivated_at));
    }
  }, [gtmCredential]);

  React.useEffect(() => {
    analytics.track('GTM Credential Page Viewed');
  }, [analytics]);

  return (
    <S.Wrapper>
      <S.AdditionalInformationWrapper>
        <S.AdditionalInformationContent>
          <S.AdditionalInformationHeader backgroundColor="#246FDB">
            <S.TextBanner>Google Tag Manager</S.TextBanner>
            <S.IconWrapper>
              <S.Icon src={GTMIcon} alt="Integration image" />
            </S.IconWrapper>
          </S.AdditionalInformationHeader>

          <S.AdditionalInformationBody>
            <S.TitleAndSwitchWrapper>
              <S.Title size={EHeadingSize.H6} fontWeight={EHeadingWeight.SEMI_BOLD}>
                Google Tag Manager
              </S.Title>

              {gtmCredential && (
                <S.Switch
                  id="install -gtm"
                  name="install-gtm"
                  checked={isActivated}
                  onChange={handleStatusChange}
                />
              )}
            </S.TitleAndSwitchWrapper>

            <S.Description>
              Gerencie códigos de rastreamento de forma simples para entender o comportamento dos
              usuários e melhorar estratégias de marketing.
            </S.Description>
          </S.AdditionalInformationBody>
        </S.AdditionalInformationContent>

        {/* <Tutorial
          image_url={tutorialImage}
          title="Google Tag Manager: Guia de Integração em Minutos"
          video_url="https://www.youtube.com/embed/f5_wn8mexmM?si=Ud_rqwSSYa_nU4az"
        /> */}
      </S.AdditionalInformationWrapper>

      <S.Form onSubmit={handleSubmit(onSubmit)}>
        <S.CredentialsWrapper>
          <S.CredentialsTitle>Credenciais</S.CredentialsTitle>

          <S.InputGroup>
            <S.Label>ID de rastreamento</S.Label>

            <S.Input
              {...trackingIdRegister}
              isError={Boolean(errors.tracking_id)}
              type="text"
              placeholder="GTM-XXXXXXX"
              onChange={onIdChange}
            />

            {errors.tracking_id && (
              <S.MessageError isFeedbackError>{errors.tracking_id.message}</S.MessageError>
            )}
          </S.InputGroup>
        </S.CredentialsWrapper>

        {showSaveForm && <SaveForm />}
      </S.Form>
    </S.Wrapper>
  );
};

export default Body;
