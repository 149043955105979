import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { EButtonVariant } from '@domain/enums/components/common/EButton';
import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/common/EHeading';
import { ICreateCheckoutCustomizationData } from '@domain/interfaces/hooks/services/checkoutCustomization/ICheckoutCustomizationService';

import { useEditCheckout } from '@store/common/checkoutCustomization/EditCheckoutCustomizationContext';
import appearanceCheckoutCustomizationSchema from '@helpers/validators/pages/dashboard/checkoutCustomizations/appearanceCheckoutCustomizationSchema';

import Tooltip from '@components/pages/Dashboard/CheckoutCustomization/Tooltip';
import * as S from './styles';

const AppearanceOttawa: React.FC = () => {
  const navigate = useNavigate();
  const { accountId, checkoutId } = useParams();
  const {
    checkoutCustomization,
    updateCurrentCheckout,
    isUpdateCheckout,
    setCheckoutCustomization,
    selectedThemeCheckout,
  } = useEditCheckout();
  const {
    id,
    name,
    boleto_discount_type,
    credit_card_discount_type,
    pix_discount_type,
    allow_credit_card,
    allow_pix,
    allow_boleto,
    credit_card_discount_amount,
    pix_discount_amount,
    boleto_discount_amount,
    show_coupon_input,
    enable_itens_quantity_change,
    header_customization,
    footer_customization,
    appearance_customization,
    advanced_customization,
    show_time_shipping_estimate,
  } = checkoutCustomization;

  const { control, reset, register, handleSubmit, setValue, watch } = useForm({
    resolver: yupResolver(appearanceCheckoutCustomizationSchema),
  });

  const onSubmit = async (formData: FieldValues): Promise<void> => {
    const data: ICreateCheckoutCustomizationData = {
      name,
      allow_credit_card,
      allow_pix,
      allow_boleto,
      boleto_discount_type,
      credit_card_discount_type,
      pix_discount_type,
      credit_card_discount_amount,
      pix_discount_amount,
      boleto_discount_amount,
      show_coupon_input,
      enable_itens_quantity_change,
      show_time_shipping_estimate,
      header_customization: { ...header_customization },
      footer_customization: { ...footer_customization },
      appearance_customization: {
        primary_hex_color: formData.primary_hex_color,
        title_hex_color: formData.title_hex_color,
        label_hex_color: formData.label_hex_color,
        chip_hex_color: formData.chip_hex_color,
        button_hex_color: formData.button_hex_color,
        button_text_hex_color: formData.button_text_hex_color,
        title_font_family: formData.title_font_family,
        text_font_family: formData.text_font_family,
        is_title_uppercase: formData.is_title_uppercase,
        is_button_uppercase: formData.is_button_uppercase,
        metadata: {
          is_text_uppercase: formData.metadata.is_text_uppercase,
          button_font_family: formData.metadata.button_font_family,
        },
        id,
      },
      advanced_customization: { ...advanced_customization },
      checkout_theme: { theme: selectedThemeCheckout?.theme },
    };

    await updateCurrentCheckout({ checkoutCustomizationId: id, payload: data });
  };

  React.useEffect(() => {
    return () => {
      const fieldValues = watch();
      setCheckoutCustomization(prevData => {
        return {
          ...prevData,
          appearance_customization: {
            primary_hex_color: fieldValues.primary_hex_color,
            title_hex_color: fieldValues.title_hex_color,
            label_hex_color: fieldValues.label_hex_color,
            chip_hex_color: fieldValues.chip_hex_color,
            button_hex_color: fieldValues.button_hex_color,
            button_text_hex_color: fieldValues.button_text_hex_color,
            title_font_family: fieldValues.title_font_family,
            text_font_family: fieldValues.text_font_family,
            is_title_uppercase: fieldValues.is_title_uppercase,
            is_button_uppercase: fieldValues.is_button_uppercase,
            metadata: {
              button_font_family: fieldValues.metadata.button_font_family,
              is_text_uppercase: fieldValues.metadata.is_text_uppercase,
            },
            id,
          },
        };
      });
    };
  }, [setCheckoutCustomization, watch, id]);

  const onCancelButtonClick = (): void => {
    reset();
    navigate(`/${accountId}/${checkoutId}/dashboard/checkout/customizations`);
  };

  register('chip_hex_color', { value: appearance_customization?.chip_hex_color });
  register('label_hex_color', { value: appearance_customization?.label_hex_color });

  return (
    <S.Form onSubmit={handleSubmit(onSubmit)}>
      <S.Wrapper>
        <S.ColorSection>
          <S.Title size={EHeadingSize.H4} fontWeight={EHeadingWeight.SEMI_BOLD}>
            Cores
          </S.Title>
          <S.InputColorWrapper>
            <S.InputColor
              {...register('primary_hex_color', {
                value: appearance_customization?.primary_hex_color,
              })}
              value={appearance_customization?.primary_hex_color}
              onChange={event => {
                setValue('primary_hex_color', event.target.value);
              }}
              placeholder="Digite o código da cor"
            >
              {/* <Tooltip url="https://images.freeimages.com/images/small-previews/aed/three-bees-on-sunflower-1337029.jpg"> */}
              Cor primária
              {/* </Tooltip> */}
            </S.InputColor>
            <S.InputColor
              {...register('title_hex_color', {
                value: appearance_customization?.title_hex_color,
              })}
              value={appearance_customization?.title_hex_color}
              onChange={event => {
                setValue('title_hex_color', event.target.value);
              }}
              placeholder="Digite o código da cor"
            >
              {/* <Tooltip url="https://images.freeimages.com/images/small-previews/aed/three-bees-on-sunflower-1337029.jpg"> */}
              Cor dos títulos
              {/* </Tooltip> */}
            </S.InputColor>
          </S.InputColorWrapper>
        </S.ColorSection>
        <S.Divider />
        <S.ButtonColorSection>
          <S.Title size={EHeadingSize.H4} fontWeight={EHeadingWeight.SEMI_BOLD}>
            Botão de finalizar compra
          </S.Title>
          <S.ButtonColorInputWrapper>
            <S.InputColor
              {...register('button_hex_color', {
                value: appearance_customization?.button_hex_color,
              })}
              value={appearance_customization?.button_hex_color}
              onChange={event => {
                setValue('button_hex_color', event.target.value);
              }}
              placeholder="Digite o código da cor"
            >
              {/* <Tooltip url="https://images.freeimages.com/images/small-previews/aed/three-bees-on-sunflower-1337029.jpg"> */}
              Cor do botão
              {/* </Tooltip> */}
            </S.InputColor>
            <S.InputColor
              {...register('button_text_hex_color', {
                value: appearance_customization?.button_text_hex_color,
              })}
              value={appearance_customization?.button_text_hex_color}
              onChange={event => {
                setValue('button_text_hex_color', event.target.value);
              }}
              placeholder="Digite o código da cor"
            >
              {/* <Tooltip url="https://images.freeimages.com/images/small-previews/aed/three-bees-on-sunflower-1337029.jpg"> */}
              Cor do texto
              {/* </Tooltip> */}
            </S.InputColor>
          </S.ButtonColorInputWrapper>
        </S.ButtonColorSection>
        <S.Divider />
        <S.TypographySection>
          <S.Title size={EHeadingSize.H4} fontWeight={EHeadingWeight.SEMI_BOLD}>
            Tipografia
          </S.Title>
          <S.SelectWrapper>
            <S.LabelAndSelectWrapper>
              <S.Label>Fonte dos textos</S.Label>
              <S.Select {...register('text_font_family')} name="font-text">
                <S.Option value="SF Pro Display">
                  {appearance_customization?.text_font_family}
                </S.Option>
              </S.Select>
            </S.LabelAndSelectWrapper>
            <S.CheckboxGroup>
              <Controller
                control={control}
                name="metadata.is_text_uppercase"
                defaultValue={appearance_customization?.metadata?.is_text_uppercase}
                render={({ field }) => (
                  <S.Checkbox
                    id="metadata.is_text_uppercase"
                    checked={appearance_customization?.metadata?.is_text_uppercase}
                    value={field?.value?.toString()}
                    onChange={() => field?.onChange(!field.value)}
                  />
                )}
              />
              <S.CheckboxLabel htmlFor="metadata.is_text_uppercase">
                Letras em caixa alta
              </S.CheckboxLabel>
            </S.CheckboxGroup>
          </S.SelectWrapper>
          <S.SelectWrapper>
            <S.LabelAndSelectWrapper>
              <S.Label>Fonte dos títulos</S.Label>
              <S.Select {...register('title_font_family')} name="font-tile">
                <S.Option value="SF Pro Display">
                  {appearance_customization?.title_font_family}
                </S.Option>
              </S.Select>
            </S.LabelAndSelectWrapper>
            <S.CheckboxGroup>
              <Controller
                control={control}
                name="is_title_uppercase"
                defaultValue={appearance_customization?.is_title_uppercase}
                render={({ field }) => (
                  <S.Checkbox
                    id="is_title_uppercase"
                    checked={appearance_customization?.is_title_uppercase}
                    value={field?.value?.toString()}
                    onChange={() => field?.onChange(!field.value)}
                  />
                )}
              />
              <S.CheckboxLabel htmlFor="is_title_uppercase">Letras em caixa alta</S.CheckboxLabel>
            </S.CheckboxGroup>
          </S.SelectWrapper>

          <S.SelectWrapper>
            <S.LabelAndCheckboxWrapper>
              <S.LabelAndSelectWrapper>
                <S.Label>Fonte dos botões</S.Label>
                <S.Select {...register('metadata.button_font_family')} name="font-text">
                  <S.Option value="SF Pro Display">
                    {appearance_customization?.metadata?.button_font_family}
                  </S.Option>
                </S.Select>
              </S.LabelAndSelectWrapper>
            </S.LabelAndCheckboxWrapper>
            <S.CheckboxGroup>
              <Controller
                control={control}
                name="is_button_uppercase"
                defaultValue={appearance_customization?.is_button_uppercase}
                render={({ field }) => (
                  <S.Checkbox
                    id="is_button_uppercase"
                    checked={appearance_customization?.is_button_uppercase}
                    value={field?.value?.toString()}
                    onChange={() => field?.onChange(!field.value)}
                  />
                )}
              />
              <S.CheckboxLabel htmlFor="is_button_uppercase">Letras em caixa alta</S.CheckboxLabel>
            </S.CheckboxGroup>
          </S.SelectWrapper>
        </S.TypographySection>
      </S.Wrapper>
      <S.ButtonsWrapper>
        <S.CancelButton
          type="button"
          onClick={onCancelButtonClick}
          variant={EButtonVariant.SECONDARY}
        >
          Cancelar
        </S.CancelButton>
        <S.SubmitButton type="submit" isLoading={isUpdateCheckout}>
          Salvar
        </S.SubmitButton>
      </S.ButtonsWrapper>
    </S.Form>
  );
};

export default AppearanceOttawa;
