import React from 'react';

import { EBusinessModel } from '@domain/enums/common/onboarding/EUser';

import { useAnalytics } from '@store/context/common/AnalyticsContext';
import { useUserCheckout } from '@store/context/common/UserCheckout';

import CustomerCard from './CustomerCard';
import PaymentCard from './PaymentCard';
import ShippingCard from './ShippingCard';
import ProductCard from './ProductCard';
import CustomModal from '../CustomModal';

import * as S from './styles';

const Body: React.FC = () => {
  const { analytics } = useAnalytics();
  const { currentUserCheckout } = useUserCheckout();

  const isInfoproduct = currentUserCheckout?.business_type === EBusinessModel.INFOPRODUCT;

  React.useEffect(() => {
    analytics.track('Order Details Page Viewed');
  }, [analytics]);

  return (
    <S.Wrapper>
      <CustomModal />

      <S.CustomerAndPaymentWrapper>
        <CustomerCard />
        <PaymentCard />
      </S.CustomerAndPaymentWrapper>

      {!isInfoproduct && <ShippingCard />}

      <ProductCard />
    </S.Wrapper>
  );
};

export default Body;
