import React from 'react';
import { LinkProps } from 'react-router-dom';

import * as S from './styles';

const Link: React.FC<LinkProps & React.RefAttributes<HTMLAnchorElement>> = ({
  children,
  ...rest
}) => {
  return <S.LinkBase {...rest}>{children}</S.LinkBase>;
};

export default Link;
