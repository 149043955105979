import React from 'react';

import { useGetMyRatesContext } from '@store/common/myRates/GetMyRatesContext';

import { PaymentRate } from './PaymentRate';
import { OperationalFee } from './OperationalFee';
import { FinancialReserve } from './FinancialReserve';

import * as S from './styles';

const MyRates: React.FC = () => {
  const { rates, reserves } = useGetMyRatesContext();

  return (
    <S.Wrapper>
      <PaymentRate rates={rates?.payment} />
      <OperationalFee />
      <FinancialReserve rates={reserves?.rolling} />
    </S.Wrapper>
  );
};

export default MyRates;
