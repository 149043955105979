import React from 'react';
import { DotsThree, CheckCircle, XCircle, Clock, ArrowCounterClockwise } from 'phosphor-react';
import { useTheme } from 'styled-components/macro';
import { format } from 'date-fns';

import { EButtonVariant } from '@domain/enums/components/common/EButton';
import { EChipColor } from '@domain/enums/components/common/EChip';

import { useGetOrder } from '@store/common/order/GetOrder';

import PageHeader from '@components/common/utils/PageHeader';

import * as S from './styles';

const Header: React.FC = () => {
  const theme = useTheme();
  const { order, handleRefundModalOpen, showRefundButton } = useGetOrder();

  const orderStatus = order?.order.status;
  const orderId = order?.order.provider_id;
  const createdAt = order?.order.created_at ? new Date(order.order.created_at) : new Date();
  const formattedCreatedAt = `em ${format(createdAt, 'dd/MM/yyyy')} às ${format(
    createdAt,
    'HH:MM',
  )}`;

  const getChipByStatus = React.useCallback(() => {
    if (orderStatus === 'PAID') {
      return (
        <S.Chip backgroundColor={EChipColor.LIGHT_GREEN} textColor="#088443">
          <CheckCircle color="#088443" size={16} />
          PAGO
        </S.Chip>
      );
    }

    if (orderStatus === 'CANCELED' || orderStatus === 'FAILED' || orderStatus === 'DECLINED') {
      return (
        <S.Chip backgroundColor="#FBCDD2" textColor="#BD2C2D">
          <XCircle color="#BD2C2D" size={16} />
          CANCELADO
        </S.Chip>
      );
    }

    if (orderStatus === 'UNPAID') {
      return (
        <S.Chip backgroundColor="#FBCDD2" textColor="#BD2C2D">
          <XCircle color="#BD2C2D" size={16} />
          NÃO PAGO
        </S.Chip>
      );
    }

    if (orderStatus === 'REFUNDED' || orderStatus === 'PARTIALLY_REFUNDED') {
      return (
        <S.Chip backgroundColor="#E3E3E3" textColor="#282828">
          <XCircle color="#282828" size={16} />
          ESTORNADO
        </S.Chip>
      );
    }

    if (orderStatus === 'DISPUTED') {
      return (
        <S.Chip backgroundColor="#E3E3E3" textColor="#282828">
          <XCircle color="#282828" size={16} />
          CHARGEBACK
        </S.Chip>
      );
    }

    if (orderStatus === 'PENDING') {
      <S.Chip backgroundColor="#F6ECD7" textColor="#986500">
        <Clock color="#986500" size={16} />
        PENDENTE
      </S.Chip>;
    }

    return <></>;
  }, [orderStatus]);

  return (
    <S.Wrapper>
      <S.TitleAndButton>
        <S.TitleAndChip>
          <PageHeader title="Detalhes do pedido" goBackButton />
          {getChipByStatus()}
        </S.TitleAndChip>

        <S.WrapperButtonHeader>
          {showRefundButton && (
            <S.SendMessageButton onClick={handleRefundModalOpen} variant={EButtonVariant.SECONDARY}>
              <ArrowCounterClockwise weight="bold" size={14} color={theme.colors.neutral.darker} />
              Estornar
            </S.SendMessageButton>
          )}
          {/* <S.Dropdown> */}
          {/*  <S.DropdownTrigger> */}
          {/*    <S.DotsThreeButton variant={EButtonVariant.SECONDARY}> */}
          {/*      <DotsThree color={theme.colors.neutral.darker} size={18} weight="bold" /> */}
          {/*    </S.DotsThreeButton> */}
          {/*  </S.DropdownTrigger> */}

          {/*  <S.DropdownContent> */}
          {/*    <S.DropdownItem onClick={() => setIsOpenModal(state => !state)}> */}
          {/*      Alterar status de envio */}
          {/*    </S.DropdownItem> */}
          {/*  </S.DropdownContent> */}
          {/* </S.Dropdown> */}
        </S.WrapperButtonHeader>
      </S.TitleAndButton>
      <S.OrderIdAndDateWrapper>
        <S.Tooltip>
          <S.TooltipTrigger>
            <S.TooltipContent>
              <S.TooltipText>Ir para o pedido</S.TooltipText>
            </S.TooltipContent>
            <S.OrderIdText to={order?.shopify?.order_url || ''} target="_blank">
              {orderId}
            </S.OrderIdText>
          </S.TooltipTrigger>
        </S.Tooltip>
        <S.DateText>{formattedCreatedAt}</S.DateText>
      </S.OrderIdAndDateWrapper>
    </S.Wrapper>
  );
};

export default Header;
