import ShopifyImage from '@assets/images/common/dashboard/integrations/shopify.svg';
import ReportanaImage from '@assets/images/common/dashboard/integrations/reportanaIcon.svg';
import GTMImage from '@assets/images/common/dashboard/integrations/gtm.svg';
import GA4Image from '@assets/images/common/dashboard/integrations/ga4.svg';
import ProfitfyImage from '@assets/images/common/dashboard/integrations/profitfy.svg';

export const STORE_PROVIDER = [
  {
    name: 'Shopify',
    alias: 'shopify',
    icon: ShopifyImage,
    description: 'Instale o Checkout Transparente da Zouti na sua loja Shopify.',
  },
  {
    name: 'Profitfy',
    alias: 'profitfy',
    icon: ProfitfyImage,
    description:
      'Cresça seu negócio com segurança! Uma plataforma que organiza, centraliza e automatiza informações financeiras, de marketing e produto em um só lugar.',
  },
  {
    name: 'Google Analytics 4',
    alias: 'ga4',
    icon: GA4Image,
    description:
      'Analise o comportamento dos usuários em seu site e aplicativo para melhorar sua estratégia de marketing, conteúdo e produtos.',
  },
  {
    name: 'Google Tag Manager',
    alias: 'gtm',
    icon: GTMImage,
    description:
      'Gerencie códigos de rastreamento de forma simples para entender o comportamento dos usuários e melhorar estratégias de marketing.',
  },
  {
    name: 'Reportana',
    alias: 'reportana',
    icon: ReportanaImage,
    description:
      'Maximize suas métricas. Todas as funcionalidades que seu negócio precisa em apenas um só lugar!',
  },
];
