import styled from 'styled-components/macro';

import SwitchBase from '@components/common/Inputs/Switch';
import TypographyBase from '@components/common/DataDisplay/Typography';

export const AdditionalInformation = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const TitleAndSwitchWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

export const Switch = styled(SwitchBase)``;

export const AdditionalInformationHeader = styled.div`
  position: relative;
  width: 100%;
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px 8px 0 0;
`;

export const AdditionalInformationBody = styled.div`
  background: ${({ theme }) => theme.colors.neutral.white};
  width: 100%;
  height: 162px;
  border-radius: 0 0 8px 8px;
  display: flex;
  flex-direction: column;
  padding: 24px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
`;

export const AditionalInformationTitle = styled(TypographyBase)`
  color: ${({ theme }) => theme.colors.neutral.darker};
  text-transform: capitalize;
`;

export const Description = styled(TypographyBase)`
  color: ${({ theme }) => theme.colors.neutral.dark};
`;

export const ShopifyFullImg = styled.img`
  width: 100%;
`;

export const IconWrapper = styled.div`
  position: absolute;
  left: 24px;
  bottom: -18px;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.neutral.gray_white};
  padding: 8px 9px;
`;

export const Icon = styled.img`
  width: 100%;
  max-width: 20px;
`;
