import React from 'react';

import Content from '@components/pages/Dashboard/Pixel/UpdatePixel';
import { UpdatePixelProvider } from '@store/common/pixel/UpdatePixelContext';

const UpdatePixel: React.FC = () => {
  return (
    <UpdatePixelProvider>
      <Content />
    </UpdatePixelProvider>
  );
};

export default UpdatePixel;
