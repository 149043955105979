import styled, { css } from 'styled-components/macro';

import selectArrow from '@assets/images/components/select/CaretDown.svg';

interface ISelectStyleProps {
  readOnly?: boolean;
}

export const Container = styled.div`
  position: relative;
  width: fit-content;

  &:after {
    content: url(${selectArrow});
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 12px;
  }
`;

export const Label = styled.label``;

export const Select = styled.select<ISelectStyleProps>`
  width: 100%;
  height: 40px;
  padding: 8px 16px;
  border-radius: ${({ theme }) => theme.borderRadius.small};
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  background-color: #fff;
  color: ${({ theme }) => theme.colors.neutral.dark};
  font-family: ${({ theme }) => theme.fonts.typography};
  font-weight: 400;
  font-size: 14px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition: all 0.3s;

  &:active {
    outline: none;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.primary.dark_green_40};
    border-color: ${({ theme }) => theme.colors.primary.green_light};
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.colors.neutral.lightest};
    border-color: ${({ theme }) => theme.colors.neutral.lightest};
    color: ${({ theme }) => theme.colors.neutral.darker};
  }

  ${({ theme, readOnly }) =>
    readOnly &&
    css`
      cursor: not-allowed;
      pointer-events: none;
      touch-action: none;
      background-color: ${theme.colors.neutral.lighter};
    `}
`;
