import React from 'react';
import useSWR from 'swr';
import { useParams } from 'react-router-dom';

import { DASHBOARD_API_URL } from '@constants/services/apiUrl';
import { dashboardInstance } from '@services/common/dashboardInstance';

import { ISwr } from '@domain/interfaces/common/swr/ISwr';
import { IProduct } from '@domain/interfaces/common/product/IProducts';
import { IListProductsResponse } from '@domain/interfaces/hooks/services/products/IProductService';

import { useErrorHandler } from '@store/context/common/ErrorHandlerContext';

interface IProductProvider {
  products: IProduct[];
  totalProducts: number;
  page: number;
  mutate: any;
  isLoadingProducts: boolean;
  isProductError: boolean;
  totalPages: number;
  handlePageChange: (selectedItem: number) => void;
  handlePageLimit: (newPageLimit: number) => void;
  handleSelectedProduct: (newSelectedProduct: IProduct) => void;
  handleVariantsSideModalOpen: () => void;
  handleSearchName: (newSearchName: string) => void;
  searchName: string;
  variantSideModalOpen: boolean;
  selectedProduct: IProduct;
  pageLimit: number;
}

const ListProductsContext = React.createContext<IProductProvider | null>(null);

export const ListProductsProvider: React.FC = ({ children }) => {
  const { accountId, checkoutId } = useParams();
  const { handleError } = useErrorHandler();

  const [products, setProducts] = React.useState<Array<IProduct>>([]);
  const [page, setPage] = React.useState<number>(0);
  const [totalProducts, setTotalProducts] = React.useState<number>(0);
  const [totalPages, setTotalPages] = React.useState<number>(0);
  const [pageLimit, setPageLimit] = React.useState<number>(10);
  const [selectedProduct, setSelectedProduct] = React.useState<IProduct>({} as IProduct);
  const [variantSideModalOpen, setVariantsSideModalOpen] = React.useState<boolean>(false);
  const [searchName, setSearchName] = React.useState<string>('');

  dashboardInstance.defaults.headers.common['x-zouti-account'] = accountId;

  const PRODUCT_URL = `${DASHBOARD_API_URL}/accounts/${accountId}/checkouts/${checkoutId}/products?page=${
    page + 1
  }&limit=${pageLimit}&name=${encodeURIComponent(searchName)}`;

  const {
    data: requestResponseData,
    isLoading,
    isValidating,
    mutate,
    error,
  } = useSWR<ISwr<IListProductsResponse>>(PRODUCT_URL, dashboardInstance);

  const handlePageChange = React.useCallback(selectedItem => {
    setPage(selectedItem);
  }, []);

  const handlePageLimit = React.useCallback(newPageLimit => {
    setPageLimit(newPageLimit);
  }, []);

  const handleSearchName = React.useCallback(newSearchName => {
    setSearchName(newSearchName);
  }, []);

  const handleVariantsSideModalOpen = React.useCallback(() => {
    setVariantsSideModalOpen(currentState => !currentState);
  }, []);

  const handleSelectedProduct = React.useCallback(
    newSelectedProduct => {
      setSelectedProduct(newSelectedProduct);
      handleVariantsSideModalOpen();
    },
    [handleVariantsSideModalOpen],
  );

  React.useEffect(() => {
    if (error) {
      handleError(error);
    }

    if (requestResponseData?.data?.object) {
      setProducts(requestResponseData.data.object.data);
      setTotalProducts(requestResponseData.data.object.total);
      setTotalPages(requestResponseData.data.object.total_pages);
    }
  }, [requestResponseData, handleError, error]);

  const isLoadingProducts = isLoading || isValidating;
  const isProductError = Boolean(error);

  return (
    <ListProductsContext.Provider
      value={{
        products,
        totalProducts,
        isLoadingProducts,
        isProductError,
        page,
        totalPages,
        pageLimit,
        variantSideModalOpen,
        selectedProduct,
        searchName,
        handleSearchName,
        mutate,
        handlePageChange,
        handlePageLimit,
        handleSelectedProduct,
        handleVariantsSideModalOpen,
      }}
    >
      {children}
    </ListProductsContext.Provider>
  );
};

export const useListProducts = (): IProductProvider => {
  const context = React.useContext(ListProductsContext);

  if (!context) {
    throw new Error('useProduct must be used within provider');
  }

  return context;
};
