export enum EStoreOnboardingStep {
  FIRST_STEP = 'FIRST_STEP',
  SECOND_STEP = 'SECOND_STEP',
  THIRD_STEP = 'THIRD_STEP',
}

export enum EStoreSegment {
  DROPSHIPPING_INTERNATIONAL = 'DROPSHIPPING_INTERNATIONAL',
  DROPSHIPPING_NATIONAL = 'DROPSHIPPING_NATIONAL',
  DROPSHIPPING_GLOBAL = 'DROPSHIPPING_GLOBAL',
  INFOPRODUCT = 'INFOPRODUCT',
  ECOMMERCE = 'ECOMMERCE',
  OTHER = 'OTHER',
}
