import styled from 'styled-components';

import ButtonBase from '@components/common/Inputs/Button';
import HeadingBase from '@components/common/DataDisplay/Heading';
import ImageBase from '@components/common/DataDisplay/Image';
import TextBase from '@components/common/DataDisplay/Text';
import { Plus } from 'phosphor-react';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const Title = styled(HeadingBase)`
  margin-bottom: 8px;
`;

export const Description = styled(TextBase)`
  font-size: 1.6rem;
  text-align: center;
  max-width: 378px;
  margin-bottom: 24px;
  color: ${({ theme }) => theme.colors.neutral.base};
`;

export const PlusIcon = styled(Plus).attrs({
  size: 16,
  weight: 'bold',
})`
  color: ${({ theme }) => theme.colors.neutral.black};
`;

export const Button = styled(ButtonBase)``;

export const Image = styled(ImageBase)`
  width: 250px;
  height: 166px;
  margin-bottom: 40px;
`;
