import { AxiosResponse } from 'axios';

import {
  IListPaymentCredentialResponse,
  ICreatePaymentCredentialProps,
  ICreatePaymentCredentialResponse,
  IUpdatePaymentCredentialProps,
  IUpdatePaymentCredentialResponse,
  IDeletePaymentCredentialProps,
  IDeletePaymentCredentialResponse,
  IPaymentCredentialService,
} from '@domain/interfaces/hooks/services/paymentCredential/IPaymentCredentialService';

import { dashboardInstance } from '@services/common/dashboardInstance';

export const usePaymentCredentialService = (): IPaymentCredentialService => {
  const listPaymentCredential = async (): Promise<
    AxiosResponse<IListPaymentCredentialResponse>
  > => {
    return dashboardInstance.get('/accounts/:accountId/zouti-gateway-credentials');
  };

  const createPaymentCredential = async ({
    payload,
    accountId,
    checkoutId,
  }: ICreatePaymentCredentialProps): Promise<AxiosResponse<ICreatePaymentCredentialResponse>> => {
    return dashboardInstance.post(
      `accounts/${accountId}/checkouts/${checkoutId}/gateways/zouti/credentials`,
      { ...payload },
      {
        headers: {
          'x-zouti-account': accountId,
        },
      },
    );
  };

  const updatePaymentCredential = async ({
    paymentCredentialId,
    payload,
    accountId,
    checkoutId,
  }: IUpdatePaymentCredentialProps): Promise<AxiosResponse<IUpdatePaymentCredentialResponse>> => {
    return dashboardInstance.put(
      `accounts/${accountId}/checkouts/${checkoutId}/gateways/zouti/credentials/${paymentCredentialId}`,
      {
        ...payload,
      },
      {
        headers: {
          'x-zouti-account': accountId,
        },
      },
    );
  };

  const deletePaymentCredential = async ({
    paymentCredentialId,
    accountId,
    checkoutId,
  }: IDeletePaymentCredentialProps): Promise<AxiosResponse<IDeletePaymentCredentialResponse>> => {
    return dashboardInstance.delete(
      `accounts/${accountId}/checkouts/${checkoutId}/gateways/zouti/credentials/${paymentCredentialId}`,
      {
        headers: {
          'x-zouti-account': accountId,
        },
      },
    );
  };

  return {
    listPaymentCredential,
    createPaymentCredential,
    updatePaymentCredential,
    deletePaymentCredential,
  };
};
