export const TRANSACTION_ERRORS = [
  {
    code: '5F7250B57CC',
    description: 'Transação autorizada com sucesso.',
  },
  {
    code: 'D4A9B1F3341',
    description: 'Transação não autorizada. Referida (suspeita de fraude) pelo banco emissor.',
  },
  {
    code: '5412E232D3D',
    description: 'Transação não autorizada. Referida (suspeita de fraude) pelo banco emissor.',
  },
  {
    code: '470A63204E8',
    description: 'Não foi possível processar a transação.',
  },
  {
    code: '817294B551B',
    description: 'Transação não autorizada. Cartão bloqueado pelo banco emissor.',
  },
  {
    code: '11EE457FDD9',
    description: 'Transação não autorizada. Cartão inadimplente (Do not honor).',
  },
  {
    code: '608E562CB2C',
    description: 'Transação não autorizada. Cartão cancelado.',
  },
  {
    code: '0A45572BB3C',
    description: 'Transação negada.',
  },
  {
    code: '663D3887CEF',
    description: 'Transação não autorizada. Código de segurança inválido.',
  },
  {
    code: 'F0892979CAB',
    description: 'Transação cancelada parcialmente com sucesso.',
  },
  {
    code: '6B4511172659',
    description: 'Transação autorizada com sucesso para cartão emitido no exterior',
  },
  {
    code: '62A9F12B5F62',
    description: 'Transação inválida, erro no cartão.',
  },
  {
    code: 'BB8D613FE73D',
    description: 'Transação não permitida. Valor da transação Inválido.',
  },
  {
    code: '53191140B38F',
    description: 'Transação não autorizada. Cartão Inválido',
  },
  {
    code: '3A43E151409D',
    description: 'Banco emissor indisponível ou inexistente.',
  },
  {
    code: 'FAC3D19E07C0',
    description: '-',
  },
  {
    code: '9D51C21CBE6A',
    description: 'Cancelamento não efetuado. Transação não localizada.',
  },
  {
    code: '76D6922F6A24',
    description: 'Parcelamento inválido. Número de parcelas inválidas.',
  },
  {
    code: '1AE9E23BE412',
    description: 'Transação não autorizada. Valor da prestação inválido.',
  },
  {
    code: '4992524CC780',
    description: 'Quantidade de parcelas inválido.',
  },
  {
    code: '3B36B25F6B9B',
    description: 'Pedido de autorização não enviou número do cartão',
  },
  {
    code: '2920C2824417',
    description: 'Arquivo temporariamente indisponível.',
  },
  {
    code: '1D88C3044655',
    description: 'Transação não autorizada. Decline Message',
  },
  {
    code: '10923397857F',
    description: 'Transação não autorizada. Erro no banco emissor.',
  },
  {
    code: '7C218417AF1B',
    description: 'Transação não autorizada. Cartão bloqueado por perda.',
  },
  {
    code: 'BE8DF432F3F7',
    description: 'Transação não autorizada. Cartão bloqueado por roubo.',
  },
  {
    code: 'E233C46B0870',
    description: 'Conta encerrada',
  },
  {
    code: '18AF6517F0D6',
    description: 'Transação não autorizada. Limite excedido/sem saldo.',
  },
  {
    code: '5DC685225810',
    description: 'Cartão com dígito de controle inválido.',
  },
  {
    code: '2A01C53EBD3B',
    description: 'Transação não permitida. Cartão poupança inválido',
  },
  {
    code: '825F8548530A',
    description: 'Transação não autorizada. Cartão vencido',
  },
  {
    code: '638C6550CE64',
    description: 'Transação não autorizada. Senha inválida',
  },
  {
    code: '1207A563956A',
    description: 'Número do cartão não pertence ao emissor | Número do cartão inválido',
  },
  {
    code: '78A2257CFDA1',
    description: 'Transação não permitida para o cartão',
  },
  {
    code: '5F6355858D3E',
    description: 'Transação não permitida. Opção de pagamento inválida.',
  },
  {
    code: '44A3259EE409',
    description: 'Transação não autorizada pelo emissor do cartão. Suspeita de fraude',
  },
  {
    code: 'D7A716005513',
    description: 'Transação não autorizada.',
  },
  {
    code: '27B5E61B6097',
    description: 'Banco emissor indisponível.',
  },
  {
    code: '51D706227208',
    description: 'Transação não autorizada. Cartão restrito para uso doméstico',
  },
  {
    code: '00AD16323CCF',
    description: 'Transação não autorizada. Violação de segurança',
  },
  {
    code: '381D464099FF',
    description: 'Transação não autorizada. Valor abaixo do mínimo exigido pelo banco emissor.',
  },
  {
    code: '961AA6597AC8',
    description: 'Transação não autorizada. Excedida a quantidade de transações para o cartão.',
  },
  {
    code: '8632167E7B57',
    description: 'Transação não autorizada. Cartão bloqueado para compras hoje.',
  },
  {
    code: '1D75A708E633',
    description: 'Transação não autorizada. Limite excedido/sem saldo.',
  },
  {
    code: '1406372BFCE5',
    description: 'Cancelamento não efetuado. Saldo disponível para cancelamento insuficiente.',
  },
  {
    code: 'B59977425DFC',
    description: 'Transação não autorizada. A senha está vencida.',
  },
  {
    code: 'F8B8575C6012',
    description: 'Senha bloqueada. Excedeu tentativas de cartão.',
  },
  {
    code: '73AA576CA342',
    description: 'Cancelamento não efetuado. Banco emissor não localizou a transação original',
  },
  {
    code: 'BD01A7782129',
    description: 'Cancelamento não efetuado. Não foi localizado a transação original',
  },
  {
    code: '3D60D784C19F',
    description: 'Transação não autorizada. Cartão bloqueado primeiro uso.',
  },
  {
    code: '222487963E99',
    description: 'Transação não autorizada.',
  },
  {
    code: '2BE1580B7C36',
    description: 'Transação não autorizada. Divergência na data de transação/pagamento.',
  },
  {
    code: 'A7ED581D7A37',
    description: 'Transação não autorizada. A senha está vencida.',
  },
  {
    code: '132FC825374C',
    description: 'Transação não autorizada. Cartão inválido.',
  },
  {
    code: 'EC33F8341013',
    description: 'Transação não autorizada. Erro no controle de senhas (CVV)',
  },
  {
    code: '8E60F85306BE',
    description: 'Transação não permitida. Falha da operação.',
  },
  {
    code: '3905286D16C4',
    description: 'Transação não permitida. Falha da operação.',
  },
  {
    code: 'C0DDB88A7195',
    description: 'Falha na criptografia dos dados.',
  },
  {
    code: '5FEFA8902E45',
    description: 'Erro na transação.',
  },
  {
    code: '060739084754',
    description: 'Transação não permitida. Falha da operação.',
  },
  {
    code: '6111991B0185',
    description: 'Transação não autorizada. Banco emissor temporariamente indisponível.',
  },
  {
    code: 'EF3799256301',
    description: 'Transação não autorizada. Tempo de comunicação excedido.',
  },
  {
    code: '023EB93771D0',
    description: 'Transação não autorizada. Violação de regra, possível erro no cadastro.',
  },
  {
    code: '3A36194A0373',
    description: 'Transação duplicada.',
  },
  {
    code: 'D95A69657AE8',
    description: 'Falha no processamento.',
  },
  {
    code: 'DE69E97E3F2F',
    description: 'Valor não permitido para essa transação.',
  },
  {
    code: 'EF8589886076',
    description: 'Sistema/comunicação indisponível.',
  },
  {
    code: '8E6069963797',
    description: 'Sistema/comunicação indisponível.',
  },
  {
    code: '1C9A447503856',
    description: 'Timeout de Cancelamento',
  },
  {
    code: '4082C99910099',
    description: 'Sistema/comunicação indisponível.',
  },
  {
    code: '879DF99AE2A2B',
    description: 'Token não encontrado.',
  },
  {
    code: '182A699B82DD6',
    description: 'Sistema indisponível / Falha na comunicação',
  },
  {
    code: '38A8DA2DA8A3',
    description: 'Verifique os dados do cartão',
  },
  {
    code: '71211A3763B8',
    description: 'Erro no cartão',
  },
  {
    code: 'EFC83A5E0741',
    description: 'Transação não permitida',
  },
  {
    code: 'F0A50A7D7B28',
    description: 'Erro no cartão',
  },
  {
    code: '590A9AAF500D',
    description: 'Tempo Excedido',
  },
  {
    code: '8E94DAB211AE',
    description: 'Função incorreta (débito)',
  },
  {
    code: 'C0130ACCD8D6',
    description: 'Transação não permitida. Cartão de débito sendo usado com crédito.',
  },
  {
    code: '0586BAE42257',
    description: 'Tente Mais Tarde',
  },
  {
    code: 'F2266AFB9B50',
    description: 'Transação não permitida. Falha da operação.',
  },
  {
    code: '72C06AG86B8D',
    description: 'Transação não permitida. Falha da operação.',
  },
  {
    code: 'BB9E8AHAF39F',
    description: 'Transação não permitida. Cartão de crédito sendo usado com débito.',
  },
  {
    code: '39550AIC50F1',
    description: 'Transação não autorizada. Autenticação não foi realizada.',
  },
  {
    code: 'F9E29AJ71951',
    description:
      'Transação não permitida. Transação de crédito ou débito em uma operação que permite apenas Private Label.',
  },
  {
    code: 'A54D8AVFCA56',
    description: 'Transação não autorizada. Dados Inválidos',
  },
  {
    code: '918A8BDB3832',
    description: 'Transação não permitida. Falha da operação.',
  },
  {
    code: '6E3AABL1A09D',
    description: 'Transação não autorizada. Limite diário excedido.',
  },
  {
    code: 'DD89EBM5B9E3',
    description: 'Transação não autorizada. Cartão Inválido',
  },
  {
    code: '52332BN8793C',
    description: 'Transação não autorizada. Cartão ou conta bloqueado.',
  },
  {
    code: 'C5371BO977E5',
    description: 'Transação não permitida. Falha da operação.',
  },
  {
    code: 'B30B1BP80396',
    description: 'Transação não autorizada. Conta corrente inexistente.',
  },
  {
    code: '86BB8BP1768F15E',
    description: 'Transação não permitida.',
  },
  {
    code: 'EB608BV30858',
    description: 'Transação não autorizada. Cartão vencido',
  },
  {
    code: '60244CF429AA',
    description: 'Transação não autorizada. Falha na validação dos dados.',
  },
  {
    code: '71078CGD8B6A',
    description: 'Transação não autorizada. Falha na validação dos dados.',
  },
  {
    code: 'FEC03DAAD472',
    description: 'Transação não autorizada. Falha na validação dos dados.',
  },
  {
    code: '9D153DFDDDC6',
    description: 'Transação não permitida. Falha no cartão ou cartão inválido.',
  },
  {
    code: '68E4CDMC9E91',
    description: 'Transação não autorizada. Limite excedido/sem saldo.',
  },
  {
    code: '1D942DQ32780',
    description: 'Transação não autorizada. Falha na validação dos dados.',
  },
  {
    code: 'BFF4BDSF9615',
    description: 'Transação não permitida para o cartão',
  },
  {
    code: 'E685DEB24B01',
    description: 'Transação não autorizada. Limite diário excedido.',
  },
  {
    code: '6525EEE1974D',
    description: 'Transação não permitida. Valor da parcela inferior ao mínimo permitido.',
  },
  {
    code: '65D68EK3BCAE',
    description: 'Transação não permitida para o cartão',
  },
  {
    code: '9EC50FAC53B0',
    description: 'Transação não autorizada.',
  },
  {
    code: '30846FC0675F',
    description: 'Transação não autorizada. Ligue Emissor',
  },
  {
    code: 'DE8AEFD82A6E',
    description: 'Transação negada. Reter cartão condição especial',
  },
  {
    code: 'F1141FE758E2',
    description: 'Transação não autorizada. Divergência na data de transação/pagamento.',
  },
  {
    code: 'FCA52FF77550',
    description: 'Cancelamento OK',
  },
  {
    code: '328CFFG04500',
    description: 'Transação não autorizada. Ligue AmEx 08007285090.',
  },
  {
    code: '34111GAD60C7',
    description: 'Aguarde Contato',
  },
  {
    code: '58B87GD916CA',
    description: 'Transação não permitida.',
  },
  {
    code: '24E24HJ360E2',
    description: 'Transação não permitida. Código da operação inválido.',
  },
  {
    code: 'E49A4IAFDFB0',
    description: 'Transação não permitida. Indicador da operação inválido.',
  },
  {
    code: '4C3EBJB935DA',
    description: 'Transação não permitida. Valor da operação inválido.',
  },
  {
    code: 'DEEB1P56BBD4',
    description: 'Troca de senha / Desbloqueio',
  },
  {
    code: '1B3E4KA3833F',
    description: 'Transação não permitida. Falha na validação dos dados.',
  },
  {
    code: 'AD04EKB947F3',
    description: 'Transação não permitida. Selecionado a opção incorrente.',
  },
  {
    code: '5CB17KEB1FF9',
    description: 'Transação não autorizada. Falha na validação dos dados.',
  },
  {
    code: '45FFAN70322F',
    description: 'Transação não autorizada. Código de segurança inválido.',
  },
  {
    code: 'D5640R0B1F18',
    description: 'SUSPENSÃO DE PAGAMENTO RECORRENTE PARA UM SERVIÇO',
  },
  {
    code: '43AFBR101C55',
    description: 'Transação não autorizada. Cartão inadimplente (Do not honor)',
  },
  {
    code: '522A2R2204E3',
    description: 'TRANSAÇÃO NÃO QUALIFICADA PARA VISA PIN',
  },
  {
    code: '5106DR3347A0',
    description: 'SUSPENSÃO DE TODAS AS ORDENS DE AUTORIZAÇÃO',
  },
  {
    code: '01B9DRT2BFF3',
    description: 'Recusado em análise antifraude',
  },
  {
    code: '4AEDFU3CB515',
    description: 'Transação não permitida. Falha na validação dos dados.',
  },
  {
    code: 'FBE41N3F7BAC',
    description: 'SAQUE NÃO DISPONÍVEL',
  },
  {
    code: '32EDFN88AA1C',
    description: 'DIFERENÇA. PRÉ AUTORIZAÇÃO',
  },
  {
    code: '158E2NR6DFE6',
    description: 'Transação não permitida.',
  },
  {
    code: 'EFA46RP73F2A',
    description: 'Transação não permitida.',
  },
  {
    code: '879DF99AE2A2B',
    description: 'Token não encontrado',
  },
  {
    code: '182A699B82DD6',
    description: 'Sistema indisponível/Falha na comunicação',
  },
  {
    code: '724BE99CBAF08',
    description: 'Sistema indisponível/Exceção no processamento',
  },
  {
    code: '78B1F99ZAF0E7',
    description: 'Sistema indisponível/Retorno desconhecido',
  },
  {
    code: '2FC2399TA38EAC',
    description: 'Timeout na requisição. O tempo para receber o retorno da requisição excedeu.',
  },
  {
    code: '92C85AF015F9E8',
    description: 'Recusado manualmente em analise antifraude',
  },
  {
    code: '8C412AF0292961',
    description: 'Recusado automaticamente em analise antifraude',
  },
  {
    code: '7329AAF0384DB0',
    description: 'Recusado pelo antifraude da adquirente de crédito',
  },
  {
    code: '1EAD212640E43',
    description: 'A data de validade do cartão de crédito é inválida',
  },
  {
    code: 'A0587RE01D79',
    description:
      "Trava por excesso de 'retentativas' de uma transação que já foi negada pelo emissor.",
  },
];

export const findTransactionError = (code: string): string | undefined => {
  const foundError = TRANSACTION_ERRORS.find(error => error.code === code);

  return foundError?.description;
};
