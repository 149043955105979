import React from 'react';
import { useTheme } from 'styled-components';
import { CircleSpinner } from 'react-spinners-kit';
import logo from '@assets/images/common/logo.svg';
import { EnvelopeSimple, Lock, UserCircle } from 'phosphor-react';

import { useSignUpUserInvited } from '@store/context/public/signUpUserInvited/SignUpUserInvitedContext';

import * as S from './styles';
import CustomModal from './CustomModal';

const SignUpUserInvited: React.FC = () => {
  const theme = useTheme();
  const { register, handleSubmit, onSubmit, errors, isCreatingAccount, isVerifyTokenLoading } =
    useSignUpUserInvited();

  const nameRegister = register('name');
  const emailRegister = register('email');
  const passwordRegister = register('password');

  if (isVerifyTokenLoading) {
    return (
      <S.Wrapper>
        <CustomModal />
        <CircleSpinner size={64} color={theme.colors.primary.dark_green_40} />
      </S.Wrapper>
    );
  }

  return (
    <S.Form onSubmit={handleSubmit(onSubmit)}>
      <S.Box>
        <S.Logo src={logo} alt="Zouti" />

        <S.BoxDescription>Complete o cadastro para fazer parte da sua equipe</S.BoxDescription>
      </S.Box>
      <S.InputGroup>
        <S.Label>E-mail</S.Label>
        <S.TextField
          {...emailRegister}
          type="email"
          placeholder="Digite o seu e-mail"
          isError={Boolean(errors.email)}
          icon={EnvelopeSimple}
        />
        {errors.email && (
          <S.ErrorMessageText isFeedbackError>{errors.email.message}</S.ErrorMessageText>
        )}
      </S.InputGroup>
      <S.InputGroup>
        <S.Label>Nome completo</S.Label>
        <S.TextField
          {...nameRegister}
          type="text"
          placeholder="Digite o seu nome"
          isError={Boolean(errors.name)}
          icon={UserCircle}
        />
        {errors.name && (
          <S.ErrorMessageText isFeedbackError>{errors.name.message}</S.ErrorMessageText>
        )}
      </S.InputGroup>

      <S.InputGroup>
        <S.Label>Senha</S.Label>
        <S.TextField
          {...passwordRegister}
          type="password"
          placeholder="Digite sua senha"
          isError={Boolean(errors.password)}
          icon={Lock}
        />
        {errors.password && (
          <S.ErrorMessageText isFeedbackError>{errors.password.message}</S.ErrorMessageText>
        )}
      </S.InputGroup>

      <S.Text>
        Ao registrar-se na Zouti, você aceita os nossos <br />
        <S.Link isLinkForm to="https://zouti.com.br/terms-of-usage">
          Termos de serviço
        </S.Link>{' '}
        e{' '}
        <S.Link isLinkForm to="https://zouti.com.br/privacy-policy">
          Políticas de privacidade
        </S.Link>
      </S.Text>

      <S.SubmitButton type="submit" isLoading={isCreatingAccount}>
        Cadastrar
      </S.SubmitButton>
    </S.Form>
  );
};

export default SignUpUserInvited;
