import { AxiosResponse } from 'axios';

import { dashboardInstance } from '@services/common/dashboardInstance';
import {
  IAccountDataService,
  IGetAccountDataResponse,
  IUpdateAccountDataProps,
  IUpdateAccountDataResponse,
  IUpdateAccountLanguageProps,
} from '@domain/interfaces/hooks/services/accountData/IAccountDataService';

export const useAccountDataService = (): IAccountDataService => {
  const getAccountData = async (): Promise<AxiosResponse<IGetAccountDataResponse>> => {
    return dashboardInstance.get('accounts/:accountId');
  };

  const updateAccountData = async ({
    accountId,
    checkoutId,
    ...rest
  }: IUpdateAccountDataProps): Promise<AxiosResponse<IUpdateAccountDataResponse>> => {
    return dashboardInstance.put(
      `accounts/${accountId}/checkouts/${checkoutId}`,
      { ...rest },
      {
        headers: {
          'x-zouti-account': accountId,
        },
      },
    );
  };

  const updateAccountLanguage = async ({
    accountId,
    checkoutId,
    payload,
  }: IUpdateAccountLanguageProps): Promise<AxiosResponse<void>> => {
    return dashboardInstance.put(
      `accounts/${accountId}/checkouts/${checkoutId}/language`,
      payload,
      {
        headers: {
          'x-zouti-account': accountId,
        },
      },
    );
  };

  return { getAccountData, updateAccountData, updateAccountLanguage };
};
