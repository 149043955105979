import styled from 'styled-components/macro';

import TextFieldBase from '@components/common/Inputs/TextField';
import LabelBase from '@components/common/DataDisplay/Label';
import InputGroupBase from '@components/common/Inputs/InputGroup';
import ButtonBase from '@components/common/Inputs/Button';

export const SubmitButton = styled(ButtonBase)`
  margin-top: 24px;
`;

export const TextField = styled(TextFieldBase)``;

export const Label = styled(LabelBase)``;

export const InputGroup = styled(InputGroupBase)`
  margin-bottom: 16px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 380px;
  width: 100%;
`;
