import styled from 'styled-components/macro';
import ButtonBase from '@components/common/Inputs/Button';
import InputGroupBase from '@components/common/Inputs/InputGroup';
import LabelBase from '@components/common/DataDisplay/Label';
import TextAreaBase from '@components/common/Inputs/TextArea';
import TextFieldBase from '@components/common/Inputs/TextField';
import TextBase from '@components/common/DataDisplay/Text';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.small_16};
  background-color: ${({ theme }) => theme.colors.neutral.white};
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  padding: 24px;
`;

export const Form = styled.form``;

export const HeaderCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AvatarInputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.small_16};
`;

export const IconWrapper = styled.div`
  width: 62px;
  height: 62px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.primary.lemon_20};
  border: unset;
  padding: unset;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const InputFile = styled(TextFieldBase)`
  width: 80%;
  display: none;
`;

export const CustomFileInput = styled(LabelBase)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.small_8};
  width: 168px;
  height: 36px;
  font-size: 1.6rem;
  font-family: ${({ theme }) => theme.fonts.typography};
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  cursor: pointer;
`;

export const ImagePreview = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: fill;
`;

export const Label = styled(LabelBase)`
  font-size: 1.4rem;
`;

export const Input = styled(TextFieldBase)``;

export const InputGroup = styled(InputGroupBase)``;

export const Info = styled(TextBase)``;

export const InputAndInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StarsRatingGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
`;

export const StarsRatingWrapper = styled.div`
  display: flex;
  align-self: flex-end;
  gap: 4px;
`;

export const StarsRatingInput = styled.input`
  display: none;
`;

export const StarsRatingLabel = styled.label`
  display: inline-block;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

export const Textarea = styled(TextAreaBase)``;

export const DeleteSocialProofButton = styled(ButtonBase)`
  align-self: self-start;
  background-color: transparent;
  border: none;
  > span {
    color: ${({ theme }) => theme.colors.negative.base};
    font-size: 1.6rem;
    font-weight: 500;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  margin-top: ${({ theme }) => theme.spacing.small_24};
  align-items: center;
  justify-content: space-between;
`;

export const WrapperCancelAndSubmitButtons = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.small_16};
`;

export const SubmitButton = styled(ButtonBase)``;

export const CancelButton = styled(ButtonBase)`
  & > span {
    color: ${({ theme }) => theme.colors.neutral.darkest};
  }
`;
