import React from 'react';
import { WarningCircle } from 'phosphor-react';
import { useTheme } from 'styled-components';
import { useGetShipping } from '@store/common/shipping/GetShippingContext';
import { EButtonVariant } from '@domain/enums/components/common/EButton';

import * as S from './styles';

const CustomModal: React.FC = () => {
  const theme = useTheme();
  const {
    setIsOpenModal,
    isOpenModal,
    deleteCurrentShipping,
    isLoadingDeleteShipping,
    shippingDeletedInfo,
  } = useGetShipping();
  const { shippingId, name } = shippingDeletedInfo;

  const onDeleteShippingButtonClick = async (): Promise<void> => {
    await deleteCurrentShipping(shippingId);
  };

  return (
    <S.Modal toggle={() => setIsOpenModal(state => !state)} isOpen={isOpenModal}>
      <S.ModalHeader>
        <S.TitleAndWarningIconWrapper>
          <WarningCircle weight="fill" size={24} color={theme.colors.negative.base} />
          <S.HeaderTitle>Excluir frete</S.HeaderTitle>
        </S.TitleAndWarningIconWrapper>
        <S.HeaderCloseIcon
          onClick={() => setIsOpenModal(state => !state)}
          size={20}
          color={theme.colors.neutral.black}
        />
      </S.ModalHeader>
      <S.ModalContent>
        Você está prestes a remover a opção de frete {name}. Tem a certeza de que deseja prosseguir?
      </S.ModalContent>
      <S.ModalAction>
        <S.CancelButton
          variant={EButtonVariant.SECONDARY}
          onClick={() => setIsOpenModal(state => !state)}
        >
          Cancelar
        </S.CancelButton>
        <S.ConfirmButton isLoading={isLoadingDeleteShipping} onClick={onDeleteShippingButtonClick}>
          Confirmar
        </S.ConfirmButton>
      </S.ModalAction>
    </S.Modal>
  );
};

export default CustomModal;
