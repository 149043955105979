import React from 'react';
import { Controller } from 'react-hook-form';

import Text from '@components/common/DataDisplay/Text';
import { useUpdatePixel } from '@store/common/pixel/UpdatePixelContext';

import * as S from './styles';

const FacebookConversionForm: React.FC = () => {
  const { register, control, errors, pixel, setValue } = useUpdatePixel();
  const {
    send_boleto_purchase,
    send_pix_purchase,
    send_credit_card_purchase,
    name,
    provider_id,
    credentials,
  } = pixel;

  const pixelName = register('pixelName');
  const pixelId = register('pixelId');
  const accessToken = register('accessToken');

  React.useEffect(() => {
    setValue('pixelName', name);
    setValue('pixelId', provider_id);
    setValue('accessToken', credentials?.access_token);
  }, [setValue, credentials, provider_id, name]);

  return (
    <S.Wrapper>
      <S.InputGroup>
        <S.Label>Nome do Pixel</S.Label>
        <S.Input placeholder="Ex: Campanha de Remarketing" {...pixelName} type="text" />
        {errors?.pixelName && <Text isFeedbackError>{errors?.pixelName?.message}</Text>}
      </S.InputGroup>

      <S.InputGroup>
        <S.Label>ID do pixel</S.Label>
        <S.Input placeholder="Ex: 123456789012345" {...pixelId} type="text" />
        {errors?.pixelId && <Text isFeedbackError>{errors?.pixelId?.message}</Text>}
      </S.InputGroup>

      <S.InputGroup>
        <S.Label>Token de acesso</S.Label>
        <S.Input placeholder="Ex: EAAJZCkZB3UvIBALZAjVZBwZDZD" {...accessToken} type="text" />
        {errors?.accessToken && <Text isFeedbackError>{errors?.accessToken?.message}</Text>}
      </S.InputGroup>

      <S.CheckboxGroup>
        <Controller
          control={control}
          name="send_credit_card_purchase"
          defaultValue={send_credit_card_purchase}
          render={({ field }) => (
            <S.Checkbox
              checked={send_credit_card_purchase}
              value={field?.value?.toString()}
              onChange={() => field?.onChange(!field.value)}
            />
          )}
        />
        <S.CheckboxLabel htmlFor="send_credit_card_purchase">
          Marcar compras no cartão
        </S.CheckboxLabel>
      </S.CheckboxGroup>

      <S.CheckboxGroup>
        <Controller
          control={control}
          name="send_pix_purchase"
          defaultValue={send_pix_purchase}
          render={({ field }) => (
            <S.Checkbox
              checked={send_pix_purchase}
              value={field?.value?.toString()}
              onChange={() => field?.onChange(!field.value)}
            />
          )}
        />
        <S.CheckboxLabel htmlFor="send_pix_purchase">Marcar compras no pix</S.CheckboxLabel>
      </S.CheckboxGroup>

      <S.CheckboxGroup>
        <Controller
          control={control}
          name="send_boleto_purchase"
          defaultValue={send_boleto_purchase}
          render={({ field }) => (
            <S.Checkbox
              checked={send_boleto_purchase}
              value={field?.value?.toString()}
              onChange={() => field?.onChange(!field.value)}
            />
          )}
        />
        <S.CheckboxLabel htmlFor="send_boleto_purchase">Marcar compras no boleto</S.CheckboxLabel>
      </S.CheckboxGroup>
    </S.Wrapper>
  );
};

export default FacebookConversionForm;
