import React from 'react';

import { ResetPasswordProvider } from '@store/context/public/ResetPassword/ResetPasswordContext';

import ResetPasswordWrapper from '@components/pages/Public/ResetPassword/Wrapper';

const ResetPassword: React.FC = () => {
  return (
    <ResetPasswordProvider>
      <ResetPasswordWrapper />
    </ResetPasswordProvider>
  );
};

export default ResetPassword;
