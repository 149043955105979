import styled from 'styled-components/macro';

import HeadingBase from '@components/common/DataDisplay/Heading';
import InputGroupBase from '@components/common/Inputs/InputGroup';
import LabelBase from '@components/common/DataDisplay/Label';
import TextBase from '@components/common/DataDisplay/Text';
import TextFieldBase from '@components/common/Inputs/TextField';

import SelectBase from '@components/common/Inputs/Select';
import OptionBase from '@components/common/Inputs/Select/Option';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.small_24};

  & > button {
    align-self: flex-end;
  }
`;

export const WrapperSection = styled.div`
  gap: ${({ theme }) => theme.spacing.small_24};
  padding: ${({ theme }) => theme.spacing.small_24};
  background-color: ${({ theme }) => theme.colors.neutral.white};
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  border-radius: ${({ theme }) => theme.borderRadius.small};
`;

export const AddressSection = styled(WrapperSection)`
  display: grid;
  grid-template: repeat(5, auto) / repeat(3, 1fr);

  & > h1 {
    grid-area: 1 / 1 / 2 / 3;
  }

  & > div:nth-child(2) {
    grid-area: 2 / 1 / 3 / 2;
  }

  & > div:nth-child(3) {
    grid-area: 3 / 1 / 4 / 3;
  }

  & > div:nth-child(4) {
    grid-area: 3 / 3 / 4 / 4;
  }

  & > div:nth-child(5) {
    grid-area: 4 / 1 / 5 / 3;
  }

  & > div:nth-child(6) {
    grid-area: 4 / 3 / 5 / 4;
  }

  & > div:nth-child(7) {
    grid-area: 5 / 1 / 6 / 3;
  }

  & > div:nth-child(8) {
    grid-area: 5 / 3 / 6 / 4;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

export const Title = styled(HeadingBase)`
  font-size: 2rem;
`;

export const Label = styled(LabelBase)`
  margin: 0;
  font-size: 1.4rem;
`;

export const Span = styled(TextBase)`
  color: ${({ theme }) => theme.colors.neutral.base};
`;

export const Input = styled(TextFieldBase)``;

export const InputGroup = styled(InputGroupBase)`
  gap: ${({ theme }) => theme.spacing.small_8};
`;

export const MessageError = styled(TextBase)`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.negative.base};
`;

export const Select = styled(SelectBase)`
  min-width: 100%;
`;
export const Option = styled(OptionBase)``;
