import { useParams } from 'react-router-dom';
import React from 'react';
import useSWR from 'swr';

import { ISwr } from '@domain/interfaces/common/swr/ISwr';
import { ISocialProof } from '@domain/interfaces/common/socialProof/ISocialProof';
import { IGetSocialProofProvider } from '@domain/interfaces/store/common/socialProof/IGetSocialProofProvider';
import { IListSocialProofsResponse } from '@domain/interfaces/hooks/services/socialProof/ISocialProofService';

import { DASHBOARD_API_URL } from '@constants/services/apiUrl';
import { dashboardInstance } from '@services/common/dashboardInstance';

import { useToast } from '@store/context/common/ToastContext';
import { useErrorHandler } from '@store/context/common/ErrorHandlerContext';
import { useSocialProofService } from '@hooks/services/socialProof/useSocialProofService';
import { useAnalytics } from '@store/context/common/AnalyticsContext';

const GetSocialProofContext = React.createContext<IGetSocialProofProvider | null>(null);

export const GetSocialProofProvider: React.FC = ({ children }) => {
  const { toast } = useToast();
  const { analytics } = useAnalytics();
  const { handleError } = useErrorHandler();
  const { accountId, checkoutId } = useParams();
  const { deleteSocialProof, inactivateSocialProof, activateSocialProof } = useSocialProofService();

  const [isDeletingSocialProof, setIsDeletingSocialProof] = React.useState<boolean>(false);
  const [socialProofs, setSocialProofs] = React.useState<Array<ISocialProof>>([]);
  const [page] = React.useState<number>(0);
  const [totalActivatedSocialProofs, setTotalActivatedSocialProofs] = React.useState<number>(0);
  const [totalSocialProofs, setTotalSocialProofs] = React.useState<number>(0);
  const [totalPages] = React.useState<number>(1);

  dashboardInstance.defaults.headers.common['x-zouti-account'] = accountId;

  const SOCIAL_PROOF_URL = `${DASHBOARD_API_URL}/accounts/${accountId}/checkouts/${checkoutId}/socials?page=${page}&limit=10`;

  const { data, isLoading, isValidating, mutate, error } = useSWR<ISwr<IListSocialProofsResponse>>(
    SOCIAL_PROOF_URL,
    dashboardInstance,
  );

  React.useEffect(() => {
    if (error) {
      handleError(error);
    }

    if (data?.data?.object) {
      setSocialProofs(data.data.object);
      setTotalSocialProofs(data.data.object.length);
      const countObjectsWithoutInactivatedAt = data.data.object.filter(
        () => !Object.prototype.hasOwnProperty.call(data.data, 'inactivated_at'),
      ).length;
      setTotalActivatedSocialProofs(countObjectsWithoutInactivatedAt);
    }
  }, [data, handleError, error]);

  const deleteCurrentSocialProof = React.useCallback(
    async socialProofId => {
      setIsDeletingSocialProof(true);

      try {
        await deleteSocialProof({ socialProofId, accountId, checkoutId });

        analytics.track('Social Proof Deleted');

        await mutate();
        setIsDeletingSocialProof(false);
        toast.success('Prova social deletada com sucesso');
      } catch (err) {
        setIsDeletingSocialProof(false);
        handleError(err);
      } finally {
        setIsDeletingSocialProof(false);
      }
    },
    [deleteSocialProof, handleError, mutate, toast, accountId, checkoutId, analytics],
  );

  const inactivateCurrentSocialProof = React.useCallback(
    async (socialProofId: string) => {
      try {
        await inactivateSocialProof({ socialProofId, accountId, checkoutId });

        analytics.track('Social Proof Updated');

        await mutate();
      } catch (errorInactivateSocialProof) {
        handleError(errorInactivateSocialProof);
      }
    },
    [inactivateSocialProof, handleError, mutate, accountId, checkoutId, analytics],
  );

  const activateCurrentSocialProof = React.useCallback(
    async (socialProofId: string) => {
      try {
        await activateSocialProof({ socialProofId, accountId, checkoutId });

        analytics.track('Social Proof Updated');

        await mutate();
      } catch (errorActivateSocialProof) {
        handleError(errorActivateSocialProof);
      }
    },
    [activateSocialProof, handleError, mutate, accountId, checkoutId, analytics],
  );

  const isLoadingSocialProofs = isLoading || isValidating;
  const isSocialProofsError = Boolean(error);

  return (
    <GetSocialProofContext.Provider
      value={{
        socialProofs,
        isLoadingSocialProofs,
        isSocialProofsError,
        mutate,
        page,
        totalPages,
        totalSocialProofs,
        isDeletingSocialProof,
        deleteCurrentSocialProof,
        totalActivatedSocialProofs,
        inactivateCurrentSocialProof,
        activateCurrentSocialProof,
      }}
    >
      {children}
    </GetSocialProofContext.Provider>
  );
};

export const useGetSocialProof = (): IGetSocialProofProvider => {
  const context = React.useContext(GetSocialProofContext);

  if (!context) {
    throw new Error('useGetSocialProofContext must be used within provider');
  }

  return context;
};
