import { IOptionProps } from '@domain/interfaces/components/common/ITab';
import styled from 'styled-components/macro';

export const OptionWrapper = styled.div<IOptionProps>`
  display: flex;
  margin-bottom: 24px;
  flex-wrap: wrap;

  @media (min-width: 797px) {
    border-bottom: 3px solid ${({ theme }) => theme.colors.neutral.lightest};
  }
`;
