import React from 'react';
import ReactDOM from 'react-dom';
import { X as CancelIcon } from 'phosphor-react';

import { EHeadingSize } from '@domain/enums/components/common/EHeading';

import * as S from './styles';

interface ISideModalProps {
  isOpen: boolean;
  children?: React.ReactNode;
  toggle: () => void;
  tabIndex?: number;
  title?: string;
  blockOverflowYScroll?: boolean;
}

const SideModal: React.FC<ISideModalProps> = ({
  isOpen,
  children,
  toggle,
  blockOverflowYScroll = false,
  title,
  ...rest
}) => {
  const portalDiv = document.getElementById('portal');

  const onBackgroundClick = React.useCallback(
    event => {
      event.stopPropagation();

      toggle();
    },
    [toggle],
  );

  const onContentClick = React.useCallback(event => {
    event.stopPropagation();
  }, []);

  if (!portalDiv) return null;

  return ReactDOM.createPortal(
    <>
      {isOpen && (
        <>
          <S.ModalBackground onClick={onBackgroundClick} />

          <S.ModalContent
            onClick={onContentClick}
            blockOverflowYScroll={blockOverflowYScroll}
            {...rest}
          >
            <S.ModalContentWrapper>
              <S.CloseButtonWrapper className="close-button-wrapper">
                {title && <S.Title size={EHeadingSize.H2}>{title}</S.Title>}

                <S.CloseButton onClick={toggle}>
                  <CancelIcon size={18} color="gray" />
                </S.CloseButton>
              </S.CloseButtonWrapper>
              {children}
            </S.ModalContentWrapper>
          </S.ModalContent>
        </>
      )}
    </>,
    portalDiv,
  );
};

export default React.memo(SideModal);
