import React from 'react';
import useSWR from 'swr';
import { useParams } from 'react-router-dom';
import { subDays, format } from 'date-fns';

import { ISwr } from '@domain/interfaces/common/swr/ISwr';
import { IGetGeneralProvider } from '@domain/interfaces/store/common/general/IGetGeneralProvider';
import { IRevenue } from '@domain/interfaces/store/common/dashboard/IRevenue';
import { IOverview } from '@domain/interfaces/store/common/dashboard/IOverview';
import { ICheckoutChart } from '@domain/interfaces/store/common/dashboard/ICheckoutChart';
import { ICheckoutMetrics } from '@domain/interfaces/store/common/dashboard/ICheckoutMetrics';

import { DASHBOARD_API_URL } from '@constants/services/apiUrl';

import { dashboardInstance } from '@services/common/dashboardInstance';

import { useErrorHandler } from '@store/context/common/ErrorHandlerContext';

const GetGeneralContext = React.createContext<IGetGeneralProvider | null>(null);

export const GetGeneralProvider: React.FC = ({ children }) => {
  const { handleError } = useErrorHandler();
  const { accountId, checkoutId } = useParams();

  const [startDate, setStartDate] = React.useState<Date>(subDays(new Date(), 29));
  const [endDate, setEndDate] = React.useState<Date>(new Date());
  const [revenueData, setRevenueData] = React.useState<IRevenue | undefined>(undefined);
  const [overviewData, setOverviewData] = React.useState<IOverview | undefined>(undefined);
  const [checkoutChartData, setCheckoutChartData] = React.useState<ICheckoutChart | undefined>(
    undefined,
  );
  const [checkoutMetricsData, setCheckoutMetricsData] = React.useState<
    ICheckoutMetrics | undefined
  >(undefined);

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  dashboardInstance.defaults.headers.common['x-zouti-account'] = accountId;
  dashboardInstance.defaults.headers.common.user_timezone = timezone;

  const startOfDay = format(startDate, 'yyyy-MM-dd');
  const endOfDay = format(endDate, 'yyyy-MM-dd');

  const DASHBOARD_REVENUE_URL = `${DASHBOARD_API_URL}/accounts/${accountId}/checkouts/${checkoutId}/dashboards/revenue?start_date=${startOfDay}&end_date=${endOfDay}`;
  const DASHBOARD_OVERVIEW_URL = `${DASHBOARD_API_URL}/accounts/${accountId}/checkouts/${checkoutId}/dashboards/overview?start_date=${startOfDay}&end_date=${endOfDay}`;
  const DASHBOARD_CHECKOUT_CHART_URL = `${DASHBOARD_API_URL}/accounts/${accountId}/checkouts/${checkoutId}/dashboards/checkout/chart?start_date=${startOfDay}&end_date=${endOfDay}`;
  const DASHBOARD_CHECKOUT_METRICS_URL = `${DASHBOARD_API_URL}/accounts/${accountId}/checkouts/${checkoutId}/dashboards/checkout/metrics?start_date=${startOfDay}&end_date=${endOfDay}`;

  const {
    data: revenueDataResponse,
    isLoading: isLoadingRevenue,
    error: revenueError,
  } = useSWR<ISwr>(DASHBOARD_REVENUE_URL, dashboardInstance);
  const {
    data: overviewDataResponse,
    isLoading: isLoadingOverview,
    error: overviewError,
  } = useSWR<ISwr>(DASHBOARD_OVERVIEW_URL, dashboardInstance);
  const {
    data: checkoutChartDataResponse,
    isLoading: isLoadingCheckoutChart,
    error: checkoutChartError,
  } = useSWR<ISwr>(DASHBOARD_CHECKOUT_CHART_URL, dashboardInstance);
  const {
    data: checkoutMetricsDataResponse,
    isLoading: isLoadingCheckoutMetrics,
    error: checkoutMetricsError,
  } = useSWR<ISwr>(DASHBOARD_CHECKOUT_METRICS_URL, dashboardInstance);

  const handleStartDate = React.useCallback(newDate => setStartDate(newDate), []);

  const handleEndDate = React.useCallback(newDate => setEndDate(newDate), []);

  React.useEffect(() => {
    if (revenueError) {
      handleError(revenueError);
      return;
    }

    if (revenueDataResponse?.data.object) {
      setRevenueData(revenueDataResponse.data.object);
    }
  }, [revenueDataResponse, revenueError, handleError]);

  React.useEffect(() => {
    if (overviewError) {
      handleError(overviewError);
      return;
    }

    if (overviewDataResponse?.data.object) {
      setOverviewData(overviewDataResponse.data.object);
    }
  }, [overviewDataResponse, overviewError, handleError]);

  React.useEffect(() => {
    if (checkoutChartError) {
      handleError(checkoutChartError);
      return;
    }

    if (checkoutChartDataResponse?.data.object) {
      setCheckoutChartData(checkoutChartDataResponse.data.object);
    }
  }, [checkoutChartDataResponse, checkoutChartError, handleError]);

  React.useEffect(() => {
    if (checkoutMetricsError) {
      handleError(checkoutMetricsError);
      return;
    }

    if (checkoutMetricsDataResponse?.data.object) {
      setCheckoutMetricsData(checkoutMetricsDataResponse.data.object);
    }
  }, [checkoutMetricsDataResponse, checkoutMetricsError, handleError]);

  const isLoadingGeneral =
    isLoadingCheckoutChart || isLoadingCheckoutMetrics || isLoadingOverview || isLoadingRevenue;
  const isGeneralError =
    Boolean(checkoutChartError) ||
    Boolean(checkoutMetricsError) ||
    Boolean(overviewError) ||
    Boolean(revenueError);

  return (
    <GetGeneralContext.Provider
      value={{
        isLoadingGeneral,
        isGeneralError,
        handleEndDate,
        handleStartDate,
        startDate,
        endDate,
        checkoutChartData,
        checkoutMetricsData,
        overviewData,
        revenueData,
        isLoadingRevenue,
        isLoadingCheckoutChart,
        isLoadingOverview,
        isLoadingCheckoutMetrics,
      }}
    >
      {children}
    </GetGeneralContext.Provider>
  );
};

export const useGetGeneral = (): IGetGeneralProvider => {
  const context = React.useContext(GetGeneralContext);

  if (!context) {
    throw new Error('useGetGeneral must be used within provider');
  }

  return context;
};
