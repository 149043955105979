import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useSWR from 'swr';

import { ISwr } from '@domain/interfaces/common/swr/ISwr';

import { ISignUpUserInvitedContext } from '@domain/interfaces/store/context/public/signUpUserInvited/ISignUpUserInvitedContext';
import { ICreateUserInvitedData } from '@domain/interfaces/common/SignUpUserInvited/ISignUpUserInvited';

import { signUpUserInvitedSchema } from '@helpers/validators/pages/public/signUp';

import { useConfig } from '@store/context/common/ConfigContext';
import { useSignUpUserInvitedService } from '@hooks/services/signUpUserInvited/useSignUpUserInvitedService';
import { useErrorHandler } from '@store/context/common/ErrorHandlerContext';
import { useAnalytics } from '@store/context/common/AnalyticsContext';
import { useToast } from '@store/context/common/ToastContext';
import { useQuery } from '@hooks/common/useQuery';
import { DASHBOARD_API_URL } from '@constants/services/apiUrl';
import { dashboardInstance } from '@services/common/dashboardInstance';

const SignUpUserInvitedContext = React.createContext<ISignUpUserInvitedContext | null>(null);

export const SignUpUserInvitedProvider: React.FC = ({ children }) => {
  const query = useQuery();
  const { toast } = useToast();
  const { analytics } = useAnalytics();
  const { handleError } = useErrorHandler();
  const { handleSessionToken } = useConfig();
  const { createUserInvited, acceptInvite } = useSignUpUserInvitedService();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onChange',
    resolver: yupResolver(signUpUserInvitedSchema),
  });

  const membershipInviteToken = query?.get('membership_invite_token') || '';

  const VERIFY_TOKEN_URL = `${DASHBOARD_API_URL}/_public/membership_groups/verify_token?membership_invite_token=${membershipInviteToken}`;

  const { data: verifyTokeData, error: verifyTokenError } = useSWR<ISwr<{ object: string }>>(
    VERIFY_TOKEN_URL,
    dashboardInstance,
  );

  const [isCreatingAccount, setIsCreatingAccount] = React.useState<boolean>(false);
  const [isVerifyTokenLoading, setIsVerifyTokenLoading] = React.useState<boolean>(false);
  const [isOpenModal, setIsOpenModal] = React.useState<boolean>(false);

  const acceptCurrentInvite = React.useCallback(
    async (userId: string) => {
      try {
        if (membershipInviteToken) {
          await acceptInvite({ userId, membershipInviteToken });
          toast.success('Convite aceito com sucesso!');
          window.location.href = `${window.location.origin}/loading?membership_invite_token=${membershipInviteToken}`;
        }
      } catch (error: any) {
        handleError(error);
      }
    },
    [membershipInviteToken, acceptInvite, handleError, toast],
  );

  React.useEffect(() => {
    setIsVerifyTokenLoading(true);
    if (verifyTokenError) {
      handleError(verifyTokenError);
      const msgError = verifyTokenError?.response?.data?.message;
      if (msgError === 'Token expired') setIsOpenModal(true);

      setTimeout(() => {
        setIsOpenModal(false);
        setIsVerifyTokenLoading(false);
        window.location.href = `${window.location.origin}/loading`;
      }, 5000);
    }

    if (verifyTokeData?.data?.object) {
      setIsVerifyTokenLoading(false);
    }
  }, [verifyTokenError, handleError, verifyTokeData]);

  const onSubmit = React.useCallback(
    async data => {
      setIsCreatingAccount(true);

      try {
        const payload: ICreateUserInvitedData = {
          name: data.name,
          email: data.email,
          password: data.password,
        };

        const { data: userData } = await createUserInvited({ payload });

        analytics.identify(userData.object.user.id, {
          name: userData.object.user.name,
          email: userData.object.user.email,
          created_at: userData.object.user.created_at,
        });

        analytics.track('User Invited Signed Up ', { email: userData.object.user.email });

        handleSessionToken(`ZSID=${userData.object.token}`);

        setIsCreatingAccount(false);
        acceptCurrentInvite(userData?.object.user.id);
        toast.success('Conta criada com sucesso!');
      } catch (error) {
        setIsCreatingAccount(false);
        handleError(error);
      }
    },
    [createUserInvited, handleSessionToken, handleError, analytics, acceptCurrentInvite, toast],
  );

  return (
    <SignUpUserInvitedContext.Provider
      value={{
        errors,
        isVerifyTokenLoading,
        handleSubmit,
        onSubmit,
        register,
        isCreatingAccount,
        acceptCurrentInvite,
        isOpenModal,
        setIsOpenModal,
        // verifyCurrentInviteToken,
      }}
    >
      {children}
    </SignUpUserInvitedContext.Provider>
  );
};

export const useSignUpUserInvited = (): ISignUpUserInvitedContext => {
  const context = React.useContext(SignUpUserInvitedContext);

  if (!context) {
    throw new Error('useSignUpUserInvited must be used within SignUpUserInvitedProvider');
  }

  return context;
};
