import styled from 'styled-components/macro';
import { CaretDown, CaretUp } from 'phosphor-react';

import TextBase from '@components/common/DataDisplay/Text';
import CheckboxBase from '@components/common/Inputs/CheckboxNew';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border-top: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  border-right: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  border-left: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  background-color: ${({ theme }) => theme.colors.neutral.white};
`;

export const HeaderAccordion = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 80px 12px 24px;
`;

export const Accordion = styled.button<{ currentIndex: number; activeIndex: number | null }>`
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.neutral.gray_darker};
  cursor: pointer;
  padding: 12px 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  text-align: left;
  transition: 0.4s;

  &:nth-child(2) {
    border-top: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  }

  &:last-of-type {
    border-radius: ${({ currentIndex, activeIndex }) =>
      currentIndex === activeIndex ? '0' : '0 0 8px 8px'};
  }
`;

export const Panel = styled.div<{ currentIndex: number; activeIndex: number | null }>`
  padding: ${({ currentIndex, activeIndex }) =>
    currentIndex === activeIndex ? '12px 24px' : '0 24px'};
  display: ${({ currentIndex, activeIndex }) => (currentIndex === activeIndex ? 'block' : 'none')};
  overflow: hidden;
  border-bottom: ${({ currentIndex, activeIndex, theme }) =>
    currentIndex === activeIndex ? `1px solid ${theme.colors.neutral.lightest}` : 'none'};
  transition: max-height 0.2s ease-out;

  &:last-child {
    border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
    border-radius: 0 0 8px 8px;
  }
`;

export const PanelContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 80px;
`;

export const CaretDownIcon = styled(CaretDown).attrs({
  weight: 'bold',
  size: 16,
})`
  color: ${({ theme }) => theme.colors.neutral.darker};
`;

export const CaretTopIcon = styled(CaretUp).attrs({
  weight: 'bold',
  size: 16,
})`
  color: ${({ theme }) => theme.colors.neutral.darker};
`;

export const TextHeaderCard = styled(TextBase)`
  font-weight: 500;
`;

export const TextActionsHeaderWrapper = styled.div`
  display: flex;
  gap: 41px;
`;

export const IconAndTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
`;

export const TextItemAccordionContent = styled(TextBase)``;

export const AccordionTitle = styled(TextBase).attrs({ role: 'title' })`
  font-weight: 500;
`;

export const Checkbox = styled(CheckboxBase)``;

export const CheckboxGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 67px;
`;
