import React from 'react';

import { useListCheckout } from '@store/common/checkoutCustomization/ListCheckoutCustomizationsContext';
import { useAnalytics } from '@store/context/common/AnalyticsContext';

import TableRow from './TableRow';

import * as S from './styles';

const Body: React.FC = () => {
  const { checkouts } = useListCheckout();
  const { analytics } = useAnalytics();

  React.useEffect(() => {
    analytics.track('Checkout Customizations Page Viewed');
  }, [analytics]);

  return (
    <S.Wrapper>
      <S.Table>
        <S.Table.Header>
          <S.Table.Row>
            <S.Table.Head />
            <S.Table.Head>Nome</S.Table.Head>
          </S.Table.Row>
        </S.Table.Header>

        <S.Table.Body>
          {checkouts?.map(item => (
            <TableRow items={item} key={item.id} />
          ))}
        </S.Table.Body>
      </S.Table>
    </S.Wrapper>
  );
};

export default Body;
