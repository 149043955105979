import styled from 'styled-components/macro';

import { Info as Icon } from 'phosphor-react';
import CustomTooltipBase from '@components/common/utils/CustomTooltip';
import ButtonBase from '@components/common/Inputs/Button';
import CheckboxBase from '@components/common/Inputs/Checkbox';
import HeadingBase from '@components/common/DataDisplay/Heading';
import InputGroupBase from '@components/common/Inputs/InputGroup';
import LabelBase from '@components/common/DataDisplay/Label';
import TextBase from '@components/common/DataDisplay/Text';
import TextFieldBase from '@components/common/Inputs/TextField';

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.neutral.white};
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  border-radius: 8px;
  padding: 24px;
`;

export const Form = styled.form``;

export const Divider = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.neutral.lightest};
  margin: 16px 0;
`;

export const Label = styled(LabelBase)`
  margin: 0;
  font-weight: 500;
`;

export const Input = styled(TextFieldBase)``;

export const InputGroup = styled(InputGroupBase)`
  gap: 8px;
`;

export const MessageError = styled(TextBase)`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.negative.base};
`;

export const Text = styled(TextBase)``;

export const Title = styled(HeadingBase)`
  color: ${({ theme }) => theme.colors.neutral.darkest};
`;

export const NameSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const TextInfo = styled(TextBase)`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.neutral.dark};
`;

export const Checkbox = styled(CheckboxBase)``;

export const CheckboxLabel = styled(LabelBase)`
  margin: 0;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.neutral.darkest};
  cursor: pointer;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  gap: 24px;
`;

export const CheckboxAndTooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const CheckboxGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const DiscountSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const LabelAndIcon = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const InputWrapper = styled.div`
  display: flex;
  gap: 16px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const FinalOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ValueInputWrapperCouple = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

export const ValueWrapperCouple = styled.div`
  display: flex;
  align-content: center;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  border-radius: 4px;

  & > div:nth-child(2) {
    border: unset;
    width: 100%;
    border-radius: unset;
    border-left: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
    border-right: 1px solid ${({ theme }) => theme.colors.neutral.lightest};

    & > input {
      width: 100%;
      text-align: left;
    }
  }
`;

export const CurrencyLabel = styled.div`
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  font-family: ${({ theme }) => theme.fonts.text};
  font-weight: 700;
  color: ${({ theme }) => theme.colors.neutral.darkest};
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 24px;
  gap: 16px;
`;

export const SubmitButton = styled(ButtonBase)``;

export const CancelButton = styled(ButtonBase)`
  & > span {
    color: ${({ theme }) => theme.colors.neutral.dark} !important;
  }
`;

export const Tooltip = styled(CustomTooltipBase)``;

export const TooltipTrigger = styled(CustomTooltipBase.Trigger)`
  height: 13px;
  width: 14px;
`;

export const TooltipContent = styled(CustomTooltipBase.Content)``;

export const InfoIcon = styled(Icon)``;

export const TooltipText = styled(TextBase)`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.neutral.gray_white};
`;
