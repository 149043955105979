import * as Yup from 'yup';

const maxSize = 5 * 1024 * 1024;
const validFileExtensions = ['jpg', 'png', 'jpeg', 'webp'];

export const createSocialProofSchema = Yup.object().shape({
  name: Yup.string().required('Este campo é obrigatório!'),
  testimony: Yup.string().required('Este campo é obrigatório!'),
  image_url: Yup.string().url('URL da imagem inválida!'),
  image_path: Yup.string(),
  qtd_stars: Yup.number()
    .required('Este campo é obrigatório!')
    .min(1, 'Quantidade de estrelas não pode ser menor que 1!')
    .max(5, 'Quantidade de estrelas não pode ser maior que 5!'),
  photo: Yup.mixed()
    .test('is-file', 'Foto é obrigatório!', value => {
      return value instanceof File;
    })
    .test('file-type', 'Tipo de arquivo inválido! Tipos válidos: jpg, png, jpeg, webp.', value => {
      if (value instanceof File) {
        const fileExtension = value.name.split('.').pop()?.toLowerCase();
        return fileExtension ? validFileExtensions.includes(fileExtension) : false;
      }
      return false;
    })
    .test('file-size', 'Tamanho da foto não pode ser maior que 5MB!', value => {
      if (value instanceof File) {
        return value.size <= maxSize;
      }
      return false;
    }),
});

export const updateSocialProofSchema = Yup.object().shape({
  name: Yup.string().required('Este campo é obrigatório!'),
  testimony: Yup.string().required('Este campo é obrigatório!'),
  image_url: Yup.string().url('URL da imagem inválida!'),
  image_path: Yup.string(),
  qtd_stars: Yup.number()
    .required('Este campo é obrigatório!')
    .min(1, 'Quantidade de estrelas não pode ser menor que 1!')
    .max(5, 'Quantidade de estrelas não pode ser maior que 5!'),
});
