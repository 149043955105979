import React from 'react';

import { useUsersContext } from '@store/common/users/UsersContext';
import { TableRow } from './TableRow';

import * as S from './styles';
import DeleteGroupModal from '../../DeleteModel';

const Users: React.FC = () => {
  const {
    users,
    isOpenDeleteModal,
    onToggleDeleteModel,
    deleteUserData,
    deleteCurrentUser,
    isDeleteUserLoading,
  } = useUsersContext();

  return (
    <S.Wrapper>
      <DeleteGroupModal
        title="Remover usuário"
        handleDelete={deleteCurrentUser}
        isOpen={isOpenDeleteModal}
        isSuccessButtonLoading={isDeleteUserLoading}
        toggle={onToggleDeleteModel}
        contentText={`Você está prestes a remover o usuário ${deleteUserData?.userName}. Essa ação é irreversível. Deseja continuar?`}
      />
      <S.Table>
        <S.TableHeader>
          <S.Table.Row>
            {/* <S.Table.Head>
              <S.Checkbox />
            </S.Table.Head> */}
            <S.Table.Head>Nome</S.Table.Head>
            <S.Table.Head>E-mail</S.Table.Head>
            <S.Table.Head>Grupo</S.Table.Head>
            {/* <S.Table.Head>Último login</S.Table.Head> */}
            <S.Table.Head />
          </S.Table.Row>
        </S.TableHeader>

        <S.Table.Body>
          {users.map((user, index) => (
            <TableRow user={user} key={index.toFixed()} />
          ))}
        </S.Table.Body>
      </S.Table>
    </S.Wrapper>
  );
};

export default Users;
