import React from 'react';

import ZoutiFullImage from '@assets/images/common/zouti/zouti-banner.svg';
import ZoutiSimpleImage from '@assets/images/common/zouti/zouti-brand.svg';

// import { usePaymentCredential } from '@store/common/paymentCredential/PaymentCredentialContext';

import { useTheme } from 'styled-components';

import { ETypographySize, ETypographyWeight } from '@domain/enums/components/common/ETypography';

import * as S from './styles';
// TODO: COMMENT SWITCH CODE

const ZoutiPay: React.FC = () => {
  // const { paymentCredential } = usePaymentCredential();
  // const [isActive, setIsActive] = React.useState<boolean>(Boolean(false));

  // const onCheckboxChange = React.useCallback(event => {
  //   setIsActive(event.target.checked);
  // }, []);

  const theme = useTheme();
  return (
    <S.AdditionalInformation>
      <S.AdditionalInformationHeader>
        <S.ShopifyFullImg src={ZoutiFullImage} alt="Shopify imagem completa" />
        <S.IconWrapper>
          <S.Icon src={ZoutiSimpleImage} alt="Integration image" />
        </S.IconWrapper>
      </S.AdditionalInformationHeader>

      <S.AdditionalInformationBody>
        <S.TitleAndSwitchWrapper>
          <S.AditionalInformationTitle
            size={ETypographySize.h6_button_medium}
            weight={ETypographyWeight.medium}
          >
            Zouti Pay
          </S.AditionalInformationTitle>

          {/* <S.Switch
            id="installZoutiPay"
            name="installZoutiPay"
            checked={isActive}
            onChange={onCheckboxChange}
          /> */}
        </S.TitleAndSwitchWrapper>

        <S.Description size={ETypographySize.medium_text}>
          Aceite pagamentos de forma segura e conveniente com a integração do Gateway Zouti.
        </S.Description>
      </S.AdditionalInformationBody>
    </S.AdditionalInformation>
  );
};

export default ZoutiPay;
