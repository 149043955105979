import React from 'react';

interface IPixProps {
  size?: string;
  fillColor?: string;
}

const PIX: React.FC<IPixProps> = ({ size = '20', fillColor = '#4AB7A8' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.478 14.2365C13.8497 14.2365 13.2608 13.9949 12.816 13.5501L10.4202 11.1543C10.2556 10.9896 9.95665 10.9896 9.79198 11.1543L7.38634 13.5599C6.94155 14.0047 6.35267 14.2463 5.72441 14.2463H5.25098L8.29472 17.2901C9.24158 18.2369 10.7872 18.2369 11.734 17.2901L14.7867 14.2374L14.478 14.2365Z"
        fill={fillColor}
      />
      <path
        d="M5.71472 5.75299C6.34298 5.75299 6.93186 5.99463 7.37665 6.43942L9.78229 8.84506C9.95591 9.01868 10.236 9.01868 10.4105 8.84506L12.8162 6.44927C13.261 6.00447 13.8499 5.76284 14.4781 5.76284H14.7681L11.7154 2.71015C10.7685 1.76328 9.22294 1.76328 8.27608 2.71015L5.23145 5.75299H5.71472Z"
        fill={fillColor}
      />
      <path
        d="M17.289 8.28493L15.4436 6.43953C15.4051 6.45922 15.3568 6.46817 15.3084 6.46817H14.4681C14.0331 6.46817 13.608 6.64179 13.3091 6.95145L10.9124 9.34724C10.6905 9.56919 10.3906 9.68553 10.1007 9.68553C9.80087 9.68553 9.51091 9.56919 9.28896 9.34724L6.88332 6.9416C6.57456 6.63284 6.14946 6.45833 5.72435 6.45833H4.69068C4.64235 6.45833 4.60387 6.44848 4.56539 6.42969L2.71015 8.28493C1.76328 9.23179 1.76328 10.7774 2.71015 11.7242L4.55554 13.5696C4.59403 13.5499 4.63251 13.541 4.68084 13.541H5.71451C6.14946 13.541 6.57456 13.3674 6.87348 13.0577L9.27911 10.6521C9.71406 10.2171 10.4775 10.2171 10.9115 10.6521L13.3073 13.0479C13.6161 13.3566 14.0412 13.5312 14.4663 13.5312H15.3066C15.355 13.5312 15.3934 13.541 15.4418 13.5598L17.2872 11.7144C18.2358 10.7675 18.2358 9.23179 17.289 8.28493Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default PIX;
