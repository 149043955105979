import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  background-color: #fff;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  padding: 32px 32px 16px 32px;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 512px;
  width: 100%;
`;
