import React from 'react';
import { useNavigate } from 'react-router-dom';
import Gleap from 'gleap';

import { IConfigContext } from '@domain/interfaces/store/context/IConfigContext';

import { getEnvironment } from '@utils/common/environment';

import { useLocalStorage } from '@hooks/common/useLocalStorage';
import { useSession } from './SessionContext';

const ConfigContext = React.createContext<IConfigContext | null>(null);

export const ConfigProvider: React.FC = ({ children }) => {
  const navigate = useNavigate();
  const { addMetadata, identifyUser } = useSession();

  const [user, setUser] = useLocalStorage(`@zouti-dashboard:${getEnvironment()}/user`, null);
  const [zoutiAccount, setZoutiAccount] = useLocalStorage(
    `@zouti-dashboard:${getEnvironment()}/x-zouti-account`,
    null,
  );
  const [sessionToken, setSessionToken] = useLocalStorage(
    `@zouti-dashboard:${getEnvironment()}/token`,
    null,
  );

  const handleSessionToken = React.useCallback(token => setSessionToken(token), [setSessionToken]);

  const handleUser = React.useCallback(newUser => setUser(newUser), [setUser]);

  const handleZoutiAccount = React.useCallback(
    account => setZoutiAccount(account),
    [setZoutiAccount],
  );

  const handleLogout = React.useCallback(() => {
    setUser(null);
    setZoutiAccount(null);
    setSessionToken(null);
    navigate('/');
  }, [navigate, setUser, setZoutiAccount, setSessionToken]);

  React.useEffect(() => {
    if (user) {
      Gleap.identify(user?.user?.email, {
        name: user?.user?.name,
        email: user?.user?.email,
      });
    }
  }, [user]);

  React.useEffect(() => {
    if (!user?.user) return;

    identifyUser(user.user?.id);
    addMetadata('email', user.user?.email);
  }, [user, identifyUser, addMetadata]);

  return (
    <ConfigContext.Provider
      value={{
        user,
        handleUser,
        handleZoutiAccount,
        zoutiAccount,
        handleSessionToken,
        sessionToken,
        handleLogout,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};

export const useConfig = (): IConfigContext => {
  const context = React.useContext(ConfigContext);

  if (!context) {
    throw new Error('useConfig must be used within ConfigProvider');
  }

  return context;
};
