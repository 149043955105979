import { AxiosResponse } from 'axios';

import {
  ICheckoutCustomizationService,
  IListCheckoutCustomizationResponse,
  ICreateCheckoutCustomizationResponse,
  IUpdateCheckoutCustomizationResponse,
  IUpdateCheckoutCustomizationProps,
  ICreateCheckoutCustomizationProps,
  IDeleteCheckoutCustomizationProps,
  IDeleteCheckoutCustomizationResponse,
  IActivateCheckoutCustomizationProps,
  IUploadCheckoutCustomizationProps,
  IUploadCheckoutCustomizationResponse,
  IDeleteUploadCheckoutCustomizationProps,
  IDeleteUploadCheckoutCustomizationResponse,
} from '@domain/interfaces/hooks/services/checkoutCustomization/ICheckoutCustomizationService';

import { dashboardInstance } from '@services/common/dashboardInstance';

export const useCheckoutCustomizationService = (): ICheckoutCustomizationService => {
  const listCheckoutCustomization = async (): Promise<
    AxiosResponse<IListCheckoutCustomizationResponse>
  > => {
    return dashboardInstance.get('accounts/:accountId/checkout/customizations');
  };

  const createCheckoutCustomization = async ({
    accountId,
    checkoutId,
    payload,
  }: ICreateCheckoutCustomizationProps): Promise<
    AxiosResponse<ICreateCheckoutCustomizationResponse>
  > => {
    return dashboardInstance.post(
      `accounts/${accountId}/checkouts/${checkoutId}/checkout_customizations`,
      payload,
      {
        headers: {
          'x-zouti-account': accountId,
        },
      },
    );
  };

  const updateCheckoutCustomization = async ({
    accountId,
    checkoutId,
    checkoutCustomizationId,
    checkout_theme,
    payload,
  }: IUpdateCheckoutCustomizationProps): Promise<
    AxiosResponse<IUpdateCheckoutCustomizationResponse>
  > => {
    return dashboardInstance.put(
      `accounts/${accountId}/checkouts/${checkoutId}/checkout_customizations/${checkoutCustomizationId}`,
      { ...payload, checkout_theme },
      {
        headers: {
          'x-zouti-account': accountId,
        },
      },
    );
  };

  const activateCheckoutCustomization = async ({
    accountId,
    checkoutId,
    checkoutCustomizationId,
  }: IActivateCheckoutCustomizationProps): Promise<
    AxiosResponse<IUpdateCheckoutCustomizationResponse>
  > => {
    return dashboardInstance.put(
      `accounts/${accountId}/checkouts/${checkoutId}/checkout_customizations/${checkoutCustomizationId}/activate`,
      {},
      {
        headers: {
          'x-zouti-account': accountId,
        },
      },
    );
  };

  const deleteCheckoutCustomization = async ({
    accountId,
    checkoutId,
    checkoutCustomizationId,
  }: IDeleteCheckoutCustomizationProps): Promise<
    AxiosResponse<IDeleteCheckoutCustomizationResponse>
  > => {
    return dashboardInstance.delete(
      `accounts/${accountId}/checkouts/${checkoutId}/checkout_customizations/${checkoutCustomizationId}`,
      {
        headers: {
          'x-zouti-account': accountId,
        },
      },
    );
  };

  const uploadCheckoutCustomization = async ({
    accountId,
    checkoutId,
    payload,
  }: IUploadCheckoutCustomizationProps): Promise<
    AxiosResponse<IUploadCheckoutCustomizationResponse>
  > => {
    return dashboardInstance.post(
      `accounts/${accountId}/checkouts/${checkoutId}/checkout_customizations/upload`,
      payload,
      {
        headers: {
          'x-zouti-account': accountId,
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  };

  const deleteUploadCheckoutCustomization = async ({
    accountId,
    checkoutId,
    path,
  }: IDeleteUploadCheckoutCustomizationProps): Promise<
    AxiosResponse<IDeleteUploadCheckoutCustomizationResponse>
  > => {
    return dashboardInstance.delete(
      `accounts/${accountId}/checkouts/${checkoutId}/checkout_customizations/upload?key=${path}`,
      {
        headers: {
          'x-zouti-account': accountId,
        },
      },
    );
  };

  return {
    listCheckoutCustomization,
    createCheckoutCustomization,
    updateCheckoutCustomization,
    uploadCheckoutCustomization,
    deleteUploadCheckoutCustomization,
    deleteCheckoutCustomization,
    activateCheckoutCustomization,
  };
};
