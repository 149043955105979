import React from 'react';
import * as DropDownMenu from '@radix-ui/react-dropdown-menu';

import * as S from './styles';

const Arrow: React.FC<DropDownMenu.DropdownMenuArrowProps & React.RefAttributes<SVGSVGElement>> = ({
  children,
  ...rest
}) => {
  return <S.Wrapper {...rest}>{children}</S.Wrapper>;
};

export default Arrow;
