import React from 'react';

import { UserOnboardingProvider } from '@store/context/onboarding/user/UserOnboardingContext';

import Wrapper from '@components/pages/Onboarding/User/Wrapper';

const User: React.FC = () => {
  return (
    <UserOnboardingProvider>
      <Wrapper />
    </UserOnboardingProvider>
  );
};

export default User;
