import React from 'react';

import { UpdateOrderBumpProvider } from '@store/common/orderBump/UpdateOrderBumpContext';

import Content from '@components/pages/Dashboard/OrderBump/EditOrderBump';

const EditOrderBump: React.FC = () => {
  return (
    <UpdateOrderBumpProvider>
      <Content />
    </UpdateOrderBumpProvider>
  );
};

export default EditOrderBump;
