import styled from 'styled-components/macro';

import TableBase from '@components/common/utils/Table';
import CheckboxBase from '@components/common/Inputs/Checkbox';

export const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  gap: 24px;
`;

export const Checkbox = styled(CheckboxBase)``;

export const Table = styled(TableBase)`
  width: 100%;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
`;

export const TableHeader = styled(TableBase.Header)`
  background-color: ${({ theme }) => theme.colors.neutral.gray_darker};
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
`;
