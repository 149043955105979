import React from 'react';
import { PlusCircle } from 'phosphor-react';
import { useTheme } from 'styled-components/macro';
import { useNavigate, useParams } from 'react-router-dom';

import { EButtonVariant } from '@domain/enums/components/common/EButton';

import PageHeader from '@components/common/utils/PageHeader';

import * as S from './styles';

const Header: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { accountId, checkoutId } = useParams();

  const onNewOrderBumpClick = React.useCallback(() => {
    navigate(`/${accountId}/${checkoutId}/dashboard/marketing/order-bumps/new`);
  }, [navigate, accountId, checkoutId]);

  return (
    <S.Wrapper>
      <PageHeader
        title="Gerenciar Order Bumps"
        description="Crie e gerencie suas ofertas de order bump"
      />

      <S.NewShippingButton variant={EButtonVariant.SECONDARY} onClick={onNewOrderBumpClick}>
        <PlusCircle size={20} weight="bold" color={theme.colors.neutral.darker} /> Novo order bump
      </S.NewShippingButton>
    </S.Wrapper>
  );
};

export default Header;
