import React from 'react';
import { useTheme } from 'styled-components';
import { User, SignOut } from 'phosphor-react';

import ZoutiLogo from '@assets/images/common/logo.svg';

import { EBusinessModel } from '@domain/enums/common/onboarding/EUser';

import { useConfig } from '@store/context/common/ConfigContext';
import { useUserCheckout } from '@store/context/common/UserCheckout';
import { useAlerts } from '@store/context/common/AlertsContext';

import * as S from './styles';

const NavBar: React.FC = () => {
  const theme = useTheme();
  const { handleLogout } = useConfig();
  const { currentUserCheckout } = useUserCheckout();
  const { alertsWrapperHeight } = useAlerts();

  const getAccountType = React.useCallback(() => {
    if (currentUserCheckout?.business_type === EBusinessModel.DROPSHIPPING_GLOBAL) {
      return (
        <S.Chip
          backgroundColor={theme.colors.primary.lemon_30}
          textColor={theme.colors.primary.dark_green_80}
        >
          Dropshipping Global
        </S.Chip>
      );
    }

    if (currentUserCheckout?.business_type === EBusinessModel.DROPSHIPPING_INTERNATIONAL) {
      return (
        <S.Chip
          backgroundColor={theme.colors.primary.lemon_30}
          textColor={theme.colors.primary.dark_green_80}
        >
          Dropshipping Internacional
        </S.Chip>
      );
    }

    if (currentUserCheckout?.business_type === EBusinessModel.DROPSHIPPING_NATIONAL) {
      return (
        <S.Chip
          backgroundColor={theme.colors.primary.lemon_30}
          textColor={theme.colors.primary.dark_green_80}
        >
          Dropshipping Nacional
        </S.Chip>
      );
    }

    if (currentUserCheckout?.business_type === EBusinessModel.ECOMMERCE) {
      return (
        <S.Chip
          backgroundColor={theme.colors.primary.lemon_30}
          textColor={theme.colors.primary.dark_green_80}
        >
          E-commerce
        </S.Chip>
      );
    }

    if (currentUserCheckout?.business_type === EBusinessModel.INFOPRODUCT) {
      return (
        <S.Chip
          backgroundColor={theme.colors.primary.lemon_30}
          textColor={theme.colors.primary.dark_green_80}
        >
          Infoproduto
        </S.Chip>
      );
    }

    return <></>;
  }, [currentUserCheckout, theme]);

  return (
    <S.Wrapper heightOffset={alertsWrapperHeight}>
      <S.LogoAndChipWrapper>
        <S.LogoIcon src={ZoutiLogo} alt="Zouti logo image" />
        {getAccountType()}
      </S.LogoAndChipWrapper>

      <S.Content>
        {/* <S.IconWrapper>
          <Question size={16} weight="bold" color={theme.colors.neutral.darkest} />
        </S.IconWrapper>

        <S.IconWrapper>
          <Bell size={16} weight="bold" color={theme.colors.neutral.darkest} />
        </S.IconWrapper> */}

        <S.Dropdown>
          <S.Dropdown.Trigger>
            <S.IconWrapper>
              <User size={16} weight="bold" color={theme.colors.neutral.darkest} />
            </S.IconWrapper>
          </S.Dropdown.Trigger>

          <S.DropdownContent>
            <S.ActionButton onClick={handleLogout}>
              <SignOut size={16} />
              Sair
            </S.ActionButton>
          </S.DropdownContent>
        </S.Dropdown>
      </S.Content>
    </S.Wrapper>
  );
};

export default NavBar;
