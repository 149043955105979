import React from 'react';

import { ESearchInputSize } from '@domain/enums/components/common/ESearchInput';
import { IProduct } from '@domain/interfaces/common/product/IProducts';

// import { PRODUCT_FILTER_OPTIONS } from '@constants/common/Filter/productsFilter';

// import FilterSideModal from '@components/common/utils/FilterButton/SideModal';
import PageHeader from '@components/common/utils/PageHeader';

import { useListProducts } from '@store/common/product/ListProductsContext';
import { useDebounce } from '@hooks/common/useDebounce';
import * as S from './styles';

interface IHeaderProps {
  products: IProduct[];
}

const Header: React.FC<IHeaderProps> = ({ products }) => {
  const { searchName, handleSearchName } = useListProducts();

  const [searchValue, setSearchValue] = React.useState<string>(searchName);

  // const [, setIsFilterSideModalOpen] = React.useState<boolean>(false);

  const handleSearchInputChange = React.useCallback(event => {
    setSearchValue(event.target.value);
  }, []);

  const clearInput = React.useCallback(() => {
    handleSearchName('');
  }, [handleSearchName]);

  // const handleFilterSideModalOpen = React.useCallback(() => {
  //   setIsFilterSideModalOpen(currentState => !currentState);
  // }, []);

  // const applyFilter = React.useCallback(() => {
  //   console.log('apply filter');
  // }, []);

  const debouncedValue = useDebounce(searchValue, 700);

  React.useEffect(() => {
    handleSearchName(debouncedValue);
  }, [debouncedValue, handleSearchName]);

  const results = products.length;

  return (
    <S.Wrapper>
      <PageHeader title="Produtos" quantityText={`${results} resultados`} />

      <S.InputAndFilterWrapper>
        <S.SearchInput
          value={searchValue}
          size={ESearchInputSize.MEDIUM}
          placeholder="Digite o nome do produto"
          onChange={handleSearchInputChange}
          onClear={clearInput}
        />

        {/* <S.FilterButton onClick={handleFilterSideModalOpen} /> */}
      </S.InputAndFilterWrapper>

      {/* <FilterSideModal
        isOpen={isFilterSideModalOpen}
        toggle={handleFilterSideModalOpen}
        filterData={PRODUCT_FILTER_OPTIONS}
        onCancel={handleFilterSideModalOpen}
        onSuccess={applyFilter}
      /> */}
    </S.Wrapper>
  );
};

export default Header;
