import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Plus, Minus } from 'phosphor-react';
import { useTheme } from 'styled-components/macro';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { EButtonVariant } from '@domain/enums/components/common/EButton';
import { ICreateShippingData } from '@domain/interfaces/hooks/services/shipping/IShippingService';

import { useNewShipping } from '@store/common/shipping/NewShippingContext';
import { useUserAccount } from '@store/context/common/UserAccount';

import { currencyFormatter, currencyToNumber } from '@helpers/utils/common/number';
import { getCurrencySymbol } from '@utils/common/formatCurrency';

import { createShippingSchemaValidation } from '@helpers/validators/pages/dashboard/shipping/shippingSchema';
import Text from '@components/common/DataDisplay/Text';

import * as S from './styles';

interface IFormParams {
  is_free: boolean;
  amount: number;
  delivery_time_in_days: number;
  show_delivery_time: boolean;
  name: string;
}

const Body: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { accountId, checkoutId } = useParams();
  const { currentUserAccount } = useUserAccount();
  const inputRef = React.useRef<HTMLInputElement>(null);
  const {
    isCreatingShipping,
    period,
    isFreeShipping,
    increasePeriod,
    decreasePeriod,
    onChangePeriod,
    setIsFreeShipping,
    createNewShipping,
  } = useNewShipping();

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<IFormParams>({
    resolver: yupResolver(createShippingSchemaValidation),
    defaultValues: {
      delivery_time_in_days: 1,
      show_delivery_time: false,
      is_free: false,
      amount: 0,
    },
  });

  const nameRegister = register('name');
  const amountRegister = register('amount');

  const onAmountChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    event.target.value = currencyFormatter(event.target.value);
  };

  const onSubmit = async (formData: FieldValues): Promise<void> => {
    const data: ICreateShippingData = {
      is_free: isFreeShipping,
      amount: isFreeShipping ? 0 : Math.round(currencyToNumber(formData.amount) * 100),
      delivery_time_in_days: period,
      show_delivery_time: formData.show_delivery_time,
      name: formData.name,
    };

    await createNewShipping(data);
  };

  const onCancelButtonClick = (): void => {
    navigate(`/${accountId}/${checkoutId}/dashboard/checkout/shipping`);
  };

  React.useEffect(() => {
    if (!inputRef.current) return;

    inputRef.current.value = String(period);
  }, [inputRef, period]);

  const showShippingAmountInput = !isFreeShipping;

  const onChangeIsFreeShipping = React.useCallback(
    event => {
      const value = event.target.checked;
      setIsFreeShipping(value);
      setValue('is_free', value);
    },
    [setIsFreeShipping, setValue],
  );

  const currency = getCurrencySymbol(currentUserAccount?.language, currentUserAccount?.currency);

  return (
    <S.Form onSubmit={handleSubmit(onSubmit)}>
      <S.Wrapper>
        <S.InputGroup>
          <S.Label>Nome</S.Label>
          <S.Input {...nameRegister} type="text" />
          {errors?.name && <Text isFeedbackError>{errors?.name?.message}</Text>}
        </S.InputGroup>

        <S.InputGroup>
          <S.ValueInputWrapper>
            <S.Label>Preço</S.Label>
            <S.SwitchAndText>
              <S.Switch
                id="free-shipping"
                name="free-shipping"
                onChange={onChangeIsFreeShipping}
                checked={isFreeShipping}
              />
              <S.Text>Frete Grátis</S.Text>
            </S.SwitchAndText>

            {showShippingAmountInput && (
              <S.ValueWrapper>
                <S.CurrencyLabel>{currency}</S.CurrencyLabel>
                <S.Input {...amountRegister} type="text" onChange={onAmountChange} />
              </S.ValueWrapper>
            )}
            {!isFreeShipping && errors?.amount && (
              <Text isFeedbackError>{errors?.amount?.message}</Text>
            )}
          </S.ValueInputWrapper>
        </S.InputGroup>

        <S.InputGroup>
          <S.Label>Previsão de entrega (dias)</S.Label>

          <S.PeriodWrapper>
            <S.PeriodButton type="button" onClick={decreasePeriod}>
              <Minus size={12} weight="bold" color={theme.colors.neutral.darkest} />
            </S.PeriodButton>
            <S.Input ref={inputRef} type="text" onChange={onChangePeriod} defaultValue={period} />
            <S.PeriodButton type="button" onClick={increasePeriod}>
              <Plus size={12} weight="bold" color={theme.colors.neutral.darkest} />
            </S.PeriodButton>
          </S.PeriodWrapper>
        </S.InputGroup>
        <S.CheckboxGroup>
          <Controller
            control={control}
            name="show_delivery_time"
            render={({ field }) => (
              <S.Checkbox
                id="show_delivery_time"
                value={field?.value?.toString()}
                onChange={() => field?.onChange(!field.value)}
              />
            )}
          />
          <S.CheckboxLabel htmlFor="show_delivery_time">
            Exibir previsão de entrega no checkout
          </S.CheckboxLabel>
        </S.CheckboxGroup>
      </S.Wrapper>

      <S.ButtonsWrapper>
        <S.CancelButton
          type="button"
          variant={EButtonVariant.SECONDARY}
          disabled={isCreatingShipping}
          onClick={onCancelButtonClick}
        >
          Cancelar
        </S.CancelButton>
        <S.SubmitButton type="submit" isLoading={isCreatingShipping}>
          Criar frete
        </S.SubmitButton>
      </S.ButtonsWrapper>
    </S.Form>
  );
};

export default Body;
