import styled from 'styled-components/macro';

import TextBase from '@components/common/DataDisplay/Text';
import SwitchBase from '@components/common/Inputs/Switch';

export const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.neutral.white};
  padding: 24px;
  margin-bottom: 8px;
  border-radius: 4px;
`;

export const CustomerName = styled(TextBase)`
  font-size: 1.4rem;
  margin-left: 24px;
  color: ${({ theme }) => theme.colors.neutral.black};
`;

export const Switch = styled(SwitchBase)``;

export const WrapperIcon = styled.div`
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary.lemon_20};
    border-radius: 4px;
    > svg {
      filter: invert(53%) sepia(86%) saturate(564%) hue-rotate(28deg) brightness(96%) contrast(83%);
    }
  }

  > svg {
    width: 18px;
    height: 18px;
    margin: 4px;
  }
`;

export const ButtonActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 16px;
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
`;
