import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components/macro';
import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Minus, Plus, Trash, UploadSimple } from 'phosphor-react';
import { useDropzone } from 'react-dropzone';

import { EButtonVariant } from '@domain/enums/components/common/EButton';
import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/common/EHeading';
import { IUpdateCheckoutCustomizationData } from '@domain/interfaces/hooks/services/checkoutCustomization/ICheckoutCustomizationService';

import InputColorCheckout from '@components/common/Inputs/InputColorCheckout';
import { useEditCheckout } from '@store/common/checkoutCustomization/EditCheckoutCustomizationContext';
import headerCheckoutCustomizationSchema from '@helpers/validators/pages/dashboard/checkoutCustomizations/headerCheckoutCustomizationSchema';

import * as S from './styles';

const Header: React.FC = () => {
  const navigate = useNavigate();
  const { accountId, checkoutId } = useParams();
  const {
    updateCurrentCheckout,
    decreaseTime,
    increaseTime,
    logoImage,
    deleteUploadCheckout,
    fileSizeKB,
    checkoutCustomization,
    setCheckoutCustomization,
    isUpdateCheckout,
    handleImageChange,
    responseUploadLogo,
    selectedThemeCheckout,
  } = useEditCheckout();
  const {
    id,
    header_customization,
    boleto_discount_type,
    credit_card_discount_type,
    pix_discount_type,
    name,
    allow_credit_card,
    allow_pix,
    allow_boleto,
    credit_card_discount_amount,
    pix_discount_amount,
    boleto_discount_amount,
    show_coupon_input,
    enable_itens_quantity_change,
    footer_customization,
    appearance_customization,
    advanced_customization,
    show_time_shipping_estimate,
  } = checkoutCustomization;

  const theme = useTheme();

  const [isActive, setIsActive] = React.useState<boolean>(header_customization?.show_timer);

  const onSwitchChange = React.useCallback(() => {
    setIsActive(state => !state);
  }, []);

  const { register, handleSubmit, setValue, reset, watch } = useForm({
    resolver: yupResolver(headerCheckoutCustomizationSchema),
  });
  const onSubmit = async (formData: FieldValues): Promise<void> => {
    const data: IUpdateCheckoutCustomizationData = {
      name,
      allow_credit_card,
      allow_pix,
      allow_boleto,
      boleto_discount_type,
      credit_card_discount_type,
      pix_discount_type,
      credit_card_discount_amount,
      pix_discount_amount,
      boleto_discount_amount,
      show_coupon_input,
      enable_itens_quantity_change,
      show_time_shipping_estimate,
      header_customization: {
        background_hex_color: formData.background_hex_color,
        name_hex_color: formData.name_hex_color,
        secure_hex_color: formData.secure_hex_color,
        timer_background_hex_color: formData.timer_background_hex_color,
        timer_text_hex_color: formData.timer_text_hex_color,
        timer_text: formData.timer_text,
        show_timer: formData.show_timer,
        timer_unit_of_time: formData.timer_unit_of_time,
        timer_amount: header_customization?.timer_amount,
        logo_path: responseUploadLogo.path,
        logo_url: responseUploadLogo.url,
        id,
      },
      footer_customization: { ...footer_customization },
      appearance_customization: { ...appearance_customization },
      advanced_customization: { ...advanced_customization },
      checkout_theme: { theme: selectedThemeCheckout?.theme },
    };

    await updateCurrentCheckout({ checkoutCustomizationId: id, payload: data });
  };

  React.useEffect(() => {
    return () => {
      const fieldValues = watch();
      setCheckoutCustomization(prevData => {
        return {
          ...prevData,
          header_customization: {
            background_hex_color: fieldValues.background_hex_color,
            name_hex_color: fieldValues.name_hex_color,
            secure_hex_color: fieldValues.secure_hex_color,
            timer_background_hex_color: fieldValues.timer_background_hex_color,
            timer_text_hex_color: fieldValues.timer_text_hex_color,
            timer_text: fieldValues.timer_text,
            show_timer: fieldValues.show_timer,
            timer_unit_of_time: fieldValues.timer_unit_of_time,
            timer_amount: prevData?.header_customization?.timer_amount,
            logo_path: responseUploadLogo.path,
            logo_url: responseUploadLogo.url,
            id,
          },
        };
      });
    };
  }, [setCheckoutCustomization, watch, responseUploadLogo, id]);

  register('background_hex_color', { value: header_customization?.background_hex_color });
  register('name_hex_color', { value: header_customization?.name_hex_color });
  register('secure_hex_color', { value: header_customization?.secure_hex_color });
  register('show_timer', { value: header_customization?.show_timer });
  register('timer_amount', { value: header_customization?.timer_amount });
  register('timer_background_hex_color', {
    value: header_customization?.timer_background_hex_color,
  });
  register('timer_text_hex_color', {
    value: header_customization?.timer_text_hex_color,
  });
  register('timer_text', { value: header_customization?.timer_text });

  const onCancelButtonClick = (): void => {
    reset();
    navigate(`/${accountId}/${checkoutId}/dashboard/checkout/customizations`);
  };

  const onDrop = React.useCallback(
    (accFiles: File[]) => {
      const mappedAcc = accFiles.map(file => ({
        file,
        size: file.size,
        name: file.name,
        errors: [],
        preview: URL.createObjectURL(file),
      }));
      handleImageChange({ mappedAcc, type: 'logo' });
    },
    [handleImageChange],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    maxSize: 104857600,
  });

  const onDeleteLogo = (path: string): void => {
    deleteUploadCheckout(path, 'logo');
  };

  return (
    <S.Form onSubmit={handleSubmit(onSubmit)}>
      <S.Wrapper>
        <S.ColorSection>
          <S.Title size={EHeadingSize.H4} fontWeight={EHeadingWeight.BOLD}>
            Cores
          </S.Title>
          <S.InputColorWrapper>
            <InputColorCheckout
              onChange={event => {
                setValue('background_hex_color', event.target.value);
              }}
              value={header_customization?.background_hex_color}
              placeholder="Digite o código da cor"
            >
              {/* <S.Tooltip url="https://images.freeimages.com/images/small-previews/aed/three-bees-on-sunflower-1337029.jpg"> */}
              Cor de fundo do cabeçalho
              {/* </S.Tooltip> */}
            </InputColorCheckout>
            <InputColorCheckout
              onChange={event => {
                setValue('name_hex_color', event.target.value);
              }}
              value={header_customization?.name_hex_color}
              placeholder="Digite o código da cor"
            >
              {/* <S.Tooltip url="https://images.freeimages.com/images/small-previews/aed/three-bees-on-sunflower-1337029.jpg"> */}
              Cor do nome da loja
              {/* </S.Tooltip> */}
            </InputColorCheckout>
            <InputColorCheckout
              onChange={event => {
                setValue('secure_hex_color', event.target.value);
              }}
              value={header_customization?.secure_hex_color}
              placeholder="Digite o código da cor"
            >
              {/* <S.Tooltip url="https://images.freeimages.com/images/small-previews/aed/three-bees-on-sunflower-1337029.jpg"> */}
              Cor do ambiente seguro
              {/* </S.Tooltip> */}
            </InputColorCheckout>
          </S.InputColorWrapper>
        </S.ColorSection>
        <S.Divider />

        <S.LogoSection>
          <S.Title size={EHeadingSize.H4} fontWeight={EHeadingWeight.BOLD}>
            Logo
          </S.Title>
          {logoImage?.length === 0 ? (
            <>
              <S.ContainerDropzone {...getRootProps()}>
                <input {...getInputProps()} />
                <UploadSimple weight="bold" size={13} />
                <S.TextDropzone>Arraste e solte seu arquivo aqui</S.TextDropzone>
                <S.AcceptFormat>JPEG ou PNG</S.AcceptFormat>
              </S.ContainerDropzone>
              <S.TextInfoAndTooltipWrapper>
                <S.TextInfo>Resolução recomendada: 300x90px.</S.TextInfo>
                {/* <S.Tooltip
                  iconColor={theme.colors.neutral.dark}
                  iconWeight="regular"
                  url="https://images.freeimages.com/images/small-previews/aed/three-bees-on-sunflower-1337029.jpg"
                /> */}
              </S.TextInfoAndTooltipWrapper>
            </>
          ) : (
            logoImage?.map((item, index) => (
              <>
                <S.PreviewDropzone key={index.toFixed()}>
                  <S.Image
                    src={item?.preview || header_customization?.logo_url}
                    alt={`Preview ${item?.file?.name || 'logo'}`}
                  />
                </S.PreviewDropzone>
                <S.PreviewCardInfo key={index.toFixed()}>
                  <S.FileName>{item?.file?.name || 'logo'}</S.FileName>
                  <S.FileSize>{fileSizeKB}KB de 2MB</S.FileSize>
                  <Trash
                    onClick={() => onDeleteLogo(header_customization?.logo_path || '')}
                    size={28}
                  />
                </S.PreviewCardInfo>
              </>
            ))
          )}
        </S.LogoSection>
        <S.Divider />
        <S.StopwatchSection>
          <S.Title size={EHeadingSize.H4} fontWeight={EHeadingWeight.BOLD}>
            Cronômetro
          </S.Title>
          <S.SwitchWrapper>
            <S.Switch
              id="stopwatch"
              {...register('show_timer', { value: isActive })}
              checked={isActive}
              onChange={event => {
                onSwitchChange();
                setValue('show_timer', event.target.checked);
              }}
            />
            <S.SwitchLabel>Ativar cronômetro</S.SwitchLabel>
          </S.SwitchWrapper>
          {isActive && (
            <S.TimeWrapper>
              <S.InputGroup>
                <S.TimerLabel>
                  Tempo do cronômetro <S.TimeTextSpan>(minutos)</S.TimeTextSpan>
                </S.TimerLabel>
                <S.TimeWrapperButtons>
                  <S.TimeButton type="button" onClick={decreaseTime}>
                    <Minus size={12} weight="bold" color={theme.colors.neutral.darkest} />
                  </S.TimeButton>
                  <S.Input
                    onChange={event => {
                      setValue('timer_amount', event.target.value);
                    }}
                    type="text"
                    value={header_customization?.timer_amount}
                  />
                  <S.TimeButton type="button" onClick={increaseTime}>
                    <Plus size={12} weight="bold" color={theme.colors.neutral.darkest} />
                  </S.TimeButton>
                </S.TimeWrapperButtons>
              </S.InputGroup>
              <S.ConfigInputColorWrapper>
                <InputColorCheckout
                  onChange={event => {
                    setValue('timer_background_hex_color', event.target.value);
                  }}
                  value={header_customization?.timer_background_hex_color}
                  placeholder="Digite o código da cor"
                >
                  {/* <S.Tooltip url="https://images.freeimages.com/images/small-previews/aed/three-bees-on-sunflower-1337029.jpg"> */}
                  Cor de fundo do cabeçalho
                  {/* </S.Tooltip> */}
                </InputColorCheckout>
                <InputColorCheckout
                  onChange={event => {
                    setValue('timer_text_hex_color', event.target.value);
                  }}
                  value={header_customization?.timer_text_hex_color}
                  placeholder="Digite o código da cor"
                >
                  {/* <S.Tooltip url="https://images.freeimages.com/images/small-previews/aed/three-bees-on-sunflower-1337029.jpg"> */}
                  Cor do nome da loja
                  {/* </S.Tooltip> */}
                </InputColorCheckout>
              </S.ConfigInputColorWrapper>
              <S.TextArea
                onChange={event => {
                  setValue('timer_text', event.target.value);
                }}
                defaultValue={header_customization?.timer_text}
                label="Texto do cronômetro"
                placeholder="Digite um texto para o cronômetro"
              />
            </S.TimeWrapper>
          )}
        </S.StopwatchSection>
      </S.Wrapper>
      <S.ButtonsWrapper>
        <S.CancelButton
          type="button"
          onClick={onCancelButtonClick}
          variant={EButtonVariant.SECONDARY}
        >
          Cancelar
        </S.CancelButton>
        <S.SubmitButton isLoading={isUpdateCheckout} type="submit">
          Salvar
        </S.SubmitButton>
      </S.ButtonsWrapper>
    </S.Form>
  );
};

export default Header;
