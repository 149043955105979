import React from 'react';
import { Analytics, AnalyticsBrowser } from '@segment/analytics-next';

import { IAnalyticsContext } from '@domain/interfaces/store/context/IAnalyticsContext';

import { useConfig } from './ConfigContext';

const AnalyticsContext = React.createContext<IAnalyticsContext | null>(null);

const WRITE_KEY = 'vHpjMGLQqOYA9Mb2Ukg392BgYrEQVguS';

export const AnalyticsProvider: React.FC = ({ children }) => {
  const { user } = useConfig();

  const [segmentAnalytics, setSegmentAnalytics] = React.useState<Analytics | undefined>(undefined);

  const analytics = React.useMemo(
    () => ({
      identify(identifier: string, traits: Record<string, any>, config?: Record<string, any>) {
        if (segmentAnalytics) {
          segmentAnalytics.identify(identifier, traits, config);
        }
      },
      track(event: string, data?: Record<string, any>, config?: Record<string, any>) {
        if (segmentAnalytics) {
          segmentAnalytics.track(event, { email: user?.email, ...data }, config);
        }
      },
    }),
    [segmentAnalytics, user],
  );

  const loadAnalytics = React.useCallback(async () => {
    const [response] = await AnalyticsBrowser.load({ writeKey: WRITE_KEY }, {});

    setSegmentAnalytics(response);
  }, []);

  React.useEffect(() => {
    loadAnalytics();
  }, [loadAnalytics]);

  return <AnalyticsContext.Provider value={{ analytics }}>{children}</AnalyticsContext.Provider>;
};

export const useAnalytics = (): IAnalyticsContext => {
  const context = React.useContext(AnalyticsContext);

  if (!context) {
    throw new Error('useAnalytics must be used within provider');
  }

  return context;
};
