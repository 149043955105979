import React from 'react';

import { RecoveryPasswordProvider } from '@store/context/public/RecoveryPassword/RecoveryPasswordContext';

import RecoveryPasswordWrapper from '@components/pages/Public/RecoveryPassword/Wrapper';

const RecoveryPassword: React.FC = () => {
  return (
    <RecoveryPasswordProvider>
      <RecoveryPasswordWrapper />
    </RecoveryPasswordProvider>
  );
};

export default RecoveryPassword;
