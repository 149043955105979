import React from 'react';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/common/EHeading';

import { useGetOrder } from '@store/common/order/GetOrder';

import { formatCurrency } from '@utils/common/formatCurrency';
import { findTransactionError } from '@utils/common/transactionError';

import * as S from './styles';

const TransactionDetail: React.FC = () => {
  const { order } = useGetOrder();

  const declinedReason = order?.payment.decline_reason || '';

  const totalAmount = order?.order.amount_total ? order?.order.amount_total / 100 : 0;
  const totalAmountInBrl = order?.order.amount_in_brl ? order?.order.amount_in_brl / 100 : 0;
  const conversionRate = order?.order.metadata?.currency_rate || 1;

  const totalAmountFormatted = formatCurrency(totalAmount, order?.language, order?.order.currency);
  const totalAmountInBrlFormatted = formatCurrency(totalAmountInBrl, order?.language, 'BRL');
  const conversionRateFormatted = formatCurrency(1, order?.language, order?.order.currency);
  const conversionRateInBrlFormatted = formatCurrency(1 / conversionRate, order?.language, 'BRL');

  return (
    <S.Wrapper>
      <S.Title size={EHeadingSize.H5} fontWeight={EHeadingWeight.BOLD}>
        Detalhes da transação
      </S.Title>

      <S.Divider />

      <S.WrapperInfos>
        <S.ValueAndCurrencyWrapper>
          <S.LabelAndValue>
            <S.Label>Valor</S.Label>
            <S.Value>
              <S.GlobalCurrency>{totalAmountFormatted}</S.GlobalCurrency> <S.ArrowRightIcon />
              <S.BRLCurrency>{totalAmountInBrlFormatted}</S.BRLCurrency>
            </S.Value>
          </S.LabelAndValue>

          <S.LabelAndValue>
            <S.Label>Taxa de câmbio</S.Label>
            <S.Value>
              <S.UnityGlobalCurrency>{conversionRateFormatted}</S.UnityGlobalCurrency>{' '}
              <S.ArrowRightIcon />
              <S.BRLCurrency>{conversionRateInBrlFormatted}</S.BRLCurrency>
            </S.Value>
          </S.LabelAndValue>
        </S.ValueAndCurrencyWrapper>

        {Boolean(declinedReason) && (
          <>
            <S.RefusedDivider />
            <S.RefusedInfoCard>
              <S.RefusedInfoText>
                {`${declinedReason}: ${findTransactionError(declinedReason)}`}
              </S.RefusedInfoText>
            </S.RefusedInfoCard>
          </>
        )}
      </S.WrapperInfos>
    </S.Wrapper>
  );
};

export default TransactionDetail;
