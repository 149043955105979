import styled from 'styled-components/macro';

import TableBase from '@components/common/utils/Table';
import Text from '@components/common/DataDisplay/Text';

export const Wrapper = styled.div`
  height: 100%;
`;

export const EmptyContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -35%);
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  align-items: center;
`;

export const EmptyImage = styled.img.attrs({
  alt: 'Empty state',
})``;

export const EmptyText = styled(Text)`
  font-size: 1.8rem;
  color: ${({ theme }) => theme.colors.neutral.darkest};
`;

export const Table = styled(TableBase)`
  position: relative;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  th {
    padding: 16px 24px !important;
    background-color: ${({ theme }) => theme.colors.neutral.lightest};
  }
  tr {
    border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  }
`;

export const TableBody = styled(TableBase.Body)`
  height: 285px;
`;
