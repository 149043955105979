interface IStepValues {
  id: number;
  key: string;
  title: string;
  description: string;
  path?: string;
}

export const firstStepValues = (accountId?: string, checkoutId?: string): IStepValues[] => [
  {
    id: 1,
    key: 'STORE_CONNECTED_AT',
    title: 'Conecte com sua loja',
    description: 'Conectar a loja aos sistemas necessários.',
  },
  // {
  //   id: 2,
  //   key: 'PAYMENT_INTEGRATED_AT',
  //   title: 'Pagamento',
  //   description: 'Integrar a loja a um provedor de pagamentos.',
  //   path: `/${accountId}/${checkoutId}/dashboard/payment-credential`,
  // },
  {
    id: 3,
    key: 'FREIGHTS_CONFIGURED_AT',
    title: 'Frete',
    description: 'Configurar as regras de frete.',
    path: `/${accountId}/${checkoutId}/dashboard/checkout/shipping`,
  },
  {
    id: 4,
    key: 'CHECKOUT_ENABLED_AT',
    title: 'Iniciar',
    description: 'Habilitar o checkout para que os clientes possam finalizar as compras.',
  },
  // {
  //   id: 5,
  //   key: 'FIRST_SALE_AT',
  //   title: 'Primeira transação',
  //   description: 'Realize a sua primeira transação na loja',
  // },
];

export const secondStepValues = (accountId?: string, checkoutId?: string): IStepValues[] => [
  {
    id: 1,
    key: 'PIXEL_CONFIGURED_AT',
    title: 'Pixel',
    description: 'Configurar eventos de rastreamento relevantes.',
    path: `/${accountId}/${checkoutId}/dashboard/marketing/pixels`,
  },
  {
    id: 2,
    key: 'EMAIL_CONFIGURED_AT',
    title: 'Confirmação de e-mail',
    description:
      'Configurar o e-mail da loja e enviar uma confirmação de cadastro para os clientes.',
    path: `/${accountId}/${checkoutId}/dashboard/settings/transactional-email`,
  },
  {
    id: 3,
    key: 'COMPANY_DATA_ADDED_AT',
    title: 'Empresa',
    description: 'Insira os dados de sua empresa para passar mais confiança aos seus clientes',
    path: `/${accountId}/${checkoutId}/dashboard/settings/account-data`,
  },
  {
    id: 4,
    key: 'DOMAIN_CONNECTED_AT',
    title: 'Domínio',
    description: 'Conectar a loja aos sistemas necessários.',
    path: `/${accountId}/${checkoutId}/dashboard/checkout/domains`,
  },
  {
    id: 5,
    key: 'CHECKOUT_CUSTOMIZED_AT',
    title: 'Customizar Checkout',
    description:
      'Altere a aparência de seu checkout para combinar com sua marca e aumentar ainda mais a confiança',
    path: `/${accountId}/${checkoutId}/dashboard/checkout/customizations`,
  },
  {
    id: 6,
    key: 'ORDER_BUMP_CREATED_AT',
    title: 'Criar o primeiro order bump',
    description: 'Vendas outros produtos no checkout aumentando ainda mais seu ticket médio',
    path: `/${accountId}/${checkoutId}/dashboard/marketing/order-bumps`,
  },
  {
    id: 7,
    key: 'UPSELL_CREATED_AT',
    title: 'Criar o primeiro upsell',
    description: 'Definir quando e como exibir upsells.',
    path: `/${accountId}/${checkoutId}/dashboard/marketing/upsells`,
  },
  {
    id: 8,
    key: 'DISCOUNT_CODES_CREATED_AT',
    title: 'Cupom de desconto',
    description: 'Criar códigos de cupom para promoções.',
    path: `/${accountId}/${checkoutId}/dashboard/marketing/coupon`,
  },
];
