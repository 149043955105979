import React from 'react';
import Tab from '@components/common/utils/Tab';
import Option from '@components/common/utils/Tab/Option';

import { EThemeCheckout } from '@domain/enums/common/checkoutCustomization/EThemeCheckout';

import { useEditCheckout } from '@store/common/checkoutCustomization/EditCheckoutCustomizationContext';
import AppearanceOttawa from '../../UpdateCheckoutCustomizationTabContent/AppearanceOttawa';
import ConfigurationOttawa from '../../UpdateCheckoutCustomizationTabContent/ConfigurationsOttawa';
import FooterOttawa from '../../UpdateCheckoutCustomizationTabContent/FooterOttawa';
import HeaderOttawa from '../../UpdateCheckoutCustomizationTabContent/HeaderOttawa';
import Theme from '../Tabs/Theme';
import Configuration from '../Tabs/Configurations';
import Header from '../Tabs/Header';
import Advanced from '../Tabs/Advanced';
import Footer from '../Tabs/Footer';
import Appearance from '../Tabs/Appearance';

const Body: React.FC = () => {
  const { selectedTab, onChangeTab, selectedThemeCheckout } = useEditCheckout();

  const tabsByTheme = {
    [EThemeCheckout.OTTAWA]: [
      Theme,
      ConfigurationOttawa,
      HeaderOttawa,
      FooterOttawa,
      AppearanceOttawa,
      Advanced,
    ],
    [EThemeCheckout.ZOUTI]: [Theme, Configuration, Header, Footer, Appearance, Advanced],
  };

  const currentTabs =
    tabsByTheme[selectedThemeCheckout?.theme] || tabsByTheme[EThemeCheckout.ZOUTI];

  const TabComponent = currentTabs[selectedTab];

  return (
    <>
      <Tab defaultTab={selectedTab} onChangeTab={onChangeTab}>
        <Option>Tema</Option>
        <Option>Configurações</Option>
        <Option>Cabeçalho</Option>
        <Option>Rodapé</Option>
        <Option>Aparência</Option>
        <Option>Avançado</Option>
      </Tab>
      {TabComponent && <TabComponent />}
    </>
  );
};

export default Body;
