import React from 'react';

import {
  IDatePickerContext,
  IDatePickerContextProps,
} from '@domain/interfaces/store/context/components/IDatePickerContext';

const DatePickerContext = React.createContext<IDatePickerContext | null>(null);

export const DatePickerProvider: React.FC<IDatePickerContextProps> = ({
  startDate,
  endDate,
  onChange,
  children,
}) => {
  const [selectedStartDate, setSelectedStartDate] = React.useState<Date>(startDate);
  const [selectedEndDate, setSelectedEndDate] = React.useState<Date>(endDate);
  const [isCalendarOpen, setIsCalendarOpen] = React.useState<boolean>(false);
  const [customStartDate, setCustomStartDate] = React.useState<Date>(startDate);
  const [customEndDate, setCustomEndDate] = React.useState<Date>(endDate);

  const handleCalendarOpen = React.useCallback(() => setIsCalendarOpen(state => !state), []);

  const handleSelectedStartDate = React.useCallback(date => setSelectedStartDate(date), []);

  const handleSelectedEndDate = React.useCallback(date => setSelectedEndDate(date), []);

  const handleCustomDate = React.useCallback(
    dates => {
      const [start, end] = dates;

      setCustomStartDate(start);
      setCustomEndDate(end);

      if (end) {
        setSelectedStartDate(start);
        setSelectedEndDate(end);
        handleCalendarOpen();
      }
    },
    [handleCalendarOpen],
  );

  React.useEffect(() => {
    if (selectedStartDate && selectedEndDate) {
      onChange([selectedStartDate, selectedEndDate]);
    }
  }, [selectedStartDate, selectedEndDate, onChange]);

  return (
    <DatePickerContext.Provider
      value={{
        selectedEndDate,
        selectedStartDate,
        handleCalendarOpen,
        isCalendarOpen,
        handleSelectedEndDate,
        handleSelectedStartDate,
        handleCustomDate,
        customEndDate,
        customStartDate,
      }}
    >
      {children}
    </DatePickerContext.Provider>
  );
};

export const useDatePicker = (): IDatePickerContext => {
  const context = React.useContext(DatePickerContext);

  if (!context) {
    throw new Error('useDatePicker must be used within provider');
  }

  return context;
};
