import styled from 'styled-components/macro';

import ImageBase from '@components/common/DataDisplay/Image';

export const Line = styled.div`
  width: 100%;
  border-bottom: 1px dashed ${({ theme }) => theme.colors.neutral.lighter};
  position: absolute;
`;

export const StepperWrapper = styled.div`
  max-width: 124px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

export const Logo = styled(ImageBase)`
  width: 102px;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
`;
