import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Plus, Minus, Trash } from 'phosphor-react';
import { useTheme } from 'styled-components/macro';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { EButtonVariant } from '@domain/enums/components/common/EButton';
import { IUpdateShippingData } from '@domain/interfaces/hooks/services/shipping/IShippingService';

import { currencyFormatter, currencyToNumber, numberToString } from '@helpers/utils/common/number';
import { getCurrencySymbol } from '@utils/common/formatCurrency';
import { useUpdateShipping } from '@store/common/shipping/UpdateShippingContext';
import { useUserAccount } from '@store/context/common/UserAccount';

import { updateShippingSchemaValidation } from '@helpers/validators/pages/dashboard/shipping/shippingSchema';

import Text from '@components/common/DataDisplay/Text';
import CustomModal from '../CustomModal';

import * as S from './styles';

interface IFormParams {
  active: boolean;
  is_free: boolean;
  amount: string;
  delivery_time_in_days: number;
  show_delivery_time: boolean;
  name: string;
}

const Body: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { accountId, checkoutId } = useParams();
  const { currentUserAccount } = useUserAccount();
  const inputRef = React.useRef<HTMLInputElement>(null);
  const {
    shipping,
    updateCurrentShipping,
    isLoadingUpdateShipping,
    isFreeShipping,
    period,
    handleIsFreeShipping,
    decreasePeriod,
    increasePeriod,
    onChangePeriod,
    setIsOpenModal,
    setShippingDeletedInfo,
    isInactivatedAt,
    activateCurrentShipping,
    inactivateCurrentShipping,
  } = useUpdateShipping();
  const { name, amount, delivery_time_in_days, id, show_delivery_time } = shipping;
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<IFormParams>({
    resolver: yupResolver(updateShippingSchemaValidation),
    defaultValues: {
      delivery_time_in_days: 1,
      is_free: false,
    },
  });

  const nameRegister = register('name');
  const amountRegister = register('amount');

  const onAmountChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    event.target.value = currencyFormatter(event.target.value);
  };

  const onSwitchChange = React.useCallback(async () => {
    if (isInactivatedAt) {
      await inactivateCurrentShipping(shipping.id);
    } else {
      await activateCurrentShipping(shipping.id);
    }
  }, [isInactivatedAt, activateCurrentShipping, inactivateCurrentShipping, shipping]);

  const onCancelButtonClick = (): void => {
    navigate(`/${accountId}/${checkoutId}/dashboard/checkout/shipping`);
  };

  const onSubmit = async (formData: FieldValues): Promise<void> => {
    const activeValue = isInactivatedAt ? undefined : new Date().toISOString();
    const payload: IUpdateShippingData = {
      inactivated_at: activeValue,
      is_free: isFreeShipping,
      amount: isFreeShipping ? 0 : Math.round(currencyToNumber(formData.amount) * 100),
      delivery_time_in_days: period,
      show_delivery_time: formData.show_delivery_time,
      name: formData.name,
    };
    await updateCurrentShipping({ payload, shippingId: id });
  };

  React.useEffect(() => {
    if (!inputRef.current) return;

    inputRef.current.value = String(period);
  }, [inputRef, period]);

  React.useEffect(() => {
    setValue('name', name);
    setValue('amount', numberToString(amount / 100, 2));
    setValue('delivery_time_in_days', delivery_time_in_days);
    setValue('show_delivery_time', show_delivery_time);
  }, [setValue, name, amount, delivery_time_in_days, show_delivery_time]);

  const showShippingAmountInput = !isFreeShipping;

  const currency = getCurrencySymbol(currentUserAccount?.language, currentUserAccount?.currency);

  return (
    <S.Form onSubmit={handleSubmit(onSubmit)}>
      <CustomModal />
      <S.Wrapper>
        <S.InputGroup>
          <S.Label>Ativar frete</S.Label>
          <S.Switch
            id="inactivated_at"
            checked={isInactivatedAt}
            name={name}
            onChange={onSwitchChange}
          />
        </S.InputGroup>
        <S.InputGroup>
          <S.Label>Nome</S.Label>
          <S.Input {...nameRegister} defaultValue={name} type="text" />
          {errors?.name && <Text isFeedbackError>{errors?.name?.message}</Text>}
        </S.InputGroup>

        <S.InputGroup>
          <S.ValueInputWrapper>
            <S.Label>Preço</S.Label>
            <S.SwitchAndText>
              <S.Switch
                id="free-shipping"
                name="free-shipping"
                onChange={handleIsFreeShipping}
                checked={isFreeShipping}
              />
              <S.Text>Frete Grátis</S.Text>
            </S.SwitchAndText>

            {showShippingAmountInput && (
              <S.ValueWrapper>
                <S.CurrencyLabel>{currency}</S.CurrencyLabel>
                <S.Input {...amountRegister} type="text" onChange={onAmountChange} />
              </S.ValueWrapper>
            )}
          </S.ValueInputWrapper>
          {!isFreeShipping && errors?.amount && (
            <Text isFeedbackError>{errors?.amount?.message}</Text>
          )}
        </S.InputGroup>

        <S.InputGroup>
          <S.Label>Previsão de entrega (dias)</S.Label>

          <S.PeriodWrapper>
            <S.PeriodButton type="button" onClick={decreasePeriod}>
              <Minus size={12} weight="bold" color={theme.colors.neutral.darkest} />
            </S.PeriodButton>
            <S.Input ref={inputRef} type="text" onChange={onChangePeriod} defaultValue={period} />
            <S.PeriodButton type="button" onClick={increasePeriod}>
              <Plus size={12} weight="bold" color={theme.colors.neutral.darkest} />
            </S.PeriodButton>
          </S.PeriodWrapper>
        </S.InputGroup>

        <S.CheckboxGroup>
          <Controller
            control={control}
            name="show_delivery_time"
            render={({ field }) => (
              <S.Checkbox
                id="show_delivery_time"
                checked={field.value}
                value={field?.value?.toString()}
                onChange={() => field?.onChange(!field.value)}
              />
            )}
          />
          <S.CheckboxLabel htmlFor="show_delivery_time">
            Exibir previsão de entrega no checkout
          </S.CheckboxLabel>
        </S.CheckboxGroup>
      </S.Wrapper>

      <S.ButtonsWrapper>
        <S.DeleteShippingButton
          type="button"
          onClick={() => {
            setShippingDeletedInfo({ shippingId: id, name });
            setIsOpenModal(state => !state);
          }}
        >
          <Trash size={16} />
          Excluir frete
        </S.DeleteShippingButton>
        <S.WrapperCancelAndSubmitButton>
          <S.CancelButton
            type="button"
            variant={EButtonVariant.SECONDARY}
            disabled={isLoadingUpdateShipping}
            onClick={onCancelButtonClick}
          >
            Cancelar
          </S.CancelButton>
          <S.SubmitButton type="submit" isLoading={isLoadingUpdateShipping}>
            Salvar
          </S.SubmitButton>
        </S.WrapperCancelAndSubmitButton>
      </S.ButtonsWrapper>
    </S.Form>
  );
};

export default Body;
