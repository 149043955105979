import React from 'react';
import { useLocation } from 'react-router-dom';

import { MENU_OPTIONS } from '@constants/components/sideBar/menuOptions';
import { SUB_MENU_OPTIONS } from '@constants/components/sideBar/subMenuOptions';
import { INFOPRODUCT_SUB_MENU_OPTIONS } from '@constants/components/sideBar/infoProductSubMenuOptions';
import { EBusinessModel } from '@domain/enums/common/onboarding/EUser';

import { useUserSession } from '@store/context/common/UserSession';
import { useUserAccount } from '@store/context/common/UserAccount';
import { useAlerts } from '@store/context/common/AlertsContext';
import { useUserCheckout } from '@store/context/common/UserCheckout';

import { checkUserPermissions } from '@utils/common/checkUserPermissions';

import MenuOption from './MenuOption';
import CurrentStore from './CurrentStore';

import * as S from './styles';

const Sidebar: React.FC = () => {
  const location = useLocation();
  const { currentUserAccount } = useUserAccount();
  const { user } = useUserSession();
  const { alertsWrapperHeight } = useAlerts();
  const { currentUserCheckout } = useUserCheckout();

  const [selectedOption, setSelectedOption] = React.useState<string | undefined>(undefined);
  const [selectedItemOption, setSelectedItemOption] = React.useState<string | undefined>(undefined);
  const [selectedSubItemOption, setSelectedSubItemOption] = React.useState<string | undefined>(
    undefined,
  );

  const handleSelectedOption = React.useCallback(option => setSelectedOption(option), []);

  const handleSelectedItemOption = React.useCallback(option => setSelectedItemOption(option), []);

  const handleSelectedSubItemOption = React.useCallback(
    option => setSelectedSubItemOption(option),
    [],
  );

  const updateSideBarState = React.useCallback(() => {
    const { pathname } = location;

    const isInfoproduct = currentUserCheckout?.business_type === EBusinessModel.INFOPRODUCT;

    const subMenuOptions = isInfoproduct ? INFOPRODUCT_SUB_MENU_OPTIONS : SUB_MENU_OPTIONS;

    const foundSubMenuOption = subMenuOptions.find(subItem => pathname.includes(subItem.path));

    if (foundSubMenuOption) {
      setSelectedSubItemOption(foundSubMenuOption.name);
      setSelectedItemOption(undefined);
      return;
    }

    const foundMenuOption = MENU_OPTIONS.find(item => pathname.includes(item.path));

    if (foundMenuOption) {
      setSelectedItemOption(foundMenuOption.name);
      setSelectedSubItemOption(undefined);
    }
  }, [location, currentUserCheckout]);

  React.useEffect(() => {
    updateSideBarState();
  }, [updateSideBarState, currentUserAccount?.scopes]);

  return (
    <S.Wrapper heightOffset={alertsWrapperHeight}>
      <S.OptionsWrapper>
        {MENU_OPTIONS.filter(option =>
          checkUserPermissions({
            type: user?.user?.type,
            scopes: currentUserAccount?.scopes || [],
            requiredPermissions: option.requiredPermissions,
          }),
        ).map((option: any) => (
          <MenuOption
            option={option}
            key={option.name}
            selectedOption={selectedOption}
            handleSelectedOption={handleSelectedOption}
            handleSelectedSubItemOption={handleSelectedSubItemOption}
            selectedSubItemOption={selectedSubItemOption}
            handleSelectedItemOption={handleSelectedItemOption}
            selectedItemOption={selectedItemOption}
          />
        ))}
      </S.OptionsWrapper>

      <CurrentStore updateSideBarState={updateSideBarState} />
    </S.Wrapper>
  );
};

export default Sidebar;
