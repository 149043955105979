import React from 'react';
import useSWR from 'swr';

import { ISwr } from '@domain/interfaces/common/swr/ISwr';

import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { IGTMProvider } from '@domain/interfaces/store/common/GTM/IGTMProvider';
import { DASHBOARD_API_URL } from '@constants/services/apiUrl';
import { dashboardInstance } from '@services/common/dashboardInstance';

import { gtmSchema } from '@helpers/validators/pages/dashboard/GTM/gtmSchema';

import { useToast } from '@store/context/common/ToastContext';
import { useErrorHandler } from '@store/context/common/ErrorHandlerContext';
import { useAnalytics } from '@store/context/common/AnalyticsContext';
import { useGTMService } from '@hooks/services/GTM/useGTMService';
import { IGTM } from '@domain/interfaces/common/GTM/IGTM';

const GTMContext = React.createContext<IGTMProvider | null>(null);

export const GTMProvider: React.FC = ({ children }) => {
  const { analytics } = useAnalytics();
  const { accountId, checkoutId } = useParams();
  const { toast } = useToast();
  const { handleError } = useErrorHandler();
  const { inactivateGTM, createGTM, activateGTM, updateGTM } = useGTMService();
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    watch,
  } = useForm({ mode: 'onBlur', reValidateMode: 'onChange', resolver: yupResolver(gtmSchema) });

  const [isSubmittingForm, setIsSubmittingForm] = React.useState<boolean>(false);
  const [gtmCredential, setGtmCredential] = React.useState<IGTM | undefined>(undefined);

  const GTM_URL = `${DASHBOARD_API_URL}/accounts/${accountId}/checkouts/${checkoutId}/integrations/gtm`;

  const { data, isLoading, error, mutate } = useSWR<ISwr>(GTM_URL, dashboardInstance);

  const watchTrackingId = watch('tracking_id');
  const trackingIdRegister = register('tracking_id');

  const onCreateGTMCredential = React.useCallback(
    async formData => {
      setIsSubmittingForm(true);

      try {
        await createGTM({ accountId, checkoutId, payload: formData });

        analytics.track('GTM Credential Created');

        toast.success('App instalado com sucesso');

        setIsSubmittingForm(false);

        await mutate();
      } catch (requestError) {
        setIsSubmittingForm(false);
        handleError(requestError);
      }
    },
    [accountId, checkoutId, toast, handleError, mutate, analytics, createGTM],
  );

  const onGTMCredentialUpdate = React.useCallback(
    async formData => {
      setIsSubmittingForm(true);

      try {
        await updateGTM({ accountId, checkoutId, gtmId: gtmCredential?.id, payload: formData });

        analytics.track('GTM Credential Update');

        toast.success('App atualizado com sucesso');

        setIsSubmittingForm(false);

        await mutate();
      } catch (requestError) {
        setIsSubmittingForm(false);
        handleError(requestError);
      }
    },
    [accountId, checkoutId, toast, handleError, mutate, analytics, updateGTM, gtmCredential],
  );

  const onSubmit = React.useCallback(
    async formData => {
      const payload = {
        tracking_id: formData.tracking_id.trim(),
      };

      if (data?.data.object) {
        await onGTMCredentialUpdate(payload);
      } else {
        await onCreateGTMCredential(payload);
      }
    },
    [data, onGTMCredentialUpdate, onCreateGTMCredential],
  );

  const onActivateGTM = React.useCallback(async () => {
    try {
      await activateGTM({ accountId, checkoutId, gtmId: gtmCredential?.id });
    } catch (requestError) {
      handleError(requestError);
    }
  }, [accountId, checkoutId, handleError, activateGTM, gtmCredential]);

  const onInactivateGTM = React.useCallback(async () => {
    try {
      await inactivateGTM({ accountId, checkoutId, gtmId: gtmCredential?.id });
    } catch (requestError) {
      handleError(requestError);
    }
  }, [accountId, checkoutId, handleError, inactivateGTM, gtmCredential]);

  React.useEffect(() => {
    if (error) {
      handleError(error);
    }

    if (data?.data?.object) {
      setGtmCredential(data.data.object);
      setValue('tracking_id', data.data.object.tracking_id);
    }
  }, [data, setValue, error, handleError]);

  const isGTMError = Boolean(error);
  const isLoadingGTM = isLoading;

  return (
    <GTMContext.Provider
      value={{
        isSubmittingForm,
        errors,
        handleSubmit,
        watch,
        trackingIdRegister,
        watchTrackingId,
        onActivateGTM,
        onInactivateGTM,
        isGTMError,
        onSubmit,
        gtmCredential,
        isLoadingGTM,
      }}
    >
      {children}
    </GTMContext.Provider>
  );
};

export const useGTMContext = (): IGTMProvider => {
  const context = React.useContext(GTMContext);

  if (!context) {
    throw new Error('useGTMContext must be used within provider');
  }

  return context;
};
