import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { EButtonVariant } from '@domain/enums/components/common/EButton';

import { useEditGroupsContext } from '@store/common/Group/EditGroupsContext';
import PermissionAccordion from '../PermissionAccordion';

import * as S from './styles';

const Body: React.FC = () => {
  const navigate = useNavigate();
  const { onSubmit, handleSubmit, register, errors, group, setValue, isLoadingUpdateGroup } =
    useEditGroupsContext();

  const onCancelButtonClick = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  React.useEffect(() => {
    setValue('group_name', group?.name);
  }, [setValue, group]);

  return (
    <S.Wrapper>
      <S.Form onSubmit={handleSubmit(onSubmit)}>
        <S.InputGroup>
          <S.Label>Nome do grupo</S.Label>
          <S.Input
            {...register('group_name', {
              required: { message: 'Campo obrigatório!', value: true },
            })}
            type="text"
            placeholder="Ex: Suporte"
          />
          {errors?.group_name && (
            <S.MessageError isFeedbackError>{errors?.group_name?.message}</S.MessageError>
          )}
        </S.InputGroup>

        <PermissionAccordion />

        <S.ButtonsWrapper>
          <S.CancelButton
            onClick={onCancelButtonClick}
            variant={EButtonVariant.SECONDARY}
            type="button"
          >
            Cancelar
          </S.CancelButton>
          <S.SubmitButton isLoading={isLoadingUpdateGroup} type="submit">
            Salvar
          </S.SubmitButton>
        </S.ButtonsWrapper>
      </S.Form>
    </S.Wrapper>
  );
};

export default Body;
