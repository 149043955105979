import styled from 'styled-components/macro';

import TypographyBase from '@components/common/DataDisplay/Typography';
import InputGroupBase from '@components/common/Inputs/InputGroup';
import LabelBase from '@components/common/DataDisplay/Label';
import TextFieldBase from '@components/common/Inputs/TextField';
import SwitchBase from '@components/common/Inputs/Switch';
import ButtonBase from '@components/common/Inputs/Button';

export const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 440px auto;
  gap: 20px;
`;

export const ZoutiPayAndTutorialWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const CredentialsWrapper = styled.div`
  width: 100%;
  background: #fff;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lighter};
  display: flex;
  flex-direction: column;
  padding: 42px;
  box-sizing: border-box;
  align-self: flex-start;
`;

export const CredentialsTitle = styled(TypographyBase)`
  color: ${({ theme }) => theme.colors.neutral.darker};
  margin-bottom: 24px;
`;

export const SkipCartWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 24px;
`;

export const SkipCartSwitch = styled(SwitchBase)``;

export const SkipCartText = styled(TypographyBase)`
  color: ${({ theme }) => theme.colors.neutral.darkest};
`;

export const TitleAndSwitchWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

export const Switch = styled(SwitchBase)``;

export const InputGroup = styled(InputGroupBase)`
  margin-bottom: 24px;
`;

export const Label = styled(LabelBase)``;

export const Input = styled(TextFieldBase)``;

export const ReinstallCheckoutButton = styled(ButtonBase)`
  max-width: 184px;

  & > span {
    font-size: 14px;
    color: ${({ theme }) => theme.colors.neutral.dark};
  }
`;
