import React from 'react';

import { Code } from 'phosphor-react';

import { useGenerateCheckoutCode } from '@store/common/checkoutCustomization/GenerateCheckoutCodeContext';
import { useToast } from '@store/context/common/ToastContext';

import Text from '@components/common/DataDisplay/Text';
import ColorPicker from './ColorPicker';
import Select from './Select';
import ProductSelect from './ProductSelect';

import * as S from './styles';

const ACTIONS = [
  {
    type: 'ZOUTI',
    message: 'Redirecionar para a página de obrigado da Zouti',
  },
  {
    type: 'EXTERNAL',
    message: 'Redirecionar para uma página de obrigado externa',
  },
];

const Editor: React.FC = () => {
  const { toast } = useToast();
  const {
    colorButtton,
    colorTextButton,
    colorTextDecline,
    setColorButton,
    setColorTextButton,
    setColorTextDecline,
    generateWidgetCode,
    acceptUpsellAction,
    setAcceptUpsellAction,
    setDeclineUpsellAction,
    handleSelectedOfferProduct,
    declineUpsellAction,
    selectedOfferProduct,
    acceptOfferTextRegister,
    declineOfferTextRegister,
    colorButtonRegister,
    colorTextButtonRegister,
    colorTextDeclineRegister,
    errors,
    externalDeclineUrlRegister,
    externalUpsellUrlRegister,
    handleSubmit,
    setValue,
  } = useGenerateCheckoutCode();

  const handleCopyCode = (formData: any): void => {
    if (!selectedOfferProduct) {
      toast.error('Selecione um produto para oferecer no Upsell');
      return;
    }

    const fullCode = generateWidgetCode();
    navigator.clipboard
      .writeText(fullCode)
      .then(() => toast.success('Código copiado com sucesso!'))
      .catch(() => toast.error('Erro ao copiar código'));
  };

  const handleAcceptUpselAction = (value: any): void => {
    setAcceptUpsellAction(value);
    setValue('acceptUpsellAction', value);
  };

  const handleDeclineUpsellAction = (value: any): void => {
    setDeclineUpsellAction(value);
    setValue('declineUpsellAction', value);
  };

  return (
    <S.Wrapper>
      <S.Form onSubmit={handleSubmit(handleCopyCode)}>
        <S.InputGroup>
          <S.Label>Texto do botão de aceitar</S.Label>
          <S.TextField
            {...acceptOfferTextRegister}
            type="text"
            placeholder="Digite um nome para o order bump (EDITAR)"
          />
          {errors?.acceptOfferText && (
            <Text isFeedbackError>{errors?.acceptOfferText.message}</Text>
          )}
        </S.InputGroup>
        <S.InputGroup>
          <S.Label>Texto do botão de recusar</S.Label>
          <S.TextField
            {...declineOfferTextRegister}
            type="text"
            placeholder="Digite um nome para o order bump (EDITAR)"
          />
          {errors?.declineOfferText && (
            <Text isFeedbackError>{errors?.declineOfferText.message}</Text>
          )}
        </S.InputGroup>
        <ColorPicker
          label="Cor do botão de aceitar"
          color={colorButtton}
          onChange={setColorButton}
          register={colorButtonRegister}
        />
        <ColorPicker
          label="Cor do texto do botão de compra"
          color={colorTextButton}
          onChange={setColorTextButton}
          register={colorTextButtonRegister}
        />
        <ColorPicker
          label="Cor do texto do botão de recusa"
          color={colorTextDecline}
          onChange={setColorTextDecline}
          register={colorTextDeclineRegister}
        />

        <S.InputGroup>
          <S.Label>Produto oferecido no Upsell</S.Label>
          <ProductSelect onProductSelect={handleSelectedOfferProduct} />
        </S.InputGroup>

        <S.InputGroup>
          <S.Label>Ao aceitar o Upsell</S.Label>
          <Select
            data={ACTIONS}
            onChange={handleAcceptUpselAction}
            defaultSelected={acceptUpsellAction}
          />
          {errors?.acceptUpsellAction && (
            <Text isFeedbackError>{errors?.acceptUpsellAction.message}</Text>
          )}
        </S.InputGroup>

        {acceptUpsellAction === 'EXTERNAL' && (
          <S.InputGroup>
            <S.Label>URL da página de obrigado</S.Label>
            <S.TextField
              type="text"
              {...externalUpsellUrlRegister}
              placeholder="https://zouti.com.br"
            />
            {errors?.externalUpsellUrl && (
              <Text isFeedbackError>{errors?.externalUpsellUrl.message}</Text>
            )}
          </S.InputGroup>
        )}

        <S.InputGroup>
          <S.Label>Ao recusar o Upsell</S.Label>
          <Select
            data={ACTIONS}
            onChange={handleDeclineUpsellAction}
            defaultSelected={declineUpsellAction}
          />
          {errors?.declineUpsellAction && (
            <Text isFeedbackError>{errors?.declineUpsellAction.message}</Text>
          )}
        </S.InputGroup>

        {declineUpsellAction === 'EXTERNAL' && (
          <S.InputGroup>
            <S.Label>URL da página de obrigado</S.Label>
            <S.TextField
              type="text"
              {...externalDeclineUrlRegister}
              placeholder="https://zouti.com.br"
            />
            {errors?.externalDeclineUrl && (
              <Text isFeedbackError>{errors?.externalDeclineUrl.message}</Text>
            )}
          </S.InputGroup>
        )}

        <S.Button type="submit">
          <Code />
          <S.span>Copiar código HTML</S.span>
        </S.Button>
      </S.Form>
    </S.Wrapper>
  );
};

export default Editor;
