import React from 'react';

import * as S from './styles';

export interface IImageProps {
  src?: string;
  alt?: string;
}

const Image: React.FC<IImageProps> = props => {
  return <S.ImageBase {...props} />;
};

export default Image;
