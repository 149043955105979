import styled from 'styled-components/macro';

import TextBase from '@components/common/DataDisplay/Text';

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  gap: 24px;
`;

export const PreviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  background-color: #fff;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  border-radius: 4px;
  padding: 24px;
  position: sticky;
  top: 80px;
`;

export const PreviewText = styled(TextBase)`
  font-size: 14px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.neutral.darker};
`;

export const PreviewBox = styled.div`
  width: 100%;
  height: 115;
  padding: 16px;
`;
