import React from 'react';

// import ZoutiTutorialImage from '@assets/images/common/zouti/zouti-tutorial.svg';

// import Tutorial from '@components/common/utils/Tutorial';
// import SaveHeaderBar from '@components/common/utils/SaveHeaderBar';
import ZoutiPay from './ZoutiPay';

import Credentials from './Credentials';

import * as S from './styles';

type PaymentCredentialsCancelButtonClick = () => void;
type PaymentCredentialsSubmitButtonClick = () => void;

const Body: React.FC = () => {
  const onPaymentCredentialsCancelButtonClick: PaymentCredentialsCancelButtonClick = () => {
    console.log('cancel');
  };

  const onPaymentCredentialsSubmitButtonClick: PaymentCredentialsSubmitButtonClick = () => {
    console.log('submit');
  };

  return (
    <S.Wrapper>
      {/* <SaveHeaderBar
        onCancelClick={onPaymentCredentialsCancelButtonClick}
        onSubmitClick={onPaymentCredentialsSubmitButtonClick}
      /> */}

      <S.ZoutiPayAndTutorialWrapper>
        <ZoutiPay />

        {/* <Tutorial
          image_url={ZoutiTutorialImage}
          title="Integração Zouti Pay: Guia de configuração fácil"
          video_url="https://www.youtube.com/embed/f5_wn8mexmM?si=Ud_rqwSSYa_nU4az"
        /> */}
      </S.ZoutiPayAndTutorialWrapper>

      <Credentials />
    </S.Wrapper>
  );
};

export default Body;
