import React from 'react';
import { Lock } from '@phosphor-icons/react';
import { useTheme } from 'styled-components';

import { useConfig } from '@store/context/common/ConfigContext';

import * as S from './styles';

const Blocked: React.FC = () => {
  const { colors } = useTheme();
  const { handleLogout } = useConfig();

  return (
    <S.Container>
      <S.Wrapper>
        <S.IconWrapper>
          <Lock size={30} color={colors.primary.dark_green} />
        </S.IconWrapper>

        <S.Title>Conta bloqueada</S.Title>

        <S.Description>
          A sua conta foi bloqueada. Entre em contato para mais detalhes ou finalize a sua sessão.
        </S.Description>

        <S.SignOutButton onClick={handleLogout}>Finalizar sessão</S.SignOutButton>
      </S.Wrapper>
    </S.Container>
  );
};

export default Blocked;
