import styled from 'styled-components/macro';

import DropdownBase from '@components/common/utils/Dropdown';
import TextBase from '@components/common/DataDisplay/Text';

export const DropdownContent = styled(DropdownBase.Content)`
  z-index: 999;
  display: flex;
`;

export const DateText = styled(TextBase)`
  font-size: 1.4rem;
  font-weight: 500;
`;

export const DropdownTrigger = styled(DropdownBase.Trigger)`
  height: 40px;
  width: 240px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  padding-left: 16px;
  padding-right: 16px;
  box-sizing: border-box;
  background-color: #fff;
  transition: all 0.3s;
  gap: 16px;
  cursor: pointer;

  &:hover {
    border-color: ${({ theme }) => theme.colors.neutral.dark};
  }

  &:active {
    border-color: ${({ theme }) => theme.colors.neutral.black};
  }
`;

export const Dropdown = styled(DropdownBase)``;
