export enum EDomainStatus {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  DEFAULT = 'DEFAULT',
}

export enum EDomainType {
  DEDICATED = 'DEDICATED',
  SHARED = 'SHARED',
}
