import mastercardCardIcon from '@assets/images/common/creditCardIssuers/mastercard.svg';
import visaCardIcon from '@assets/images/common/creditCardIssuers/visa.svg';
import dinersCardIcon from '@assets/images/common/creditCardIssuers/diners.svg';
import eloIcon from '@assets/images/common/creditCardIssuers/elo.svg';
import amexIcon from '@assets/images/common/creditCardIssuers/amex.svg';
import hiperIcon from '@assets/images/common/creditCardIssuers/hiper.svg';
import discoverIcon from '@assets/images/common/creditCardIssuers/discover.svg';

export const getCreditCardIssuer: Record<string, string> = {
  MASTERCARD: mastercardCardIcon,
  MASTER: mastercardCardIcon,
  VISA: visaCardIcon,
  DINERS: dinersCardIcon,
  ELO: eloIcon,
  AMERICAN_EXPRESS: amexIcon,
  AMEX: amexIcon,
  HIPERCARD: hiperIcon,
  DISCOVER: discoverIcon,
};
