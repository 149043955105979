import LabelBase from '@components/common/DataDisplay/Label';
import styled from 'styled-components/macro';

export const ValueInputWrapperCouple = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ColorInput = styled.input`
  width: 40px;
  height: 40px;
  font-size: 1.4rem;
  font-family: ${({ theme }) => theme.fonts.text};
  font-weight: 700;
  color: ${({ theme }) => theme.colors.neutral.darkest};
  background: white;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.base};
  display: inline-block;
`;

export const ValueWrapperCouple = styled.div`
  display: flex;
  align-content: center;
  width: 100%;
  height: 100%;

  & > div:nth-child(2) {
    border: unset;
    width: 100%;
    border-radius: unset;
    border-left: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
    border-right: 1px solid ${({ theme }) => theme.colors.neutral.lightest};

    & > input {
      width: 100%;
      text-align: left;
      height: 100%;
      margin-left: 10px;
    }
  }
`;

export const Label = styled(LabelBase)`
  color: ${({ theme }) => theme.colors.neutral.darker};
  font-weight: 500;
`;

export const Input = styled.input`
  height: 40px;
  width: 100%;
  color: ${({ theme }) => theme.colors.neutral.darker};
  font-family: 'Inter';
  font-weight: 400;
  font-size: 16px;
  padding-left: 20px;
  margin-left: 10px;
  border-left: 1px solid black;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  border-radius: 4px;

  &:active {
    outline: none;
  }

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.neutral.base};
    font-family: 'Inter';
    font-weight: 400;
    font-size: 14px;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.colors.neutral.lightest};
  }
`;
