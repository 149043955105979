import styled from 'styled-components/macro';

import TextBase from '@components/common/DataDisplay/Text';

export interface IButtonProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const Text = styled(TextBase)`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ButtonBase = styled.button<IButtonProps>`
  height: 28px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  gap: 8px;
  background-color: #fff;
  border: 2px solid #fff;
  position: relative;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary.lemon_20};

    > span {
      > svg {
        filter: invert(53%) sepia(86%) saturate(564%) hue-rotate(28deg) brightness(96%)
          contrast(83%);
      }
    }
  }

  &:active {
    filter: brightness(0.9);
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.neutral.light};
    border-color: ${({ theme }) => theme.colors.neutral.light};
    cursor: not-allowed;

    &:hover {
      filter: unset;
    }

    &:active {
      filter: unset;
    }
  }

  & > span {
    line-height: 100%;
    color: white;
    font-family: ${({ theme }) => theme.fonts.text};
    font-size: 1.6rem;
    font-weight: 500;
    opacity: 1;
  }
`;
