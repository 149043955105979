import React from 'react';

import { EPayoutStatus } from '@domain/enums/common/payouts/EPayoutStatus';

import * as S from './styles';

interface IStatusMessageProps {
  status: string;
  canceledReason?: string;
}

export const StatusMessage: React.FC<IStatusMessageProps> = ({ status, canceledReason }) => {
  const message = {
    [EPayoutStatus.FAILED]: {
      text: 'Houve uma falha ao processar sua solicitação. Verifique se os dados bancários estão corretos e tente novamente',
    },
    [EPayoutStatus.PROCESSING]: {
      text: 'A transferência está sendo processada e em breve estará disponível na sua conta bancária.',
    },
    [EPayoutStatus.PROCESSING_ATTEMPT]: {
      text: 'A transferência está sendo processada e em breve estará disponível na sua conta bancária.',
    },
    [EPayoutStatus.CANCELED]: {
      text: canceledReason,
    },
    [EPayoutStatus.COMPLETED]: {
      text: 'A transferência foi processada e deve estar disponível em sua conta bancária.',
    },
    [EPayoutStatus.PENDING]: {
      text: 'A transferência está em análise.',
    },
    [EPayoutStatus.SENT]: {
      text: 'A transferência foi enviada para sua conta bancária.',
    },
  }[status];

  return (
    <S.MessageContainer status={status as EPayoutStatus}>
      <S.WarningIcon />
      <S.MessageText>{message?.text}</S.MessageText>
    </S.MessageContainer>
  );
};
