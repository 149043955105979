import React from 'react';

import Content from '@components/pages/Dashboard/Groups/NewGroup';
import { GroupsProvider } from '@store/common/Group/NewGroupsContext';

const NewGroups: React.FC = () => {
  return (
    <GroupsProvider>
      <Content />
    </GroupsProvider>
  );
};

export default NewGroups;
