import React from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { parseToRawPhoneWithoutCountry, phone } from '@helpers/utils/common/phone';

import { useUpdatePersonalData } from '@store/common/profile/UpdatePersonalDataContext';
import { IUpdatePersonalData } from '@domain/interfaces/hooks/services/profile/IProfileService';

import { yupResolver } from '@hookform/resolvers/yup';
import { personalDataSchema } from '@helpers/validators/pages/dashboard/profile/personalDataSchema';

import * as S from './styles';
import SkeletonLoading from '../../SkeletonLoading';

const PersonalData: React.FC = () => {
  const { profile, updateCurrentProfilePersonalData, isUpdatingPersonalData, isLoadingProfile } =
    useUpdatePersonalData();
  const { full_name, email, phone_number, id } = profile;

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm<IUpdatePersonalData>({
    resolver: yupResolver(personalDataSchema),
  });
  const onSubmit = async (formData: FieldValues): Promise<void> => {
    const data: IUpdatePersonalData = {
      full_name: formData.full_name,
      email: formData.email,
      phone_number: parseToRawPhoneWithoutCountry(formData.phone_number),
    };
    await updateCurrentProfilePersonalData({ payload: data, profileId: id });
  };

  React.useEffect(() => {
    setValue('full_name', full_name);
    setValue('email', email);
    setValue('phone_number', phone(phone_number));
  }, [setValue, full_name, email, phone_number]);

  const phoneNumber = register('phone_number');

  const onPhoneChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    phoneNumber.onChange(event);

    const { value } = event.target;

    event.target.value = phone(value);
  };

  return (
    <>
      {isLoadingProfile ? (
        <SkeletonLoading />
      ) : (
        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <S.Wrapper>
            <S.InputGroup>
              <S.Label>Nome Completo</S.Label>
              <S.Input
                {...register('full_name')}
                isError={!!errors.full_name}
                defaultValue={full_name}
                placeholder="Digite um nome"
                type="text"
              />
              {errors.full_name && <S.MessageError>{errors.full_name.message}</S.MessageError>}
            </S.InputGroup>
            <S.InputGroup>
              <S.Label>E-mail</S.Label>
              <S.Input
                {...register('email')}
                isError={!!errors.email}
                defaultValue={email}
                placeholder="Digite um email"
                type="text"
              />
              {errors.email && <S.MessageError>{errors.email.message}</S.MessageError>}
            </S.InputGroup>
            <S.InputGroup>
              <S.Label>Telefone</S.Label>
              <S.Input
                {...phoneNumber}
                isError={!!errors.phone_number}
                defaultValue={phone_number}
                onChange={onPhoneChange}
                placeholder="Digite um telefone"
                type="text"
              />
              {errors.phone_number && (
                <S.MessageError>{errors.phone_number.message}</S.MessageError>
              )}
            </S.InputGroup>
          </S.Wrapper>
          <S.SubmitButton isLoading={isUpdatingPersonalData} type="submit">
            Salvar
          </S.SubmitButton>
        </S.Form>
      )}
    </>
  );
};

export default PersonalData;
