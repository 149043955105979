import * as Yup from 'yup';

export const createShippingSchemaValidation = Yup.object().shape({
  name: Yup.string().required('Este campo é obrigatório!'),
  is_free: Yup.boolean().required('Este campo é obrigatório!'),
  amount: Yup.string(),
  delivery_time_in_days: Yup.number().required('Este campo é obrigatório!'),
  show_delivery_time: Yup.boolean().required('Este campo é obrigatório!'),
});

export const updateShippingSchemaValidation = Yup.object().shape({
  name: Yup.string().required('Este campo é obrigatório!'),
  is_free: Yup.boolean().required('Este campo é obrigatório!'),
  amount: Yup.string(),
  show_delivery_time: Yup.boolean().required('Este campo é obrigatório!'),
  delivery_time_in_days: Yup.number().required('Este campo é obrigatório!'),
});
