export default {
  primary: {
    darkest: '#984200',
    darker: '#C05605',
    dark: '#DE690F',
    base: '#F37413',
    light: '#FC9545',
    lighter: '#FFAD6E',
    lighter_2: '#FFCEA5',
    lightest: '#FFF1DC',
    dark_green: '#262906',
    dark_green_80: '#4B530B',
    dark_green_60: '#717C11',
    dark_green_40: '#96A516',
    green_lemon: '#BCCF1C',
    green_light: '#CAD750',
    lemon_40: '#D7E277',
    lemon_30: '#E4ECA4',
    lemon_20: '#F2F5D2',
    lemon_10: '#F8FAE8',
  },
  secondary: {
    darkest: '#986500',
    darker: '#C28100',
    dark: '#E79900',
    base: '#F7A400',
    light: '#FFBC36',
    lighter: '#FFCA60',
    lightest: '#F6ECD7',
  },
  tertiary: {
    darkest: '#2F4D2A',
    darker: '#375931',
    dark: '#5E9954',
    base: '#86D977',
    light: '#C3ECBB',
    lighter: '#E1F5DD',
    lightest: '#F3FBF1',
  },
  positive: {
    darkest: '#082514',
    darker: '#104928',
    dark: '#196E3D',
    base: '#29B765',
    light: '#7FD4A3',
    lighter: '#A9E2C1',
    lightest: '#D4F1E0',
    lightest_10: '#EAF8F0',
  },
  negative: {
    darkest: '#2C0C0C',
    darker: '#581717',
    dark: '#842323',
    base: '#DC3A3A',
    light: '#EA8989',
    lighter: '#F1B0B0',
    lightest: '#F8D8D8',
    lightest_10: '#FBEBEB',
  },
  info: {
    darkest: '#090626',
    darker: '#120C4C',
    dark: '#1C1173',
    base: '#2E1DBF',
    light: '#8277D9',
    lighter: '#ABA5E5',
    lightest: '#D5D2F2',
    lightest_10: '#EAE8F9',
    white_blue: '#F1F0F5', // Deprecated
  },
  warning: {
    darkest: '#312301',
    darker: '#624601',
    dark: '#936802',
    base: '#F5AE03',
    light: '#FBDF9A',
    lighter: '#FDEFCD',
    lighter_10: '#FEF7E6',
  },
  neutral: {
    black: '#141414',
    darkest: '#282828',
    darker: '#515151',
    dark: '#797979',
    base: '#A1A1A1',
    light: '#C7C7C7',
    lighter: '#D5D5D5',
    lightest: '#E3E3E3',
    gray_dark: '#F9FAFB',
    gray_darkest: '#F2F2F2',
    gray_darker: '#F4F4F4',
    gray_white: '#FBFBFB',
    white: '#FFFFFF',
  },
};
