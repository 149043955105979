import React from 'react';

import { IAlertsContext } from '@domain/interfaces/store/context/common/IAlertsContext';

const AlertsContext = React.createContext<IAlertsContext | null>(null);

export const AlertsProvider: React.FC = ({ children }) => {
  const [alertsWrapperHeight, setAlertsWrapperHeight] = React.useState<number>(0);

  const handleAlertsWrapperHeight = React.useCallback(newHeight => {
    setAlertsWrapperHeight(newHeight);
  }, []);

  return (
    <AlertsContext.Provider value={{ alertsWrapperHeight, handleAlertsWrapperHeight }}>
      {children}
    </AlertsContext.Provider>
  );
};

export const useAlerts = (): IAlertsContext => {
  const context = React.useContext(AlertsContext);

  if (!context) {
    throw new Error('useAlerts must be used within provider');
  }

  return context;
};
