import React from 'react';
import { useTheme } from 'styled-components/macro';

import noProduct from '@assets/images/common/no-product.svg';

import { useUpdateOrderBump } from '@store/common/orderBump/UpdateOrderBumpContext';

import * as S from './styles';

const Preview: React.FC = () => {
  const theme = useTheme();
  const {
    selectedPrimaryColor,
    selectedTextColor,
    watch,
    selectedOfferProduct,
    hasSelectedAllVariants,
  } = useUpdateOrderBump();

  const [priceValue, promotionalPriceValue, ctaTextValue, titleValue, descriptionValue] = watch([
    'price',
    'promotional_price',
    'cta_text',
    'title',
    'description',
  ]);

  const [isDesktopVersion, setIsDesktopVersion] = React.useState<boolean>(true);

  const variantsOptions =
    selectedOfferProduct?.variants &&
    hasSelectedAllVariants &&
    selectedOfferProduct.variants?.length > 0
      ? selectedOfferProduct.variants
      : [];

  return (
    <S.PreviewModel style={{ width: isDesktopVersion ? '100%' : '60%' }}>
      <S.TitleArea>
        <S.TitleAreaText>
          <h2>Preview do order bump</h2>
        </S.TitleAreaText>
        <S.IconsArea>
          <S.DesktopIcon
            size={25}
            color={theme.colors.neutral.darker}
            onClick={() => setIsDesktopVersion(true)}
          />
          <S.MobileIcon
            size={25}
            color={theme.colors.neutral.darker}
            onClick={() => setIsDesktopVersion(false)}
          />
        </S.IconsArea>
      </S.TitleArea>

      <S.PreviewDataShow color={selectedPrimaryColor}>
        <S.PreviewHead>
          <S.TitleHead color={selectedPrimaryColor}>{titleValue || 'Título da oferta'}</S.TitleHead>

          <S.SubTitleHead color={selectedTextColor}>
            {descriptionValue || 'Texto de descrição escolhido para a oferta'}
          </S.SubTitleHead>
        </S.PreviewHead>
        <S.PreviewBody>
          <S.PreviewProduct>
            <S.ProductInfo>
              {selectedOfferProduct && selectedOfferProduct.images.length > 0 ? (
                <S.ImageProduct
                  src={selectedOfferProduct?.images[0].url}
                  alt="Order Bump Product"
                />
              ) : (
                <S.ImageProduct src={noProduct} alt="No Product" />
              )}

              <S.InfoProductDetails>
                <S.ProductName color={selectedTextColor}>
                  {selectedOfferProduct?.product.name || 'Nome do produto'}
                </S.ProductName>

                <S.Priced>
                  <S.PriceProduct color={selectedTextColor}>{`R$ ${
                    priceValue || '0,00'
                  }`}</S.PriceProduct>
                  <S.EndPrice color={selectedTextColor}>{`R$ ${
                    promotionalPriceValue || '0,00'
                  }`}</S.EndPrice>
                </S.Priced>
              </S.InfoProductDetails>
            </S.ProductInfo>

            {variantsOptions.length > 0 && (
              <S.Select>
                {variantsOptions.map(variant => (
                  <S.Option value={variant.id} key={variant.id}>
                    {variant.name}
                  </S.Option>
                ))}
              </S.Select>
            )}

            <S.CtaAreaPreview color={selectedPrimaryColor}>
              <S.Checkbox type="checkbox" />

              <S.CtaText color={selectedPrimaryColor}>{ctaTextValue || 'Texto do CTA'}</S.CtaText>
            </S.CtaAreaPreview>
          </S.PreviewProduct>
        </S.PreviewBody>
      </S.PreviewDataShow>
    </S.PreviewModel>
  );
};

export default Preview;
