import React from 'react';
import useSWR from 'swr';
import { useParams } from 'react-router-dom';

import { ISwr } from '@domain/interfaces/common/swr/ISwr';

import { useErrorHandler } from '@store/context/common/ErrorHandlerContext';
import { IGetPayoutsProvider } from '@domain/interfaces/store/common/payouts/IGetPayoutsProvider';
import { dashboardInstance } from '@services/common/dashboardInstance';
import { DASHBOARD_API_URL } from '@constants/services/apiUrl';
import { IListPayoutsResponse } from '@domain/interfaces/hooks/services/payouts/IPayoutsService';
import { IPayout } from '@domain/interfaces/common/payout/IPayout';

const GetPayoutsContext = React.createContext<IGetPayoutsProvider | null>(null);

interface IBalanceProps {
  total: number;
  pending: number;
  available: number;
  reserved: number;
}
interface IGetBalanceResponse {
  object: IBalanceProps;
}

export const GetPayoutsProvider: React.FC = ({ children }) => {
  const { accountId } = useParams();
  const { handleError } = useErrorHandler();

  const [page, setPage] = React.useState<number>(0);
  const [pageLimit, setPageLimit] = React.useState<number>(10);
  const [totalPages, setTotalPages] = React.useState<number>(0);
  const [payoutsTotal, setPayoutsTotal] = React.useState<number>(0);

  const [isOpenModal, setIsOpenModal] = React.useState<boolean>(false);
  const [payoutsData, setPayoutsData] = React.useState<any[]>([]);
  const [selectedPayout, setSelectedPayout] = React.useState<IPayout>({} as IPayout);
  const [balances, setBalances] = React.useState<IBalanceProps>({} as IBalanceProps);

  const GET_BALANCE_URL = `${DASHBOARD_API_URL}/accounts/${accountId}/balance`;

  const PAYOUTS_URL = `${DASHBOARD_API_URL}/accounts/${accountId}/payouts?page=${
    page + 1
  }&limit=${pageLimit}`;

  const {
    data: payoutData,
    isLoading: isLoadingPayouts,
    error: isErrorPayouts,
  } = useSWR<ISwr<IListPayoutsResponse>>(PAYOUTS_URL, dashboardInstance);

  const {
    data: balanceData,
    isLoading: isLoadingBalance,
    error: isErrorBalance,
  } = useSWR<ISwr<IGetBalanceResponse>>(GET_BALANCE_URL, dashboardInstance);

  const handlePageChange = React.useCallback(selected => {
    setPage(selected);
  }, []);

  const handlePageLimit = React.useCallback(newPageLimit => {
    setPageLimit(newPageLimit);
  }, []);

  React.useEffect(() => {
    if (isErrorPayouts) {
      handleError(isErrorPayouts);
    }

    if (isErrorBalance) {
      handleError(isErrorBalance);
    }

    if (payoutData?.data.object) {
      setPayoutsData(payoutData?.data.object.data);
      setTotalPages(payoutData?.data.object.total_pages);
      setPayoutsTotal(payoutData?.data.object.total);
    }

    if (balanceData?.data?.object) {
      setBalances(balanceData.data.object);
    }
  }, [balanceData, payoutData, isErrorBalance, isErrorPayouts, handleError]);

  const isLoading = isLoadingPayouts || isLoadingBalance;
  const isEmpty = payoutsData?.length === 0;
  const isShowPagination = payoutsData?.length > 10;

  return (
    <GetPayoutsContext.Provider
      value={{
        page,
        isEmpty,
        balances,
        isLoading,
        totalPages,
        isOpenModal,
        setIsOpenModal,
        handlePageLimit,
        handlePageChange,
        payoutsData,
        selectedPayout,
        payoutsTotal,
        isShowPagination,
        setSelectedPayout,
      }}
    >
      {children}
    </GetPayoutsContext.Provider>
  );
};

export const useGetPayouts = (): IGetPayoutsProvider => {
  const context = React.useContext(GetPayoutsContext);

  if (!context) {
    throw new Error('useGetPayouts must be used within GetPayoutsProvider');
  }

  return context;
};
