import { createGlobalStyle } from 'styled-components/macro';

export default createGlobalStyle`
  html {
    font-size: 62.5%;
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;

    @media only screen and (max-width: 1367px) {
      font-size: 54.6875%;
    }

    body {
      background: #FBFBFB;
      -webkit-font-smoothing: antialiased !important;
    }
  
    *, *::before, *::after {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      border: unset;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:focus {
      transition: background-color 600000s 0s, color 600000s 0s;
    }
    input[data-autocompleted] {
        background-color: transparent !important;
    }
  }
`;
