import { EUserType } from '@domain/enums/common/user/EUser';

interface ICheckUserPermissionsProps {
  scopes: string[];
  requiredPermissions: string[];
  type?: EUserType;
}

export const checkUserPermissions = ({
  type,
  scopes,
  requiredPermissions,
}: ICheckUserPermissionsProps): boolean => {
  if (scopes.includes('admin') || type === EUserType.ADMIN) return true;

  return requiredPermissions.some(
    permission => scopes.includes(`${permission}:read`) || scopes.includes(`${permission}:write`),
  );
};
