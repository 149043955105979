import React from 'react';
import useSWR from 'swr';
import { useParams, useNavigate } from 'react-router-dom';

import { IAccount } from '@domain/interfaces/common/account/IAccount';
import { ISwr } from '@domain/interfaces/common/swr/ISwr';
import {
  IGetAccountResponse,
  IListAccountsResponse,
} from '@domain/interfaces/hooks/services/account/IAccountService';
import { IUserAccountContext } from '@domain/interfaces/store/context/common/IUserAccountContext';

import { DASHBOARD_API_URL } from '@constants/services/apiUrl';

import { dashboardInstance } from '@services/common/dashboardInstance';
import { useErrorHandler } from '@store/context/common/ErrorHandlerContext';
import { useUserSession } from '@store/context/common/UserSession';

const UserAccountContext = React.createContext<IUserAccountContext | null>(null);

export const UserAccountProvider: React.FC = ({ children }) => {
  const { accountId } = useParams();
  const { handleError } = useErrorHandler();
  const { user } = useUserSession();
  const navigate = useNavigate();

  const isAdmin = user?.user?.type === 'ADMIN';

  const [userAccounts, setUserAccounts] = React.useState<Array<IAccount>>([]);
  const [currentUserAccount, setCurrentUserAccount] = React.useState<IAccount | undefined>(
    undefined,
  );

  dashboardInstance.defaults.headers.common['x-zouti-account'] = accountId;

  const ACCOUNTS_URL = `${DASHBOARD_API_URL}/accounts`;
  const ACCOUNT_URL = `${DASHBOARD_API_URL}/accounts/${accountId}`;

  const {
    data: accountsData,
    isLoading: isLoadingAccounts,
    isValidating: isValidatingAccounts,
    error: accountsError,
  } = useSWR<ISwr<IListAccountsResponse>>(ACCOUNTS_URL, dashboardInstance);

  const {
    data: accountData,
    isLoading: isLoadingAccount,
    isValidating: isValidatingAccount,
    error: accountError,
    mutate: mutateAccount,
  } = useSWR<ISwr<IGetAccountResponse>>(ACCOUNT_URL, dashboardInstance);

  React.useEffect(() => {
    if (accountsError) {
      handleError(accountsError);
      return;
    }

    if (accountsData?.data?.object && accountsData.data.object.length > 0) {
      setUserAccounts(accountsData.data.object);
    }
  }, [accountsData, accountsError, handleError]);

  React.useEffect(() => {
    if (accountData) {
      setCurrentUserAccount(accountData.data.object);
    }
  }, [accountData]);

  React.useEffect(() => {
    if (currentUserAccount && currentUserAccount.blocked_at && !isAdmin) {
      navigate('/blocked');
    }
  }, [currentUserAccount, navigate, isAdmin]);

  const isLoadingUserAccountProvider = isLoadingAccounts || isLoadingAccount;
  const isValidatingUserAccountProvider = isValidatingAccounts || isValidatingAccount;
  const isUserAccountProviderError = Boolean(accountsError) || Boolean(accountError);

  return (
    <UserAccountContext.Provider
      value={{
        isLoadingUserAccountProvider,
        isUserAccountProviderError,
        isValidatingUserAccountProvider,
        userAccounts,
        currentUserAccount,
        mutateAccount,
      }}
    >
      {children}
    </UserAccountContext.Provider>
  );
};

export const useUserAccount = (): IUserAccountContext => {
  const context = React.useContext(UserAccountContext);

  if (!context) {
    throw new Error('useUserAccount must be used within UserAccountProvider');
  }

  return context;
};
