import React from 'react';

import PageHeader from '@components/common/utils/PageHeader';

import * as S from './styles';

const Header: React.FC = () => {
  return (
    <S.Wrapper>
      <PageHeader title="Minhas taxas" description="Veja as taxas aplicadas em sua operação" />
    </S.Wrapper>
  );
};

export default Header;
