import React from 'react';

import { GetHopyStatementsProvider } from '@store/common/hopyTransactionsStatements/GetHopyStatements';

import Wrapper from './Wrapper';

const LegacyStatements: React.FC = () => {
  return (
    <GetHopyStatementsProvider>
      <Wrapper />
    </GetHopyStatementsProvider>
  );
};

export default LegacyStatements;
