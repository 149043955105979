import React from 'react';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/common/EHeading';

import * as S from './styles';

const Header: React.FC = () => {
  return (
    <S.Wrapper>
      <S.Title size={EHeadingSize.H2} fontWeight={EHeadingWeight.SEMI_BOLD}>
        Google Analytics 4
      </S.Title>
    </S.Wrapper>
  );
};

export default Header;
