import React from 'react';

import { useUpdateAccountData } from '@store/common/accountData/UpdateAccountData';
import { useAnalytics } from '@store/context/common/AnalyticsContext';

import Header from './Header';
import Body from './Body';

import * as S from './styles';
import SkeletonLoading from './SkeletonLoading';

const AccountData: React.FC = () => {
  const { isLoadingAccountData } = useUpdateAccountData();
  const { analytics } = useAnalytics();

  React.useEffect(() => {
    analytics.track('Account Data Page Viewed');
  }, [analytics]);

  if (isLoadingAccountData) {
    return <SkeletonLoading />;
  }

  return (
    <S.Wrapper>
      <Header />

      <Body />
    </S.Wrapper>
  );
};

export default AccountData;
