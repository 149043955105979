import React from 'react';
import PageHeader from '@components/common/utils/PageHeader';

import * as S from './styles';

const Header: React.FC = () => {
  return (
    <S.Wrapper>
      <PageHeader title="Extrato" />
    </S.Wrapper>
  );
};

export default Header;
