import React from 'react';
import { PlusCircle } from 'phosphor-react';
import { useNavigate, useParams } from 'react-router-dom';

import PageHeader from '@components/common/utils/PageHeader';

import * as S from './styles';

const Header: React.FC = () => {
  const navigate = useNavigate();
  const { accountId, checkoutId } = useParams();

  const onCreateButtonClick = (): void => {
    navigate(`/${accountId}/${checkoutId}/dashboard/checkout/customizations/new`);
  };

  return (
    <S.Wrapper>
      <PageHeader
        title="Customização"
        description="Crie personalizações exclusivas para o processo de checkout."
      />

      <S.NewCheckoutButton onClick={onCreateButtonClick}>
        <PlusCircle size={20} weight="bold" />
        Criar customização
      </S.NewCheckoutButton>
    </S.Wrapper>
  );
};

export default Header;
