import React from 'react';
import { CircleSpinner } from 'react-spinners-kit';
import { useTheme } from 'styled-components/macro';

import * as S from './styles';

const Wrapper: React.FC = () => {
  const theme = useTheme();

  return (
    <S.Wrapper>
      <CircleSpinner size={64} color={theme.colors.primary.dark_green_40} />
    </S.Wrapper>
  );
};

export default Wrapper;
