import React from 'react';

import { useListWebhooks } from '@store/common/webhook/ListWebhooksContext';
import { useAnalytics } from '@store/context/common/AnalyticsContext';

import CustomModal from '../CustomModal';
import Row from './Row';

import * as S from './styles';

const Body: React.FC = () => {
  const { webhooks } = useListWebhooks();
  const { analytics } = useAnalytics();

  React.useEffect(() => {
    analytics.track('Webhooks Page Viewed');
  }, [analytics]);

  return (
    <S.Wrapper>
      <CustomModal />
      <S.Table>
        <S.Table.Header>
          <S.Table.Row>
            <S.Table.Head />
            <S.Table.Head>Nome</S.Table.Head>
            <S.Table.Head>Criado em</S.Table.Head>
          </S.Table.Row>
        </S.Table.Header>

        <S.Table.Body>
          {webhooks.map(webhook => (
            <Row webhook={webhook} key={webhook.id} />
          ))}
        </S.Table.Body>
      </S.Table>
    </S.Wrapper>
  );
};

export default Body;
