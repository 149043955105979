import React from 'react';

import { ListOrdersProvider } from '@store/common/order/ListOrders';

import Wrapper from '@components/pages/Dashboard/Order/ListOrders/Wrapper';

const ListOrders: React.FC = () => {
  return (
    <ListOrdersProvider>
      <Wrapper />
    </ListOrdersProvider>
  );
};

export default ListOrders;
