import styled from 'styled-components/macro';

export const Body = styled.tbody`
  & > tr {
    transition: all 0.1s;
    // Comentado para posterior análise para entender o porque do hover afetar o comportamento do menu dropdown na row
    /* &:hover {
      filter: brightness(0.9);
    } */
  }
`;
