import React from 'react';
import useSWR from 'swr';
import { useParams } from 'react-router-dom';

import { EBalanceType } from '@domain/interfaces/store/common/statementTransactions/IGetStatementTransactionsProvider';
import { ISwr } from '@domain/interfaces/common/swr/ISwr';
import { IStatementTransactions } from '@domain/interfaces/common/statementTransactions/IStatementTransactions';
import { IGetHopyStatementsProvider } from '@domain/interfaces/store/common/hopyTransactionsStatements/IGetHopyStatementsProvider';

import { dashboardInstance } from '@services/common/dashboardInstance';

import { DASHBOARD_API_URL } from '@constants/services/apiUrl';

export const GetHopyStatementsContext = React.createContext<IGetHopyStatementsProvider | null>(
  null,
);

export const GetHopyStatementsProvider: React.FC = ({ children }) => {
  const { accountId } = useParams();

  const [page, setPage] = React.useState<number>(0);
  const [pageLimit, setPageLimit] = React.useState<number>(10);
  const [totalPages, setTotalPages] = React.useState<number>(0);
  const [statementTransactionTotal, setStatementTransactionTotal] = React.useState<number>(0);
  const [statementTransactions, setStatementTransactions] = React.useState<
    Array<IStatementTransactions>
  >([]);
  const [selectedBalance, setSelectedBalance] = React.useState<EBalanceType>(
    EBalanceType.Available,
  );

  const HOPY_RESERVE_STATEMENT_URL = `${DASHBOARD_API_URL}/accounts/${accountId}/hopy_reserve_statement?page=${
    page + 1
  }&limit=${pageLimit}`;
  const HOPY_AVAILABLE_STATEMENT_URL = `${DASHBOARD_API_URL}/accounts/${accountId}/hopy_available_statement?page=${
    page + 1
  }&limit=${pageLimit}`;

  const { data: reserveStatementResponse, isLoading: isLoadingReserveStatement } = useSWR<
    ISwr<any>
  >(HOPY_RESERVE_STATEMENT_URL, dashboardInstance);
  const { data: availableStatementResponse, isLoading: isLoadingAvailableStatement } = useSWR<
    ISwr<any>
  >(HOPY_AVAILABLE_STATEMENT_URL, dashboardInstance);

  const handleSelectedBalance = React.useCallback(newSelectedBalance => {
    if (newSelectedBalance === EBalanceType.All || newSelectedBalance === EBalanceType.Pending) {
      return;
    }

    setPage(0);
    setSelectedBalance(newSelectedBalance);
  }, []);

  const handlePageChange = React.useCallback(selected => {
    setPage(selected);
  }, []);

  const handlePageLimit = React.useCallback(newPageLimit => {
    setPageLimit(newPageLimit);
  }, []);

  React.useEffect(() => {
    if (selectedBalance === EBalanceType.Available && availableStatementResponse?.data?.object) {
      const statements = [
        ...availableStatementResponse.data.object.adjustments,
        ...availableStatementResponse.data.object.reserves,
      ];

      setStatementTransactions(statements);
      setTotalPages(availableStatementResponse.data.object.total_pages);
      setStatementTransactionTotal(availableStatementResponse.data.object.total);
    }

    if (selectedBalance === EBalanceType.Reserved && reserveStatementResponse?.data?.object) {
      setStatementTransactions(reserveStatementResponse.data.object.data);
      setTotalPages(reserveStatementResponse.data.object.total_pages);
      setStatementTransactionTotal(reserveStatementResponse.data.object.total);
    }
  }, [selectedBalance, reserveStatementResponse, availableStatementResponse]);

  const isEmpty = statementTransactions?.length === 0;

  return (
    <GetHopyStatementsContext.Provider
      value={{
        handlePageChange,
        handlePageLimit,
        handleSelectedBalance,
        page,
        pageLimit,
        selectedBalance,
        statementTransactions,
        statementTransactionTotal,
        totalPages,
        isEmpty,
      }}
    >
      {children}
    </GetHopyStatementsContext.Provider>
  );
};

export const useGetHopyStatements = (): IGetHopyStatementsProvider => {
  const context = React.useContext(GetHopyStatementsContext);

  if (!context) {
    throw new Error('useGetHopyStatements must be used within GetHopyStatementsProvider');
  }

  return context;
};
