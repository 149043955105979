import React from 'react';
import { DownloadSimple, Table, UploadSimple } from 'phosphor-react';
import { useDropzone } from 'react-dropzone';

import { useListOrders } from '@store/common/order/ListOrders';
import { useUserAccount } from '@store/context/common/UserAccount';
import { useToast } from '@store/context/common/ToastContext';

import { useFulfillmentService } from '@hooks/services/fulfillment/useFulfillmentService';

import { EButtonVariant } from '@domain/enums/components/common/EButton';

import PageHeader from '@components/common/utils/PageHeader';
import Modal from '@components/common/DataDisplay/Modal';

import * as S from './styles';

const Header: React.FC = props => {
  const { totalOrders } = useListOrders();
  const { currentUserAccount } = useUserAccount();
  const { uploadFulfillmentsSheet } = useFulfillmentService();
  const { toast } = useToast();

  const [isOpen, setIsOpen] = React.useState(false);
  const [file, setFile] = React.useState<File>();
  const [uploading, setUploading] = React.useState(false);

  const onClick = (): void => {
    setIsOpen(true);
  };

  const onDrop = (acceptedFiles: File[]): void => {
    setFile(acceptedFiles[0]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    maxSize: 104857600,
  });

  const fileSize = file ? file.size : 0;

  const formatterFilesize =
    fileSize > 1024 ** 2
      ? `${(fileSize / 1024 ** 2).toFixed(2)}MB`
      : `${(fileSize / 1024).toFixed(1)}kB`;

  const closeModal = (): void => {
    setIsOpen(false);
    setFile(undefined);
  };

  const uploadSheet = async (): Promise<void> => {
    if (!file) {
      toast.warning('Necessario selecionar um arquivo');

      return;
    }
    const fileExtension = file.name.split('.').pop() || '';

    if (!['csv', 'xlsx'].includes(fileExtension)) {
      toast.warning('Formato de arquivo inválido');

      return;
    }

    setUploading(true);

    try {
      const response = await uploadFulfillmentsSheet({
        accountId: currentUserAccount?.id || '',
        file,
      });

      if (response.data.object.success.length > 0) {
        toast.success('Atualização realizada com sucesso');

        closeModal();
      } else {
        toast.error('Falha na atualização.');
      }
    } catch (error: any) {
      console.error(error);
      toast.error('Ocorreu um erro inesperado.');
    }

    setUploading(false);
  };

  return (
    <S.Wrapper {...props}>
      <PageHeader title="Pedidos" quantityText={`${totalOrders} resultados`} />

      {/* <S.Button onClick={onClick} variant={EButtonVariant.SECONDARY}>
        <Table size={20} />
        Atualizar via planilha
      </S.Button> */}

      <Modal
        isOpen={isOpen}
        toggle={closeModal}
        title="Atualizar via planilha"
        onSuccess={uploadSheet}
        successText="Importar"
        cancelText="Cancelar"
        isSuccessButtonLoading={uploading}
        blockBackgroundClick
      >
        <S.ModalContent>
          {!file && (
            <>
              <S.ContainerDropzone {...getRootProps()}>
                <input {...getInputProps()} />
                <UploadSimple weight="bold" size={13} />
                <S.TextDropzone>Arraste e solte seu arquivo aqui</S.TextDropzone>
                <S.AcceptFormat>CSV ou XLS</S.AcceptFormat>
              </S.ContainerDropzone>

              {/* <S.DownloadTemplate>
                <DownloadSimple size={20} />

                <span>Baixar planilha modelo</span>
              </S.DownloadTemplate> */}
            </>
          )}

          {file && (
            <>
              <S.UploadedFileWrapper>
                <S.FileName>{file.name}</S.FileName>
                <S.FileSize>{formatterFilesize}</S.FileSize>
              </S.UploadedFileWrapper>
            </>
          )}
        </S.ModalContent>
      </Modal>
    </S.Wrapper>
  );
};

export default Header;
