import React from 'react';
import useSWR from 'swr';
import { useNavigate, useParams } from 'react-router-dom';

import { EDomainStatus, EDomainType } from '@domain/enums/common/domain/EDomain';
import { IDomain } from '@domain/interfaces/common/domain/IDomain';
import { IGetDomainProvider } from '@domain/interfaces/store/common/domain/IGetDomainProvider';
import { ISwr } from '@domain/interfaces/common/swr/ISwr';
import { IListDomainResponse } from '@domain/interfaces/hooks/services/domain/IDomainService';

import { DASHBOARD_API_URL } from '@constants/services/apiUrl';
import { dashboardInstance } from '@services/common/dashboardInstance';

import { useDomainService } from '@hooks/services/domain/useDomainService';
import { useErrorHandler } from '@store/context/common/ErrorHandlerContext';
import { useToast } from '@store/context/common/ToastContext';
import { useAnalytics } from '@store/context/common/AnalyticsContext';
import { useUserAccount } from '@store/context/common/UserAccount';
import { useUserCheckout } from '@store/context/common/UserCheckout';

const GetDomainContext = React.createContext<IGetDomainProvider | null>(null);

export const GetDomainProvider: React.FC = ({ children }) => {
  const navigate = useNavigate();
  const { deleteDomain, createDomain, activateDomain } = useDomainService();
  const { accountId, checkoutId } = useParams();
  const { toast } = useToast();
  const { handleError } = useErrorHandler();
  const { analytics } = useAnalytics();
  const { currentUserAccount } = useUserAccount();
  const { currentUserCheckout } = useUserCheckout();

  const [domains, setDomains] = React.useState<Array<IDomain>>([]);
  const [selectedRow, setSelectedRow] = React.useState<string | null>(null);
  const [domainDeletedInfo, setDomainDeletedInfo] = React.useState({
    domainId: '',
    domainUrl: '',
  });
  const [isLoadingCreatingDomain, setIsLoadingCreatingDomain] = React.useState<boolean>(false);
  const [isLoadingDeleteDomain, setIsLoadingDeleteDomain] = React.useState<boolean>(false);
  const [isOpenModal, setIsOpenModal] = React.useState<boolean>(false);
  const [isOpenAddDomainModal, setIsOpenAddDomainModal] = React.useState<boolean>(false);
  const [page] = React.useState<number>(0);
  const [totalDomains, setTotalDomains] = React.useState<number>(0);
  const [totalPages] = React.useState<number>(1);

  dashboardInstance.defaults.headers.common['x-zouti-account'] = accountId;

  const DOMAIN_URL = `${DASHBOARD_API_URL}/accounts/${accountId}/checkouts/${checkoutId}/domains?page=${page}&total=12`;

  const { data, isLoading, isValidating, mutate, error } = useSWR<ISwr<IListDomainResponse>>(
    DOMAIN_URL,
    dashboardInstance,
  );

  const checkHandle = React.useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
      setSelectedRow(event.target.value);

      await activateDomain({ accountId, checkoutId, domainId: event.target.value });

      toast.success('Domínio alterado com sucesso!');
    },
    [accountId, checkoutId, activateDomain, toast],
  );

  React.useEffect(() => {
    if (error) {
      handleError(error);
    }

    if (data?.data?.object) {
      setDomains(data.data.object);
    }
  }, [data, handleError, error]);

  React.useEffect(() => {
    if (!domains.length) return;

    const foundActiveDomain = domains.find(domain => !domain.inactivated_at);

    if (foundActiveDomain) setSelectedRow(foundActiveDomain.id);
  }, [domains]);

  const getDomainStatus = (domain: IDomain): EDomainStatus => {
    const { domain_status, type } = domain;

    if (type === EDomainType.SHARED) {
      return EDomainStatus.DEFAULT;
    }

    const statusMap: Record<string, EDomainStatus> = {
      ACTIVE: EDomainStatus.ACTIVE,
      PENDING: EDomainStatus.PENDING,
      DEFAULT: EDomainStatus.DEFAULT,
    };

    return statusMap[domain_status] || EDomainStatus.DEFAULT;
  };

  const createNewDomain = React.useCallback(
    async payload => {
      setIsLoadingCreatingDomain(true);
      try {
        const linkPayload =
          currentUserCheckout?.currency !== 'BRL'
            ? `pay.${payload.payload}`
            : `seguro.${payload.payload}`;

        const result = await createDomain({ accountId, checkoutId, payload: linkPayload });

        analytics.track('Domain Created');

        navigate(
          `/${accountId}/${checkoutId}/dashboard/checkout/domain/${result?.data.object?.id}`,
        );
        setIsOpenAddDomainModal(false);
        toast.success('Domínio adicionado com sucesso!');
      } catch (err) {
        setIsLoadingCreatingDomain(false);
        toast.error('Não foi possível criar o domínio');
      } finally {
        setIsLoadingCreatingDomain(false);
      }
    },
    [createDomain, navigate, toast, accountId, checkoutId, analytics, currentUserAccount],
  );

  const deleteCurrentDomain = React.useCallback(
    async domainId => {
      setIsLoadingDeleteDomain(true);
      try {
        await deleteDomain({ domainId, accountId, checkoutId });

        analytics.track('Domain Deleted');

        toast.success('Domínio excluído com sucesso.');
        await mutate();
        setIsOpenModal(false);
      } catch (errorCheckoutCustomization) {
        setIsLoadingDeleteDomain(false);
        handleError(errorCheckoutCustomization);
      } finally {
        setIsLoadingDeleteDomain(false);
      }
    },
    [accountId, checkoutId, deleteDomain, handleError, toast, mutate, analytics],
  );

  const isLoadingDomain = isLoading || isValidating;
  const isDomainsError = Boolean(error);
  const isEmptyState = domains.length === 0;
  const totalResultDomains = domains.length;

  return (
    <GetDomainContext.Provider
      value={{
        domains,
        isLoadingDomain,
        isDomainsError,
        page,
        totalPages,
        totalDomains,
        getDomainStatus,
        isOpenModal,
        setIsOpenModal,
        isOpenAddDomainModal,
        setIsOpenAddDomainModal,
        domainDeletedInfo,
        setDomainDeletedInfo,
        deleteCurrentDomain,
        isLoadingDeleteDomain,
        isEmptyState,
        totalResultDomains,
        mutate,
        selectedRow,
        checkHandle,
        createNewDomain,
        isLoadingCreatingDomain,
      }}
    >
      {children}
    </GetDomainContext.Provider>
  );
};

export const useGetDomain = (): IGetDomainProvider => {
  const context = React.useContext(GetDomainContext);

  if (!context) {
    throw new Error('useGetDomain must be used within provider');
  }

  return context;
};
