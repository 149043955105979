import React from 'react';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/common/EHeading';
import { EButtonVariant } from '@domain/enums/components/common/EButton';

import { useConfigDomain } from '@store/common/domain/ConfigDomainContext';

import { CopyArea } from './CopyArea';

import * as S from './styles';

const SecondStep: React.FC = () => {
  const { domain, verifyCurrentDomain, isLoadingVerifyDomain } = useConfigDomain();

  const isDomainActivated = domain?.domain_status === 'ACTIVE';

  return (
    <S.Wrapper>
      <S.InfoWrapper>
        <S.WrapperIconAndTextInfo>
          {isDomainActivated ? (
            <>
              <S.CheckCircleIcon statusVerify={isDomainActivated} />
              <S.InfoText statusVerify={isDomainActivated}>
                Os apontamentos foram validados.
              </S.InfoText>
            </>
          ) : (
            <>
              <S.InfoIcon statusVerify={isDomainActivated} />
              <S.InfoText statusVerify={isDomainActivated}>
                Crie os apontamentos para o seu domínio.
              </S.InfoText>
            </>
          )}
        </S.WrapperIconAndTextInfo>
      </S.InfoWrapper>

      <S.Divider />

      <S.TextWrapper>
        <S.Title size={EHeadingSize.H4} fontWeight={EHeadingWeight.MEDIUM}>
          Etapa 2: Crie os apontamentos para o seu domínio
        </S.Title>
        <S.Description>
          Dentro da seção de Configurações de DNS, selecione a opção &quot;Incluir&quot;. Preencha
          todos os campos com as informações correspondentes indicadas a seguir:
        </S.Description>
      </S.TextWrapper>

      {domain?.verification_records?.map(record => (
        <CopyArea key={record.value} host={record.name} type={record.type} value={record.value} />
      ))}

      {!isDomainActivated && (
        <S.VerifyDomainButton
          variant={EButtonVariant.SECONDARY}
          isLoading={isLoadingVerifyDomain}
          onClick={() => verifyCurrentDomain()}
        >
          <S.ArrowsCounterClockwiseIcon />
          Verificar domínio
        </S.VerifyDomainButton>
      )}
    </S.Wrapper>
  );
};

export default SecondStep;
