import { Clock, XCircle, Truck, ShoppingCartSimple, CheckCircle } from 'phosphor-react';
import { ReceiptX } from '@phosphor-icons/react';

export const EMAIL_TYPE = [
  {
    id: '1',
    type: 'WAITING_PAYMENT',
    icon: Clock,
  },
  {
    id: '2',
    type: 'CANCELED',
    icon: XCircle,
  },
  {
    id: '3',
    type: 'IN_TRANSPORT',
    icon: Truck,
  },
  {
    id: '4',
    type: 'ABANDONED_CART',
    icon: ShoppingCartSimple,
  },
  {
    id: '5',
    type: 'PAYMENT_APPROVED',
    icon: CheckCircle,
  },
  {
    id: '6',
    type: 'PAYMENT_REFUSED',
    icon: ReceiptX,
  },
];
