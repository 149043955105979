import React from 'react';
import { DOMAIN_PROVIDERS } from '@constants/common/domainProvider';
import { useConfigDomain } from '@store/common/domain/ConfigDomainContext';
import ProviderOption from '../../Option';

import * as S from './styles';

const FirstStep: React.FC = () => {
  const { providerSelected } = useConfigDomain();
  const currentProvider = DOMAIN_PROVIDERS.find(item => item.provider === providerSelected);
  return (
    <S.Wrapper>
      <S.Title>Etapa 1: Selecione o seu provedor</S.Title>
      <S.Description>
        Para acessar os registros de DNS do seu domínio, selecione um provedor abaixo:
      </S.Description>

      <S.WrapperSelect>
        <S.Label>Provider</S.Label>
        <S.Dropdown>
          <S.DropdownTrigger isDropdownOpen>
            {providerSelected ? (
              <>
                <S.IconContainer>
                  <S.ProviderIcon src={currentProvider?.icon} />
                </S.IconContainer>
                <S.ProviderName>{currentProvider?.provider}</S.ProviderName>
              </>
            ) : (
              'Selecione...'
            )}
          </S.DropdownTrigger>

          <S.DropdownContent align="center" sideOffset={10}>
            {DOMAIN_PROVIDERS.map(provider => (
              <ProviderOption provider={provider} key={provider.id} />
            ))}
          </S.DropdownContent>
        </S.Dropdown>
      </S.WrapperSelect>

      {providerSelected && (
        <S.ProviderAction>
          <S.WrapperAccessLink target="_blank" to={currentProvider?.providerUrl || ''}>
            <S.ArrowSquareOutIcon />
            <S.AccessLinkText>Acessar {providerSelected}</S.AccessLinkText>
          </S.WrapperAccessLink>

          {/* <S.WrapperTutorialLink target="_blank" to={currentProvider?.providerTutorialUrl || ''}>
            <S.MonitorPlayIcon />
            <S.TutorialLinkText>Veja como configurar</S.TutorialLinkText>
          </S.WrapperTutorialLink> */}
        </S.ProviderAction>
      )}
    </S.Wrapper>
  );
};

export default FirstStep;
