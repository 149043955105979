import React from 'react';

import Wrapper from '@components/pages/Dashboard/MyRates';
import { GetMyRatesProvider } from '@store/common/myRates/GetMyRatesContext';

const MyRates: React.FC = () => {
  return (
    <GetMyRatesProvider>
      <Wrapper />
    </GetMyRatesProvider>
  );
};

export default MyRates;
