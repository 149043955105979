import React from 'react';

import { useShopify } from '@store/common/shopify/ShopifyContext';

import * as S from './styles';

const ScopeErrorContent: React.FC = () => {
  const { scopesList } = useShopify();
  return (
    <S.Wrapper>
      <S.Text>
        Os escopos escolhidos no aplicativo da Shopify estão incompletas. Para garantir uma
        integração bem-sucedida, por favor, adicione todos os escopos abaixo:
      </S.Text>
      <S.ScopesCard>
        {scopesList?.map(scope => (
          <S.WrapperIconAndScope key={scope}>
            <S.WarningIcon />
            <S.ScopeText>{scope}</S.ScopeText>
          </S.WrapperIconAndScope>
        ))}
      </S.ScopesCard>
      <S.WrapperLinksActions>
        <S.AccessLinkArticle
          target="_blank"
          to="https://ajuda.zouti.com.br/pt-BR/articles/8504976-integrando-com-a-shopify"
        >
          <S.ArrowSquareOutIcon />
          <S.AccessLinkText>Ver artigo</S.AccessLinkText>
        </S.AccessLinkArticle>

        <S.AccessLinkShopify target="_blank" to="https://www.shopify.com/br">
          <S.AccessLinkText>Ir para a Shopify</S.AccessLinkText>
        </S.AccessLinkShopify>
      </S.WrapperLinksActions>
    </S.Wrapper>
  );
};

export default ScopeErrorContent;
