import React from 'react';

import { EButtonVariant } from '@domain/enums/components/common/EButton';
import { CHECKOUT_THEMES } from '@constants/common/themeCheckout';
import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/common/EHeading';

import { useNewCheckout } from '@store/common/checkoutCustomization/NewCheckoutCustomizationContext';

import * as S from './styles';

const LibraryTheme: React.FC = () => {
  const { setSelectedThemeCheckout, selectedThemeCheckout } = useNewCheckout();

  return (
    <S.Wrapper>
      <S.Title fontWeight={EHeadingWeight.BOLD} size={EHeadingSize.H5}>
        Biblioteca de temas
      </S.Title>
      <S.WrapperThemes>
        {CHECKOUT_THEMES.map(
          theme =>
            theme.key !== selectedThemeCheckout && (
              <S.ThemeCard key={theme.key}>
                <S.Image src={theme.themeImage} />
                <S.CardFooter>
                  <S.TextWrapper>
                    <S.NameTheme>{theme.name}</S.NameTheme>
                    <S.ThemeByText>Por Zouti</S.ThemeByText>
                  </S.TextWrapper>
                  <S.Button
                    onClick={() => setSelectedThemeCheckout(theme?.key)}
                    variant={EButtonVariant.SECONDARY}
                  >
                    Selecionar
                  </S.Button>
                </S.CardFooter>
              </S.ThemeCard>
            ),
        )}
      </S.WrapperThemes>
    </S.Wrapper>
  );
};

export default LibraryTheme;
