import styled from 'styled-components/macro';

export const CheckboxInputWrapper = styled.div`
  position: inherit;
`;

export const CheckboxLabelWrapper = styled.div``;

export const CheckboxMark = styled.span`
  display: block;

  transition: all 180ms;

  height: 16px;
  width: 16px;

  background-color: transparent;

  border: 1px solid ${({ theme }) => theme.colors.neutral.base};
  border-radius: 2px;

  &:after {
    content: '';
    position: absolute;
    display: none;

    left: 4px;
    top: 1px;

    width: 6px;
    height: 10px;

    border: solid white;
    border-width: 0 2px 2px 0;

    transform: rotate(45deg);
  }
`;

export const CheckboxInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:checked ~ ${CheckboxMark} {
    background-color: ${({ theme }) => theme.colors.primary.green_lemon};
    border: 1px solid ${({ theme }) => theme.colors.primary.green_lemon};

    &:after {
      display: block;
    }
  }
`;

export const Wrapper = styled.label`
  cursor: pointer;
  user-select: none;

  position: relative;

  display: flex;
  align-items: center;
  gap: 8px;

  font-family: ${({ theme }) => theme.fonts.typography};
  font-size: 1.4rem;
  font-weight: 500;

  &:hover ${CheckboxInput} ~ ${CheckboxMark} {
    border: 1px solid ${({ theme }) => theme.colors.neutral.darker};
  }
`;
