import React, { useEffect } from 'react';

import { Controller } from 'react-hook-form';

import { useUpdatePixel } from '@store/common/pixel/UpdatePixelContext';
import Text from '@components/common/DataDisplay/Text';

import * as S from './styles';

const FacebookForm: React.FC = () => {
  const { pixel, control, errors, register, setValue } = useUpdatePixel();
  const { send_boleto_purchase, send_pix_purchase, send_credit_card_purchase, name, provider_id } =
    pixel;

  const pixelName = register('pixelName');
  const pixelId = register('pixelId');

  useEffect(() => {
    setValue('pixelName', name);
    setValue('pixelId', provider_id);
  }, []);

  return (
    <S.Wrapper>
      <S.InputGroup>
        <S.Label>Nome do Pixel</S.Label>
        <S.Input {...pixelName} type="text" />
        {errors?.pixelName && <Text isFeedbackError>{errors?.pixelName?.message}</Text>}
      </S.InputGroup>

      <S.InputGroup>
        <S.Label>ID do pixel</S.Label>
        <S.Input {...pixelId} type="text" />
        {errors?.pixelId && <Text isFeedbackError>{errors?.pixelId?.message}</Text>}
      </S.InputGroup>

      <S.CheckboxGroup>
        <Controller
          control={control}
          name="send_credit_card_purchase"
          defaultValue={send_credit_card_purchase}
          render={({ field }) => (
            <S.Checkbox
              checked={send_credit_card_purchase}
              value={field?.value?.toString()}
              onChange={() => field?.onChange(!field.value)}
            />
          )}
        />
        <S.CheckboxLabel htmlFor="send_credit_card_purchase">
          Marcar compras no cartão
        </S.CheckboxLabel>
      </S.CheckboxGroup>

      <S.CheckboxGroup>
        <Controller
          control={control}
          name="send_pix_purchase"
          defaultValue={send_pix_purchase}
          render={({ field }) => (
            <S.Checkbox
              checked={send_pix_purchase}
              value={field?.value?.toString()}
              onChange={() => field?.onChange(!field.value)}
            />
          )}
        />
        <S.CheckboxLabel htmlFor="send_pix_purchase">Marcar compras no pix</S.CheckboxLabel>
      </S.CheckboxGroup>

      <S.CheckboxGroup>
        <Controller
          control={control}
          name="send_boleto_purchase"
          defaultValue={send_boleto_purchase}
          render={({ field }) => (
            <S.Checkbox
              checked={send_boleto_purchase}
              value={field?.value?.toString()}
              onChange={() => field?.onChange(!field.value)}
            />
          )}
        />
        <S.CheckboxLabel htmlFor="send_boleto_purchase">Marcar compras no boleto</S.CheckboxLabel>
      </S.CheckboxGroup>
    </S.Wrapper>
  );
};

export default FacebookForm;
