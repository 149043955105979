import React from 'react';
import { useUsersContext } from '@store/common/users/UsersContext';
import ListSkeletonLoading from '@components/common/utils/ListSkeletonLoading';
import Header from './Header';
import Body from './Body';

import * as S from './styles';

const Users: React.FC = () => {
  const { isLoadingMemberships } = useUsersContext();

  if (isLoadingMemberships) {
    return <ListSkeletonLoading />;
  }

  return (
    <S.Wrapper>
      <Header />

      <Body />
    </S.Wrapper>
  );
};

export default Users;
