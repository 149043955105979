import React from 'react';
import { Info } from '@phosphor-icons/react';

import { useGetStatementTransactions } from '@store/common/statementTransactions/GetStatementTransactionsContext';

import * as S from './styles';

const ShowLegacyBalance: React.FC = () => {
  const { showLegacyBalance, handleShowLegacyBalance } = useGetStatementTransactions();

  return (
    <S.Wrapper>
      <S.SwitchWrapper>
        <S.Switch
          id="show-legacy-balance"
          name="show-legacy-balance"
          defaultChecked={showLegacyBalance}
          onChange={handleShowLegacyBalance}
        />
        <S.SwitchLabel>Visualizar saldo legado</S.SwitchLabel>
      </S.SwitchWrapper>

      {showLegacyBalance && (
        <S.AlertWrapper>
          <Info size={24} color="#5A31F4" weight="bold" />
          <S.AlertText>O saldo legado mostra os dados referente à antiga plataforma.</S.AlertText>
        </S.AlertWrapper>
      )}
    </S.Wrapper>
  );
};

export default ShowLegacyBalance;
