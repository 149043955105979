import React from 'react';

import { EBalanceType } from '@domain/interfaces/store/common/statementTransactions/IGetStatementTransactionsProvider';

import { useGetHopyTransactionsStatements } from '@store/common/hopyTransactionsStatements/GetHopyTransactionsStatements';

import { useGetHopyStatements } from '@store/common/hopyTransactionsStatements/GetHopyStatements';

import BottomPagination from '@components/common/utils/BottomPagination';

import LegacyBalanceCard from '../LegacyBalanceCard';
import Body from '../Body';

const Wrapper: React.FC = () => {
  const { balances } = useGetHopyTransactionsStatements();
  const {
    page,
    pageLimit,
    totalPages,
    handlePageLimit,
    handlePageChange,
    statementTransactionTotal,
    selectedBalance,
  } = useGetHopyStatements();

  return (
    <>
      <LegacyBalanceCard balances={balances} />

      <Body />

      {selectedBalance !== EBalanceType.Available && (
        <BottomPagination
          page={page}
          pageLimit={pageLimit}
          totalPages={totalPages}
          handlePageLimit={handlePageLimit}
          handlePageChange={handlePageChange}
          totalItems={statementTransactionTotal}
        />
      )}
    </>
  );
};

export default Wrapper;
