import React from 'react';
import { Barcode, ArrowCircleUpRight } from 'phosphor-react';
import PIX from '@components/icons/Pix';
import { IReserve } from '@domain/interfaces/common/reserve/IReserve';

import { RateCard } from '../RateCard';

interface IFinancialReserveProps {
  rates: IReserve['rolling'];
}

export const FinancialReserve: React.FC<IFinancialReserveProps> = ({ rates }) => {
  const replaceAvailableIn = (value: string): string => {
    return value?.replace('d', '');
  };

  const paymentMethods = [
    {
      icon: <PIX size="18" fillColor="#515151" />,
      name: 'Pix',
      fee: `${rates?.pix?.percentage}%`,
      textFee: 'transação',
      description: `Liberação em ${replaceAvailableIn(
        rates?.pix?.available_in,
      )} dias após recebimento`,
    },
    {
      icon: <Barcode size="18" weight="bold" />,
      name: 'Boleto bancário',
      fee: `${rates?.boleto?.percentage}%`,
      textFee: 'boleto pago',
      description: `Liberação em ${replaceAvailableIn(
        rates?.pix?.available_in,
      )} dias após recebimento`,
    },
    {
      icon: <ArrowCircleUpRight size="18" weight="bold" />,
      name: 'Antecipação',
      fee: `${rates?.credit_card?.percentage}%`,
      textFee: 'transação',
      description: `Liberação em ${replaceAvailableIn(
        rates?.pix?.available_in,
      )} dias após recebimento`,
    },
  ];
  return <RateCard rates={paymentMethods} title="Reserva financeira" />;
};
