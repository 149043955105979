import LabelBase from '@components/common/DataDisplay/Label';
import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled(LabelBase)``;

export const TextArea = styled.textarea<{ isDisabled?: boolean }>`
  width: 100%;
  min-height: 120px;
  padding: ${({ theme }) => theme.spacing.small_16};
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  border-radius: ${({ theme }) => theme.borderRadius.small};

  font-family: ${({ theme }) => theme.fonts.text};
  font-size: 16px;
  resize: none;

  &:active {
    outline: none;
  }

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.neutral.base};
    font-family: ${({ theme }) => theme.fonts.text};
    font-weight: 400;
    font-size: 14px;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.colors.neutral.lightest};
  }
`;
