import { AxiosResponse } from 'axios';

import { dashboardInstance } from '@services/common/dashboardInstance';
import {
  IActivateDomainProps,
  ICreateDomainProps,
  ICreateDomainResponse,
  IDeleteDomainProps,
  IDeleteDomainResponse,
  IDomainService,
  IListDomainResponse,
  IVerifyDomainProps,
  IVerifyDomainResponse,
} from '@domain/interfaces/hooks/services/domain/IDomainService';

export const useDomainService = (): IDomainService => {
  const listDomains = async (): Promise<AxiosResponse<IListDomainResponse>> => {
    return dashboardInstance.get('accounts/:accountId/domains');
  };

  const createDomain = async ({
    accountId,
    checkoutId,
    payload,
  }: ICreateDomainProps): Promise<AxiosResponse<ICreateDomainResponse>> => {
    return dashboardInstance.post(
      `accounts/${accountId}/checkouts/${checkoutId}/domains`,
      { name: `${payload}` },
      {
        headers: {
          'x-zouti-account': accountId,
        },
      },
    );
  };

  const deleteDomain = async ({
    accountId,
    checkoutId,
    domainId,
  }: IDeleteDomainProps): Promise<AxiosResponse<IDeleteDomainResponse>> => {
    return dashboardInstance.delete(
      `accounts/${accountId}/checkouts/${checkoutId}/domains/${domainId}`,
    );
  };

  const verifyDomain = async ({
    domainId,
    accountId,
    checkoutId,
  }: IVerifyDomainProps): Promise<AxiosResponse<IVerifyDomainResponse>> => {
    return dashboardInstance.post(
      `accounts/${accountId}/checkouts/${checkoutId}/domains/${domainId}/verify`,
      {},
      {
        headers: {
          'x-zouti-account': accountId,
        },
      },
    );
  };

  const activateDomain = async ({
    domainId,
    accountId,
    checkoutId,
  }: IActivateDomainProps): Promise<AxiosResponse<void>> => {
    return dashboardInstance.put(
      `accounts/${accountId}/checkouts/${checkoutId}/domains/${domainId}/activate`,
      {},
      {
        headers: {
          'x-zouti-account': accountId,
        },
      },
    );
  };

  return { listDomains, createDomain, deleteDomain, verifyDomain, activateDomain };
};
