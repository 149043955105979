import React from 'react';

import { ShopifyProvider } from '@store/common/shopify/ShopifyContext';

import Wrapper from '@components/pages/Dashboard/Shopify/Wrapper';

const Shopify: React.FC = () => {
  return (
    <ShopifyProvider>
      <Wrapper />
    </ShopifyProvider>
  );
};

export default Shopify;
