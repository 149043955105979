import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 621px;
  height: 318px;
  padding: 24px;
  background-color: ${({ theme }) => theme.colors.neutral.white};
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const WrapperItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ChartBody = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const ChartBar = styled.div``;
