import React from 'react';

import { useGetBankAccount } from '@store/common/BankAccount/GetBankAccountContext';
import { EHeadAlign } from '@domain/enums/components/common/ETable';
// import BottomPagination from '@components/common/utils/BottomPagination';

import TableRow from './TableRow';
import DeleteBankAccountModal from '../DeleteBankAccountModal';

import * as S from './styles';

const Body: React.FC = () => {
  const { bankAccounts, bankAccountsTotal, page, totalPages, handlePageChange } =
    useGetBankAccount();

  return (
    <S.Wrapper>
      <DeleteBankAccountModal />
      <S.Table>
        <S.Table.Header>
          <S.Table.Row>
            <S.Table.Head>Titular da conta</S.Table.Head>
            <S.Table.Head>Banco</S.Table.Head>
            <S.Table.Head>Agência</S.Table.Head>
            <S.Table.Head>Conta</S.Table.Head>
            <S.Table.Head align={EHeadAlign.CENTER}>Tipo de conta</S.Table.Head>
          </S.Table.Row>
        </S.Table.Header>

        <S.Table.Body>
          {bankAccounts.map(bank => (
            <TableRow bankAccount={bank} key={bank.id} />
          ))}
        </S.Table.Body>
      </S.Table>
      {/* <BottomPagination
        page={page}
        pageLimit={10}
        totalItems={bankAccountsTotal}
        totalPages={totalPages}
        handlePageChange={handlePageChange}
      /> */}
    </S.Wrapper>
  );
};

export default Body;
