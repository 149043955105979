import styled from 'styled-components/macro';

enum EStatus {
  EXPIRED = 'EXPIRED',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

interface IBadgeProps {
  status: EStatus;
}

export const Badge = styled.span<IBadgeProps>`
  display: flex;
  width: 68px;
  height: 23px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;

  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  text-transform: uppercase;

  ${({ theme, status }) => {
    if (status === 'EXPIRED') {
      return `
        color: ${theme.colors.negative.darkest};
        background: ${theme.colors.negative.lightest};
      `;
    }
    if (status === 'ACTIVE') {
      return `
        color: ${theme.colors.positive.darkest};
        background: ${theme.colors.positive.lightest};
      `;
    }
    if (status === 'INACTIVE') {
      return `
        color: ${theme.colors.neutral.darkest};
        background: ${theme.colors.neutral.lightest};
      `;
    }

    return '';
  }}
`;
