import styled from 'styled-components/macro';

import LabelBase from '@components/common/DataDisplay/Label';
import TextBase from '@components/common/DataDisplay/Text';
import ButtonBase from '@components/common/Inputs/Button';
import InputGroupBase from '@components/common/Inputs/InputGroup';
import SwitchBase from '@components/common/Inputs/Switch';
import TextFieldBase from '@components/common/Inputs/TextField';

export const SubmitButton = styled(ButtonBase)``;

export const CancelButton = styled(ButtonBase)`
  & > span {
    color: ${({ theme }) => theme.colors.neutral.darkest};
  }
`;

export const TextAreaEditor = styled.textarea`
  height: 150px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  border-radius: 4px;
  padding: 15px;
  font-family: ${({ theme }) => theme.fonts.text};
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.neutral.darker};
`;

export const PeriodButton = styled.button`
  background-color: unset;
  box-shadow: unset;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const PeriodWrapper = styled.div`
  display: flex;
  align-content: center;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  width: fit-content;
  border-radius: 4px;

  & > div:nth-child(2) {
    border: unset;
    width: 100px;
    border-radius: unset;
    border-left: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
    border-right: 1px solid ${({ theme }) => theme.colors.neutral.lightest};

    & > input {
      text-align: center;
    }
  }
`;

export const CurrencyLabel = styled.div`
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  font-family: ${({ theme }) => theme.fonts.text};
  font-weight: 700;
  color: ${({ theme }) => theme.colors.neutral.darkest};
`;

export const ValueWrapper = styled.div`
  display: flex;
  align-content: center;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  width: fit-content;
  border-radius: 4px;

  & > div:nth-child(2) {
    border: unset;
    width: 140px;
    border-radius: unset;
    border-left: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
    border-right: 1px solid ${({ theme }) => theme.colors.neutral.lightest};

    & > input {
      text-align: right;
    }
  }
`;

export const CoupleSelect = styled.div`
  display: flex;
  align-content: center;
  width: 100%;
  flex-wrap: wrap;
`;

export const ValueWrapperCoupleSelect = styled.div`
  display: flex;
  align-content: center;
  width: 100%;
  border-radius: 4px;
  gap: 16px;
`;

export const ValueWrapperCouple = styled.div`
  display: flex;
  align-content: center;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  width: 100%;
  border-radius: 4px;

  & > div:nth-child(2) {
    border: unset;
    width: 100%;
    border-radius: unset;
    border-left: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
    border-right: 1px solid ${({ theme }) => theme.colors.neutral.lightest};

    & > input {
      width: 100%;
      text-align: left;
    }
  }
`;

export const Text = styled(TextBase)`
  font-size: 1.4rem;
`;

export const SwitchAndText = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ValueInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ValueInputWrapperCouple = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 49%;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: flex-end;
`;

export const Input = styled(TextFieldBase)`
  padding-left: 15px;
`;
export const InputCouple = styled(TextFieldBase)`
  width: 100%;
`;

export const Switch = styled(SwitchBase)``;

export const Label = styled(LabelBase)``;

export const InputGroup = styled(InputGroupBase)``;

export const InputGroupFlex = styled(InputGroupBase)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`;

export const Form = styled.form`
  width: 100%;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-color: white;
  padding: 24px;
  border-radius: 4px;
  margin-bottom: 24px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
`;

export const InitialButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: flex-start;
  width: 30%;
`;

export const EndButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: flex-end;
  width: 70%;
`;

export const ButtonDelete = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: flex-start;
  font-family: Inter, 'sans-serif';
  font-size: 1.6rem;
  font-weight: 500;
  height: 40px;
  cursor: pointer;
  color: #dc3a3a;
`;
