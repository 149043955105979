import React from 'react';
import * as DropDownMenu from '@radix-ui/react-dropdown-menu';

import * as S from './styles';

const Separator: React.FC<
  DropDownMenu.DropdownMenuItemProps & React.RefAttributes<HTMLDivElement>
> = ({ children, ...rest }) => {
  return <S.Wrapper {...rest}>{children}</S.Wrapper>;
};

export default Separator;
