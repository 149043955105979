import styled from 'styled-components/macro';

import ButtonBase from '@components/common/Inputs/Button';
import HeadingBase from '@components/common/DataDisplay/Heading';
import InputGroupBase from '@components/common/Inputs/InputGroup';
import LabelBase from '@components/common/DataDisplay/Label';
import SwitchBase from '@components/common/Inputs/Switch';
import TextBase from '@components/common/DataDisplay/Text';
import TextFieldBase from '@components/common/Inputs/TextField';
import TableBase from '@components/common/utils/Table';
import { Accordion } from '@components/common/Surfaces/Accordion/styles';

export const Divider = styled.hr`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.neutral.lightest};
  margin: 16px 0 24px 0;
`;

export const Helper = styled.p`
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  color: ${({ theme }) => theme.colors.neutral.darker};
  margin: 8px 0;
`;

export const Title = styled(HeadingBase)`
  display: inline-block;
  color: ${({ theme }) => theme.colors.neutral.darkest};
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  background-color: ${({ theme }) => theme.colors.neutral.white};
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  border-radius: 8px;
`;

export const DnsData = styled.div.attrs({
  className: 'DnsAccordionData',
})`
  & .Accordion {
    gap: 24px;
    background-color: ${({ theme }) => theme.colors.neutral.white};
    border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
    border-radius: 8px;
    margin-top: 24px;
  }

  & .Accordion .AccordionContent {
    padding: 0 24px;
  }

  & .Accordion.open .AccordionContent {
    padding: 0 24px 24px 24px;
  }
`;

export const Switch = styled(SwitchBase)``;

export const WrapperSwitch = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const LabelSwitch = styled(LabelBase)`
  margin: 0;
  font-weight: 600;
`;

export const Label = styled(LabelBase)`
  font-weight: 500;
`;

export const Input = styled(TextFieldBase)``;

export const InputGroup = styled(InputGroupBase)``;

export const MessageError = styled(TextBase)``;

export const ButtonsGroup = styled.div`
  display: flex;
  gap: 12px;
`;

export const WrapperInputs = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

export const VerifyEmailButton = styled(ButtonBase)`
  align-self: self-start;
`;

export const ButtonTitle = styled(TextBase)`
  font-size: 1.6rem;
  font-weight: 500;
`;

export const EditButton = styled(ButtonBase)`
  align-self: flex-start;
  height: 20px;
  background-color: unset;
  border: none;
`;

export const EditButtonTitle = styled(TextBase)`
  font-size: 1.6rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.neutral.darker};
`;

export const ToastInfo = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  gap: 4px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.warning.lighter_10};
`;

export const ToastInfoTitle = styled(TextBase)`
  font-weight: 600;
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.warning.base};
`;

export const SuccessVerifyInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 12px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.positive.base};
  background-color: ${({ theme }) => theme.colors.positive.lightest_10};

  ${Label} {
    font-weight: 600;
  }
`;

export const WrapperInputSuccess = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const InputValue = styled(TextBase)`
  font-size: 1.6rem;
`;

export const InputSuccessGroup = styled(InputGroupBase)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const SettingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Wrapper = styled.div`
  max-width: 1024px;
  margin: 0 auto;
`;

export const Table = styled(TableBase)`
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  border-radius: 8px;

  margin-top: 24px;

  th {
    padding-bottom: 16px !important;
  }
`;
