export const numberFormatter = (number: number, digits: number, noDecimal?: boolean): string => {
  if (!number) {
    return '0';
  }

  if (number % 1 === 0 && noDecimal) {
    return number.toLocaleString('pt-br', { maximumFractionDigits: 0 });
  }

  return number.toLocaleString('pt-br', {
    maximumFractionDigits: digits,
    minimumFractionDigits: digits,
  });
};
