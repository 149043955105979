import React from 'react';

import { ETypographySize, ETypographyWeight } from '@domain/enums/components/common/ETypography';

import * as S from './styles';

interface IIntegrationCard {
  icon: any;
  title: string;
  description: string;
  onClick?: () => void;
}

const IntegrationCard: React.FC<IIntegrationCard> = ({ icon, title, description, onClick }) => {
  return (
    <S.CardWrapper onClick={onClick}>
      <S.Wrapper>
        <S.ContentWrapper>
          <S.IconWrapper>
            <S.Icon src={icon} alt="Integration image" />
          </S.IconWrapper>

          <S.Title size={ETypographySize.h6_button_medium} weight={ETypographyWeight.medium}>
            {title}
          </S.Title>

          <S.Description size={ETypographySize.caption}>{description}</S.Description>
        </S.ContentWrapper>

        <S.InstallText size={ETypographySize.medium_text}>Instalar</S.InstallText>
      </S.Wrapper>
    </S.CardWrapper>
  );
};

export default IntegrationCard;
