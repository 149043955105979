import styled from 'styled-components/macro';

import TextBase from '@components/common/DataDisplay/Text';

export const Text = styled(TextBase)`
  color: ${({ theme }) => theme.colors.neutral.black};
  font-size: 1.8rem;
  font-weight: 600;
`;

export const NextMonthButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  margin-right: 12px;
  font-weight: 700;
  transition: all 0.3s;
`;

export const PreviousMonthButton = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  margin-left: 12px;
  font-weight: 700;
  transition: all 0.3s;
`;

export const HeaderWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
  height: 23px;
`;
