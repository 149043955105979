import TextBase from '@components/common/DataDisplay/Text';
import styled from 'styled-components/macro';
import ChipBase from '@components/common/DataDisplay/Chip';

export const Wrapper = styled.div`
  width: 621px;
  height: 375px;
  background-color: ${({ theme }) => theme.colors.neutral.white};
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  padding: ${({ theme }) => theme.spacing.small_24};
`;

export const ChartWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 75%;

  tspan {
    font-family: ${({ theme }) => theme.fonts.typography};
    font-size: 1.2rem;
    fill: ${({ theme }) => theme.colors.neutral.base};
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin-bottom: 20px;
`;

export const Title = styled(TextBase)`
  font-size: 1.6rem;
  line-height: 19px;
  display: inline-block;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.colors.neutral.dark};
`;

export const RevenueInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RevenueValueAndPercentTagWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
`;

export const Chip = styled(ChipBase)``;

export const PercentTag = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  height: 20px;
  padding: 4px 8px;
  font-family: ${({ theme }) => theme.fonts.typography};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  font-size: 1.2rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.positive.darker};
  background-color: ${({ theme }) => theme.colors.positive.lightest};
`;

export const RevenueValue = styled(TextBase)<{ isEmptyData: boolean }>`
  font-size: ${({ isEmptyData }) => (isEmptyData ? '1.6rem' : '2.0rem')};
  color: ${({ theme, isEmptyData }) =>
    isEmptyData ? theme.colors.neutral.base : theme.colors.neutral.darker};
  font-weight: ${({ isEmptyData }) => (isEmptyData ? 500 : 600)};
  line-height: 24px;
`;

export const PreviousMonthValue = styled(TextBase)`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.neutral.base};
`;

export const Legend = styled.div`
  display: flex;
  align-items: center;
`;

export const LegendText = styled(TextBase)`
  font-size: 1.2rem;
`;

export const LegendWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.small_16};
`;

export const DotColor = styled.div`
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  margin-right: ${({ theme }) => theme.spacing.small_8};
`;

export const EmptyText = styled(TextBase)`
  position: absolute;
  left: 200px;
  bottom: 120px;
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.neutral.light};
`;
