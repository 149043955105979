import * as yup from 'yup';

export const pixelSchemaValidation = yup.object().shape({
  pixelName: yup.string().required('Este campo é obrigatório.').trim(),
  conversion_tag: yup.string().trim(),
  accessToken: yup.string().required('Este campo é obrigatório').trim(),
  pixelId: yup.string().required('Este campo é obrigatório.').trim(),
  send_credit_card_purchase: yup.boolean().required('Este campo é obrigatório.'),
  send_pix_purchase: yup.boolean().required('Este campo é obrigatório.'),
  send_boleto_purchase: yup.boolean().required('Este campo é obrigatório.'),
});

export const pixelTaboolaSchemaValidation = pixelSchemaValidation.omit([
  'send_credit_card_purchase',
  'accessToken',
]);

export const pixelFacebookSchemaValidation = pixelSchemaValidation.omit(['accessToken']);

export const pixelGoogleSchemaValidation = pixelSchemaValidation.omit(['accessToken']);

export const pixelTikTokSchemaValidation = pixelSchemaValidation.omit(['accessToken']);

export const getPixelSchemaValidation = (provider: string): any | undefined => {
  if (provider === 'FACEBOOK') return pixelFacebookSchemaValidation;

  if (provider === 'FACEBOOK_CONVERSIONS') return pixelSchemaValidation;

  if (provider === 'GOOGLE') return pixelGoogleSchemaValidation;

  if (provider === 'TABOOLA') return pixelTaboolaSchemaValidation;

  if (provider === 'TIKTOK') return pixelTikTokSchemaValidation;

  return undefined;
};
