import styled from 'styled-components/macro';

import ChipBase from '@components/common/DataDisplay/Chip';
import DropdownBase from '../Dropdown';

interface IWrapperProps {
  heightOffset: number;
}

export const Chip = styled(ChipBase)``;

export const ActionButton = styled.button`
  height: 40px;
  display: flex;
  align-items: center;
  width: 100%;
  gap: 8px;
  padding: 6px 12px;
  border: unset;
  background-color: unset;
  font-family: ${({ theme }) => theme.fonts.text};
  font-size: 1.4rem;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary.lemon_10};
  }
`;

export const DropdownContent = styled(DropdownBase.Content)`
  min-width: 200px;
`;

export const Dropdown = styled(DropdownBase)``;

export const Wrapper = styled.div<IWrapperProps>`
  position: sticky;
  top: ${({ heightOffset }) => `${heightOffset}px`};
  background-color: #fff;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 16px;
  z-index: 1;
`;

export const LogoAndChipWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const LogoIcon = styled.img`
  width: 100%;
  max-width: 101px;
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

export const IconWrapper = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: unset;
  border: unset;
  padding: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.neutral.lightest};
  }
`;
