import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  EExperienceTime,
  EMonthlyRevenue,
  EUserOnboardingStep,
  EWhereDidYouFindUs,
} from '@domain/enums/common/onboarding/EUser';
import { IUserOnboardingContext } from '@domain/interfaces/store/context/onboarding/user/IUserOnboarding';
import { IUpdateOnboardingData } from '@domain/interfaces/hooks/services/userOnboarding/IUserOnboardingService';

import { useErrorHandler } from '@store/context/common/ErrorHandlerContext';
import { useUserOnboardingService } from '@hooks/services/userOnboarding/useUserOnboardingService';
import { useAnalytics } from '@store/context/common/AnalyticsContext';
import { useAccountService } from '@hooks/services/account/useAccountService';
import { useCheckoutService } from '@hooks/services/checkout/useCheckoutService';

const UserOnboardingContext = React.createContext<IUserOnboardingContext | null>(null);

export const UserOnboardingProvider: React.FC = ({ children }) => {
  const navigate = useNavigate();
  const { analytics } = useAnalytics();
  const { handleError } = useErrorHandler();
  const { listAccounts } = useAccountService();
  const { listCheckouts } = useCheckoutService();
  const { updateOnboarding } = useUserOnboardingService();

  const [onboardingStep, setOnboardingStep] = React.useState<EUserOnboardingStep | undefined>(
    EUserOnboardingStep.FIRST_STEP,
  );
  const [experienceTimeAnswer, setExperienceTimeAnswer] = React.useState<
    EExperienceTime | undefined
  >(undefined);
  const [monthlyRevenueAnswer, setMonthlyRevenueAnswer] = React.useState<
    EMonthlyRevenue | undefined
  >(undefined);
  const [whereDidYouFindUsAnswer, setWhereDidYouFindUsAnswer] = React.useState<
    EWhereDidYouFindUs | undefined
  >(undefined);
  const [whereDidYouFindUsValue, setWhereDidYouFindUsValue] = React.useState<string>('');

  const handleOnboardingStep = React.useCallback(step => setOnboardingStep(step), []);

  const handleExperienceTimeAnswer = React.useCallback(
    answer => setExperienceTimeAnswer(answer),
    [],
  );

  const handleMonthlyRevenueAnswer = React.useCallback(
    answer => setMonthlyRevenueAnswer(answer),
    [],
  );

  const handleWhereDidYouFindUsAnswer = React.useCallback(
    answer => setWhereDidYouFindUsAnswer(answer),
    [],
  );

  const handleWhereDidYouFindUsValue = React.useCallback(
    answer => setWhereDidYouFindUsValue(answer),
    [],
  );

  const finishUserOnboarding = React.useCallback(
    async data => {
      try {
        const payload: IUpdateOnboardingData = {
          dropshipping_experience: experienceTimeAnswer || '',
          how_you_met_us: data.where_did_you_find_us,
          monthly_billing: monthlyRevenueAnswer || '',
        };

        await updateOnboarding({ payload });

        analytics.track('User Onboarding Completed');

        const { data: accountsData } = await listAccounts();
        const { data: checkoutsData } = await listCheckouts({
          account_id: accountsData.object[0].id,
        });

        if (checkoutsData.object.length === 0) {
          navigate('/onboarding/store');
        } else {
          navigate(`/${accountsData.object[0].id}/${checkoutsData.object[0].id}/dashboard/home`);
        }
      } catch (error) {
        handleError(error);
      }
    },
    [
      experienceTimeAnswer,
      monthlyRevenueAnswer,
      updateOnboarding,
      handleError,
      navigate,
      analytics,
      listAccounts,
      listCheckouts,
    ],
  );

  return (
    <UserOnboardingContext.Provider
      value={{
        handleExperienceTimeAnswer,
        handleMonthlyRevenueAnswer,
        handleOnboardingStep,
        handleWhereDidYouFindUsAnswer,
        handleWhereDidYouFindUsValue,
        finishUserOnboarding,
        experienceTimeAnswer,
        monthlyRevenueAnswer,
        onboardingStep,
        whereDidYouFindUsAnswer,
        whereDidYouFindUsValue,
      }}
    >
      {children}
    </UserOnboardingContext.Provider>
  );
};

export const useUserOnboarding = (): IUserOnboardingContext => {
  const context = React.useContext(UserOnboardingContext);

  if (!context) {
    throw new Error('useUserOnboarding must be used within provider');
  }

  return context;
};
