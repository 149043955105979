import styled from 'styled-components';

import TextBase from '@components/common/DataDisplay/Text';

export const Wrapper = styled.button`
  width: 100%;
  max-width: 99px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: ${({ theme }) => theme.borderRadius.small};
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  background: #fff;
  padding: unset;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    filter: brightness(0.9);
  }

  &:active {
    filter: brightness(0.8);
  }
`;

export const FilterText = styled(TextBase)`
  font-weight: 500;
`;
