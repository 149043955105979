import styled, { css } from 'styled-components/macro';

export interface ITextProps {
  isFeedbackError?: boolean;
}

export const TextBase = styled.span<ITextProps>`
  font-family: ${({ theme }) => theme.fonts.text};
  color: ${({ theme }) => theme.colors.neutral.darker};
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 150%;

  strong {
    font-weight: 700;
  }

  ${({ isFeedbackError, theme }) =>
    isFeedbackError &&
    css`
      font-size: 1.4rem;
      display: block;
      margin-top: 4px;
      color: ${theme.colors.negative.base};
    `}
`;
