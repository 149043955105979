import TextBase from '@components/common/DataDisplay/Text';
import styled from 'styled-components/macro';
import ChipBase from '@components/common/DataDisplay/Chip';

export const Wrapper = styled.div`
  width: 621px;
  height: 318px;
  background-color: ${({ theme }) => theme.colors.neutral.white};
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  padding: ${({ theme }) => theme.spacing.small_24};
`;

export const ChartWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 75%;

  tspan {
    font-family: ${({ theme }) => theme.fonts.typography};
    font-size: 1.2rem;
    fill: ${({ theme }) => theme.colors.neutral.base};
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin-bottom: 12px;
`;

export const Title = styled(TextBase)`
  font-size: 1.6rem;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.neutral.dark};
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.small_8};
`;
export const PercentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.small_8};
`;

export const Chip = styled(ChipBase)``;

export const PercentTag = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 20px;
  padding: 4px 8px;
  font-family: ${({ theme }) => theme.fonts.typography};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  font-size: 1.2rem;
  gap: 4px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.positive.darker};
  background-color: ${({ theme }) => theme.colors.positive.lightest};
`;

export const PercentValue = styled(TextBase)`
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.neutral.darker};
  font-weight: 600;
`;

export const EmptyPercentValue = styled(TextBase)`
  margin-left: 25px;
  font-size: 2.3rem;
  color: ${({ theme }) => theme.colors.neutral.light};
`;

export const EmptyText = styled(TextBase)`
  position: absolute;
  left: 200px;
  bottom: 100px;
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.neutral.light};
`;
