import styled from 'styled-components/macro';

import TableBase from '@components/common/utils/Table';
import ChipBase from '@components/common/DataDisplay/Chip';
import LinkBase from '@components/common/Navigation/Link';
import TextBase from '@components/common/DataDisplay/Text';
import CustomTooltipBase from '@components/common/utils/CustomTooltip';

export const Table = styled(TableBase)``;

export const Actions = styled.div`
  width: 24px;
  cursor: pointer;
`;

export const Chip = styled(ChipBase)`
  h1 {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

export const ProductPrice = styled(TextBase)`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.neutral.dark};
  max-width: 180px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ProductName = styled(TextBase)`
  max-width: 180px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Product = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InstallmentAmount = styled(TextBase)`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.neutral.dark};
`;

export const TransactionAmount = styled(TextBase)``;

export const PaymentMethodData = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CardIssuerWrapper = styled.img``;

export const PaymentMethod = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const CustomerEmail = styled(TextBase)`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.neutral.dark};
  max-width: 180px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const CustomerName = styled(TextBase)`
  max-width: 180px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Customer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DateText = styled(TextBase)`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.neutral.dark};
`;

export const OrderIdText = styled(LinkBase)`
  font-size: 1.4rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.primary.dark_green_40};
`;

export const OrderIdAndDateWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Tooltip = styled(CustomTooltipBase)``;
export const TooltipTrigger = styled(CustomTooltipBase.Trigger)``;

export const TooltipContent = styled(CustomTooltipBase.Content)`
  display: flex;
  flex-direction: column;
`;

export const RefusedText = styled(TextBase)`
  color: ${({ theme }) => theme.colors.neutral.gray_white};
  font-size: 1.2rem;
`;

export const DescriptionText = styled(RefusedText)``;
