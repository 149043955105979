import React from 'react';
import FacebookForm from './Facebook';
import FacebookConversionForm from './FacebookConversion';
import TaboolaForm from './Taboola';
import TikTokForm from './TikTok';
import GoogleForm from './Google';

type FormsProps = {
  provider: string;
};

const Forms: React.FC<FormsProps> = ({ provider }: FormsProps) => {
  switch (provider) {
    case 'FACEBOOK':
      return <FacebookForm />;

    case 'FACEBOOK_CONVERSIONS':
      return <FacebookConversionForm />;

    case 'GOOGLE':
      return <GoogleForm />;

    case 'TABOOLA':
      return <TaboolaForm />;

    case 'TIKTOK':
      return <TikTokForm />;

    default:
      return <></>;
  }
};

export default Forms;
