import React from 'react';
import useSWR from 'swr';
import { useParams } from 'react-router-dom';

import { ISwr } from '@domain/interfaces/common/swr/ISwr';
import { IWebhook } from '@domain/interfaces/common/webhook/IWebhook';
import { IListWebhooksResponse } from '@domain/interfaces/hooks/services/webhook/IWebhookService';
import { IListWebhooksProvider } from '@domain/interfaces/store/common/webhook/IListWebhooksProvider';

import { DASHBOARD_API_URL } from '@constants/services/apiUrl';
import { dashboardInstance } from '@services/common/dashboardInstance';

import { useToast } from '@store/context/common/ToastContext';
import { useErrorHandler } from '@store/context/common/ErrorHandlerContext';
import { useWebhookService } from '@hooks/services/webhook/useWebhookService';
import { useAnalytics } from '@store/context/common/AnalyticsContext';

const ListWebhooksContext = React.createContext<IListWebhooksProvider | null>(null);

export const ListWebhooksProvider: React.FC = ({ children }) => {
  const { accountId } = useParams();
  const { toast } = useToast();
  const { deleteWebhook } = useWebhookService();
  const { handleError } = useErrorHandler();
  const { analytics } = useAnalytics();

  const [isOpenModal, setIsOpenModal] = React.useState<boolean>(false);
  const [webhooks, setWebhooks] = React.useState<Array<IWebhook>>([]);
  const [isDeletingWebhook, setIsDeletingWebhook] = React.useState<boolean>(false);

  dashboardInstance.defaults.headers.common['x-zouti-account'] = accountId;

  const WEBHOOKS_URL = `${DASHBOARD_API_URL}/accounts/${accountId}/webhooks`;

  const { data, isLoading, isValidating, mutate, error } = useSWR<ISwr<IListWebhooksResponse>>(
    WEBHOOKS_URL,
    dashboardInstance,
  );

  const deleteSelectedWebhook = React.useCallback(
    async webhookId => {
      setIsDeletingWebhook(true);

      try {
        await deleteWebhook({ accountId, webhookId });

        analytics.track('Webhook Deleted');

        setIsDeletingWebhook(false);
        toast.success('Webhook deletado com sucesso!');

        await mutate();
      } catch (apiError: any) {
        toast.error('Erro ao deletar webhook!');
        setIsDeletingWebhook(false);
        handleError(apiError);
      }
    },
    [accountId, mutate, deleteWebhook, handleError, toast, analytics],
  );

  React.useEffect(() => {
    if (error) {
      handleError(error);
    }

    if (data?.data?.object?.items) {
      setWebhooks(data.data.object?.items);
    }
  }, [data, handleError, error]);

  const isLoadingWebhooks = isLoading || isValidating;
  const isWebhooksError = Boolean(error);
  const webhooksLength = data?.data?.object?.items?.length || 0;

  return (
    <ListWebhooksContext.Provider
      value={{
        webhooks,
        isLoadingWebhooks,
        isWebhooksError,
        mutate,
        deleteSelectedWebhook,
        isDeletingWebhook,
        webhooksLength,
        isOpenModal,
        setIsOpenModal,
      }}
    >
      {children}
    </ListWebhooksContext.Provider>
  );
};

export const useListWebhooks = (): IListWebhooksProvider => {
  const context = React.useContext(ListWebhooksContext);

  if (!context) {
    throw new Error('useListWebhooksContext must be used within provider');
  }

  return context;
};
