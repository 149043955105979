import React from 'react';

import { useGetAccountStep } from '@store/common/home/GetAccountStepContext';
import { useAnalytics } from '@store/context/common/AnalyticsContext';

import { useNavigate, useParams } from 'react-router-dom';
import { useUserAccount } from '@store/context/common/UserAccount';
import { getPathByScopePermission } from '@utils/getPathByScopePermission';
import FirstStep from '../Steps/FirstStep';
import SecondStep from '../Steps/SecondStep';

import * as S from './styles';

const Body: React.FC = () => {
  const { isFirstStepDone } = useGetAccountStep();
  const { analytics } = useAnalytics();
  const navigate = useNavigate();
  const { accountId, checkoutId } = useParams();
  const { currentUserAccount } = useUserAccount();

  const scopes = React.useMemo(
    () => currentUserAccount?.scopes || [],
    [currentUserAccount?.scopes],
  );

  React.useEffect(() => {
    analytics.track('Home Page Viewed');
  }, [analytics]);

  if (scopes?.length > 0) {
    const scope =
      scopes?.find(item => !item.startsWith('account') && !item.startsWith('user')) || '';

    const pathInvitedUser = getPathByScopePermission(scope);

    if (pathInvitedUser !== 'admin') {
      navigate(`/${accountId}/${checkoutId}/dashboard/${pathInvitedUser}`);
    }
  }

  return <S.Wrapper>{isFirstStepDone ? <SecondStep /> : <FirstStep />}</S.Wrapper>;
};

export default Body;
