import styled from 'styled-components/macro';

import LinkBase from '@components/common/Navigation/Link';
import TextBase from '@components/common/DataDisplay/Text';
import { ArrowSquareOut } from 'phosphor-react';

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: 16px;
`;

export const Text = styled(TextBase)`
  font-size: 1.6rem;
  font-weight: 500;
  text-align: justify;
`;

export const AccessLinkText = styled(TextBase)`
  color: ${({ theme }) => theme.colors.neutral.black};
  font-size: 1.6rem;
  font-weight: 500;
`;

export const ArticleLink = styled(LinkBase)`
  display: flex;
  align-items: center;
  align-self: end;
  gap: 8px;
  border-radius: 4px;
  padding: 10px 16px;
  margin-top: 24px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  text-decoration: none !important;

  & > span {
    color: ${({ theme }) => theme.colors.neutral.darker};
  }
`;

export const WrapperAccessLink = styled(LinkBase)`
  display: flex;
  align-items: center;
  align-self: end;
  background-color: ${({ theme }) => theme.colors.primary.green_lemon};
  gap: 8px;
  border-radius: 4px;
  padding: 10px 16px;
  margin-top: 24px;
  text-decoration: none !important;
`;

export const ArrowSquareOutIcon = styled(ArrowSquareOut).attrs(props => ({
  size: 20,
  weight: 'bold',
  color: props.theme.colors.neutral.darker,
}))``;
