import React from 'react';
import { FieldValues } from 'react-hook-form';

import { EButtonVariant } from '@domain/enums/components/common/EButton';

import { EMAIL_TYPE } from '@constants/common/transactionalEmail';

import { useTransactionalEmail } from '@store/common/TransactionalEmail/TransactionalEmailContext';

import SkeletonLoading from './SkeletonLoading';
import TableRow from './TableRow';

import * as S from '../../styles';

const Emails: React.FC = () => {
  const {
    isLoadingTransactionEmailActives,
    handleSubmit,
    activeRegister,
    isActiveSendEmail,
    setIsActiveSendEmail,
    updateActiveStatus,
    setValue,
    nameRegister,
    errors,
    updateSenderName,
    isUpdatingSenderName,
  } = useTransactionalEmail();

  if (isLoadingTransactionEmailActives) {
    return <SkeletonLoading />;
  }

  const onSaveSenderName = async (formData: FieldValues): Promise<void> => {
    await updateSenderName(formData);
  };

  const onToggleActive = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    await updateActiveStatus(event.target.checked);
  };

  return (
    <S.Wrapper>
      <S.Form onSubmit={handleSubmit(onSaveSenderName)}>
        <S.WrapperSwitch>
          <S.Switch
            {...activeRegister}
            id="active"
            checked={isActiveSendEmail}
            onChange={event => {
              setIsActiveSendEmail(state => !state);
              onToggleActive(event);
              setValue('active', event.target.checked);
            }}
          />
          <S.LabelSwitch>Ativar envio de e-mails</S.LabelSwitch>
        </S.WrapperSwitch>

        <S.SettingsWrapper>
          <>
            <S.WrapperInputs>
              <S.InputGroup>
                <S.Label>Nome do remetente</S.Label>
                <S.Input {...nameRegister} placeholder="Ex: Suporte Loja" type="text" />
                {errors.name && (
                  <S.MessageError isFeedbackError>{errors.name.message}</S.MessageError>
                )}
              </S.InputGroup>
            </S.WrapperInputs>

            <S.VerifyEmailButton
              variant={EButtonVariant.SECONDARY}
              type="submit"
              isLoading={isUpdatingSenderName}
            >
              <S.ButtonTitle>Salvar</S.ButtonTitle>
            </S.VerifyEmailButton>
          </>
        </S.SettingsWrapper>
      </S.Form>

      <S.Table>
        <S.Table.Body>
          {EMAIL_TYPE.map(item => (
            <TableRow emailTypes={item} key={item.id} />
          ))}
        </S.Table.Body>
      </S.Table>
    </S.Wrapper>
  );
};

export default Emails;
