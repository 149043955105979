export enum ETypographySize {
  h1_main_title = '32px',
  h2_secondary_title = '24px',
  h3_tertiary_title = '24px',
  h4_subtitle_bold = '18px',
  h5_subtitle = '18px',
  h6_button_medium = '16px',
  paragraph = '16px',
  medium_text = '14px',
  caption = '12px',
  overline = '10px',
}

export enum ETypographyWeight {
  regular = '400',
  medium = '600',
  bold = '700',
}
