import React from 'react';

import Content from '@components/pages/Dashboard/CheckoutCustomization/GetCheckoutCustomization';
import { ListCheckoutCustomizationProvider } from '@store/common/checkoutCustomization/ListCheckoutCustomizationsContext';

const GetCheckoutCustomization: React.FC = () => {
  return (
    <ListCheckoutCustomizationProvider>
      <Content />
    </ListCheckoutCustomizationProvider>
  );
};

export default GetCheckoutCustomization;
