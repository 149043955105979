import styled from 'styled-components/macro';

import Text from '@components/common/DataDisplay/Text';

export const TextBase = styled(Text)`
  font-size: 1.2rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.neutral.base};
`;

export const BankAccountBankName = styled(Text)`
  font-size: 1.4rem;
  font-weight: 500;
`;

export const BankAccountOptionContent = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;
  align-items: flex-start;
`;

export const BankAccountBankAccountGroup = styled.div``;

export const AccountNumber = styled(TextBase)``;
export const RoutingNumber = styled(TextBase)``;

export const BankAccountOption = styled.button`
  align-items: center;
  background: unset;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 16px;
  transition: all 0.3s;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.lightest};

  &:first-child {
    border-top: none;
  }

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: ${({ theme }) => `${theme.colors.primary.lemon_20}`};
    ${AccountNumber} {
      color: ${({ theme }) => theme.colors.primary.dark_green_80};
    }
    ${RoutingNumber} {
      color: ${({ theme }) => theme.colors.primary.dark_green_80};
    }
    ${BankAccountBankName} {
      color: ${({ theme }) => theme.colors.primary.dark_green_80};
    }
  }
`;
