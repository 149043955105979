import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useAnalytics } from '@store/context/common/AnalyticsContext';

import ListSkeletonLoading from '@components/common/utils/ListSkeletonLoading';
import { useGetBankAccount } from '@store/common/BankAccount/GetBankAccountContext';

import Body from './Body';
import Header from './Header';
import EmptyData from './EmptyData';

import * as S from './styles';

const BankAccount: React.FC = () => {
  const navigate = useNavigate();
  const { analytics } = useAnalytics();
  const { accountId, checkoutId } = useParams();
  const { isLoadingBankAccount, bankAccounts } = useGetBankAccount();

  React.useEffect(() => {
    analytics.track('Bank Account Page Viewed');
  }, [analytics]);

  const isEmpty = bankAccounts.length === 0;

  const onEmptyDataButtonClick = (): void => {
    navigate(`/${accountId}/${checkoutId}/dashboard/bank-accounts/new`);
  };

  if (isLoadingBankAccount) {
    return <ListSkeletonLoading />;
  }

  if (isEmpty) {
    return <EmptyData onClick={onEmptyDataButtonClick} />;
  }

  return (
    <S.Wrapper>
      <Header />
      <Body />
    </S.Wrapper>
  );
};
export default BankAccount;
