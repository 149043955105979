import styled from 'styled-components/macro';

import TableBase from '@components/common/utils/Table';

export const Table = styled(TableBase)`
  margin-top: 48px;

  th {
    padding: 16px 8px !important;
  }

  tr > td:nth-child(4) {
    color: ${({ theme }) => theme.colors.primary.dark_green_60};
  }
`;
