import React from 'react';

import { SignInProvider } from '@store/context/public/signIn/SignInContext';

import SignInWrapper from '@components/pages/Public/SignIn/Wrapper';

const SignIn: React.FC = () => {
  return (
    <SignInProvider>
      <SignInWrapper />
    </SignInProvider>
  );
};

export default SignIn;
