import React from 'react';
import TextArea from '@components/common/Inputs/TextArea';

import * as S from './styles';

interface ITextAreaEditorProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  textAreaRef: React.RefObject<HTMLTextAreaElement>;
  insertedTextRef: any;
  label?: string;
  countCharacters?: number;
  maxCount?: number;
  errorMessage?: string;
}

const TextAreaEditor: React.FC<ITextAreaEditorProps> = ({
  textAreaRef,
  insertedTextRef,
  countCharacters,
  maxCount,
  label,
  errorMessage,
  ...rest
}) => {
  const handleInsertText = (text: string): void => {
    const { current } = textAreaRef;

    if (current) {
      const { selectionStart, selectionEnd, value } = current;
      const textToInsert = text;
      const newValue = `${value.slice(0, selectionStart)}${textToInsert}${value.slice(
        selectionEnd,
      )}`;
      current.value = newValue;
      current.setSelectionRange(
        selectionStart + textToInsert.length,
        selectionStart + textToInsert.length,
      );
      current.focus();
      insertedTextRef.current = textToInsert;
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>): void => {
    if (e.key === 'Backspace' && textAreaRef.current) {
      const { current } = textAreaRef;
      const { selectionStart, value } = current;
      const textToCheck = insertedTextRef.current;

      if (
        textToCheck &&
        value.slice(selectionStart - textToCheck.length, selectionStart) === textToCheck
      ) {
        const startPos = selectionStart - textToCheck.length;
        const newValue = `${value.slice(0, startPos)}${value.slice(selectionStart)}`;
        current.value = newValue;
        current.setSelectionRange(startPos, startPos);
        current.focus();
        insertedTextRef.current = '';
      }
    }
  };

  return (
    <S.Wrapper>
      <S.WrapperTextArea>
        <S.Label>{label}</S.Label>
        <S.WrapperButton>
          <S.Button onClick={() => handleInsertText('{{nome}}')}>
            <S.TextButton>{`{{nome}}`}</S.TextButton>
          </S.Button>
          <S.Button onClick={() => handleInsertText('{{link}}')}>
            <S.TextButton>{`{{link}}`}</S.TextButton>
          </S.Button>
        </S.WrapperButton>
      </S.WrapperTextArea>
      <TextArea {...rest} ref={textAreaRef} onKeyDown={handleKeyDown} />
      <S.WrapperCountAndTextError errorMessage={errorMessage}>
        {errorMessage && <S.MessageError>{errorMessage}</S.MessageError>}
        <S.CountCharacters>
          {countCharacters}/{maxCount}
        </S.CountCharacters>
      </S.WrapperCountAndTextError>
    </S.Wrapper>
  );
};

export default TextAreaEditor;
