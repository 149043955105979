import React from 'react';
import { Trash } from 'phosphor-react';

import shopifyFullImage from '@assets/images/common/dashboard/integrations/shopify-full.svg';
import shopifyIcon from '@assets/images/common/dashboard/integrations/shopify.svg';
import tutorialImage from '@assets/images/components/tutorial/play-image.svg';

import { EButtonVariant } from '@domain/enums/components/common/EButton';

import { useShopify } from '@store/common/shopify/ShopifyContext';
import { useAnalytics } from '@store/context/common/AnalyticsContext';

import Tutorial from '@components/common/utils/Tutorial';
import ReinstallCheckoutModal from '../ReinstallCheckoutModal';
import SaveForm from '../SaveForm';
import ErrorModal from '../ErrorModal';

import * as S from './styles';
import DeleteCredentialModal from '../DeleteCredentialModel';

const Body: React.FC = () => {
  const { analytics } = useAnalytics();
  const {
    domainRegister,
    accessTokenRegister,
    // apiKeyRegister,
    secretApiKeyRegister,
    handleSubmit,
    onSubmit,
    isSubmittingForm,
    handleSkipCart,
    skipCart,
    checkoutInFrame,
    handleAddCheckoutInFrame,
    shopifyCredential,
    errors,
    watch,
    onActivateShopifyCredential,
    onInactivateShopifyCredential,
    onReinstallShopifyCredential,
    isLoadingDeleteCredential,
    isDeleteCredentialModalOpen,
    setIsDeleteCredentialModalOpen,
    isReinstallingCredential,
    isEnableShopifyApp,
  } = useShopify();

  const [domainValue, accessTokenValue, secretApiKeyValue] = watch([
    'domain',
    'access_token',
    'secret_api_key',
  ]);

  const [isReinstallCheckoutModalOpen, setIsReinstallCheckoutModalOpen] =
    React.useState<boolean>(false);
  const [showSaveForm, setShowSaveForm] = React.useState<boolean>(false);
  const [isActivated, setIsActivated] = React.useState<boolean>(false);

  const handleReinstallCheckoutModal = React.useCallback(() => {
    onReinstallShopifyCredential();
  }, [onReinstallShopifyCredential]);

  const handleStatusChange = React.useCallback(
    event => {
      setIsActivated(event.target.checked);

      if (event.target.checked) {
        onActivateShopifyCredential();
      } else {
        onInactivateShopifyCredential();
      }
    },
    [onActivateShopifyCredential, onInactivateShopifyCredential],
  );

  React.useEffect(() => {
    if (
      (domainValue || accessTokenValue || secretApiKeyValue || skipCart || checkoutInFrame) &&
      !shopifyCredential
    ) {
      setShowSaveForm(true);
      return;
    }

    if (!shopifyCredential) return;

    if (
      shopifyCredential.domain !== domainValue ||
      shopifyCredential.access_token !== accessTokenValue ||
      // shopifyCredential.apiKey !== apiKeyValue ||
      shopifyCredential.secret_api_key !== secretApiKeyValue ||
      shopifyCredential.skip_cart !== skipCart ||
      shopifyCredential.checkout_in_frame !== checkoutInFrame
    ) {
      setShowSaveForm(true);
    }
  }, [
    accessTokenValue,
    domainValue,
    secretApiKeyValue,
    shopifyCredential,
    skipCart,
    checkoutInFrame,
  ]);

  React.useEffect(() => {
    if (shopifyCredential) {
      setIsActivated(Boolean(!shopifyCredential.inactivated_at));
    }
  }, [shopifyCredential]);

  React.useEffect(() => {
    analytics.track('Shopify Credential Page Viewed');
  }, [analytics]);

  return (
    <S.Wrapper>
      <ErrorModal />
      <S.AdditionalInformationWrapper>
        <S.AdditionalInformationContent>
          <S.AdditionalInformationHeader backgroundColor="#004c3f">
            <S.FullImg src={shopifyFullImage} alt="Shopify Zouti" />

            <S.IconWrapper>
              <S.Icon src={shopifyIcon} alt="Integration image" />
            </S.IconWrapper>
          </S.AdditionalInformationHeader>

          <S.AdditionalInformationBody>
            <S.TitleAndSwitchWrapper>
              <S.Title>Shopify</S.Title>
            </S.TitleAndSwitchWrapper>

            <S.Description>
              Integre sua loja ao Shopify para sincronizar produtos e pedidos automaticamente, e
              ofereça uma experiência de compra coesa e fluida.
            </S.Description>
          </S.AdditionalInformationBody>
        </S.AdditionalInformationContent>

        {/* <Tutorial
          image_url={tutorialImage}
          title="Integração Shopify: Sincronização de pedidos"
          video_url="https://www.youtube.com/embed/f5_wn8mexmM?si=Ud_rqwSSYa_nU4az"
        /> */}
      </S.AdditionalInformationWrapper>

      <S.Form onSubmit={handleSubmit(onSubmit)}>
        <S.CredentialsWrapper>
          <S.SwitchGroup>
            {isEnableShopifyApp && (
              <S.SwitchWrapper>
                <S.Switch
                  id="install-checkout"
                  name="install-checkout"
                  checked={isActivated}
                  onChange={handleStatusChange}
                />

                <S.SwitchText>Ativar checkout</S.SwitchText>
              </S.SwitchWrapper>
            )}

            <S.SwitchWrapper>
              <S.SkipCartSwitch
                id="skip-cart"
                name="skip-cart"
                checked={skipCart}
                onChange={handleSkipCart}
              />

              <S.SwitchText>Pular carrinho de compras</S.SwitchText>
            </S.SwitchWrapper>

            {/* <S.SwitchWrapper>
              <S.SkipCartSwitch
                id="checkout-in-frame"
                name="checkout-in-frame"
                checked={checkoutInFrame}
                onChange={handleAddCheckoutInFrame}
              />

              <S.SwitchText>Utilizar Checkout em loja</S.SwitchText>
            </S.SwitchWrapper> */}
          </S.SwitchGroup>

          <S.Divider />

          <S.InputGroup>
            <S.Label>URL da loja</S.Label>

            <S.Input
              {...domainRegister}
              isError={Boolean(errors.domain)}
              type="text"
              placeholder="seudominio.myshopify.com"
            />

            {errors.domain && <S.MessageError>{errors.domain.message}</S.MessageError>}
          </S.InputGroup>

          <S.InputGroup>
            <S.Label>Token de acesso da API admin</S.Label>

            <S.Input
              {...accessTokenRegister}
              isError={Boolean(errors.access_token)}
              type="text"
              placeholder="Informe o token de acesso"
            />

            {errors.access_token && <S.MessageError>{errors.access_token.message}</S.MessageError>}
          </S.InputGroup>

          {/* <S.InputGroup>
            <S.Label>Chave de API</S.Label>

            <S.Input
              {...apiKeyRegister}
              type="text"
              isError={Boolean(errors.api_key)}
              placeholder="Informe a chave de api"
            />

            {errors.api_key && <S.MessageError>{errors.api_key.message}</S.MessageError>}
          </S.InputGroup> */}

          <S.InputGroup>
            <S.Label>Chave secreta de API</S.Label>

            <S.Input
              {...secretApiKeyRegister}
              type="text"
              isError={Boolean(errors.secret_api_key)}
              placeholder="Informe a chave secreta de api"
            />

            {errors.secret_api_key && (
              <S.MessageError>{errors.secret_api_key.message}</S.MessageError>
            )}
          </S.InputGroup>

          {shopifyCredential && (
            <S.ButtonsWrapper>
              <S.ReinstallCheckoutButton
                type="button"
                variant={EButtonVariant.SECONDARY}
                onClick={handleReinstallCheckoutModal}
                isLoading={isReinstallingCredential}
              >
                Reinstalar checkout
              </S.ReinstallCheckoutButton>

              <S.DeleteCredentialButton
                type="button"
                onClick={() => setIsDeleteCredentialModalOpen(prev => !prev)}
              >
                <Trash weight="bold" size={16} />
                Excluir credenciais
              </S.DeleteCredentialButton>
            </S.ButtonsWrapper>
          )}
        </S.CredentialsWrapper>
        <DeleteCredentialModal
          contentText="Está prestes a excluir permanentemente suas credenciais da Shopify. Essa ação não pode ser desfeita. Tem certeza de que deseja continuar?"
          isSuccessButtonLoading={isLoadingDeleteCredential}
          isOpen={isDeleteCredentialModalOpen}
          toggle={() => setIsDeleteCredentialModalOpen(prev => !prev)}
        />

        {shopifyCredential && (
          <ReinstallCheckoutModal
            contentText="Você está prestes a reinstalar o checkout da sua loja Shopify. Tem certeza de que deseja continuar?"
            isSuccessButtonLoading={isSubmittingForm}
            isOpen={isReinstallCheckoutModalOpen}
            toggle={handleReinstallCheckoutModal}
          />
        )}

        {showSaveForm && <SaveForm />}
      </S.Form>
    </S.Wrapper>
  );
};

export default Body;
