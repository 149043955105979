import React from 'react';

import Content from '@components/pages/Dashboard/Coupon/UpdateCoupon';

import { UpdatePromocodeProvider } from '@store/common/promocode/UpdatePromocodeContext';

const UpdateCoupon: React.FC = () => {
  return (
    <UpdatePromocodeProvider>
      <Content />
    </UpdatePromocodeProvider>
  );
};

export default UpdateCoupon;
