import React from 'react';

import { useGetStatementTransactions } from '@store/common/statementTransactions/GetStatementTransactionsContext';

import { EBalanceType } from '@domain/interfaces/store/common/statementTransactions/IGetStatementTransactionsProvider';

import EmptyTable from '@components/common/utils/EmptyTable';

import TableRow from './TableRow';

import * as S from './styles';

const Body: React.FC = () => {
  const { statementTransactions, isEmpty, selectedBalance } = useGetStatementTransactions();

  return (
    <S.Wrapper>
      <S.Table>
        <S.Table.Header>
          <S.Table.Row>
            <S.Table.Head>ID da transação</S.Table.Head>
            {selectedBalance === EBalanceType.Pending && <S.Table.Head>Data</S.Table.Head>}
            <S.Table.Head>Disponível em</S.Table.Head>
            <S.Table.Head>Tipo da transação</S.Table.Head>
            <S.Table.Head>Valor</S.Table.Head>
          </S.Table.Row>
        </S.Table.Header>

        <S.TableBody>
          {isEmpty && <EmptyTable colSpan={4} text="Nenhum extrato encontrado." />}

          {statementTransactions?.map(item => (
            <TableRow statementTransaction={item} key={item.id} selectedBalance={selectedBalance} />
          ))}
        </S.TableBody>
      </S.Table>
    </S.Wrapper>
  );
};

export default Body;
