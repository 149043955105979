import styled from 'styled-components/macro';

import ButtonBase from '@components/common/Inputs/Button';
import HeadingBase from '@components/common/DataDisplay/Heading';
import LabelBase from '@components/common/DataDisplay/Label';
import SwitchBase from '@components/common/Inputs/Switch';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  background-color: ${({ theme }) => theme.colors.neutral.white};
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  border-radius: ${({ theme }) => theme.borderRadius.small};
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;

  & > button {
    align-self: flex-end;
  }
`;

export const Title = styled(HeadingBase)`
  margin-bottom: 8px;
`;

export const Switch = styled(SwitchBase)``;

export const WrapperSwitch = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Label = styled(LabelBase)`
  margin: 0;
  font-size: 1.6rem;
`;

export const SubmitButton = styled(ButtonBase)``;
