import React from 'react';
import useSWR from 'swr';

import { ISwr } from '@domain/interfaces/common/swr/ISwr';
import { IUser } from '@domain/interfaces/common/user/IUser';
import { IUserSessionContext } from '@domain/interfaces/store/context/common/IUserSessionContext';

import { AUTH_API_URL } from '@constants/services/authUrl';

import { authInstance } from '@services/common/authInstance';

import { useErrorHandler } from '@store/context/common/ErrorHandlerContext';
import { useConfig } from './ConfigContext';

const UserSessionContext = React.createContext<IUserSessionContext | null>(null);

export const UserSessionProvider: React.FC = ({ children }) => {
  const USER_URL = `${AUTH_API_URL}/users/me`;

  const { handleUser } = useConfig();
  const { handleError } = useErrorHandler();

  const [user, setUser] = React.useState<IUser | undefined>(undefined);

  const { data, isLoading, isValidating, error } = useSWR<ISwr<any>>(USER_URL, authInstance);

  React.useEffect(() => {
    if (error) {
      handleError(error);
    }

    if (data?.data?.object) {
      setUser(data.data.object);
    }
  }, [data, error, handleError]);

  React.useEffect(() => {
    if (user) {
      handleUser(user);
    }
  }, [user, handleUser]);

  const isLoadingUserSessionProvider = isLoading;
  const isValidatingUserSessionProvider = isValidating;
  const isUserSessionProviderError = Boolean(error);

  return (
    <UserSessionContext.Provider
      value={{
        isLoadingUserSessionProvider,
        isUserSessionProviderError,
        isValidatingUserSessionProvider,
        user,
      }}
    >
      {children}
    </UserSessionContext.Provider>
  );
};

export const useUserSession = (): IUserSessionContext => {
  const context = React.useContext(UserSessionContext);

  if (!context) {
    throw new Error('useUserSession must be sued within UserSessionProvider');
  }

  return context;
};
