import React, { useRef } from 'react';
import { FieldValues, useForm } from 'react-hook-form';

import { IUpdateRecoverySMSData } from '@domain/interfaces/hooks/services/recoveryTemplate/IRecoveryTemplateService';
import { useNewRecoverySMS } from '@store/common/recovery/NewRecoverySMSContext';
import { yupResolver } from '@hookform/resolvers/yup';
import { recoverySchemaSMS } from '@helpers/validators/pages/dashboard/recovery/recoverySchemaSMS';
import SkeletonLoading from '@components/pages/Dashboard/recovery/Tabs/SkeletonLoading';
import { EHeadingSize } from '@domain/enums/components/common/EHeading';
import TextAreaEditor from '../../TextAreaEditor';

import * as S from './styles';

const SMS: React.FC = () => {
  const {
    updateCurrentRecoverySMS,
    isCreatingRecoverySMS,
    isLoadingRecoveryTemplates,
    activateCurrentRecoveryTemplate,
    inactivateCurrentRecoveryTemplate,
    recoveryTemplates,
    isActive,
  } = useNewRecoverySMS();

  const [countStringLength, setCountStringLength] = React.useState<number>(0);

  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const insertedTextRef = useRef<string>('');

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(recoverySchemaSMS),
  });

  const messageRegister = register('message', { value: textAreaRef.current?.value });

  const onSubmit = async (formData: FieldValues): Promise<void> => {
    const activeValue = isActive ? undefined : new Date().toISOString();
    const data: IUpdateRecoverySMSData = {
      inactivated_at: activeValue,
      message: textAreaRef.current?.value || formData.message,
    };
    await updateCurrentRecoverySMS({ templateId: recoveryTemplates?.id || '', payload: data });
  };

  const isInactivated = Object.prototype.hasOwnProperty.call(recoveryTemplates, 'inactivated_at');

  const onSwitchChange = React.useCallback(async () => {
    if (isInactivated) {
      await activateCurrentRecoveryTemplate(recoveryTemplates?.id || '');
    } else {
      await inactivateCurrentRecoveryTemplate(recoveryTemplates?.id || '');
    }
  }, [
    isInactivated,
    activateCurrentRecoveryTemplate,
    inactivateCurrentRecoveryTemplate,
    recoveryTemplates,
  ]);

  React.useEffect(() => {
    setValue('message', recoveryTemplates?.message);
    setCountStringLength(recoveryTemplates?.message?.length || 0);
  }, [setValue, recoveryTemplates]);

  return (
    <>
      {isLoadingRecoveryTemplates ? (
        <SkeletonLoading />
      ) : (
        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <S.Wrapper>
            <S.Title size={EHeadingSize.H4}>Envio de SMS</S.Title>
            <S.WrapperSwitch>
              <S.Label>Ativar</S.Label>
              <S.Switch
                id="active-message"
                {...register('active', { value: isActive })}
                checked={isActive}
                onChange={onSwitchChange}
              />
            </S.WrapperSwitch>
            <TextAreaEditor
              {...messageRegister}
              maxLength={110}
              maxCount={110}
              errorMessage={errors.message && errors?.message?.message?.toString()}
              countCharacters={countStringLength}
              label="Mensagem"
              onChange={event => {
                setValue('message', event.target.value);
                setCountStringLength(event.target.value.length);
              }}
              defaultValue={recoveryTemplates?.message}
              insertedTextRef={insertedTextRef}
              textAreaRef={textAreaRef}
            />
          </S.Wrapper>
          <S.SubmitButton isLoading={isCreatingRecoverySMS} type="submit">
            Salvar
          </S.SubmitButton>
        </S.Form>
      )}
    </>
  );
};

export default SMS;
