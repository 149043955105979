import React from 'react';

import { EHeadAlign } from '@domain/enums/components/common/ETable';

import { useListProducts } from '@store/common/product/ListProductsContext';

import Row from './Row';

import * as S from './styles';

const VariantsSideModalContent: React.FC = () => {
  const { selectedProduct } = useListProducts();

  return (
    <S.Table isSecondary>
      <S.Table.Header>
        <S.Table.Row>
          <S.Table.Head>Variante</S.Table.Head>
          <S.Table.Head>SKU</S.Table.Head>
          <S.Table.Head>Preço</S.Table.Head>
          <S.Table.Head align={EHeadAlign.RIGHT}>Checkout</S.Table.Head>
        </S.Table.Row>
      </S.Table.Header>

      <S.Table.Body>
        {selectedProduct.variants.map(variant => {
          return <Row variant={variant} key={variant.id} />;
        })}
      </S.Table.Body>
    </S.Table>
  );
};

export default VariantsSideModalContent;
