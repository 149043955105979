import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/common/EHeading';
import { EWhereDidYouFindUs } from '@domain/enums/common/onboarding/EUser';

import { useUserOnboarding } from '@store/context/onboarding/user/UserOnboardingContext';

import { whereDidYouFindUsSchema } from '@helpers/validators/pages/onboarding/user/whereDidYouFindUs';

import Text from '@components/common/DataDisplay/Text';

import * as S from './styles';

const WhereDidYouFindUs: React.FC = () => {
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(whereDidYouFindUsSchema),
  });
  const { finishUserOnboarding } = useUserOnboarding();

  const whereDidYouFindUsRegister = register('where_did_you_find_us');
  const whereDidYouFindUsValueRegister = register('where_did_you_find_us_value');

  const onSubmit = React.useCallback(
    data => {
      finishUserOnboarding(data);
    },
    [finishUserOnboarding],
  );

  watch(['where_did_you_find_us']);

  const [whereDidYouFindUsValue] = getValues(['where_did_you_find_us']);

  const isSubmitButtonDisabled = Boolean(!whereDidYouFindUsValue);
  const showRecommendationInput = whereDidYouFindUsValue === EWhereDidYouFindUs.RECOMMENDATION;
  const showEventInput = whereDidYouFindUsValue === EWhereDidYouFindUs.EVENT;
  const showPlatformInput = whereDidYouFindUsValue === EWhereDidYouFindUs.PLATFORM_OR_CHECKOUT;

  return (
    <S.Wrapper onSubmit={handleSubmit(onSubmit)}>
      <S.QuestionText size={EHeadingSize.H6} fontWeight={EHeadingWeight.MEDIUM}>
        Aonde nos conheceu?
      </S.QuestionText>

      <S.OptionsWrapper>
        <S.Option htmlFor={EWhereDidYouFindUs.ADS}>
          <S.RadioOption
            {...whereDidYouFindUsRegister}
            id={EWhereDidYouFindUs.ADS}
            value={EWhereDidYouFindUs.ADS}
          />
          <S.Text>Anúncios</S.Text>
        </S.Option>

        <S.Option htmlFor={EWhereDidYouFindUs.GOOGLE}>
          <S.RadioOption
            {...whereDidYouFindUsRegister}
            id={EWhereDidYouFindUs.GOOGLE}
            value={EWhereDidYouFindUs.GOOGLE}
          />
          <S.Text>Google</S.Text>
        </S.Option>

        <S.Option htmlFor={EWhereDidYouFindUs.RECOMMENDATION}>
          <S.RadioOption
            {...whereDidYouFindUsRegister}
            id={EWhereDidYouFindUs.RECOMMENDATION}
            value={EWhereDidYouFindUs.RECOMMENDATION}
          />
          <S.Text>Recomendação</S.Text>
        </S.Option>

        <S.Option htmlFor={EWhereDidYouFindUs.EVENT}>
          <S.RadioOption
            {...whereDidYouFindUsRegister}
            id={EWhereDidYouFindUs.EVENT}
            value={EWhereDidYouFindUs.EVENT}
          />
          <S.Text>Evento</S.Text>
        </S.Option>

        <S.Option htmlFor={EWhereDidYouFindUs.PLATFORM_OR_CHECKOUT}>
          <S.RadioOption
            {...whereDidYouFindUsRegister}
            id={EWhereDidYouFindUs.PLATFORM_OR_CHECKOUT}
            value={EWhereDidYouFindUs.PLATFORM_OR_CHECKOUT}
          />
          <S.Text>Plataforma ou Checkout</S.Text>
        </S.Option>
      </S.OptionsWrapper>

      {showRecommendationInput && (
        <S.InputGroup>
          <S.Label>Quem te recomendou?</S.Label>
          <S.Input
            {...whereDidYouFindUsValueRegister}
            type="text"
            placeholder="Informe quem te recomendou"
          />
          {errors?.where_did_you_find_us_value && (
            <Text isFeedbackError>{errors?.where_did_you_find_us_value?.message}</Text>
          )}
        </S.InputGroup>
      )}

      {showEventInput && (
        <S.InputGroup>
          <S.Label>Qual evento?</S.Label>
          <S.Input {...whereDidYouFindUsValueRegister} type="text" placeholder="Informe o evento" />
          {errors?.where_did_you_find_us_value && (
            <Text isFeedbackError>{errors?.where_did_you_find_us_value?.message}</Text>
          )}
        </S.InputGroup>
      )}

      {showPlatformInput && (
        <S.InputGroup>
          <S.Label>Qual plataforma?</S.Label>
          <S.Input
            {...whereDidYouFindUsValueRegister}
            type="text"
            placeholder="Informe a plataforma"
          />
          {errors?.where_did_you_find_us_value && (
            <Text isFeedbackError>{errors?.where_did_you_find_us_value?.message}</Text>
          )}
        </S.InputGroup>
      )}

      <S.SubmitButton type="submit" disabled={isSubmitButtonDisabled}>
        Continuar
      </S.SubmitButton>
    </S.Wrapper>
  );
};

export default WhereDidYouFindUs;
