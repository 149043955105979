import React from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { EHeadAlign } from '@domain/enums/components/common/ETable';

import { PROVIDERS } from '@constants/common/pixelProviders';

import { PencilSimple as EditIcon, Trash as TrashIcon } from 'phosphor-react';
import { useTheme } from 'styled-components';

import { IPixel } from '@domain/interfaces/common/pixel/IPixel';
import { useGetPixel } from '@store/common/pixel/GetPixelContext';

import * as S from './styles';

interface ITableRowProps {
  pixel: IPixel;
}

const TableRow: React.FC<ITableRowProps> = ({ pixel }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { accountId, checkoutId } = useParams();
  const { deleteCurrentPixel, inactivateCurrentPixel, activateCurrentPixel } = useGetPixel();

  const isInactivated = Object.prototype.hasOwnProperty.call(pixel, 'inactivated_at');

  const [isVisibleActions, setIsVisibleActions] = React.useState<boolean>(false);

  const onRowMouseEnter = React.useCallback(() => {
    setIsVisibleActions(true);
  }, []);

  const onRowMouseLeave = React.useCallback(() => {
    setIsVisibleActions(false);
  }, []);

  const onCheckboxChange = React.useCallback(async () => {
    if (isInactivated) {
      await activateCurrentPixel(pixel.id);
    } else {
      await inactivateCurrentPixel(pixel.id);
    }
  }, [isInactivated, activateCurrentPixel, inactivateCurrentPixel, pixel]);

  const onEditPixelButtonClick = (pixelId: string): void => {
    navigate(`/${accountId}/${checkoutId}/dashboard/marketing/pixels/${pixelId}`);
  };

  const onDeletePixelButtonClick = async (pixelId: string): Promise<void> => {
    await deleteCurrentPixel({ pixelId });
  };

  const pixelId = pixel.id;
  const providerId = pixel.provider_id;
  const pixelName = pixel.name;
  const providerName = pixel.provider;
  const providerIcon = PROVIDERS.find(provider => provider.name === providerName)?.icon;
  const providerText = PROVIDERS.find(provider => provider.name === providerName)?.text;

  return (
    <S.Table.Row onMouseEnter={onRowMouseEnter} onMouseLeave={onRowMouseLeave} borderBottom>
      <S.Table.Data>
        <S.Switch
          id={providerId}
          name={providerId}
          checked={!isInactivated}
          onChange={onCheckboxChange}
        />
      </S.Table.Data>

      <S.Table.Data>
        <S.TextAndSubTextWrapper>
          <S.Text>{pixelName}</S.Text>
          <S.SubText>ID: {providerId}</S.SubText>
        </S.TextAndSubTextWrapper>
      </S.Table.Data>

      <S.Table.Data>
        <S.IconTextWrapper>
          <S.Icon src={providerIcon} />
          <S.Text>{providerText}</S.Text>
        </S.IconTextWrapper>
      </S.Table.Data>

      <S.Table.Data align={EHeadAlign.RIGHT}>
        {isVisibleActions ? (
          <S.ButtonActionsWrapper>
            <S.Table.Action onClick={() => onEditPixelButtonClick(pixelId)}>
              <EditIcon size={18} color={theme.colors.neutral.darker} weight="bold" />
            </S.Table.Action>
            <S.Table.Action onClick={() => onDeletePixelButtonClick(pixelId)}>
              <TrashIcon size={18} color={theme.colors.neutral.darker} weight="bold" />
            </S.Table.Action>
          </S.ButtonActionsWrapper>
        ) : (
          <S.ButtonActionsWrapper />
        )}
      </S.Table.Data>
    </S.Table.Row>
  );
};

export default TableRow;
