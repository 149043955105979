import React from 'react';

import Content from '@components/pages/Dashboard/Domains/ConfigDomain';
import { ConfigDomainProvider } from '@store/common/domain/ConfigDomainContext';

const ConfigDomains: React.FC = () => {
  return (
    <ConfigDomainProvider>
      <Content />
    </ConfigDomainProvider>
  );
};

export default ConfigDomains;
