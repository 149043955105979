import styled from 'styled-components/macro';

import TableBase from '@components/common/utils/Table';
import SwitchBase from '@components/common/Inputs/Switch';
import TextBase from '@components/common/DataDisplay/Text';

export const Table = styled(TableBase)``;

export const Switch = styled(SwitchBase)``;

export const Text = styled(TextBase)`
  color: ${({ theme }) => theme.colors.neutral.darkest};
  font-size: 1.4rem;
`;

export const ButtonActionsWrapper = styled.div`
  min-width: 79.5px;
  justify-content: right;
  display: flex;
  gap: 8px;
`;
