import React, { ReactNode } from 'react';

import * as S from './styles';

interface IPaymentMethod {
  icon: ReactNode;
  name: string;
  fee: string;
  textFee: string;
  description: string;
}

interface IPaymentMethodProps {
  rates: IPaymentMethod[];
  title: string;
}

export const RateCard: React.FC<IPaymentMethodProps> = ({ rates, title }) => {
  return (
    <S.PaymentMethodContainer>
      <S.CardTitle>{title}</S.CardTitle>
      <S.DividerTitle />
      {rates?.map((method, index) => (
        <React.Fragment key={index.toFixed()}>
          <S.PaymentMethodItem>
            <S.PaymentMethodIconWrapper>
              <S.Icons>{method?.icon}</S.Icons>
              <S.TextIcon>{method.name}</S.TextIcon>
            </S.PaymentMethodIconWrapper>
            <S.PaymentMethodDetails>
              <S.FeeGroup>
                <S.FeeAmount>{method.fee}</S.FeeAmount>
                <S.FeeDescription>/por {method.textFee}</S.FeeDescription>
              </S.FeeGroup>
              <S.Description>{method.description}</S.Description>
            </S.PaymentMethodDetails>
          </S.PaymentMethodItem>
          {index < rates?.length - 1 && <S.Divider />}
        </React.Fragment>
      ))}
    </S.PaymentMethodContainer>
  );
};
