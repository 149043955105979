import React from 'react';

import PageHeader from '@components/common/utils/PageHeader';
import * as S from './styles';

const Header: React.FC = () => {
  return (
    <S.Wrapper>
      <PageHeader
        goBackButton
        title="Editar cupom"
        description="Defina as configurações do cupom que você vai disponibilizar para seus clientes."
      />
    </S.Wrapper>
  );
};

export default Header;
