import React, { ElementType } from 'react';

import { useTransactionalEmail } from '@store/common/TransactionalEmail/TransactionalEmailContext';

import Badge from './Badge';

import * as S from './styles';

interface ITableRowProps {
  emailTypes: {
    id: string;
    type: string;
    icon: ElementType;
  };
}

const TableRow: React.FC<ITableRowProps> = ({ emailTypes }) => {
  const { getEmailType, transactionActiveEmails, changeTypeStatus, setTransactionActiveEmails } =
    useTransactionalEmail();

  const typeEmailId = emailTypes.id;
  const typeEmailType = emailTypes.type;
  const typeEmailIcon = emailTypes.icon;
  const emailType = getEmailType(typeEmailType);
  const isActivated = transactionActiveEmails
    ? (transactionActiveEmails as any)[typeEmailType]
    : false;

  const onChangeTypeStatus = React.useCallback(
    async event => {
      if (!emailTypes) return;

      const newStatus = event.target.checked;

      try {
        setTransactionActiveEmails(previousState => ({
          ...previousState,
          [emailTypes.type]: newStatus ? new Date().toISOString() : undefined,
        }));

        event.target.value = newStatus;

        await changeTypeStatus(emailTypes?.type);
      } catch (error) {
        event.target.checked = !newStatus;
      }
    },
    [changeTypeStatus, emailTypes, setTransactionActiveEmails],
  );

  return (
    <S.Table.Row borderBottom>
      <S.Table.Data>
        <S.Switch
          name="active-email"
          id={typeEmailId}
          onChange={onChangeTypeStatus}
          checked={isActivated !== undefined}
        />
      </S.Table.Data>
      <S.Table.Data />
      <S.Table.Data>
        <Badge emailType={emailType} icon={typeEmailIcon} />
      </S.Table.Data>
    </S.Table.Row>
  );
};

export default TableRow;
