import { AxiosResponse } from 'axios';

import {
  ICreateWebhookProps,
  ICreateWebhookResponse,
  IDeleteWebhookProps,
  IGetWebhookProps,
  IGetWebhookResponse,
  IListWebhooksProps,
  IListWebhooksResponse,
  IUpdateWebhookProps,
  IUpdateWebhookResponse,
  IWebhookService,
} from '@domain/interfaces/hooks/services/webhook/IWebhookService';

import { dashboardInstance } from '@services/common/dashboardInstance';

export const useWebhookService = (): IWebhookService => {
  const listWebhooks = async ({
    accountId,
  }: IListWebhooksProps): Promise<AxiosResponse<IListWebhooksResponse>> => {
    return dashboardInstance.get(`webhooks`, {
      headers: {
        'x-zouti-account': accountId,
      },
    });
  };

  const getWebhook = async ({
    accountId,
    webhookId,
  }: IGetWebhookProps): Promise<AxiosResponse<IGetWebhookResponse>> => {
    return dashboardInstance.get(`webhooks/${webhookId}`, {
      headers: {
        'x-zouti-account': accountId,
      },
    });
  };

  const createWebhook = async ({
    accountId,
    payload,
  }: ICreateWebhookProps): Promise<AxiosResponse<ICreateWebhookResponse>> => {
    return dashboardInstance.post(
      `/accounts/${accountId}/webhooks`,
      { ...payload },
      {
        headers: {
          'x-zouti-account': accountId,
        },
      },
    );
  };

  const updateWebhook = async ({
    accountId,
    webhookId,
    payload,
  }: IUpdateWebhookProps): Promise<AxiosResponse<IUpdateWebhookResponse>> => {
    return dashboardInstance.put(
      `/accounts/${accountId}/webhooks/${webhookId}`,
      { ...payload },
      {
        headers: {
          'x-zouti-account': accountId,
        },
      },
    );
  };

  const deleteWebhook = async ({
    accountId,
    webhookId,
  }: IDeleteWebhookProps): Promise<AxiosResponse<void>> => {
    return dashboardInstance.delete(`accounts/${accountId}/webhooks/${webhookId}`, {
      headers: {
        'x-zouti-account': accountId,
      },
    });
  };

  return { createWebhook, deleteWebhook, getWebhook, listWebhooks, updateWebhook };
};
