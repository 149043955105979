import styled from 'styled-components/macro';

import ButtonBase from '@components/common/Inputs/Button';
import HeadingBase from '@components/common/DataDisplay/Heading';
import InputGroupBase from '@components/common/Inputs/InputGroup';
import LabelBase from '@components/common/DataDisplay/Label';
import TextBase from '@components/common/DataDisplay/Text';
import TextFieldBase from '@components/common/Inputs/TextField';

import SelectBase from '@components/common/Inputs/Select';
import OptionBase from '@components/common/Inputs/Select/Option';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.small_24};

  & > button {
    align-self: flex-end;
  }
`;

export const WrapperSection = styled.div`
  gap: ${({ theme }) => theme.spacing.small_24};
  padding: ${({ theme }) => theme.spacing.small_24};
  background-color: ${({ theme }) => theme.colors.neutral.white};
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  border-radius: ${({ theme }) => theme.borderRadius.small};
`;

export const LanguageAndCurrencyWrapper = styled(WrapperSection)`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const SelectsWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

export const Title = styled(HeadingBase)`
  font-size: 2rem;
`;

export const Label = styled(LabelBase)`
  margin: 0;
  font-size: 1.4rem;
`;

export const Span = styled(TextBase)`
  color: ${({ theme }) => theme.colors.neutral.base};
`;

export const Input = styled(TextFieldBase)``;

export const InputGroup = styled(InputGroupBase)`
  gap: ${({ theme }) => theme.spacing.small_8};
`;

export const MessageError = styled(TextBase)`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.negative.base};
`;

export const Select = styled(SelectBase)`
  min-width: 100%;
`;
export const Option = styled(OptionBase)`
  &:disabled {
    color: ${({ theme }) => theme.colors.neutral.base};
  }
`;

export const SubmitButton = styled(ButtonBase)``;
