import * as yup from 'yup';

export const orderBumpSchema = yup.object().shape({
  name: yup.string().required('Este campo é obrigatório.').min(2, 'Informe duas ou mais letras.'),
  price: yup.string().required('Este campo é obrigatório'),
  promotional_price: yup.string().required('Este campo é obrigatório'),
  cta_text: yup.string().required('Este campo é obrigatório'),
  title: yup.string().required('Este campo é obrigatório'),
  description: yup.string().required('Este campo é obrigatório'),
});
