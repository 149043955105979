import React from 'react';

import Content from '@components/pages/Dashboard/Pixel/GetPixel';
import { GetPixelProvider } from '@store/common/pixel/GetPixelContext';

const Pixel: React.FC = () => {
  return (
    <GetPixelProvider>
      <Content />
    </GetPixelProvider>
  );
};

export default Pixel;
