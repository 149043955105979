import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { EButtonVariant } from '@domain/enums/components/common/EButton';
import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/common/EHeading';
import { IUpdateCheckoutCustomizationData } from '@domain/interfaces/hooks/services/checkoutCustomization/ICheckoutCustomizationService';

import InputColorCheckout from '@components/common/Inputs/InputColorCheckout';
import { useEditCheckout } from '@store/common/checkoutCustomization/EditCheckoutCustomizationContext';
import footerCheckoutCustomizationSchema from '@helpers/validators/pages/dashboard/checkoutCustomizations/footerCheckoutCustomizationSchema';

import Tooltip from '@components/pages/Dashboard/CheckoutCustomization/Tooltip';
import * as S from './styles';

const Footer: React.FC = () => {
  const navigate = useNavigate();
  const { accountId, checkoutId } = useParams();
  const {
    checkoutCustomization,
    updateCurrentCheckout,
    setCheckoutCustomization,
    isUpdateCheckout,
    selectedThemeCheckout,
  } = useEditCheckout();
  const {
    id,
    header_customization,
    boleto_discount_type,
    credit_card_discount_type,
    pix_discount_type,
    name,
    allow_credit_card,
    allow_pix,
    allow_boleto,
    credit_card_discount_amount,
    pix_discount_amount,
    boleto_discount_amount,
    show_coupon_input,
    enable_itens_quantity_change,
    footer_customization,
    appearance_customization,
    advanced_customization,
    show_time_shipping_estimate,
  } = checkoutCustomization;

  const { register, handleSubmit, setValue, control, reset, watch } = useForm({
    resolver: yupResolver(footerCheckoutCustomizationSchema),
  });

  const onSubmit = async (formData: FieldValues): Promise<void> => {
    const data: IUpdateCheckoutCustomizationData = {
      name,
      allow_credit_card,
      allow_pix,
      allow_boleto,
      boleto_discount_type,
      credit_card_discount_type,
      pix_discount_type,
      credit_card_discount_amount,
      pix_discount_amount,
      boleto_discount_amount,
      show_coupon_input,
      enable_itens_quantity_change,
      show_time_shipping_estimate,
      header_customization: { ...header_customization },
      footer_customization: {
        footer_hex_color: formData.footer_hex_color,
        text_hex_color: formData.text_hex_color,
        show_security_seal: formData.show_security_seal,
        show_account_name: formData.show_account_name,
        show_account_address: formData.show_account_address,
        show_account_cnpj_or_cpf: formData.show_account_cnpj_or_cpf,
        show_account_email: formData.show_account_email,
        id,
      },
      appearance_customization: { ...appearance_customization },
      advanced_customization: { ...advanced_customization },
      checkout_theme: { theme: selectedThemeCheckout?.theme },
    };

    await updateCurrentCheckout({ checkoutCustomizationId: id, payload: data });
  };

  React.useEffect(() => {
    return () => {
      const fieldValues = watch();
      setCheckoutCustomization(prevData => {
        return {
          ...prevData,
          footer_customization: {
            footer_hex_color: fieldValues.footer_hex_color,
            text_hex_color: fieldValues.text_hex_color,
            show_security_seal: fieldValues.show_security_seal,
            show_account_name: fieldValues.show_account_name,
            show_account_address: fieldValues.show_account_address,
            show_account_cnpj_or_cpf: fieldValues.show_account_cnpj_or_cpf,
            show_account_email: fieldValues.show_account_email,
            id,
          },
        };
      });
    };
  }, [setCheckoutCustomization, watch, id]);

  useEffect(() => {
    setValue('footer_hex_color', footer_customization?.footer_hex_color);
    setValue('text_hex_color', footer_customization?.text_hex_color);
    setValue('show_security_seal', footer_customization?.show_security_seal);
    setValue('show_account_name', footer_customization?.show_account_name);
    setValue('show_account_address', footer_customization?.show_account_address);
    setValue('show_account_cnpj_or_cpf', footer_customization?.show_account_cnpj_or_cpf);
    setValue('show_account_email', footer_customization?.show_account_email);
  }, [setValue, register, footer_customization]);

  const onCancelButtonClick = (): void => {
    reset();
    navigate(`/${accountId}/${checkoutId}/dashboard/checkout/customizations`);
  };

  return (
    <S.Form onSubmit={handleSubmit(onSubmit)}>
      <S.Wrapper>
        <S.ColorSection>
          <S.Title size={EHeadingSize.H4} fontWeight={EHeadingWeight.BOLD}>
            Cores
          </S.Title>
          <S.InputColorWrapper>
            <InputColorCheckout
              onChange={event => {
                setValue('footer_hex_color', event.target.value);
              }}
              value={footer_customization?.footer_hex_color}
              placeholder="Digite o código da cor"
            >
              {/* <Tooltip url="https://images.freeimages.com/images/small-previews/aed/three-bees-on-sunflower-1337029.jpg"> */}
              Cor do rodapé
              {/* </Tooltip> */}
            </InputColorCheckout>
            <InputColorCheckout
              onChange={event => {
                setValue('text_hex_color', event.target.value);
              }}
              value={footer_customization?.text_hex_color}
              placeholder="Digite o código da cor"
            >
              {/* <Tooltip url="https://images.freeimages.com/images/small-previews/aed/three-bees-on-sunflower-1337029.jpg"> */}
              Cor do texto
              {/* </Tooltip> */}
            </InputColorCheckout>
          </S.InputColorWrapper>
        </S.ColorSection>
        <S.Divider />
        <S.RoleSection>
          <S.Title size={EHeadingSize.H4} fontWeight={EHeadingWeight.BOLD}>
            Regras
          </S.Title>
          <S.CheckboxGroup>
            <Controller
              control={control}
              name="show_security_seal"
              render={({ field }) => (
                <S.Checkbox
                  id="show_security_seal"
                  checked={footer_customization?.show_security_seal}
                  value={field?.value?.toString()}
                  onChange={() => field.onChange(!field.value)}
                />
              )}
            />
            <S.CheckboxLabel htmlFor="show_security_seal">
              Mostrar selo de segurança
            </S.CheckboxLabel>
          </S.CheckboxGroup>
          <S.CheckboxGroup>
            <Controller
              control={control}
              name="show_account_name"
              render={({ field }) => (
                <S.Checkbox
                  id="show_account_name"
                  checked={footer_customization?.show_account_name}
                  value={field?.value?.toString()}
                  onChange={() => field.onChange(!field.value)}
                />
              )}
            />
            <S.CheckboxLabel htmlFor="show_account_name">Mostrar nome da loja</S.CheckboxLabel>
          </S.CheckboxGroup>
          <S.CheckboxGroup>
            <Controller
              control={control}
              name="show_account_address"
              render={({ field }) => (
                <S.Checkbox
                  id="show_account_address"
                  checked={footer_customization?.show_account_address}
                  value={field?.value?.toString()}
                  onChange={() => field.onChange(!field.value)}
                />
              )}
            />
            <S.CheckboxLabel htmlFor="show_account_address">
              Mostrar endereço da loja
            </S.CheckboxLabel>
          </S.CheckboxGroup>
          <S.CheckboxGroup>
            <Controller
              control={control}
              name="show_account_cnpj_or_cpf"
              render={({ field }) => (
                <S.Checkbox
                  id="show_account_cnpj_or_cpf"
                  checked={footer_customization?.show_account_cnpj_or_cpf}
                  value={field?.value?.toString()}
                  onChange={() => field.onChange(!field.value)}
                />
              )}
            />
            <S.CheckboxLabel htmlFor="show_account_cnpj_or_cpf">
              Mostrar razão social e CNPJ ou nome completo e CPF
            </S.CheckboxLabel>
          </S.CheckboxGroup>
          <S.CheckboxGroup>
            <Controller
              control={control}
              name="show_account_email"
              render={({ field }) => (
                <S.Checkbox
                  id="show_account_email"
                  checked={footer_customization?.show_account_email}
                  value={field?.value?.toString()}
                  onChange={() => field.onChange(!field.value)}
                />
              )}
            />
            <S.CheckboxLabel htmlFor="show_account_email">Mostrar e-mail da loja</S.CheckboxLabel>
          </S.CheckboxGroup>
        </S.RoleSection>
      </S.Wrapper>
      <S.ButtonsWrapper>
        <S.CancelButton
          type="button"
          onClick={onCancelButtonClick}
          variant={EButtonVariant.SECONDARY}
        >
          Cancelar
        </S.CancelButton>
        <S.SubmitButton isLoading={isUpdateCheckout} type="submit">
          Salvar
        </S.SubmitButton>
      </S.ButtonsWrapper>
    </S.Form>
  );
};

export default Footer;
