import React from 'react';

import { EHeadingSize } from '@domain/enums/common/components/EHeading';

import { useRecoveryPassword } from '@store/context/public/RecoveryPassword/RecoveryPasswordContext';

import logo from '@assets/images/common/logo.svg';
import logoIcon from '@assets/images/common/logoIcon.svg';

import Form from '../Form';
import SuccessWrapper from '../SuccessWrapper';

import * as S from './styles';

const Wrapper: React.FC = () => {
  const imgBackground = 'https://zouti-core-media.s3.amazonaws.com/dashboard/auth/recovery.png';

  const { showSuccessMessage } = useRecoveryPassword();

  return (
    <S.Container>
      <S.ImageBox image={imgBackground}>
        <S.InfoWrapper align="end">
          <S.BadgeCategory>
            <img src={logoIcon} alt="" height="14px" /> Secure FinTech Solution
          </S.BadgeCategory>
          <S.BadgeText>
            Checkout e gateway integrados em uma plataforma inovadora, transparente e humanizada.
          </S.BadgeText>
        </S.InfoWrapper>
      </S.ImageBox>

      {showSuccessMessage ? (
        <SuccessWrapper />
      ) : (
        <S.Wrapper>
          <S.Logo src={logo} alt="Zouti" />

          <S.Title size={EHeadingSize.H5}>
            <b>Não consegue acessar sua conta?</b> <br />
          </S.Title>

          <S.Text>
            Insira seu e-mail cadastrado, nós enviaremos <br /> os próximos passos.
          </S.Text>

          <S.Body>
            <Form />
          </S.Body>
        </S.Wrapper>
      )}
    </S.Container>
  );
};

export default Wrapper;
