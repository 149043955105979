import { ELanguage } from '@domain/enums/common/order/ELanguage';

export const countryFlag: Record<ELanguage, string> = {
  [ELanguage.PT_BR]: 'br',
  [ELanguage.ES_ES]: 'es',
  [ELanguage.ES_AR]: 'ar',
  [ELanguage.IT_IT]: 'it',
  [ELanguage.FR_FR]: 'fr',
  [ELanguage.DE_DE]: 'de',
  [ELanguage.EN_US]: 'us',
  [ELanguage.NB_NO]: 'no',
  [ELanguage.NL_BE]: 'be',
  [ELanguage.PT_PT]: 'pt',
  [ELanguage.RU_RU]: 'ru',
  [ELanguage.BG_BG]: 'bg',
  [ELanguage.EN_CA]: 'ca',
  [ELanguage.ES_CL]: 'cl',
  [ELanguage.ES_CO]: 'co',
  [ELanguage.ES_MX]: 'mx',
  [ELanguage.ES_PE]: 'pe',
  [ELanguage.ES_PR]: 'pr',
  [ELanguage.EN_GB]: 'gb',
  [ELanguage.NL_NL]: 'nl',
  [ELanguage.EN_NZ]: 'nz',
  [ELanguage.SV_SE]: 'se',
  [ELanguage.TR_TR]: 'tr',
  [ELanguage.ZH_CN]: 'cn',
  [ELanguage.ZH_TW]: 'tw',
  [ELanguage.JA_JP]: 'jp',
  [ELanguage.KO_KR]: 'kr',
  [ELanguage.VI_VN]: 'vn',
  [ELanguage.TH_TH]: 'th',
  [ELanguage.ID_ID]: 'id',
  [ELanguage.AR_SA]: 'sa',
  [ELanguage.AR_AE]: 'ae',
  [ELanguage.HI_IN]: 'in',
  [ELanguage.DE_CH]: 'ch',
};

export const countryName: Record<ELanguage, string> = {
  [ELanguage.PT_BR]: 'Brasil',
  [ELanguage.ES_ES]: 'Espanha',
  [ELanguage.ES_AR]: 'Argentina',
  [ELanguage.IT_IT]: 'Itália',
  [ELanguage.FR_FR]: 'França',
  [ELanguage.DE_DE]: 'Alemanha',
  [ELanguage.EN_US]: 'Estados Unidos',
  [ELanguage.NB_NO]: 'Noruega',
  [ELanguage.NL_BE]: 'Bélgica',
  [ELanguage.PT_PT]: 'Portugal',
  [ELanguage.RU_RU]: 'Rússia',
  [ELanguage.BG_BG]: 'Bulgária',
  [ELanguage.EN_CA]: 'Canadá',
  [ELanguage.ES_CL]: 'Chile',
  [ELanguage.ES_CO]: 'Colômbia',
  [ELanguage.ES_MX]: 'México',
  [ELanguage.ES_PE]: 'Peru',
  [ELanguage.ES_PR]: 'Porto Rico',
  [ELanguage.EN_GB]: 'Reino Unido',
  [ELanguage.NL_NL]: 'Holanda',
  [ELanguage.EN_NZ]: 'Nova Zelândia',
  [ELanguage.SV_SE]: 'Suécia',
  [ELanguage.TR_TR]: 'Turquia',
  [ELanguage.ZH_CN]: 'China',
  [ELanguage.ZH_TW]: 'Taiwan',
  [ELanguage.JA_JP]: 'Japão',
  [ELanguage.KO_KR]: 'Coreia do Sul',
  [ELanguage.VI_VN]: 'Vietnã',
  [ELanguage.TH_TH]: 'Tailândia',
  [ELanguage.ID_ID]: 'Indonésia',
  [ELanguage.AR_SA]: 'Arábia Saudita',
  [ELanguage.AR_AE]: 'Emirados Árabes Unidos',
  [ELanguage.HI_IN]: 'Índia',
  [ELanguage.DE_CH]: 'Suíça',
};
