import React from 'react';
import useSWR from 'swr';
import { useParams } from 'react-router-dom';

import { IGetPixelProvider } from '@domain/interfaces/store/common/pixel/IGetPixelProvider';
import { IPixel } from '@domain/interfaces/common/pixel/IPixel';
import { ISwr } from '@domain/interfaces/common/swr/ISwr';
import { IListPixelsPromiseResponse } from '@domain/interfaces/hooks/services/pixel/IPixelService';

import { DASHBOARD_API_URL } from '@constants/services/apiUrl';
import { dashboardInstance } from '@services/common/dashboardInstance';

import { useErrorHandler } from '@store/context/common/ErrorHandlerContext';
import { usePixelService } from '@hooks/services/pixel/usePixelService';
import { useToast } from '@store/context/common/ToastContext';
import { useAnalytics } from '@store/context/common/AnalyticsContext';

const GetPixelContext = React.createContext<IGetPixelProvider | null>(null);

export const GetPixelProvider: React.FC = ({ children }) => {
  const { toast } = useToast();
  const { analytics } = useAnalytics();
  const { handleError } = useErrorHandler();
  const { accountId, checkoutId } = useParams();
  const { inactivatePixel, activatePixel, deletePixel } = usePixelService();

  const [isDeletingPixel, setIsDeletingPixel] = React.useState<boolean>(false);
  const [pixels, setPixels] = React.useState<Array<IPixel>>([]);
  const [totalPixels, setTotalPixels] = React.useState<number>(0);

  dashboardInstance.defaults.headers.common['x-zouti-account'] = accountId;

  const PIXEL_URL = `${DASHBOARD_API_URL}/accounts/${accountId}/checkouts/${checkoutId}/pixels`;

  const { data, isLoading, isValidating, mutate, error } = useSWR<ISwr<IListPixelsPromiseResponse>>(
    PIXEL_URL,
    dashboardInstance,
  );

  React.useEffect(() => {
    if (error) {
      handleError(error);
    }

    if (data?.data) {
      setPixels(data.data.object);
      setTotalPixels(data.data.object.length);
    }
  }, [data, handleError, error]);

  const deleteCurrentPixel = React.useCallback(
    async pixelId => {
      setIsDeletingPixel(true);

      try {
        await deletePixel({ ...pixelId, accountId, checkoutId });

        analytics.track('Pixel Deleted');

        await mutate();
        toast.success('Pixel deletado com sucesso!');
        setIsDeletingPixel(false);
      } catch (errorDeletePixel) {
        setIsDeletingPixel(false);
        handleError(errorDeletePixel);
      }
    },
    [deletePixel, handleError, toast, mutate, accountId, checkoutId, analytics],
  );

  const inactivateCurrentPixel = React.useCallback(
    async (pixelId: string) => {
      try {
        await inactivatePixel({ pixelId, accountId, checkoutId });

        analytics.track('Pixel Updated');

        await mutate();
      } catch (errorInactivatePixel) {
        handleError(errorInactivatePixel);
      }
    },
    [inactivatePixel, handleError, mutate, accountId, checkoutId, analytics],
  );

  const activateCurrentPixel = React.useCallback(
    async (pixelId: string) => {
      try {
        await activatePixel({ pixelId, accountId, checkoutId });

        analytics.track('Pixel Updated');

        await mutate();
      } catch (errorActivatePixel) {
        handleError(errorActivatePixel);
      }
    },
    [activatePixel, handleError, mutate, accountId, checkoutId, analytics],
  );

  const isLoadingPixels = isLoading || isValidating;
  const isPixelsError = Boolean(error);
  const isEmptyPixels = !isLoadingPixels && !isPixelsError && pixels.length === 0;

  return (
    <GetPixelContext.Provider
      value={{
        pixels,
        isLoadingPixels,
        isPixelsError,
        mutate,
        totalPixels,
        isDeletingPixel,
        deleteCurrentPixel,
        isEmptyPixels,
        inactivateCurrentPixel,
        activateCurrentPixel,
      }}
    >
      {children}
    </GetPixelContext.Provider>
  );
};

export const useGetPixel = (): IGetPixelProvider => {
  const context = React.useContext(GetPixelContext);

  if (!context) {
    throw new Error('useGetPixelContext must be used within provider');
  }

  return context;
};
