import styled from 'styled-components/macro';

export const Wrapper = styled.div``;

export const LinearBackground = styled.rect`
  fill: ${({ theme }) => theme.colors.neutral.lightest};
`;

export const LinearProgress = styled.rect`
  fill: ${({ theme }) => theme.colors.positive.base};
`;
