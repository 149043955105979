import React from 'react';

import Header from './Header';
import Body from './Body';
import Footer from './Footer';
import Row from './Row';
import Head from './Head';
import Data from './Data';
import Action from './ActionButton';

import * as S from './styles';

interface ITableProps {
  virtualizedTable?: boolean;
  id?: string;
  containerRef?: React.RefObject<HTMLDivElement> | null;
  isSecondary?: boolean;
}

interface ITable {
  Header: typeof Header;
  Body: typeof Body;
  Footer: typeof Footer;
  Head: typeof Head;
  Row: typeof Row;
  Data: typeof Data;
  Action: typeof Action;
}

const Table: ITable & React.FC<ITableProps> = ({
  children,
  containerRef,
  isSecondary,
  ...rest
}) => {
  return (
    <S.Container ref={containerRef} {...rest}>
      <S.Table isSecondary={isSecondary}>{children}</S.Table>
    </S.Container>
  );
};

Table.Header = Header;
Table.Body = Body;
Table.Row = Row;
Table.Head = Head;
Table.Data = Data;
Table.Footer = Footer;
Table.Action = Action;

export default Table;
