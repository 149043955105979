import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PlusCircle } from 'phosphor-react';

import { useGetSocialProof } from '@store/common/socialProof/GetSocialProofContext';

import PageHeader from '@components/common/utils/PageHeader';

import * as S from './styles';

const Header: React.FC = () => {
  const navigate = useNavigate();
  const { accountId, checkoutId } = useParams();
  const { totalSocialProofs, totalActivatedSocialProofs } = useGetSocialProof();

  const onNewSocialProofButtonClick = (): void => {
    navigate(`/${accountId}/${checkoutId}/dashboard/checkout/social-proof/new`);
  };
  return (
    <S.Wrapper>
      <PageHeader
        title="Provas sociais"
        quantityText={`${totalActivatedSocialProofs} de ${totalSocialProofs} provas sociais ativas`}
      />

      <S.NewSocialProofButton onClick={onNewSocialProofButtonClick}>
        <PlusCircle size={20} weight="bold" />
        Adicionar prova social
      </S.NewSocialProofButton>
    </S.Wrapper>
  );
};

export default Header;
