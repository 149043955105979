import styled, { css } from 'styled-components/macro';

interface IWrapperProps {
  isCurrentStep: boolean;
  isStepFinished: boolean;
}

export const Wrapper = styled.div<IWrapperProps>`
  width: 26px;
  height: 26px;
  font-family: ${({ theme }) => theme.fonts.text};
  font-size: 1.4rem;
  font-weight: 500;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid ${({ theme }) => theme.colors.neutral.dark};
  color: ${({ theme }) => theme.colors.neutral.dark};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  ${({ isCurrentStep, theme }) =>
    isCurrentStep &&
    css`
      border-color: ${theme.colors.primary.green_lemon};
      color: ${theme.colors.primary.green_lemon};
      background-color: ${theme.colors.primary.lemon_20};
    `}

  ${({ isStepFinished, theme }) =>
    isStepFinished &&
    css`
      border-color: ${theme.colors.positive.base};
      background-color: ${theme.colors.positive.base};
    `}
`;
