import styled from 'styled-components/macro';

import TextBase from '@components/common/DataDisplay/Text';

export const ProviderIcon = styled.img`
  width: 24px;
  height: 24px;
`;

export const ProviderName = styled(TextBase)`
  font-size: 1.4rem;
`;

export const ProviderOption = styled.button`
  align-items: center;
  background: unset;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 16px;
  transition: all 0.3s;
  width: 100%;

  &:hover {
    background-color: ${({ theme }) => `${theme.colors.primary.lemon_20}`};
    & > span {
      color: ${({ theme }) => `${theme.colors.primary.dark_green_40}`};
    }
  }
`;
