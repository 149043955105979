import * as yup from 'yup';

export const storeNameSchema = {
  store_name_value: yup
    .string()
    .required('É necessário informar o nome do negócio.')
    .min(2, 'Informe um nome válido'),
};

export const storeGlobalSchema = {
  store_language_value: yup.string().required('É necessário informar o idioma do negócio.'),
  store_currency_value: yup.string().required('É necessário informar a moeda do negócio.'),
};
