import React from 'react';
import * as DropDownMenu from '@radix-ui/react-dropdown-menu';

import * as S from './styles';

const Trigger: React.ForwardRefRenderFunction<
  HTMLButtonElement,
  DropDownMenu.DropdownMenuTriggerProps & React.RefAttributes<HTMLButtonElement>
> = ({ children, ...rest }, ref) => {
  return (
    <S.Wrapper ref={ref} {...rest}>
      {children}
    </S.Wrapper>
  );
};

export default React.forwardRef(Trigger);
