import React from 'react';
import { Info } from 'phosphor-react';
import { useGetDomain } from '@store/common/domain/GetDomainContext';
import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schemaDomainUrl } from '@helpers/validators/pages/dashboard/domain/domainUrl';

import * as S from './styles';

const DomainAddModal: React.FC = () => {
  const {
    setIsOpenAddDomainModal,
    isOpenAddDomainModal,
    createNewDomain,
    isLoadingCreatingDomain,
  } = useGetDomain();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaDomainUrl),
  });

  const onSubmit = async (formData: FieldValues): Promise<void> => {
    await createNewDomain({ payload: formData.domain_url });
  };

  return (
    <S.Modal
      title="Adicionar domínio"
      toggle={() => setIsOpenAddDomainModal(state => !state)}
      isOpen={isOpenAddDomainModal}
    >
      <S.Form onSubmit={handleSubmit(onSubmit)}>
        <S.InputGroup>
          <S.Label>Domínio</S.Label>
          <S.Input
            isError={!!errors.domain_url}
            {...register('domain_url')}
            placeholder="Digite um domínio"
            type="text"
          />
          {errors.domain_url && <S.MessageError>{errors.domain_url.message}</S.MessageError>}
          <S.Info>
            <Info size={16} />
            <S.MessageInfo>
              Informe apenas o nome do domínio, excluindo &quot;https&quot;, &quot;http&quot;,
              &quot;www&quot; etc.
            </S.MessageInfo>
          </S.Info>
        </S.InputGroup>
        <S.ModalAction>
          <S.CancelButton type="button" onClick={() => setIsOpenAddDomainModal(state => !state)}>
            Cancelar
          </S.CancelButton>
          <S.SaveButton isLoading={isLoadingCreatingDomain} type="submit">
            Salvar
          </S.SaveButton>
        </S.ModalAction>
      </S.Form>
    </S.Modal>
  );
};

export default DomainAddModal;
