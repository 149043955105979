import styled from 'styled-components/macro';
import { ETransactionalEmail } from '@domain/enums/common/transactionalEmail/ETransactionalEmail';

interface IBadgeProps {
  emailType: ETransactionalEmail;
}

export const Badge = styled.span<IBadgeProps>`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 4px 8px;
  border-radius: 4px;

  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;

  ${({ theme, emailType }) => {
    if (emailType === ETransactionalEmail.WAITING_PAYMENT) {
      return `
        color: ${theme.colors.secondary.darkest};
        background: ${theme.colors.secondary.lightest};
      `;
    }

    if (emailType === ETransactionalEmail.CANCELED) {
      return `
      color: ${theme.colors.neutral.darkest};
      background: ${theme.colors.neutral.lightest};
    `;
    }

    if (emailType === ETransactionalEmail.IN_TRANSPORT) {
      return `
      color: ${theme.colors.info.darkest};
      background: ${theme.colors.info.white_blue};
    `;
    }

    if (emailType === ETransactionalEmail.ABANDONED_CART) {
      return `
      color: ${theme.colors.primary.dark};
      background: ${theme.colors.primary.lightest};
    `;
    }

    if (emailType === ETransactionalEmail.PAYMENT_APPROVED) {
      return `
      color: ${theme.colors.positive.darker};
      background: ${theme.colors.positive.lightest};
    `;
    }

    if (emailType === ETransactionalEmail.PAYMENT_REFUSED) {
      return `
      color: ${theme.colors.negative.darker};
      background: ${theme.colors.negative.lightest};
    `;
    }

    return '';
  }}
`;
