import React from 'react';
import { useForm } from 'react-hook-form';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/common/EHeading';
import { EMonthlyRevenue, EUserOnboardingStep } from '@domain/enums/common/onboarding/EUser';

import { useUserOnboarding } from '@store/context/onboarding/user/UserOnboardingContext';

import * as S from './styles';

const MonthlyRevenue: React.FC = () => {
  const { register, handleSubmit, watch, getValues } = useForm();
  const { handleMonthlyRevenueAnswer, handleOnboardingStep } = useUserOnboarding();

  const monthlyRevenueRegister = register('monthly_revenue');

  const onSubmit = React.useCallback(
    data => {
      handleMonthlyRevenueAnswer(data.monthly_revenue);
      handleOnboardingStep(EUserOnboardingStep.THIRD_STEP);
    },
    [handleMonthlyRevenueAnswer, handleOnboardingStep],
  );

  watch(['monthly_revenue']);

  const [monthlyRevenueValue] = getValues(['monthly_revenue']);

  const isSubmitButtonDisabled = Boolean(!monthlyRevenueValue);

  return (
    <S.Wrapper onSubmit={handleSubmit(onSubmit)}>
      <S.QuestionText size={EHeadingSize.H6} fontWeight={EHeadingWeight.MEDIUM}>
        Qual o faturamento mensal da sua empresa?
      </S.QuestionText>

      <S.OptionsWrapper>
        <S.Option htmlFor={EMonthlyRevenue.LESS_THAN_5_THOUSAND}>
          <S.RadioOption
            {...monthlyRevenueRegister}
            id={EMonthlyRevenue.LESS_THAN_5_THOUSAND}
            value={EMonthlyRevenue.LESS_THAN_5_THOUSAND}
          />
          <S.Text>Menos de 5 mil reais</S.Text>
        </S.Option>

        <S.Option htmlFor={EMonthlyRevenue.BETWEEN_5_AND_10_THOUSAND}>
          <S.RadioOption
            {...monthlyRevenueRegister}
            id={EMonthlyRevenue.BETWEEN_5_AND_10_THOUSAND}
            value={EMonthlyRevenue.BETWEEN_5_AND_10_THOUSAND}
          />
          <S.Text>Entre 5 mil e 10 mil reais</S.Text>
        </S.Option>

        <S.Option htmlFor={EMonthlyRevenue.BETWEEN_10_AND_20_THOUSAND}>
          <S.RadioOption
            {...monthlyRevenueRegister}
            id={EMonthlyRevenue.BETWEEN_10_AND_20_THOUSAND}
            value={EMonthlyRevenue.BETWEEN_10_AND_20_THOUSAND}
          />
          <S.Text>Entre 10 mil e 20 mil reais</S.Text>
        </S.Option>

        <S.Option htmlFor={EMonthlyRevenue.BETWEEN_20_AND_50_THOUSAND}>
          <S.RadioOption
            {...monthlyRevenueRegister}
            id={EMonthlyRevenue.BETWEEN_20_AND_50_THOUSAND}
            value={EMonthlyRevenue.BETWEEN_20_AND_50_THOUSAND}
          />
          <S.Text>Entre 20 mil e 50 mil reais</S.Text>
        </S.Option>

        <S.Option htmlFor={EMonthlyRevenue.BETWEEN_50_AND_100_THOUSAND}>
          <S.RadioOption
            {...monthlyRevenueRegister}
            id={EMonthlyRevenue.BETWEEN_50_AND_100_THOUSAND}
            value={EMonthlyRevenue.BETWEEN_50_AND_100_THOUSAND}
          />
          <S.Text>Entre 50 mil e 100 mil reais</S.Text>
        </S.Option>

        <S.Option htmlFor={EMonthlyRevenue.BETWEEN_100_AND_200_THOUSAND}>
          <S.RadioOption
            {...monthlyRevenueRegister}
            id={EMonthlyRevenue.BETWEEN_100_AND_200_THOUSAND}
            value={EMonthlyRevenue.BETWEEN_100_AND_200_THOUSAND}
          />
          <S.Text>Entre 100 mil e 200 mil reais</S.Text>
        </S.Option>

        <S.Option htmlFor={EMonthlyRevenue.BETWEEN_200_AND_500_THOUSAND}>
          <S.RadioOption
            {...monthlyRevenueRegister}
            id={EMonthlyRevenue.BETWEEN_200_AND_500_THOUSAND}
            value={EMonthlyRevenue.BETWEEN_200_AND_500_THOUSAND}
          />
          <S.Text>Entre 200 mil e 500 mil reais</S.Text>
        </S.Option>

        <S.Option htmlFor={EMonthlyRevenue.BETWEEN_500_AND_1_MILLION}>
          <S.RadioOption
            {...monthlyRevenueRegister}
            id={EMonthlyRevenue.BETWEEN_500_AND_1_MILLION}
            value={EMonthlyRevenue.BETWEEN_500_AND_1_MILLION}
          />
          <S.Text>Entre 500 mil e 1 milhão de reais</S.Text>
        </S.Option>

        <S.Option htmlFor={EMonthlyRevenue.MORE_THAN_1_MILLION}>
          <S.RadioOption
            {...monthlyRevenueRegister}
            id={EMonthlyRevenue.MORE_THAN_1_MILLION}
            value={EMonthlyRevenue.MORE_THAN_1_MILLION}
          />
          <S.Text>Acima de 1 milhão de reais</S.Text>
        </S.Option>
      </S.OptionsWrapper>

      <S.SubmitButton type="submit" disabled={isSubmitButtonDisabled}>
        Continuar
      </S.SubmitButton>
    </S.Wrapper>
  );
};

export default MonthlyRevenue;
