export enum EBankAccountType {
  SAVINGS = 'SAVINGS',
  CHECKING = 'CHECKING',
}

export interface IBankAccount {
  id: string;
  account_id: string;
  type: EBankAccountType;
  holder_name: string;
  holder_document: string;
  bank_ispb: string;
  bank_code: string;
  routing_number: string;
  account_number: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}
