import styled, { css } from 'styled-components/macro';

import { EButtonVariant, EButtonSize } from '@domain/enums/components/common/EButton';

import TextBase from '@components/common/DataDisplay/Text';

export interface IButtonProps {
  disabled?: boolean;
  isLoading?: boolean;
  type?: 'button' | 'submit' | 'reset';
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  variant?: EButtonVariant;
  size?: EButtonSize;
}

export interface IButtonTextProps {
  isLoading?: boolean;
}

export const Text = styled(TextBase)<IButtonTextProps>`
  display: flex;
  align-items: center;
  gap: 8px;

  ${({ isLoading }) =>
    isLoading &&
    css`
      opacity: 0;
    `};
`;

export const LoadingWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const ButtonBase = styled.button<IButtonProps>`
  height: 40px;
  border-radius: ${({ theme }) => theme.borderRadius.small};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  gap: 8px;
  background-color: ${({ theme }) => theme.colors.primary.green_lemon};
  border: 2px solid ${({ theme }) => theme.colors.primary.green_lemon};
  position: relative;
  transition: all 0.3s;
  cursor: pointer;

  ${({ variant, theme }) =>
    variant === EButtonVariant.SECONDARY &&
    css`
      & > span {
        color: ${theme.colors.primary.dark_green_60} !important;
      }
    `}

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.neutral.light};
    border-color: ${({ theme }) => theme.colors.neutral.light};
    cursor: not-allowed;

    ${({ variant, theme }) =>
      variant === EButtonVariant.SECONDARY &&
      css`
        & > span {
          color: ${theme.colors.neutral.lightest};
        }
      `}

    ${({ variant, theme }) =>
      variant === EButtonVariant.TERTIARY &&
      css`
        & > span {
          color: ${theme.colors.neutral.lightest};
        }
      `}

    &:hover {
      filter: unset;
    }

    &:active {
      filter: unset;
    }
  }

  & > span {
    line-height: 100%;
    color: ${({ theme }) => theme.colors.neutral.black};
    font-family: ${({ theme }) => theme.fonts.text};
    font-size: 1.6rem;
    font-weight: 500;
  }

  ${({ size }) =>
    size === EButtonSize.LARGE &&
    css`
      height: 52px;
      padding: 0 32px;
    `}

  ${({ size }) =>
    size === EButtonSize.SMALL &&
    css`
      height: 36px;
      padding: 0 16px;
    `}

  ${({ variant, theme }) =>
    variant === EButtonVariant.SECONDARY &&
    css`
      background-color: ${theme.colors.neutral.white};
      border: 1px solid ${theme.colors.neutral.light};

      & > span {
        color: ${theme.colors.primary.green_lemon};
      }

      &:hover {
        filter: unset;
        border: 1px solid ${theme.colors.neutral.lightest};
        background-color: ${theme.colors.neutral.lightest};
      }
    `}

  ${({ variant, theme }) =>
    variant === EButtonVariant.TERTIARY &&
    css`
      background-color: unset;
      border: 1px solid transparent;

      & > span {
        color: ${theme.colors.primary.green_lemon};
      }
    `}
`;
