import React from 'react';
import Tab from '@components/common/utils/Tab';
import Option from '@components/common/utils/Tab/Option';

import { EThemeCheckout } from '@domain/enums/common/checkoutCustomization/EThemeCheckout';

import { useNewCheckout } from '@store/common/checkoutCustomization/NewCheckoutCustomizationContext';

import Configuration from '../Tabs/Configurations';
import Appearance from '../Tabs/Appearance';
import Header from '../Tabs/Header';
import Footer from '../Tabs/Footer';
import ConfigurationOttawa from '../../NewCheckoutCustomizationTabContent/ConfigurationsOttawa';
import AppearanceOttawa from '../../NewCheckoutCustomizationTabContent/AppearanceOttawa';
import HeaderOttawa from '../../NewCheckoutCustomizationTabContent/HeaderOttawa';
import FooterOttawa from '../../NewCheckoutCustomizationTabContent/FooterOttawa';
import Advanced from '../Tabs/Advanced';
import Theme from '../Tabs/Theme';

const Body: React.FC = () => {
  const { selectedTab, onChangeTab, selectedThemeCheckout } = useNewCheckout();

  const tabsByTheme = {
    [EThemeCheckout.OTTAWA]: [
      Theme,
      ConfigurationOttawa,
      HeaderOttawa,
      FooterOttawa,
      AppearanceOttawa,
      Advanced,
    ],
    [EThemeCheckout.ZOUTI]: [Theme, Configuration, Header, Footer, Appearance, Advanced],
  };

  const currentTabs = tabsByTheme[selectedThemeCheckout] || tabsByTheme[EThemeCheckout.ZOUTI];

  const TabComponent = currentTabs[selectedTab];

  return (
    <>
      <Tab defaultTab={selectedTab} onChangeTab={onChangeTab}>
        <Option>Tema</Option>
        <Option>Configurações</Option>
        <Option>Cabeçalho</Option>
        <Option>Rodapé</Option>
        <Option>Aparência</Option>
        <Option>Avançado</Option>
      </Tab>
      {TabComponent && <TabComponent />}
    </>
  );
};

export default Body;
