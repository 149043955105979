import React from 'react';

import { EHeadAlign } from '@domain/enums/components/common/ETable';

import { useGetPayouts } from '@store/common/Payout/GetPayoutsContext';
import BottomPagination from '@components/common/utils/BottomPagination';
import EmptyTable from '@components/common/utils/EmptyTable';
import TableRow from './TableRow';

import TransferModal from '../PayoutsModal';
import DetailPayoutModal from '../DetailPayoutsModal';

import * as S from './styles';

const Body: React.FC = () => {
  const {
    page,
    isEmpty,
    totalPages,
    handlePageLimit,
    payoutsData,
    handlePageChange,
    payoutsTotal,
    isShowPagination,
  } = useGetPayouts();

  return (
    <S.Wrapper>
      <TransferModal />
      <DetailPayoutModal />
      <S.Table>
        <S.Table.Header>
          <S.Table.Row>
            <S.Table.Head>ID da transferência</S.Table.Head>
            <S.Table.Head>Valor transferido</S.Table.Head>
            <S.Table.Head align={EHeadAlign.CENTER}>Taxas</S.Table.Head>
            <S.Table.Head align={EHeadAlign.CENTER}>Status</S.Table.Head>
            <S.Table.Head>Criado em</S.Table.Head>
          </S.Table.Row>
        </S.Table.Header>

        <S.TableBody>
          {isEmpty && <EmptyTable colSpan={5} text="Nenhuma transferência encontrada." />}
          {payoutsData?.map(transfer => (
            <TableRow payouts={transfer} key={transfer.id} />
          ))}
        </S.TableBody>
      </S.Table>
      {isShowPagination && (
        <BottomPagination
          page={page}
          pageLimit={10}
          totalPages={totalPages}
          totalItems={payoutsTotal}
          handlePageLimit={handlePageLimit}
          handlePageChange={handlePageChange}
        />
      )}
    </S.Wrapper>
  );
};

export default Body;
