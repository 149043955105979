import React from 'react';
import useSWR from 'swr';
import { useParams } from 'react-router-dom';

import { ISwr } from '@domain/interfaces/common/swr/ISwr';
import { IListCheckoutProvider } from '@domain/interfaces/store/common/checkoutCustomization/IListCheckoutCustomizationProvider';
import { ICheckoutCustomization } from '@domain/interfaces/common/checkoutCustomization/ICheckoutCustomization';
import { IListCheckoutCustomizationResponse } from '@domain/interfaces/hooks/services/checkoutCustomization/ICheckoutCustomizationService';

import { DASHBOARD_API_URL } from '@constants/services/apiUrl';

import { dashboardInstance } from '@services/common/dashboardInstance';

import { useCheckoutCustomizationService } from '@hooks/services/checkoutCustomization/useCheckoutCustomizationService';
import { useToast } from '@store/context/common/ToastContext';
import { useErrorHandler } from '@store/context/common/ErrorHandlerContext';
import { useAnalytics } from '@store/context/common/AnalyticsContext';

const ListCheckoutCustomizationContext = React.createContext<IListCheckoutProvider | null>(null);

export const ListCheckoutCustomizationProvider: React.FC = ({ children }) => {
  const { analytics } = useAnalytics();
  const { toast } = useToast();
  const { handleError } = useErrorHandler();
  const { accountId, checkoutId } = useParams();
  const { deleteCheckoutCustomization, activateCheckoutCustomization } =
    useCheckoutCustomizationService();

  const [checkoutCustomizations, setCheckoutCustomizations] = React.useState<
    Array<ICheckoutCustomization>
  >([]);
  const [totalCheckoutCustomizations, setTotalCheckoutCustomizations] = React.useState<number>(0);
  const [page, setPage] = React.useState<number>(0);
  const [totalPages, setTotalPages] = React.useState<number>(0);
  const [pageLimit, setPageLimit] = React.useState<number>(10);

  dashboardInstance.defaults.headers.common['x-zouti-account'] = accountId;

  const CHECKOUT_CUSTOMIZATION_URL = `${DASHBOARD_API_URL}/accounts/${accountId}/checkouts/${checkoutId}/checkout_customizations?page=${
    page + 1
  }&limit=${pageLimit}`;

  const { data, isLoading, mutate, error } = useSWR<ISwr<IListCheckoutCustomizationResponse>>(
    CHECKOUT_CUSTOMIZATION_URL,
    dashboardInstance,
  );

  React.useEffect(() => {
    if (error) {
      handleError(error);
      return;
    }

    if (data?.data.object) {
      setCheckoutCustomizations(data?.data.object.data);
      setTotalPages(data?.data.object.total_pages);
      setTotalCheckoutCustomizations(data?.data.object.total);
    }
  }, [error, handleError, data]);

  const deleteCurrentCheckoutCustomization = React.useCallback(
    async checkoutCustomizationId => {
      try {
        await deleteCheckoutCustomization({ checkoutCustomizationId, accountId, checkoutId });

        analytics.track('Checkout Customization Deleted');

        await mutate();

        toast.success('Personalização de checkout excluída com sucesso!');
      } catch (errorCheckoutCustomization) {
        handleError(errorCheckoutCustomization);
      }
    },
    [deleteCheckoutCustomization, handleError, mutate, toast, accountId, checkoutId, analytics],
  );

  const activateCheckout = React.useCallback(
    async currentCheckoutId => {
      try {
        await activateCheckoutCustomization({
          checkoutCustomizationId: currentCheckoutId,
          accountId,
          checkoutId,
        });

        analytics.track('Checkout Customization Updated');

        await mutate();
        toast.success('Customização do checkout ativado com sucesso!');
      } catch (err) {
        handleError(err);
      }
    },
    [accountId, checkoutId, toast, activateCheckoutCustomization, handleError, mutate, analytics],
  );

  const handlePageChange = React.useCallback(selected => {
    setPage(selected);
  }, []);

  const handlePageLimit = React.useCallback(newPageLimit => {
    setPageLimit(newPageLimit);
  }, []);

  const isLoadingCheckout = isLoading;
  const isCheckoutError = Boolean(error);
  const checkouts = checkoutCustomizations;
  const isEmptyState = checkouts?.length === 0;

  return (
    <ListCheckoutCustomizationContext.Provider
      value={{
        checkouts,
        isLoadingCheckout,
        isCheckoutError,
        isEmptyState,
        mutate,
        page,
        deleteCurrentCheckoutCustomization,
        activateCheckout,
        handlePageChange,
        handlePageLimit,
        totalPages,
        totalCheckoutCustomizations,
      }}
    >
      {children}
    </ListCheckoutCustomizationContext.Provider>
  );
};

export const useListCheckout = (): IListCheckoutProvider => {
  const context = React.useContext(ListCheckoutCustomizationContext);

  if (!context) {
    throw new Error('useListCheckout must be used within provider');
  }

  return context;
};
