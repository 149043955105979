import styled from 'styled-components/macro';

import DropdownBase from '@components/common/utils/Dropdown';
import TextBase from '@components/common/DataDisplay/Text';
import ButtonBase from '@components/common/Inputs/Button';
import LabelBase from '@components/common/DataDisplay/Label';
import CheckboxBase from '@components/common/Inputs/Checkbox';

export const OptionText = styled(TextBase)``;

export const Checkbox = styled(CheckboxBase)``;

export const Option = styled(LabelBase)`
  padding: 12px 16px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary.lemon_20};

    & > span {
      color: ${({ theme }) => theme.colors.primary.dark_green_40};
    }
  }
`;

export const ConfirmButton = styled(ButtonBase)``;

export const CancelButton = styled(ButtonBase)`
  span {
    color: ${({ theme }) => theme.colors.neutral.darker};
  }
`;

export const ButtonsWrapper = styled.div`
  padding: 12px 32px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: flex-end;
`;

export const OptionsWrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.lighter};
`;

export const Content = styled.div`
  width: 482px;
`;

export const DefaultText = styled(TextBase)`
  font-size: 1.4rem;
`;

export const Select = styled.div`
  width: 100%;
  height: 40px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  display: flex;
  align-items: center;
  padding: 8px 16px;
  box-sizing: border-box;
  justify-content: space-between;
  cursor: pointer;
`;

export const Dropdown = styled(DropdownBase)``;

export const SelectEventName = styled(TextBase)`
  font-size: 1.6rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.neutral.base};
`;

export const CheckboxGroup = styled(LabelBase)`
  display: flex;
  align-items: center;
  padding: 12px 16px;

  gap: 8px;
`;

export const CheckboxLabel = styled(TextBase)`
  width: 100%;
  margin: 0;
  cursor: pointer;
  font-size: 1.6rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.neutral.dark};
`;
