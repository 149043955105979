import styled from 'styled-components';
import { WarningCircle } from 'phosphor-react';

import TextBase from '@components/common/DataDisplay/Text';
import { EPayoutStatus } from '@domain/enums/common/payouts/EPayoutStatus';

interface IBadgeProps {
  status: EPayoutStatus;
}

export const MessageContainer = styled.div<IBadgeProps>`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 16px;
  margin-top: 24px;
  border-radius: 4px;

  ${({ theme, status }) => {
    if (status === EPayoutStatus.FAILED) {
      return `
        background-color: ${theme.colors.negative.lightest_10};
        & > span {
          color: ${theme.colors.negative.dark};
        }
        & > svg {
          color: ${theme.colors.negative.dark};
        }
      `;
    }
    if ([EPayoutStatus.PROCESSING, EPayoutStatus.PROCESSING_ATTEMPT].includes(status)) {
      return `
        background-color: ${theme.colors.warning.lighter_10};
         & > span {
          color: ${theme.colors.warning.dark};
        }
        & > svg {
          color: ${theme.colors.warning.dark};
        }
      `;
    }
    if (status === EPayoutStatus.CANCELED) {
      return `
        background-color: ${theme.colors.negative.lightest_10};
        & > span {
          color: ${theme.colors.negative.dark};
        }
        & > svg {
          color: ${theme.colors.negative.dark};
        }
      `;
    }
    if (status === EPayoutStatus.COMPLETED) {
      return `
        background-color: ${theme.colors.positive.lightest_10};
        & > span {
          color: ${theme.colors.positive.dark};
        }
        & > svg {
          color: ${theme.colors.positive.dark};
        }
      `;
    }
    if (status === EPayoutStatus.PENDING) {
      return `
        background-color: ${theme.colors.neutral.lightest};
         & > span {
          color: ${theme.colors.neutral.darker};
        }
        & > svg {
           color: ${theme.colors.neutral.darker};
        }
      `;
    }

    return '';
  }}
`;

export const WarningIcon = styled(WarningCircle).attrs({ size: 20, weight: 'bold' })`
  min-width: 20px;
`;

export const MessageText = styled(TextBase)`
  font-size: 1.2rem;
  font-weight: 500;
`;
