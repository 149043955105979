import { AxiosResponse } from 'axios';

import {
  IFulfillmentService,
  IUploadFulfillmentSheetResponse,
  IUploadFulfillmentsSheetProps,
} from '@domain/interfaces/hooks/services/fulfillment/IFulfillmentService';

import { dashboardInstance } from '@services/common/dashboardInstance';

export const useFulfillmentService = (): IFulfillmentService => {
  const uploadFulfillmentsSheet = async ({
    accountId,
    file,
  }: IUploadFulfillmentsSheetProps): Promise<AxiosResponse<IUploadFulfillmentSheetResponse>> => {
    const formData = new FormData();

    formData.append('file', file);

    return dashboardInstance.put(`accounts/${accountId}/fulfillment/upload`, formData, {
      headers: {
        'x-zouti-account': accountId,
        'Content-Type': 'multipart/form-data',
      },
    });
  };

  return {
    uploadFulfillmentsSheet,
  };
};
