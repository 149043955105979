import React from 'react';

import * as S from './styles';

export interface ISwitch {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  id: string;
  name: string;
  defaultChecked?: boolean;
  innerRef?: React.RefObject<HTMLInputElement>;
  isLoading?: boolean;
  disabled?: boolean;
  checked?: boolean;
}

const Switch: React.FC<ISwitch> = ({
  onChange,
  id,
  name,
  defaultChecked,
  innerRef,
  isLoading = false,
  disabled,
  checked,
  ...rest
}) => {
  return (
    <S.Wrapper {...rest}>
      <S.SwitchWrapper>
        <S.SwitchInput
          id={id}
          name={name}
          type="checkbox"
          defaultChecked={defaultChecked}
          checked={checked}
          ref={innerRef}
          onChange={onChange}
          disabled={isLoading || disabled}
        />
        <S.SwitchLabel htmlFor={id} />
      </S.SwitchWrapper>
    </S.Wrapper>
  );
};

export default React.memo(Switch);
