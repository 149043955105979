import React from 'react';

import { useTheme } from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { PencilSimple as EditIcon, Trash as TrashIcon } from 'phosphor-react';

import { IDomain } from '@domain/interfaces/common/domain/IDomain';
import { EDomainType } from '@domain/enums/common/domain/EDomain';
import { EHeadAlign } from '@domain/enums/components/common/ETable';

import { useGetDomain } from '@store/common/domain/GetDomainContext';

import Badge from './Badge';

import * as S from './styles';

interface ITableRowProps {
  domain: IDomain;
}

const TableRow: React.FC<ITableRowProps> = ({ domain }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { accountId, checkoutId } = useParams();

  const { getDomainStatus, setIsOpenModal, setDomainDeletedInfo, checkHandle, selectedRow } =
    useGetDomain();

  const [isVisibleActions, setIsVisibleActions] = React.useState<boolean>(false);

  const onRowMouseEnter = React.useCallback(() => {
    setIsVisibleActions(true);
  }, []);

  const onRowMouseLeave = React.useCallback(() => {
    setIsVisibleActions(false);
  }, []);

  const onEditDomainButtonClick = (domainId: string): void => {
    navigate(`/${accountId}/${checkoutId}/dashboard/checkout/domain/${domainId}`);
  };

  const domainId = domain.id;
  const domainType = domain.type;
  const domainUrl = domain.name;
  const domainStatus = getDomainStatus(domain);

  const typeDomainMappings: Record<EDomainType, string> = {
    [EDomainType.SHARED]: 'Padrão ',
    [EDomainType.DEDICATED]: 'Personalizado',
  };

  return (
    <S.Table.Row onMouseEnter={onRowMouseEnter} onMouseLeave={onRowMouseLeave} borderBottom>
      <S.Table.Data>
        <S.RadioButton
          name="radioButton"
          checked={selectedRow === domainId}
          value={domainId}
          onChange={checkHandle}
          label={typeDomainMappings[domainType]}
        />
      </S.Table.Data>

      <S.Table.Data>
        <S.TextAndSubTextWrapper>
          <S.Text>{domainUrl}</S.Text>
        </S.TextAndSubTextWrapper>
      </S.Table.Data>

      <S.Table.Data>
        <Badge status={domainStatus} />
      </S.Table.Data>

      <S.Table.Data align={EHeadAlign.RIGHT}>
        {isVisibleActions && domainType !== EDomainType.SHARED ? (
          <S.ButtonActionsWrapper>
            <S.Table.Action onClick={() => onEditDomainButtonClick(domainId)}>
              <EditIcon size={18} color={theme.colors.neutral.darker} weight="bold" />
            </S.Table.Action>
            <S.Table.Action
              onClick={() => {
                setIsOpenModal(state => !state);
                setDomainDeletedInfo({ domainId, domainUrl });
              }}
            >
              <TrashIcon size={18} color={theme.colors.neutral.darker} weight="bold" />
            </S.Table.Action>
          </S.ButtonActionsWrapper>
        ) : (
          <S.ButtonActionsWrapper />
        )}
      </S.Table.Data>
    </S.Table.Row>
  );
};

export default TableRow;
