import React from 'react';
import useSWR from 'swr';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';

import { ISwr } from '@domain/interfaces/common/swr/ISwr';
import { IDetailAbandonedCart } from '@domain/interfaces/common/AbandonedCart/IDetailAbandonedCart';
import { IDetailAbandonedCartProvider } from '@domain/interfaces/store/common/AbandonedCart/IDetailAbandonedCartProvider';

import { DASHBOARD_API_URL } from '@constants/services/apiUrl';
import { ABANDONED_CART_STEPS } from '@constants/common/abandonedCart';

import { dashboardInstance } from '@services/common/dashboardInstance';

import { cpfFormatter } from '@helpers/utils/common/cpf';
import { phoneFormatter } from '@utils/common/phone';
import { getStepsAbandonedCart } from '@utils/getStepsAbandonedCart';
import { useErrorHandler } from '@store/context/common/ErrorHandlerContext';
import { IRecoveryTemplates } from '@domain/interfaces/common/recovery/IRecoveryTemplates';
import { IGetRecoveryTemplatesResponse } from '@domain/interfaces/hooks/services/recoveryTemplate/IRecoveryTemplateService';

const DetailAbandonedCartContext = React.createContext<IDetailAbandonedCartProvider | null>(null);

export const DetailAbandonedCartProvider: React.FC = ({ children }) => {
  const { abandonedCartId, accountId, checkoutId } = useParams();
  const { handleError } = useErrorHandler();
  let dateHeader;

  const [detailAbandonedCart, setDetailAbandonedCart] = React.useState<IDetailAbandonedCart>(
    {} as IDetailAbandonedCart,
  );

  const [accountRecoveryTemplate, setAccountRecoveryTemplate] = React.useState<
    Array<IRecoveryTemplates>
  >([]);

  dashboardInstance.defaults.headers.common['x-zouti-account'] = accountId;

  const DETAIL_ABANDONED_CART_URL = `${DASHBOARD_API_URL}/accounts/${accountId}/checkouts/${checkoutId}/abandoned_carts/${abandonedCartId}`;
  const RECOVERY_TEMPLATES_URL = `${DASHBOARD_API_URL}/accounts/${accountId}/checkouts/${checkoutId}/recovery_templates`;

  const { data, isLoading, isValidating, error } = useSWR<ISwr>(
    DETAIL_ABANDONED_CART_URL,
    dashboardInstance,
  );

  const {
    data: recoveryTemplatesData,
    isLoading: isLoadingRecoveryTemplates,
    error: recoveryTemplatesError,
  } = useSWR<ISwr<IGetRecoveryTemplatesResponse>>(RECOVERY_TEMPLATES_URL, dashboardInstance);

  React.useEffect(() => {
    if (error) {
      handleError(error);
    }

    if (data?.data.object) {
      setDetailAbandonedCart(data.data.object);
    }
  }, [data, handleError, error]);

  React.useEffect(() => {
    if (recoveryTemplatesError) {
      handleError(recoveryTemplatesError);
      return;
    }

    if (recoveryTemplatesData?.data?.object) {
      setAccountRecoveryTemplate(recoveryTemplatesData.data.object);
    }
  }, [recoveryTemplatesData, recoveryTemplatesError, handleError]);

  const customer = detailAbandonedCart?.customer;
  const { ip_address, step } = detailAbandonedCart;
  const customerData = {
    ...customer,
    document: cpfFormatter(customer?.document),
    phone: phoneFormatter(customer?.phone),
    ip_address,
    step,
  };

  const stepType = getStepsAbandonedCart(step);
  const currentStepInfo = ABANDONED_CART_STEPS.find(item => item.step === step);
  const StepIcon = currentStepInfo && currentStepInfo.icon;

  const products = detailAbandonedCart?.items;

  if (detailAbandonedCart?.created_at) {
    dateHeader = format(new Date(detailAbandonedCart?.created_at), "dd/MM/yyyy 'às' HH:mm");
  }

  const isLoadingListDetailAbandonedCart = isLoading || isValidating || isLoadingRecoveryTemplates;
  const isDetailAbandonedCartError = Boolean(error) || recoveryTemplatesError;

  return (
    <DetailAbandonedCartContext.Provider
      value={{
        isLoadingListDetailAbandonedCart,
        isDetailAbandonedCartError,
        accountRecoveryTemplate,
        detailAbandonedCart,
        customerData,
        stepType,
        StepIcon,
        products,
        dateHeader,
      }}
    >
      {children}
    </DetailAbandonedCartContext.Provider>
  );
};

export const useDetailAbandonedCart = (): IDetailAbandonedCartProvider => {
  const context = React.useContext(DetailAbandonedCartContext);

  if (!context) {
    throw new Error('useDetailAbandonedCart must be used within provider');
  }

  return context;
};
