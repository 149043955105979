import styled from 'styled-components/macro';
import { Headset, Truck, MetaLogo, Tag } from '@phosphor-icons/react';

import ButtonBase from '@components/common/Inputs/Button';
import ModalBase from '@components/common/DataDisplay/Modal';
import TextBase from '@components/common/DataDisplay/Text';

export const Modal = styled(ModalBase)`
  background-color: ${({ theme }) => theme.colors.neutral.white};
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

export const TipsCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-radius: 8px;
  padding: 12px;
  background-color: ${({ theme }) => theme.colors.primary.lemon_20};
`;

export const WrapperTextAndIcon = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const TipsText = styled(TextBase)`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.primary.dark_green_80};
`;

export const TeamText = styled(TextBase)`
  font-size: 1.5rem;
  font-weight: 500;
  align-self: start;
`;

export const Text = styled(TextBase)`
  font-size: 1.5rem;
  text-align: justify;
  align-self: start;
`;

export const HeadsetIcon = styled(Headset).attrs({
  size: 14,
  weight: 'bold',
})`
  color: ${({ theme }) => theme.colors.primary.dark_green_80};
`;

export const TruckIcon = styled(Truck).attrs({
  size: 14,
  weight: 'bold',
})`
  color: ${({ theme }) => theme.colors.primary.dark_green_80};
`;

export const MetaLogoIcon = styled(MetaLogo).attrs({
  size: 14,
  weight: 'bold',
})`
  color: ${({ theme }) => theme.colors.primary.dark_green_80};
`;

export const TagIcon = styled(Tag).attrs({
  size: 14,
  weight: 'bold',
})`
  color: ${({ theme }) => theme.colors.primary.dark_green_80};
`;

export const Button = styled(ButtonBase)`
  align-self: end;
  margin-top: 24px;
`;
