import React from 'react';
import useSWR from 'swr';
import { useParams } from 'react-router-dom';

import { ISwr } from '@domain/interfaces/common/swr/ISwr';
import { IHopyBalance } from '@domain/interfaces/common/hopyTransactionsStatements/IHopyTransactionsStatements';
import { IGetHopyBalanceResponse } from '@domain/interfaces/hooks/services/hopyTransactionsStatements/IHopyBalanceService';
import { IGetHopyTransactionsStatementsProvider } from '@domain/interfaces/store/common/hopyTransactionsStatements/IGetHopyTransactionsStatementsProvider';

import { DASHBOARD_API_URL } from '@constants/services/apiUrl';

import { dashboardInstance } from '@services/common/dashboardInstance';

export const GetHopyTransactionsStatementsContext =
  React.createContext<IGetHopyTransactionsStatementsProvider | null>(null);

export const GetHopyTransactionsStatementsProvider: React.FC = ({ children }) => {
  const { accountId } = useParams();

  const [balances, setBalances] = React.useState<IHopyBalance | undefined>(undefined);

  const HOPY_BALANCE_URL = `${DASHBOARD_API_URL}/accounts/${accountId}/hopy_balance`;

  const { data, mutate: mutateHopyBalance } = useSWR<ISwr<IGetHopyBalanceResponse>>(
    HOPY_BALANCE_URL,
    dashboardInstance,
  );

  React.useEffect(() => {
    if (data) {
      setBalances(data.data.object);
    }
  }, [data]);

  return (
    <GetHopyTransactionsStatementsContext.Provider value={{ balances, mutateHopyBalance }}>
      {children}
    </GetHopyTransactionsStatementsContext.Provider>
  );
};

export const useGetHopyTransactionsStatements = (): IGetHopyTransactionsStatementsProvider => {
  const context = React.useContext(GetHopyTransactionsStatementsContext);

  if (!context) {
    throw new Error(
      'useGetHopyTransactionsStatements must be used within GetHopyTransactionsStatementsProvider',
    );
  }

  return context;
};
