export function formatCurrency(
  value: number,
  language: string | undefined = 'pt-BR',
  currency: string | undefined = 'BRL',
): string {
  if (language === 'PORTUGUESE') {
    return value.toLocaleString('pt-BR', {
      style: 'currency',
      currency: currency || 'BRL',
    });
  }

  return value?.toLocaleString(language || 'pt-BR', {
    style: 'currency',
    currency: currency || 'BRL',
  });
}

export function getCurrencySymbol(
  locale: string | undefined = 'pt-BR',
  currency: string | undefined = 'BRL',
): string {
  if (locale === 'PORTUGUESE') {
    return (0)
      .toLocaleString('pt-BR', {
        style: 'currency',
        currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
      .replace(/\d/g, '')
      .trim();
  }

  return (0)
    .toLocaleString(locale, {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, '')
    .trim();
}

export const formatCurrencyParts = (
  value: number,
  language = 'pt-BR',
  currency = 'BRL',
): { symbol: string; amount: string } => {
  const amount = value
    ?.toLocaleString(language, {
      style: 'currency',
      currency,
    })
    .replace(/[^\d.,-]/g, '');

  const symbol = getCurrencySymbol(language, currency);

  return { symbol, amount };
};
