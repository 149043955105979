import styled from 'styled-components/macro';
import { Copy } from 'phosphor-react';

import HeadingBase from '@components/common/DataDisplay/Heading';
import TextBase from '@components/common/DataDisplay/Text';
import ImageBase from '@components/common/DataDisplay/Image';

export const Wrapper = styled.div`
  padding: 24px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.neutral.white};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
`;

export const WrapperInfos = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Divider = styled.hr`
  margin: 16px 0;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
`;

export const Value = styled(TextBase)`
  max-width: 240px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.neutral.darkest};
`;

export const Label = styled(TextBase)`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.neutral.dark};
`;

export const LabelAndValue = styled.div`
  /* max-width: 200px; */
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Title = styled(HeadingBase)`
  color: ${({ theme }) => theme.colors.neutral.black};
`;

export const FlagCountryImage = styled(ImageBase)`
  width: 22px;
  height: 16px;
  border-radius: 2px;
`;

export const CustomerOriginAndFlagWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const DropdownCard = styled.div`
  display: flex;
  position: absolute;
  align-items: center;
  font-family: ${({ theme }) => theme.fonts.text};
  font-size: 1.4rem;
  gap: 8px;
  border-radius: 4px;
  margin-top: 5px;
  color: ${({ theme }) => theme.colors.neutral.darkest};
  background-color: ${({ theme }) => theme.colors.neutral.white};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 13px;
  transition: all 0.3s;
`;
export const WrapperDropdownAndEmail = styled.div`
  width: 100%;
`;

export const CopyIcon = styled(Copy).attrs(props => ({
  size: 20,
  color: props.theme.colors.primary.dark_green_60,
}))`
  &:hover {
    transform: scale(1.2);
  }
  cursor: pointer;
`;
