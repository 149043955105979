import * as yup from 'yup';

const configurationCheckoutCustomizationSchema = yup.object().shape({
  name: yup.string().required('Este campo é obrigatório.'),
  allow_credit_card: yup.boolean().required('Este campo é obrigatório.'),
  allow_pix: yup.boolean().required('Este campo é obrigatório.'),
  allow_boleto: yup.boolean().required('Este campo é obrigatório.'),
  credit_card_discount_amount: yup.number().required('Este campo é obrigatório.'),
  pix_discount_amount: yup.number().required('Este campo é obrigatório.'),
  boleto_discount_amount: yup.number().required('Este campo é obrigatório.'),
  credit_card_discount_type: yup.string(),
  pix_discount_type: yup.string(),
  boleto_discount_type: yup.string(),
  show_coupon_input: yup.boolean().required('Este campo é obrigatório.'),
  show_time_shipping_estimate: yup.boolean().required('Este campo é obrigatório.'),
  enable_itens_quantity_change: yup.boolean().required('Este campo é obrigatório.'),
});

export default configurationCheckoutCustomizationSchema;
