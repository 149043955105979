import React from 'react';

import { useConfigDomain } from '@store/common/domain/ConfigDomainContext';

import Header from './Header';
import Body from './Body';

import * as S from './styles';

const ConfigDomain: React.FC = () => {
  const { isLoadingConfigDomain } = useConfigDomain();

  if (isLoadingConfigDomain) {
    return <div>Loading...</div>;
  }

  return (
    <S.Wrapper>
      <Header />
      <Body />
    </S.Wrapper>
  );
};
export default ConfigDomain;
