import React, { createContext } from 'react';

interface ITabsProviderProps {
  selectedTab: number;
  onChangeTab: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const TabsContext = createContext<ITabsProviderProps>({} as ITabsProviderProps);

export const TabsProvider: React.FC = ({ children }) => {
  const [selectedTab, setSelectedTab] = React.useState<number>(0);
  const onChangeTab = React.useCallback(index => setSelectedTab(index), []);

  return (
    <TabsContext.Provider value={{ selectedTab, onChangeTab }}>{children}</TabsContext.Provider>
  );
};

export const useTabsContext = (): ITabsProviderProps => {
  const context = React.useContext(TabsContext);

  if (!context) {
    throw new Error('useTabsContext must be used within provider');
  }

  return context;
};
