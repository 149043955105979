import styled from 'styled-components/macro';

import TableBase from '@components/common/utils/Table';
import EmptyImageBase from '@components/common/utils/EmptyImage';
import TextBase from '@components/common/DataDisplay/Text';

export const LinkWrapper = styled.div`
  width: 160px;
  display: flex;
  justify-content: flex-end;
`;

export const TableData = styled(TableBase.Data)``;

export const TableRow = styled(TableBase.Row)`
  & > td {
    padding: 16px 8px;
  }
`;

export const VariantName = styled(TextBase)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 200px;
`;

export const VariantNameContent = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const ProductImage = styled.img`
  height: 36px;
  width: 36px;
  border-radius: ${({ theme }) => theme.borderRadius.small};
`;

export const EmptyImage = styled(EmptyImageBase)`
  height: 36px;
  width: 36px;
`;

export const CheckoutLinkTextWrapper = styled.button`
  background-color: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  font-family: ${({ theme }) => theme.fonts.text};
  font-weight: 400;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.primary.dark_green_60};
  cursor: pointer;
  transition: all 0.3s;
  text-decoration: none;

  &:hover {
    filter: brightness(0.9);
  }

  &:active {
    filter: brightness(0.8);
  }
`;
