import styled from 'styled-components/macro';
import { X, Info as Icon } from 'phosphor-react';

import ButtonBase from '@components/common/Inputs/Button';
import InputGroupBase from '@components/common/Inputs/InputGroup';
import LabelBase from '@components/common/DataDisplay/Label';
import ModalBase from '@components/common/DataDisplay/Modal';
import OptionBase from '@components/common/Inputs/Select/Option';
import SelectBase from '@components/common/Inputs/Select';
import TextBase from '@components/common/DataDisplay/Text';
import TextFieldBase from '@components/common/Inputs/TextField';

export const Modal = styled(ModalBase)`
  background-color: ${({ theme }) => theme.colors.neutral.white};

  & > div {
    padding: 0;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Label = styled(LabelBase)`
  margin: 0;
`;

export const Input = styled(TextFieldBase)`
  flex-grow: 1;
  border: none;
`;

export const Select = styled(SelectBase)`
  width: 100%;
`;

export const Option = styled(OptionBase)``;

export const MessageError = styled(TextBase)`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.negative.base};
`;

export const InputGroup = styled(InputGroupBase)`
  gap: 8px;
  padding: 0 32px;
`;

export const Info = styled(TextBase)`
  font-size: 1.6rem;
  padding: 24px 32px 0px 32px;
`;

export const MessageInfo = styled(TextBase)`
  font-size: 1.4rem;
`;

export const ModalAction = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 16px 32px;
  background-color: ${({ theme }) => theme.colors.neutral.gray_dark};
  gap: 16px;
  margin-top: 8px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
`;

export const SaveButton = styled(ButtonBase)``;

export const CancelButton = styled(ButtonBase)`
  background-color: ${({ theme }) => theme.colors.neutral.gray_white};
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  > span {
    color: ${({ theme }) => theme.colors.neutral.dark} !important;
  }
`;

export const InfoIcon = styled(Icon)``;
export const HintText = styled(TextBase)``;
export const IconAndTextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

// Tag Input

export const TagInputContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 4px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  border-radius: 4px;
  flex-wrap: wrap;
  gap: 6px;
`;

export const EmailText = styled(TextBase)``;

export const TagItem = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
  background-color: ${({ theme }) => theme.colors.primary.lemon_10};
  padding: 4px 6px;
  border-radius: 4px;
`;

export const CloseIcon = styled(X)`
  cursor: pointer;
`;
