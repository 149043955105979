import { AxiosResponse } from 'axios';

import {
  ICreateShippingProps,
  ICreateShippingResponse,
  IUpdateShippingProps,
  IUpdateShippingResponse,
  IDeleteShippingProps,
  IDeleteShippingResponse,
  IShippingService,
  IInactivateShippingProps,
  IActivateShippingProps,
} from '@domain/interfaces/hooks/services/shipping/IShippingService';

import { dashboardInstance } from '@services/common/dashboardInstance';

export const useShippingService = (): IShippingService => {
  const createShipping = async ({
    accountId,
    checkoutId,
    payload,
  }: ICreateShippingProps): Promise<AxiosResponse<ICreateShippingResponse>> => {
    return dashboardInstance.post(
      `accounts/${accountId}/checkouts/${checkoutId}/freights`,
      { ...payload },
      {
        headers: {
          'x-zouti-account': accountId,
        },
      },
    );
  };

  const updateShipping = async ({
    shippingId,
    payload,
    accountId,
    checkoutId,
  }: IUpdateShippingProps): Promise<AxiosResponse<IUpdateShippingResponse>> => {
    return dashboardInstance.put(
      `accounts/${accountId}/checkouts/${checkoutId}/freights/${shippingId}`,
      { ...payload },
      {
        headers: {
          'x-zouti-account': accountId,
        },
      },
    );
  };

  const deleteShipping = async ({
    accountId,
    checkoutId,
    shippingId,
  }: IDeleteShippingProps): Promise<AxiosResponse<IDeleteShippingResponse>> => {
    return dashboardInstance.delete(
      `accounts/${accountId}/checkouts/${checkoutId}/freights/${shippingId}`,
      {
        headers: {
          'x-zouti-account': accountId,
        },
      },
    );
  };

  const inactivateShipping = async ({
    shippingId,
    accountId,
    checkoutId,
  }: IInactivateShippingProps): Promise<AxiosResponse<IUpdateShippingResponse>> => {
    return dashboardInstance.put(
      `accounts/${accountId}/checkouts/${checkoutId}/freights/${shippingId}/inactivate`,
      {
        headers: {
          'x-zouti-account': accountId,
        },
      },
    );
  };

  const activateShipping = async ({
    shippingId,
    accountId,
    checkoutId,
  }: IActivateShippingProps): Promise<AxiosResponse<IUpdateShippingResponse>> => {
    return dashboardInstance.put(
      `accounts/${accountId}/checkouts/${checkoutId}/freights/${shippingId}/activate`,
      {
        headers: {
          'x-zouti-account': accountId,
        },
      },
    );
  };

  return {
    createShipping,
    updateShipping,
    deleteShipping,
    inactivateShipping,
    activateShipping,
  };
};
