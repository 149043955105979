import React from 'react';
import 'react-loading-skeleton/dist/skeleton.css';

import * as S from './styles';

const SkeletonLoading: React.FC = () => {
  return (
    <S.Wrapper>
      <S.Title />
      <S.Switch />
      <S.Textarea />
    </S.Wrapper>
  );
};

export default SkeletonLoading;
