import React from 'react';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import * as S from './styles';

const OverviewSkeleton: React.FC = () => {
  return (
    <S.Wrapper>
      <Skeleton width="180px" height="15px" />
      {Array.from({ length: 3 }).map((_, index) => (
        <S.Row key={index.toFixed()}>
          <S.IconAndTextWrapper>
            <S.ContainerIcon width="48px" height="48px" />
            <S.ContainerText>
              <Skeleton width="150px" height="20px" />
              <Skeleton width="100px" height="15px" />
            </S.ContainerText>
          </S.IconAndTextWrapper>
          <S.WrapperItems>
            <Skeleton width="100px" height="15px" />
            <Skeleton width="50px" height="20px" />
          </S.WrapperItems>
        </S.Row>
      ))}
    </S.Wrapper>
  );
};

export default OverviewSkeleton;
