import styled from 'styled-components/macro';

import TableBase from '@components/common/utils/Table';

export const Wrapper = styled.div`
  height: 100%;
`;

export const Table = styled(TableBase)`
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  th {
    padding: 16px 24px !important;
  }
  tr {
    border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  }
`;
