import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useGetPromocode } from '@store/common/promocode/GetPromocodeContext';
import { useAnalytics } from '@store/context/common/AnalyticsContext';

import EmptyData from '@components/common/utils/EmptyData';

import ListSkeletonLoading from '@components/common/utils/ListSkeletonLoading';
import Header from './Header';
import Body from './Body';

import * as S from './styles';

const Coupon: React.FC = () => {
  const navigate = useNavigate();
  const { isLoadingPromocodes, isPromocodesError, promocodes } = useGetPromocode();
  const { accountId, checkoutId } = useParams();
  const { analytics } = useAnalytics();

  React.useEffect(() => {
    analytics.track('Home Page Viewed');
  }, [analytics]);

  const isEmpty = promocodes.length === 0;

  const onEmptyDataButtonClick = (): void => {
    navigate(`/${accountId}/${checkoutId}/dashboard/marketing/coupon/new`);
  };

  if (isLoadingPromocodes) {
    return <ListSkeletonLoading />;
  }

  if (isPromocodesError && !isLoadingPromocodes) {
    return <div>Error...</div>;
  }

  if (isEmpty) {
    return (
      <EmptyData
        title="Nenhum cupom cadastrado"
        description="Adicione os cupons que deseja distribuir para seus clientes."
        buttonText="Novo cupom"
        onClick={onEmptyDataButtonClick}
      />
    );
  }

  return (
    <S.Wrapper>
      <Header />
      <Body />
      {/* <BottomPagination totalItems={1} totalPages={1} handlePageChange={() => ''} /> */}
    </S.Wrapper>
  );
};
export default Coupon;
