import { AxiosResponse } from 'axios';

import {
  ICreateReportanaProps,
  IReportanaService,
  IActivateReportanaProps,
  IInactivateReportanaProps,
  IInactivateReportanaResponse,
  IActiveReportanaResponse,
  ICreateReportanaResponse,
  IUpdateReportanaProps,
  IUpdateReportanaResponse,
} from '@domain/interfaces/hooks/services/reportana/IReportanaService';

import { dashboardInstance } from '@services/common/dashboardInstance';

export const useReportanaService = (): IReportanaService => {
  const createReportana = async ({
    accountId,
    checkoutId,
    payload,
  }: ICreateReportanaProps): Promise<AxiosResponse<ICreateReportanaResponse>> => {
    return dashboardInstance.post(
      `/accounts/${accountId}/checkouts/${checkoutId}/integrations/reportana`,
      { ...payload },
    );
  };

  const updateReportana = async ({
    accountId,
    checkoutId,
    reportanaId,
    payload,
  }: IUpdateReportanaProps): Promise<AxiosResponse<IUpdateReportanaResponse>> => {
    return dashboardInstance.put(
      `/accounts/${accountId}/checkouts/${checkoutId}/integrations/reportana/${reportanaId}`,
      {
        ...payload,
      },
    );
  };

  const activateReportana = async ({
    accountId,
    checkoutId,
    reportanaId,
  }: IActivateReportanaProps): Promise<AxiosResponse<IActiveReportanaResponse>> => {
    return dashboardInstance.put(
      `accounts/${accountId}/checkouts/${checkoutId}/integrations/reportana/${reportanaId}/activate`,
    );
  };

  const inactivateReportana = async ({
    accountId,
    checkoutId,
    reportanaId,
  }: IInactivateReportanaProps): Promise<AxiosResponse<IInactivateReportanaResponse>> => {
    return dashboardInstance.put(
      `accounts/${accountId}/checkouts/${checkoutId}/integrations/reportana/${reportanaId}/inactivate`,
    );
  };

  return { createReportana, activateReportana, inactivateReportana, updateReportana };
};
