import React from 'react';

import * as S from './styles';

export const AcquirerValidation: React.FC = () => {
  return (
    <S.Wrapper>
      <S.Text>
        Houve um problema para validar o seu CNPJ. Entre em contato com o nosso suporte para mais
        detalhes.
      </S.Text>
    </S.Wrapper>
  );
};

export default AcquirerValidation;
