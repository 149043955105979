import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';

import { useConfig } from '@store/context/common/ConfigContext';

import * as S from './styles';

const OnboardingOutlet: React.FC = () => {
  const { user } = useConfig();

  const isAuthenticated = Boolean(user);

  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  return (
    <S.Wrapper>
      <S.Content>
        <Outlet />
      </S.Content>
    </S.Wrapper>
  );
};

export default OnboardingOutlet;
