import React from 'react';

import { useGetOrder } from '@store/common/order/GetOrder';

import Header from './Header';
import Body from './Body';
import GlobalBody from './GlobalBody';
import RefundModal from './RefundModal';

import * as S from './styles';

const Wrapper: React.FC = () => {
  const { isLoadingOrder, order, isOrderError } = useGetOrder();

  if (isLoadingOrder) {
    return <div>Carregando...</div>;
  }

  // if (isOrderError && !isLoadingOrder) {
  //   return <div>Erro...</div>;
  // }

  return (
    <S.Wrapper>
      <Header />

      <RefundModal />

      {order?.language === 'pt-BR' || order?.language === 'PORTUGUESE' ? <Body /> : <GlobalBody />}
    </S.Wrapper>
  );
};

export default Wrapper;
