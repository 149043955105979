import React from 'react';

import { IntegrationsProvider } from '@hooks/pages/dashboard/Apps/useIntegrations';

import Content from '@components/pages/Dashboard/Apps';

const Apps: React.FC = () => {
  return (
    <IntegrationsProvider>
      <Content />
    </IntegrationsProvider>
  );
};

export default Apps;
