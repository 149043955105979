import React from 'react';

import { useDetailAbandonedCart } from '@store/common/AbandonedCart/DetailAbandonedCartContext';
import { useAnalytics } from '@store/context/common/AnalyticsContext';

import SkeletonLoading from '@components/pages/Dashboard/AbandonedCart/DetailAbandonedCart/SkeletonLoading';
import Header from './Header';
import Body from './Body';

import * as S from './styles';

const DetailAbandonedCart: React.FC = () => {
  const { isLoadingListDetailAbandonedCart } = useDetailAbandonedCart();
  const { analytics } = useAnalytics();

  React.useEffect(() => {
    analytics.track('Abandoned Cart Details Page Viewed');
  }, [analytics]);

  if (isLoadingListDetailAbandonedCart) {
    return <SkeletonLoading />;
  }
  return (
    <S.Wrapper>
      <Header />

      <Body />
    </S.Wrapper>
  );
};

export default DetailAbandonedCart;
