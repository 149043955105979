import React from 'react';

import Wrapper from '@components/pages/Dashboard/Reportana/Wrapper';
import { ReportanaProvider } from '@store/common/Reportana/ReportanaContext';

const Reportana: React.FC = () => {
  return (
    <ReportanaProvider>
      <Wrapper />
    </ReportanaProvider>
  );
};

export default Reportana;
