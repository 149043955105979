import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components/macro';
import { UserCirclePlus, PlusCircle } from 'phosphor-react';

import { EButtonVariant } from '@domain/enums/components/common/EButton';
import PageHeader from '@components/common/utils/PageHeader';
import { useTabsContext } from '@store/context/components/Tab/tabsContext';
import { useUsersContext } from '@store/common/users/UsersContext';

import InviteUserModal from '../InviteUserModal';

import * as S from './styles';

const Header: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { accountId, checkoutId } = useParams();
  const { selectedTab } = useTabsContext();
  const { handleOpenModal, isOpenModal, setIsOpenModal } = useUsersContext();

  const handleRedirectCreateGroup = (): void => {
    navigate(`/${accountId}/${checkoutId}/dashboard/settings/membership-groups`);
  };

  return (
    <S.Wrapper>
      <InviteUserModal isOpen={isOpenModal} setIsOpenModal={setIsOpenModal} />
      <S.TitleAndButtonWrapper>
        <PageHeader
          title="Usuários"
          description="Gerencie os usuários ativos e vincule-os em grupos de trabalho."
        />

        {selectedTab === 0 && (
          <S.InviteUserButton variant={EButtonVariant.SECONDARY} onClick={handleOpenModal}>
            <UserCirclePlus size={16} weight="bold" color={theme.colors.neutral.darker} /> Convidar
            usuário
          </S.InviteUserButton>
        )}

        {selectedTab === 1 && (
          <S.CreateGroupButton
            variant={EButtonVariant.SECONDARY}
            onClick={handleRedirectCreateGroup}
          >
            <PlusCircle size={16} weight="bold" color={theme.colors.neutral.darker} />
            Criar grupo
          </S.CreateGroupButton>
        )}
      </S.TitleAndButtonWrapper>
    </S.Wrapper>
  );
};

export default Header;
