import React from 'react';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/common/EHeading';
import { IHeadingProps } from './styles';

import * as S from './styles';

const Heading: React.FC<IHeadingProps> = ({
  size = EHeadingSize.H1,
  fontWeight = EHeadingWeight.BOLD,
  children,
  ...rest
}) => {
  return (
    <S.HeadingBase as={size} size={size} fontWeight={fontWeight} {...rest}>
      {children}
    </S.HeadingBase>
  );
};

export default Heading;
