import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { EButtonVariant } from '@domain/enums/components/common/EButton';
import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/common/EHeading';
import { ICreateCheckoutCustomizationData } from '@domain/interfaces/hooks/services/checkoutCustomization/ICheckoutCustomizationService';

import InputColorCheckout from '@components/common/Inputs/InputColorCheckout';
import Tooltip from '@components/pages/Dashboard/CheckoutCustomization/Tooltip';
import { useEditCheckout } from '@store/common/checkoutCustomization/EditCheckoutCustomizationContext';

import footerCheckoutCustomizationSchema from '@helpers/validators/pages/dashboard/checkoutCustomizations/footerCheckoutCustomizationSchema';

import * as S from './styles';

const FooterOttawa: React.FC = () => {
  const navigate = useNavigate();
  const { accountId, checkoutId } = useParams();
  const {
    checkoutCustomization,
    updateCurrentCheckout,
    setCheckoutCustomization,
    isUpdateCheckout,
    selectedThemeCheckout,
  } = useEditCheckout();
  const {
    id,
    header_customization,
    boleto_discount_type,
    credit_card_discount_type,
    pix_discount_type,
    name,
    allow_credit_card,
    allow_pix,
    allow_boleto,
    credit_card_discount_amount,
    pix_discount_amount,
    boleto_discount_amount,
    show_coupon_input,
    enable_itens_quantity_change,
    footer_customization,
    appearance_customization,
    advanced_customization,
    show_time_shipping_estimate,
  } = checkoutCustomization;

  const { control, register, reset, handleSubmit, setValue, watch } = useForm({
    resolver: yupResolver(footerCheckoutCustomizationSchema),
  });

  const onSubmit = async (formData: FieldValues): Promise<void> => {
    const data: ICreateCheckoutCustomizationData = {
      name,
      allow_credit_card,
      allow_pix,
      allow_boleto,
      boleto_discount_type,
      credit_card_discount_type,
      pix_discount_type,
      credit_card_discount_amount,
      pix_discount_amount,
      boleto_discount_amount,
      show_coupon_input,
      enable_itens_quantity_change,
      show_time_shipping_estimate,
      header_customization: { ...header_customization },
      footer_customization: {
        footer_hex_color: formData.footer_hex_color,
        text_hex_color: formData.text_hex_color,
        show_security_seal: false,
        show_account_name: false,
        show_account_address: false,
        show_account_cnpj_or_cpf: false,
        show_account_email: false,
        metadata: {
          link_hex_color: formData.metadata.link_hex_color,
          policies: {
            devolution: {
              enabled: formData.metadata.policies.devolution.enabled,
              link: formData.metadata.policies.devolution.link,
            },
            privacy: {
              enabled: formData.metadata.policies.privacy.enabled,
              link: formData.metadata.policies.privacy.link,
            },
            terms: {
              enabled: formData.metadata.policies.terms.enabled,
              link: formData.metadata.policies.terms.link,
            },
          },
        },
        id,
      },
      appearance_customization: { ...appearance_customization },
      advanced_customization: { ...advanced_customization },
      checkout_theme: { theme: selectedThemeCheckout?.theme },
    };

    await updateCurrentCheckout({ checkoutCustomizationId: id, payload: data });
  };

  React.useEffect(() => {
    return () => {
      const fieldValues = watch();
      setCheckoutCustomization(prevData => {
        return {
          ...prevData,
          footer_customization: {
            footer_hex_color: fieldValues.footer_hex_color,
            text_hex_color: fieldValues.text_hex_color,
            show_security_seal: fieldValues.show_security_seal,
            show_account_name: fieldValues.show_account_name,
            show_account_address: fieldValues.show_account_address,
            show_account_cnpj_or_cpf: fieldValues.show_account_cnpj_or_cpf,
            show_account_email: fieldValues.show_account_email,
            id,
            metadata: {
              link_hex_color: fieldValues.metadata?.link_hex_color || '#FF5F00',
              policies: {
                devolution: {
                  enabled: fieldValues.metadata?.policies.devolution.enabled || false,
                  link: fieldValues.metadata?.policies.devolution.link || '',
                },
                privacy: {
                  enabled: fieldValues.metadata?.policies.privacy.enabled || false,
                  link: fieldValues.metadata?.policies.privacy.link || '',
                },
                terms: {
                  enabled: fieldValues.metadata?.policies.terms.enabled || false,
                  link: fieldValues.metadata?.policies.terms.link || '',
                },
              },
            },
          },
        };
      });
    };
  }, [setCheckoutCustomization, watch, id]);

  React.useEffect(() => {
    setValue('metadata.policies.terms.link', footer_customization?.metadata?.policies?.terms?.link);
    setValue('footer_hex_color', footer_customization?.footer_hex_color);
    setValue('text_hex_color', footer_customization?.text_hex_color);
    setValue('show_security_seal', footer_customization?.show_security_seal);
    setValue('show_account_name', footer_customization?.show_account_name);
    setValue('show_account_address', footer_customization?.show_account_address);
    setValue('show_account_cnpj_or_cpf', footer_customization?.show_account_cnpj_or_cpf);
    setValue('show_account_email', footer_customization?.show_account_email);
  }, [setValue, register, footer_customization]);

  const onCancelButtonClick = (): void => {
    reset();
    navigate(`/${accountId}/${checkoutId}/dashboard/checkout/customizations`);
  };

  return (
    <S.Form onSubmit={handleSubmit(onSubmit)}>
      <S.Wrapper>
        <S.ColorSection>
          <S.Title size={EHeadingSize.H4} fontWeight={EHeadingWeight.SEMI_BOLD}>
            Cores
          </S.Title>
          <InputColorCheckout
            {...register('metadata.link_hex_color', {
              value: footer_customization?.metadata?.link_hex_color,
            })}
            value={footer_customization?.metadata?.link_hex_color}
            onChange={event => {
              setValue('metadata.link_hex_color', event.target.value);
            }}
            placeholder="Digite o código da cor"
          >
            {/* <Tooltip url="https://images.freeimages.com/images/small-previews/aed/three-bees-on-sunflower-1337029.jpg"> */}
            Cor dos links
            {/* </Tooltip> */}
          </InputColorCheckout>
        </S.ColorSection>
        <S.Divider />
        <S.RoleSection>
          <S.Title size={EHeadingSize.H4} fontWeight={EHeadingWeight.SEMI_BOLD}>
            Políticas
          </S.Title>
          <S.CheckboxGroup>
            <Controller
              control={control}
              name="metadata.policies.devolution.enabled"
              defaultValue={footer_customization?.metadata?.policies?.devolution?.enabled}
              render={({ field }) => (
                <S.Checkbox
                  {...register('metadata.policies.devolution.enabled', {
                    value: footer_customization?.metadata?.policies?.devolution?.enabled,
                  })}
                  id="metadata.policies.devolution.enabled"
                  checked={footer_customization?.metadata?.policies?.devolution?.enabled}
                  value={field?.value?.toString()}
                  onChange={() => field?.onChange(!field.value)}
                />
              )}
            />
            <S.CheckboxLabel htmlFor="metadata.policies.devolution.enabled">
              Política de devolução
            </S.CheckboxLabel>
          </S.CheckboxGroup>
          <S.Input
            {...register('metadata.policies.devolution.link', {
              value: footer_customization?.metadata?.policies?.devolution?.link,
            })}
            name="metadata.policies.devolution.link"
            placeholder="Link da Política de devolução"
            type="text"
          />
          <S.CheckboxGroup>
            <Controller
              control={control}
              name="metadata.policies.privacy.enabled"
              defaultValue={footer_customization?.metadata?.policies?.privacy?.enabled}
              render={({ field }) => (
                <S.Checkbox
                  {...register('metadata.policies.privacy.enabled', {
                    value: footer_customization?.metadata?.policies?.privacy?.enabled,
                  })}
                  id="metadata.policies.privacy.enabled"
                  checked={footer_customization?.metadata?.policies?.privacy?.enabled}
                  value={field?.value?.toString()}
                  onChange={() => field?.onChange(!field.value)}
                />
              )}
            />
            <S.CheckboxLabel htmlFor="metadata.policies.privacy.enable">
              Política de privacidade
            </S.CheckboxLabel>
          </S.CheckboxGroup>
          <S.Input
            {...register('metadata.policies.privacy.link', {
              value: footer_customization?.metadata?.policies?.privacy?.link,
            })}
            name="metadata.policies.privacy.link"
            placeholder="Link da Política de privacidade"
            type="text"
          />
          <S.CheckboxGroup>
            <Controller
              control={control}
              name="metadata.policies.terms.enabled"
              defaultValue={footer_customization?.metadata?.policies?.terms?.enabled}
              render={({ field }) => (
                <S.Checkbox
                  {...register('metadata.policies.terms.enabled', {
                    value: footer_customization?.metadata?.policies?.terms?.enabled,
                  })}
                  id="metadata.policies.terms.enabled"
                  checked={footer_customization?.metadata?.policies?.terms?.enabled}
                  value={field?.value?.toString()}
                  onChange={() => field?.onChange(!field.value)}
                />
              )}
            />
            <S.CheckboxLabel htmlFor="metadata.policies.terms.enabled">
              Termos de serviço
            </S.CheckboxLabel>
          </S.CheckboxGroup>
          <S.Input
            {...register('metadata.policies.terms.link', {
              value: footer_customization?.metadata?.policies?.terms?.link,
            })}
            name="metadata.policies.terms.link"
            placeholder="Link dos Termos de serviço "
            type="text"
          />
        </S.RoleSection>
      </S.Wrapper>
      <S.ButtonsWrapper>
        <S.CancelButton
          onClick={onCancelButtonClick}
          type="button"
          variant={EButtonVariant.SECONDARY}
        >
          Cancelar
        </S.CancelButton>
        <S.SubmitButton type="submit" isLoading={isUpdateCheckout}>
          Salvar
        </S.SubmitButton>
      </S.ButtonsWrapper>
    </S.Form>
  );
};

export default FooterOttawa;
