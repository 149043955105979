import React from 'react';

import { useListAbandonedCart } from '@store/common/AbandonedCart/ListAbandonedCartContext';
import { useAnalytics } from '@store/context/common/AnalyticsContext';

import BottomPagination from '@components/common/utils/BottomPagination';
import EmptyData from '@components/common/utils/EmptyData';
import ListSkeletonLoading from '@components/common/utils/ListSkeletonLoading';
import Header from './Header';
import Body from './Body';

import * as S from './styles';

const ListAbandonedCart: React.FC = () => {
  const {
    isEmptyState,
    isLoadingListAbandonedCart,
    page,
    totalPages,
    handlePageChange,
    abandonedCartsTotal,
  } = useListAbandonedCart();
  const { analytics } = useAnalytics();

  React.useEffect(() => {
    analytics.track('Abandoned Carts Page Viewed');
  }, [analytics]);

  if (isLoadingListAbandonedCart) {
    return <ListSkeletonLoading />;
  }

  if (isEmptyState) {
    return (
      <EmptyData
        title="Nenhum carrinho abandonado"
        description="Quando um cliente abandonar um carrinho ele será exibido aqui."
      />
    );
  }
  return (
    <S.Wrapper>
      <Header />

      <Body />

      <BottomPagination
        page={page}
        pageLimit={10}
        totalItems={abandonedCartsTotal}
        totalPages={totalPages}
        handlePageChange={handlePageChange}
      />
    </S.Wrapper>
  );
};

export default ListAbandonedCart;
