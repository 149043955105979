import { AxiosResponse } from 'axios';

import {
  ICreateSocialProofProps,
  ICreateSocialProofResponse,
  IDeleteSocialProofProps,
  IDeleteSocialProofResponse,
  IUpdateSocialProofProps,
  IUpdateSocialProofResponse,
  IListSocialProofsResponse,
  ISocialProofService,
  IInactivateSocialProofProps,
  IActivateSocialProofProps,
  IUploadSocialProofImageResponse,
  IUploadSocialProofImageProps,
} from '@domain/interfaces/hooks/services/socialProof/ISocialProofService';

import { dashboardInstance } from '@services/common/dashboardInstance';

export const useSocialProofService = (): ISocialProofService => {
  const uploadSocialProofImage = async ({
    accountId,
    checkoutId,
    payload,
  }: IUploadSocialProofImageProps): Promise<AxiosResponse<IUploadSocialProofImageResponse>> => {
    return dashboardInstance.post(
      `accounts/${accountId}/checkouts/${checkoutId}/socials/upload`,
      payload,
      {
        headers: {
          'x-zouti-account': accountId,
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  };

  const createSocialProof = async ({
    payload,
    accountId,
    checkoutId,
  }: ICreateSocialProofProps): Promise<AxiosResponse<ICreateSocialProofResponse>> => {
    return dashboardInstance.post(
      `accounts/${accountId}/checkouts/${checkoutId}/socials`,
      payload,
      {
        headers: {
          'x-zouti-account': accountId,
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  };

  const updateSocialProof = async ({
    socialProofId,
    accountId,
    checkoutId,
    payload,
  }: IUpdateSocialProofProps): Promise<AxiosResponse<IUpdateSocialProofResponse>> => {
    return dashboardInstance.put(
      `accounts/${accountId}/checkouts/${checkoutId}/socials/${socialProofId}`,
      payload,
      {
        headers: {
          'x-zouti-account': accountId,
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  };

  const deleteSocialProof = async ({
    accountId,
    checkoutId,
    socialProofId,
  }: IDeleteSocialProofProps): Promise<AxiosResponse<IDeleteSocialProofResponse>> => {
    return dashboardInstance.delete(
      `accounts/${accountId}/checkouts/${checkoutId}/socials/${socialProofId}`,
      {
        headers: {
          'x-zouti-account': accountId,
        },
      },
    );
  };

  const inactivateSocialProof = async ({
    socialProofId,
    accountId,
    checkoutId,
  }: IInactivateSocialProofProps): Promise<AxiosResponse<IUpdateSocialProofResponse>> => {
    return dashboardInstance.put(
      `accounts/${accountId}/checkouts/${checkoutId}/socials/${socialProofId}/inactivate`,
      {
        headers: {
          'x-zouti-account': accountId,
        },
      },
    );
  };

  const activateSocialProof = async ({
    socialProofId,
    accountId,
    checkoutId,
  }: IActivateSocialProofProps): Promise<AxiosResponse<IUpdateSocialProofResponse>> => {
    return dashboardInstance.put(
      `accounts/${accountId}/checkouts/${checkoutId}/socials/${socialProofId}/activate`,
      {
        headers: {
          'x-zouti-account': accountId,
        },
      },
    );
  };

  return {
    createSocialProof,
    updateSocialProof,
    deleteSocialProof,
    inactivateSocialProof,
    activateSocialProof,
    uploadSocialProofImage,
  };
};
