import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.small_24};
  height: 100%;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  padding-bottom: 24px;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
`;

export const WrapperContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.small_24};
  padding: ${({ theme }) => theme.spacing.small_24};
  background-color: ${({ theme }) => theme.colors.neutral.white};
  max-width: 1024px;
  margin: 0 auto;
`;

export const WrapperInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.small_8};
`;

export const Button = styled.div`
  display: flex;
  align-self: flex-end;
`;
