import styled from 'styled-components/macro';
import EmptyDataBase from '@components/common/utils/EmptyData';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 1024px;
  margin: 0 auto;
`;

export const NotFoundProductSearch = styled.div`
  margin: 0 auto;
  width: unset;
`;

export const EmptyData = styled(EmptyDataBase)``;
