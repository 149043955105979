import TextBase from '@components/common/DataDisplay/Text';
import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
  width: 439px;
  height: 318px;
  background-color: ${({ theme }) => theme.colors.neutral.white};
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  border-radius: 4px;
  padding: 24px;
`;

export const Title = styled(TextBase)`
  font-size: 1.6rem;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.neutral.dark};
`;

export const IconContainer = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  padding: 12px;
  justify-content: flex-end;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary.lemon_10};
  border-radius: 6px;
`;

export const CardRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FirstColumn = styled.div`
  display: flex;
  gap: 24px;
`;
export const SecondColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const CurrencyValueAndTag = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const InfoCurrencyValue = styled(TextBase)<{ isEmptyData: boolean }>`
  font-size: 1.6rem;
  color: ${({ theme, isEmptyData }) =>
    isEmptyData ? theme.colors.neutral.light : theme.colors.neutral.darker};
  font-weight: 600;
`;

export const PercentTag = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  height: 20px;
  padding: 4px 8px;
  font-family: ${({ theme }) => theme.fonts.text};
  border-radius: 4px;
  font-size: 1.2rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.positive.darker};
  background-color: ${({ theme }) => theme.colors.positive.lightest};
`;

export const InfoSubscription = styled(TextBase)`
  color: ${({ theme }) => theme.colors.neutral.dark};
`;

export const RateText = styled(TextBase)`
  color: ${({ theme }) => theme.colors.neutral.dark};
`;

export const PercentRate = styled(TextBase)`
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.neutral.darker};
  font-weight: 500;
`;

export const QuantityText = styled(TextBase)`
  color: ${({ theme }) => theme.colors.neutral.dark};
`;

export const QuantityValue = styled(TextBase)`
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.neutral.darker};
  font-weight: 600;
`;
