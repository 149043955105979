import { UserCircle, Package, Truck, Wallet } from 'phosphor-react';

export const ABANDONED_CART_STEPS = [
  {
    id: '1',
    step: 'DELIVERY',
    icon: Package,
  },
  {
    id: '2',
    step: 'PERSONAL_DATA',
    icon: UserCircle,
  },
  {
    id: '3',
    step: 'SHIPPING',
    icon: Truck,
  },
  {
    id: '4',
    step: 'PAYMENT',
    icon: Wallet,
  },
];
