import React from 'react';
import useSWR from 'swr';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { ISwr } from '@domain/interfaces/common/swr/ISwr';
import { ITransactionalEmail } from '@domain/interfaces/common/transactionalEmail/ITransactionalEmail';
import { ITransactionalEmailProvider } from '@domain/interfaces/store/common/transactionalEmail/ITransactionalEmailProvider';
import {
  IEmailsType,
  IUpdateTransactionalEmailSenderEmailData,
  IUpdateTransactionalEmailSenderNameData,
} from '@domain/interfaces/hooks/services/transactionalEmail/ITransactionaEmailService';

import { DASHBOARD_API_URL } from '@constants/services/apiUrl';
import { dashboardInstance } from '@services/common/dashboardInstance';

import { useToast } from '@store/context/common/ToastContext';
import { useErrorHandler } from '@store/context/common/ErrorHandlerContext';
import { useTransactionalEmailService } from '@hooks/services/transactionalEmail/useTransactionalEmailService';
import { useAnalytics } from '@store/context/common/AnalyticsContext';

import { transactionalEmailConfigSchema } from '@helpers/validators/pages/dashboard/transactionalEmail/transactionalEmailConfigSchema';

import { ETransactionalEmail } from '@domain/enums/common/transactionalEmail/ETransactionalEmail';

const TransactionEmailContext = React.createContext<ITransactionalEmailProvider | null>(null);

export const TransactionalEmailProvider: React.FC = ({ children }) => {
  const {
    verifyTransactionalEmail,
    updateTransactionalEmailActiveStatus,
    changeTypeStatusTransactionalEmail,
    updateTransactionalEmailSenderName,
    updateTransactionalEmailSenderEmail,
  } = useTransactionalEmailService();
  const { accountId, checkoutId } = useParams();
  const { toast } = useToast();
  const { handleError } = useErrorHandler();
  const { analytics } = useAnalytics();

  const [isVerifyingEmail, setIsVerifyingEmail] = React.useState<boolean>(false);
  const [isActiveSendEmail, setIsActiveSendEmail] = React.useState<boolean>(true);
  const [isCreatingEmail, setIsCreatingEmail] = React.useState<boolean>(false);
  const [transactionEmailData, setTransactionEmailData] = React.useState<
    ITransactionalEmail | undefined
  >(undefined);
  const [isUpdatingSenderName, setIsUpdatingSenderName] = React.useState<boolean>(false);

  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(transactionalEmailConfigSchema),
  });

  const [transactionActiveEmails, setTransactionActiveEmails] = React.useState<
    IEmailsType | undefined
  >();

  const getEmailType = (emailType: string): ETransactionalEmail => {
    const emailTypeMap: Record<string, ETransactionalEmail> = {
      WAITING_PAYMENT: ETransactionalEmail.WAITING_PAYMENT,
      CANCELED: ETransactionalEmail.CANCELED,
      IN_TRANSPORT: ETransactionalEmail.IN_TRANSPORT,
      ABANDONED_CART: ETransactionalEmail.ABANDONED_CART,
      PAYMENT_APPROVED: ETransactionalEmail.PAYMENT_APPROVED,
      PAYMENT_REFUSED: ETransactionalEmail.PAYMENT_REFUSED,
    };

    return emailTypeMap[emailType];
  };

  const changeTypeStatus = React.useCallback(
    async type_name => {
      try {
        await changeTypeStatusTransactionalEmail({ accountId, checkoutId, payload: { type_name } });

        analytics.track('Has Updated Transactional Emails');

        toast.success('Status do email transacional atualizado com sucesso');
      } catch (err) {
        handleError(err);
      }
    },
    [changeTypeStatusTransactionalEmail, handleError, toast, accountId, checkoutId, analytics],
  );

  dashboardInstance.defaults.headers.common['x-zouti-account'] = accountId;

  const TRANSACTION_EMAIL_URL = `${DASHBOARD_API_URL}/accounts/${accountId}/checkouts/${checkoutId}/transactional_mails`;

  const {
    data: transactionalData,
    isLoading: transactionalLoading,
    isValidating: transactionalValidating,
    error: transactionalError,
    mutate: transactionalMutate,
  } = useSWR<ISwr>(TRANSACTION_EMAIL_URL, dashboardInstance);

  const activeRegister = register('active');
  const emailRegister = register('email');
  const nameRegister = register('name');

  React.useEffect(() => {
    if (transactionalError) {
      handleError(transactionalError);
    }

    if (transactionalData?.data.object) {
      setTransactionEmailData(transactionalData?.data.object);
      setTransactionActiveEmails(transactionalData?.data.object.emails);

      setIsActiveSendEmail(Boolean(!transactionalData.data.object.inactivated_at));

      setValue('active', Boolean(!transactionalData.data.object.inactivated_at));
      setValue('email', transactionalData.data.object.sender_email);
      setValue('name', transactionalData.data.object.sender_name);
    }
  }, [transactionalData, handleError, transactionalError, setValue]);

  const isLoadingTransactionEmailActives = transactionalLoading || transactionalValidating;
  const isTransactionEmailActivesError = Boolean(transactionalError);

  const updateActiveStatus = React.useCallback(
    async sendEmailStatus => {
      try {
        const payload: any = {
          inactivated_at: sendEmailStatus ? '' : new Date().toISOString(),
        };

        const { data: transactionData } = await updateTransactionalEmailActiveStatus({
          accountId,
          checkoutId,
          payload,
        });

        analytics.track('Has Updated Transactional Email Active Status');

        setTransactionEmailData(transactionData.object);
      } catch (requestError) {
        handleError(requestError);
      }
    },
    [accountId, checkoutId, updateTransactionalEmailActiveStatus, handleError, analytics],
  );

  const updateSenderEmail = React.useCallback(
    async formData => {
      setIsCreatingEmail(true);

      try {
        const payload: IUpdateTransactionalEmailSenderEmailData = {
          sender_email: formData.email,
        };

        await updateTransactionalEmailSenderEmail({ accountId, checkoutId, payload });

        analytics.track('Has Updated Transactional Email Domain');

        transactionalMutate();
      } catch (requestError) {
        handleError(requestError);
      }

      setIsCreatingEmail(false);
    },
    [
      handleError,
      accountId,
      checkoutId,
      updateTransactionalEmailSenderEmail,
      transactionalMutate,
      analytics,
    ],
  );

  const updateSenderName = React.useCallback(
    async formData => {
      setIsUpdatingSenderName(true);

      try {
        const payload: IUpdateTransactionalEmailSenderNameData = {
          sender_name: formData.name,
        };

        await updateTransactionalEmailSenderName({
          accountId,
          checkoutId,
          payload,
        });

        analytics.track('Has Created Transactional Email Domain');

        transactionalMutate();
      } catch (requestError) {
        handleError(requestError);
      }

      setIsUpdatingSenderName(false);
    },
    [
      handleError,
      accountId,
      checkoutId,
      updateTransactionalEmailSenderName,
      transactionalMutate,
      analytics,
    ],
  );

  const verifyEmail = React.useCallback(async () => {
    setIsVerifyingEmail(true);

    try {
      const { data: verifyData } = await verifyTransactionalEmail({ accountId, checkoutId });

      if (verifyData.object.valid) {
        analytics.track('Has Verified Transactional Email Domain');

        await transactionalMutate();
      } else {
        toast.error('O seu e-mail ainda não está validado!');
      }

      setIsVerifyingEmail(false);
    } catch (responseError) {
      setIsVerifyingEmail(false);
      handleError(responseError);
    }
  }, [
    accountId,
    checkoutId,
    verifyTransactionalEmail,
    handleError,
    transactionalMutate,
    toast,
    analytics,
  ]);

  const isLoadingTransactionEmail = transactionalLoading || transactionalValidating;
  const isTransactionEmailError = Boolean(transactionalError);

  return (
    <TransactionEmailContext.Provider
      value={{
        transactionEmailData,
        isVerifyingEmail,
        isLoadingTransactionEmail,
        isTransactionEmailError,
        isActiveSendEmail,
        setIsActiveSendEmail,
        activeRegister,
        emailRegister,
        nameRegister,
        errors,
        handleSubmit,
        setValue,
        getValues,
        isUpdatingSenderName,
        setIsUpdatingSenderName,
        isCreatingEmail,
        verifyEmail,
        updateActiveStatus,
        updateSenderEmail,
        updateSenderName,
        transactionActiveEmails,
        isLoadingTransactionEmailActives,
        isTransactionEmailActivesError,
        getEmailType,
        changeTypeStatus,
        setTransactionActiveEmails,
      }}
    >
      {children}
    </TransactionEmailContext.Provider>
  );
};

export const useTransactionalEmail = (): ITransactionalEmailProvider => {
  const context = React.useContext(TransactionEmailContext);

  if (!context) {
    throw new Error('useTransactionalEmailConfig must be used within provider');
  }

  return context;
};
