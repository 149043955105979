import { Bank } from 'phosphor-react';
import styled from 'styled-components';

import TextBase from '@components/common/DataDisplay/Text';
import PIX from '@components/icons/Pix';

export const RecipientContainer = styled.section`
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  border-radius: 4px;
  padding: 16px;
  margin-top: 24px;
`;

export const RecipientHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ContainerIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lighter};
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.neutral.white};
`;

export const BankIcon = styled(Bank).attrs({ size: 16, weight: 'bold' })``;

export const PIXIcon = styled(PIX).attrs({
  size: '22',
  fillColor: '#282828',
})``;

export const RecipientDetails = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;
`;

export const RecipientName = styled(TextBase)`
  color: ${({ theme }) => theme.colors.neutral.darkest};
  font-size: 1.6rem;
`;

export const RecipientDocument = styled(TextBase)`
  color: ${({ theme }) => theme.colors.neutral.dark};
  font-size: 1.2rem;
  font-weight: 500;
`;

export const BankInfo = styled.div`
  margin-top: 16px;
`;

export const BankInfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;

  &:first-child {
    margin-top: 0;
  }
`;

export const BankInfoItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
`;

export const Label = styled(TextBase)`
  color: ${({ theme }) => theme.colors.neutral.dark};
  font-size: 1.6rem;
`;

export const Value = styled(TextBase)`
  color: ${({ theme }) => theme.colors.neutral.darkest};
  font-size: 1.6rem;
`;
