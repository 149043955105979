import React from 'react';

import Content from '@components/pages/Dashboard/StatementTransactions';
import { GetStatementTransactionsProvider } from '@store/common/statementTransactions/GetStatementTransactionsContext';

const StatementTransactions: React.FC = () => {
  return (
    <GetStatementTransactionsProvider>
      <Content />
    </GetStatementTransactionsProvider>
  );
};

export default StatementTransactions;
