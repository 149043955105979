import React from 'react';

import { EHeadingSize } from '@domain/enums/common/components/EHeading';
import { EHeadingWeight } from '@domain/enums/components/common/EHeading';

import { CHECKOUT_THEMES } from '@constants/common/themeCheckout';

import { useEditCheckout } from '@store/common/checkoutCustomization/EditCheckoutCustomizationContext';

import * as S from './styles';

const CurrentTheme: React.FC = () => {
  const { selectedThemeCheckout } = useEditCheckout();

  const foundTheme = CHECKOUT_THEMES.find(theme => theme.key === selectedThemeCheckout?.theme);

  const image = foundTheme?.image;
  const miniTheme = foundTheme?.miniTheme;
  const logo = foundTheme?.logo;
  const name = foundTheme?.name;
  const createdAt = selectedThemeCheckout?.created_at;

  return (
    <S.Wrapper>
      <S.Title fontWeight={EHeadingWeight.BOLD} size={EHeadingSize.H5}>
        Tema atual
      </S.Title>
      <S.Preview src={image} alt={`Preview Current theme ${selectedThemeCheckout}`} />
      <S.InfoWrapper>
        <S.ImageAndLogoWrapper>
          <S.MiniImage src={miniTheme} alt="Shopify" />
          <S.Logo src={logo} alt="Logo" />
        </S.ImageAndLogoWrapper>
        <S.TextWrapper>
          <S.NameTheme>{name}</S.NameTheme>
          <S.AddDateTheme>Adicionado em {createdAt}</S.AddDateTheme>
        </S.TextWrapper>
      </S.InfoWrapper>
    </S.Wrapper>
  );
};

export default CurrentTheme;
