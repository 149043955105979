import styled, { css } from 'styled-components';
import { IRadioProps } from '.';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Input = styled.input`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    appearance: none;
    width: 1.8rem;
    height: 1.8rem;
    border: 0.2rem solid ${theme.colors.neutral.base};
    border-radius: 50%;
    background: transparent;
    transition: background 0.1s ease-in-out;
    outline: none;
    cursor: pointer;

    &:before {
      content: '';
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      background: ${theme.colors.primary.green_lemon};
      transition: opacity 0.1s ease-in-out;
      opacity: 0;
    }

    &:checked {
      border: 0.2rem solid ${theme.colors.primary.green_lemon};
      &:before {
        opacity: 1;
      }
    }
  `}
`;

export const Label = styled.label<Pick<IRadioProps, 'labelColor'>>`
  ${({ theme }) => css`
    padding-left: 0.8rem;
    color: ${theme.colors.neutral.darkest};
    cursor: pointer;

    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 170%;
  `}
`;
