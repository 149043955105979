import React from 'react';

import PageHeader from '@components/common/utils/PageHeader';

import * as S from './styles';

const Header: React.FC = () => {
  return (
    <S.Wrapper>
      <PageHeader
        title="E-mails transacionais"
        description="Gerencie os os e-mails que seus clientes receberão."
      />
    </S.Wrapper>
  );
};

export default Header;
