import React from 'react';

import { EBankAccountType } from '@domain/interfaces/common/bankAccount/IBankAccount';

import * as S from './styles';

interface IBadgeProps {
  status: EBankAccountType;
}

const statusMapping = {
  [EBankAccountType.CHECKING]: { text: 'Corrente', mappedStatus: EBankAccountType.CHECKING },
  [EBankAccountType.SAVINGS]: { text: 'Poupança', mappedStatus: EBankAccountType.SAVINGS },
};

const Badge: React.FC<IBadgeProps> = ({ status }) => {
  const { text: badgeText, mappedStatus } = statusMapping[status] || {
    text: 'Corrente',
    mappedStatus: EBankAccountType.CHECKING,
  };

  return <S.Badge status={mappedStatus}>{badgeText}</S.Badge>;
};

export default Badge;
