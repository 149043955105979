import {
  House,
  ChartBar,
  ShoppingCartSimple,
  Tag,
  Target,
  Monitor,
  SquaresFour,
  Gear,
  CurrencyCircleDollar,
} from 'phosphor-react';
import ZoutiIcon from '@components/common/utils/ZoutiIcon';

export const MENU_OPTIONS = [
  {
    name: 'home',
    parsedName: 'Início',
    path: '/home',
    hasSubItem: false,
    icon: House,
    requiredPermissions: [''],
  },
  {
    name: 'reports',
    parsedName: 'Relatórios',
    path: '/reports',
    hasSubItem: true,
    icon: ChartBar,
    requiredPermissions: [
      'report',
      'dashboard_checkout_metrics',
      'dashboards_checkout_chart',
      'dashboards_overview',
    ],
  },
  {
    name: 'orders',
    parsedName: 'Pedidos',
    path: '/orders',
    hasSubItem: true,
    icon: ShoppingCartSimple,
    requiredPermissions: ['order', 'abandoned_cart'],
  },
  {
    name: 'products',
    parsedName: 'Produtos',
    path: '/products',
    hasSubItem: true,
    icon: Tag,
    requiredPermissions: ['product'],
  },
  // {
  //   name: 'clients',
  //   parsedName: 'Clientes',
  //   path: '/clients',
  //   hasSubItem: true,
  //   icon: UsersThree,
  // },
  {
    name: 'marketing',
    parsedName: 'Marketing',
    path: '/marketing',
    hasSubItem: true,
    icon: Target,
    requiredPermissions: ['promocode', 'upsell', 'orderbump', 'pixel', 'recovery_templates'],
  },
  {
    name: 'checkout',
    parsedName: 'Checkout',
    path: '/checkout',
    hasSubItem: true,
    icon: Monitor,
    requiredPermissions: ['checkout_customization', 'domain', 'freight', 'social'],
  },
  {
    name: 'apps',
    parsedName: 'Central de Apps',
    path: '/apps',
    hasSubItem: false,
    icon: SquaresFour,
    requiredPermissions: ['app'],
  },
  // {
  //   name: 'payment-credential',
  //   parsedName: 'Pagamento',
  //   path: '/payment-credential',
  //   hasSubItem: true,
  //   icon: ZoutiIcon,
  //   requiredPermissions: [''],
  // },
  {
    name: 'gateway',
    parsedName: 'Finanças',
    path: '/gateway',
    hasSubItem: true,
    icon: CurrencyCircleDollar,
    requiredPermissions: [''],
  },
  {
    name: 'settings',
    parsedName: 'Configurações',
    path: '/settings',
    hasSubItem: true,
    icon: Gear,
    requiredPermissions: ['business', 'transactional_mail', 'webhook'],
  },
];
