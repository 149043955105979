import React from 'react';
import { useTheme } from 'styled-components/macro';
import { ArrowUpRight } from 'phosphor-react';
import {
  ResponsiveContainer,
  AreaChart as Chart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from 'recharts';

import { useGetGeneral } from '@store/common/general/GetGeneralContext';

import { numberFormatter } from '@helpers/utils/common/numberFormatter';

import { mapNetRevenue } from '@utils/pages/dashboard/reports/netRevenue';

import ChartTooltip from './Tooltip';

import * as S from './styles';
import NetRevenueSkeleton from '../Skeletons/NetRevenue';

const NetRevenue: React.FC = () => {
  const theme = useTheme();
  const { revenueData, isLoadingRevenue } = useGetGeneral();

  const mappedData = mapNetRevenue(revenueData?.previous_revenue.chart, revenueData?.revenue.chart);
  const currentRevenue = revenueData?.revenue.value || 0;
  const previousRevenue = revenueData?.previous_revenue.value || 0;

  const getXAxisTick = React.useCallback(
    tick => {
      if (tick === mappedData[0].name || tick === mappedData[mappedData.length - 1].name)
        return tick;

      return '';
    },
    [mappedData],
  );

  const formatYAxisTick = (tick: number): string => {
    if (tick >= 1000000) {
      return `${numberFormatter(tick / 1000000, 1)}M`;
    }
    return `${tick}k`;
  };

  const isEmptyData = !currentRevenue && !previousRevenue;

  if (isLoadingRevenue) {
    return <NetRevenueSkeleton />;
  }

  return (
    <S.Wrapper>
      <S.HeaderWrapper>
        <S.RevenueInfoWrapper>
          <S.Title>Faturamento</S.Title>

          <S.RevenueValueAndPercentTagWrapper>
            <S.RevenueValue isEmptyData={isEmptyData}>{`R$ ${
              currentRevenue ? numberFormatter(currentRevenue / 100, 2) : '0,00'
            }`}</S.RevenueValue>

            {/* {!isEmptyData && (
              <S.PercentTag>
                <ArrowUpRight size={12} color={theme.colors.positive.darker} weight="bold" />
                123,3%
              </S.PercentTag>
            )} */}
          </S.RevenueValueAndPercentTagWrapper>

          <S.PreviousMonthValue>{`Período anterior: R$ ${
            previousRevenue ? numberFormatter(previousRevenue / 100, 2) : '0,00'
          }`}</S.PreviousMonthValue>
        </S.RevenueInfoWrapper>

        {!isEmptyData && (
          <S.LegendWrapper>
            <S.Legend>
              <S.DotColor color={theme.colors.primary.dark_green_40} />
              <S.LegendText>Período selecionado</S.LegendText>
            </S.Legend>

            <S.Legend>
              <S.DotColor color={theme.colors.positive.darker} />
              <S.LegendText>Período anterior</S.LegendText>
            </S.Legend>
          </S.LegendWrapper>
        )}
      </S.HeaderWrapper>

      <S.ChartWrapper>
        <ResponsiveContainer width="100%" height="100%">
          <Chart data={mappedData}>
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor={theme.colors.primary.lemon_30} stopOpacity={2} />
                <stop offset="100%" stopColor={theme.colors.primary.lemon_20} stopOpacity={0.1} />
              </linearGradient>
            </defs>

            <CartesianGrid
              strokeDasharray="10 10"
              horizontal={false}
              stroke={theme.colors.neutral.lightest}
            />

            <XAxis
              dataKey="name"
              tick={{ dy: 8 }}
              strokeDasharray="10 10"
              axisLine={{ stroke: theme.colors.neutral.lightest }}
              tickLine={false}
              tickFormatter={getXAxisTick}
            />

            <YAxis
              tick={{ dx: -10 }}
              axisLine={false}
              tickLine={false}
              stroke={theme.colors.neutral.base}
              tickFormatter={tick => formatYAxisTick(tick)}
            />

            <Tooltip content={<ChartTooltip />} />

            <Area
              type="monotone"
              dataKey="current"
              strokeWidth={2}
              stroke={isEmptyData ? 'none' : theme.colors.primary.green_lemon}
              fill="url(#colorUv)"
              name="Período selecionado"
            />

            <Area
              type="monotone"
              dataKey="past"
              strokeWidth={2}
              stroke={isEmptyData ? 'none' : theme.colors.positive.darker}
              fill="none"
              name="Período anterior"
            />
          </Chart>
        </ResponsiveContainer>

        {isEmptyData && <S.EmptyText>Nenhum dado disponível</S.EmptyText>}
      </S.ChartWrapper>
    </S.Wrapper>
  );
};

export default NetRevenue;
