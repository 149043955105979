export default {
  small_8: '8px',
  small_16: '16px',
  small_24: '24px',
  medium_32: '32px',
  medium_40: '40px',
  medium_48: '48px',
  base_56: '56px',
  base_64: '64px',
  base_72: '72px',
  large: '128px',
};
