import React from 'react';

import Content from '@components/pages/Dashboard/AccountData';
import { UpdateAccountDataProvider } from '@store/common/accountData/UpdateAccountData';

const AccountData: React.FC = () => {
  return (
    <UpdateAccountDataProvider>
      <Content />
    </UpdateAccountDataProvider>
  );
};

export default AccountData;
