import React from 'react';
import * as yup from 'yup';
import { useTheme } from 'styled-components';

import { useToast } from '@store/context/common/ToastContext';
import { useUsersContext } from '@store/common/users/UsersContext';

import * as S from './styles';

interface IInviteUserModalProps {
  isOpen: boolean;
  setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const InviteUserModal: React.FC<IInviteUserModalProps> = ({ isOpen, setIsOpenModal }) => {
  const theme = useTheme();
  const { toast } = useToast();
  const emailSchema = yup.string().email();
  const { onSubmit, inviteUserIsLoading, groups, register, handleSubmit, errors, email, setEmail } =
    useUsersContext();

  const addEmails = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if ((e.key === 'Enter' || e.key === ' ') && e.currentTarget.value.trim().length !== 0) {
      e.preventDefault();
      const newWEmail = e.currentTarget.value.trim();
      const isValidEmail = emailSchema?.isValidSync(newWEmail);
      if (isValidEmail && newWEmail !== '') {
        setEmail([...email, newWEmail]);
        e.currentTarget.value = '';
      } else {
        toast.error('Insira apenas emails válidos!');
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === ' ') {
      if (e.currentTarget.value.trim().length === 0) {
        e.preventDefault();
      }
    }

    if (e.key === 'Enter') {
      e.preventDefault();
    }

    if (e.key === 'Backspace' && e.currentTarget.value === '') {
      e.preventDefault();
      setEmail(currentEmails => currentEmails.slice(0, -1));
    }
  };

  const removeTag = (index: number): void => {
    setEmail(email.filter((el, i) => i !== index));
  };

  const handleCancelButton = (): void => {
    setIsOpenModal(state => !state);
  };

  return (
    <S.Modal
      title="Convidar novos usuários"
      toggle={() => setIsOpenModal(state => !state)}
      isOpen={isOpen}
    >
      <S.Form onSubmit={handleSubmit(onSubmit)}>
        <S.Info>
          Ao emitir convites, solicitaremos que os novos membros insiram seus dados pessoais durante
          o processo de registro.
        </S.Info>
        <S.InputGroup>
          <S.Label>Endereços de e-mail</S.Label>
          <S.TagInputContainer>
            {email.map((item, index) => (
              <S.TagItem key={index.toFixed()}>
                <S.EmailText>{item}</S.EmailText>
                <S.CloseIcon size={10} onClick={() => removeTag(index)} />
              </S.TagItem>
            ))}
            <S.Input
              isError={!!errors.email}
              {...register('emails')}
              placeholder="nome@example.com"
              onKeyUp={addEmails}
              onKeyDown={handleKeyDown}
              type="text"
            />
          </S.TagInputContainer>
          <S.IconAndTextWrapper>
            <S.InfoIcon name="info" size={16} color={theme?.colors?.neutral?.darkest} />
            <S.HintText>Pressione Enter ou Espaço para adicionar um e-mail</S.HintText>
          </S.IconAndTextWrapper>
          {errors.emails && <S.MessageError>{errors.emails.message}</S.MessageError>}
        </S.InputGroup>

        <S.InputGroup>
          <S.Label>Membros do grupo</S.Label>
          <S.Select {...register('membership_group_id')}>
            {groups.map((group, index) => (
              <S.Option value={group.id} key={index.toFixed()}>
                {group.name}
              </S.Option>
            ))}
          </S.Select>
          {errors.group && <S.MessageError>{errors.group.message}</S.MessageError>}
        </S.InputGroup>
        <S.ModalAction>
          <S.CancelButton type="button" onClick={handleCancelButton}>
            Cancelar
          </S.CancelButton>
          <S.SaveButton isLoading={inviteUserIsLoading} type="submit">
            Convidar usuários
          </S.SaveButton>
        </S.ModalAction>
      </S.Form>
    </S.Modal>
  );
};

export default InviteUserModal;
