import { AxiosResponse } from 'axios';

import {
  IGenerateVariantCheckoutLinkProps,
  IListVariantsProps,
  IListVariantsResponse,
  IVariantService,
  IGenerateVariantCheckoutLinkResponse,
} from '@domain/interfaces/hooks/services/variant/IVariantService';

import { dashboardInstance } from '@services/common/dashboardInstance';

export const useVariantService = (): IVariantService => {
  const listVariants = async ({
    accountId,
    checkoutId,
    productId,
  }: IListVariantsProps): Promise<AxiosResponse<IListVariantsResponse>> => {
    return dashboardInstance.get(
      `accounts/${accountId}/checkouts/${checkoutId}/products/${productId}/variants`,
      {
        headers: {
          'x-zouti-account': accountId,
        },
      },
    );
  };

  const generateVariantCheckoutLink = async ({
    accountId,
    checkoutId,
    productId,
    variantId,
  }: IGenerateVariantCheckoutLinkProps): Promise<
    AxiosResponse<IGenerateVariantCheckoutLinkResponse>
  > => {
    return dashboardInstance.post(
      `accounts/${accountId}/checkouts/${checkoutId}/products/${productId}/variants/${variantId}/generate_url`,
      {},
      {
        headers: {
          'x-zouti-account': accountId,
        },
      },
    );
  };

  return { listVariants, generateVariantCheckoutLink };
};
