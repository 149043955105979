import { AxiosResponse } from 'axios';

import {
  IUpdateOnboardingProps,
  IUserOnboardingService,
} from '@domain/interfaces/hooks/services/userOnboarding/IUserOnboardingService';

import { dashboardInstance } from '@services/common/dashboardInstance';

export const useUserOnboardingService = (): IUserOnboardingService => {
  const updateOnboarding = async ({
    payload,
  }: IUpdateOnboardingProps): Promise<AxiosResponse<void>> => {
    return dashboardInstance.post(`/users/me/onboarding`, { ...payload });
  };

  return { updateOnboarding };
};
