import styled from 'styled-components/macro';

import ButtonBase from '@components/common/Inputs/Button';
import CheckboxBase from '@components/common/Inputs/Checkbox';
import HeadingBase from '@components/common/DataDisplay/Heading';
import LabelBase from '@components/common/DataDisplay/Label';

export const Form = styled.form``;

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.neutral.white};
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  border-radius: 4px;
  padding: 24px;
`;

export const Divider = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.neutral.lightest};
  margin: 16px 0 24px 0;
`;

export const Title = styled(HeadingBase)`
  color: ${({ theme }) => theme.colors.neutral.darkest};
`;

export const ColorSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const InputColorWrapper = styled.div`
  display: flex;
  gap: 16px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const RoleSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Checkbox = styled(CheckboxBase)``;

export const CheckboxLabel = styled(LabelBase)`
  margin: 0;
  cursor: pointer;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.neutral.darkest};
`;

export const CheckboxGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 24px;
  gap: 16px;
`;

export const SubmitButton = styled(ButtonBase)``;

export const CancelButton = styled(ButtonBase)`
  & > span {
    color: ${({ theme }) => theme.colors.neutral.darkest};
  }
`;
