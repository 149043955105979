import React from 'react';

import { EButtonVariant } from '@domain/enums/components/common/EButton';
import * as S from './styles';

interface ISaveHeaderbarProps {
  onCancelClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onSubmitClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  isLoading?: boolean;
}

const SaveHeaderbar: React.FC<ISaveHeaderbarProps> = ({
  isLoading,
  onCancelClick,
  onSubmitClick,
}) => {
  return (
    <S.SaveHeaderBar>
      <S.CancelButton variant={EButtonVariant.SECONDARY} onClick={onCancelClick}>
        Cancelar
      </S.CancelButton>
      <S.SubmitButton type="submit" onClick={onSubmitClick} isLoading={isLoading}>
        Salvar
      </S.SubmitButton>
    </S.SaveHeaderBar>
  );
};

export default SaveHeaderbar;
