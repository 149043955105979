import React from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import EmptyData from '@components/common/utils/EmptyData';

import { useUpdatePixel } from '@store/common/pixel/UpdatePixelContext';
import SkeletonLoading from './SkeletonLoading';

import Header from './Header';
import Body from './Body';

import * as S from './styles';

const Pixel: React.FC = () => {
  const { isLoadingPixel, isPixelError } = useUpdatePixel();
  const { accountId, checkoutId } = useParams();
  const isEmpty = false;

  const navigate = useNavigate();

  const onEmptyDataButtonClick = (): void => {
    navigate(`/${accountId}/${checkoutId}/dashboard/marketing/pixels/new`);
  };

  if (isLoadingPixel) {
    return <SkeletonLoading />;
  }

  if (isPixelError && !isLoadingPixel) {
    return <div>Error...</div>;
  }

  if (isEmpty) {
    return (
      <EmptyData
        title="Nenhum pixel cadastrado"
        description="Adicione pixels das principais plataformas para monitorar sua loja"
        buttonText="Novo pixel"
        onClick={onEmptyDataButtonClick}
      />
    );
  }

  return (
    <S.Wrapper>
      <Header />
      <Body />
    </S.Wrapper>
  );
};

export default Pixel;
