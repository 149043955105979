import React from 'react';
import { WarningOctagon } from 'phosphor-react';
import { useTheme } from 'styled-components/macro';

import { ETypographySize } from '@domain/enums/components/common/ETypography';

import * as S from './styles';

interface IDeleteModalProps {
  isOpen: boolean;
  toggle: () => void;
  title: string;
  isSuccessButtonLoading?: boolean;
  contentText?: string;
  handleDelete: () => void;
}

const DeleteModal: React.FC<IDeleteModalProps> = ({
  isOpen,
  toggle,
  title,
  isSuccessButtonLoading,
  contentText,
  handleDelete,
}) => {
  const theme = useTheme();

  return (
    <S.Modal
      onSuccess={handleDelete}
      isOpen={isOpen}
      toggle={toggle}
      icon={<WarningOctagon size={24} weight="fill" color={theme?.colors.negative.base} />}
      title={title}
      cancelText="Cancelar"
      successButtonType="submit"
      colorSuccessButton={theme?.colors.negative.base}
      colorSuccessButtonText={theme?.colors.neutral.white}
      successText="Sim, excluir"
      isSuccessButtonLoading={isSuccessButtonLoading}
    >
      <S.Content size={ETypographySize.h6_button_medium} weight="500">
        {contentText}
      </S.Content>
    </S.Modal>
  );
};

export default DeleteModal;
