import * as yup from 'yup';

const maxSize = 5 * 1024 * 1024;
const validFileExtensions = ['jpg', 'png', 'jpeg', 'webp'];

const headerCheckoutCustomizationSchema = yup.object().shape({
  background_hex_color: yup.string().nonNullable(),
  name_hex_color: yup.string().nonNullable(),
  secure_hex_color: yup.string().nonNullable(),
  show_timer: yup.boolean().nonNullable(),
  timer_amount: yup.number().nonNullable(),
  timer_unit_of_time: yup.string().oneOf(['MINUTES']).nonNullable(),
  timer_background_hex_color: yup.string().nonNullable(),
  timer_text_hex_color: yup.string().nonNullable(),
  timer_text: yup.string().nonNullable(),
  logo: yup.mixed(),
  // .mixed()
  // .test('file-type', 'Tipo de arquivo inválido! Tipos válidos: jpg, png, jpeg, webp.', value => {
  //   if (value instanceof File) {
  //     console.log('schema', value);
  //     const fileExtension = value.name.split('.').pop()?.toLowerCase();
  //     return fileExtension ? validFileExtensions.includes(fileExtension) : false;
  //   }
  //   return false;
  // })
  // .test('file-size', 'Tamanho da foto não pode ser maior que 5MB!', value => {
  //   if (value instanceof File) {
  //     return value.size <= maxSize;
  //   }
  //   return false;
  // }),
});

export default headerCheckoutCustomizationSchema;
