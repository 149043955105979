import { AxiosResponse } from 'axios';

import {
  IListPromocodesResponse,
  IGetPromocodeResponse,
  IGetPromocodeProps,
  ICreatePromocodeProps,
  ICreatePromocodeResponse,
  IUpdatePromocodeProps,
  IUpdatePromocodeResponse,
  IDeletePromocodeProps,
  IDeletePromocodeResponse,
  IPromocodeService,
  IInactivatePromocodeProps,
  IInactivatePromocodeResponse,
  IActivatePromocodeProps,
} from '@domain/interfaces/hooks/services/promocode/IPromocodeService';

import { dashboardInstance } from '@services/common/dashboardInstance';

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const usePromocodeService = (): IPromocodeService => {
  const listPromocodes = async (): Promise<AxiosResponse<IListPromocodesResponse>> => {
    return dashboardInstance.get('accounts/:accountId/promocodes');
  };

  const getPromocode = async ({
    promocodeId,
    accountId,
  }: IGetPromocodeProps): Promise<AxiosResponse<IGetPromocodeResponse>> => {
    return dashboardInstance.get(`promocodes/${promocodeId}`, {
      headers: {
        'x-zouti-account': accountId,
      },
    });
  };

  const createPromocode = async ({
    payload,
    accountId,
    checkoutId,
  }: ICreatePromocodeProps): Promise<AxiosResponse<ICreatePromocodeResponse>> => {
    return dashboardInstance.post(
      `/accounts/${accountId}/checkouts/${checkoutId}/promocodes`,
      payload,
      {
        headers: {
          'x-zouti-account': accountId,
          user_timezone: timezone,
        },
      },
    );
  };

  const updatePromocode = async ({
    promocodeId,
    payload,
    accountId,
    checkoutId,
  }: IUpdatePromocodeProps): Promise<AxiosResponse<IUpdatePromocodeResponse>> => {
    return dashboardInstance.put(
      `accounts/${accountId}/checkouts/${checkoutId}/promocodes/${promocodeId}`,
      { ...payload },
      {
        headers: {
          'x-zouti-account': accountId,
          user_timezone: timezone,
        },
      },
    );
  };

  const deletePromocode = async ({
    promocodeId,
    accountId,
    checkoutId,
  }: IDeletePromocodeProps): Promise<AxiosResponse<IDeletePromocodeResponse>> => {
    return dashboardInstance.delete(
      `accounts/${accountId}/checkouts/${checkoutId}/promocodes/${promocodeId}`,
      {
        headers: {
          'x-zouti-account': accountId,
          user_timezone: timezone,
        },
      },
    );
  };

  const activatePromocode = async ({
    promocodeId,
    accountId,
    checkoutId,
  }: IActivatePromocodeProps): Promise<AxiosResponse<IUpdatePromocodeResponse>> => {
    return dashboardInstance.put(
      `accounts/${accountId}/checkouts/${checkoutId}/promocodes/${promocodeId}/activate`,
      {
        headers: {
          'x-zouti-account': accountId,
          user_timezone: timezone,
        },
      },
    );
  };

  const inactivatePromocode = async ({
    promocodeId,
    accountId,
    checkoutId,
  }: IInactivatePromocodeProps): Promise<AxiosResponse<IInactivatePromocodeResponse>> => {
    return dashboardInstance.put(
      `accounts/${accountId}/checkouts/${checkoutId}/promocodes/${promocodeId}/inactivate`,
      {
        headers: {
          'x-zouti-account': accountId,
          user_timezone: timezone,
        },
      },
    );
  };

  return {
    listPromocodes,
    getPromocode,
    createPromocode,
    updatePromocode,
    deletePromocode,
    inactivatePromocode,
    activatePromocode,
  };
};
