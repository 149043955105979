import React from 'react';

import Content from '@components/pages/Dashboard/Upsell/EditUpsell';
import { GetProductProvider } from '@store/common/product/GetProductContext';
import { UpdateUpsellProvider } from '@store/common/upsell/UpdateUpsellContext';

const EditUpsell: React.FC = () => {
  return (
    <GetProductProvider>
      <UpdateUpsellProvider>
        <Content />
      </UpdateUpsellProvider>
    </GetProductProvider>
  );
};

export default EditUpsell;
