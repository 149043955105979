import React from 'react';

import Content from '@components/pages/Dashboard/Groups/EditGroup';
import { EditGroupsProvider } from '@store/common/Group/EditGroupsContext';

const EditGroups: React.FC = () => {
  return (
    <EditGroupsProvider>
      <Content />
    </EditGroupsProvider>
  );
};

export default EditGroups;
