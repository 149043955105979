import React from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { useGetSocialProof } from '@store/common/socialProof/GetSocialProofContext';
import { useTheme } from 'styled-components';
import { PencilSimple, Trash } from 'phosphor-react';
import * as S from './styles';

type SocialProps = {
  id: string;
  name: string;
};
interface IProps {
  items: SocialProps;
}
const CardItem: React.FC<IProps> = ({ items }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { accountId, checkoutId } = useParams();
  const { deleteCurrentSocialProof, inactivateCurrentSocialProof, activateCurrentSocialProof } =
    useGetSocialProof();

  const [isVisibleActions, setIsVisibleActions] = React.useState<boolean>(false);

  const isInactivated = Object.prototype.hasOwnProperty.call(items, 'inactivated_at');

  const onRowMouseEnter = React.useCallback(() => {
    setIsVisibleActions(true);
  }, []);

  const onRowMouseLeave = React.useCallback(() => {
    setIsVisibleActions(false);
  }, []);

  const onSwitchChange = React.useCallback(async () => {
    if (isInactivated) {
      await activateCurrentSocialProof(items.id);
    } else {
      await inactivateCurrentSocialProof(items.id);
    }
  }, [isInactivated, activateCurrentSocialProof, inactivateCurrentSocialProof, items]);

  const onEditButtonClick = (): void => {
    navigate(`/${accountId}/${checkoutId}/dashboard/checkout/social-proof/${items.id}`);
  };

  return (
    <S.Wrapper onMouseEnter={onRowMouseEnter} onMouseLeave={onRowMouseLeave}>
      <S.Switch id={items.id} name={items.id} checked={!isInactivated} onChange={onSwitchChange} />
      <S.CustomerName>{items.name}</S.CustomerName>
      {isVisibleActions ? (
        <S.ButtonActionsWrapper>
          <S.WrapperIcon>
            <PencilSimple
              onClick={onEditButtonClick}
              size={18}
              color={theme.colors.neutral.darker}
              weight="bold"
            />
          </S.WrapperIcon>
          <S.WrapperIcon>
            <Trash
              onClick={() => deleteCurrentSocialProof(items.id)}
              size={18}
              color={theme.colors.neutral.darker}
              weight="bold"
            />
          </S.WrapperIcon>
        </S.ButtonActionsWrapper>
      ) : (
        <S.ButtonActionsWrapper />
      )}
    </S.Wrapper>
  );
};

export default CardItem;
