import React from 'react';

import { EHeadingSize } from '@domain/enums/common/components/EHeading';

import { useResetPassword } from '@store/context/public/ResetPassword/ResetPasswordContext';

import logo from '@assets/images/common/logo.svg';
import logoIcon from '@assets/images/common/logoIcon.svg';

import Form from '../Form';
import SuccessWrapper from '../SuccessWrapper';

import * as S from './styles';

const Wrapper: React.FC = () => {
  const imgBackground = 'https://zouti-core-media.s3.amazonaws.com/dashboard/auth/reset.png';

  const { showSuccessMessage } = useResetPassword();

  return (
    <S.Container>
      <S.ImageBox image={imgBackground}>
        <S.InfoWrapper align="end">
          <S.BadgeCategory>
            <img src={logoIcon} alt="" height="14px" /> Secure FinTech Solution
          </S.BadgeCategory>
          <S.Text>
            Checkout e gateway integrados em uma plataforma inovadora, transparente e humanizada.
          </S.Text>
        </S.InfoWrapper>
      </S.ImageBox>

      {showSuccessMessage ? (
        <SuccessWrapper />
      ) : (
        <S.Wrapper>
          <S.Logo src={logo} alt="Zouti" />

          <S.Title size={EHeadingSize.H5}>
            <b>Crie uma nova senha</b>
          </S.Title>

          <S.Text>
            Por questões de segurança, redefina sua senha antes de acessar a plataforma.
          </S.Text>

          <S.Body>
            <Form />
          </S.Body>
        </S.Wrapper>
      )}
    </S.Container>
  );
};

export default Wrapper;
