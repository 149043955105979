import React from 'react';
import { Controller } from 'react-hook-form';

import { accordions } from '@constants/pages/dashboard/Groups';

import { useEditGroupsContext } from '@store/common/Group/EditGroupsContext';

import * as S from './styles';

const PermissionAccordion: React.FC = () => {
  const { control, group, setGroup } = useEditGroupsContext();
  const [openIndexes, setOpenIndexes] = React.useState<boolean[]>([false, false]);

  const toggle = (index: number): void => {
    setOpenIndexes(state => {
      const newIndexes = [...state];
      newIndexes[index] = !newIndexes[index];
      return newIndexes;
    });
  };

  const handleCheckboxChange = (isChecked: boolean, scopeKey: string): void => {
    let updatedScopes = [...group.scopes];

    if (isChecked) {
      if (!updatedScopes?.includes(scopeKey)) {
        updatedScopes.push(scopeKey);
      }
    } else {
      updatedScopes = updatedScopes.filter(scope => scope !== scopeKey);
    }

    setGroup(prevGroup => ({ ...prevGroup, scopes: updatedScopes }));
  };

  return (
    <S.Wrapper>
      <S.HeaderAccordion>
        <S.TextHeaderCard>Permissões</S.TextHeaderCard>
        <S.TextActionsHeaderWrapper>
          <S.TextHeaderCard>Editar</S.TextHeaderCard>
          <S.TextHeaderCard>Visualizar</S.TextHeaderCard>
        </S.TextActionsHeaderWrapper>
      </S.HeaderAccordion>
      {accordions.map((accordion, index) => {
        const Icon = accordion.icon;
        const CaretIcon = openIndexes[index] ? S.CaretTopIcon : S.CaretDownIcon;
        return (
          <>
            <S.Accordion
              currentIndex={index}
              activeIndex={openIndexes[index] ? index : null}
              type="button"
              key={index.toFixed()}
              onClick={() => toggle(index)}
            >
              <S.IconAndTitleWrapper>
                <Icon size={14} weight="bold" />
                <S.AccordionTitle>{accordion.title}</S.AccordionTitle>
              </S.IconAndTitleWrapper>
              <CaretIcon />
            </S.Accordion>
            {accordion.content.map((content, i) => (
              <S.Panel
                currentIndex={index}
                activeIndex={openIndexes[index] ? index : null}
                key={i.toFixed()}
              >
                <S.PanelContent>
                  <S.TextItemAccordionContent>{content.text}</S.TextItemAccordionContent>
                  <S.CheckboxGroup>
                    <Controller
                      control={control}
                      name={`${content.key}:write`}
                      render={({ field }) => (
                        <S.Checkbox
                          id={`${content.key}:write`}
                          checked={group?.scopes?.includes(`${content.key}:write`)}
                          onChange={e => {
                            field.onChange(e.target.checked);
                            handleCheckboxChange(e.target.checked, `${content.key}:write`);
                          }}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name={`${content.key}:read`}
                      render={({ field }) => (
                        <S.Checkbox
                          id={`${content.key}:read`}
                          checked={group?.scopes?.includes(`${content.key}:read`)}
                          onChange={e => {
                            field.onChange(e.target.checked);
                            handleCheckboxChange(e.target.checked, `${content.key}:read`);
                          }}
                        />
                      )}
                    />
                  </S.CheckboxGroup>
                </S.PanelContent>
              </S.Panel>
            ))}
          </>
        );
      })}
    </S.Wrapper>
  );
};

export default PermissionAccordion;
