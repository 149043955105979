import TextBase from '@components/common/DataDisplay/Text';
import styled from 'styled-components';

export const PlatformIcon = styled.img`
  width: 24px;
  height: 24px;
`;

export const PlatformName = styled(TextBase)`
  font-size: 1.4rem;
`;

export const PlatformOption = styled.span`
  align-items: 8px;
  background: unset;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 16px;
  transition: all 0.3s;
  width: 100%;

  &:hover {
    background-color: ${({ theme }) => `${theme.colors.primary.lightest}`};
  }
`;
