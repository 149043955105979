import * as yup from 'yup';

const footerCheckoutCustomizationSchema = yup.object().shape({
  footer_hex_color: yup.string().nonNullable(),
  text_hex_color: yup.string().nonNullable(),
  show_security_seal: yup.boolean().nonNullable(),
  show_account_name: yup.boolean().nonNullable(),
  show_account_address: yup.boolean().nonNullable(),
  show_account_cnpj_or_cpf: yup.boolean().nonNullable(),
  show_account_email: yup.boolean().nonNullable(),
  metadata: yup.object().shape({
    link_hex_color: yup.string().nonNullable(),
    policies: yup.object().shape({
      devolution: yup.object().shape({
        enabled: yup.boolean().nonNullable(),
        link: yup.string().nonNullable(),
      }),
      privacy: yup.object().shape({
        enabled: yup.boolean().nonNullable(),
        link: yup.string().nonNullable(),
      }),
      terms: yup.object().shape({
        enabled: yup.boolean().nonNullable(),
        link: yup.string().nonNullable(),
      }),
    }),
  }),
});

export default footerCheckoutCustomizationSchema;
