import LabelBase from '@components/common/DataDisplay/Label';
import { Desktop, DeviceMobile } from 'phosphor-react';
import styled from 'styled-components';

interface IPreviewColorProps {
  color?: string;
}

export const Label = styled(LabelBase)``;

export const DesktopIcon = styled(Desktop)`
  margin-right: 10px;

  &:hover {
    background-color: #fff;
  }
  cursor: pointer;
  border-radius: 4px;
  padding: 4px;
`;

export const MobileIcon = styled(DeviceMobile)`
  &:hover {
    background-color: #fff2dc;
  }
  cursor: pointer;
  border-radius: 4px;
  padding: 4px;
`;

export const TitleArea = styled.div`
  display: flex;
  width: 100%;

  & > h2 {
    margin-top: 10px;
    width: 80%;
  }
`;

export const TitleAreaText = styled.div`
  display: flex;
  width: 85%;

  h2 {
    color: ${({ theme }) => theme.colors.neutral.darker};
  }
`;

export const IconsArea = styled.div`
  display: flex;
  width: 20%;
  justify-content: flex-end;

  & > h2 {
    margin-top: 10px;
    width: 80%;
  }
`;

export const PreviewModel = styled.div`
  display: flex;
  align-content: center;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  width: 100%;
  max-width: 480px;
  height: fit-content;
  background-color: white;
  margin-left: 20px;
  padding: 20px;
  font-family: Inter, 'sans-serif';
  color: #d5d5d5;
  margin-bottom: 8px;
  border-radius: 4px;

  & > h2 {
    margin-top: 10px;
    width: 80%;
  }
`;

export const PreviewDataShow = styled.div<IPreviewColorProps>`
  display: flex;
  height: fit-content;
  align-content: center;
  align-items: center;
  flex-direction: column;
  border: 2px dashed ${({ color }) => color || '#3F198C'};
  border-radius: 4px;
  width: 100%;
  padding: 24px;
  margin-top: 30px;
  background-color: ${({ color }) => (color ? `${color}0D` : '#3F198C0D')};
`;

export const PreviewHead = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #b0aead;
  width: 100%;
  margin-bottom: 16px;
`;

export const TitleHead = styled.h3<IPreviewColorProps>`
  font-family: 'Inter', 'sans-serif';
  font-size: 1.6rem;
  font-weight: 600;
  color: ${({ color }) => color};
`;

export const SubTitleHead = styled.p<IPreviewColorProps>`
  font-family: 'Inter', 'sans-serif';
  color: ${({ theme }) => theme.colors.neutral.darkest};
  text-align: center;
  line-height: 16px;
  font-size: 1.4rem;
  margin-top: 12px;
  margin-bottom: 10px;
  font-weight: 500;
  color: ${({ color }) => color};
`;

export const ProductName = styled.h4<IPreviewColorProps>`
  font-family: 'Inter', 'sans-serif';
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 150%;
  color: ${({ color }) => color};
`;

export const PreviewBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
`;

export const Select = styled.select`
  width: fit-content;
  height: 44px;
  padding: 10px 16px;
  border-radius: 4px;
  border: 1px solid #e3e3e3;
  background-color: #ffffff;
  color: #515151;
  font-size: 14px;
  line-height: 170%;
`;

export const Option = styled.option``;

export const PreviewProduct = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

export const ImageProduct = styled.img`
  min-width: 50px;
  max-width: 50px;
  min-height: 50px;
  max-height: 50px;
  border-radius: 4px;
  border: 1px solid #e3e3e3;
`;

export const ProductInfo = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 50px;
  padding-top: 0;
  font-size: 12px;
  margin-bottom: 16px;
`;

export const Priced = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 9px;
`;

export const PriceProduct = styled.span<IPreviewColorProps>`
  font-family: 'Inter', 'sans-serif';
  text-decoration: line-through;
  color: ${({ color }) => color};
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 170%;
`;

export const EndPrice = styled.span<IPreviewColorProps>`
  font-family: 'Inter', 'sans-serif';
  color: ${({ color }) => color};
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 170%;
`;

export const EndPreview = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 60%;
  margin-top: 10px;
  padding: 10px;
`;

export const InfoProductDetails = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-direction: column;
  width: 100%;
  margin-left: 10px;
  gap: 6px;
`;

export const CtaAreaPreview = styled.label<IPreviewColorProps>`
  display: flex;
  width: 100%;
  height: 37px;
  background: ${({ color }) => `${color}1A`};
  border: 1px solid ${({ color }) => color};
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  margin-top: 16px;
  cursor: pointer;
`;

export const Checkbox = styled.input``;

export const CtaText = styled.p<IPreviewColorProps>`
  width: 100%;
  margin-left: 10px;
  font-weight: 500;
  font-size: 1.4rem;
  color: ${({ color }) => color};
`;
