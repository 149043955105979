import React from 'react';

import { useEditCheckout } from '@store/common/checkoutCustomization/EditCheckoutCustomizationContext';
import Header from './Header';
import Body from './Body';

import * as S from './styles';
import SkeletonLoading from './SkeletonLoading';

const CustomCheckout: React.FC = () => {
  const { isLoadingCheckoutCustomization, checkoutCustomization } = useEditCheckout();

  if (isLoadingCheckoutCustomization || !checkoutCustomization?.name) {
    return <SkeletonLoading />;
  }

  return (
    <S.Wrapper>
      <Header />
      <Body />
    </S.Wrapper>
  );
};

export default CustomCheckout;
