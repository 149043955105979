import React from 'react';

export const useLocalStorage = (key: string, initialValue: any): any => {
  const [storedValue, setStoredValue] = React.useState<any>(() => {
    const value = localStorage.getItem(key);

    if (value) {
      return JSON.parse(value);
    }

    return initialValue;
  });

  React.useEffect(() => {
    localStorage.setItem(key, JSON.stringify(storedValue));
  }, [storedValue, key]);

  return [storedValue, setStoredValue];
};
