import React from 'react';
import { useTheme } from 'styled-components/macro';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { CreditCard } from 'phosphor-react';

import { EButtonVariant } from '@domain/enums/components/common/EButton';
import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/common/EHeading';
import { ICreateCheckoutCustomizationData } from '@domain/interfaces/hooks/services/checkoutCustomization/ICheckoutCustomizationService';

import { useNewCheckout } from '@store/common/checkoutCustomization/NewCheckoutCustomizationContext';
import configurationCheckoutCustomizationSchema from '@helpers/validators/pages/dashboard/checkoutCustomizations/configurationCheckoutCustomizationSchema';

import Tooltip from '@components/pages/Dashboard/CheckoutCustomization/Tooltip';
import * as S from './styles';

const ConfigurationOttawa: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { accountId, checkoutId } = useParams();
  const {
    setCheckoutCustomizationData,
    checkoutCustomizationData,
    createNewCheckout,
    isCreatingCheckout,
    selectedThemeCheckout,
  } = useNewCheckout();
  const {
    header_customization,
    footer_customization,
    appearance_customization,
    advanced_customization,
  } = checkoutCustomizationData;

  const {
    register,
    reset,
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm<ICreateCheckoutCustomizationData>({
    resolver: yupResolver(configurationCheckoutCustomizationSchema),
  });

  const onSubmit = async (formData: FieldValues): Promise<void> => {
    const data: ICreateCheckoutCustomizationData = {
      name: formData.name,
      allow_credit_card: false,
      allow_pix: false,
      allow_boleto: false,
      credit_card_discount_amount: formData.credit_card_discount_amount,
      pix_discount_amount: 0,
      boleto_discount_amount: 0,
      credit_card_discount_type: 'PERCENTAGE',
      pix_discount_type: 'PERCENTAGE',
      boleto_discount_type: 'PERCENTAGE',
      enable_itens_quantity_change: false,
      show_coupon_input: formData.show_coupon_input,
      show_time_shipping_estimate: false,
      header_customization: { ...header_customization },
      footer_customization: { ...footer_customization },
      appearance_customization: { ...appearance_customization },
      advanced_customization: { ...advanced_customization },
      checkout_theme: { theme: selectedThemeCheckout },
    };

    await createNewCheckout({ payload: data });
  };

  const onCancelButtonClick = (): void => {
    reset();
    navigate(`/${accountId}/${checkoutId}/dashboard/checkout/customizations`);
  };

  React.useEffect(() => {
    return () => {
      const fieldValues = watch();
      setCheckoutCustomizationData(prevData => {
        return {
          ...prevData,
          name: fieldValues.name,
          allow_credit_card: fieldValues.allow_credit_card,
          allow_pix: fieldValues.allow_pix,
          allow_boleto: fieldValues.allow_boleto,
          credit_card_discount_amount: fieldValues.credit_card_discount_amount,
          pix_discount_amount: fieldValues.pix_discount_amount,
          boleto_discount_amount: fieldValues.boleto_discount_amount,
          credit_card_discount_type: 'PERCENTAGE',
          pix_discount_type: 'PERCENTAGE',
          boleto_discount_type: 'PERCENTAGE',
          inactivated_at: '',
          enable_itens_quantity_change: false,
          show_coupon_input: fieldValues.show_coupon_input,
          show_time_shipping_estimate: fieldValues.show_time_shipping_estimate,
        };
      });
    };
  }, [setCheckoutCustomizationData, watch]);

  register('allow_boleto', { value: checkoutCustomizationData?.allow_boleto });
  register('allow_credit_card', { value: checkoutCustomizationData?.allow_credit_card });
  register('allow_pix', { value: checkoutCustomizationData?.allow_pix });
  register('pix_discount_amount', { value: checkoutCustomizationData?.pix_discount_amount });
  register('boleto_discount_amount', { value: checkoutCustomizationData?.boleto_discount_amount });
  register('show_time_shipping_estimate', {
    value: checkoutCustomizationData?.show_time_shipping_estimate,
  });
  register('enable_itens_quantity_change', { value: false });

  return (
    <S.Form onSubmit={handleSubmit(onSubmit)}>
      <S.Wrapper>
        <S.NameSection>
          <S.Title size={EHeadingSize.H4} fontWeight={EHeadingWeight.SEMI_BOLD}>
            Nome
          </S.Title>
          <S.InputGroup>
            <S.Label>Nome da customização</S.Label>
            <S.Input
              defaultValue={checkoutCustomizationData?.name}
              isError={!!errors?.name}
              {...register('name')}
              placeholder="Digite um nome"
              type="text"
            />
            {errors?.name && <S.MessageError>{errors?.name?.message}</S.MessageError>}
            {/* <Tooltip
              iconColor={theme.colors.neutral.dark}
              iconWeight="regular"
              url="https://images.freeimages.com/images/small-previews/aed/three-bees-on-sunflower-1337029.jpg"
            > */}
            <S.TextInfo>Essa informação é privada e apenas você pode acessá-la.</S.TextInfo>
            {/* </Tooltip> */}
          </S.InputGroup>
        </S.NameSection>
        <S.Divider />
        <S.DiscountSection>
          <S.Title size={EHeadingSize.H4} fontWeight={EHeadingWeight.SEMI_BOLD}>
            Descontos
          </S.Title>
          <S.InputWrapper>
            <S.ValueInputWrapperCouple>
              <S.LabelAndIcon>
                <CreditCard weight="bold" size={20} color={theme.colors.neutral.darker} />
                <S.Label>Cartão de crédito</S.Label>
              </S.LabelAndIcon>
              <S.ValueWrapperCouple>
                <S.CurrencyLabel>%</S.CurrencyLabel>
                <S.Input
                  defaultValue={checkoutCustomizationData?.credit_card_discount_amount}
                  {...register('credit_card_discount_amount')}
                  placeholder="Digite um valor"
                  type="text"
                />
              </S.ValueWrapperCouple>
            </S.ValueInputWrapperCouple>
          </S.InputWrapper>
        </S.DiscountSection>
        <S.Divider />
        <S.FinalOptionsWrapper>
          <S.CheckboxGroup>
            <Controller
              control={control}
              name="show_coupon_input"
              defaultValue={checkoutCustomizationData?.show_coupon_input}
              render={({ field }) => (
                <S.Checkbox
                  id="show_coupon_input"
                  checked={checkoutCustomizationData?.show_coupon_input}
                  value={field?.value?.toString()}
                  onChange={() => field?.onChange(!field.value)}
                />
              )}
            />
            <S.CheckboxLabel htmlFor="show_coupon_input">
              Exibir campo de cupom de desconto
            </S.CheckboxLabel>
          </S.CheckboxGroup>
          {/* <S.CheckboxGroup>
            <Controller
              control={control}
              name="enable_itens_quantity_change"
              defaultValue={checkoutCustomizationData?.enable_itens_quantity_change}
              render={({ field }) => (
                <S.Checkbox
                  id="enable_itens_quantity_change"
                  checked={checkoutCustomizationData?.enable_itens_quantity_change}
                  value={field?.value?.toString()}
                  onChange={() => field?.onChange(!field.value)}
                />
              )}
            />
            <S.Tooltip>
              <S.TooltipContent>
                <S.TooltipText>
                  O tema Ottawa não permite a mudança da quantidade de itens.
                </S.TooltipText>
                <S.Tooltip.Arrow />
              </S.TooltipContent>
              <S.CheckboxLabel htmlFor="enable_itens_quantity_change">
                Permitir mudança de quantidade dos itens
              </S.CheckboxLabel>
              <S.TooltipTrigger>
                <S.InfoIcon weight="fill" size={16} color={theme.colors.neutral.light} />
              </S.TooltipTrigger>
            </S.Tooltip>
          </S.CheckboxGroup> */}
        </S.FinalOptionsWrapper>
      </S.Wrapper>
      <S.ButtonsWrapper>
        <S.CancelButton onClick={onCancelButtonClick} variant={EButtonVariant.SECONDARY}>
          Cancelar
        </S.CancelButton>
        <S.SubmitButton type="submit" isLoading={isCreatingCheckout}>
          Salvar
        </S.SubmitButton>
      </S.ButtonsWrapper>
    </S.Form>
  );
};

export default ConfigurationOttawa;
