import styled from 'styled-components/macro';
import TextBase from '@components/common/DataDisplay/Text';
import HeadingBase from '@components/common/DataDisplay/Heading';
import EmpytImageBase from '@components/common/utils/EmptyImage';

import ChipBase from '@components/common/DataDisplay/Chip';
import ImageBase from '@components/common/DataDisplay/Image';

interface IInfoGroupProps {
  maxWidth: number;
}

export const Image = styled(ImageBase)`
  min-width: 56px;
  max-width: 56px;
  min-height: 56px;
  max-height: 56px;
  border-radius: 4px;
`;

export const EmptyImage = styled(EmpytImageBase)`
  width: 56px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const CustomerCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  background-color: ${({ theme }) => theme.colors.neutral.white};

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

export const InfoGroupWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

export const Title = styled(HeadingBase)``;

export const Label = styled(TextBase)`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.neutral.dark};
`;

export const TextValue = styled(TextBase)`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.neutral.darkest};
`;

export const InfoGroup = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 212px;
  width: 100%;
`;

export const ItemInfoGroup = styled.div<IInfoGroupProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${({ maxWidth }) => `${maxWidth}px`};
`;

export const Chip = styled(ChipBase)`
  h1 {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

export const ProductCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  background-color: ${({ theme }) => theme.colors.neutral.white};
`;

export const CardRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`;
