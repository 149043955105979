import React from 'react';

import { EDomainStatus } from '@domain/enums/common/domain/EDomain';

import * as S from './styles';

interface IBadgeProps {
  status: EDomainStatus;
}

const Badge: React.FC<IBadgeProps> = ({ status }) => {
  const statusDomainMappings: Record<EDomainStatus, string> = {
    [EDomainStatus.ACTIVE]: 'VERIFICADO',
    [EDomainStatus.PENDING]: 'PENDENTE',
    [EDomainStatus.DEFAULT]: 'PADRÃO',
  };

  const badgeText = statusDomainMappings[status];

  return <S.Badge status={status}>{badgeText}</S.Badge>;
};

export default Badge;
