export const SUB_MENU_OPTIONS = [
  {
    name: 'reports-general',
    parsedName: 'Geral',
    path: '/reports/general',
    parent: 'reports',
    requiredPermissions: ['report'],
  },
  {
    name: 'orders-all',
    parsedName: 'Todos os pedidos',
    path: '/orders/all',
    parent: 'orders',
    requiredPermissions: ['order'],
  },
  {
    name: 'orders-abandoned-cart',
    parsedName: 'Carrinhos abandonados',
    path: '/orders/abandoned-cart',
    parent: 'orders',
    requiredPermissions: ['abandoned_cart'],
  },
  {
    name: 'products-all',
    parsedName: 'Todos os produtos',
    path: '/products/all',
    parent: 'products',
    requiredPermissions: ['product'],
  },
  {
    name: 'clients-all',
    parsedName: 'Todos os clientes',
    path: '/clients/all',
    parent: 'clients',
    requiredPermissions: ['client'],
  },
  {
    name: 'marketing-coupons',
    parsedName: 'Cupons',
    path: '/marketing/coupon',
    parent: 'marketing',
    requiredPermissions: ['promocode'],
  },
  {
    name: 'marketing-upsells',
    parsedName: 'Upsells',
    path: '/marketing/upsells',
    parent: 'marketing',
    requiredPermissions: ['upsell'],
  },
  {
    name: 'marketing-order-bumps',
    parsedName: 'Order bumps',
    path: '/marketing/order-bumps',
    parent: 'marketing',
    requiredPermissions: ['orderbump'],
  },
  {
    name: 'marketing-pixels',
    parsedName: 'Pixels',
    path: '/marketing/pixels',
    parent: 'marketing',
    requiredPermissions: ['pixel'],
  },
  {
    name: 'marketing-recoveries',
    parsedName: 'Recuperações',
    path: '/marketing/recovery',
    parent: 'marketing',
    requiredPermissions: ['recovery_templates'],
  },
  {
    name: 'checkout-customizations',
    parsedName: 'Customizações',
    path: '/checkout/customizations',
    parent: 'checkout',
    requiredPermissions: ['checkout_customization'],
  },
  {
    name: 'checkout-domain',
    parsedName: 'Domínios',
    path: '/checkout/domains',
    parent: 'checkout',
    requiredPermissions: ['domain'],
  },
  {
    name: 'checkout-shipping',
    parsedName: 'Fretes',
    path: '/checkout/shipping',
    parent: 'checkout',
    requiredPermissions: ['freight'],
  },
  {
    name: 'checkout-social-proofs',
    parsedName: 'Provas sociais',
    path: '/checkout/social-proofs',
    parent: 'checkout',
    requiredPermissions: ['social'],
  },
  {
    name: 'zouti-pay-credentials',
    parsedName: 'Credencial',
    path: '/payment-credential',
    parent: 'payment-credential',
    requiredPermissions: ['credential'],
  },
  {
    name: 'zouti-pay-link',
    parsedName: 'Ir para Zouti Pay',
    path: 'https://app.pay.zouti.com.br',
    parent: 'payment-credential',
    requiredPermissions: ['link'],
  },
  {
    name: 'settings-company-data',
    parsedName: 'Negócio',
    path: '/settings/account-data',
    parent: 'settings',
    requiredPermissions: ['business'],
  },
  {
    name: 'settings-my-rates',
    parsedName: 'Minhas taxas',
    path: '/settings/my-rates',
    parent: 'settings',
    requiredPermissions: ['rates'],
  },
  {
    name: 'settings-transactional-emails',
    parsedName: 'E-mails transacionais',
    path: '/settings/transactional-email',
    parent: 'settings',
    requiredPermissions: ['transactional_mail'],
  },
  {
    name: 'users',
    parsedName: 'Usuários',
    path: '/settings/users',
    parent: 'settings',
    requiredPermissions: [''],
  },
  {
    name: 'settings-webhooks',
    parsedName: 'Webhooks',
    path: '/apps/webhooks',
    parent: 'settings',
    requiredPermissions: ['webhook'],
  },
  {
    name: 'gateway-statements-transactions',
    parsedName: 'Extratos',
    path: '/statement-transactions',
    parent: 'gateway',
    requiredPermissions: [''],
  },
  {
    name: 'gateway-bank-accounts',
    parsedName: 'Contas bancárias',
    path: '/bank-accounts',
    parent: 'gateway',
    requiredPermissions: [''],
  },
  {
    name: 'gateway-payout',
    parsedName: 'Transferências bancárias',
    path: '/payout',
    parent: 'gateway',
    requiredPermissions: [''],
  },
  // {
  //   name: 'settings-users',
  //   parsedName: 'Usuários',
  //   path: '/settings/users',
  //   parent: 'settings',
  // },
];
