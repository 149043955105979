import React from 'react';

import { EHeadAlign } from '@domain/enums/components/common/ETable';
import { IShipping } from '@domain/interfaces/common/shipping/IShipping';

import { PencilSimple as EditIcon, Trash as TrashIcon } from 'phosphor-react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components/macro';
import { useGetShipping } from '@store/common/shipping/GetShippingContext';
import { useUserAccount } from '@store/context/common/UserAccount';

import { formatCurrency } from '@utils/common/formatCurrency';

import * as S from './styles';

interface ITableRowProps {
  shipping: IShipping;
}

const TableRow: React.FC<ITableRowProps> = ({ shipping }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { accountId, checkoutId } = useParams();
  const { currentUserAccount } = useUserAccount();
  const {
    setIsOpenModal,
    setShippingDeletedInfo,
    inactivateCurrentShipping,
    activateCurrentShipping,
  } = useGetShipping();
  const [isVisibleActions, setIsVisibleActions] = React.useState<boolean>(false);

  const isInactivated = Object.prototype.hasOwnProperty.call(shipping, 'inactivated_at');
  const convertShippingValue = shipping.amount / 100;

  const onSwitchChange = React.useCallback(async () => {
    if (isInactivated) {
      await activateCurrentShipping(shipping.id);
    } else {
      await inactivateCurrentShipping(shipping.id);
    }
  }, [isInactivated, activateCurrentShipping, inactivateCurrentShipping, shipping]);

  const onRowMouseEnter = React.useCallback(() => {
    setIsVisibleActions(true);
  }, []);

  const onRowMouseLeave = React.useCallback(() => {
    setIsVisibleActions(false);
  }, []);

  const onEditShippingButtonClick = (shippingId: string): void => {
    navigate(`/${accountId}/${checkoutId}/dashboard/checkout/shipping/${shippingId}`);
  };

  const getShippingValue = React.useCallback(() => {
    if (shipping.amount === 0) return 'Grátis';

    return formatCurrency(
      shipping.amount / 100,
      currentUserAccount?.language,
      currentUserAccount?.currency,
    );
  }, [shipping, currentUserAccount]);

  const getPeriodText = React.useCallback(() => {
    if (shipping.delivery_time_in_days <= 7) return '1 a 7 dias';

    if (shipping.delivery_time_in_days <= 14) return '1 a 2 semanas';

    if (shipping.delivery_time_in_days <= 28) return '2 a 4 semanas';

    return `até ${shipping.delivery_time_in_days} dias`;
  }, [shipping]);

  const shippingId = shipping.id;
  const shippingName = shipping.name;
  const shippingValue = getShippingValue();
  const shippingPeriod = getPeriodText();

  return (
    <S.Table.Row onMouseEnter={onRowMouseEnter} onMouseLeave={onRowMouseLeave} borderBottom>
      <S.Table.Data>
        <S.Switch
          id={shippingId}
          name={shippingId}
          checked={!isInactivated}
          onChange={onSwitchChange}
        />
      </S.Table.Data>

      <S.Table.Data>
        <S.Text>{shippingName}</S.Text>
      </S.Table.Data>

      <S.Table.Data align={EHeadAlign.RIGHT}>
        <S.Text>{shippingValue}</S.Text>
      </S.Table.Data>

      <S.Table.Data>
        <S.Text>{shippingPeriod}</S.Text>
      </S.Table.Data>
      <S.Table.Data align={EHeadAlign.RIGHT}>
        <S.ButtonActionsWrapper>
          {isVisibleActions && (
            <>
              <S.Table.Action onClick={() => onEditShippingButtonClick(shippingId)}>
                <EditIcon size={18} color={theme.colors.neutral.darker} weight="bold" />
              </S.Table.Action>
              <S.Table.Action
                onClick={() => {
                  setIsOpenModal(state => !state);
                  setShippingDeletedInfo({ shippingId, name: shippingName });
                }}
              >
                <TrashIcon size={18} color={theme.colors.neutral.darker} weight="bold" />
              </S.Table.Action>
            </>
          )}
        </S.ButtonActionsWrapper>
      </S.Table.Data>
    </S.Table.Row>
  );
};

export default TableRow;
