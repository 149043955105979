import React from 'react';
import { FieldValues, useForm } from 'react-hook-form';

import { useNewWebhook } from '@store/common/webhook/NewWebhookContext';

import SaveHeaderBar from '@components/common/utils/SaveHeaderBar';
import WebhookEventsSelect from './WebhookEventsSelect';

import * as S from './styles';

const CardCredentials: React.FC = () => {
  const { register, handleSubmit } = useForm();
  const {
    webhookUrl,
    webhookName,
    webhookEvents,
    isCreatingWebhook,
    onWebhookEventsChange,
    onWebhookNameChange,
    onWebhookUrlChange,
    onCancelClick,
    onSubmitClick,
  } = useNewWebhook();

  const nameRegister = register('name');
  const urlRegister = register('url');

  const onNameChange = React.useCallback(
    event => {
      onWebhookNameChange(event.target.value);
    },
    [onWebhookNameChange],
  );

  const onUrlChange = React.useCallback(
    event => {
      onWebhookUrlChange(event.target.value);
    },
    [onWebhookUrlChange],
  );

  const onSubmit = async (formData: FieldValues): Promise<void> => {
    console.log({ formData });
  };

  const showSaveHeaderBar = webhookName && webhookUrl && webhookEvents?.length;

  return (
    <S.Form onSubmit={handleSubmit(onSubmit)}>
      <S.Credentials>
        {Boolean(showSaveHeaderBar) && (
          <SaveHeaderBar
            onCancelClick={onCancelClick}
            onSubmitClick={onSubmitClick}
            isLoading={isCreatingWebhook}
          />
        )}
        <S.CredentialsTitle>Credenciais</S.CredentialsTitle>

        <S.InputsWrapper>
          <S.InputGroup>
            <S.Label>Nome do Webhook</S.Label>
            <S.Input
              {...nameRegister}
              onChange={onNameChange}
              type="text"
              placeholder="Insira o nome do Webhook"
            />
          </S.InputGroup>

          <S.InputGroup>
            <S.Label>URL</S.Label>
            <S.Input
              {...urlRegister}
              onChange={onUrlChange}
              type="text"
              placeholder="Insira a URL"
            />
          </S.InputGroup>

          <S.InputGroup>
            <S.Label>Selecionar Eventos</S.Label>
            <WebhookEventsSelect onChange={onWebhookEventsChange} />
          </S.InputGroup>
        </S.InputsWrapper>
      </S.Credentials>
    </S.Form>
  );
};

export default CardCredentials;
