import { AxiosResponse } from 'axios';

import { dashboardInstance } from '@services/common/dashboardInstance';

import {
  IPayoutsService,
  ICreatePayoutProps,
  ICreatePayoutsResponse,
  ISimulatePayoutProps,
  ISimulatePayoutResponse,
} from '@domain/interfaces/hooks/services/payouts/IPayoutsService';

export const usePayoutService = (): IPayoutsService => {
  const createPayout = async ({
    accountId,
    payload,
  }: ICreatePayoutProps): Promise<AxiosResponse<ICreatePayoutsResponse>> => {
    return dashboardInstance.post(`/accounts/${accountId}/payouts`, payload, {
      headers: {
        'x-zouti-account': accountId,
      },
    });
  };

  const simulatePayout = async ({
    accountId,
    payload,
  }: ISimulatePayoutProps): Promise<AxiosResponse<ISimulatePayoutResponse>> => {
    return dashboardInstance.post(`/accounts/${accountId}/payouts/simulate`, payload, {
      headers: {
        'x-zouti-account': accountId,
      },
    });
  };

  return { createPayout, simulatePayout };
};
