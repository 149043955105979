import styled from 'styled-components/macro';

import ButtonBase from '@components/common/Inputs/Button';

export const Wrapper = styled.div`
  align-items: flex-start;
  display: flex;
  gap: 8px;
  justify-content: space-between;
  margin-bottom: 32px;
  width: 100%;
`;

export const NewCouponButton = styled(ButtonBase)`
  & > span {
    color: ${({ theme }) => theme.colors.neutral.darker};
  }
`;
