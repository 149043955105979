import React from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/common/EHeading';
import { EStoreSegment } from '@domain/enums/common/onboarding/EStore';

import { currencies } from '@constants/common/currencies';
import { useStoreOnboarding } from '@store/context/onboarding/store/StoreOnboardingContext';

import {
  storeGlobalSchema,
  storeNameSchema,
} from '@helpers/validators/pages/onboarding/store/storeName';

import Text from '@components/common/DataDisplay/Text';

import { languages } from '@constants/common/languages';

import * as S from './styles';

const StoreName: React.FC = () => {
  const { finishStoreOnboarding, isCreatingAccount, storeSegmentValue, storeSegmentAnswer } =
    useStoreOnboarding();

  const isDropshippingGlobal = storeSegmentAnswer === EStoreSegment.DROPSHIPPING_GLOBAL;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(
      yup.object().shape({
        ...storeNameSchema,
        ...(isDropshippingGlobal ? storeGlobalSchema : {}),
      }),
    ),
  });

  const storeNameValueRegister = register('store_name_value');
  const sotreLanguageValueRegister = register('store_language_value');
  const storeCurrencyValueRegister = register('store_currency_value');

  const onSubmit = React.useCallback(
    data => {
      finishStoreOnboarding(data);
    },
    [finishStoreOnboarding],
  );

  return (
    <S.Wrapper onSubmit={handleSubmit(onSubmit)}>
      {!isDropshippingGlobal && (
        <S.QuestionText size={EHeadingSize.H6} fontWeight={EHeadingWeight.MEDIUM}>
          Qual o nome do seu negócio?
        </S.QuestionText>
      )}

      {isDropshippingGlobal && (
        <>
          <S.QuestionText size={EHeadingSize.H6} fontWeight={EHeadingWeight.MEDIUM}>
            Configurações iniciais, Idioma e Moeda
          </S.QuestionText>

          <S.TitleDescription>
            Insira o nome do seu negócio, escolha a moeda das transações e o idioma da interface
            para personalizar a experiência na sua loja.
          </S.TitleDescription>
        </>
      )}

      <S.InputGroup>
        <S.Input {...storeNameValueRegister} type="text" placeholder="Informe o nome do negócio" />
        {errors?.store_name_value && (
          <Text isFeedbackError>{errors?.store_name_value?.message}</Text>
        )}

        {isDropshippingGlobal && (
          <>
            <S.Select {...sotreLanguageValueRegister} name="store_language_value" defaultValue="">
              <S.Option value="" key="empty-language" disabled>
                Escolha o idioma
              </S.Option>
              {languages.map(language => {
                return (
                  <S.Option value={language.value} key={language.value}>
                    {language.label}
                  </S.Option>
                );
              })}
            </S.Select>
            {errors?.store_language_value && (
              <Text isFeedbackError>{errors?.store_language_value?.message}</Text>
            )}

            <S.Select {...storeCurrencyValueRegister} name="store_currency_value" defaultValue="">
              <S.Option value="" key="empty-currency" disabled>
                Escolha a moeda
              </S.Option>
              {currencies.map(currency => (
                <S.Option
                  value={currency.value}
                  key={currency.value}
                  disabled={currency.value === 'ARS'}
                >
                  {currency.value === 'ARS' ? (
                    <>
                      {currency.value} - {currency.currency} (indisponível no momento)
                    </>
                  ) : (
                    <>
                      {currency.value} - {currency.currency}
                    </>
                  )}
                </S.Option>
              ))}
            </S.Select>

            {errors?.store_currency_value && (
              <Text isFeedbackError>{errors?.store_currency_value?.message}</Text>
            )}
          </>
        )}
      </S.InputGroup>

      <S.SubmitButton type="submit" isLoading={isCreatingAccount}>
        Continuar
      </S.SubmitButton>
    </S.Wrapper>
  );
};

export default StoreName;
