import React from 'react';
import { Trash } from 'phosphor-react';

import { useConfigDomain } from '@store/common/domain/ConfigDomainContext';

import SecondStep from '../Steps/SecondStep';
import FirstStep from '../Steps/FirstStep';
import * as S from './styles';
import SkeletonLoading from '../SkeletonLoading';

const Body: React.FC = () => {
  const { deleteCurrentDomain } = useConfigDomain();
  const isLoading = false;

  if (isLoading) {
    return <SkeletonLoading />;
  }

  return (
    <S.Wrapper>
      <FirstStep />

      <SecondStep />

      <S.DeleteDomainButton onClick={deleteCurrentDomain}>
        <Trash size={20} />
        Excluir domínio
      </S.DeleteDomainButton>
    </S.Wrapper>
  );
};

export default Body;
