import React from 'react';

import { useShopify } from '@store/common/shopify/ShopifyContext';
import { useGetAccountStep } from '@store/common/home/GetAccountStepContext';

import * as S from './styles';

const ActiveCheckoutModal: React.FC = () => {
  const { setIsOpenModal, isOpenModal } = useGetAccountStep();
  const { onActivateShopifyCredential, isLoadingActiveCheckout } = useShopify();

  const onActiveCheckout = (): void => {
    onActivateShopifyCredential();
  };

  return (
    <S.Modal
      onSuccess={onActiveCheckout}
      cancelText="Não ativar"
      successText="Ativar checkout"
      title="Ativar checkout"
      isSuccessButtonLoading={isLoadingActiveCheckout}
      toggle={() => setIsOpenModal({ ...isOpenModal, activeCheckout: !isOpenModal.activeCheckout })}
      isOpen={isOpenModal?.activeCheckout}
    >
      <S.Text>
        Ao ativar o checkout, a sua loja começará a redirecionar os pedidos para o checkout da
        Zouti. Deseja ativar o checkout?
      </S.Text>
    </S.Modal>
  );
};

export default ActiveCheckoutModal;
