import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft } from '@phosphor-icons/react';
import { useTheme } from 'styled-components/macro';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/common/EHeading';

import * as S from './styles';

interface IPageHeaderProps {
  title: string;
  titleWeight?: EHeadingWeight;
  description?: string;
  quantityText?: string;
  goBackButton?: boolean;
}

const PageHeader: React.FC<IPageHeaderProps> = ({
  title,
  titleWeight = EHeadingWeight.MEDIUM,
  quantityText,
  description,
  goBackButton = false,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const onGoBackClick = React.useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <S.Wrapper>
      <S.TitleAndQuantityWrapper>
        {goBackButton ? (
          <S.GoBackButton onClick={onGoBackClick}>
            <ArrowLeft size={20} weight="bold" color={theme.colors.neutral.darker} />

            <S.Title size={EHeadingSize.H2} fontWeight={titleWeight}>
              {title}
            </S.Title>
          </S.GoBackButton>
        ) : (
          <S.Title size={EHeadingSize.H2} fontWeight={titleWeight}>
            {title}
          </S.Title>
        )}

        {Boolean(quantityText) && <S.QuantityText>{quantityText}</S.QuantityText>}
      </S.TitleAndQuantityWrapper>

      {Boolean(description) && <S.Description>{description}</S.Description>}
    </S.Wrapper>
  );
};

export default PageHeader;
