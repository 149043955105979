import React from 'react';
import { useAnalytics } from '@store/context/common/AnalyticsContext';
import ListSkeletonLoading from '@components/common/utils/ListSkeletonLoading';

import { useGetPayouts } from '@store/common/Payout/GetPayoutsContext';

import BalanceCard from '@components/common/Surfaces/BalanceCard';
import Body from './Body';
import Header from './Header';

import * as S from './styles';

const Coupon: React.FC = () => {
  const { analytics } = useAnalytics();
  const { isLoading, balances } = useGetPayouts();

  React.useEffect(() => {
    analytics.track('Transfer Bank Page Viewed');
  }, [analytics]);

  if (isLoading) {
    return <ListSkeletonLoading />;
  }

  return (
    <S.Wrapper>
      <Header />
      <BalanceCard balances={balances} />
      <Body />
    </S.Wrapper>
  );
};

export default Coupon;
