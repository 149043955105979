import styled from 'styled-components/macro';

import LabelBase from '@components/common/DataDisplay/Label';
import TextBase from '@components/common/DataDisplay/Text';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const WrapperTextArea = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const WrapperButton = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;

export const Button = styled.div`
  padding: 6px 8px;
  cursor: pointer;

  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.primary.dark_green_60};
  background-color: ${({ theme }) => theme.colors.primary.lemon_20};
`;

export const TextButton = styled(TextBase)`
  font-size: 1.2rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary.dark_green_60};
`;

export const Label = styled(LabelBase)`
  margin: 0;
  font-size: 1.6rem;
  align-self: flex-end;
`;

export const CountCharacters = styled(TextBase)`
  display: flex;
  justify-content: flex-end;
  color: ${({ theme }) => theme.colors.neutral.base};
`;

export const MessageError = styled(TextBase)`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.negative.base};
`;

export const WrapperCountAndTextError = styled.div<{ errorMessage?: string }>`
  display: flex;
  align-items: center;
  justify-content: ${({ errorMessage }) => (errorMessage ? 'space-between' : 'flex-end')};
`;
