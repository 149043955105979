import React from 'react';

import { UpdateSocialProofProvider } from '@store/common/socialProof/UpdateSocialProofContext';

import Content from '@components/pages/Dashboard/SocialProof/UpdateSocialProof';

const NewSocialProof: React.FC = () => {
  return (
    <UpdateSocialProofProvider>
      <Content />
    </UpdateSocialProofProvider>
  );
};

export default NewSocialProof;
