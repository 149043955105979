import React from 'react';

import { usePaymentCredential } from '@store/common/paymentCredential/PaymentCredentialContext';
import { useAnalytics } from '@store/context/common/AnalyticsContext';

import AppSkeletonLoading from '@components/common/utils/AppSkeletonLoading';
import Header from './Header';
import Body from './Body';

import * as S from './styles';

const PaymentCredential: React.FC = () => {
  const { isLoadingPaymentCredential, isPaymentCredentialError } = usePaymentCredential();
  const { analytics } = useAnalytics();

  React.useEffect(() => {
    analytics.track('Zouti Credential Page Viewed');
  }, [analytics]);

  if (isLoadingPaymentCredential) {
    return <AppSkeletonLoading />;
  }

  if (isPaymentCredentialError && !isLoadingPaymentCredential) {
    return <div>Error...</div>;
  }

  return (
    <S.Wrapper>
      <Header />

      <Body />
    </S.Wrapper>
  );
};

export default PaymentCredential;
