import React from 'react';
import { MagnifyingGlass, Funnel } from 'phosphor-react';
import { useTheme } from 'styled-components/macro';

import { EButtonVariant } from '@domain/enums/components/common/EButton';

import { useUsersContext } from '@store/common/users/UsersContext';

import * as S from './styles';

const InputSearchAndFilter: React.FC = () => {
  const theme = useTheme();
  const { handleSearchChange } = useUsersContext();

  return (
    <S.Wrapper>
      <S.InputSearch
        onChange={handleSearchChange}
        type="text"
        icon={MagnifyingGlass}
        placeholder="Buscar usuário..."
      />
      {/* <S.FilterButton variant={EButtonVariant.SECONDARY}>
        <Funnel size={16} weight="bold" color={theme.colors.neutral.darker} /> Filter
      </S.FilterButton> */}
    </S.Wrapper>
  );
};

export default InputSearchAndFilter;
