import React from 'react';
import { ArrowUpRight, Lightning, RocketLaunch, ShoppingCartSimple } from 'phosphor-react';
import { useTheme } from 'styled-components/macro';

import { useGetGeneral } from '@store/common/general/GetGeneralContext';

import { numberFormatter } from '@helpers/utils/common/numberFormatter';

import OverviewSkeleton from '../Skeletons/Overview';

import * as S from './styles';

const Overview: React.FC = () => {
  const theme = useTheme();
  const { checkoutMetricsData, isLoadingOverview } = useGetGeneral();

  const isEmptyData = false;

  const data = [
    {
      type: 'order',
      currency: `R$ ${
        checkoutMetricsData?.order_bump.value
          ? numberFormatter(checkoutMetricsData?.order_bump.value / 100, 2)
          : 0
      }`,
      subscription: 'Order bump',
      rateText: 'Tx conv.',
      textQuantity: 'Quantidade',
      quantity: checkoutMetricsData?.order_bump.quantity,
      percentRate: `${
        checkoutMetricsData?.order_bump.conversion_rate
          ? numberFormatter(checkoutMetricsData?.order_bump.conversion_rate, 2)
          : 0
      }`,
    },
    {
      type: 'upsell',
      textQuantity: 'Quantidade',
      quantity: checkoutMetricsData?.upsell.quantity,
      currency: `R$ ${
        checkoutMetricsData?.upsell.value
          ? numberFormatter(checkoutMetricsData?.upsell.value / 100, 2)
          : 0
      }`,
      subscription: 'Upsell',
      rateText: 'Tx conv.',
      percentRate: `${
        checkoutMetricsData?.upsell.conversion_rate
          ? numberFormatter(checkoutMetricsData?.upsell.conversion_rate, 2)
          : 0
      }`,
      // percentTag: '128,3%',
    },
    {
      type: 'cart',
      currency: `R$ ${
        checkoutMetricsData?.abandoned_cart.value
          ? numberFormatter(checkoutMetricsData?.abandoned_cart.value / 100, 2)
          : 0
      }`,
      subscription: 'Recup. de Carrinho',
      textQuantity: 'Quantidade',
      rateText: 'Tx Recup.',
      quantity: checkoutMetricsData?.abandoned_cart.quantity,
      percentRate: `${
        checkoutMetricsData?.abandoned_cart.conversion_rate
          ? numberFormatter(checkoutMetricsData?.abandoned_cart.conversion_rate, 2)
          : 0
      }`,
      // percentTag: '8,3%',
    },
  ];

  const iconMap = { order: Lightning, upsell: RocketLaunch, cart: ShoppingCartSimple };

  if (isLoadingOverview) {
    return <OverviewSkeleton />;
  }

  return (
    <S.Wrapper>
      <S.Title>Marketing</S.Title>

      {data.map((item, index) => {
        const Icon = iconMap[item.type as 'order' | 'upsell' | 'cart'];

        return (
          <S.CardRow key={index.toFixed()}>
            <S.FirstColumn>
              <S.IconContainer>
                <Icon size={22} color={theme.colors.primary.dark_green_60} />
              </S.IconContainer>
              <S.CurrencyValueAndTag>
                <S.InfoCurrencyValue isEmptyData={isEmptyData}>{item.currency}</S.InfoCurrencyValue>
                <S.InfoSubscription>{item.subscription}</S.InfoSubscription>

                {/* {item.percentTag && !isEmptyData && (
                <S.PercentTag>
                <ArrowUpRight size={12} color={theme.colors.positive.darker} weight="bold" />

                {item.percentTag}
                </S.PercentTag>
              )} */}
              </S.CurrencyValueAndTag>
            </S.FirstColumn>

            <S.SecondColumn>
              <S.QuantityText>{item.textQuantity}</S.QuantityText>
              <S.QuantityValue>{item.quantity}</S.QuantityValue>
            </S.SecondColumn>

            {/* {!isEmptyData && (
              <>
                <S.RateText>{item.rateText}</S.RateText>

                <S.PercentRate>{`${item.percentRate}%`}</S.PercentRate>
              </>
            )} */}
          </S.CardRow>
        );
      })}
    </S.Wrapper>
  );
};

export default Overview;
