import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { ISignInContext } from '@domain/interfaces/store/context/public/signIn/ISignInContext';
import { IGetUserData } from '@domain/interfaces/common/auth/IAuth';

import { signInSchema } from '@helpers/validators/pages/public/signIn';

import { useConfig } from '@store/context/common/ConfigContext';
import { useAuthService } from '@hooks/services/auth/useAuthService';
import { useErrorHandler } from '@store/context/common/ErrorHandlerContext';
import { useAnalytics } from '@store/context/common/AnalyticsContext';
import { useQuery } from '@hooks/common/useQuery';

const SignInContext = React.createContext<ISignInContext | null>(null);

export const SignInProvider: React.FC = ({ children }) => {
  const query = useQuery();
  const { handleError } = useErrorHandler();
  const { getUser } = useAuthService();
  const { handleSessionToken } = useConfig();
  const { analytics } = useAnalytics();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onChange',
    resolver: yupResolver(signInSchema),
  });

  const membershipInviteToken = query?.get('membership_invite_token');
  const [isSigningIn, setIsSigningIng] = React.useState<boolean>(false);

  const onSubmit = React.useCallback(
    async data => {
      setIsSigningIng(true);

      try {
        const payload: IGetUserData = {
          email: data.email,
          password: data.password,
        };

        const { data: userData } = await getUser({ payload });

        analytics.identify(userData.object.user.id, {
          name: userData.object.user.name,
          email: userData.object.user.email,
          created_at: userData.object.user.created_at,
        });

        analytics.track('User Signed In', { email: userData.object.user.email });

        handleSessionToken(`ZSID=${userData.object.token}`);

        setIsSigningIng(false);

        if (membershipInviteToken) {
          window.location.href = `${window.location.origin}/loading?membership_invite_token=${membershipInviteToken}&origin_path=accept-invite`;
          return;
        }

        window.location.href = `${window.location.origin}/loading`;
      } catch (error) {
        setIsSigningIng(false);
        handleError(error);
      }
    },
    [getUser, handleSessionToken, handleError, analytics, membershipInviteToken],
  );

  return (
    <SignInContext.Provider value={{ errors, handleSubmit, register, onSubmit, isSigningIn }}>
      {children}
    </SignInContext.Provider>
  );
};

export const useSignIn = (): ISignInContext => {
  const context = React.useContext(SignInContext);

  if (!context) {
    throw new Error('useSignIn must be used within SignInProvider');
  }

  return context;
};
