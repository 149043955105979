import React from 'react';

import { useGetPromocode } from '@store/common/promocode/GetPromocodeContext';

import TableRow from './TableRow';

import * as S from './styles';

const Body: React.FC = () => {
  const { promocodes } = useGetPromocode();

  return (
    <S.Wrapper>
      <S.Table>
        <S.Table.Header>
          <S.Table.Row>
            <S.Table.Head />
            <S.Table.Head>Código</S.Table.Head>
            <S.Table.Head>Descrição</S.Table.Head>
            <S.Table.Head>Status</S.Table.Head>
            <S.Table.Head>Nº de utilizações</S.Table.Head>
            <S.Table.Head />
          </S.Table.Row>
        </S.Table.Header>

        <S.Table.Body>
          {promocodes.map(promocode => (
            <TableRow promocode={promocode} key={promocode.id} />
          ))}
        </S.Table.Body>
      </S.Table>
    </S.Wrapper>
  );
};

export default Body;
