import styled from 'styled-components/macro';
import ButtonBase from '@components/common/Inputs/Button';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  & > div {
    align-self: self-end;
  }
`;

export const NewSocialProofButton = styled(ButtonBase)`
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  background: ${({ theme }) => theme.colors.neutral.white};

  & > span {
    color: ${({ theme }) => theme.colors.neutral.darker};
    font-size: 1.6rem;
  }

  &:hover {
    filter: brightness(0.9);
  }

  &:active {
    filter: brightness(0.8);
  }
`;
