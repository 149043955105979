import React from 'react';

import { useListAbandonedCart } from '@store/common/AbandonedCart/ListAbandonedCartContext';
import { IAbandonedCart } from '@domain/interfaces/common/AbandonedCart/IAbandonedCart';
import TableRow from './TableRow';

import * as S from './styles';

const Body: React.FC = () => {
  const { abandonedCarts } = useListAbandonedCart();

  return (
    <S.Wrapper>
      <S.Table>
        <S.Table.Header>
          <S.Table.Row>
            <S.Table.Head>ID / Data</S.Table.Head>

            <S.Table.Head>Cliente</S.Table.Head>

            <S.Table.Head>Abandonou em</S.Table.Head>

            <S.Table.Head>Total</S.Table.Head>
          </S.Table.Row>
        </S.Table.Header>

        <S.Table.Body>
          {abandonedCarts?.map((item: IAbandonedCart) => {
            return <TableRow abandonedCart={item} key={item.id} />;
          })}
        </S.Table.Body>
      </S.Table>
    </S.Wrapper>
  );
};

export default Body;
