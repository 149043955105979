import React from 'react';
import { CaretRight, PlusCircle, Check } from 'phosphor-react';
import { useTheme } from 'styled-components/macro';
import { useNavigate } from 'react-router-dom';

import logo from '@assets/images/common/logoIconSecondary.svg';
import { useUserCheckout } from '@store/context/common/UserCheckout';

import * as S from './styles';

interface ICurrentStoreProps {
  updateSideBarState: () => void;
}

const CurrentStore: React.FC<ICurrentStoreProps> = ({ updateSideBarState }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { userCheckouts, currentUserCheckout } = useUserCheckout();

  const [isDropdownOpen, setIsDropdownOpen] = React.useState<boolean>(false);

  const handleDropdownOpen = React.useCallback(state => setIsDropdownOpen(state), []);

  const onCheckoutChangeClick = React.useCallback(
    async data => {
      setIsDropdownOpen(false);
      navigate(`/${data?.account_id}/${data?.id}/dashboard/home`);
      updateSideBarState();
    },
    [navigate, updateSideBarState],
  );

  const onNewCheckoutClick = React.useCallback(() => {
    setIsDropdownOpen(false);
    navigate(`/onboarding/store`);
  }, [navigate]);

  const checkoutName = currentUserCheckout?.name;

  return (
    <S.Dropdown open={isDropdownOpen} onOpenChange={handleDropdownOpen}>
      <S.DropdownTrigger>
        <S.Wrapper>
          <S.ImageAndNameWrapper>
            <S.Image src={logo} alt="Zouti" />
            <S.StoreName>{checkoutName}</S.StoreName>
          </S.ImageAndNameWrapper>

          <CaretRight size={12} weight="bold" color={theme.colors.neutral.dark} />
        </S.Wrapper>
      </S.DropdownTrigger>

      <S.DropdownContent side="top" sideOffset={5}>
        {userCheckouts?.map(checkout => (
          <S.StoreWrapper
            onClick={() => onCheckoutChangeClick(checkout)}
            key={checkout.id}
            isCurrentCheckout={checkout.id === currentUserCheckout?.id}
          >
            <S.StoreImageAndNameWrapper>
              <S.Image src={logo} alt="Zouti" />
              <S.StoreListName>{checkout.name}</S.StoreListName>
            </S.StoreImageAndNameWrapper>

            {checkout.id === currentUserCheckout?.id && (
              <Check size={12} weight="bold" color={theme.colors.primary.dark_green_40} />
            )}
          </S.StoreWrapper>
        ))}

        <S.AddStoreWrapper onClick={onNewCheckoutClick}>
          <PlusCircle size={20} color={theme.colors.neutral.darkest} />
          <S.AddStoreText>Adicionar novo checkout</S.AddStoreText>
        </S.AddStoreWrapper>
      </S.DropdownContent>
    </S.Dropdown>
  );
};

export default CurrentStore;
