import React from 'react';
import useSWR from 'swr';

import { ISwr } from '@domain/interfaces/common/swr/ISwr';

import { dashboardInstance } from '@services/common/dashboardInstance';
import { useErrorHandler } from '@store/context/common/ErrorHandlerContext';

const GetProductContext = React.createContext<any | null>(null);

export const GetProductProvider: React.FC = ({ children }) => {
  const { handleError } = useErrorHandler();
  const [products, setProducts] = React.useState<Array<any>>([]);
  const [productActive, setProductActive] = React.useState<any>();

  // TODO: Revere essa requisição, pois os produtos então sendo requisitados
  // pelo comoponente product list que usa diretamente o service

  // const PRODUCTS_URL = `/accounts/${accountId}/products?page=1&limit=10`;

  const {
    data: response,
    isLoading,
    isValidating,
    mutate,
    error,
  }: any = useSWR<ISwr<any>>('', dashboardInstance);

  React.useEffect(() => {
    if (productActive) {
      setProductActive(productActive);
    }
  }, [productActive]);

  React.useEffect(() => {
    if (error) {
      handleError(error);
    }
    if (response) {
      setProducts(response.data);
    }
  }, [response, handleError, error]);

  const isLoadingProducts = isLoading || isValidating;
  const isProductsError = Boolean(error);

  return (
    <GetProductContext.Provider
      value={{
        products,
        productActive,
        setProductActive,
        isLoadingProducts,
        isProductsError,
        mutate,
      }}
    >
      {children}
    </GetProductContext.Provider>
  );
};

export const useGetProduct = (): any => {
  const context = React.useContext(GetProductContext);

  if (!context) {
    throw new Error('useGetProductContext must be used within provider');
  }

  return context;
};
