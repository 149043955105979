import React from 'react';

import useSWR from 'swr';

import { useParams } from 'react-router-dom';

import { ISwr } from '@domain/interfaces/common/swr/ISwr';
import { IOrder } from '@domain/interfaces/common/order/IOrder';
import { IListOrdersProvider } from '@domain/interfaces/store/common/order/IListOrdersProvider';

import { DASHBOARD_API_URL } from '@constants/services/apiUrl';
import { dashboardInstance } from '@services/common/dashboardInstance';

import { useErrorHandler } from '@store/context/common/ErrorHandlerContext';

const ListOrdersContext = React.createContext<IListOrdersProvider | null>(null);

interface IResponse {
  object: {
    data: Array<IOrder>;
    page: number;
    per_page: number;
    total: number;
    total_pages: number;
  };
}

export const ListOrdersProvider: React.FC = ({ children }) => {
  const { accountId, checkoutId } = useParams();
  const { handleError } = useErrorHandler();

  const [orders, setOrders] = React.useState<Array<IOrder>>([]);
  const [page, setPage] = React.useState<number>(0);
  const [totalOrders, setTotalOrders] = React.useState<number>(0);
  const [totalPages, setTotalPages] = React.useState<number>(0);
  const [pageLimit, setPageLimit] = React.useState<number>(10);
  const [isFilterSideModalOpen, setIsFilterSideModalOpen] = React.useState(false);

  dashboardInstance.defaults.headers.common['x-zouti-account'] = accountId;

  const ORDERS_URL = `${DASHBOARD_API_URL}/accounts/${accountId}/checkouts/${checkoutId}/orders?page=${
    page + 1
  }&limit=${pageLimit}`;

  const {
    data: requestResponseData,
    isLoading,
    isValidating,
    mutate,
    error,
  } = useSWR<ISwr<IResponse>>(ORDERS_URL, dashboardInstance);

  const handleFilter = React.useCallback(() => {
    console.log('teste');
  }, []);

  const handlePageChange = React.useCallback(selected => {
    setPage(selected);
  }, []);

  const handlePageLimit = React.useCallback(newPageLimit => {
    setPageLimit(newPageLimit);
  }, []);

  const handleFilterSideModalOpen = React.useCallback(() => {
    setIsFilterSideModalOpen(currentState => !currentState);
  }, []);

  React.useEffect(() => {
    if (error) {
      handleError(error);
    }

    if (requestResponseData?.data?.object?.data) {
      setOrders(requestResponseData.data?.object?.data);
      setTotalOrders(requestResponseData.data?.object.total);
      setTotalPages(requestResponseData.data?.object.total_pages);
    }
  }, [requestResponseData, error, handleError]);

  const isLoadingOrders = isLoading || isValidating;
  const isOrdersError = Boolean(error);

  return (
    <ListOrdersContext.Provider
      value={{
        isLoadingOrders,
        isOrdersError,
        mutate,
        orders,
        totalOrders,
        totalPages,
        handlePageChange,
        page,
        pageLimit,
        handlePageLimit,
        handleFilterSideModalOpen,
        isFilterSideModalOpen,
        handleFilter,
      }}
    >
      {children}
    </ListOrdersContext.Provider>
  );
};

export const useListOrders = (): IListOrdersProvider => {
  const context = React.useContext(ListOrdersContext);

  if (!context) {
    throw new Error('useListOrders must be used within provider');
  }

  return context;
};
