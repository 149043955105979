import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useGetSocialProof } from '@store/common/socialProof/GetSocialProofContext';
import { useAnalytics } from '@store/context/common/AnalyticsContext';

import EmptyData from '@components/common/utils/EmptyData';
import ListSkeletonLoading from '@components/common/utils/ListSkeletonLoading';
import Header from './Header';
import Body from './Body';

import * as S from './styles';

const SocialProof: React.FC = () => {
  const navigate = useNavigate();
  const { isLoadingSocialProofs, socialProofs } = useGetSocialProof();
  const { accountId, checkoutId } = useParams();
  const { analytics } = useAnalytics();

  React.useEffect(() => {
    analytics.track('Social Proofs Page Viewed');
  }, [analytics]);

  const isEmptyState = socialProofs.length === 0;

  if (isLoadingSocialProofs) {
    return <ListSkeletonLoading />;
  }

  const onNewSocialProofButtonClick = (): void => {
    navigate(`/${accountId}/${checkoutId}/dashboard/checkout/social-proof/new`);
  };

  if (isEmptyState) {
    return (
      <EmptyData
        title="Nenhum prova social cadastrada"
        description="Utilize o depoimento de seus clientes para aumentar sua credibilidade."
        buttonText="Nova prova social"
        onClick={onNewSocialProofButtonClick}
      />
    );
  }

  return (
    <S.Wrapper>
      <Header />

      <Body />
    </S.Wrapper>
  );
};

export default SocialProof;
