import React, { ReactNode } from 'react';
import { Barcode, CreditCard } from 'phosphor-react';
import PIX from '@components/icons/Pix';
import { formatCurrency } from '@utils/common/formatCurrency';
import { numberFormatter } from '@helpers/utils/common/numberFormatter';
import { IMyRates } from '@domain/interfaces/common/MyRates/IMyRates';
import { RateCard } from '../RateCard';

interface IPaymentMethod {
  icon: ReactNode;
  name: string;
  fee: string;
  textFee: string;
  description: string;
}

interface IPaymentMethodProps {
  rates: IMyRates['payment'];
}

export const PaymentRate: React.FC<IPaymentMethodProps> = ({ rates }) => {
  const replaceAvailableIn = (value: string): string => {
    return value?.replace('d', '');
  };

  const formatCreditCardFee = formatCurrency(rates?.credit_card?.fee / 100);
  const formatCreditCardRate = numberFormatter(Number(rates?.credit_card?.fee_percentage), 2);

  const formatBoletoFee = formatCurrency(rates?.boleto?.fee / 100);
  const formatBoletoRate = numberFormatter(Number(rates?.boleto?.fee_percentage), 2);

  const formatPixRate = numberFormatter(Number(rates?.pix?.fee_percentage), 2);

  const paymentMethods: IPaymentMethod[] = [
    {
      icon: <CreditCard size="18" weight="bold" />,
      name: 'Cartão de crédito',
      fee: `${formatCreditCardRate}% + ${formatCreditCardFee}`,
      textFee: 'por venda aprovada',
      description: 'Recebimento em D+2 após o pagamento.',
    },
    {
      icon: <Barcode size="18" weight="bold" />,
      name: 'Boleto bancário',
      fee: `${formatBoletoRate}% + ${formatBoletoFee}`,
      textFee: 'por boleto pago',
      description: `Recebimento em D+${replaceAvailableIn(
        rates?.boleto?.available_in,
      )} (Instantâneo)`,
    },
    {
      icon: <PIX size="18" fillColor="#515151" />,
      name: 'Pix',
      fee: `${formatPixRate}%`,
      textFee: 'por transação',
      description: `Recebimento em D+${replaceAvailableIn(rates?.pix?.available_in)} (Instantâneo)`,
    },
  ];

  return <RateCard rates={paymentMethods} title="Taxa de pagamento" />;
};
