import React from 'react';

import logo from '@assets/images/common/logo.svg';
import logoIcon from '@assets/images/common/logoIcon.svg';

import { useQuery } from '@hooks/common/useQuery';

import Form from '../Form';

import * as S from './styles';

const Wrapper: React.FC = () => {
  const imgBackground = 'https://zouti-core-media.s3.amazonaws.com/dashboard/auth/sign_in.png';

  const query = useQuery();

  const isOldUser = query.get('is_old_user');

  const signUpLink =
    isOldUser === 'true' ? '/sign-up?is_old_user=true' : 'https://auth.zouti.com.br';

  return (
    <S.Container>
      <S.ImageBox image={imgBackground}>
        <S.InfoWrapper align="start">
          <S.BadgeCategory>
            <S.LogoIcon src={logoIcon} alt="" height="14px" /> Secure FinTech Solution
          </S.BadgeCategory>
          <S.BadgeText>
            Checkout e gateway integrados em uma plataforma inovadora, transparente e humanizada.
          </S.BadgeText>
        </S.InfoWrapper>
      </S.ImageBox>

      <S.Wrapper>
        <S.Logo src={logo} alt="Zouti" />

        <S.Body>
          <Form />

          <S.Text>
            Ainda não possui uma conta? <S.Link to={signUpLink}>Cadastrar</S.Link>
          </S.Text>
        </S.Body>
      </S.Wrapper>
    </S.Container>
  );
};

export default Wrapper;
