import React from 'react';

import Header from './Header';
import Body from './Body';

import * as S from './styles';

const Coupon: React.FC = () => {
  return (
    <S.Wrapper>
      <Header />
      <Body />
    </S.Wrapper>
  );
};

export default Coupon;
