import styled from 'styled-components/macro';
import TextBase from '@components/common/DataDisplay/Text';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 443px;
  height: 376px;
  background-color: ${({ theme }) => theme.colors.neutral.white};
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  border-radius: 4px;
  padding: 24px;
`;

export const Title = styled(TextBase)`
  font-size: 1.6rem;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.neutral.dark};
`;

export const IconContainer = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  padding: 12px;
  justify-content: flex-end;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary.lemon_10};
  border-radius: 6px;
`;

export const MetricsWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.small_16};
  justify-content: space-between;
`;

export const ChartWrapper = styled.div`
  position: relative;
  width: 141px;
  height: 135px;

  tspan {
    font-family: ${({ theme }) => theme.fonts.text};
    font-size: 1.2rem;
    fill: ${({ theme }) => theme.colors.neutral.base};
  }
`;

export const Value = styled(TextBase)<{ isEmptyData: boolean }>`
  font-size: 2rem;
  line-height: 20px;
  color: ${({ theme, isEmptyData }) =>
    isEmptyData ? theme.colors.neutral.light : theme.colors.neutral.darker};
  font-weight: 600;
`;

export const Text = styled(TextBase)`
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.neutral.dark};
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const SingleMetricsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 189px;
  height: 287px;
  background-color: ${({ theme }) => theme.colors.neutral.gray_white};
  padding: 10px 24px;
  gap: 16px;
`;

export const EmptyText = styled(TextBase)`
  position: absolute;
  left: 30px;
  bottom: 70px;
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.neutral.light};
`;
