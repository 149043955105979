import React from 'react';

import { EUserOnboardingStep } from '@domain/enums/common/onboarding/EUser';

import { useUserOnboarding } from '@store/context/onboarding/user/UserOnboardingContext';

import ExperienceTime from './ExperienceTime';
import MonthlyRevenue from './MonthlyRevenue';
import WhereDidYouFindUs from './WhereDidYouFindUs';

import * as S from './styles';

const FormContent: React.FC = () => {
  const { onboardingStep } = useUserOnboarding();

  return (
    <S.Wrapper>
      {onboardingStep === EUserOnboardingStep.FIRST_STEP && <ExperienceTime />}

      {onboardingStep === EUserOnboardingStep.SECOND_STEP && <MonthlyRevenue />}

      {onboardingStep === EUserOnboardingStep.THIRD_STEP && <WhereDidYouFindUs />}
    </S.Wrapper>
  );
};

export default FormContent;
