import React from 'react';

import Wrapper from '@components/pages/Dashboard/GTM/Wrapper';
import { GTMProvider } from '@store/common/GTM/GTMContext';

const GTM: React.FC = () => {
  return (
    <GTMProvider>
      <Wrapper />
    </GTMProvider>
  );
};

export default GTM;
