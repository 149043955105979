import styled from 'styled-components/macro';

import InputGroupBase from '@components/common/Inputs/InputGroup';
import LabelBase from '@components/common/DataDisplay/Label';
import TextFieldBase from '@components/common/Inputs/TextField';
import TextBase from '@components/common/DataDisplay/Text';

export const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Form = styled.form``;

export const Credentials = styled.div`
  width: 100%;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  background: #fff;
  display: flex;
  flex-direction: column;
  padding: 40px 24px;
  align-self: flex-start;
`;

export const CredentialsTitle = styled(TextBase)`
  color: ${({ theme }) => theme.colors.neutral.darker};
  font-size: 1.6rem;
  font-weight: 600;
  display: block;
  margin-bottom: 24px;
`;

export const InputGroup = styled(InputGroupBase)``;

export const Label = styled(LabelBase)``;

export const Input = styled(TextFieldBase)``;
