import React from 'react';
import AcceptInviteUserWithAccount from '@components/pages/Public/AcceptInviteUserWithAccount';
import { SignUpUserInvitedProvider } from '@store/context/public/signUpUserInvited/SignUpUserInvitedContext';

const AcceptInviteUserWithAccountPage: React.FC = () => {
  return (
    <SignUpUserInvitedProvider>
      <AcceptInviteUserWithAccount />
    </SignUpUserInvitedProvider>
  );
};

export default AcceptInviteUserWithAccountPage;
