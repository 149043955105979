import styled from 'styled-components/macro';

import ModalBase from '@components/common/DataDisplay/Modal';

export const Modal = styled(ModalBase)`
  width: 544px;
  background-color: ${({ theme }) => theme.colors.neutral.white};
`;

export const Wrapper = styled.div`
  width: 100%;
`;

export const TransferContent = styled.main`
  display: flex;
  flex-direction: column;
`;
