import { AxiosResponse } from 'axios';

import {
  IAccountService,
  ICreateAccountProps,
  ICreateAccountResponse,
  IListAccountsResponse,
} from '@domain/interfaces/hooks/services/account/IAccountService';

import { dashboardInstance } from '@services/common/dashboardInstance';

export const useAccountService = (): IAccountService => {
  const listAccounts = async (): Promise<AxiosResponse<IListAccountsResponse>> => {
    return dashboardInstance.get(`/accounts`);
  };

  const createAccount = async ({
    payload,
  }: ICreateAccountProps): Promise<AxiosResponse<ICreateAccountResponse>> => {
    return dashboardInstance.post(`/accounts`, { ...payload });
  };

  const setToken = (token: string): void => {
    dashboardInstance.defaults.headers.common.access_key = token;
  };

  return { listAccounts, setToken, createAccount };
};
