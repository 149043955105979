import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { IOrderBump } from '@domain/interfaces/common/orderBump/IOrderBump';

import { useListOrderBumps } from '@store/common/orderBump/ListOrderBumpsContext';

import * as S from './styles';

interface ITableRowProps {
  orderBump: IOrderBump;
}

const TableRow: React.FC<ITableRowProps> = ({ orderBump }) => {
  const navigate = useNavigate();
  const { accountId, checkoutId } = useParams();
  const { removeOrderBump, inactivateSelectedOrderBump, activateSelectedOrderBump } =
    useListOrderBumps();

  const [isActive, setIsActive] = React.useState<boolean>(Boolean(!orderBump.inactivated_at));

  const onCheckboxChange = React.useCallback(
    event => {
      setIsActive(event.target.checked);

      if (event.target.checked) {
        activateSelectedOrderBump(orderBump.id);
      } else {
        inactivateSelectedOrderBump(orderBump.id);
      }
    },
    [orderBump, activateSelectedOrderBump, inactivateSelectedOrderBump],
  );

  const onEditOrderBumpClick = React.useCallback(() => {
    navigate(`/${accountId}/${checkoutId}/dashboard/marketing/order-bumps/${orderBump?.id}`);
  }, [navigate, accountId, checkoutId, orderBump]);

  const onRemoveOrderBumpClick = React.useCallback(() => {
    removeOrderBump(orderBump);
  }, [removeOrderBump, orderBump]);

  const orderBumpId = orderBump.id;
  const orderBumpName = orderBump?.name;
  const offerProductName = orderBump?.offer_product?.variants?.[0].name;
  const offerProductImage =
    orderBump?.offer_product?.images?.length > 0 ? orderBump?.offer_product?.images[0]?.url : '';
  const triggerProductName =
    orderBump?.trigger_product?.variants?.[0].name || orderBump?.trigger_product?.product?.name;
  const triggerProductImage =
    orderBump?.offer_product?.images?.length > 0 ? orderBump?.trigger_product?.images[0]?.url : '';

  return (
    <S.Table.Row borderBottom>
      <S.Table.Data>
        <S.Switch
          id={orderBumpId}
          name={orderBumpId}
          checked={isActive}
          onChange={onCheckboxChange}
        />
      </S.Table.Data>

      <S.Table.Data>
        <S.TextDescription>{orderBumpName}</S.TextDescription>
      </S.Table.Data>

      <S.Table.Data>
        <S.ImageWithText>
          <S.ImageFile>
            <S.ImageSelect src={triggerProductImage} />
          </S.ImageFile>
          <S.Text>{triggerProductName}</S.Text>
        </S.ImageWithText>
      </S.Table.Data>

      <S.Table.Data>
        <S.ImageWithText>
          <S.ImageFile>
            <S.ImageSelect src={offerProductImage} />
          </S.ImageFile>
          <S.Text>{offerProductName}</S.Text>
        </S.ImageWithText>
      </S.Table.Data>

      <S.Table.Data>
        <S.ButtonActionsWrapper>
          <S.Table.Action onClick={onEditOrderBumpClick}>
            <S.PencilSimpleIcon />
          </S.Table.Action>

          <S.Table.Action onClick={onRemoveOrderBumpClick}>
            <S.TrashIcon />
          </S.Table.Action>
        </S.ButtonActionsWrapper>
      </S.Table.Data>

      <S.Table.Data />
    </S.Table.Row>
  );
};

export default TableRow;
