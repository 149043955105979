import React, { ChangeEvent, InputHTMLAttributes, ReactNode, useState } from 'react';

import * as S from './styles';

interface IInputColorCheckoutProps extends InputHTMLAttributes<HTMLInputElement> {
  children: ReactNode;
}

const InputColorCheckout: React.FC<IInputColorCheckoutProps> = ({ children, ...rest }) => {
  const [selectedColor, setSelectedColor] = useState(rest.value);

  const handleColorChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setSelectedColor(event.target.value);
    rest.onChange?.(event);
  };

  const handleTextChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const newColor = event.target.value;
    setSelectedColor(newColor);
    rest.onChange?.(event);
  };

  return (
    <S.ValueInputWrapperCouple>
      <S.Label>{children}</S.Label>
      <S.ValueWrapperCouple>
        <S.ColorInput {...rest} type="color" value={selectedColor} onChange={handleColorChange} />
        <S.Input type="text" value={selectedColor} onChange={handleTextChange} />
      </S.ValueWrapperCouple>
    </S.ValueInputWrapperCouple>
  );
};

export default InputColorCheckout;
