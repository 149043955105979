import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { useListOrderBumps } from '@store/common/orderBump/ListOrderBumpsContext';
import { useAnalytics } from '@store/context/common/AnalyticsContext';

import EmptyData from '@components/common/utils/EmptyData';
import ListSkeletonLoading from '@components/common/utils/ListSkeletonLoading';
import Body from './Body';
import Header from './Header';

import * as S from './styles';

const ListOrderBumps: React.FC = () => {
  const navigate = useNavigate();
  const { analytics } = useAnalytics();
  const { accountId, checkoutId } = useParams();
  const { orderBumps, isLoadingOrderBumps, isOrderBumpsError } = useListOrderBumps();

  const isEmpty = orderBumps.length === 0;

  const onButtonClick = React.useCallback(() => {
    navigate(`/${accountId}/${checkoutId}/dashboard/marketing/order-bumps/new`);
  }, [navigate, accountId, checkoutId]);

  React.useEffect(() => {
    analytics.track('Order Bumps Page Viewed');
  }, [analytics]);

  if (isLoadingOrderBumps) {
    return <ListSkeletonLoading />;
  }

  if (isOrderBumpsError && !isLoadingOrderBumps) {
    return <div>Error...</div>;
  }

  if (isEmpty) {
    return (
      <EmptyData
        title="Nenhum order bump cadastrado"
        description="Adicione as informações e valores de frete para sua loja."
        buttonText="Novo order bump"
        onClick={onButtonClick}
      />
    );
  }

  return (
    <S.Wrapper>
      <Header />
      <Body />
    </S.Wrapper>
  );
};

export default ListOrderBumps;
