import styled from 'styled-components/macro';

import ButtonBase from '@components/common/Inputs/Button';

export const SaveHeaderBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 8px 24px;
  gap: 14px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  background: ${({ theme }) => theme.colors.primary.lemon_10};
  z-index: 2;
`;

export const SubmitButton = styled(ButtonBase)``;

export const CancelButton = styled(ButtonBase)`
  & > span {
    color: ${({ theme }) => theme.colors.neutral.darkest};
  }
`;
