import React from 'react';
import { Copy, Clock, Truck, WarningCircle } from 'phosphor-react';
import { useTheme } from 'styled-components/macro';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/common/EHeading';
import { EFulfillmentStatus } from '@domain/enums/common/fulfillment/EFulfillmentStatus';

import { useToast } from '@store/context/common/ToastContext';
import { useGetOrder } from '@store/common/order/GetOrder';

import * as S from './styles';

const ShippingCard: React.FC = () => {
  const theme = useTheme();
  const { toast } = useToast();
  const { order } = useGetOrder();

  const shippingAmount = '20 dias';
  // const shippingType = order?.shipping?.shipping_method;
  // const shippingStatus = order?.shipping?.status;
  const address = order?.shipping_address.line1;
  const addressNumber = order?.shipping_address.line2;
  const complement = order?.shipping_address.line3 || '-';
  const neighborhood = order?.shipping_address.neighborhood;
  const zipCode = order?.shipping_address.postal_code;
  const city = order?.shipping_address.city;
  const state = order?.shipping_address?.state;
  const shippingMethod = order?.shippings[0]?.name || '-';
  const orderStatus = order?.order.status;

  const copyTrackingNumber = React.useCallback(async () => {
    if (!order?.fulfillments.length) return;

    const mostRecentTracking = order.fulfillments[0].tracking_numbers?.[0];

    try {
      await navigator.clipboard.writeText(mostRecentTracking || '');

      toast.success('Código copiado com sucesso!');
    } catch (error) {
      toast.error('Não foi possível copiar o código.');
    }
  }, [order, toast]);

  const getTrackingCodeValue = React.useCallback(() => {
    if (!order?.fulfillments.length)
      return <S.NoTrackingNumberValue>Pedido não enviado</S.NoTrackingNumberValue>;

    const mostRecentTracking = order.fulfillments[0];

    if (!mostRecentTracking.tracking_numbers?.length)
      return <S.NoTrackingNumberValue>Rastreio não disponível</S.NoTrackingNumberValue>;

    return (
      <S.TrackingValue>
        {mostRecentTracking.tracking_numbers[0]}{' '}
        <S.CopyButton onClick={copyTrackingNumber}>
          <Copy color={theme.colors.primary.green_lemon} size={20} />
        </S.CopyButton>
      </S.TrackingValue>
    );
  }, [theme, copyTrackingNumber, order]);

  const getChipByShippingStatus = React.useCallback(() => {
    const shippingStatus = order?.fulfillments[0]?.status;

    if (
      !order?.fulfillments.length &&
      orderStatus !== 'PAID' &&
      orderStatus !== 'PARTIALLY_REFUNDED' &&
      orderStatus !== 'REFUNDED' &&
      orderStatus !== 'DISPUTED'
    ) {
      return '-';
    }

    if (!order?.fulfillments.length)
      return (
        <S.Chip backgroundColor="#F1F0F5" textColor="#023E8A">
          <Clock color="#023E8A" size={16} />
          AGUARDANDO ENVIO
        </S.Chip>
      );

    if (
      shippingStatus === EFulfillmentStatus.TRACEABLE ||
      shippingStatus === EFulfillmentStatus.IN_TRANSIT ||
      shippingStatus === EFulfillmentStatus.WAITING
    ) {
      return (
        <S.Chip backgroundColor="#F1F0F5" textColor="#023E8A">
          <Truck color="#023E8A" size={16} />
          EM TRÂNSITO
        </S.Chip>
      );
    }

    if (shippingStatus === EFulfillmentStatus.DELIVERED) {
      return (
        <S.Chip backgroundColor="#F1F0F5" textColor="#023E8A">
          <Truck color="#023E8A" size={16} />
          ENTREGUE
        </S.Chip>
      );
    }

    if (shippingStatus === EFulfillmentStatus.FAILED) {
      return (
        <S.Chip backgroundColor="#F1F0F5" textColor="#023E8A">
          <WarningCircle color="#023E8A" size={16} />
          FALHA NA ENTREGA
        </S.Chip>
      );
    }

    return <></>;
  }, [order, orderStatus]);

  return (
    <S.Wrapper>
      <S.TitleWrapper>
        <S.Title size={EHeadingSize.H6} fontWeight={EHeadingWeight.BOLD}>
          Envio
        </S.Title>

        {/* <S.EditButton onClick={() => setIsOpenModal(statePrev => !statePrev)}>Editar</S.EditButton> */}
      </S.TitleWrapper>

      <S.DataWrapper>
        <S.LabelAndValue>
          <S.Label>Código de Rastreio</S.Label>
          {getTrackingCodeValue()}
        </S.LabelAndValue>

        <S.LabelAndValue>
          <S.Label>Prazo</S.Label>
          <S.Value>{shippingAmount}</S.Value>
        </S.LabelAndValue>

        <S.LabelAndValue>
          <S.Label>Método de Envio</S.Label>
          <S.Value>{shippingMethod}</S.Value>
        </S.LabelAndValue>

        <S.LabelAndValue>
          <S.Label>Status</S.Label>
          {getChipByShippingStatus()}
        </S.LabelAndValue>

        <S.LabelAndValue>
          <S.Label>Logradouro</S.Label>
          <S.Value>{address}</S.Value>
        </S.LabelAndValue>

        <S.LabelAndValue>
          <S.Label>No.</S.Label>
          <S.Value>{addressNumber}</S.Value>
        </S.LabelAndValue>

        <S.LabelAndValue>
          <S.Label>Bairro</S.Label>
          <S.Value>{neighborhood}</S.Value>
        </S.LabelAndValue>

        <S.LabelAndValue>
          <S.Label>CEP</S.Label>
          <S.Value>{zipCode}</S.Value>
        </S.LabelAndValue>

        <S.LabelAndValue>
          <S.Label>Cidade</S.Label>
          <S.Value>{city}</S.Value>
        </S.LabelAndValue>

        <S.LabelAndValue>
          <S.Label>Estado</S.Label>
          <S.Value>{state}</S.Value>
        </S.LabelAndValue>

        <S.LabelAndValue>
          <S.Label>Complemento</S.Label>
          <S.Value>{complement}</S.Value>
        </S.LabelAndValue>
      </S.DataWrapper>
    </S.Wrapper>
  );
};

export default ShippingCard;
