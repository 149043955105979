export enum EChipColor {
  LIGHT_RED = 'LIGHT_RED',
  RED = 'RED',
  LIGHT_BLUE = 'LIGHT_BLUE',
  DARK_BLUE = 'DARK_BLUE',
  LIGHT_GREEN = 'LIGHT_GREEN',
  DARK_GREEN = 'DARK_GREEN',
  GRAY = 'GRAY',
  BLACK = 'BLACK',
  PRIMARY_LIGHT = 'PRIMARY_LIGHT',
  PRIMARY_DARK = 'PRIMARY_DARK',
  WHITE_BLUE = 'WHITE_BLUE',
}
