import React from 'react';

import { EHeadAlign } from '@domain/enums/components/common/ETable';
import { IStatementTransactions } from '@domain/interfaces/common/statementTransactions/IStatementTransactions';
import { EBalanceType } from '@domain/interfaces/store/common/statementTransactions/IGetStatementTransactionsProvider';

import { formatDateParts } from '@utils/common/date';
import { formatCurrency } from '@utils/common/formatCurrency';

import Badge from './Badge';

import * as S from './styles';

interface ITableRowProps {
  statementTransaction: IStatementTransactions;
  selectedBalance: EBalanceType;
}

const TableRow: React.FC<ITableRowProps> = ({ statementTransaction, selectedBalance }) => {
  const { id, created_at, amount, available_at } = statementTransaction;

  const formatDate = formatDateParts(created_at);
  const availableAtFormatted = formatDateParts(available_at);
  const formattedAmount = formatCurrency(amount / 100);

  const isNegative = formattedAmount?.includes('-');

  return (
    <S.Table.Row>
      <S.Table.Data>
        <S.Text>{id}</S.Text>
      </S.Table.Data>

      {selectedBalance === EBalanceType.Pending && (
        <S.Table.Data>
          <S.Text>{formatDate}</S.Text>
        </S.Table.Data>
      )}

      <S.Table.Data>
        <S.Text>{availableAtFormatted}</S.Text>
      </S.Table.Data>

      <S.Table.Data align={EHeadAlign.CENTER}>
        <Badge status={statementTransaction?.reason} />
      </S.Table.Data>

      <S.TableDataAmount align={EHeadAlign.RIGHT}>
        <S.TextAmount isnegative={isNegative}>{formattedAmount}</S.TextAmount>
      </S.TableDataAmount>
    </S.Table.Row>
  );
};

export default TableRow;
