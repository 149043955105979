import React from 'react';

import { NewBankAccountProvider } from '@store/common/BankAccount/NewBankAccountContext';

import Content from '@components/pages/Dashboard/BankAccount/NewBankAccount';

const NewBankAccount: React.FC = () => {
  return (
    <NewBankAccountProvider>
      <Content />
    </NewBankAccountProvider>
  );
};

export default NewBankAccount;
