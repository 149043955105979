import styled from 'styled-components/macro';
import ButtonBase from '@components/common/Inputs/Button';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.small_24};
  max-width: 1024px;
  margin: 0 auto;
`;

export const DeleteDomainButton = styled(ButtonBase)`
  align-self: self-start;
  background-color: transparent;
  border: none;

  > span {
    color: ${({ theme }) => theme.colors.negative.base};
    font-size: 1.6rem;
    font-weight: 500;
  }
`;
