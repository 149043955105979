import React from 'react';

import Option from './Option';

import * as S from './styles';

interface ISelectProps {
  size?: number;
  name: string;
  defaultValue?: string;
  children: React.ReactNode;
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  value?: string;
  autoFocus?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
}

interface ISelect {
  Option: typeof Option;
}

const Select: ISelect & React.ForwardRefRenderFunction<HTMLSelectElement, ISelectProps> = (
  {
    size,
    name,
    disabled,
    defaultValue,
    children,
    onChange,
    value,
    autoFocus,
    readOnly = false,
    ...rest
  },
  ref,
) => {
  return (
    <S.Container {...rest}>
      <S.Label>
        <S.Select
          size={size}
          ref={ref}
          name={name}
          disabled={disabled}
          defaultValue={defaultValue}
          onChange={onChange}
          value={value}
          autoFocus={autoFocus}
          readOnly={readOnly}
          tabIndex={readOnly ? -1 : undefined}
        >
          {children}
        </S.Select>
      </S.Label>
    </S.Container>
  );
};

Select.Option = Option;

export default React.forwardRef(Select);
