import styled from 'styled-components/macro';

import TableBase from '@components/common/utils/Table';
import CheckboxBase from '@components/common/Inputs/Checkbox';
import SideModalBase from '@components/common/DataDisplay/SideModal';
import CustomTooltipBase from '@components/common/utils/CustomTooltip';
import TextBase from '@components/common/DataDisplay/Text';

export const Wrapper = styled.div`
  height: 100%;
`;

export const Tooltip = styled(CustomTooltipBase)``;

export const TooltipContent = styled(CustomTooltipBase.Content)``;

export const TooltipText = styled(TextBase)`
  color: ${({ theme }) => theme.colors.neutral.gray_white};
  font-size: 1.2rem;
`;

export const Checkbox = styled(CheckboxBase)``;

export const IdContent = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const StockContent = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const Table = styled(TableBase)`
  thead {
    z-index: 1;
    background-color: #fff;
    position: sticky;
    top: 0;
  }

  th {
    padding-bottom: 16px !important;
  }

  tr > td:nth-child(3) {
    color: ${({ theme }) => theme.colors.primary.base};
  }
`;

export const VariantsSideModal = styled(SideModalBase)`
  width: 100%;
  max-width: 754px;
`;
