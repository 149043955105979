import React from 'react';

import { EStoreOnboardingStep } from '@domain/enums/common/onboarding/EStore';

import { useStoreOnboarding } from '@store/context/onboarding/store/StoreOnboardingContext';

import zoutiLogo from '@assets/images/common/logo.svg';

import Step from './Step';

import * as S from './styles';

const FormHeader: React.FC = () => {
  const { onboardingStep } = useStoreOnboarding();

  const isFirstStep = onboardingStep === EStoreOnboardingStep.FIRST_STEP;
  const isSecondStep = onboardingStep === EStoreOnboardingStep.SECOND_STEP;

  const isFirstStepFinished = onboardingStep !== EStoreOnboardingStep.FIRST_STEP;
  const isSecondStepFinished =
    onboardingStep !== EStoreOnboardingStep.FIRST_STEP &&
    onboardingStep !== EStoreOnboardingStep.SECOND_STEP;

  return (
    <S.Wrapper>
      <S.Logo src={zoutiLogo} alt="Zouti" />

      <S.StepperWrapper>
        <Step step={1} isCurrentStep={isFirstStep} isStepFinished={isFirstStepFinished} />
        <Step step={2} isCurrentStep={isSecondStep} isStepFinished={isSecondStepFinished} />

        <S.Line />
      </S.StepperWrapper>
    </S.Wrapper>
  );
};

export default FormHeader;
