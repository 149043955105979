import React from 'react';

import { EHeadAlign } from '@domain/enums/components/common/ETable';

import * as S from './styles';

interface IDataProps {
  align?: EHeadAlign;
  colSpan?: number;
  children?: React.ReactNode;
}

const Data: React.ForwardRefRenderFunction<HTMLTableCellElement, IDataProps> = (
  { align = EHeadAlign.LEFT, colSpan, children, ...rest },
  ref,
) => {
  return (
    <S.Data align={align} colSpan={colSpan} ref={ref} {...rest}>
      {children}
    </S.Data>
  );
};

export default React.forwardRef(Data);
