import React from 'react';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import * as S from './styles';

const AppSkeletonLoading: React.FC = () => {
  return (
    <S.Wrapper>
      <S.Header>
        <Skeleton width="30%" height="25px" />
      </S.Header>
      <S.Body>
        <S.ContainerIcon baseColor="#FBFBFB" width="28px" height="28px" />
        <S.Select borderRadius={25} width="5%" height="18px" />
        <S.ZoutiPay>
          <Skeleton width="100%" height="50%" />
          <Skeleton baseColor="#F9FAFB" width="100%" height="50%" />
        </S.ZoutiPay>

        <S.Credentials>
          <Skeleton width="100%" height="100%" />
        </S.Credentials>
      </S.Body>
    </S.Wrapper>
  );
};

export default AppSkeletonLoading;
