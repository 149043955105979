import React from 'react';

import * as S from './styles';

interface IVideoModalProps {
  isOpen: boolean;
  toggle: () => void;
  src: string;
}

const VideoModal: React.FC<IVideoModalProps> = ({ toggle, isOpen, src, ...rest }) => {
  return (
    <>
      {isOpen && (
        <>
          <S.ModalBackground onClick={toggle} />
          <S.ModalContent {...rest}>
            <S.VideoWrapper>
              <S.VideoFrame
                width="560"
                height="315"
                src={src}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </S.VideoWrapper>
          </S.ModalContent>
        </>
      )}
    </>
  );
};

export default VideoModal;
