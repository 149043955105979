import React from 'react';

import { GetOrderProvider } from '@store/common/order/GetOrder';

import Wrapper from '@components/pages/Dashboard/Order/GetOrder/Wrapper';

const GetOrder: React.FC = () => {
  return (
    <GetOrderProvider>
      <Wrapper />
    </GetOrderProvider>
  );
};

export default GetOrder;
