import React from 'react';
import useSWR from 'swr';
import { useNavigate, useParams } from 'react-router-dom';

import { ISwr } from '@domain/interfaces/common/swr/ISwr';
import { IPaymentCredential } from '@domain/interfaces/common/paymentCredential/IPaymentCredential';
import { IPaymentCredentialProvider } from '@domain/interfaces/store/common/paymentCredential/IPaymentCredentialProvider';

import { DASHBOARD_API_URL } from '@constants/services/apiUrl';
import { dashboardInstance } from '@services/common/dashboardInstance';

import { useErrorHandler } from '@store/context/common/ErrorHandlerContext';
import { usePaymentCredentialService } from '@hooks/services/paymentCredential/usePaymentCredential';
import { useAnalytics } from '@store/context/common/AnalyticsContext';
import { useToast } from '@store/context/common/ToastContext';
import { useGetAccountStep } from '../home/GetAccountStepContext';

const PaymentCredentialContext = React.createContext<IPaymentCredentialProvider | null>(null);

export const PaymentCredentialProvider: React.FC = ({ children }) => {
  const { accountId, checkoutId } = useParams();
  const navigate = useNavigate();
  const { handleError } = useErrorHandler();
  const { analytics } = useAnalytics();
  const { updatePaymentCredential, createPaymentCredential } = usePaymentCredentialService();
  const { propertiesFirstStep, mutate: mutateHome } = useGetAccountStep();
  const { toast } = useToast();

  const [isUpdatingPaymentCredential, setIsUpdatingPaymentCredential] =
    React.useState<boolean>(false);
  const [IsCreatingPaymentCredential, setIsCreatingPaymentCredential] =
    React.useState<boolean>(false);
  const [paymentCredential, setPaymentCredential] = React.useState<IPaymentCredential | undefined>(
    undefined,
  );

  const isFirstTime = Boolean(!propertiesFirstStep?.PAYMENT_INTEGRATED_AT);

  dashboardInstance.defaults.headers.common['x-zouti-account'] = accountId;

  const PAYMENT_CREDENTIAL_URL = `${DASHBOARD_API_URL}/accounts/${accountId}/checkouts/${checkoutId}/gateways/zouti/credentials`;

  const { data, isLoading, isValidating, mutate, error } = useSWR<ISwr>(
    PAYMENT_CREDENTIAL_URL,
    dashboardInstance,
  );

  React.useEffect(() => {
    if (error) {
      handleError(error);
    }

    if (data?.data.object) {
      setPaymentCredential(data.data.object);
    }
  }, [data, handleError, error]);

  const updateCurrentPaymentCredential = React.useCallback(
    async updatePaymentCredentialData => {
      setIsUpdatingPaymentCredential(true);

      try {
        await updatePaymentCredential({
          paymentCredentialId: updatePaymentCredentialData.paymentCredentialId,
          payload: updatePaymentCredentialData.payload,
          accountId,
          checkoutId,
        });

        analytics.track('Zouti Credential Updated');

        toast.success('Credencial atualizada com sucesso!');

        await mutate();

        setIsUpdatingPaymentCredential(false);
      } catch (errorPaymentCredential) {
        setIsUpdatingPaymentCredential(false);
        handleError(errorPaymentCredential);
      }
    },
    [updatePaymentCredential, mutate, accountId, checkoutId, handleError, analytics, toast],
  );

  const createNewPaymentCredential = React.useCallback(
    async payload => {
      setIsCreatingPaymentCredential(true);

      try {
        await createPaymentCredential({ payload, accountId, checkoutId });

        await new Promise(resolve => setTimeout(resolve, 3000));

        await mutateHome();

        analytics.track('Zouti Credential Created');

        toast.success('Credencial adicionada com sucesso!');

        setIsCreatingPaymentCredential(false);

        if (isFirstTime) {
          navigate(`/${accountId}/${checkoutId}/dashboard/home`);
        }
      } catch (errorNewPaymentCredential) {
        setIsCreatingPaymentCredential(false);
        handleError(errorNewPaymentCredential);
      }
    },
    [
      createPaymentCredential,
      accountId,
      checkoutId,
      handleError,
      analytics,
      toast,
      mutateHome,
      navigate,
      isFirstTime,
    ],
  );

  const isLoadingPaymentCredential = isLoading || isValidating;
  const isPaymentCredentialError = Boolean(error);

  return (
    <PaymentCredentialContext.Provider
      value={{
        createNewPaymentCredential,
        paymentCredential,
        isLoadingPaymentCredential,
        isPaymentCredentialError,
        updateCurrentPaymentCredential,
        isUpdatingPaymentCredential,
        IsCreatingPaymentCredential,
        mutate,
      }}
    >
      {children}
    </PaymentCredentialContext.Provider>
  );
};

export const usePaymentCredential = (): IPaymentCredentialProvider => {
  const context = React.useContext(PaymentCredentialContext);

  if (!context) {
    throw new Error('usePaymentCredentialContext must be used within provider');
  }

  return context;
};
