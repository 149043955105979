import React from 'react';

import { useTheme } from 'styled-components';

import { numberFormatter } from '@helpers/utils/common/numberFormatter';

import * as S from './styles';

const ChartTooltip: React.FC = ({ active, payload }: any) => {
  const theme = useTheme();

  if (active && payload && payload.length) {
    return (
      <S.TooltipCard>
        <S.LabelWrapper key={payload[0].dataKey}>
          <S.DotColorAndValuesWrapper>
            <S.DotColor color={payload[0].color} />

            <S.Values color={theme.colors.neutral.darker}>
              R$ {payload[0].value ? numberFormatter(payload[0].value, 2) : '0'}
            </S.Values>
          </S.DotColorAndValuesWrapper>

          <S.LabelText>{`${payload[0].payload.name}`}</S.LabelText>
        </S.LabelWrapper>

        <S.LabelWrapper key={payload[1].dataKey}>
          <S.DotColorAndValuesWrapper>
            <S.DotColor color={payload[1].color} />

            <S.Values color={theme.colors.neutral.darker}>
              R$ {payload[1].past ? numberFormatter(payload[1].past, 2) : '0'}
            </S.Values>
          </S.DotColorAndValuesWrapper>

          <S.LabelText>{`${payload[1].payload.past_name}`}</S.LabelText>
        </S.LabelWrapper>
      </S.TooltipCard>
    );
  }

  return null;
};

export default ChartTooltip;
