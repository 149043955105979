import React from 'react';

import { EUserOnboardingStep } from '@domain/enums/common/onboarding/EUser';

import { useUserOnboarding } from '@store/context/onboarding/user/UserOnboardingContext';

import zoutiLogo from '@assets/images/common/logo.svg';

import Step from './Step';

import * as S from './styles';

const FormHeader: React.FC = () => {
  const { onboardingStep } = useUserOnboarding();

  const isFirstStep = onboardingStep === EUserOnboardingStep.FIRST_STEP;
  const isSecondStep = onboardingStep === EUserOnboardingStep.SECOND_STEP;
  const isThirdStep = onboardingStep === EUserOnboardingStep.THIRD_STEP;

  const isFirstStepFinished = onboardingStep !== EUserOnboardingStep.FIRST_STEP;
  const isSecondStepFinished =
    onboardingStep !== EUserOnboardingStep.FIRST_STEP &&
    onboardingStep !== EUserOnboardingStep.SECOND_STEP;

  return (
    <S.Wrapper>
      <S.Logo src={zoutiLogo} alt="Zouti" />

      <S.StepperWrapper>
        <Step step={1} isCurrentStep={isFirstStep} isStepFinished={isFirstStepFinished} />
        <Step step={2} isCurrentStep={isSecondStep} isStepFinished={isSecondStepFinished} />
        <Step step={3} isCurrentStep={isThirdStep} isStepFinished={false} />

        <S.Line />
      </S.StepperWrapper>
    </S.Wrapper>
  );
};

export default FormHeader;
