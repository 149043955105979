import styled from 'styled-components/macro';

import HeadingBase from '@components/common/DataDisplay/Heading';
import TextBase from '@components/common/DataDisplay/Text';
import ButtonBase from '@components/common/Inputs/Button';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 360px;
  width: 100%;
`;

export const Title = styled(HeadingBase)`
  margin-bottom: 8px;
  text-align: center;
`;

export const Description = styled(TextBase)`
  margin-bottom: 24px;
  font-size: 1.6rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.neutral.base};
  text-align: center;
`;

export const Button = styled(ButtonBase)``;
