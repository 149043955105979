import React from 'react';
import { useParams } from 'react-router-dom';
import CashBackImage from '@assets/images/common/cashback-every.svg';
import { EHeadingSize } from '@domain/enums/components/common/EHeading';
import { secondStepValues } from '@constants/pages/dashboard/home';
import { useGetAccountStep } from '@store/common/home/GetAccountStepContext';
import LinearProgress from '../../LinearProgress';

import * as S from './styles';

const SecondStep: React.FC = () => {
  const { accountId, checkoutId } = useParams();
  const { propertiesSecondStep, countSecondStep, hoveredIndex, setHoveredIndex, isSecondStepDone } =
    useGetAccountStep();

  const onRowMouseEnter = React.useCallback(
    index => {
      setHoveredIndex(index);
    },
    [setHoveredIndex],
  );

  const onRowMouseLeave = React.useCallback(() => {
    setHoveredIndex(null);
  }, [setHoveredIndex]);

  const stepValues = secondStepValues(accountId, checkoutId);

  const SecondStepComponent = (): React.ReactElement => {
    return (
      <>
        <S.StepCard>
          <S.HeaderStepCard>
            <S.WrapperHeaderIconAndTitle>
              <S.IconContainer>
                <S.RocketIcon weight="duotone" size={16} />
              </S.IconContainer>
              <S.HeaderTitle size={EHeadingSize.H6}>Venda ainda mais!</S.HeaderTitle>
            </S.WrapperHeaderIconAndTitle>
            <S.WrapperLinearProgress>
              <S.TextStep>
                {countSecondStep} DE {stepValues?.length} ETAPAS
              </S.TextStep>
              <LinearProgress qtdStepDone={countSecondStep} quantitySteps={stepValues?.length} />
            </S.WrapperLinearProgress>
          </S.HeaderStepCard>
          <S.WrapperRows>
            {stepValues?.map((item, index) => (
              <S.StepCardRow
                onMouseEnter={() => onRowMouseEnter(index)}
                onMouseLeave={onRowMouseLeave}
                key={index.toFixed()}
              >
                <S.Link to={item?.path || ''}>
                  {propertiesSecondStep[item.key] ? <S.CheckCircleIcon /> : <S.CircleIcon />}
                  <S.WrapperText>
                    <S.Title>{item.title}</S.Title>
                    <S.Description>{item.description}</S.Description>
                  </S.WrapperText>
                  <S.WrapperConfig>
                    {hoveredIndex === index && (
                      <S.WrapperConfigAndArrowIcon>
                        <S.TextLink> Configurar</S.TextLink>
                        <S.ArrowUpRightIcon />
                      </S.WrapperConfigAndArrowIcon>
                    )}
                  </S.WrapperConfig>
                </S.Link>
              </S.StepCardRow>
            ))}
          </S.WrapperRows>
        </S.StepCard>
      </>
    );
  };
  const SecondStepDoneComponent = (): React.ReactElement => {
    return (
      <S.SecondStepDoneCard>
        <S.SecondStepDoneImage src={CashBackImage} />
        <S.SecondStepDoneTitle>Lorem ipsum dolor amed</S.SecondStepDoneTitle>
        <S.SecondStepDoneDescription>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          ut labore et dolore magna.
        </S.SecondStepDoneDescription>
      </S.SecondStepDoneCard>
    );
  };

  return (
    <S.Wrapper>
      {isSecondStepDone ? <SecondStepDoneComponent /> : <SecondStepComponent />}
    </S.Wrapper>
  );
};

export default SecondStep;
