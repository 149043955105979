import React from 'react';
import useSWR from 'swr';
import { useParams } from 'react-router-dom';

import { ISwr } from '@domain/interfaces/common/swr/ISwr';
import { IAbandonedCart } from '@domain/interfaces/common/AbandonedCart/IAbandonedCart';
import { IListAbandonedCartProvider } from '@domain/interfaces/store/common/AbandonedCart/IListAbandonedCartProvider';
import { IListAbandonedCartPromiseResponse } from '@domain/interfaces/hooks/services/abandonedCart/IAbandonedCartService';

import { DASHBOARD_API_URL } from '@constants/services/apiUrl';
import { dashboardInstance } from '@services/common/dashboardInstance';

import { useErrorHandler } from '@store/context/common/ErrorHandlerContext';
import { IRecoveryTemplates } from '@domain/interfaces/common/recovery/IRecoveryTemplates';
import { IGetRecoveryTemplatesResponse } from '@domain/interfaces/hooks/services/recoveryTemplate/IRecoveryTemplateService';

const ListAbandonedCartContext = React.createContext<IListAbandonedCartProvider | null>(null);

export const ListAbandonedCartProvider: React.FC = ({ children }) => {
  const { handleError } = useErrorHandler();
  const { accountId, checkoutId } = useParams();

  const [abandonedCarts, setAbandonedCarts] = React.useState<IAbandonedCart[]>([]);
  const [page, setPage] = React.useState<number>(0);
  const [totalPages, setTotalPages] = React.useState<number>(1);
  const [abandonedCartsTotal, setAbandonedCartsTotal] = React.useState<number>(0);

  dashboardInstance.defaults.headers.common['x-zouti-account'] = accountId;

  const ABANDONED_CART_URL = `${DASHBOARD_API_URL}/accounts/${accountId}/checkouts/${checkoutId}/abandoned_carts?page=${
    page + 1
  }&limit=10`;
  const RECOVERY_TEMPLATES_URL = `${DASHBOARD_API_URL}/accounts/${accountId}/checkouts/${checkoutId}/recovery_templates`;

  const [accountRecoveryTemplate, setAccountRecoveryTemplate] = React.useState<
    Array<IRecoveryTemplates>
  >([]);

  const { data, isLoading, isValidating, error } = useSWR<ISwr<IListAbandonedCartPromiseResponse>>(
    ABANDONED_CART_URL,
    dashboardInstance,
  );

  const {
    data: recoveryTemplatesData,
    isLoading: isLoadingRecoveryTemplates,
    error: recoveryTemplatesError,
  } = useSWR<ISwr<IGetRecoveryTemplatesResponse>>(RECOVERY_TEMPLATES_URL, dashboardInstance);

  const handlePageChange = React.useCallback(selected => {
    setPage(selected);
  }, []);

  React.useEffect(() => {
    if (error) {
      handleError(error);
    }

    if (data?.data.object) {
      setAbandonedCarts(data.data.object.data);
      setTotalPages(data.data.object.total_pages);
      setAbandonedCartsTotal(data.data.object.total);
    }
  }, [data, handleError, error]);

  React.useEffect(() => {
    if (recoveryTemplatesError) {
      handleError(recoveryTemplatesError);
      return;
    }

    if (recoveryTemplatesData?.data?.object) {
      setAccountRecoveryTemplate(recoveryTemplatesData.data.object);
    }
  }, [recoveryTemplatesData, recoveryTemplatesError, handleError]);

  const isLoadingListAbandonedCart = isLoading || isValidating || isLoadingRecoveryTemplates;
  const isAbandonedCartError = Boolean(error) || recoveryTemplatesError;
  const isEmptyState = abandonedCarts?.length === 0;

  return (
    <ListAbandonedCartContext.Provider
      value={{
        page,
        totalPages,
        isEmptyState,
        abandonedCarts,
        isAbandonedCartError,
        isLoadingListAbandonedCart,
        accountRecoveryTemplate,
        abandonedCartsTotal,
        handlePageChange,
      }}
    >
      {children}
    </ListAbandonedCartContext.Provider>
  );
};

export const useListAbandonedCart = (): IListAbandonedCartProvider => {
  const context = React.useContext(ListAbandonedCartContext);

  if (!context) {
    throw new Error('useListAbandonedCart must be used within provider');
  }

  return context;
};
