import React from 'react';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/common/EHeading';

import { formatCurrency } from '@utils/common/formatCurrency';

import { useGetOrder } from '@store/common/order/GetOrder';

import * as S from './styles';

const ProductCard: React.FC = () => {
  const { order } = useGetOrder();

  return (
    <S.Wrapper>
      <S.Title size={EHeadingSize.H5} fontWeight={EHeadingWeight.BOLD}>
        Produtos
      </S.Title>

      <S.Divider />

      <S.ProductsWrapper>
        {order?.products.map((product, index) => (
          <S.ProductWrapper key={index.toFixed()}>
            {product.image_url ? (
              <S.Image src={product.image_url} alt={product.name} />
            ) : (
              <S.EmptyImage />
            )}

            <S.DescriptionWrapper>
              <S.Label>Descrição</S.Label>
              <S.Value>{product.name}</S.Value>
            </S.DescriptionWrapper>

            <S.VariantWrapper>
              <S.Label>Variação</S.Label>
              <S.Value>{product.variant_name}</S.Value>
            </S.VariantWrapper>

            <S.SKUWrapper>
              <S.Label>SKU</S.Label>
              <S.Value>{product.sku || '-'}</S.Value>
            </S.SKUWrapper>

            <S.QuantityWrapper>
              <S.Label>Qtde</S.Label>
              <S.Value>{product.quantity}</S.Value>
            </S.QuantityWrapper>

            <S.UnitPriceWrapper>
              <S.Label>Preço Un.</S.Label>
              <S.Value>
                {formatCurrency(product.unit_price / 100, order?.language, order?.order?.currency)}
              </S.Value>
            </S.UnitPriceWrapper>

            <S.TotalPriceWrapper>
              <S.Label>Subtotal</S.Label>
              <S.Value>
                {formatCurrency(product.sub_total / 100, order?.language, order?.order?.currency)}
              </S.Value>
            </S.TotalPriceWrapper>
          </S.ProductWrapper>
        ))}
      </S.ProductsWrapper>
    </S.Wrapper>
  );
};

export default ProductCard;
