import React from 'react';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import * as S from './styles';

const SkeletonLoading: React.FC = () => {
  return (
    <S.Wrapper>
      <S.Header>
        <Skeleton width="40%" height="25px" />
        <Skeleton width="70%" height="10px" />
      </S.Header>
      <S.WrapperContent>
        {Array.from(new Array(7).keys()).map((index: number) => (
          <S.InputGroup key={index}>
            <Skeleton circle width="20px" height="20px" />
            <S.WrapperInput>
              <Skeleton width="30%" height="10px" />
              <Skeleton width="100%" height="20px" />
            </S.WrapperInput>
          </S.InputGroup>
        ))}
      </S.WrapperContent>
    </S.Wrapper>
  );
};

export default SkeletonLoading;
