import React from 'react';
import { useUpdateSocialProof } from '@store/common/socialProof/UpdateSocialProofContext';

import Body from './Body';
import Header from './Header';
import SkeletonLoading from './SkeletonLoading';

import * as S from './styles';

const SocialProof: React.FC = () => {
  const { isLoadingSocialProof } = useUpdateSocialProof();

  if (isLoadingSocialProof) {
    return <SkeletonLoading />;
  }

  return (
    <S.Wrapper>
      <Header />

      <Body />
    </S.Wrapper>
  );
};

export default SocialProof;
