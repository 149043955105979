import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useListWebhooks } from '@store/common/webhook/ListWebhooksContext';

import EmptyData from '@components/common/utils/EmptyData';
import ListSkeletonLoading from '@components/common/utils/ListSkeletonLoading';
import Header from './Header';
import Body from './Body';

import * as S from './styles';

const ListWebhooks: React.FC = () => {
  const navigate = useNavigate();
  const { webhooks, isLoadingWebhooks, isWebhooksError } = useListWebhooks();
  const { accountId, checkoutId } = useParams();

  const onEmptyDataButtonClick = React.useCallback(() => {
    navigate(`/${accountId}/${checkoutId}/dashboard/apps/webhooks/new`);
  }, [navigate, accountId, checkoutId]);

  const isEmpty = webhooks.length === 0;

  if (isLoadingWebhooks) {
    return <ListSkeletonLoading />;
  }

  if (isWebhooksError) {
    return <div>Error...</div>;
  }

  if (isEmpty) {
    return (
      <EmptyData
        title="Nenhum webhook cadastrado"
        description="Adicione webhooks para enviar os eventos de sua loja."
        buttonText="Novo webhook"
        onClick={onEmptyDataButtonClick}
      />
    );
  }

  return (
    <S.Wrapper>
      <Header />

      <Body />
    </S.Wrapper>
  );
};

export default ListWebhooks;
