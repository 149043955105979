import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import queryString from 'query-string';
import { CaretDown, MagnifyingGlass } from '@phosphor-icons/react';
import { useTheme } from 'styled-components/macro';
import { useParams } from 'react-router-dom';

import { IProduct } from '@domain/interfaces/common/product/IProducts';
import {
  IUpsellOfferProduct,
  IUpsellTriggerProduct,
} from '@domain/interfaces/common/upsell/IUpsell';

import { useProductService } from '@hooks/services/product/useProductsService';
import { useDebounce } from '@hooks/common/useDebounce';

import * as S from './styles';

interface IProductSelectProps {
  onProductSelect: (product: IUpsellOfferProduct | IUpsellTriggerProduct) => void;
  defaultProduct?: any;
}

const ProductSelect: React.FC<IProductSelectProps> = ({ onProductSelect, defaultProduct }) => {
  const theme = useTheme();
  const { accountId, checkoutId } = useParams();
  const { listProducts } = useProductService();

  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [currentPage, setCurrentPage] = React.useState<number>(0);
  const [totalPages, setTotalPages] = React.useState<number>(1);
  const [products, setProducts] = React.useState<Array<IProduct>>([]);
  const [isLoadingProducts, setIsLoadingProducts] = React.useState<boolean>(false);
  const [searchName, setSearchName] = React.useState<string>('');
  const [previousSearchName, setPreviousSearchName] = React.useState<string>('');
  const [selectedProduct, setSelectedProduct] = React.useState<IProduct | undefined>(
    defaultProduct,
  );
  const [triggerWidth, setTriggerWidth] = React.useState<number>(0);

  const listProductsRef = React.useRef(listProducts);
  const triggerRef = React.useRef<HTMLButtonElement>(null);

  const debouncedSearchName = useDebounce(searchName, 500);

  const onSearchName = React.useCallback(event => setSearchName(event.target.value), []);

  const loadProducts: (page: number, name: string, previousName: string) => Promise<void> =
    React.useCallback(
      async (page, name, previousName) => {
        setIsLoadingProducts(true);

        try {
          const queryStringfiedQuery = queryString.stringify({ name });

          if (name !== previousName) {
            const { data } = await listProductsRef.current({
              accountId,
              checkoutId,
              page: 1,
              perPage: 20,
              query: queryStringfiedQuery,
            });

            setCurrentPage(0);
            setProducts([...data.object.data]);
            setTotalPages(data.object.total_pages);
            setPreviousSearchName(name);
            setIsLoadingProducts(false);

            return;
          }

          const { data } = await listProductsRef.current({
            accountId,
            checkoutId,
            page: page + 1,
            perPage: 20,
            query: queryStringfiedQuery,
          });

          setProducts(previousData => [...previousData, ...data.object.data]);
          setTotalPages(data.object.total_pages);
          setIsLoadingProducts(false);
        } catch {
          console.log('error');
        }

        setIsLoadingProducts(false);
      },
      [accountId, checkoutId],
    );

  const reset = React.useCallback(() => {
    setProducts([]);
    setCurrentPage(0);
    setTotalPages(1);
    setPreviousSearchName('');
    setSearchName('');
  }, []);

  const onSelectedProductClick = React.useCallback(
    product => {
      const payload: IUpsellOfferProduct | IUpsellTriggerProduct = {
        id: product.id,
        images: product.images,
        variants: product.variants,
        product,
      };

      setSelectedProduct(product);
      onProductSelect(payload);
      setIsOpen(false);
      reset();
    },
    [reset, onProductSelect],
  );

  const nextPage = React.useCallback(() => {
    loadProducts(currentPage + 1, debouncedSearchName, previousSearchName);

    setCurrentPage(currentPage + 1);
  }, [currentPage, debouncedSearchName, previousSearchName, loadProducts]);

  const handleOpen = React.useCallback(
    state => {
      if (state) {
        loadProducts(currentPage, debouncedSearchName, previousSearchName);
      } else {
        reset();
      }

      setIsOpen(state);
    },
    [loadProducts, reset, currentPage, debouncedSearchName, previousSearchName],
  );

  React.useEffect(() => {
    if (defaultProduct) setSelectedProduct(defaultProduct);
  }, [defaultProduct]);

  React.useEffect(() => {
    if (debouncedSearchName !== previousSearchName) {
      loadProducts(currentPage, debouncedSearchName, previousSearchName);
    }
  }, [debouncedSearchName, previousSearchName, loadProducts, currentPage]);

  React.useLayoutEffect(() => {
    if (!triggerRef.current) return;

    setTriggerWidth(triggerRef.current.offsetWidth);
  }, []);

  const hasMore = currentPage + 1 < totalPages;

  return (
    <S.Dropdown open={isOpen} onOpenChange={handleOpen}>
      <S.DropdownTrigger type="button" ref={triggerRef}>
        <S.TriggerContentWrapper>
          {selectedProduct ? (
            <S.ImageAndProductNameWrapper>
              {selectedProduct.images.length ? (
                <S.SelectImage src={selectedProduct.images[0].url} alt="Zouti Products" />
              ) : (
                <S.NoImage />
              )}

              <S.SelectProductName>
                {(selectedProduct as any)?.product?.name || selectedProduct.name}
              </S.SelectProductName>
            </S.ImageAndProductNameWrapper>
          ) : (
            <S.SelectProductName>Selecione o produto</S.SelectProductName>
          )}
        </S.TriggerContentWrapper>

        <CaretDown size={20} weight="bold" color={theme.colors.neutral.dark} />
      </S.DropdownTrigger>

      <S.DropdownContent width={triggerWidth} sideOffset={10}>
        <S.SearchInput
          onChange={onSearchName}
          type="text"
          icon={MagnifyingGlass}
          placeholder="Procurar produto"
        />

        <S.ScrollableContainer id="products-list">
          <InfiniteScroll
            dataLength={products.length}
            next={nextPage}
            hasMore={hasMore}
            loader={<div>Loading...</div>}
            scrollableTarget="products-list"
            scrollThreshold={0.95}
          >
            {isLoadingProducts && products.length === 0 && (
              <S.ProductNotFoundContainer>
                <S.TextContainerNotFound>Loading...</S.TextContainerNotFound>
              </S.ProductNotFoundContainer>
            )}
            {products.length === 0 && !isLoadingProducts && (
              <S.ProductNotFoundContainer>
                <S.TextContainerNotFound>Produto não encontrado!</S.TextContainerNotFound>
              </S.ProductNotFoundContainer>
            )}

            {products?.map(product => {
              return (
                <S.ProductWrapper key={product.id} onClick={() => onSelectedProductClick(product)}>
                  {product.images.length ? (
                    <S.SelectImage src={product.images[0].url} alt="Zouti Product" />
                  ) : (
                    <S.NoImage />
                  )}
                  <S.ProductName>{product.name}</S.ProductName>
                </S.ProductWrapper>
              );
            })}
          </InfiniteScroll>
        </S.ScrollableContainer>
      </S.DropdownContent>
    </S.Dropdown>
  );
};

export default ProductSelect;
