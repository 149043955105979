import React from 'react';

import { useUpdatePixel } from '@store/common/pixel/UpdatePixelContext';

import { PROVIDERS } from '@constants/common/pixelProviders';

import { EButtonVariant } from '@domain/enums/components/common/EButton';
import { useNavigate, useParams } from 'react-router-dom';
import Forms from './Forms';

import * as S from './styles';

const Body: React.FC = () => {
  const navigate = useNavigate();
  const { accountId, checkoutId } = useParams();
  const { pixel, isUpdatingPixel, handleSubmit, onSubmit } = useUpdatePixel();

  const { provider } = pixel;

  const providerIcon = PROVIDERS.find(item => item.name === provider)?.icon;
  const providerName = PROVIDERS.find(item => item.name === provider)?.text;

  const onCancelButtonClick = (): void => {
    navigate(`/${accountId}/${checkoutId}/dashboard/marketing/pixels`);
  };

  return (
    <S.Wrapper onSubmit={handleSubmit(onSubmit)}>
      <S.Form>
        <S.Card>
          <S.InputGroup>
            <S.Label>Plataforma</S.Label>

            <S.Dropdown>
              <S.DropdownTrigger isDropdownOpen>
                <S.ProviderIcon src={providerIcon} />
                <S.ProviderName>{providerName}</S.ProviderName>
              </S.DropdownTrigger>
            </S.Dropdown>
          </S.InputGroup>

          <Forms provider={provider} />
        </S.Card>
        <S.ButtonsWrapper>
          <S.CancelButton onClick={onCancelButtonClick} variant={EButtonVariant.SECONDARY}>
            Cancelar
          </S.CancelButton>
          <S.SubmitButton type="submit" isLoading={isUpdatingPixel}>
            Salvar
          </S.SubmitButton>
        </S.ButtonsWrapper>
      </S.Form>
    </S.Wrapper>
  );
};

export default Body;
