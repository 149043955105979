import styled from 'styled-components/macro';

import ButtonBase from '@components/common/Inputs/Button';
import HeadingBase from '@components/common/DataDisplay/Heading';
import InputGroupBase from '@components/common/Inputs/InputGroup';
import LabelBase from '@components/common/DataDisplay/Label';
import TextAreaBase from '@components/common/Inputs/TextArea';
import TextBase from '@components/common/DataDisplay/Text';
import TextFieldBase from '@components/common/Inputs/TextField';
import TooltipBase from '@components/pages/Dashboard/CheckoutCustomization/Tooltip';
import SwitchBase from '@components/common/Inputs/Switch';

import { Info as Icon } from 'phosphor-react';

export const Form = styled.form``;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.neutral.white};
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  border-radius: 4px;
  padding: 24px;
`;

export const Divider = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.neutral.lightest};
  margin: 16px 0 24px 0;
`;

export const Title = styled(HeadingBase)`
  color: ${({ theme }) => theme.colors.neutral.darkest};
`;

export const ColorSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const InputColorWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 16px;

  & > div:nth-child(3) {
    grid-column: span 2;
  }

  @media (max-width: 768px) {
    & > div {
      grid-column: span 2;
    }
  }
`;

export const InputColorGroup = styled.div``;

export const TextInfoAndTooltipWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const InputGroup = styled(InputGroupBase)``;

export const LogoSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ContainerDropzone = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 24px;
  border: 2px dashed ${({ theme }) => theme.colors.primary.dark_green_40};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.primary.lemon_20};
  transition: border 0.24s ease-in-out;
  cursor: pointer;

  svg {
    color: ${({ theme }) => theme.colors.primary.dark_green_40};
  }
`;

export const TextDropzone = styled(TextBase)`
  font-weight: 500;
`;
export const AcceptFormat = styled(TextBase)`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.neutral.base};
`;

export const PreviewDropzone = styled.div`
  display: flex;
  align-self: center;
  justify-content: center;
  aspect-ratio: 10 / 3;
  width: 300px;
  height: 90px;
`;

export const PreviewCardInfo = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(2, 1fr);
  border-radius: 4px;
  padding: 5px 24px;
  background-color: ${({ theme }) => theme.colors.neutral.gray_white};

  & > span:nth-child(1) {
    grid-column: span 5;
    grid-row: 1;
  }

  & > span:nth-child(2) {
    grid-column: span 5;
    grid-row: 2;
  }

  & > svg {
    cursor: pointer;
    align-self: center;
    justify-self: end;
    padding: 5px;
    color: ${({ theme }) => theme.colors.primary.dark_green_40};
    grid-column: 6;
    grid-row: span 2;

    &:active {
      filter: brightness(0.9);
    }

    &:hover {
      background-color: ${({ theme }) => theme.colors.primary.lemon_20};
    }
  }
`;
export const FileName = styled(TextBase)`
  color: ${({ theme }) => theme.colors.neutral.darker};
  font-weight: 500;
`;

export const FileSize = styled(TextBase)`
  color: ${({ theme }) => theme.colors.neutral.base};
  font-weight: 500;
  font-size: 12px;
`;

export const Image = styled.img`
  width: 100%;
`;

export const TextInfo = styled(TextBase)`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.neutral.dark};
`;

export const InfoIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.neutral.dark};
`;

export const StopwatchSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Switch = styled(SwitchBase)``;

export const SwitchLabel = styled(LabelBase)`
  margin: 0;
  font-weight: 500;
`;

export const Tooltip = styled(TooltipBase)``;

export const TimerLabel = styled(LabelBase)``;

export const TimeButton = styled.button`
  background-color: unset;
  box-shadow: unset;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const TimeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const TimeWrapperButtons = styled.div`
  display: flex;
  align-content: center;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  width: fit-content;
  border-radius: 4px;

  & > div:nth-child(2) {
    border: unset;
    width: 100px;
    border-radius: unset;
    border-left: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
    border-right: 1px solid ${({ theme }) => theme.colors.neutral.lightest};

    & > input {
      text-align: center;
    }
  }
`;

export const TimeTextSpan = styled(TextBase)`
  color: ${({ theme }) => theme.colors.neutral.base};
`;

export const Input = styled(TextFieldBase)``;

export const ConfigInputColorWrapper = styled.div`
  display: flex;
  gap: 16px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const TextArea = styled(TextAreaBase)``;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
  gap: 16px;
  justify-content: flex-end;
`;

export const SubmitButton = styled(ButtonBase)``;

export const CancelButton = styled(ButtonBase)`
  & > span {
    color: ${({ theme }) => theme.colors.neutral.darkest};
  }
`;
