import React from 'react';
import { useToast } from '@store/context/common/ToastContext';

import * as S from './styles';

interface ICopyAreaProps {
  type: string;
  host: string;
  value: string;
}

export const CopyArea: React.FC<ICopyAreaProps> = ({ type, host, value }) => {
  const { toast } = useToast();

  const copyTextToClipboard = async (text: string): Promise<void> => {
    try {
      await navigator.clipboard.writeText(text);
      toast.success('Copiado com sucesso!');
    } catch (err) {
      toast.error('Erro ao copiar!');
    }
  };

  const getHostValue = React.useCallback(() => {
    if (value.includes('acm-validation')) {
      return host.split('.')[0];
    }

    return host;
  }, [host, value]);

  return (
    <S.CopyArea>
      <S.CopyWrapperItens>
        <S.CopyAreaKey>Tipo</S.CopyAreaKey>
        <S.CopyAreaValue>{type}</S.CopyAreaValue>
      </S.CopyWrapperItens>

      <S.CopyWrapperItens>
        <S.CopyAreaKey>Host</S.CopyAreaKey>
        <S.CopyAreaValue>{getHostValue()}</S.CopyAreaValue>
        <S.CopyIcon onClick={() => copyTextToClipboard(getHostValue())} />
      </S.CopyWrapperItens>
      <S.CopyWrapperItens>
        <S.CopyAreaKey>Valor</S.CopyAreaKey>
        <S.CopyAreaValue>{value}</S.CopyAreaValue>
        <S.CopyIcon onClick={() => copyTextToClipboard(value)} />
      </S.CopyWrapperItens>
    </S.CopyArea>
  );
};
