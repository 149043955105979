export default {
  weights: {
    regular: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900,
  },
  sizes: {
    larger: '32px',
    extraLarge: '24px',
    large: '18px',
    paragraph: '16px',
    medium: '14px',
    caption: '12px',
    overline: '10px',
  },
};
