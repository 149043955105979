import React from 'react';
import { WarningOctagon } from 'phosphor-react';

import { useGetBankAccount } from '@store/common/BankAccount/GetBankAccountContext';

import { useTheme } from 'styled-components/macro';

import * as S from './styles';

const DeleteBankAccountModal: React.FC = () => {
  const theme = useTheme();
  const { deleteCurrentBankAccount, isDeletingBankAccount, isOpenModal, setIsOpenModal } =
    useGetBankAccount();

  const onDeleteDomainButtonClick = (): void => {
    deleteCurrentBankAccount(isOpenModal?.bankAccountId);
  };

  return (
    <S.Modal
      title="Excluir conta bancária"
      toggle={() => setIsOpenModal(state => ({ isOpen: !state.isOpen }))}
      icon={<WarningOctagon size={24} weight="fill" color={theme?.colors.negative.base} />}
      isOpen={isOpenModal?.isOpen}
    >
      <S.Text>
        Você está prestes a excluir a sua conta bancária. Tem certeza que deseja prosseguir? Essa
        ação é irreversível.
      </S.Text>
      <S.ModalAction>
        <S.CancelButton onClick={() => setIsOpenModal(state => ({ isOpen: !state.isOpen }))}>
          Cancelar
        </S.CancelButton>
        <S.ConfirmButton isLoading={isDeletingBankAccount} onClick={onDeleteDomainButtonClick}>
          Confirmar
        </S.ConfirmButton>
      </S.ModalAction>
    </S.Modal>
  );
};

export default DeleteBankAccountModal;
