import React from 'react';
import { ArrowUpRight } from 'phosphor-react';
import { useTheme } from 'styled-components/macro';
import {
  ResponsiveContainer,
  BarChart as Chart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from 'recharts';

import { useGetGeneral } from '@store/common/general/GetGeneralContext';
import { numberFormatter } from '@helpers/utils/common/numberFormatter';
import ChartTooltip from './Tooltip';

import * as S from './styles';
import CheckoutConversionSkeleton from '../Skeletons/CheckoutConversion';

const CheckoutConversion: React.FC = () => {
  const theme = useTheme();
  const { checkoutChartData, isLoadingCheckoutChart } = useGetGeneral();

  const isEmptyData =
    checkoutChartData?.completed.percentage === 0 &&
    checkoutChartData?.customer.percentage === 0 &&
    checkoutChartData?.shipping_address.percentage === 0 &&
    checkoutChartData?.shipping_rate.percentage === 0 &&
    checkoutChartData?.payment.percentage === 0;
  const conversionRate = numberFormatter(checkoutChartData?.completed.percentage || 0, 2);

  const data = [
    {
      name: 'Dados Pessoais',
      uv: checkoutChartData?.customer.percentage || 0,
      pv: 0,
      quantity: checkoutChartData?.customer.quantity || 0,
    },
    {
      name: 'Entrega',
      uv: checkoutChartData?.shipping_address.percentage || 0,
      pv: 0,
      quantity: checkoutChartData?.shipping_address.quantity || 0,
    },
    {
      name: 'Frete',
      uv: checkoutChartData?.shipping_rate.percentage || 0,
      pv: 0,
      quantity: checkoutChartData?.shipping_rate.quantity || 0,
    },
    {
      name: 'Pagamento',
      uv: checkoutChartData?.payment.percentage || 0,
      pv: 0,
      quantity: checkoutChartData?.payment.quantity || 0,
    },
    {
      name: 'Finalizado',
      uv: checkoutChartData?.completed.percentage || 0,
      pv: 0,
      quantity: checkoutChartData?.completed.quantity || 0,
    },
  ];

  const colors = ['#717C11', '#96A516', '#BCCF1C', '#CAD750', '#D7E277'];

  if (isLoadingCheckoutChart) {
    return <CheckoutConversionSkeleton />;
  }

  return (
    <S.Wrapper>
      <S.HeaderWrapper>
        <S.InfoWrapper>
          <S.Title>Conversão do checkout</S.Title>

          {isEmptyData ? (
            <S.EmptyPercentValue>-%</S.EmptyPercentValue>
          ) : (
            <S.PercentWrapper>
              <S.PercentValue>{`${conversionRate}%`}</S.PercentValue>

              {/* <S.PercentTag>
                <ArrowUpRight size={12} color={theme.colors.positive.darker} weight="bold" />
                123,3%
              </S.PercentTag> */}
            </S.PercentWrapper>
          )}
        </S.InfoWrapper>
      </S.HeaderWrapper>

      <S.ChartWrapper>
        <ResponsiveContainer width="100%" height="100%">
          <Chart data={data}>
            <CartesianGrid strokeDasharray="10 10" horizontal={false} />

            <XAxis
              dataKey="name"
              tick={{ dy: 8 }}
              strokeDasharray="10 10"
              axisLine={{ stroke: theme.colors.neutral.lightest }}
              tickLine={false}
            />

            <YAxis
              tick={{ dx: -10 }}
              axisLine={false}
              tickLine={false}
              stroke={theme.colors.neutral.base}
              tickFormatter={tick => `${tick}%`}
            />

            <Tooltip content={<ChartTooltip />} />

            <Bar stackId="a" dataKey="uv">
              {data.map((_, index) => (
                <Cell key={`cell-${index.toFixed()}`} fill={colors[index % 20]} />
              ))}
            </Bar>

            <Bar stackId="a" dataKey="pv" fill={theme.colors.neutral.lightest} />
          </Chart>
        </ResponsiveContainer>

        {isEmptyData && <S.EmptyText>Nenhum dado disponível</S.EmptyText>}
      </S.ChartWrapper>
    </S.Wrapper>
  );
};

export default CheckoutConversion;
