import React from 'react';
import useSWR from 'swr';
import { useParams } from 'react-router-dom';

import { ICheckout } from '@domain/interfaces/common/checkout/ICheckout';
import { ISwr } from '@domain/interfaces/common/swr/ISwr';
import {
  IGetCheckoutResponse,
  IListCheckoutResponse,
} from '@domain/interfaces/hooks/services/checkout/ICheckoutService';
import { IUserCheckoutContext } from '@domain/interfaces/store/context/common/IUserCheckoutContext';

import { DASHBOARD_API_URL } from '@constants/services/apiUrl';

import { dashboardInstance } from '@services/common/dashboardInstance';
import { useErrorHandler } from '@store/context/common/ErrorHandlerContext';
import { IAccount } from '@domain/interfaces/common/account/IAccount';

const UserCheckoutContext = React.createContext<IUserCheckoutContext | null>(null);

export const UserCheckoutProvider: React.FC = ({ children }) => {
  const { accountId, checkoutId } = useParams();
  const { handleError } = useErrorHandler();

  const [userCheckouts, setUserCheckouts] = React.useState<ICheckout[] | null>(null);
  const [currentUserAccount, setCurrentUserAccount] = React.useState<IAccount | undefined>();
  const [currentUserCheckout, setCurrentUserCheckout] = React.useState<ICheckout>({} as ICheckout);

  dashboardInstance.defaults.headers.common['x-zouti-account'] = accountId;

  const CHECKOUTS_URL = `${DASHBOARD_API_URL}/accounts/${accountId}/checkout`;
  const CHECKOUT_URL = `${DASHBOARD_API_URL}/accounts/${accountId}/checkouts/${checkoutId}`;

  const {
    data: checkoutsData,
    isLoading: isLoadingCheckouts,
    isValidating: isValidatingCheckouts,
    error: checkoutsError,
  } = useSWR<ISwr<IListCheckoutResponse>>(CHECKOUTS_URL, dashboardInstance);

  const {
    data: checkoutData,
    isLoading: isLoadingCheckout,
    isValidating: isValidatingCheckout,
    error: CheckoutError,
    mutate: mutateCheckout,
  } = useSWR<ISwr<IGetCheckoutResponse>>(CHECKOUT_URL, dashboardInstance);

  React.useEffect(() => {
    if (checkoutsError) {
      handleError(checkoutsError);
      return;
    }

    if (checkoutsData?.data?.object && checkoutsData.data.object) {
      setUserCheckouts(checkoutsData.data.object);
    }
  }, [checkoutsData, checkoutsError, handleError]);

  React.useEffect(() => {
    if (CheckoutError) {
      handleError(CheckoutError);
      return;
    }
    if (checkoutData) {
      setCurrentUserCheckout(checkoutData.data.object.checkout);
      setCurrentUserAccount(checkoutData.data.object.account);
    }
  }, [checkoutData, CheckoutError, handleError]);

  const isLoadingUserCheckoutProvider = isLoadingCheckouts || isLoadingCheckout;
  const isValidatingUserCheckoutProvider = isValidatingCheckouts || isValidatingCheckout;
  const isUserCheckoutProviderError = Boolean(checkoutsError) || Boolean(CheckoutError);

  return (
    <UserCheckoutContext.Provider
      value={{
        isLoadingUserCheckoutProvider,
        isUserCheckoutProviderError,
        isValidatingUserCheckoutProvider,
        userCheckouts,
        currentUserCheckout,
        currentUserAccount,
        mutateCheckout,
      }}
    >
      {children}
    </UserCheckoutContext.Provider>
  );
};

export const useUserCheckout = (): IUserCheckoutContext => {
  const context = React.useContext(UserCheckoutContext);

  if (!context) {
    throw new Error('useUserCheckout must be used within UserCheckoutProvider');
  }

  return context;
};
