import React from 'react';
import { useNewPayout } from '@store/common/Payout/NewPayoutContext';

import { banks } from '@constants/common/banks';

import { formatCurrency } from '@utils/common/formatCurrency';
import { EPayoutType } from '@domain/enums/common/payouts/EPayoutType';

import { currencyToNumber } from '@helpers/utils/common/number';

import { cnpjFormatter } from '@utils/common/cnpj';

import * as S from './styles';

const TransferModalDetail: React.FC = () => {
  const {
    reset,
    onSubmit,
    getValues,
    setNextStep,
    setIsOpenModal,
    simulateResponse,
    bankAccountSelected,
    isLoadingCreatePayout,
  } = useNewPayout();

  const { transferAmount, transferFee, willReceive, pix } = simulateResponse;
  const { transfer_value, bank_code, account_number, routing_number, transfer_type } = getValues();

  const payoutsData = {
    bank_code,
    account_number,
    routing_number,
    bank_account_id: bankAccountSelected?.id,
    amount: Math.round(currencyToNumber(transfer_value) * 100),
  };

  const pixData = {
    pix_key_id: pix?.key,
    amount: Math.round(currencyToNumber(transfer_value) * 100),
  };

  const handleCancel = (): void => {
    reset();
    setNextStep(0);
    setIsOpenModal(state => !state);
  };

  const handleSubmit = (): void => {
    const data = transfer_type === EPayoutType.BANK_ACCOUNT ? payoutsData : pixData;
    onSubmit(data);
  };

  const willReceiveFormatted = formatCurrency(willReceive / 100);
  const transferFeeFormatted = formatCurrency(transferFee / 100);
  const transferValueFormatted = formatCurrency(transferAmount / 100);

  const bankName = banks?.find(bank => bank.code?.toString() === bank_code)?.name;
  const cnpjFormatted = cnpjFormatter(pix?.key);

  return (
    <S.Wrapper>
      <S.Title>Resumo</S.Title>
      <S.Card>
        {transfer_type === EPayoutType.BANK_ACCOUNT && (
          <>
            <S.TextGroup>
              <S.TextLabel>Banco</S.TextLabel>
              <S.TextValue>{bankName}</S.TextValue>
            </S.TextGroup>
            <S.Divider />
            <S.TextGroup>
              <S.TextLabel>Agência</S.TextLabel>
              <S.TextValue>{routing_number}</S.TextValue>
            </S.TextGroup>
            <S.Divider />
            <S.TextGroup>
              <S.TextLabel>Conta corrente</S.TextLabel>
              <S.TextValue>{account_number}</S.TextValue>
            </S.TextGroup>
            <S.Divider />
          </>
        )}
        {transfer_type === EPayoutType.PIX && (
          <>
            <S.TextGroup>
              <S.TextLabel>Chave Pix</S.TextLabel>
              <S.TextValue>{cnpjFormatted}</S.TextValue>
            </S.TextGroup>
            <S.Divider />
          </>
        )}
        <S.TextGroup>
          <S.TextLabel>Valor da Transferência</S.TextLabel>
          <S.TextValue>{transferValueFormatted}</S.TextValue>
        </S.TextGroup>
        <S.Divider />
        <S.TextGroup>
          <S.TextLabel>Tarifa de transferência</S.TextLabel>
          <S.TextValue>{transferFeeFormatted}</S.TextValue>
        </S.TextGroup>
        <S.Divider />
        <S.TextGroup>
          <S.TextLabel>Você receberá</S.TextLabel>
          <S.AmountReceivedTextValue>{willReceiveFormatted}</S.AmountReceivedTextValue>
        </S.TextGroup>
        <S.ModalAFooter>
          <S.ButtonsAction>
            <S.CancelButton type="button" onClick={handleCancel}>
              Cancelar
            </S.CancelButton>
            <S.SaveButton isLoading={isLoadingCreatePayout} onClick={handleSubmit}>
              Transferir
            </S.SaveButton>
          </S.ButtonsAction>
        </S.ModalAFooter>
      </S.Card>
    </S.Wrapper>
  );
};

export default TransferModalDetail;
