import styled from 'styled-components/macro';

import TextBase from '@components/common/DataDisplay/Text';
import Select from '@components/common/Inputs/Select';
import OptionBase from '@components/common/Inputs/Select/Option';
import TextField from '@components/common/Inputs/TextField';

export const Wrapper = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const ShowQuantityItemsText = styled(TextBase)`
  color: ${({ theme }) => theme.colors.neutral.dark};
  font-size: 1.4rem;
`;

export const SelectAndInputGroupWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

export const ItemsLimitSelect = styled(Select)`
  min-width: 77px;
`;

export const Option = styled(OptionBase)``;

export const GoToPageInputGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

export const GoToPageInput = styled(TextField)`
  max-width: 130px;

  input {
    text-align: center;

    &::placeholder {
      font-size: 14px;
    }
  }
`;

export const GoToPageButton = styled.button`
  width: 36px;
  height: 36px;
  background-color: ${({ theme }) => theme.colors.primary.green_lemon};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadius.small};
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    filter: brightness(0.9);
  }

  &:active {
    filter: brightness(0.8);
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.neutral.light};
    border-color: ${({ theme }) => theme.colors.neutral.light};
    cursor: not-allowed;

    &:hover {
      filter: unset;
    }

    &:active {
      filter: unset;
    }
  }
`;
