import React from 'react';

import { useAnalytics } from '@store/context/common/AnalyticsContext';

import Header from './Header';
import StoreProvider from './Integrations/StoreProvider';

import * as S from './styles';

const Apps: React.FC = () => {
  const { analytics } = useAnalytics();

  React.useEffect(() => {
    analytics.track('Apps Page Viewed');
  }, [analytics]);

  return (
    <S.Wrapper>
      <Header />

      <StoreProvider />
    </S.Wrapper>
  );
};

export default Apps;
