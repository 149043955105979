import React, { useEffect, useRef, useState } from 'react';
import { CaretDown, CaretUp } from '@phosphor-icons/react';

import * as S from './styles';

export interface IAccordionProps {
  children: React.ReactNode;
  title: string;
  titleOpened?: string;
  icon?: React.ReactNode;
  headerContent?: React.ReactNode;
  initOpen?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
}

const Accordion = ({
  title,
  titleOpened,
  icon,
  headerContent,
  initOpen,
  children,
  onOpen,
  onClose,
}: IAccordionProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(initOpen);
  const [maxHeight, setMaxHeight] = useState(0);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setIsOpen(initOpen);
  }, [initOpen]);

  useEffect(() => {
    if (contentRef.current) {
      setMaxHeight(contentRef.current.scrollHeight);
    }
  }, [children, isOpen]);

  const handleToggle = (): void => {
    setIsOpen(!isOpen);
    if (!isOpen && onOpen) {
      onOpen();
    } else if (isOpen && onClose) {
      onClose();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>): void => {
    if (e.key === 'Enter') {
      handleToggle();
    }
  };

  return (
    <S.Accordion className={`Accordion ${isOpen ? 'open' : 'closed'}`}>
      <S.AccordionHeader
        onClick={handleToggle}
        onKeyDown={handleKeyDown}
        role="button"
        tabIndex={0}
      >
        <S.AccordionHeaderTitle>
          {icon && icon}
          <h3>{isOpen ? titleOpened || title : title}</h3>
          <span>{isOpen ? <CaretUp size={20} /> : <CaretDown size={20} />}</span>
        </S.AccordionHeaderTitle>

        {headerContent ? <div>{headerContent}</div> : null}
      </S.AccordionHeader>
      <S.AccordionContent style={{ maxHeight: isOpen ? `${maxHeight}px` : '0' }} ref={contentRef}>
        {children}
      </S.AccordionContent>
    </S.Accordion>
  );
};

export default Accordion;
