import React from 'react';
import { Lock } from 'phosphor-react';

import { useResetPassword } from '@store/context/public/ResetPassword/ResetPasswordContext';

import Text from '@components/common/DataDisplay/Text';

import * as S from './styles';

const Form: React.FC = () => {
  const { register, handleSubmit, onSubmit, errors, isLoading } = useResetPassword();

  const passwordRegister = register('password');
  const passwordConfirmationRegister = register('password_confirmation');

  return (
    <S.Form onSubmit={handleSubmit(onSubmit)}>
      <S.InputGroup>
        <S.Label>Senha</S.Label>
        <S.TextField
          {...passwordRegister}
          type="password"
          placeholder="Digite sua senha"
          isError={Boolean(errors.password)}
          icon={Lock}
        />
        {errors.password && <Text isFeedbackError>{errors.password.message}</Text>}
      </S.InputGroup>

      <S.InputGroup>
        <S.Label>Repita a senha</S.Label>
        <S.TextField
          {...passwordConfirmationRegister}
          type="password"
          placeholder="Digite novamente sua senha"
          isError={Boolean(errors.password_confirmation)}
          icon={Lock}
        />
        {errors.password_confirmation && (
          <Text isFeedbackError>{errors.password_confirmation.message}</Text>
        )}
      </S.InputGroup>

      <S.SubmitButton type="submit" isLoading={isLoading}>
        Enviar
      </S.SubmitButton>
    </S.Form>
  );
};

export default Form;
