import styled from 'styled-components';

import ModalBase from '@components/common/DataDisplay/Modal';
import TextBase from '@components/common/DataDisplay/Text';
import SwitchBase from '@components/common/Inputs/Switch';

export const RefundWithLabelFee = styled(TextBase)``;

export const Switch = styled(SwitchBase)``;

export const RefundWithFeeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 8px;
`;

export const Divider = styled.hr`
  margin: 16px 0;
  width: 100%;
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors.neutral.gray_darkest};
  border-style: solid;
`;

export const Description = styled(TextBase)`
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.neutral.darker};
`;

export const Modal = styled(ModalBase)`
  max-width: 500px;
`;
