import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 1024px;
  display: flex;
  flex-direction: column;
`;
