import React from 'react';

import { NewSocialProofProvider } from '@store/common/socialProof/NewSocialProofContext';

import Content from '@components/pages/Dashboard/SocialProof/NewSocialProof';

const NewSocialProof: React.FC = () => {
  return (
    <NewSocialProofProvider>
      <Content />
    </NewSocialProofProvider>
  );
};

export default NewSocialProof;
