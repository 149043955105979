import styled from 'styled-components/macro';

import DropdownBase from '@components/common/utils/Dropdown';
import ImageBase from '@components/common/DataDisplay/Image';
import TextBase from '@components/common/DataDisplay/Text';
import TextFieldBase from '@components/common/Inputs/TextField';

interface IDropdownContentProps {
  width: number;
}

export const SelectProductName = styled(TextBase)`
  font-size: 1.4rem;
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 120px;
  width: 100%;
`;

export const SelectImage = styled(ImageBase)`
  width: 28px;
  height: 28px;
  border-radius: 4px;
`;

export const NoImage = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.neutral.base};
`;

export const ImageAndProductNameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const SearchInput = styled(TextFieldBase)`
  border-radius: 0;
  border-left: 0;
  border-right: 0;
  border-top: 0;
`;

export const ProductName = styled(TextBase)`
  font-size: 1.4rem;
  font-weight: 500;
  max-width: 172px;
  width: 100%;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
  text-align: left;
`;

export const ProductImage = styled(ImageBase)`
  width: 24px;
  height: 24px;
  border-radius: 4px;
`;

export const ProductWrapper = styled.button`
  width: 100%;
  border: unset;
  background-color: unset;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary.lemon_20};
  }
`;

export const ScrollableContainer = styled.div`
  max-height: 320px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 6px;
    position: absolute;
  }

  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.neutral.lightest};
    position: absolute;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.neutral.light};
    border-radius: 25px;
    transition: all 0.3s;
  }

  ::-webkit-scrollbar-thumb:hover {
    opacity: brightness(0.8);
  }
`;

export const TriggerContentWrapper = styled.div``;

export const DropdownContent = styled(DropdownBase.Content)<IDropdownContentProps>`
  min-width: ${({ width }) => width}px;
  max-width: ${({ width }) => width}px;
  width: 100%;
`;

export const DropdownTrigger = styled(DropdownBase.Trigger)`
  width: 100%;
  height: 40px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  display: flex;
  justify-content: space-between;
  gap: 16px;
  align-items: center;
  padding: 0 16px;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    border-color: ${({ theme }) => theme.colors.primary.green_lemon};
  }

  &:active {
    border-color: ${({ theme }) => theme.colors.primary.green_lemon};
  }
`;

export const Dropdown = styled(DropdownBase)``;

export const ProductNotFoundContainer = styled.div`
  padding: 10px;
`;

export const TextContainerNotFound = styled(TextBase)``;
