import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: ${({ theme }) => theme.spacing.small_16};

  & > div:nth-child(1) {
    grid-column: 1;
    grid-row: 1;
  }

  & > div:nth-child(2) {
    grid-column: 1;
    grid-row: 2;
  }

  @media (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);

    & > div:nth-child(1) {
      grid-column: 1 / span 2;
      grid-row: 1;
    }

    & > div:nth-child(2) {
      height: 100%;
      grid-column: 1 / span 2;
      grid-row: 2;
    }

    & > div:nth-child(3) {
      grid-column: 1;
      grid-row: 3;
    }

    & > div:nth-child(4) {
      grid-column: 1;
      grid-row: 4;
    }
  }
`;
