import styled, { css } from 'styled-components/macro';

import {
  ClockCountdown,
  CurrencyDollarSimple,
  CheckCircle,
  PiggyBank,
} from '@phosphor-icons/react';
import Text from '@components/common/DataDisplay/Text';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 32px;
  margin-bottom: 32px;
`;

export const IconContainer = styled.div<{ color: string }>`
  display: flex;
  border-radius: 6px;
  padding: 5px;
  background-color: ${({ color }) => color};
`;

export const CardContent = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const Card = styled.div`
  display: flex;
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  background-color: ${({ theme }) => theme.colors.neutral.white};
  flex-direction: column;
  gap: 24px;
`;

export const CardTitle = styled(Text)`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.neutral.black};
`;

export const CardHeader = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const CheckCircleIcon = styled(CheckCircle).attrs({
  weight: 'bold',
  size: 14,
})`
  color: ${({ theme }) => theme.colors.positive.dark};
`;

export const ClockCountDownIcon = styled(ClockCountdown).attrs({
  weight: 'bold',
  size: 14,
})`
  color: ${({ theme }) => theme.colors.warning.dark};
`;

export const CurrencyDollarSimpleIcon = styled(CurrencyDollarSimple).attrs({
  weight: 'bold',
  size: 14,
})`
  color: ${({ theme }) => theme.colors.neutral.darkest};
`;

export const PiggyBankIcon = styled(PiggyBank).attrs({
  weight: 'bold',
  size: 14,
})`
  color: ${({ theme }) => theme.colors.neutral.darkest};
`;

export const TextBase = styled(Text)`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.neutral.black};
`;

export const CurrencySymbol = styled(TextBase)<{ isnegative: boolean }>`
  ${({ theme, isnegative }) =>
    isnegative &&
    css`
      color: ${theme.colors.negative.dark};
    `}
`;
export const BalanceValue = styled(TextBase)<{ isnegative: boolean }>`
  font-size: 2.4rem;

  ${({ theme, isnegative }) =>
    isnegative &&
    css`
      color: ${theme.colors.negative.dark};
    `}
`;
