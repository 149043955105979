import React from 'react';

import EmptyImage from '@assets/images/common/empty-bank-account.png';
import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/common/EHeading';

import * as S from './styles';

interface IEmptyDataProps {
  onClick: () => void;
}

const EmptyData: React.FC<IEmptyDataProps> = ({ onClick }) => {
  return (
    <S.Wrapper>
      <S.Image src={EmptyImage} alt="Empty Data Bank Account" />
      <S.Title size={EHeadingSize.H2} fontWeight={EHeadingWeight.MEDIUM}>
        Nenhuma conta bancária
      </S.Title>
      <S.Description>Adicione uma conta bancária para o recebimento de seus saques.</S.Description>
      <S.Button onClick={onClick}>
        <S.PlusIcon /> Nova conta bancária
      </S.Button>
    </S.Wrapper>
  );
};

export default EmptyData;
