import React from 'react';
import { EnvelopeSimple, Lock, Phone, UserCircle } from 'phosphor-react';

import { phone } from '@helpers/utils/common/phone';

import { useSignUp } from '@store/context/public/signUp/SignUpContext';

import Text from '@components/common/DataDisplay/Text';

import * as S from './styles';

const Form: React.FC = () => {
  const { register, handleSubmit, onSubmit, errors, isCreatingAccount } = useSignUp();

  const nameRegister = register('name');
  const emailRegister = register('email');
  const phoneRegister = register('phone');
  const passwordRegister = register('password');
  const passwordConfirmationRegister = register('password_confirmation');

  const onPhoneChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    phoneRegister.onChange(event);

    const { value } = event.target;

    event.target.value = phone(value);
  };

  return (
    <S.Form onSubmit={handleSubmit(onSubmit)}>
      <S.InputGroup>
        <S.Label>Nome completo</S.Label>
        <S.TextField
          {...nameRegister}
          type="text"
          placeholder="Digite o seu nome"
          isError={Boolean(errors.first_name)}
          icon={UserCircle}
        />
        {errors.first_name && <Text isFeedbackError>{errors.first_name.message}</Text>}
      </S.InputGroup>

      <S.InputGroup>
        <S.Label>E-mail</S.Label>
        <S.TextField
          {...emailRegister}
          type="email"
          placeholder="Digite o seu e-mail"
          isError={Boolean(errors.email)}
          icon={EnvelopeSimple}
        />
        {errors.email && <Text isFeedbackError>{errors.email.message}</Text>}
      </S.InputGroup>

      <S.InputGroup>
        <S.Label>Telefone</S.Label>
        <S.TextField
          {...phoneRegister}
          type="text"
          placeholder="Digite o seu telefone"
          onChange={onPhoneChange}
          isError={Boolean(errors.phone)}
          icon={Phone}
        />
        {errors.last_name && <Text isFeedbackError>{errors.last_name.message}</Text>}
      </S.InputGroup>

      <S.InputGroup>
        <S.Label>Senha</S.Label>
        <S.TextField
          {...passwordRegister}
          type="password"
          placeholder="Digite sua senha"
          isError={Boolean(errors.password)}
          icon={Lock}
        />
        {errors.password && <Text isFeedbackError>{errors.password.message}</Text>}
      </S.InputGroup>

      <S.InputGroup>
        <S.Label>Repita a senha</S.Label>
        <S.TextField
          {...passwordConfirmationRegister}
          type="password"
          placeholder="Digite novamente sua senha"
          isError={Boolean(errors.password_confirmation)}
          icon={Lock}
        />
        {errors.password_confirmation && (
          <Text isFeedbackError>{errors.password_confirmation.message}</Text>
        )}
      </S.InputGroup>

      <S.Text>
        Ao continuar, você concorda com os <br />
        <S.Link isLinkForm to="https://zouti.com.br/terms-of-usage">
          Termos de serviço
        </S.Link>{' '}
        e{' '}
        <S.Link isLinkForm to="https://zouti.com.br/privacy-policy">
          Políticas de privacidade
        </S.Link>
      </S.Text>

      <S.SubmitButton type="submit" isLoading={isCreatingAccount}>
        Cadastrar
      </S.SubmitButton>
    </S.Form>
  );
};

export default Form;
