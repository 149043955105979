import React from 'react';

import { useAnalytics } from '@store/context/common/AnalyticsContext';
import { useGetStatementTransactions } from '@store/common/statementTransactions/GetStatementTransactionsContext';
import { useGetHopyTransactionsStatements } from '@store/common/hopyTransactionsStatements/GetHopyTransactionsStatements';

import ListSkeletonLoading from '@components/common/utils/ListSkeletonLoading';

import Header from './Header';
import ShowLegacyBalance from './ShowLegacyBalance';
import LegacyStatements from './LegacyStatements';
import Statements from './Statements';

import * as S from './styles';

const StatementTransaction: React.FC = () => {
  const { analytics } = useAnalytics();
  const { balances: hopyBalances } = useGetHopyTransactionsStatements();
  const { isLoading, showLegacyBalance } = useGetStatementTransactions();

  React.useEffect(() => {
    analytics.track('Statement Transaction Page Viewed');
  }, [analytics]);

  if (isLoading) {
    return <ListSkeletonLoading />;
  }

  const showLegacyOption =
    hopyBalances &&
    (hopyBalances.available !== 0 ||
      hopyBalances.pending !== 0 ||
      hopyBalances.reserved !== 0 ||
      hopyBalances.total !== 0);

  return (
    <S.Wrapper>
      <Header />

      {showLegacyOption && <ShowLegacyBalance />}

      {showLegacyBalance ? <LegacyStatements /> : <Statements />}
    </S.Wrapper>
  );
};
export default StatementTransaction;
