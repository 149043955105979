import React from 'react';
import useSWR from 'swr';
import { useNavigate, useParams } from 'react-router-dom';

import { INewGroupsProvider } from '@domain/interfaces/store/common/groups/INewGroupsProvider';
import { ISwr } from '@domain/interfaces/common/swr/ISwr';

import { DASHBOARD_API_URL } from '@constants/services/apiUrl';
import { dashboardInstance } from '@services/common/dashboardInstance';

import { useErrorHandler } from '@store/context/common/ErrorHandlerContext';
import { useToast } from '@store/context/common/ToastContext';
import { useGroupsService } from '@hooks/services/groups/useGroupsService';
import { FieldValues, useForm } from 'react-hook-form';
import { ICreateGroupData } from '@domain/interfaces/hooks/services/groups/IGroupsService';
import { IGroup } from '@domain/interfaces/common/groups/IGroup';
import { useAnalytics } from '@store/context/common/AnalyticsContext';

const GroupsContext = React.createContext<INewGroupsProvider | null>(null);

export interface IGroupsProps {
  id: string;
  name: string;
  qtdUsers: number;
}

export const GroupsProvider: React.FC = ({ children }) => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const { accountId, checkoutId } = useParams();
  const { analytics } = useAnalytics();
  const { handleError } = useErrorHandler();
  const { createGroup, deleteGroup } = useGroupsService();

  const [groups, setGroups] = React.useState<IGroupsProps[]>([]);
  const [isOpenDeleteModal, setIsOpenDeleteModel] = React.useState<boolean>(false);
  const [deleteGroupData, setDeleteGroupData] = React.useState<{
    groupName: string;
    membershipGroupId: string;
  }>({
    groupName: '',
    membershipGroupId: '',
  });

  const [isDeleteGroupLoading, setIsDeleteGroupLoading] = React.useState<boolean>(false);
  const [isCreateGroupLoading, setIsCreateGroupLoading] = React.useState<boolean>(false);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    // resolver: yupResolver(getPixelSchemaValidation(providerSelected)),
  });

  const GET_MEMBERSHIP_GROUPS_URL = `${DASHBOARD_API_URL}/accounts/${accountId}/membership_groups`;

  const { data, isLoading, isValidating, error, mutate } = useSWR<ISwr>(
    GET_MEMBERSHIP_GROUPS_URL,
    dashboardInstance,
  );

  const onSubmit = React.useCallback(
    async (formValues: FieldValues) => {
      const scopes = Object.entries(formValues)
        .filter(([key, value]) => key !== 'group_name' && value !== undefined)
        .map(([_, value]) => value);

      const payload: ICreateGroupData = {
        name: formValues?.group_name,
        scope: scopes,
      };
      setIsCreateGroupLoading(true);

      try {
        await createGroup({ accountId, payload });
        toast.success('Grupo criado com sucesso!');
        navigate(`/${accountId}/${checkoutId}/dashboard/settings/users`);
      } catch (errorCreateGroup) {
        setIsCreateGroupLoading(false);
        handleError(errorCreateGroup);
      } finally {
        setIsCreateGroupLoading(false);
      }
    },
    [createGroup, handleError, toast, accountId, navigate, checkoutId],
  );

  const deleteCurrentGroup = React.useCallback(async () => {
    setIsDeleteGroupLoading(true);

    try {
      await deleteGroup({ accountId, membershipGroupId: deleteGroupData?.membershipGroupId });

      analytics.track('Has Deleted group');

      toast.success('Grupo deletado com sucesso!');
      await mutate();
      setIsDeleteGroupLoading(false);
      setIsOpenDeleteModel(false);
    } catch (errorDeleteGroup) {
      toast.error('Erro ao deletar o grupo!');
      setIsDeleteGroupLoading(false);
      handleError(errorDeleteGroup);
    }
  }, [deleteGroup, deleteGroupData, handleError, mutate, accountId, toast, analytics]);

  const onToggleDeleteModel = (): void => {
    setIsOpenDeleteModel(state => !state);
  };

  React.useEffect(() => {
    if (error) {
      handleError(error);
    }

    if (data?.data?.object) {
      const newGroups = data.data.object.map((item: IGroup) => ({
        id: item.id,
        name: item.name,
        qtdUsers: item.qtd_members,
      }));
      setGroups(newGroups);
    }
  }, [data, handleError, error]);

  const isLoadingGroups = isLoading || isValidating;

  return (
    <GroupsContext.Provider
      value={{
        groups,
        onSubmit,
        isLoadingGroups,
        errors,
        register,
        handleSubmit,
        control,
        isCreateGroupLoading,
        isDeleteGroupLoading,
        deleteCurrentGroup,
        setIsOpenDeleteModel,
        isOpenDeleteModal,
        onToggleDeleteModel,
        setDeleteGroupData,
        deleteGroupData,
      }}
    >
      {children}
    </GroupsContext.Provider>
  );
};

export const useGroupsContext = (): INewGroupsProvider => {
  const context = React.useContext(GroupsContext);

  if (!context) {
    throw new Error('useGroupsContext must be used within provider');
  }

  return context;
};
