import styled from 'styled-components/macro';

import TableBase from '@components/common/utils/Table';
import DropdownBase from '@components/common/utils/Dropdown';
import Radio from '@components/common/Inputs/Radio';

export const Table = styled(TableBase)``;

export const RadioButton = styled(Radio)``;

export const ButtonActionsWrapper = styled.div`
  min-width: 79px;
  justify-content: right;
  display: flex;
  gap: 12px;
`;

export const Dropdown = styled(DropdownBase)``;

export const DropdownTrigger = styled(DropdownBase.Trigger)`
  padding: 0 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
`;

export const DropdownContent = styled(DropdownBase.Content)`
  display: flex;
  min-width: unset;
  width: 180px;
  flex-direction: column;
  margin-right: 150px;
  padding: 16px;
  gap: ${({ theme }) => theme.spacing.small_8};
  color: ${({ theme }) => theme.colors.neutral.darker};

  & > div:last-child {
    color: ${({ theme }) => theme.colors.negative.base};
  }
`;

export const DropdownItem = styled(DropdownBase.Item)`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.small_8};
  padding: ${({ theme }) => theme.spacing.small_8};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  z-index: 99999;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.neutral.gray_white};
  }
`;
