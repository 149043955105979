import React from 'react';
import useSWR from 'swr';
import { useNavigate, useParams } from 'react-router-dom';

import { IEditGroupsProvider } from '@domain/interfaces/store/common/groups/IEditGroupsProvider';
import { ISwr } from '@domain/interfaces/common/swr/ISwr';

import { DASHBOARD_API_URL } from '@constants/services/apiUrl';
import { dashboardInstance } from '@services/common/dashboardInstance';

import { useErrorHandler } from '@store/context/common/ErrorHandlerContext';
import { useToast } from '@store/context/common/ToastContext';
import { useGroupsService } from '@hooks/services/groups/useGroupsService';
import { FieldValues, useForm } from 'react-hook-form';
import { IUpdateGroupData } from '@domain/interfaces/hooks/services/groups/IGroupsService';
import { useUserSession } from '@store/context/common/UserSession';

const EditGroupsContext = React.createContext<IEditGroupsProvider | null>(null);

interface IGroup {
  id: string;
  account_id: string;
  name: string;
  scopes: string[];
  created_at: string;
  updated_at: string;
}

interface IGroupResponse {
  object: IGroup;
}

export const EditGroupsProvider: React.FC = ({ children }) => {
  const { toast } = useToast();
  const { user } = useUserSession();
  const navigate = useNavigate();
  const { accountId, membershipGroupsId } = useParams();
  const [isLoadingUpdateGroup, setIsLoadingUpdateGroup] = React.useState<boolean>(false);
  const [group, setGroup] = React.useState<IGroup>({} as IGroup);

  const { handleError } = useErrorHandler();
  const { updateGroup } = useGroupsService();

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({});

  const GET_MEMBERSHIP_GROUPS_URL = `${DASHBOARD_API_URL}/accounts/${accountId}/membership_groups/${membershipGroupsId}`;

  const { data, isLoading, isValidating, error } = useSWR<ISwr<IGroupResponse>>(
    GET_MEMBERSHIP_GROUPS_URL,
    dashboardInstance,
  );

  const onSubmit = React.useCallback(
    async (formValues: FieldValues) => {
      const dependentScopes = [
        'dashboard_checkout_metrics:read',
        'account:read',
        'dashboards_checkout_chart:read',
        'dashboards_overview:read',
        'dashboards_revenue:read',
        'shopify_credentials:write',
        'shopify_credentials:read',
        'reportana_credentials:write',
        'reportana_credentials:read',
        'ga4_credentials:write',
        'ga4_credentials:read',
        'gtm_credentials:write',
        'gtm_credentials:read',
      ];

      const scopes = group?.scopes?.filter(scope => !dependentScopes?.includes(scope));

      setIsLoadingUpdateGroup(true);
      const payload: IUpdateGroupData = {
        name: formValues?.group_name,
        scopes,
        id: membershipGroupsId,
        userId: user?.user?.id,
      };

      try {
        await updateGroup({ payload, accountId, membershipGroupsId });
        setIsLoadingUpdateGroup(false);
        toast.success('Grupo atualizado com sucesso!');
        navigate(-1);
      } catch (errorCreateGroup) {
        setIsLoadingUpdateGroup(false);
        handleError(errorCreateGroup);
      }
    },
    [updateGroup, handleError, toast, accountId, membershipGroupsId, user, group?.scopes, navigate],
  );

  React.useEffect(() => {
    if (error) {
      handleError(error);
    }

    if (data?.data?.object) {
      setGroup(data?.data?.object);
    }
  }, [data, handleError, error]);

  const isLoadingGroups = isLoading || isValidating;

  return (
    <EditGroupsContext.Provider
      value={{
        group,
        onSubmit,
        isLoadingGroups,
        errors,
        register,
        handleSubmit,
        control,
        setValue,
        setGroup,
        isLoadingUpdateGroup,
      }}
    >
      {children}
    </EditGroupsContext.Provider>
  );
};

export const useEditGroupsContext = (): IEditGroupsProvider => {
  const context = React.useContext(EditGroupsContext);

  if (!context) {
    throw new Error('useEditGroupsContext must be used within provider');
  }

  return context;
};
