import React from 'react';

import * as S from './styles';

const CurrencyErrorContent: React.FC = () => {
  return (
    <>
      <S.Text>
        Parece que a moeda que você cadastrou em sua loja Shopify está diferente da cadastrada na
        Zouti. Acesse o artigo abaixo e veja como é fácil resolver.
      </S.Text>

      <S.ButtonsWrapper>
        <S.ArticleLink
          target="_blank"
          to="https://ajuda.zouti.com.br/pt-BR/articles/9009547-alterar-moeda-na-shopify"
        >
          <S.ArrowSquareOutIcon />
          <S.AccessLinkText>Ver artigo</S.AccessLinkText>
        </S.ArticleLink>

        <S.WrapperAccessLink target="_blank" to="https://admin.shopify.com/">
          <S.AccessLinkText>Ir para Shopify</S.AccessLinkText>
        </S.WrapperAccessLink>
      </S.ButtonsWrapper>
    </>
  );
};

export default CurrencyErrorContent;
