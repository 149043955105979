import React from 'react';
import useSWR from 'swr';

import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { GA4Schema } from '@helpers/validators/pages/dashboard/GA4/GA4Schema';

import { ISwr } from '@domain/interfaces/common/swr/ISwr';
import { IGA4Provider } from '@domain/interfaces/store/common/GA4/IGA4Provider';

import { DASHBOARD_API_URL } from '@constants/services/apiUrl';
import { dashboardInstance } from '@services/common/dashboardInstance';

import { useToast } from '@store/context/common/ToastContext';
import { useErrorHandler } from '@store/context/common/ErrorHandlerContext';
import { useAnalytics } from '@store/context/common/AnalyticsContext';
import { useGA4Service } from '@hooks/services/GA4/useGA4Service';
import { IGA4 } from '@domain/interfaces/common/GA4/IGA4';

const GA4Context = React.createContext<IGA4Provider | null>(null);

export const GA4Provider: React.FC = ({ children }) => {
  const { analytics } = useAnalytics();
  const { accountId, checkoutId } = useParams();
  const { toast } = useToast();
  const { handleError } = useErrorHandler();
  const { inactivateGA4, createGA4, activateGA4, updateGA4 } = useGA4Service();
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
    watch,
  } = useForm({ mode: 'onBlur', reValidateMode: 'onChange', resolver: yupResolver(GA4Schema) });

  const [isSubmittingForm, setIsSubmittingForm] = React.useState<boolean>(false);
  const [ga4Credential, setGa4Credential] = React.useState<IGA4 | undefined>(undefined);

  const GA4_URL = `${DASHBOARD_API_URL}/accounts/${accountId}/checkouts/${checkoutId}/integrations/ga4`;

  const { data, isLoading, error, mutate } = useSWR<ISwr>(GA4_URL, dashboardInstance);

  const watchTrackingId = watch('tracking_id');
  const trackingIdRegister = register('tracking_id');
  const optimizationCodeRegister = register('optimization_code');

  const onCreateGA4Credential = React.useCallback(
    async formData => {
      setIsSubmittingForm(true);

      try {
        await createGA4({ accountId, checkoutId, payload: formData });

        analytics.track('GA4 Credential Created');

        toast.success('App instalado com sucesso');

        setIsSubmittingForm(false);

        await mutate();
      } catch (requestError) {
        setIsSubmittingForm(false);
        handleError(requestError);
      }
    },
    [accountId, checkoutId, toast, handleError, mutate, analytics, createGA4],
  );

  const onGA4CredentialUpdate = React.useCallback(
    async formData => {
      setIsSubmittingForm(true);

      try {
        await updateGA4({ accountId, checkoutId, ga4Id: ga4Credential?.id, payload: formData });

        analytics.track('GA4 Credential Update');

        toast.success('App atualizado com sucesso');

        setIsSubmittingForm(false);

        await mutate();
      } catch (requestError) {
        setIsSubmittingForm(false);
        handleError(requestError);
      }
    },
    [accountId, checkoutId, toast, handleError, mutate, analytics, updateGA4, ga4Credential],
  );

  const onSubmit = React.useCallback(
    async formData => {
      const payload = {
        tracking_id: formData.tracking_id.trim(),
      };

      if (data?.data.object) {
        await onGA4CredentialUpdate(payload);
      } else {
        await onCreateGA4Credential(payload);
      }
    },
    [data, onGA4CredentialUpdate, onCreateGA4Credential],
  );

  const onActivateGA4 = React.useCallback(async () => {
    if (!ga4Credential) return;

    try {
      await activateGA4({ accountId, checkoutId, ga4Id: ga4Credential?.id });
      await mutate();
    } catch (requestError) {
      handleError(requestError);
    }
  }, [accountId, checkoutId, handleError, activateGA4, ga4Credential, mutate]);

  const onInactivateGA4 = React.useCallback(async () => {
    if (!ga4Credential) return;

    try {
      await inactivateGA4({ accountId, checkoutId, ga4Id: ga4Credential?.id });
      await mutate();
    } catch (requestError) {
      handleError(requestError);
    }
  }, [accountId, checkoutId, handleError, inactivateGA4, ga4Credential, mutate]);

  React.useEffect(() => {
    if (error) {
      handleError(error);
    }

    if (data?.data.object) {
      setGa4Credential(data.data.object);
      setValue('tracking_id', data.data.object.tracking_id);
      setValue('optimization_code', data.data.object.optimization_code);
    }
  }, [data, error, handleError, setValue]);

  const isGTMError = Boolean(error);
  const isLoadingGA4 = isLoading;

  return (
    <GA4Context.Provider
      value={{
        isSubmittingForm,
        errors,
        handleSubmit,
        watch,
        trackingIdRegister,
        optimizationCodeRegister,
        watchTrackingId,
        onActivateGA4,
        onInactivateGA4,
        isGTMError,
        isLoadingGA4,
        onSubmit,
        ga4Credential,
      }}
    >
      {children}
    </GA4Context.Provider>
  );
};

export const useGA4Context = (): IGA4Provider => {
  const context = React.useContext(GA4Context);

  if (!context) {
    throw new Error('useGA4Context must be used within provider');
  }

  return context;
};
