import styled, { css } from 'styled-components/macro';

import TextBase from '@components/common/DataDisplay/Text';

interface IMenuProps {
  isSelected: boolean;
}

export const BarTray = styled.span`
  position: absolute;
  width: 2px;
  background-color: ${({ theme }) => theme.colors.neutral.lightest};
  top: 16px;
  bottom: 16px;
  left: 16px;
`;

export const SubItemRouteName = styled(TextBase)`
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.neutral.dark};
  font-weight: 500;
`;

export const Bullet = styled.span<IMenuProps>`
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.neutral.base};
  z-index: 1;
  transition: all 0.3s;

  ${({ isSelected, theme }) =>
    isSelected &&
    css`
      background-color: ${theme.colors.primary.dark_green_60};
    `}
`;

export const BulletWrapper = styled.div`
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SubItem = styled.div<IMenuProps>`
  height: 40px;
  padding: 8px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  gap: 8px;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary.lemon_20};
  }

  ${({ isSelected, theme }) =>
    isSelected &&
    css`
      background-color: ${theme.colors.primary.lemon_20};

      & > span {
        color: ${theme.colors.primary.dark_green_60};
      }
    `}
`;

export const SubItemsWrapper = styled.div`
  position: relative;
`;

export const RouteName = styled(TextBase)`
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.neutral.darker};
  font-weight: 500;
`;

export const IconAndRouteNameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Wrapper = styled.div<IMenuProps>`
  height: 40px;
  padding: 8px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  gap: 8px;
  justify-content: space-between;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary.lemon_20};
  }

  ${({ isSelected, theme }) =>
    isSelected &&
    css`
      background-color: ${theme.colors.primary.lemon_20};
    `}
`;
