import React from 'react';
import useSWR from 'swr';
import { useParams } from 'react-router-dom';

import { ISwr } from '@domain/interfaces/common/swr/ISwr';
import { IOrderBump } from '@domain/interfaces/common/orderBump/IOrderBump';
import { IListOrderBumpsProvider } from '@domain/interfaces/store/common/orderBump/IListOrderBumpsProvider';
import { IListOrderBumpsResponse } from '@domain/interfaces/hooks/services/orderBump/IOrderBumpService';

import { DASHBOARD_API_URL } from '@constants/services/apiUrl';
import { dashboardInstance } from '@services/common/dashboardInstance';

import { useToast } from '@store/context/common/ToastContext';
import { useErrorHandler } from '@store/context/common/ErrorHandlerContext';
import { useOrderBumpService } from '@hooks/services/orderBump/useOrderBumpService';
import { useAnalytics } from '@store/context/common/AnalyticsContext';

const ListOrderBumpsContext = React.createContext<IListOrderBumpsProvider | null>(null);

export const ListOrderBumpsProvider: React.FC = ({ children }) => {
  const { toast } = useToast();
  const { analytics } = useAnalytics();
  const { handleError } = useErrorHandler();
  const { accountId, checkoutId } = useParams();
  const { deleteOrderBump, updateOrderBump, inactivateOrderBump, activateOrderBump } =
    useOrderBumpService();

  const [orderBumps, setOrderBumps] = React.useState<Array<IOrderBump>>([]);
  const [isRemovingOrderBump, setIsRemovingOrderBump] = React.useState<boolean>(false);

  dashboardInstance.defaults.headers.common['x-zouti-account'] = accountId;

  const ORDER_BUMP_URL = `${DASHBOARD_API_URL}/accounts/${accountId}/checkouts/${checkoutId}/orderbumps?page=0&total=12`;

  const { data, isLoading, isValidating, mutate, error } = useSWR<ISwr<IListOrderBumpsResponse>>(
    ORDER_BUMP_URL,
    dashboardInstance,
  );

  const updateOrderBumpStatus = React.useCallback(
    async (orderBumpId, payload) => {
      try {
        await updateOrderBump({ orderBumpId, accountId, checkoutId, payload });

        analytics.track('Order Bump Updated');
      } catch (responseError) {
        handleError(responseError);
      }
    },
    [accountId, checkoutId, handleError, updateOrderBump, analytics],
  );

  const removeOrderBump = React.useCallback(
    async (orderBump: IOrderBump) => {
      try {
        setIsRemovingOrderBump(true);

        await deleteOrderBump({ accountId, checkoutId, orderBumpId: orderBump.id });

        analytics.track('Order Bump Deleted');

        toast.success('Order bump excluído com sucesso!');

        setIsRemovingOrderBump(false);

        await mutate();
      } catch (responseError) {
        setIsRemovingOrderBump(false);
        handleError(responseError);
      }
    },
    [accountId, checkoutId, handleError, deleteOrderBump, mutate, toast, analytics],
  );

  const activateSelectedOrderBump = React.useCallback(
    async (orderBumpId: string | undefined) => {
      try {
        await activateOrderBump({ accountId, checkoutId, orderBumpId });

        analytics.track('Order Bump Updated');

        toast.success('Order bump desativado com sucesso!');
      } catch (responseError) {
        setIsRemovingOrderBump(false);
        handleError(responseError);
      }
    },
    [accountId, checkoutId, activateOrderBump, handleError, toast, analytics],
  );

  const inactivateSelectedOrderBump = React.useCallback(
    async (orderBumpId: string | undefined) => {
      try {
        await inactivateOrderBump({ accountId, checkoutId, orderBumpId });

        analytics.track('Order Bump Updated');

        toast.success('Order bump desativado com sucesso!');
      } catch (responseError) {
        setIsRemovingOrderBump(false);
        handleError(responseError);
      }
    },
    [accountId, checkoutId, inactivateOrderBump, handleError, toast, analytics],
  );

  React.useEffect(() => {
    if (error) {
      handleError(error);
    }

    if (data?.data?.object) {
      setOrderBumps(data.data.object);
    }
  }, [data, handleError, error]);

  const isLoadingOrderBumps = isLoading || isValidating;
  const isOrderBumpsError = Boolean(error);

  return (
    <ListOrderBumpsContext.Provider
      value={{
        orderBumps,
        isLoadingOrderBumps,
        isOrderBumpsError,
        mutate,
        isRemovingOrderBump,
        removeOrderBump,
        updateOrderBumpStatus,
        activateSelectedOrderBump,
        inactivateSelectedOrderBump,
      }}
    >
      {children}
    </ListOrderBumpsContext.Provider>
  );
};

export const useListOrderBumps = (): IListOrderBumpsProvider => {
  const context = React.useContext(ListOrderBumpsContext);

  if (!context) {
    throw new Error('useListOrderBumps must be used within provider');
  }

  return context;
};
