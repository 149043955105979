import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  padding: 24px;
  background-color: ${({ theme }) => theme.colors.neutral.white};
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const WrapperItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Legends = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const LegendsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
`;

export const ChartBody = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const ChartBar = styled.div`
  width: 20px;
  margin: 0 5px;
`;
