import React from 'react';

import { useUpdateOrderBump } from '@store/common/orderBump/UpdateOrderBumpContext';

import Body from './Body';
import Header from './Header';

import * as S from './styles';

const EditOrderBump: React.FC = () => {
  const { isLoading } = useUpdateOrderBump();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <S.WrapperCouple>
      <S.Wrapper>
        <Header />
      </S.Wrapper>
      <S.WrapperCustom>
        <Body />
      </S.WrapperCustom>
    </S.WrapperCouple>
  );
};

export default EditOrderBump;
