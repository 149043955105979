import React from 'react';
import { useForm } from 'react-hook-form';

import { IUpdateAccountLanguageData } from '@domain/interfaces/hooks/services/accountData/IAccountDataService';

import { useUpdateAccountData } from '@store/common/accountData/UpdateAccountData';

import { languages } from '@constants/common/languages';
import { currencies } from '@constants/common/currencies';

import { useUserCheckout } from '@store/context/common/UserCheckout';

import * as S from './styles';

const LanguageAndCurrencyData: React.FC = () => {
  const { isUpdatingAccountData, updateCurrentAccountLanguage } = useUpdateAccountData();
  const { currentUserCheckout } = useUserCheckout();
  const { handleSubmit, register } = useForm({ mode: 'onBlur', reValidateMode: 'onChange' });

  const languageRegister = register('language');
  const currencyRegister = register('currency');

  const onSubmit = React.useCallback(
    data => {
      const payload: IUpdateAccountLanguageData = {
        currency: data.currency,
        language: data.language,
      };

      updateCurrentAccountLanguage(payload);
    },
    [updateCurrentAccountLanguage],
  );

  return (
    <S.Form onSubmit={handleSubmit(onSubmit)}>
      <S.LanguageAndCurrencyWrapper>
        <S.Title>Idioma e Moeda</S.Title>

        <S.SelectsWrapper>
          <S.InputGroup>
            <S.Label>Idioma</S.Label>
            <S.Select {...languageRegister} defaultValue={currentUserCheckout?.language}>
              {languages.map(language => (
                <S.Option value={language.value} key={language.value}>
                  {language.label}
                </S.Option>
              ))}
            </S.Select>
          </S.InputGroup>

          <S.InputGroup>
            <S.Label>Moeda</S.Label>
            <S.Select {...currencyRegister} defaultValue={currentUserCheckout?.currency}>
              {currencies.map(currency => (
                <S.Option
                  value={currency.value}
                  key={currency.value}
                  disabled={currency.value === 'ARS'}
                >
                  {currency.value === 'ARS' ? (
                    <>
                      {currency.value} - {currency.currency} (indisponível no momento)
                    </>
                  ) : (
                    <>
                      {currency.value} - {currency.currency}
                    </>
                  )}
                </S.Option>
              ))}
            </S.Select>
          </S.InputGroup>
        </S.SelectsWrapper>
      </S.LanguageAndCurrencyWrapper>

      <S.SubmitButton type="submit" isLoading={isUpdatingAccountData}>
        Salvar
      </S.SubmitButton>
    </S.Form>
  );
};

export default LanguageAndCurrencyData;
