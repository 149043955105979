import React from 'react';

import { NewOrderBumpProvider } from '@store/common/orderBump/NewOrderBumpContext';

import Content from '@components/pages/Dashboard/OrderBump/NewOrderBump';

const NewOrderBump: React.FC = () => {
  return (
    <NewOrderBumpProvider>
      <Content />
    </NewOrderBumpProvider>
  );
};

export default NewOrderBump;
