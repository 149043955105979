import React from 'react';

import { useAlerts } from '@store/context/common/AlertsContext';
import { useUserAccount } from '@store/context/common/UserAccount';

import AcquirerValidation from './AcquirerValidation';

import * as S from './styles';

const Alerts: React.FC = () => {
  const { handleAlertsWrapperHeight } = useAlerts();
  const { currentUserAccount } = useUserAccount();

  const wrapperRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (wrapperRef.current) {
      const updateHeight = (): void => {
        if (wrapperRef.current) {
          handleAlertsWrapperHeight(wrapperRef.current.offsetHeight);
        }
      };

      const resizeObserver = new ResizeObserver(() => {
        updateHeight();
      });

      resizeObserver.observe(wrapperRef.current);

      updateHeight();

      return () => {
        resizeObserver.disconnect();
      };
    }

    return undefined;
  }, [handleAlertsWrapperHeight]);

  const showAcquirerValidationAlert = currentUserAccount
    ? Boolean(!currentUserAccount?.is_all_accounts_verified)
    : false;

  return (
    <S.Wrapper ref={wrapperRef}>{showAcquirerValidationAlert && <AcquirerValidation />}</S.Wrapper>
  );
};

export default Alerts;
