import React from 'react';

import { useGetAccountStep } from '@store/common/home/GetAccountStepContext';
import Header from './Header';
import Body from './Body';

import * as S from './styles';
import SkeletonLoading from './SkeletonLoading';

const Home: React.FC = () => {
  const { isLoadingAccountStep } = useGetAccountStep();

  if (isLoadingAccountStep) {
    return <SkeletonLoading />;
  }

  return (
    <S.Wrapper>
      <Header />

      <Body />
    </S.Wrapper>
  );
};

export default Home;
