import React from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { Lock } from 'phosphor-react';

import { IUpdatePasswordData } from '@domain/interfaces/hooks/services/profile/IProfileService';
import { useUpdatePassword } from '@store/common/profile/UpdatePasswordContext';
import { yupResolver } from '@hookform/resolvers/yup';

import { updatePasswordSchema } from '@helpers/validators/pages/dashboard/profile/updatePasswordSchema';
import * as S from './styles';

export interface IFormProps {
  password: string;
  current_password: string;
  password_confirmation: string;
}

const Password: React.FC = () => {
  const { updateCurrentPassword, isUpdatingPassword, profile } = useUpdatePassword();
  const { id } = profile;
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<IFormProps>({
    resolver: yupResolver(updatePasswordSchema),
  });

  const onSubmit = async (formData: FieldValues): Promise<void> => {
    const data: IUpdatePasswordData = {
      current_password: formData.current_password,
      new_password: formData.password,
    };
    await updateCurrentPassword({ payload: data, profileId: id });
  };

  return (
    <S.Form onSubmit={handleSubmit(onSubmit)}>
      <S.Wrapper>
        <S.InputGroup>
          <S.Label>Senha atual</S.Label>
          <S.Input
            {...register('current_password')}
            isError={!!errors.current_password}
            placeholder="Digite a senha atual"
            type="password"
            icon={Lock}
          />
          {errors.current_password && (
            <S.MessageError>{errors.current_password.message}</S.MessageError>
          )}
        </S.InputGroup>
        <S.InputGroup>
          <S.Label>Nova senha</S.Label>
          <S.Input
            {...register('password')}
            isError={!!errors.password}
            placeholder="Digite a nova senha"
            type="password"
            icon={Lock}
          />
          {errors.password && <S.MessageError>{errors.password.message}</S.MessageError>}
        </S.InputGroup>
        <S.InputGroup>
          <S.Label>Confirmar senha</S.Label>
          <S.Input
            {...register('password_confirmation')}
            isError={!!errors.password_confirmation}
            placeholder="Confirme a nova senha"
            type="password"
            icon={Lock}
          />
          {errors.password_confirmation && (
            <S.MessageError>{errors.password_confirmation.message}</S.MessageError>
          )}
        </S.InputGroup>
      </S.Wrapper>
      <S.SubmitButton isLoading={isUpdatingPassword} type="submit">
        Salvar
      </S.SubmitButton>
    </S.Form>
  );
};

export default Password;
