import styled from 'styled-components/macro';
import Skeleton from 'react-loading-skeleton';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  padding: 24px;
  gap: 16px;
  background-color: ${({ theme }) => theme.colors.neutral.white};
`;

export const SwitchSkeleton = styled(Skeleton)`
  border-radius: 16px;
  height: 20px;
  width: 50px;
`;

export const TextFieldSkeleton = styled(Skeleton)`
  border-radius: 8px;
  height: 30px;
  width: 100%;
  margin-bottom: 10px;
`;

export const LabelSkeleton = styled(Skeleton)`
  height: 10px;
  width: 250px;
  margin-bottom: 5px;
`;

export const WrapperInput = styled.div``;

export const VerifyButtonSkeleton = styled(Skeleton)`
  border-radius: 4px;
  height: 30px;
  width: 170px;
`;
