import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useListCheckout } from '@store/common/checkoutCustomization/ListCheckoutCustomizationsContext';

import EmptyData from '@components/common/utils/EmptyData';
import BottomPagination from '@components/common/utils/BottomPagination';
import ListSkeletonLoading from '@components/common/utils/ListSkeletonLoading';
import Header from './Header';
import Body from './Body';

import * as S from './styles';

const CheckoutCustomization: React.FC = () => {
  const navigate = useNavigate();
  const {
    isEmptyState,
    isLoadingCheckout,
    totalCheckoutCustomizations,
    totalPages,
    handlePageChange,
    handlePageLimit,
    page,
  } = useListCheckout();
  const { accountId, checkoutId } = useParams();

  if (isLoadingCheckout) {
    return <ListSkeletonLoading />;
  }

  const onEmptyDataButtonClick = (): void => {
    navigate(`/${accountId}/${checkoutId}/dashboard/checkout/customizations/new`);
  };

  if (isEmptyState) {
    return (
      <EmptyData
        title="Nenhuma customização"
        description="Crie customizações para o seu checkout."
        buttonText="Criar customização"
        onClick={onEmptyDataButtonClick}
      />
    );
  }
  return (
    <S.Wrapper>
      <Header />

      <Body />

      <BottomPagination
        totalItems={totalCheckoutCustomizations}
        totalPages={totalPages}
        handlePageChange={handlePageChange}
        handlePageLimit={handlePageLimit}
        pageLimit={10}
        page={page}
      />
    </S.Wrapper>
  );
};

export default CheckoutCustomization;
