import React from 'react';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import * as S from './styles';

const SkeletonLoading: React.FC = () => {
  const INPUTS = 3;

  return (
    <S.Wrapper>
      <S.UpdatePromocodeHeaderWrapper>
        <Skeleton height="40px" width="120px" />
        <Skeleton height="25px" width="100%" />
      </S.UpdatePromocodeHeaderWrapper>

      <S.UpdatePromocodeBodyWrapper>
        <S.Card>
          {Array.from({ length: INPUTS }).map(() => (
            <S.InputGroup>
              <Skeleton height="25px" width="100%" />
              <Skeleton height="40px" width="100%" />
            </S.InputGroup>
          ))}

          <S.CheckboxGroup>
            <Skeleton height="15px" width="100%" />
            <Skeleton height="15px" width="100%" />
            <Skeleton height="15px" width="100%" />
          </S.CheckboxGroup>
        </S.Card>
      </S.UpdatePromocodeBodyWrapper>
    </S.Wrapper>
  );
};

export default SkeletonLoading;
