import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { IGenerateCheckoutCodeProvider } from '@domain/interfaces/store/common/checkoutCustomization/IGenerateCheckoutCodeProvider';

import { generateCheckoutScheme } from '@helpers/validators/pages/dashboard/checkoutCustomizations/generateCheckoutScheme';

const GenerateCheckoutCodeContext = React.createContext<IGenerateCheckoutCodeProvider | null>(null);

export const GenerateCheckoutCodeProvider: React.FC = ({ children }) => {
  const [acceptOfferText, setAcceptOfferText] = React.useState('Yes, I want it!');
  const [declineOfferText, setDeclineOfferText] = React.useState('No, thanks!');

  const [colorButtton, setColorButton] = React.useState('#29B765');
  const [colorTextButton, setColorTextButton] = React.useState('#FFFFFF');
  const [colorTextDecline, setColorTextDecline] = React.useState('#000');

  const [acceptUpsellAction, setAcceptUpsellAction] = React.useState<string>('');
  const [externalUpsellUrl, setExternalUpsellUrl] = React.useState<string>('');
  const [declineUpsellAction, setDeclineUpsellAction] = React.useState<string>('');
  const [externalDeclineUrl, setExternalDeclineUrl] = React.useState<string>('');

  const [selectedOfferProduct, setSelectedOfferProduct] = React.useState<any>(undefined);

  const {
    handleSubmit,
    register,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(generateCheckoutScheme),
    defaultValues: {
      acceptOfferText: 'Yes, I want it!',
      declineOfferText: 'No, thanks!',
      acceptUpsellAction: '',
      externalUpsellUrl: '',
      declineUpsellAction: '',
      externalDeclineUrl: '',
      colorButtton: '#29B765',
      colorTextButton: '#FFFFFF',
      colorTextDecline: '#000',
    },
  });

  const acceptOfferTextRegister = register('acceptOfferText');
  const declineOfferTextRegister = register('declineOfferText');
  const acceptUpsellActionRegister = register('acceptUpsellAction');
  const externalUpsellUrlRegister = register('externalUpsellUrl');
  const declineUpsellActionRegister = register('declineUpsellAction');
  const externalDeclineUrlRegister = register('externalDeclineUrl');
  const colorButtonRegister = register('colorButtton');
  const colorTextButtonRegister = register('colorTextButton');
  const colorTextDeclineRegister = register('colorTextDecline');

  const handleAcceptOfferText = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setAcceptOfferText(event.target.value);
  };

  const handleDeclineOfferText = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setDeclineOfferText(event.target.value);
  };

  const handleSelectedOfferProduct = React.useCallback(
    offerProduct => setSelectedOfferProduct(offerProduct),
    [],
  );

  const handleAcceptUpsellExternalUrl = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setExternalUpsellUrl(event.target.value);
  };

  const handleDeclineUpsellExternalUrl = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setExternalDeclineUrl(event.target.value);
  };

  const acceptOfferTextInputValue = watch('acceptOfferText');
  const declineOfferTextInputValue = watch('declineOfferText');

  const generateWidgetCode = (): string => {
    const htmlCode = `
      <div style="text-align: center">
      <button
        id="zouti-upsell-accept-button"
        style="
          background-color: ${colorButtton};
          padding: 12px 16px;
          cursor: pointer;
          color: ${colorTextButton};
          font-weight: 600;
          border-radius: 4px;
          border: 1px solid  ${colorButtton};
          font-size: 20px;
          display: inline-block;
        "
      >
        ${acceptOfferText}
      </button>
      <button
        id="zouti-upsell-cancel-button"
        style="
          background: none;
          border: none;
          padding: 0;
          margin: 0;
          text-decoration: underline;
          font-size: 16px;
          cursor: pointer;
          display: block;
          margin: 10px auto 0 auto;
          font-family: sans-serif;
          color: ${colorTextDecline};
        "
      >
        ${declineOfferText}
      </button>
    </div>
    <script src="https://zouti-prod-core-media-public.s3.us-east-1.amazonaws.com/scripts/zouti_pay_min.js"></script>
    <script>
      (function() {
        let acceptUpsellAction = '${acceptUpsellAction}';
        let declineUpsellAction = '${declineUpsellAction}';
        let acceptUpsellExternalUrl = '${externalUpsellUrl}';
        let declineUpsellExternalUrl = '${externalDeclineUrl}';
        let selectedOfferProductId = '${selectedOfferProduct?.id}';

        window.acceptUpsellAction = acceptUpsellAction;
        window.declineUpsellAction = declineUpsellAction;
        window.acceptUpsellExternalUrl = acceptUpsellExternalUrl;
        window.declineUpsellExternalUrl = declineUpsellExternalUrl;
        window.selectedOfferProductId = selectedOfferProductId;
      })()

    </script>
    `;

    return htmlCode;
  };

  React.useEffect(() => {
    setAcceptOfferText(acceptOfferTextInputValue);
    setDeclineOfferText(declineOfferTextInputValue);
  }, [acceptOfferTextInputValue, declineOfferTextInputValue]);

  return (
    <GenerateCheckoutCodeContext.Provider
      value={{
        colorButtton,
        colorTextButton,
        colorTextDecline,
        setColorButton,
        setColorTextButton,
        setColorTextDecline,
        handleAcceptOfferText,
        handleDeclineOfferText,
        acceptOfferText,
        declineOfferText,
        generateWidgetCode,
        acceptUpsellAction,
        setAcceptUpsellAction,
        selectedOfferProduct,
        handleSelectedOfferProduct,
        externalUpsellUrl,
        handleAcceptUpsellExternalUrl,
        declineUpsellAction,
        setDeclineUpsellAction,
        externalDeclineUrl,
        handleDeclineUpsellExternalUrl,
        acceptOfferTextRegister,
        declineOfferTextRegister,
        colorButtonRegister,
        colorTextButtonRegister,
        colorTextDeclineRegister,
        acceptUpsellActionRegister,
        externalUpsellUrlRegister,
        declineUpsellActionRegister,
        externalDeclineUrlRegister,
        handleSubmit,
        watch,
        errors,
        setValue,
      }}
    >
      {children}
    </GenerateCheckoutCodeContext.Provider>
  );
};

export const useGenerateCheckoutCode = (): IGenerateCheckoutCodeProvider => {
  const context = React.useContext(GenerateCheckoutCodeContext);

  if (!context) {
    throw new Error('useGenerateCheckoutCode must be used within a GenerateCheckoutCodeProvider');
  }

  return context;
};
