import React from 'react';
import { Copy } from '@phosphor-icons/react';
import { CheckCircle, Clock } from 'phosphor-react';
import { useTheme } from 'styled-components/macro';

import Accordion from '@components/common/Surfaces/Accordion';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/common/EHeading';

import { useToast } from '@store/context/common/ToastContext';
import { useTransactionalEmail } from '@store/common/TransactionalEmail/TransactionalEmailContext';

import * as S from './styles';

interface IDnsDataProps {
  success?: boolean;
}

interface ICopyAreaIconProps {
  success?: boolean;
}

function CopyAreaIcon({ success }: ICopyAreaIconProps): JSX.Element {
  const theme = useTheme();

  if (success) {
    return (
      <S.IconWrapper success={success}>
        <CheckCircle color={theme.colors.positive.darker} size={20} />
      </S.IconWrapper>
    );
  }

  return (
    <S.IconWrapper success={success}>
      <Clock color={theme.colors.warning.darker} size={20} />
    </S.IconWrapper>
  );
}

function DnsData({ ...props }: IDnsDataProps): JSX.Element {
  const { success } = props;

  console.log({ success });

  const theme = useTheme();
  const { toast } = useToast();
  const { verifyEmail, isVerifyingEmail, transactionEmailData } = useTransactionalEmail();

  const copyTextToClipboard = async (text: string): Promise<void> => {
    try {
      await navigator.clipboard.writeText(text);

      toast.success('Copiado com sucesso!');
    } catch (err) {
      toast.error('Erro ao copiar!');
    }
  };

  const firstType = (transactionEmailData?.dns?.mail_server?.type || '').toUpperCase();
  const secondType = (transactionEmailData?.dns?.subdomain_spf?.type || '').toUpperCase();
  const thirdType = (transactionEmailData?.dns?.dkim?.type || '').toUpperCase();
  const firstHost = transactionEmailData?.dns?.mail_server?.host || '';
  const secondHost = transactionEmailData?.dns?.subdomain_spf?.host || '';
  const thirdHost = transactionEmailData?.dns?.dkim?.host || '';
  const firstValue = transactionEmailData?.dns?.mail_server?.data || '';
  const secondValue = transactionEmailData?.dns?.subdomain_spf?.data || '';
  const thirdValue = transactionEmailData?.dns?.dkim?.data || '';

  const copyIconColor = success ? theme.colors.positive.darker : theme.colors.warning.darker;

  return (
    <Accordion title="Apontamentos do remetente" initOpen={!success}>
      <S.Wrapper>
        <S.Divider />

        <S.CopyArea success={success}>
          <CopyAreaIcon success={success} />

          <S.CopyWrapperItems>
            <S.CopyAreaKey>Tipo</S.CopyAreaKey>
            <S.CopyAreaValue success={success}>{firstType}</S.CopyAreaValue>
          </S.CopyWrapperItems>

          <S.CopyWrapperItems>
            <S.CopyAreaKey>Host</S.CopyAreaKey>
            <S.CopyAreaValue success={success}>{firstHost}</S.CopyAreaValue>
            <Copy size={20} onClick={() => copyTextToClipboard(firstHost)} color={copyIconColor} />
          </S.CopyWrapperItems>

          <S.CopyWrapperItems>
            <S.CopyAreaKey>Valor</S.CopyAreaKey>
            <S.CopyAreaValue success={success}>{firstValue}</S.CopyAreaValue>
            <Copy size={20} onClick={() => copyTextToClipboard(firstValue)} color={copyIconColor} />
          </S.CopyWrapperItems>
        </S.CopyArea>

        <S.CopyArea success={success}>
          <CopyAreaIcon success={success} />

          <S.CopyWrapperItems>
            <S.CopyAreaKey>Tipo</S.CopyAreaKey>
            <S.CopyAreaValue success={success}>{secondType}</S.CopyAreaValue>
          </S.CopyWrapperItems>

          <S.CopyWrapperItems>
            <S.CopyAreaKey>Host</S.CopyAreaKey>
            <S.CopyAreaValue success={success}>{secondHost}</S.CopyAreaValue>
            <Copy size={20} onClick={() => copyTextToClipboard(secondHost)} color={copyIconColor} />
          </S.CopyWrapperItems>

          <S.CopyWrapperItems>
            <S.CopyAreaKey>Valor</S.CopyAreaKey>
            <S.CopyAreaValue success={success}>{secondValue}</S.CopyAreaValue>
            <Copy
              size={20}
              onClick={() => copyTextToClipboard(secondValue)}
              color={copyIconColor}
            />
          </S.CopyWrapperItems>
        </S.CopyArea>

        <S.CopyArea success={success}>
          <CopyAreaIcon success={success} />

          <S.CopyWrapperItems>
            <S.CopyAreaKey>Tipo</S.CopyAreaKey>
            <S.CopyAreaValue success={success}>{thirdType}</S.CopyAreaValue>
          </S.CopyWrapperItems>

          <S.CopyWrapperItems>
            <S.CopyAreaKey>Host</S.CopyAreaKey>
            <S.CopyAreaValue success={success}>{thirdHost}</S.CopyAreaValue>
            <Copy size={20} onClick={() => copyTextToClipboard(thirdHost)} color={copyIconColor} />
          </S.CopyWrapperItems>

          <S.CopyWrapperItems>
            <S.CopyAreaKey>Valor</S.CopyAreaKey>
            <S.CopyAreaValue success={success}>{thirdValue}</S.CopyAreaValue>
            <Copy size={20} onClick={() => copyTextToClipboard(thirdValue)} color={copyIconColor} />
          </S.CopyWrapperItems>
        </S.CopyArea>
      </S.Wrapper>
    </Accordion>
  );
}

export default DnsData;
