import React from 'react';
import DatePicker from 'react-datepicker';

import { useDatePicker } from '@store/context/components/DatePickerContext';

import Header from './Header';

import * as S from './styles';

interface ICalendarRangeProps {
  isShowPreDefinedDate?: boolean;
  isDisabledDaysAfterToday?: boolean;
}

const CalendarRange: React.FC<ICalendarRangeProps> = ({
  isShowPreDefinedDate,
  isDisabledDaysAfterToday,
}) => {
  const { handleCustomDate, customStartDate, customEndDate } = useDatePicker();

  const getNameOfDay = React.useCallback(
    (nameOfDay: string) =>
      ({
        Sunday: 'Dom',
        Monday: 'Seg',
        Tuesday: 'Ter',
        Wednesday: 'Qua',
        Thursday: 'Qui',
        Friday: 'Sex',
        Saturday: 'Sab',
      }[nameOfDay]),
    [],
  );

  return (
    <S.Wrapper>
      <DatePicker
        selected={customStartDate}
        onChange={handleCustomDate}
        startDate={customStartDate}
        endDate={customEndDate}
        monthsShown={2}
        selectsRange
        inline
        formatWeekDay={nameOfDay => getNameOfDay(nameOfDay)}
        peekNextMonth={false}
        minDate={!isShowPreDefinedDate ? new Date() : undefined}
        maxDate={isDisabledDaysAfterToday ? new Date() : undefined}
        renderCustomHeader={({ monthDate, changeYear, decreaseMonth, increaseMonth }) => (
          <Header
            date={monthDate}
            changeYear={changeYear}
            decreaseMonth={decreaseMonth}
            increaseMonth={increaseMonth}
          />
        )}
      />
    </S.Wrapper>
  );
};

export default CalendarRange;
