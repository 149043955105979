import React from 'react';

import { useGetPayouts } from '@store/common/Payout/GetPayoutsContext';

import { PayoutsSummary } from './PayoutsSummary';
import { RecipientInfo } from './RecipientInfo';
import { StatusMessage } from './StatusMessage';

import * as S from './styles';

const DetailPayoutModal: React.FC = () => {
  const { isOpenModal, setIsOpenModal, selectedPayout } = useGetPayouts();

  const payoutType = selectedPayout?.pix_key_id ? 'PIX' : 'BANK_ACCOUNT';
  const document = selectedPayout?.pix_key_id
    ? selectedPayout?.pix_key_id
    : selectedPayout?.bank_account?.holder_document;

  const handleToggle = (): void => {
    setIsOpenModal(state => !state);
  };

  return (
    <S.Modal title="Detalhes da transferência" toggle={handleToggle} isOpen={isOpenModal}>
      <S.Wrapper>
        <S.TransferContent>
          <PayoutsSummary
            amount={selectedPayout?.amount}
            status={selectedPayout?.payout_status}
            transferId={selectedPayout?.id}
            fee={selectedPayout?.fee}
            createdAt={selectedPayout?.created_at}
            updatedAt={selectedPayout?.updated_at}
          />
          <RecipientInfo
            account={selectedPayout?.bank_account?.account_number}
            payoutType={payoutType}
            accountType={selectedPayout?.bank_account?.type}
            agency={selectedPayout?.bank_account?.routing_number}
            bank={selectedPayout?.bank_account?.bank_code}
            document={document}
            name={selectedPayout?.bank_account?.holder_name}
          />
          <StatusMessage
            status={selectedPayout?.payout_status}
            canceledReason={selectedPayout?.canceled_reason}
          />
        </S.TransferContent>
      </S.Wrapper>
    </S.Modal>
  );
};

export default DetailPayoutModal;
