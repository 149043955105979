import styled from 'styled-components/macro';
import Skeleton from 'react-loading-skeleton';

export const Wrapper = styled.div`
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
`;

export const Header = styled.div`
  width: 100%;
`;

export const Select = styled(Skeleton)`
  position: absolute;
  z-index: 2;
  top: 68%;
  left: 36%;
`;

export const ContainerIcon = styled(Skeleton)`
  position: absolute;
  border-radius: 8px;
  z-index: 2;
  top: 49%;
  left: 2%;
`;

export const Body = styled.div`
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 440px auto;
  gap: 20px;
`;

export const ZoutiPay = styled.div`
  width: 100%;
  height: 272px;
`;

export const Credentials = styled.div`
  width: 100%;
  height: 272px;
`;
