import styled, { css } from 'styled-components';

import Text from '@components/common/DataDisplay/Text';

interface ICardProps {
  isHovering: boolean;
  isSelected: boolean;
}

export const TextBase = styled(Text)`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.neutral.black};
`;

export const CurrencySymbol = styled(TextBase)<{ isnegative: boolean }>`
  ${({ theme, isnegative }) =>
    isnegative &&
    css`
      color: ${theme.colors.negative.dark};
    `}
`;
export const BalanceValue = styled(TextBase)<{ isnegative: boolean }>`
  font-size: 2.4rem;

  ${({ theme, isnegative }) =>
    isnegative &&
    css`
      color: ${theme.colors.negative.dark};
    `}
`;

export const IconContainer = styled.div<{ color: string }>`
  display: flex;
  border-radius: 6px;
  padding: 5px;
  background-color: ${({ color }) => color};
`;

export const CardContent = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const CardTitle = styled(Text)`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.neutral.black};
`;

export const CardHeader = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const Card = styled.div<ICardProps>`
  display: flex;
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  background-color: ${({ theme }) => theme.colors.neutral.white};
  flex-direction: column;
  gap: 24px;
  cursor: pointer;
  transition: all 0.3s;

  ${({ theme, isSelected }) =>
    isSelected &&
    css`
      background-color: ${theme.colors.primary.lemon_20};
      border: 1px solid ${theme.colors.primary.green_lemon};
    `}
`;
