import React from 'react';

import { GetShippingProvider } from '@store/common/shipping/GetShippingContext';

import Content from '@components/pages/Dashboard/Shipping/GetShipping';

const Shipping: React.FC = () => {
  return (
    <GetShippingProvider>
      <Content />
    </GetShippingProvider>
  );
};

export default Shipping;
