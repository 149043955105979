import React from 'react';

import Content from '@components/pages/Dashboard/CheckoutCustomization/GenerateCheckout';
import { GenerateCheckoutCodeProvider } from '@store/common/checkoutCustomization/GenerateCheckoutCodeContext';

const GenerateCheckout: React.FC = () => {
  return (
    <GenerateCheckoutCodeProvider>
      <Content />
    </GenerateCheckoutCodeProvider>
  );
};

export default GenerateCheckout;
