import styled from 'styled-components/macro';
import { ArrowRight } from 'phosphor-react';

import HeadingBase from '@components/common/DataDisplay/Heading';
import TextBase from '@components/common/DataDisplay/Text';

export const Wrapper = styled.div`
  padding: 24px;
  height: 300px;
  box-sizing: border-box;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.neutral.white};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  display: flex;
  flex-direction: column;
`;

export const Title = styled(HeadingBase)`
  color: ${({ theme }) => theme.colors.neutral.black};
`;

export const Divider = styled.hr`
  margin: 16px 0;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
`;

export const Value = styled(TextBase)`
  display: flex;
  align-items: center;
  gap: 7px;
  color: ${({ theme }) => theme.colors.neutral.darkest};
`;

export const Label = styled(TextBase)`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.neutral.dark};
`;

export const LabelAndValue = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const GlobalCurrency = styled.span``;

export const UnityGlobalCurrency = styled.span``;

export const BRLCurrency = styled.span``;

export const ArrowRightIcon = styled(ArrowRight).attrs({
  weight: 'bold',
})`
  color: ${({ theme }) => theme.colors.neutral.darker};
`;

export const WrapperInfos = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: space-between;
  flex-grow: 1;
`;

export const ValueAndCurrencyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const RefusedDivider = styled.hr`
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
`;

export const RefusedInfoCard = styled.div`
  padding: 16px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.negative.lightest_10};
`;

export const RefusedInfoText = styled(TextBase)`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.negative.base};
`;
