import styled from 'styled-components/macro';
import { EDomainStatus } from '@domain/enums/common/domain/EDomain';

interface IBadgeProps {
  status: EDomainStatus;
}

export const Badge = styled.span<IBadgeProps>`
  display: inline-block;
  padding: 4px 8px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;

  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  text-transform: uppercase;

  ${({ theme, status }) => {
    if (status === EDomainStatus.PENDING) {
      return `
        color: ${theme.colors.negative.dark};
        background: ${theme.colors.negative.lightest};
      `;
    }

    if (status === EDomainStatus.ACTIVE) {
      return `
      color: ${theme.colors.positive.dark};
      background: ${theme.colors.positive.lightest};
    `;
    }

    if (status === EDomainStatus.DEFAULT) {
      return `
      color: ${theme.colors.neutral.darker};
      background: ${theme.colors.neutral.lightest};
    `;
    }

    return '';
  }}
`;
