import React from 'react';

import { useListUpsells } from '@store/common/upsell/ListUpsellContext';

import EmptyData from '@components/common/utils/EmptyData';
import ListSkeletonLoading from '@components/common/utils/ListSkeletonLoading';
import TableRow from './TableRow';

import * as S from './styles';

const Body: React.FC = () => {
  const { upsells, isLoadingUpsells, isUpsellsError } = useListUpsells();

  if (isLoadingUpsells) {
    return <ListSkeletonLoading />;
  }

  if (isUpsellsError && !isLoadingUpsells) {
    return <div>Error...</div>;
  }

  if (upsells.length <= 0) {
    return (
      <EmptyData
        title="Nenhum upsell cadastrado"
        description="Adicione as informações e valores de frete para sua loja."
        buttonText="Novo upsell"
      />
    );
  }

  return (
    <S.Wrapper>
      {Boolean(upsells.length) && (
        <S.Table>
          <S.Table.Header>
            <S.Table.Row>
              <S.Table.Head>Status</S.Table.Head>
              <S.Table.Head>Titulo</S.Table.Head>
              <S.Table.Head>Ao comprar o produto...</S.Table.Head>
              <S.Table.Head>Oferecer o produto...</S.Table.Head>
              <S.Table.Head />
            </S.Table.Row>
          </S.Table.Header>

          <S.Table.Body>
            {upsells.map(upsell => (
              <TableRow upsell={upsell} key={upsell.id} />
            ))}
          </S.Table.Body>
        </S.Table>
      )}
    </S.Wrapper>
  );
};

export default Body;
