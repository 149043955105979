import { AxiosResponse } from 'axios';

import {
  IProductService,
  IGetProductProps,
  IGetProductResponse,
  IListProductsProps,
  IListProductsResponse,
} from '@domain/interfaces/hooks/services/products/IProductService';

import { dashboardInstance } from '@services/common/dashboardInstance';

export const useProductService = (): IProductService => {
  const listProducts = async ({
    accountId,
    checkoutId,
    page,
    perPage,
    query,
  }: IListProductsProps): Promise<AxiosResponse<IListProductsResponse>> => {
    return dashboardInstance.get(
      `accounts/${accountId}/checkouts/${checkoutId}/products?page=${page}&per_page=${perPage}&${
        query || ''
      }`,
      {
        headers: {
          'x-zouti-account': accountId,
        },
      },
    );
  };

  const getProduct = async ({
    productId,
    accountId,
    checkoutId,
  }: IGetProductProps): Promise<AxiosResponse<IGetProductResponse>> => {
    return dashboardInstance.get(
      `accounts/${accountId}/checkouts/${checkoutId}/products/${productId}`,
      {
        headers: {
          'x-zouti-account': accountId,
        },
      },
    );
  };

  return { listProducts, getProduct };
};
