import styled from 'styled-components/macro';

import LinkBase from '@components/common/Navigation/Link';
import TextFieldBase from '@components/common/Inputs/TextField';
import TextBase from '@components/common/DataDisplay/Text';
import LabelBase from '@components/common/DataDisplay/Label';
import InputGroupBase from '@components/common/Inputs/InputGroup';
import ButtonBase from '@components/common/Inputs/Button';

export const Link = styled(LinkBase)`
  color: ${({ theme }) => theme.colors.neutral.darkest};
  font-size: 1.4rem;
  font-weight: inherit;
  text-align: end;
  text-decoration: none;
  display: block;
  margin-top: 8px;
`;

export const SubmitButton = styled(ButtonBase)`
  margin-top: 20px;
`;

export const TextField = styled(TextFieldBase)``;

export const Text = styled(TextBase)`
  text-align: center;
`;

export const Label = styled(LabelBase)``;

export const InputGroup = styled(InputGroupBase)`
  margin-bottom: 16px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 380px;
  width: 100%;
`;
