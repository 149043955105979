import axios from 'axios';

import { DASHBOARD_API_URL } from '@constants/services/apiUrl';

import { getEnvironment } from '@utils/common/environment';

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const xZoutiAccountLocalStorage = localStorage.getItem(
  `@zouti-dashboard:${getEnvironment()}/token`,
);

const zoutiAccount = xZoutiAccountLocalStorage ? JSON.parse(xZoutiAccountLocalStorage) : undefined;

export const dashboardInstance = axios.create({
  baseURL: DASHBOARD_API_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'User-Timezone': timezone,
    Accept: 'application/json',
    // access_key: zoutiAccount,
    'x-zouti-domain': window.location.origin,
  },
});
