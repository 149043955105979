import styled from 'styled-components/macro';

import TextBase from '@components/common/DataDisplay/Text';

export const LabelText = styled(TextBase)`
  color: ${({ theme }) => theme.colors.neutral.dark};
`;

export const DotColor = styled.div`
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  margin-right: 8px;
`;

export const Values = styled.div`
  color: ${({ color }) => color};
  font-family: ${({ theme }) => theme.fonts.text};
  font-size: 1.2rem;
  font-weight: 500;
`;

export const DotColorAndValuesWrapper = styled.div`
  display: flex;
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`;

export const TooltipTitle = styled(TextBase)`
  margin-bottom: 4px;
`;

export const TooltipCard = styled.div`
  padding: 16px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.neutral.white};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
`;
