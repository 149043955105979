import React from 'react';

import { useGenerateCheckoutCode } from '@store/common/checkoutCustomization/GenerateCheckoutCodeContext';

import * as S from './styles';

const CodePreview: React.FC = () => {
  const { generateWidgetCode } = useGenerateCheckoutCode();

  const [previewCode, setPreviewCode] = React.useState(generateWidgetCode());

  React.useEffect(() => {
    setPreviewCode(generateWidgetCode());
  }, [generateWidgetCode]);

  return (
    <S.Wrapper>
      <S.PreviewWrapper>
        <S.PreviewText>Preview Upsell</S.PreviewText>
        <S.PreviewBox>
          <div dangerouslySetInnerHTML={{ __html: previewCode }} />
        </S.PreviewBox>
      </S.PreviewWrapper>
    </S.Wrapper>
  );
};

export default CodePreview;
