import React from 'react';
import { useTheme } from 'styled-components/macro';
import { useNavigate, useParams } from 'react-router-dom';

import { PencilSimple, Trash, Users } from 'phosphor-react';

import { EHeadAlign } from '@domain/enums/components/common/ETable';
import { IGroupsProps, useGroupsContext } from '@store/common/Group/NewGroupsContext';

import * as S from './styles';

interface ITableRowProps {
  group: IGroupsProps;
}

export const TableRow: React.FC<ITableRowProps> = ({ group }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { accountId, checkoutId } = useParams();
  const { onToggleDeleteModel, setDeleteGroupData } = useGroupsContext();

  const [isVisibleActions, setIsVisibleActions] = React.useState<boolean>(false);

  const onRowMouseEnter = React.useCallback(() => {
    setIsVisibleActions(true);
  }, []);

  const onRowMouseLeave = React.useCallback(() => {
    setIsVisibleActions(false);
  }, []);

  const groupId = group.id;
  const groupName = group.name;
  const { qtdUsers } = group;

  const onEditButtonClick = (): void => {
    navigate(`/${accountId}/${checkoutId}/dashboard/settings/membership-groups/${groupId}`);
  };

  const onDeleteButtonClick = (): void => {
    onToggleDeleteModel();
    setDeleteGroupData({ groupName, membershipGroupId: groupId });
  };

  return (
    <S.TableRow onMouseEnter={onRowMouseEnter} onMouseLeave={onRowMouseLeave}>
      {/* <S.Table.Data>
        <S.Checkbox />
      </S.Table.Data> */}

      <S.TableDataName>
        <S.IconContainer>
          <Users size={13} weight="bold" color={theme.colors.primary.dark_green} />
        </S.IconContainer>
        <S.Text>{groupName}</S.Text>
      </S.TableDataName>

      <S.Table.Data>
        <S.Text>{qtdUsers}</S.Text>
      </S.Table.Data>

      <S.TableDataAction align={EHeadAlign.RIGHT}>
        <S.Dropdown>
          <S.DropdownTrigger>
            <S.TableAction>
              {isVisibleActions && (
                <S.DotsThreeIcon color={theme.colors.neutral.darker} size={18} weight="bold" />
              )}
            </S.TableAction>
          </S.DropdownTrigger>
          <S.DropdownContent>
            <S.DropdownItem onClick={onEditButtonClick}>
              <PencilSimple color={theme.colors.neutral.darker} size={18} weight="bold" />
              Editar
            </S.DropdownItem>
            <S.DropdownItem onClick={onDeleteButtonClick}>
              <Trash />
              Excluir
            </S.DropdownItem>
          </S.DropdownContent>
        </S.Dropdown>
      </S.TableDataAction>
    </S.TableRow>
  );
};
