import styled from 'styled-components';

export const Accordion = styled.div`
  border-radius: 4px;
  margin-bottom: 10px;
`;

export const AccordionHeader = styled.div.attrs({
  className: 'AccordionHeader',
})`
  cursor: pointer;
  padding: 16px 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-family: Inter;
`;

export const AccordionContent = styled.div.attrs({
  className: 'AccordionContent',
})`
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s;
`;

export const AccordionHeaderTitle = styled.div.attrs({
  className: 'AccordionHeaderTitle',
})`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 9px;
  width: 100%;
`;
