import React from 'react';

import Body from './Body';
import Header from './Header';

import * as S from './styles';

const NewUpsell: React.FC = () => {
  return (
    <S.Wrapper>
      <Header />
      <Body />
    </S.Wrapper>
  );
};

export default NewUpsell;
