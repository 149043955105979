import styled, { css } from 'styled-components/macro';

import { EHeadAlign } from '@domain/enums/components/common/ETable';

export interface IHeadStyleProps {
  upperCase?: boolean;
  textAlign?: EHeadAlign;
}

export const Head = styled.th<IHeadStyleProps>`
  color: #515151;
  font-family: ${({ theme }) => theme.fonts.typography};
  font-weight: 600;
  font-size: 14px;
  text-align: left;

  ${({ textAlign }) =>
    textAlign === EHeadAlign.CENTER &&
    css`
      text-align: center;
    `};

  ${({ textAlign }) =>
    textAlign === EHeadAlign.RIGHT &&
    css`
      text-align: right;
    `};

  ${({ upperCase }) =>
    upperCase &&
    css`
      text-transform: uppercase;
    `};
`;
