import React from 'react';
import useSWR from 'swr';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { EUpsellType } from '@domain/enums/common/upsell/EUpsellType';
import { IVariant } from '@domain/interfaces/common/variant/IVariant';
import { IUpdateUpsellProvider } from '@domain/interfaces/store/common/upsell/IUpdateUpsellProvider';
import { ISwr } from '@domain/interfaces/common/swr/ISwr';
import {
  IUpsell,
  IUpsellOfferProduct,
  IUpsellTriggerProduct,
} from '@domain/interfaces/common/upsell/IUpsell';

import { DASHBOARD_API_URL } from '@constants/services/apiUrl';

import { upsellSchema } from '@helpers/validators/pages/dashboard/upsell/upsellSchema';

import { numberFormatter } from '@helpers/utils/common/numberFormatter';

import { useErrorHandler } from '@store/context/common/ErrorHandlerContext';
import { useUpsellService } from '@hooks/services/upsell/useUpsellService';
import { useAnalytics } from '@store/context/common/AnalyticsContext';

import { dashboardInstance } from '@services/common/dashboardInstance';

const UpdateUpsellContext = React.createContext<IUpdateUpsellProvider | null>(null);

export const UpdateUpsellProvider: React.FC = ({ children }) => {
  const navigate = useNavigate();
  const { handleError } = useErrorHandler();
  const { updateUpsell } = useUpsellService();
  const { accountId, checkoutId, upsellId } = useParams();
  const { analytics } = useAnalytics();

  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
    setValue,
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(upsellSchema),
  });

  const nameRegister = register('name');
  const priceRegister = register('price');
  const promotionalPriceRegister = register('promotional_price');
  const titleRegister = register('title');
  const descriptionRegister = register('description');

  dashboardInstance.defaults.headers.common['x-zouti-account'] = accountId;

  const UPSELL_URL = `${DASHBOARD_API_URL}/accounts/${accountId}/checkouts/${checkoutId}/upsells/${upsellId}`;

  const {
    data: upsellData,
    isLoading: isLoadingUpsell,
    isValidating: isValidatingUpsell,
    error: upsellError,
  } = useSWR<ISwr>(UPSELL_URL, dashboardInstance);

  const [isUpdatingUpsell, setIsUpdatingUpsell] = React.useState<boolean>(false);
  const [selectedOfferProduct, setSelectedOfferProduct] = React.useState<
    IUpsellOfferProduct | undefined
  >(undefined);
  const [selectedTriggerProduct, setSelectedTriggerProduct] = React.useState<
    IUpsellTriggerProduct | undefined
  >(undefined);
  const [selectedVariant, setSelectedVariant] = React.useState<IVariant | undefined>(undefined);
  const [selectedPrimaryColor, setSelectedPrimaryColor] = React.useState<string | undefined>(
    undefined,
  );
  const [selectedTextColor, setSelectedTextColor] = React.useState<string | undefined>(undefined);
  const [hasSelectedAllVariants, setHasSelectedAllVariants] = React.useState<boolean>(false);
  const [upsell, setUpsell] = React.useState<IUpsell | undefined>(undefined);

  const handleSelectedOfferProduct = React.useCallback(
    offerProduct => setSelectedOfferProduct(offerProduct),
    [],
  );

  const handleSelectedTriggerProduct = React.useCallback(
    triggerProduct => setSelectedTriggerProduct(triggerProduct),
    [],
  );

  const handleSelectedVariant = React.useCallback(variant => {
    if (variant === 'all') {
      setHasSelectedAllVariants(true);
      setSelectedVariant(undefined);
    } else {
      setHasSelectedAllVariants(false);
      setSelectedVariant(variant);
    }
  }, []);

  const handleSelectedPrimaryColor = React.useCallback(
    primaryColor => setSelectedPrimaryColor(primaryColor),
    [],
  );

  const handleSelectedTextColor = React.useCallback(
    textColor => setSelectedTextColor(textColor),
    [],
  );

  const updateCurrentUpsell = React.useCallback(
    async data => {
      setIsUpdatingUpsell(true);

      try {
        await updateUpsell({ payload: data, accountId, checkoutId, upsellId });

        analytics.track('Upsell Updated');

        setIsUpdatingUpsell(false);

        navigate(`/${accountId}/${checkoutId}/dashboard/marketing/upsells`);
      } catch (responseError: any) {
        setIsUpdatingUpsell(false);
        handleError(responseError);
      }
    },
    [updateUpsell, handleError, navigate, accountId, checkoutId, upsellId, analytics],
  );

  React.useEffect(() => {
    if (upsellError) {
      handleError(upsellError);
      return;
    }

    if (upsellData?.data.object) {
      setUpsell(upsellData?.data.object);
    }
  }, [upsellError, handleError, upsellData]);

  React.useEffect(() => {
    if (upsell) {
      setValue('name', upsell.name);
      setValue('price', numberFormatter(upsell.price / 100, 2));
      setValue('promotional_price', numberFormatter(upsell.promotional_price / 100, 2));
      setValue('title', upsell.title);
      setValue('description', upsell.description);
      setSelectedPrimaryColor(upsell.primary_hex_color);
      setSelectedTextColor(upsell.text_hex_color);
      setSelectedTriggerProduct(upsell.trigger_product);
      setSelectedOfferProduct(upsell.offer_product);

      if (upsell.type === EUpsellType.ALL_VARIANTS) {
        setHasSelectedAllVariants(true);
      } else {
        setSelectedVariant(upsell.offer_product.variants?.[0]);
      }
    }
  }, [upsell, setValue]);

  const isLoading = isLoadingUpsell || isValidatingUpsell;

  return (
    <UpdateUpsellContext.Provider
      value={{
        updateCurrentUpsell,
        isUpdatingUpsell,
        handleSelectedOfferProduct,
        handleSelectedTriggerProduct,
        handleSelectedVariant,
        selectedOfferProduct,
        selectedTriggerProduct,
        selectedVariant,
        descriptionRegister,
        handleSubmit,
        nameRegister,
        priceRegister,
        promotionalPriceRegister,
        titleRegister,
        watch,
        handleSelectedPrimaryColor,
        handleSelectedTextColor,
        selectedPrimaryColor,
        selectedTextColor,
        hasSelectedAllVariants,
        errors,
        isLoading,
        upsell,
      }}
    >
      {children}
    </UpdateUpsellContext.Provider>
  );
};

export const useUpdateUpsell = (): IUpdateUpsellProvider => {
  const context = React.useContext(UpdateUpsellContext);

  if (!context) {
    throw new Error('useUpdateUpsell must be used within provider');
  }

  return context;
};
