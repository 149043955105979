export const cpfFormatter = (value = ''): string => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');
};

export const formattedCpfToRaw = (value = ''): string => {
  return value.replaceAll('.', '').replaceAll('-', '');
};
