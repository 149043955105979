import React from 'react';

import { useTheme } from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { PencilSimple as EditIcon, Trash as TrashIcon } from 'phosphor-react';

import { IPromocode } from '@domain/interfaces/common/promocode/IPromocode';
import { EHeadAlign } from '@domain/enums/components/common/ETable';

import { useGetPromocode } from '@store/common/promocode/GetPromocodeContext';

import Badge from './Badge';

import * as S from './styles';

interface ITableRowProps {
  promocode: IPromocode;
}

const TableRow: React.FC<ITableRowProps> = ({ promocode }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { accountId, checkoutId } = useParams();

  const {
    deleteCurrentPromocode,
    getPromocodeStatus,
    activateCurrentPromocode,
    inactivateCurrentPromocode,
  } = useGetPromocode();

  const [isVisibleActions, setIsVisibleActions] = React.useState<boolean>(false);

  const isInactivated = Object.prototype.hasOwnProperty.call(promocode, 'inactivated_at');

  const onRowMouseEnter = React.useCallback(() => {
    setIsVisibleActions(true);
  }, []);

  const onRowMouseLeave = React.useCallback(() => {
    setIsVisibleActions(false);
  }, []);

  const onEditPromocodeButtonClick = (promocodeId: string): void => {
    navigate(`/${accountId}/${checkoutId}/dashboard/marketing/coupon/${promocodeId}`);
  };

  const onSwitchChange = React.useCallback(async () => {
    if (isInactivated) {
      await activateCurrentPromocode(promocode.id);
    } else {
      await inactivateCurrentPromocode(promocode.id);
    }
  }, [isInactivated, activateCurrentPromocode, inactivateCurrentPromocode, promocode]);

  const promocodeId = promocode.id;
  const promocodeCode = promocode.code;
  const promocodeDescription = promocode.description;
  const promocodeStatus = getPromocodeStatus(promocode);
  const promocodeUsed = promocode.used_quantity;

  return (
    <S.Table.Row onMouseEnter={onRowMouseEnter} onMouseLeave={onRowMouseLeave} borderBottom>
      <S.Table.Data>
        <S.Switch
          id={promocodeId}
          name={promocodeId}
          checked={!isInactivated}
          onChange={onSwitchChange}
          disabled={promocodeStatus === 'EXPIRED'}
        />
      </S.Table.Data>

      <S.Table.Data>
        <S.TextAndSubTextWrapper>
          <S.Text>{promocodeCode}</S.Text>
        </S.TextAndSubTextWrapper>
      </S.Table.Data>

      <S.Table.Data>
        <S.TextAndSubTextWrapper>
          <S.Text>{promocodeDescription}</S.Text>
        </S.TextAndSubTextWrapper>
      </S.Table.Data>

      <S.Table.Data>
        <Badge status={promocodeStatus} />
      </S.Table.Data>

      <S.Table.Data align={EHeadAlign.RIGHT}>
        <S.Text>{promocodeUsed}</S.Text>
      </S.Table.Data>

      <S.Table.Data align={EHeadAlign.RIGHT}>
        {isVisibleActions ? (
          <S.ButtonActionsWrapper>
            <S.Table.Action onClick={() => onEditPromocodeButtonClick(promocodeId)}>
              <EditIcon size={18} color={theme.colors.neutral.darker} weight="bold" />
            </S.Table.Action>
            <S.Table.Action onClick={() => deleteCurrentPromocode(promocodeId)}>
              <TrashIcon size={18} color={theme.colors.neutral.darker} weight="bold" />
            </S.Table.Action>
          </S.ButtonActionsWrapper>
        ) : (
          <S.ButtonActionsWrapper />
        )}
      </S.Table.Data>
    </S.Table.Row>
  );
};

export default TableRow;
