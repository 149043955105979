import { AxiosResponse } from 'axios';

import {
  IActivateUpsellProps,
  ICreateUpsellProps,
  ICreateUpsellResponse,
  IDeleteUpsellProps,
  IGetUpsellProps,
  IGetUpsellResponse,
  IInactivateUpsellProps,
  IUpdateUpsellProps,
  IUpdateUpsellResponse,
  IUpsellService,
} from '@domain/interfaces/hooks/services/upsell/IUpsellService';

import { dashboardInstance } from '@services/common/dashboardInstance';

export const useUpsellService = (): IUpsellService => {
  const getUpsell = async ({
    upsellId,
    accountId,
    checkoutId,
  }: IGetUpsellProps): Promise<AxiosResponse<IGetUpsellResponse>> => {
    return dashboardInstance.get(
      `accounts/${accountId}/checkouts/${checkoutId}/upsells/${upsellId}`,
      {
        headers: {
          'x-zouti-account': accountId,
        },
      },
    );
  };

  const createUpsell = async ({
    payload,
    accountId,
    checkoutId,
  }: ICreateUpsellProps): Promise<AxiosResponse<ICreateUpsellResponse>> => {
    return dashboardInstance.post(
      `accounts/${accountId}/checkouts/${checkoutId}/upsells`,
      { ...payload },
      {
        headers: {
          'x-zouti-account': accountId,
        },
      },
    );
  };

  const activateUpsell = async ({
    upsellId,
    accountId,
    checkoutId,
  }: IActivateUpsellProps): Promise<AxiosResponse<void>> => {
    return dashboardInstance.put(
      `accounts/${accountId}/checkouts/${checkoutId}/upsells/${upsellId}/activate`,
      {},
      {
        headers: {
          'x-zouti-account': accountId,
        },
      },
    );
  };

  const inactivateUpsell = async ({
    upsellId,
    accountId,
    checkoutId,
  }: IInactivateUpsellProps): Promise<AxiosResponse<void>> => {
    return dashboardInstance.put(
      `accounts/${accountId}/checkouts/${checkoutId}/upsells/${upsellId}/inactivate`,
      {},
      {
        headers: {
          'x-zouti-account': accountId,
        },
      },
    );
  };

  const updateUpsell = async ({
    upsellId,
    payload,
    accountId,
    checkoutId,
  }: IUpdateUpsellProps): Promise<AxiosResponse<IUpdateUpsellResponse>> => {
    return dashboardInstance.put(
      `accounts/${accountId}/checkouts/${checkoutId}/upsells/${upsellId}`,
      { ...payload },
      {
        headers: {
          'x-zouti-account': accountId,
        },
      },
    );
  };

  const deleteUpsell = async ({
    upsellId,
    accountId,
    checkoutId,
  }: IDeleteUpsellProps): Promise<AxiosResponse<void>> => {
    return dashboardInstance.delete(
      `/accounts/${accountId}/checkouts/${checkoutId}/upsells/${upsellId}`,
      {
        headers: {
          'x-zouti-account': accountId,
        },
      },
    );
  };

  return {
    getUpsell,
    createUpsell,
    updateUpsell,
    deleteUpsell,
    activateUpsell,
    inactivateUpsell,
  };
};
