import { IChart } from '@domain/interfaces/store/common/dashboard/IRevenue';

interface INewData {
  name: string;
  past: number;
  current: number;
}

const formatDate = (date: string): string => {
  const [year, month, day] = date.split('-');

  return `${day}/${month}/${year}`;
};

export const mapNetRevenue = (
  pastData: Array<IChart> | undefined,
  currentData: Array<IChart> | undefined,
): Array<INewData> => {
  if (!pastData || !currentData) return [];

  const mappedData = currentData.map((value, index) => ({
    name: formatDate(value.date),
    past: pastData[index].value ? pastData[index].value / 100 : 0,
    current: value.value ? value.value / 100 : 0,
    past_name: formatDate(pastData[index].date),
  }));

  return mappedData;
};
