import React from 'react';

import { useNavigate, useParams } from 'react-router-dom';

interface IIntegrationsProvider {
  pickIntegration: (newIntegration: string) => void;
}

const IntegrationsContext = React.createContext<IIntegrationsProvider | null>(null);

export const IntegrationsProvider: React.FC = ({ children }) => {
  const { accountId, checkoutId } = useParams();

  const navigate = useNavigate();

  const pickIntegration = React.useCallback(
    integrationName => {
      if (integrationName === 'profitfy') {
        window.open('https://profitfy.com', '_blank');
        return;
      }
      navigate(`/${accountId}/${checkoutId}/dashboard/apps/${integrationName}`);
    },
    [navigate, accountId, checkoutId],
  );

  return (
    <IntegrationsContext.Provider
      value={{
        pickIntegration,
      }}
    >
      {children}
    </IntegrationsContext.Provider>
  );
};

export const useIntegrations = (): IIntegrationsProvider => {
  const context = React.useContext(IntegrationsContext);

  if (!context) {
    throw new Error('useIntegrations must be used within IntegrationsProvider');
  }

  return context;
};
