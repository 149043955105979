import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';

import { useConfig } from '@store/context/common/ConfigContext';
import { useQuery } from '@hooks/common/useQuery';

const PublicOutlet: React.FC = () => {
  const { user } = useConfig();
  const query = useQuery();
  const membershipInviteToken = query?.get('membership_invite_token');

  const isAuthenticated = Boolean(user);
  const isInvitedUser = Boolean(membershipInviteToken);

  if (isAuthenticated && !isInvitedUser) {
    return <Navigate to="/loading" />;
  }

  return <Outlet />;
};

export default PublicOutlet;
