import React from 'react';
import { PlusCircle } from 'phosphor-react';
import { useTheme } from 'styled-components/macro';
import { useNavigate, useParams } from 'react-router-dom';

import { EButtonVariant } from '@domain/enums/components/common/EButton';

import PageHeader from '@components/common/utils/PageHeader';

import { useGetShipping } from '@store/common/shipping/GetShippingContext';

import * as S from './styles';

const Header: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { accountId, checkoutId } = useParams();
  const { totalShippings } = useGetShipping();

  const onNewShippingButtonClick = (): void => {
    navigate(`/${accountId}/${checkoutId}/dashboard/checkout/shipping/new`);
  };

  const quantityText = totalShippings === 1 ? '1 resultado' : `${totalShippings} resultados`;

  return (
    <S.Wrapper>
      <PageHeader
        title="Frete"
        description="Gerencia suas informações e valores de frete."
        quantityText={quantityText}
      />

      <S.NewShippingButton variant={EButtonVariant.SECONDARY} onClick={onNewShippingButtonClick}>
        <PlusCircle size={20} weight="bold" color={theme.colors.neutral.darker} /> Novo frete
      </S.NewShippingButton>
    </S.Wrapper>
  );
};

export default Header;
