import styled from 'styled-components/macro';

import ButtonBase from '@components/common/Inputs/Button';
import ModalBase from '@components/common/DataDisplay/Modal';
import TextBase from '@components/common/DataDisplay/Text';

export const Modal = styled(ModalBase)`
  background-color: ${({ theme }) => theme.colors.neutral.white};
`;

export const Text = styled(TextBase)`
  font-size: 2rem;
  text-align: justify;
`;

export const ModalAction = styled.div`
  display: flex;
  align-self: flex-end;
  gap: 16px;
  margin-top: 42px;
`;

export const ConfirmButton = styled(ButtonBase)`
  background-color: ${({ theme }) => theme.colors.negative.base};
  border: none;

  > span {
    color: ${({ theme }) => theme.colors.neutral.white};
  }
`;

export const CancelButton = styled(ButtonBase)`
  background-color: ${({ theme }) => theme.colors.neutral.white};
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  > span {
    color: ${({ theme }) => theme.colors.neutral.darker};
  }
`;
