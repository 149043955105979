import React from 'react';
import { CaretDown, MagnifyingGlass } from '@phosphor-icons/react';
import { useTheme } from 'styled-components/macro';

import { useDebounce } from '@hooks/common/useDebounce';
import { banks } from '@constants/common/banks';

import { useNewBankAccount } from '@store/common/BankAccount/NewBankAccountContext';
import * as S from './styles';

const BankSelect: React.FC = () => {
  const theme = useTheme();
  const { setValue, selectedBank, setSelectedBank } = useNewBankAccount();

  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [searchName, setSearchName] = React.useState<string>('');
  const [triggerWidth, setTriggerWidth] = React.useState<number>(0);

  const triggerRef = React.useRef<HTMLButtonElement>(null);

  const debouncedSearchName = useDebounce(searchName, 500);

  const onSearchName = React.useCallback(event => setSearchName(event.target.value), []);

  const onSelectedBank = React.useCallback(
    bank => {
      setSelectedBank(bank);
      setIsOpen(false);
      setValue('bankCode', bank.code);
    },
    [setValue, setSelectedBank],
  );

  const handleOpen = React.useCallback(state => {
    setIsOpen(state);
  }, []);

  React.useLayoutEffect(() => {
    if (!triggerRef.current) return;

    setTriggerWidth(triggerRef.current.offsetWidth);
  }, []);

  const filteredBanks = banks.filter(
    bank =>
      bank.name.toLowerCase().includes(debouncedSearchName.toLowerCase()) ||
      bank?.code?.toString().toLowerCase().includes(debouncedSearchName.toLowerCase()),
  );

  return (
    <S.Dropdown open={isOpen} onOpenChange={handleOpen}>
      <S.DropdownTrigger type="button" ref={triggerRef}>
        <S.TriggerContentWrapper>
          {selectedBank ? (
            <S.SelectBankName>
              {selectedBank.code
                ? `${selectedBank.code} - ${selectedBank.name}`
                : selectedBank.name}
            </S.SelectBankName>
          ) : (
            <S.SelectBankName>Selecione o seu banco</S.SelectBankName>
          )}
        </S.TriggerContentWrapper>

        <CaretDown size={20} weight="bold" color={theme.colors.neutral.dark} />
      </S.DropdownTrigger>

      <S.DropdownContent width={triggerWidth} sideOffset={10}>
        <S.SearchInput
          onChange={onSearchName}
          type="text"
          icon={MagnifyingGlass}
          placeholder="Pesquisar..."
        />

        <S.ScrollableContainer id="banks-list">
          {filteredBanks?.map((bank, index) => {
            return (
              <S.BankWrapper key={index.toFixed()} onClick={() => onSelectedBank(bank)}>
                <S.BankName>{bank.code ? `${bank.code} - ${bank.name}` : bank.name}</S.BankName>
              </S.BankWrapper>
            );
          })}
        </S.ScrollableContainer>
      </S.DropdownContent>
    </S.Dropdown>
  );
};

export default BankSelect;
