import styled from 'styled-components/macro';

import HeadingBase from '@components/common/DataDisplay/Heading';
import ButtonBase from '@components/common/Inputs/Button';
import TextBase from '@components/common/DataDisplay/Text';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  padding-bottom: 24px;
`;

export const Description = styled(TextBase)``;

export const NewShippingButton = styled(ButtonBase)`
  & > span {
    color: ${({ theme }) => theme.colors.neutral.darker};
  }
`;

export const Title = styled(HeadingBase)`
  display: flex;
  align-items: center;
  gap: 16px;
`;
