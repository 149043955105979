import React, { useState } from 'react'; // Importe o React

import { useForm } from 'react-hook-form';
import * as S from './styles';

interface IInputColor {
  change: (event: React.ChangeEvent<HTMLInputElement>) => void;
  defaultColor?: string;
}

const InputColor: React.FC<IInputColor> = ({ children, change, defaultColor }) => {
  const [selectedColor, setSelectedColor] = useState(defaultColor || '#f37414');
  const { register } = useForm(); // Cor inicial

  const handleColorChange = (event: any): any => {
    setSelectedColor(event.target.value);
    change(event);
  };

  return (
    <S.ValueInputWrapperCouple>
      <S.Label>{children}</S.Label>
      <S.ValueWrapperCouple>
        <S.ColorInput type="color" value={selectedColor} onChange={handleColorChange} />
        <S.Input type="text" value={selectedColor} onChange={change} />
      </S.ValueWrapperCouple>
    </S.ValueInputWrapperCouple>
  );
};

export default InputColor;
