import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { INewPixelProvider } from '@domain/interfaces/store/common/pixel/INewPixelProvider';

import { usePixelService } from '@hooks/services/pixel/usePixelService';
import { useToast } from '@store/context/common/ToastContext';
import { useErrorHandler } from '@store/context/common/ErrorHandlerContext';
import { useAnalytics } from '@store/context/common/AnalyticsContext';

import { getPixelSchemaValidation } from '@helpers/validators/pages/dashboard/pixel/pixelSchemaValidation';
import { ICreatePixelData } from '@domain/interfaces/hooks/services/pixel/IPixelService';

const NewPixelContext = React.createContext<INewPixelProvider | null>(null);

export const NewPixelProvider: React.FC = ({ children }) => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const { analytics } = useAnalytics();
  const { handleError } = useErrorHandler();
  const { createPixel } = usePixelService();
  const { accountId, checkoutId } = useParams();

  const [providerSelected, setProviderSelected] = React.useState<string>('');
  const [isCreatingPixel, setIsCreatingPixel] = React.useState<boolean>(false);

  const defaultValues =
    providerSelected === 'TABOOLA'
      ? { send_pix_purchase: false, send_boleto_purchase: false }
      : { send_credit_card_purchase: false, send_pix_purchase: false, send_boleto_purchase: false };

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(getPixelSchemaValidation(providerSelected)),
    defaultValues: { ...defaultValues },
  });
  const handleSelectedProvider = ({ providerName }: { providerName: string }): void => {
    document.dispatchEvent(new KeyboardEvent('keydown', { key: 'Escape' }));
    setProviderSelected(providerName);
    reset();
  };

  const createNewPixel = React.useCallback(
    async data => {
      setIsCreatingPixel(true);

      try {
        await createPixel({ ...data, accountId, checkoutId });

        analytics.track(' Pixel Created', { provider: providerSelected });

        setIsCreatingPixel(false);
        toast.success('Pixel criado com sucesso');
        navigate(`/${accountId}/${checkoutId}/dashboard/marketing/pixels`);
      } catch (error) {
        setIsCreatingPixel(false);
        handleError(error);
      }
    },
    [toast, createPixel, handleError, navigate, accountId, checkoutId, analytics, providerSelected],
  );

  const onSubmit = async (formData: FieldValues): Promise<void> => {
    const data: ICreatePixelData = {
      name: formData.pixelName.trim(),
      provider_id: formData.pixelId.trim(),
      send_boleto_purchase: formData.send_boleto_purchase,
      send_pix_purchase: formData.send_pix_purchase,
      send_credit_card_purchase: formData.send_credit_card_purchase,
      conversion_tag: formData.conversion_tag?.trim() || undefined,
      provider: providerSelected || '',
      credentials: {
        access_token: formData.accessToken?.trim(),
      },
    };

    await createNewPixel({ payload: data });
  };

  return (
    <NewPixelContext.Provider
      value={{
        providerSelected,
        handleSelectedProvider,
        createNewPixel,
        isCreatingPixel,
        register,
        control,
        handleSubmit,
        errors,
        onSubmit,
      }}
    >
      {children}
    </NewPixelContext.Provider>
  );
};

export const useNewPixel = (): INewPixelProvider => {
  const context = React.useContext(NewPixelContext);

  if (!context) {
    throw new Error('useNewPixel must be used within provider');
  }

  return context;
};
