import { AxiosResponse } from 'axios';

import {
  IGetProfileResponse,
  IProfileService,
  IUpdatePasswordProps,
  IUpdatePersonalDataProps,
  IUpdateProfileResponse,
} from '@domain/interfaces/hooks/services/profile/IProfileService';

import { dashboardInstance } from '@services/common/dashboardInstance';

export const useProfileService = (): IProfileService => {
  const getProfile = async (): Promise<AxiosResponse<IGetProfileResponse>> => {
    return dashboardInstance.get('accounts/:accountId/profile');
  };

  const updateProfilePersonalData = async ({
    profileId,
    payload,
  }: IUpdatePersonalDataProps): Promise<AxiosResponse<IUpdateProfileResponse>> => {
    return dashboardInstance.put(`accounts/:accountId/profile/personal-data/${profileId}`, {
      ...payload,
    });
  };

  const updateProfilePassword = async ({
    profileId,
    payload,
  }: IUpdatePasswordProps): Promise<AxiosResponse<IUpdateProfileResponse>> => {
    return dashboardInstance.put(`accounts/:accountId/profile/update-password/${profileId}`, {
      ...payload,
    });
  };

  return { getProfile, updateProfilePersonalData, updateProfilePassword };
};
