import React from 'react';

import { EHeadingWeight } from '@domain/enums/components/common/EHeading';

import { useGetGeneral } from '@store/common/general/GetGeneralContext';

import PageHeader from '@components/common/utils/PageHeader';

import * as S from './styles';

const Header: React.FC = () => {
  const { startDate, endDate, handleEndDate, handleStartDate, isLoadingGeneral } = useGetGeneral();

  const onChange = React.useCallback(
    dates => {
      const [start, end] = dates;

      handleStartDate(start);
      handleEndDate(end);
    },
    [handleStartDate, handleEndDate],
  );

  if (isLoadingGeneral) {
    return <S.SkeletonHeader height="35px" width="250px" highlightColor="#FFFFFF" />;
  }

  return (
    <S.Wrapper>
      <PageHeader title="Geral" titleWeight={EHeadingWeight.SEMI_BOLD} />

      <S.DatePicker
        isDisabledDaysAfterToday
        startDate={startDate}
        endDate={endDate}
        onChange={onChange}
      />
    </S.Wrapper>
  );
};

export default Header;
