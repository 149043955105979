import styled from 'styled-components/macro';

import ButtonBase from '@components/common/Inputs/Button';
import InputGroupBase from '@components/common/Inputs/InputGroup';
import LabelBase from '@components/common/DataDisplay/Label';
import ModalBase from '@components/common/DataDisplay/Modal';
import TextBase from '@components/common/DataDisplay/Text';
import TextFieldBase from '@components/common/Inputs/TextField';

export const Modal = styled(ModalBase)`
  background-color: ${({ theme }) => theme.colors.neutral.white};
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const Label = styled(LabelBase)`
  margin: 0;
`;

export const Input = styled(TextFieldBase)``;

export const MessageError = styled(TextBase)`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.negative.base};
`;

export const InputGroup = styled(InputGroupBase)`
  gap: ${({ theme }) => theme.spacing.small_8};
`;

export const Info = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${({ theme }) => theme.colors.neutral.dark};
  font-family: ${({ theme }) => theme.fonts.typography};
`;

export const MessageInfo = styled(TextBase)`
  font-size: 1.4rem;
`;

export const ModalAction = styled.div`
  display: flex;
  align-self: flex-end;
  gap: 16px;
  margin-top: 42px;
`;

export const SaveButton = styled(ButtonBase)``;

export const CancelButton = styled(ButtonBase)`
  background-color: ${({ theme }) => theme.colors.neutral.white};
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  > span {
    color: ${({ theme }) => theme.colors.neutral.darker};
  }
`;
