import { AxiosResponse } from 'axios';

import { IShopify } from '@domain/interfaces/common/shopify/IShopify';
import {
  IActivateShopifyCredentialProps,
  ICreateShopifyCredentialProps,
  IDeleteShopifyCredentialProps,
  IGetShopifyCredentialProps,
  IInactivateShopifyCredentialProps,
  IReinstallShopifyCredentialProps,
  IShopifyCredentialService,
  ISyncShopifyProductsProps,
  IUpdateShopifyCredentialProps,
} from '@domain/interfaces/hooks/services/shopify/IShopifyService';

import { dashboardInstance } from '@services/common/dashboardInstance';

export const useShopifyService = (): IShopifyCredentialService => {
  const getShopifyCredential = async ({
    accountId,
    checkoutId,
  }: IGetShopifyCredentialProps): Promise<AxiosResponse<IShopify>> => {
    return dashboardInstance.get(
      `accounts/${accountId}/checkouts/${checkoutId}/shopify/credentials`,
      {
        headers: {
          'x-zouti-account': accountId,
        },
      },
    );
  };

  const createShopifyCredential = async ({
    accountId,
    checkoutId,
    payload,
  }: ICreateShopifyCredentialProps): Promise<AxiosResponse<IShopify>> => {
    return dashboardInstance.post(
      `accounts/${accountId}/checkouts/${checkoutId}/shopify/credentials`,
      { ...payload },
      {
        headers: {
          'x-zouti-account': accountId,
        },
      },
    );
  };

  const updateShopifyCredential = async ({
    accountId,
    checkoutId,
    shopifyCredentialId,
    payload,
  }: IUpdateShopifyCredentialProps): Promise<AxiosResponse<IShopify>> => {
    return dashboardInstance.put(
      `accounts/${accountId}/checkouts/${checkoutId}/shopify/credentials/${shopifyCredentialId}`,
      { ...payload },
      {
        headers: {
          'x-zouti-account': accountId,
        },
      },
    );
  };

  const activateShopifyCredential = async ({
    accountId,
    checkoutId,
    shopifyCredentialId,
  }: IActivateShopifyCredentialProps): Promise<AxiosResponse<void>> => {
    return dashboardInstance.put(
      `accounts/${accountId}/checkouts/${checkoutId}/shopify/credentials/${shopifyCredentialId}/activate`,
      {},
      {
        headers: {
          'x-zouti-account': accountId,
        },
      },
    );
  };

  const inactivateShopifyCredential = async ({
    accountId,
    checkoutId,
    shopifyCredentialId,
  }: IInactivateShopifyCredentialProps): Promise<AxiosResponse<void>> => {
    return dashboardInstance.put(
      `accounts/${accountId}/checkouts/${checkoutId}/shopify/credentials/${shopifyCredentialId}/inactivate`,
      {},
      {
        headers: {
          'x-zouti-account': accountId,
        },
      },
    );
  };

  const reinstallShopifyCredential = async ({
    accountId,
    checkoutId,
    shopifyCredentialId,
  }: IReinstallShopifyCredentialProps): Promise<AxiosResponse<void>> => {
    return dashboardInstance.post(
      `accounts/${accountId}/checkouts/${checkoutId}/shopify/credentials/${shopifyCredentialId}/reinstall_checkout`,
      {},
      {
        headers: {
          'x-zouti-account': accountId,
        },
      },
    );
  };

  const deleteShopifyCredential = async ({
    accountId,
    checkoutId,
    shopifyCredentialId,
  }: IDeleteShopifyCredentialProps): Promise<AxiosResponse<void>> => {
    return dashboardInstance.delete(
      `accounts/${accountId}/checkouts/${checkoutId}/shopify/credentials/${shopifyCredentialId}/activate`,
    );
  };

  const syncShopifyProducts = async ({
    accountId,
    checkoutId,
  }: ISyncShopifyProductsProps): Promise<AxiosResponse<void>> => {
    return dashboardInstance.post(
      `accounts/${accountId}/checkouts/${checkoutId}/shopify/sync_products`,
      {
        headers: {
          'x-zouti-account': accountId,
        },
      },
    );
  };

  return {
    createShopifyCredential,
    getShopifyCredential,
    syncShopifyProducts,
    updateShopifyCredential,
    activateShopifyCredential,
    inactivateShopifyCredential,
    reinstallShopifyCredential,
    deleteShopifyCredential,
  };
};
