import React from 'react';
import { useNavigate } from 'react-router-dom';

import logo from '@assets/images/common/logo.svg';

import { EHeadingSize } from '@domain/enums/common/components/EHeading';

import * as S from './styles';

const SuccessWrapper: React.FC = () => {
  const navigate = useNavigate();

  return (
    <S.Wrapper>
      <S.Logo src={logo} alt="Zouti" />

      <S.Title size={EHeadingSize.H5}>
        <b>E-mail enviado com sucesso!</b>
      </S.Title>
      <S.Text>Confira sua caixa de entrada ou spam para garantir que recebeu as instruções.</S.Text>

      <S.Body>
        <S.Button type="button" onClick={() => navigate('/')}>
          Ir para o login
        </S.Button>
      </S.Body>
    </S.Wrapper>
  );
};

export default SuccessWrapper;
