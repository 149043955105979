import React from 'react';

import * as TooltipPrimitive from '@radix-ui/react-tooltip';

import * as S from './styles';

const Arrow: React.FC<TooltipPrimitive.TooltipArrowProps & React.RefAttributes<SVGSVGElement>> = ({
  children,
  ...rest
}) => {
  return <S.TooltipArrow {...rest}>{children}</S.TooltipArrow>;
};

export default Arrow;
