import React from 'react';

import { PaymentCredentialProvider } from '@store/common/paymentCredential/PaymentCredentialContext';
import Content from '@components/pages/Dashboard/PaymentCredential';

const PaymentCredential: React.FC = () => {
  return (
    <PaymentCredentialProvider>
      <Content />
    </PaymentCredentialProvider>
  );
};

export default PaymentCredential;
