import React from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { useListProducts } from '@store/common/product/ListProductsContext';

import EmptyData from '@components/common/utils/EmptyData';
import BottomPagination from '@components/common/utils/BottomPagination';
import ListSkeletonLoading from '@components/common/utils/ListSkeletonLoading';
import Header from './Header';
import Body from './Body';

import * as S from './styles';

const Products: React.FC = () => {
  const navigate = useNavigate();

  const { accountId, checkoutId } = useParams();

  const {
    isLoadingProducts,
    isProductError,
    products,
    totalProducts,
    totalPages,
    handlePageChange,
    handlePageLimit,
    pageLimit,
    page,
    searchName,
  } = useListProducts();

  const isEmpty = products.length === 0;

  const onEmptyDataClick = React.useCallback(() => {
    navigate(`/${accountId}/${checkoutId}/dashboard/apps`);
  }, [accountId, checkoutId, navigate]);

  if (isLoadingProducts) {
    return <ListSkeletonLoading />;
  }

  if (isProductError && !isLoadingProducts) {
    return <div>Error...</div>;
  }

  if (isEmpty && !searchName) {
    return (
      <EmptyData
        title="Nenhum produto encontrado"
        description="Para visualizar seus produtos primeiro adicione sua loja à Zouti."
        buttonText="Adicionar loja"
        onClick={onEmptyDataClick}
      />
    );
  }

  return (
    <S.Wrapper>
      <Header products={products} />

      {!isEmpty && <Body />}
      {isEmpty && (
        <S.NotFoundProductSearch>
          <EmptyData
            title="Nenhum produto encontrado"
            description="Não encontramos produtos com esse nome. Tente outro termo."
          />
        </S.NotFoundProductSearch>
      )}

      <BottomPagination
        totalItems={totalProducts}
        totalPages={totalPages}
        handlePageChange={handlePageChange}
        handlePageLimit={handlePageLimit}
        pageLimit={pageLimit}
        page={page}
      />
    </S.Wrapper>
  );
};

export default Products;
