import React from 'react';
import useSWR from 'swr';

import { ISwr } from '@domain/interfaces/common/swr/ISwr';
import { IAccountStep, IFirstStep, ISecondStep } from '@domain/interfaces/common/home/IAccountStep';
import { IGetAccountStepProvider } from '@domain/interfaces/store/common/home/IGetAccountStepProvider';

import { DASHBOARD_API_URL } from '@constants/services/apiUrl';
import { dashboardInstance } from '@services/common/dashboardInstance';

import { useParams } from 'react-router-dom';
import { useErrorHandler } from '@store/context/common/ErrorHandlerContext';

interface IStepProps {
  object: {
    onboarding_steps: {
      FREIGHTS_CONFIGURED_AT?: string;
      COMPANY_DATA_ADDED_AT?: string;
      ORDER_BUMP_CREATED_AT?: string;
      CHECKOUT_ENABLED_AT?: string;
      PAYMENT_INTEGRATED_AT?: string;
      STORE_CONNECTED_AT?: string;
      EMAIL_CONFIGURED_AT?: string;
      PIXEL_CONFIGURED_AT?: string;
      DOMAIN_CONNECTED_AT?: string;
      UPSELL_CREATED_AT?: string;
      CHECKOUT_CUSTOMIZED_AT?: string;
      DISCOUNT_CODES_CREATED_AT?: string;
      FIRST_SALE_AT?: string;
    };
    qtd_first_steps_completed: number;
  };
}

const GetAccountStepContext = React.createContext<IGetAccountStepProvider | null>(null);

export const GetAccountStepProvider: React.FC = ({ children }) => {
  const { accountId, checkoutId } = useParams();
  const { handleError } = useErrorHandler();
  const [accountStep, setAccountStep] = React.useState<IAccountStep>({} as IAccountStep);
  const [hoveredIndex, setHoveredIndex] = React.useState<number | null>(null);
  const [isOpenModal, setIsOpenModal] = React.useState({
    activeCheckout: false,
    congratulation: false,
  });

  dashboardInstance.defaults.headers.common['x-zouti-account'] = accountId;

  const ACCOUNT_STEP_URL = `${DASHBOARD_API_URL}/accounts/${accountId}/checkouts/${checkoutId}/home`;

  const { data, isLoading, isValidating, error, mutate } = useSWR<ISwr<IStepProps>>(
    ACCOUNT_STEP_URL,
    dashboardInstance,
  );

  const firstStep: (keyof IAccountStep)[] = [
    'STORE_CONNECTED_AT',
    // 'PAYMENT_INTEGRATED_AT',
    'FREIGHTS_CONFIGURED_AT',
    'CHECKOUT_ENABLED_AT',
    // 'FIRST_SALE_AT',
  ];

  const secondStep: (keyof IAccountStep)[] = [
    'PIXEL_CONFIGURED_AT',
    'EMAIL_CONFIGURED_AT',
    'DOMAIN_CONNECTED_AT',
    'COMPANY_DATA_ADDED_AT',
    'CHECKOUT_CUSTOMIZED_AT',
    'ORDER_BUMP_CREATED_AT',
    'UPSELL_CREATED_AT',
    'DISCOUNT_CODES_CREATED_AT',
  ];
  const propertiesFirstStep: IFirstStep = {
    STORE_CONNECTED_AT: accountStep?.STORE_CONNECTED_AT,
    PAYMENT_INTEGRATED_AT: accountStep?.PAYMENT_INTEGRATED_AT,
    FREIGHTS_CONFIGURED_AT: accountStep?.FREIGHTS_CONFIGURED_AT,
    CHECKOUT_ENABLED_AT: accountStep?.CHECKOUT_ENABLED_AT,
    // FIRST_SALE_AT: accountStep?.FIRST_SALE_AT,
  };

  const propertiesSecondStep: ISecondStep = {
    PIXEL_CONFIGURED_AT: accountStep?.PIXEL_CONFIGURED_AT,
    EMAIL_CONFIGURED_AT: accountStep?.EMAIL_CONFIGURED_AT,
    DOMAIN_CONNECTED_AT: accountStep?.DOMAIN_CONNECTED_AT,
    COMPANY_DATA_ADDED_AT: accountStep?.COMPANY_DATA_ADDED_AT,
    CHECKOUT_CUSTOMIZED_AT: accountStep?.CHECKOUT_CUSTOMIZED_AT,
    ORDER_BUMP_CREATED_AT: accountStep?.ORDER_BUMP_CREATED_AT,
    UPSELL_CREATED_AT: accountStep?.UPSELL_CREATED_AT,
    DISCOUNT_CODES_CREATED_AT: accountStep?.DISCOUNT_CODES_CREATED_AT,
  };

  const isFirstStepDone = firstStep.every(item => accountStep[item]);
  const isSecondStepDone = secondStep.every(item => accountStep[item]);

  React.useEffect(() => {
    if (error) {
      handleError(error);
    }

    if (data?.data) {
      setAccountStep(data.data.object.onboarding_steps);
    }
  }, [data, handleError, error]);

  const isLoadingAccountStep = isLoading || isValidating;
  const isAccountStepError = Boolean(error);
  const isConnectedStore = Object.prototype.hasOwnProperty.call(accountStep, 'STORE_CONNECTED_AT');
  const countFirstStep = Object.keys(propertiesFirstStep).filter(
    key => propertiesFirstStep[key],
  ).length;
  const countSecondStep = Object.values(propertiesSecondStep).filter(value => value).length;
  const isEnabledCheckoutRow =
    propertiesFirstStep.STORE_CONNECTED_AT !== undefined &&
    propertiesFirstStep.FREIGHTS_CONFIGURED_AT !== undefined;

  return (
    <GetAccountStepContext.Provider
      value={{
        isLoadingAccountStep,
        isEnabledCheckoutRow,
        isAccountStepError,
        isFirstStepDone,
        isSecondStepDone,
        accountStep,
        propertiesFirstStep,
        propertiesSecondStep,
        isConnectedStore,
        countFirstStep,
        countSecondStep,
        hoveredIndex,
        setHoveredIndex,
        mutate,
        isOpenModal,
        setIsOpenModal,
      }}
    >
      {children}
    </GetAccountStepContext.Provider>
  );
};

export const useGetAccountStep = (): IGetAccountStepProvider => {
  const context = React.useContext(GetAccountStepContext);

  if (!context) {
    throw new Error('useGetAccountStep must be used within provider');
  }

  return context;
};
