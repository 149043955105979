import LabelBase from '@components/common/DataDisplay/Label';
import DropdownBase from '@components/common/utils/Dropdown';
// eslint-disable-next-line import/no-extraneous-dependencies
import Select from 'react-select';
import styled from 'styled-components/macro';

import selectArrow from '@assets/images/components/select/CaretDown.svg';
import { TextBase } from '@components/common/DataDisplay/Text/styles';
import { ESearchInputSize } from '@domain/enums/components/common/ESearchInput';

export interface IPlatformSelectProps {
  isDropdownOpen: boolean;
}

interface IInputWrapperProps {
  disabled?: boolean;
  size?: ESearchInputSize;
  value?: string;
  isFocused?: boolean;
}

export const DivImage = styled.div`
  display: flex;
  height: 40px;
`;

export const ImageFile = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 60px;
  background-color: white;
`;

export const ImageSelect = styled.img`
  height: 27px;
  width: 30px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  border-radius: 4px;
`;

export const SelectedImage = styled(Select)`
  width: 100%;
  height: 100%;

  font-family: Inter;
`;

export const FieldSelect = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

export const SpanImage = styled.span`
  width: 80%;
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 7px;
  font-size: 1.4rem;
  font-family: ${({ theme }) => theme.fonts.text};
  #font-weight: 700;
`;

export const ColorInput = styled.input`
  width: 50px;
  height: 100%;
  font-size: 1.4rem;
  font-family: ${({ theme }) => theme.fonts.text};
  font-weight: 700;
  color: ${({ theme }) => theme.colors.neutral.darkest};
  background: white;
  border-radius: 4px;
  padding: 2.5px;
  border: 1px solid #ccc;
  display: inline-block;
`;

export const ValueWrapperCouple = styled.div`
  display: flex;
  align-content: center;
  #border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  width: 100%;
  height: 100%;
  border-radius: 4px;

  & > div:nth-child(2) {
    border: unset;
    width: 100%;
    border-radius: unset;
    border-left: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
    border-right: 1px solid ${({ theme }) => theme.colors.neutral.lightest};

    & > input {
      width: 100%;
      text-align: left;
      height: 100%;
      margin-left: 10px;
    }
  }
`;

export const Label = styled(LabelBase)``;

export const Input = styled.input`
  height: 100%;
  width: 100%;
  color: ${({ theme }) => theme.colors.neutral.darker};
  font-family: 'Inter';
  font-weight: 400;
  font-size: 16px;
  padding-left: 20px;
  margin-left: 10px;
  border-left: 1px solid black;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  border-radius: 4px;

  &:active {
    outline: none;
  }

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.neutral.base};
    font-family: 'Inter';
    font-weight: 400;
    font-size: 14px;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.colors.neutral.lightest};
  }
`;

export const DropdownContent = styled(DropdownBase.Content)`
  padding: 8px 0;
  min-width: 482px;
  max-height: 240px;
  overflow-y: scroll;
  z-index: 999999999;
`;

export const DropdownTrigger = styled(DropdownBase.Trigger)<IPlatformSelectProps>`
  display: flex;
  align-items: center;
  gap: 8px;
  height: 40px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  padding: 8px 16px;
  position: relative;
  text-align: left;
  font-size: 1.4rem;
  transition: all 0.3s;
  width: 100%;
  cursor: pointer;
  margin-right: 5px;

  color: ${({ theme }) => theme.colors.neutral.base};

  &:after {
    content: url(${selectArrow});
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: ${({ theme }) => theme.spacing.small_16};
  }
`;

export const PlatformIcon = styled.img`
  width: 24px;
  height: 24px;
`;

export const PlatformName = styled(TextBase)`
  font-size: 1.4rem;
`;

export const Dropdown = styled(DropdownBase)`
  width: 100%;
`;
