import { AxiosResponse } from 'axios';

import {
  IActivateGTMProps,
  IActiveGTMResponse,
  ICreateGTMCredentialResponse,
  ICreateGTMProps,
  IGTMService,
  IInactivateGTMProps,
  IInactivateGTMResponse,
  IUpdateGTMProps,
} from '@domain/interfaces/hooks/services/GTM/IGTMService';

import { dashboardInstance } from '@services/common/dashboardInstance';

export const useGTMService = (): IGTMService => {
  const createGTM = async ({
    accountId,
    checkoutId,
    payload,
  }: ICreateGTMProps): Promise<AxiosResponse<ICreateGTMCredentialResponse>> => {
    return dashboardInstance.post(
      `/accounts/${accountId}/checkouts/${checkoutId}/integrations/gtm`,
      { ...payload },
    );
  };

  const updateGTM = async ({
    accountId,
    checkoutId,
    gtmId,
    payload,
  }: IUpdateGTMProps): Promise<AxiosResponse<any>> => {
    return dashboardInstance.put(
      `/accounts/${accountId}/checkouts/${checkoutId}/integrations/gtm/${gtmId}`,
      {
        ...payload,
      },
    );
  };

  const activateGTM = async ({
    accountId,
    checkoutId,
    gtmId,
  }: IActivateGTMProps): Promise<AxiosResponse<IActiveGTMResponse>> => {
    return dashboardInstance.put(
      `accounts/${accountId}/checkouts/${checkoutId}/integrations/gtm/${gtmId}/activate`,
    );
  };

  const inactivateGTM = async ({
    accountId,
    checkoutId,
    gtmId,
  }: IInactivateGTMProps): Promise<AxiosResponse<IInactivateGTMResponse>> => {
    return dashboardInstance.put(
      `accounts/${accountId}/checkouts/${checkoutId}/integrations/gtm/${gtmId}/inactivate`,
    );
  };

  return { createGTM, updateGTM, activateGTM, inactivateGTM };
};
