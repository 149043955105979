import { AxiosResponse } from 'axios';

import {
  IPaymentService,
  IRefundPaymentProps,
} from '@domain/interfaces/hooks/services/payment/IPaymentService';

import { dashboardInstance } from '@services/common/dashboardInstance';

export const usePaymentService = (): IPaymentService => {
  const refundPayment = async ({
    payload,
    accountId,
  }: IRefundPaymentProps): Promise<AxiosResponse<void>> => {
    return dashboardInstance.post(
      `accounts/${accountId}/refunds`,
      { ...payload },
      { headers: { 'x-zouti-account': accountId } },
    );
  };

  return {
    refundPayment,
  };
};
