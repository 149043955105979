import styled from 'styled-components/macro';

export const PaginateWrapper = styled.div`
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;

  ul {
    margin: 0;
    list-style: none;
    padding: 0;
    display: flex;

    li {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      cursor: pointer;
      transition: all 0.3s;

      a {
        font-family: ${({ theme }) => theme.fonts.typography};
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${({ theme }) => theme.colors.neutral.darker};
        font-weight: bold;
        font-size: 14px;
        transition: all 0.3s;
      }

      &:hover {
        a {
          color: ${({ theme }) => theme.colors.primary.dark_green_60};
          background-color: ${({ theme }) => theme.colors.primary.lemon_20};
          border-radius: 50%;
        }
      }

      &:not(:last-child) {
        margin-right: 8px;
      }
    }

    .active {
      background-color: ${({ theme }) => theme.colors.primary.lemon_20};

      a {
        color: ${({ theme }) => theme.colors.primary.dark_green_60};
      }
    }

    .pagination-item {
      border: none;

      a {
        letter-spacing: 2px;
        font-size: 20px;
      }
    }
  }
`;

export const NextButton = styled.button`
  background: unset;
  border: unset;
  padding: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
  cursor: pointer;
  width: 32px;
  height: 32px;

  &:hover {
    color: ${({ theme }) => theme.colors.primary.dark_green_60};
    background-color: ${({ theme }) => theme.colors.primary.lemon_20};
    border-radius: 50%;
  }
`;

export const PreviousButton = styled.button`
  background: unset;
  border: unset;
  padding: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
  cursor: pointer;
  width: 32px;
  height: 32px;

  &:hover {
    color: ${({ theme }) => theme.colors.primary.dark_green_60};
    background-color: ${({ theme }) => theme.colors.primary.lemon_20};
    border-radius: 50%;
  }
`;
