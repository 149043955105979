import { AxiosResponse } from 'axios';

import {
  IInviteUserProps,
  IUsersService,
  IDeleteUserInvitedProps,
  IDeleteUserInvitedResponse,
} from '@domain/interfaces/hooks/services/users/IUsersService';

import { dashboardInstance } from '@services/common/dashboardInstance';

export const useUsersService = (): IUsersService => {
  const inviteUsers = async ({
    accountId,
    membershipGroupId,
    payload,
  }: IInviteUserProps): Promise<AxiosResponse<any>> => {
    return dashboardInstance.post(
      `accounts/${accountId}/membership_groups/${membershipGroupId}/invite_user`,
      {
        ...payload,
      },
    );
  };

  const deleteUserInvited = async ({
    membershipId,
    accountId,
  }: IDeleteUserInvitedProps): Promise<AxiosResponse<IDeleteUserInvitedResponse>> => {
    return dashboardInstance.delete(`accounts/${accountId}/memberships/${membershipId}`);
  };

  return {
    inviteUsers,
    deleteUserInvited,
  };
};
