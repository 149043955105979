import React from 'react';

import Content from '@components/pages/Dashboard/AbandonedCart/DetailAbandonedCart';
import { DetailAbandonedCartProvider } from '@store/common/AbandonedCart/DetailAbandonedCartContext';

const DetailAbandonedCart: React.FC = () => {
  return (
    <DetailAbandonedCartProvider>
      <Content />
    </DetailAbandonedCartProvider>
  );
};

export default DetailAbandonedCart;
