import styled from 'styled-components/macro';

import HeadingBase from '@components/common/DataDisplay/Heading';
import TextBase from '@components/common/DataDisplay/Text';
import ChipBase from '@components/common/DataDisplay/Chip';

export const CopyButton = styled.button`
  padding: unset;
  background-color: unset;
  border: unset;
  cursor: pointer;
`;

export const NoTrackingNumberValue = styled(TextBase)`
  color: ${({ theme }) => theme.colors.neutral.base};
`;

export const Chip = styled(ChipBase)`
  h1 {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

export const TrackingValue = styled(TextBase)`
  max-width: 232px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.neutral.darkest};
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Value = styled(TextBase)`
  max-width: 232px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.neutral.darkest};
  font-size: 1.4rem;
`;

export const Label = styled(TextBase)`
  color: ${({ theme }) => theme.colors.neutral.dark};
  font-size: 1.4rem;
`;

export const LabelAndValue = styled.div`
  width: 232px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const DataWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

export const EditButton = styled.button`
  background-color: unset;
  padding: 8px 16px;
  border: unset;
  border-radius: 4px;
  font-family: ${({ theme }) => theme.fonts.text};
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.primary.dark_green_40};
  background-color: ${({ theme }) => theme.colors.primary.lemon_10};
  cursor: pointer;
`;

export const Title = styled(HeadingBase)`
  color: ${({ theme }) => theme.colors.neutral.black};
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const Wrapper = styled.div`
  padding: 24px;
  box-sizing: border-box;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.neutral.white};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
`;
