import React, { createContext, useCallback, useContext } from 'react';
import { IErrorHandlerContext } from '@domain/interfaces/store/context/IErrorHandlerContext';

import { useToast } from '@store/context/common/ToastContext';
import { useConfig } from '@store/context/common/ConfigContext';

const ErrorHandlerContext = createContext<IErrorHandlerContext | null>(null);

export const ErrorHandlerProvider: React.FC = ({ children }) => {
  const { toast } = useToast();
  const { handleLogout } = useConfig();

  const handleError = useCallback(
    (err: any): void => {
      if (err?.response?.data?.status_code === 401) {
        handleLogout();
      }

      if (err?.response?.data?.message) {
        toast.error(err?.response?.data?.message);
        return;
      }

      toast.error('Ocorreu um erro inesperado!');
    },

    [toast, handleLogout],
  );

  return (
    <ErrorHandlerContext.Provider value={{ handleError }}>{children}</ErrorHandlerContext.Provider>
  );
};

export const useErrorHandler = (): IErrorHandlerContext => {
  const context = useContext(ErrorHandlerContext);
  if (!context) {
    throw new Error('useErrorHandler must be used within an ErrorHandlerProvider');
  }
  return context;
};
