import React from 'react';
import GA4Icon from '@assets/images/common/dashboard/integrations/ga4.svg';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/common/EHeading';
import { useGA4Context } from '@store/common/GA4/GA4Context';
import { useAnalytics } from '@store/context/common/AnalyticsContext';

import SaveForm from '../SaveForm';

import * as S from './styles';

const Body: React.FC = () => {
  const { analytics } = useAnalytics();
  const {
    errors,
    handleSubmit,
    onSubmit,
    watchTrackingId,
    trackingIdRegister,
    optimizationCodeRegister,
    onActivateGA4,
    onInactivateGA4,
    ga4Credential,
    watch,
  } = useGA4Context();

  const [trackingIdValue] = watch(['tracking_id']);

  const [showSaveForm, setShowSaveForm] = React.useState<boolean>(false);
  const [isActivated, setIsActivated] = React.useState<boolean>(false);

  const handleStatusChange = React.useCallback(
    async event => {
      setIsActivated(event.target.checked);

      if (event.target.checked) {
        await onActivateGA4();
      } else {
        await onInactivateGA4();
      }
    },
    [onInactivateGA4, onActivateGA4],
  );

  const onIdChange = React.useCallback(
    event => {
      const idValue = event.target.value;

      const parsedIdValue = idValue.replace(/^(?!G-)/, 'G-').replace(/\s+/g, '');

      event.target.value = parsedIdValue;

      trackingIdRegister.onChange(event);
    },
    [trackingIdRegister],
  );

  React.useEffect(() => {
    if (watchTrackingId && !ga4Credential) {
      setShowSaveForm(true);
      return;
    }

    if (!ga4Credential) return;

    if (ga4Credential.tracking_id !== trackingIdValue) {
      setShowSaveForm(true);
    }
  }, [watchTrackingId, ga4Credential, trackingIdValue]);

  React.useEffect(() => {
    if (watchTrackingId) {
      setShowSaveForm(true);
    }
  }, [watchTrackingId]);

  React.useEffect(() => {
    if (ga4Credential) {
      setIsActivated(Boolean(!ga4Credential.inactivated_at));
    }
  }, [ga4Credential]);

  React.useEffect(() => {
    analytics.track('GA4 Credential Page Viewed');
  }, [analytics]);

  return (
    <S.Wrapper>
      <S.AdditionalInformationWrapper>
        <S.AdditionalInformationContent>
          <S.AdditionalInformationHeader backgroundColor="#F9AB00">
            <S.TextBanner>Google Analytics</S.TextBanner>
            <S.IconWrapper>
              <S.Icon src={GA4Icon} alt="Integration image" />
            </S.IconWrapper>
          </S.AdditionalInformationHeader>

          <S.AdditionalInformationBody>
            <S.TitleAndSwitchWrapper>
              <S.Title size={EHeadingSize.H6} fontWeight={EHeadingWeight.SEMI_BOLD}>
                Google Analytics 4
              </S.Title>

              {ga4Credential && (
                <S.Switch
                  id="install-ga4"
                  name="install-ga4"
                  checked={isActivated}
                  onChange={handleStatusChange}
                />
              )}
            </S.TitleAndSwitchWrapper>

            <S.Description>
              Analise o comportamento dos usuários em seu site e aplicativo para melhorar sua
              estratégia de marketing, conteúdo e produtos.
            </S.Description>
          </S.AdditionalInformationBody>
        </S.AdditionalInformationContent>

        {/* <Tutorial
          image_url={tutorialImage}
          title="Integração com o Google Analytics 4: Tutorial Rápido"
          video_url="https://www.youtube.com/embed/f5_wn8mexmM?si=Ud_rqwSSYa_nU4az"
        /> */}
      </S.AdditionalInformationWrapper>

      <S.Form onSubmit={handleSubmit(onSubmit)}>
        <S.CredentialsWrapper>
          <S.CredentialsTitle>Credenciais</S.CredentialsTitle>

          <S.InputGroup>
            <S.Label>ID de rastreamento</S.Label>
            <S.Input
              {...trackingIdRegister}
              isError={Boolean(errors.tracking_id)}
              type="text"
              placeholder="G-XXXXXXXXXX"
              onChange={onIdChange}
            />

            {errors.tracking_id && (
              <S.MessageError isFeedbackError>{errors.tracking_id.message}</S.MessageError>
            )}
          </S.InputGroup>

          {/* <S.InputGroup>
            <S.Label>Código Google Optimize (opcional)</S.Label>

            <S.Input
              {...optimizationCodeRegister}
              isError={Boolean(errors.optimization_code)}
              type="text"
              placeholder="XXXXXXXXXXXXXX"
            />

            {errors.optimization_code && (
              <S.MessageError isFeedbackError>{errors.optimization_code.message}</S.MessageError>
            )}
          </S.InputGroup> */}
        </S.CredentialsWrapper>

        {showSaveForm && <SaveForm />}
      </S.Form>
    </S.Wrapper>
  );
};

export default Body;
