import React from 'react';

import { EHeadingSize } from '@domain/enums/components/common/EHeading';
import { EHeadingWeight } from '@domain/enums/common/components/EHeading';

import { useGetOrder } from '@store/common/order/GetOrder';
import { useToast } from '@store/context/common/ToastContext';

import { ELanguage } from '@domain/enums/common/order/ELanguage';
import { countryFlag, countryName } from '@utils/pages/dashboard/order/mappingCountries';

import * as S from './styles';

const CustomerCard: React.FC = () => {
  const { toast } = useToast();
  const { order } = useGetOrder();
  const dropdownRef = React.useRef<HTMLDivElement>(null);

  const [isDropdownVisible, setDropdownVisible] = React.useState(false);

  const copyTextToClipboard = async (text: string): Promise<void> => {
    try {
      await navigator.clipboard.writeText(text);
      toast.success('Copiado com sucesso!');
    } catch (err) {
      toast.error('Erro ao copiar!');
    }
  };

  const customerName = order?.customer?.name;
  const customerEmail = order?.customer?.email;
  const customerOrigin = order?.language;

  const handleDocumentClick = (event: MouseEvent): void => {
    if (dropdownRef.current && !dropdownRef.current?.contains(event.target as Node)) {
      setDropdownVisible(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener('click', handleDocumentClick);
  }, []);

  const CountryFlag = (props: { locale: ELanguage }): any => {
    const country = countryFlag[props?.locale];
    return <S.FlagCountryImage src={`https://flagcdn.com/${country}.svg`} alt="Country Flag" />;
  };

  return (
    <S.Wrapper>
      <S.Title size={EHeadingSize.H5} fontWeight={EHeadingWeight.BOLD}>
        Cliente
      </S.Title>

      <S.Divider />

      <S.WrapperInfos>
        <S.LabelAndValue>
          <S.Label>Nome</S.Label>
          <S.Value>{customerName}</S.Value>
        </S.LabelAndValue>

        <S.WrapperDropdownAndEmail>
          <S.LabelAndValue onMouseEnter={() => setDropdownVisible(true)}>
            <S.Label>E-mail</S.Label>
            <S.Value>{customerEmail}</S.Value>
          </S.LabelAndValue>
          {isDropdownVisible && (
            <S.DropdownCard ref={dropdownRef}>
              {customerEmail}
              <S.CopyIcon onClick={() => copyTextToClipboard(customerEmail || '')} />
            </S.DropdownCard>
          )}
        </S.WrapperDropdownAndEmail>

        {/* <S.LabelAndValue>
          <S.Label>Origem</S.Label>
          <S.CustomerOriginAndFlagWrapper>
            <S.Value>{countryName[customerOrigin as ELanguage]}</S.Value>
            <CountryFlag locale={customerOrigin as ELanguage} />
          </S.CustomerOriginAndFlagWrapper>
        </S.LabelAndValue> */}
      </S.WrapperInfos>
    </S.Wrapper>
  );
};

export default CustomerCard;
