import styled from 'styled-components';

import TextBase from '@components/common/DataDisplay/Text';

export const Text = styled(TextBase)`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.neutral.white};
`;

export const Wrapper = styled.div`
  padding: 16px 16px;
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.negative.base};
`;
