import styled from 'styled-components/macro';

import HeadingBase from '@components/common/DataDisplay/Heading';
import RadioBase from '@components/common/Inputs/Radio';
import LabelBase from '@components/common/DataDisplay/Label';
import TextBase from '@components/common/DataDisplay/Text';
import ButtonBase from '@components/common/Inputs/Button';

export const SubmitButton = styled(ButtonBase)`
  width: 100%;
`;

export const Text = styled(TextBase)`
  font-weight: 500;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.neutral.darker};
`;

export const RadioOption = styled(RadioBase)``;

export const Option = styled(LabelBase)`
  height: 40px;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: unset;
  transition: all 0.3s;

  &:hover {
    border-color: ${({ theme }) => theme.colors.primary.green_lemon};
  }
`;

export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 24px;
`;

export const QuestionText = styled(HeadingBase)`
  display: inline-block;
  margin-bottom: 24px;
`;

export const Wrapper = styled.form`
  margin-top: 32px;
`;
