import {
  ChartBar,
  ShoppingCartSimple,
  Tag,
  Target,
  Devices,
  SquaresFour,
  Gear,
} from '@phosphor-icons/react';

export const accordions = [
  {
    icon: ChartBar,
    title: 'Relatórios',
    content: [{ text: 'Todos os relatórios', key: 'report' }],
  },
  {
    icon: ShoppingCartSimple,
    title: 'Pedidos',
    content: [
      { text: 'Todos os pedidos', key: 'order' },
      { text: 'Carrinhos abandonados', key: 'abandoned_cart' },
    ],
  },
  {
    icon: Tag,
    title: 'Produtos',
    content: [{ text: 'Todos os produtos', key: 'product' }],
  },
  {
    icon: Target,
    title: 'Marketing',
    content: [
      { text: 'Cupons', key: 'promocode' },
      { text: 'Upsell', key: 'upsell' },
      { text: 'Order bumps', key: 'orderbump' },
      { text: 'Pixels', key: 'pixel' },
      { text: 'Recuperações', key: 'recovery_template' },
    ],
  },
  {
    icon: Devices,
    title: 'Checkout',
    content: [
      { text: 'Customizações', key: 'checkout_customization' },
      { text: 'Domínios', key: 'domain' },
      { text: 'Fretes', key: 'freight' },
      { text: 'Provas Sociais', key: 'social' },
    ],
  },
  {
    icon: SquaresFour,
    title: 'Central de Apps',
    content: [{ text: 'Integrações', key: 'app' }],
  },
  {
    icon: Gear,
    title: 'Configurações',
    content: [
      { text: 'Meu negócio', key: 'business' },
      { text: 'Emails transacionais', key: 'transactional_mail' },
      { text: 'Usuários', key: 'user' },
      { text: 'Webhooks', key: 'webhook' },
    ],
  },
];
