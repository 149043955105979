import styled, { css } from 'styled-components/macro';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/common/EHeading';

export interface IHeadingProps {
  size?: EHeadingSize;
  fontWeight?: EHeadingWeight;
}

export const HeadingBase = styled.h1<IHeadingProps>`
  font-family: ${({ theme }) => theme.fonts.heading};
  color: ${({ theme }) => theme.colors.neutral.darker};
  margin: unset;

  ${({ size }) =>
    size === EHeadingSize.H1 &&
    css`
      font-size: 3.2rem;
      line-height: 100%;
    `}

  ${({ size }) =>
    size === EHeadingSize.H2 &&
    css`
      font-size: 2.4rem;
      line-height: 110%;
    `}

    ${({ size }) =>
    size === EHeadingSize.H3 &&
    css`
      font-size: 2.4rem;
      line-height: 120%;
    `}

    ${({ size }) =>
    size === EHeadingSize.H4 &&
    css`
      font-size: 1.8rem;
      line-height: 130%;
    `}

    ${({ size }) =>
    size === EHeadingSize.H5 &&
    css`
      font-size: 1.8rem;
      line-height: 140%;
    `}

    ${({ size }) =>
    size === EHeadingSize.H6 &&
    css`
      font-size: 1.6rem;
      line-height: 150%;
    `}

    ${({ fontWeight }) =>
    fontWeight === EHeadingWeight.LIGHT &&
    css`
      font-weight: 300;
    `}

    ${({ fontWeight }) =>
    fontWeight === EHeadingWeight.REGULAR &&
    css`
      font-weight: 400;
    `}

    ${({ fontWeight }) =>
    fontWeight === EHeadingWeight.MEDIUM &&
    css`
      font-weight: 500;
    `}

    ${({ fontWeight }) =>
    fontWeight === EHeadingWeight.SEMI_BOLD &&
    css`
      font-weight: 600;
    `}

    ${({ fontWeight }) =>
    fontWeight === EHeadingWeight.BOLD &&
    css`
      font-weight: 700;
    `}

    ${({ fontWeight }) =>
    fontWeight === EHeadingWeight.EXTRA_BOLD &&
    css`
      font-weight: 800;
    `}

    ${({ fontWeight }) =>
    fontWeight === EHeadingWeight.BLACK &&
    css`
      font-weight: 900;
    `}
`;
