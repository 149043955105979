import React from 'react';
import { Plus } from 'phosphor-react';
import { useTheme } from 'styled-components/macro';
import PageHeader from '@components/common/utils/PageHeader';
import { EButtonVariant } from '@domain/enums/components/common/EButton';

import { useNewPayout } from '@store/common/Payout/NewPayoutContext';

import * as S from './styles';

const Header: React.FC = () => {
  const theme = useTheme();
  const { setIsOpenModal } = useNewPayout();

  return (
    <S.Wrapper>
      <PageHeader title="Transferências" description="Transfira e acompanhe seu saldo" />
      <S.NewPayoutButton
        variant={EButtonVariant.SECONDARY}
        onClick={() => setIsOpenModal(state => !state)}
      >
        <Plus size={12} weight="bold" color={theme.colors.neutral.black} /> Nova Transferência
      </S.NewPayoutButton>
    </S.Wrapper>
  );
};
export default Header;
