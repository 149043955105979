import styled, { css } from 'styled-components/macro';

import { IRowProps } from './index';

export const Row = styled.tr<IRowProps>`
  & > td {
    padding: 16px 24px;

    ${({ bottomSpacing }) =>
      bottomSpacing &&
      css`
        padding-bottom: ${bottomSpacing}px;
      `}

    ${({ borderBottom }) =>
      borderBottom &&
      css`
        border-bottom: 5px solid #fbfbfb;
      `}
  }

  & > td:first-child {
    border-top-left-radius: ${({ theme }) => theme.borderRadius.small};
    border-bottom-left-radius: ${({ theme }) => theme.borderRadius.small};
  }

  & > td:last-child {
    border-top-right-radius: ${({ theme }) => theme.borderRadius.small};
    border-bottom-right-radius: ${({ theme }) => theme.borderRadius.small};
  }
`;
