import React from 'react';

import { EHeadingSize } from '@domain/enums/components/common/EHeading';

import { useGetDomain } from '@store/common/domain/GetDomainContext';
import { useAnalytics } from '@store/context/common/AnalyticsContext';

import EmptyData from '@components/common/utils/EmptyData';
import ListSkeletonLoading from '@components/common/utils/ListSkeletonLoading';
import AddDomainModal from '../AddDomainModal';
import Header from './Header';
import Body from './Body';

import * as S from './styles';

const Domain: React.FC = () => {
  const { isEmptyState, isLoadingDomain, setIsOpenAddDomainModal } = useGetDomain();
  const { analytics } = useAnalytics();

  const onEmptyDataButtonClick = (): void => {
    setIsOpenAddDomainModal(state => !state);
  };

  React.useEffect(() => {
    analytics.track('Domains Page Viewed');
  }, [analytics]);

  // return (
  //   <S.Wrapper>
  //     <S.Title size={EHeadingSize.H2}>Essa funcionalidade está em manutenção</S.Title>

  //     <S.Description>
  //       Estamos realizando melhorias quanto à funcionalidade de domínios. Você será avisado quando a
  //       manutenção finalizar.
  //     </S.Description>
  //   </S.Wrapper>
  // );

  if (isLoadingDomain) {
    return <ListSkeletonLoading />;
  }

  if (isEmptyState) {
    return (
      <>
        <AddDomainModal />
        <EmptyData
          title="Nenhum domínio cadastrado"
          description="Utilize seu próprio domínio no checkout para aumentar a credibilidade."
          buttonText="Novo domínio"
          onClick={onEmptyDataButtonClick}
        />
      </>
    );
  }

  return (
    <S.Wrapper>
      <Header />
      <Body />
    </S.Wrapper>
  );
};
export default Domain;
