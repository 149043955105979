import React from 'react';
import PageHeader from '@components/common/utils/PageHeader';

import * as S from './styles';

const Header: React.FC = () => {
  return (
    <S.Wrapper>
      <PageHeader
        goBackButton
        title="Domínio Personalizado"
        description="Utilize seu próprio domínio no checkout para aumentar a credibilidade."
      />
    </S.Wrapper>
  );
};

export default Header;
