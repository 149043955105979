import React from 'react';

import * as S from './styles';

export interface IRowProps {
  style?: React.CSSProperties;
  bottomSpacing?: number;
  borderBottom?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onClick?: () => void;
}

const Row: React.FC<IRowProps> = ({ children, bottomSpacing, borderBottom, style, ...rest }) => {
  return (
    <S.Row bottomSpacing={bottomSpacing} borderBottom={borderBottom} style={style} {...rest}>
      {children}
    </S.Row>
  );
};

export default Row;
