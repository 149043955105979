import React from 'react';

import ReportanaVectorBanner from '@assets/images/common/dashboard/integrations/reportana.svg';
import ReportanaIcon from '@assets/images/common/dashboard/integrations/reportanaIcon.svg';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/common/EHeading';

import { useAnalytics } from '@store/context/common/AnalyticsContext';
import { useReportanaContext } from '@store/common/Reportana/ReportanaContext';

import SaveForm from '../SaveForm';
import ReportanaEventsSelect from './ReportanaEventsSelect';

import * as S from './styles';

const Body: React.FC = () => {
  const { analytics } = useAnalytics();
  const {
    onActivateReportana,
    onInactivateReportana,
    onSubmit,
    handleSubmit,
    clientIdRegister,
    clientSecretRegister,
    watch,
    errors,
    reportanaCredential,
    onReportanaEventsChange,
  } = useReportanaContext();

  const [clientIdValue, clientSecretValue, eventValue] = watch([
    'client_id',
    'client_secret',
    'event',
  ]);

  const [showSaveForm, setShowSaveForm] = React.useState<boolean>(false);
  const [isActivated, setIsActivated] = React.useState<boolean>(false);

  const handleStatusChange = React.useCallback(
    async event => {
      setIsActivated(event.target.checked);
      if (event.target.checked) {
        await onActivateReportana();
      } else {
        await onInactivateReportana();
      }
    },
    [onInactivateReportana, onActivateReportana],
  );

  React.useEffect(() => {
    if ((clientIdValue || clientSecretValue || eventValue) && !reportanaCredential) {
      setShowSaveForm(true);
      return;
    }
    if (!reportanaCredential) return;
    if (
      reportanaCredential.client_id !== clientIdValue ||
      reportanaCredential.client_secret !== clientSecretValue ||
      reportanaCredential.events !== eventValue
    ) {
      setShowSaveForm(true);
    }
  }, [clientIdValue, clientSecretValue, reportanaCredential, eventValue]);

  React.useEffect(() => {
    if (reportanaCredential) {
      setIsActivated(Boolean(!reportanaCredential.inactivated_at));
    }
  }, [reportanaCredential]);

  React.useEffect(() => {
    analytics.track('Reportana Credential Page Viewed');
  }, [analytics]);

  return (
    <S.Wrapper>
      <S.AdditionalInformationWrapper>
        <S.AdditionalInformationContent>
          <S.AdditionalInformationHeader backgroundColor="#1C41D3">
            <S.BannerImage src={ReportanaVectorBanner} alt="Reportana banner image" />
            <S.IconWrapper>
              <S.Icon src={ReportanaIcon} alt="Integration image" />
            </S.IconWrapper>
          </S.AdditionalInformationHeader>

          <S.AdditionalInformationBody>
            <S.TitleAndSwitchWrapper>
              <S.Title size={EHeadingSize.H6} fontWeight={EHeadingWeight.SEMI_BOLD}>
                Reportana
              </S.Title>

              {reportanaCredential && (
                <S.Switch
                  id="install-ga4"
                  name="install-ga4"
                  checked={isActivated}
                  onChange={handleStatusChange}
                />
              )}
            </S.TitleAndSwitchWrapper>

            <S.Description>
              Maximize suas métricas. Todas as funcionalidades que seu negócio precisa em apenas um
              só lugar!
            </S.Description>
          </S.AdditionalInformationBody>
        </S.AdditionalInformationContent>

        {/* <Tutorial
          image_url={tutorialImage}
          title="Real-time Updates with Zouti: Reportana Integration Guide"
          video_url="https://www.youtube.com/embed/f5_wn8mexmM?si=Ud_rqwSSYa_nU4az"
        /> */}
      </S.AdditionalInformationWrapper>

      <S.Form onSubmit={handleSubmit(onSubmit)}>
        <S.CredentialsWrapper>
          <S.CredentialsTitle>Credenciais</S.CredentialsTitle>

          <S.InputGroup>
            <S.Label>Client ID</S.Label>
            <S.Input
              {...clientIdRegister}
              isError={Boolean(errors.client_id)}
              type="text"
              placeholder="XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX"
            />

            {errors.client_id && (
              <S.MessageError isFeedbackError>{errors.client_id.message}</S.MessageError>
            )}
          </S.InputGroup>

          <S.InputGroup>
            <S.Label>Client Secret</S.Label>

            <S.Input
              {...clientSecretRegister}
              isError={Boolean(errors.client_secret)}
              type="text"
              placeholder="XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX"
            />

            {errors.client_secret && (
              <S.MessageError isFeedbackError>{errors.client_secret.message}</S.MessageError>
            )}
          </S.InputGroup>

          <S.InputGroup>
            <S.Label>Selecionar Eventos</S.Label>
            <ReportanaEventsSelect onChange={onReportanaEventsChange} />
          </S.InputGroup>
        </S.CredentialsWrapper>

        {showSaveForm && <SaveForm />}
      </S.Form>
    </S.Wrapper>
  );
};

export default Body;
