import LabelBase from '@components/common/DataDisplay/Label';
import styled from 'styled-components/macro';

import TextBase from '@components/common/DataDisplay/Text';
import { ButtonBase } from '@components/common/Inputs/Button/styles';
import SwitchBase from '@components/common/Inputs/Switch';
import TableBase from '@components/common/utils/Table';
import { CopySimple, DotsThree, Eye, PencilSimple, Trash } from 'phosphor-react';

export const TextDescription = styled(TextBase)`
  max-width: 180px;
  min-width: 180px;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
  display: inline-block;
  font-size: 1.4rem;
`;

export const Label = styled(LabelBase)`
  font-size: 13px;
  margin-bottom: 0;
`;

export const Table = styled(TableBase)``;

export const Switch = styled(SwitchBase)``;

export const Text = styled(TextBase)`
  max-width: 215px;
  min-width: 215px;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
  display: inline-block;
  color: ${({ theme }) => theme.colors.neutral.darkest};
  font-size: 1.4rem;
`;

export const NewShippingButton = styled(ButtonBase)`
  & > span {
    color: ${({ theme }) => theme.colors.neutral.darker};
  }
`;

export const CopySimpleIcon = styled(CopySimple)`
  cursor: pointer;
  border-radius: 4px;
  padding: 4px;
`;

export const EyeIcon = styled(Eye)`
  margin-right: 10px;
  &:hover {
    background-color: #fff1dc;
  }
  cursor: pointer;
  border-radius: 4px;
  padding: 4px;
`;

export const PencilSimpleIcon = styled(PencilSimple).attrs({
  size: 16,
  weight: 'bold',
})`
  color: ${({ theme }) => theme.colors.neutral.darker};
`;

export const TrashIcon = styled(Trash).attrs({
  size: 16,
  weight: 'bold',
})`
  color: ${({ theme }) => theme.colors.neutral.darker};
`;

export const DotsThreeIcon = styled(DotsThree)`
  &:hover {
    background-color: #fff1dc;
  }
  cursor: pointer;
  border-radius: 4px;
  padding: 4px;
`;

export const ButtonPopover = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  width: 100%;
  cursor: pointer;
  background-color: #fff;
  &:hover {
    background-color: #fff1dc;
  }
`;

export const DivAlignDropdown = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 90px;
  margin-left: 5px;
  cursor: pointer;
  &:hover {
    background-color: #fff1dc;
  }
`;

export const TextAlignCenter = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 40px;
  background-color: blue;
`;

export const ImageFile = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 40px;
  background-color: white;
`;

export const ImageSelect = styled.img`
  height: 27px;
  width: 30px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  border-radius: 4px;
`;

export const ImageWithText = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

export const ButtonActionsWrapper = styled.div`
  display: flex;
  gap: 12px;
`;
