import styled from 'styled-components/macro';
import Skeleton from 'react-loading-skeleton';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 439px;
  height: 318px;
  padding: 24px;
  background-color: ${({ theme }) => theme.colors.neutral.white};
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const WrapperItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TextRight = styled(Skeleton)``;

export const ContainerIcon = styled(Skeleton)``;

export const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const IconAndTextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
