import styled from 'styled-components/macro';

import TableBase from '@components/common/utils/Table';

export const Wrapper = styled.div`
  margin-top: 24px;
`;

export const Table = styled(TableBase)`
  th {
    padding-bottom: 16px !important;
  }

  tr > td:nth-child(3) {
    color: ${({ theme }) => theme.colors.primary.base};
  }
`;
