import React from 'react';
import { EHeadingSize } from '@domain/enums/components/common/EHeading';

import * as S from './styles';

const Header: React.FC = () => {
  return (
    <S.Wrapper>
      <S.TitleAndSubtitleWrapper>
        <S.Title size={EHeadingSize.H2}>Perfil</S.Title>
      </S.TitleAndSubtitleWrapper>

      <S.Description>Informações básicas de perfil.</S.Description>
    </S.Wrapper>
  );
};

export default Header;
