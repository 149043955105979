import styled from 'styled-components/macro';
import Skeleton from 'react-loading-skeleton';

import DatePickerBase from '@components/common/utils/DatePicker';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  gap: ${({ theme }) => theme.spacing.small_16};
`;

export const DatePicker = styled(DatePickerBase)``;

export const SkeletonHeader = styled(Skeleton)`
  margin-bottom: 24px;
`;
