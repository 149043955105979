import styled from 'styled-components/macro';

export const Content = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
`;

export const Wrapper = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
`;
