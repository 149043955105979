import React from 'react';

const BankSlip: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.66669 3H2.88573V17H1.66669V3Z"
        fill="#515151"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.51202 3H9.73107V17H8.51202V3Z"
        fill="#515151"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1101 3H15.3291V17H14.1101V3Z"
        fill="#515151"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.2789 3H17.4979V17H16.2789V3Z"
        fill="#515151"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.4476 3H19.6667V17H18.4476V3Z"
        fill="#515151"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6808 3H13.1603V17H10.6808V3Z"
        fill="#515151"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.83545 3H7.5623V17H3.83545V3Z"
        fill="#515151"
      />
    </svg>
  );
};

export default BankSlip;
