import React from 'react';

import Tab from '@components/common/utils/Tab';
import Option from '@components/common/utils/Tab/Option';

import { GroupsProvider } from '@store/common/Group/NewGroupsContext';
import { useTabsContext } from '@store/context/components/Tab/tabsContext';

import Users from '../Tab/Users';
import Groups from '../Tab/Groups';
import SearchAndFilter from '../InputSearchAndFilter';

import * as S from './styles';

const Body: React.FC = () => {
  const { selectedTab, onChangeTab } = useTabsContext();

  return (
    <>
      <Tab defaultTab={selectedTab} onChangeTab={onChangeTab}>
        <Option>Usuários</Option>
        <Option>Grupos</Option>
      </Tab>
      {selectedTab === 0 && (
        <S.Wrapper>
          <SearchAndFilter />
          <Users />
        </S.Wrapper>
      )}
      {selectedTab === 1 && (
        <GroupsProvider>
          <Groups />
        </GroupsProvider>
      )}
    </>
  );
};

export default Body;
