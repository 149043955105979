import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

export const LinkBase = styled(Link)`
  font-family: ${({ theme }) => theme.fonts.text};
  font-size: 1.6rem;
  font-weight: 500;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.primary.dark_green_60};
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
    text-decoration: underline;
  }

  &:active {
    filter: brightness(0.8);
    text-decoration: underline;
  }
`;
