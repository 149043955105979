import React from 'react';
import { subDays, subMonths } from 'date-fns';

import { useDatePicker } from '@store/context/components/DatePickerContext';

import * as S from './styles';

const PreDefinedDates: React.FC = () => {
  const { handleCustomDate, handleSelectedEndDate, handleSelectedStartDate } = useDatePicker();

  const handleOnClick = React.useCallback(
    type => {
      if (type === 'YESTERDAY') {
        const startDate = subDays(new Date(), 1);
        const endDate = subDays(new Date(), 1);

        handleCustomDate([startDate, endDate]);
        handleSelectedStartDate(startDate);
        handleSelectedEndDate(endDate);
      }

      if (type === 'TODAY') {
        const startDate = new Date();
        const endDate = new Date();

        handleCustomDate([startDate, endDate]);
        handleSelectedStartDate(startDate);
        handleSelectedEndDate(endDate);
      }

      if (type === 'LAST_7_DAYS') {
        const startDate = subDays(new Date(), 6);
        const endDate = new Date();

        handleCustomDate([startDate, endDate]);
        handleSelectedStartDate(startDate);
        handleSelectedEndDate(endDate);
      }

      if (type === 'LAST_15_DAYS') {
        const startDate = subDays(new Date(), 14);
        const endDate = new Date();

        handleCustomDate([startDate, endDate]);
        handleSelectedStartDate(startDate);
        handleSelectedEndDate(endDate);
      }

      if (type === 'LAST_30_DAYS') {
        const startDate = subDays(new Date(), 29);
        const endDate = new Date();

        handleCustomDate([startDate, endDate]);
        handleSelectedStartDate(startDate);
        handleSelectedEndDate(endDate);
      }

      if (type === 'LAST_3_MONTHS') {
        const startDate = subMonths(new Date(), 3);
        const endDate = new Date();

        handleCustomDate([startDate, endDate]);
        handleSelectedStartDate(startDate);
        handleSelectedEndDate(endDate);
      }

      if (type === 'LAST_6_MONTHS') {
        const startDate = subMonths(new Date(), 6);
        const endDate = new Date();

        handleCustomDate([startDate, endDate]);
        handleSelectedStartDate(startDate);
        handleSelectedEndDate(endDate);
      }
    },
    [handleCustomDate, handleSelectedEndDate, handleSelectedStartDate],
  );

  return (
    <S.Wrapper>
      <S.DateButton onClick={() => handleOnClick('YESTERDAY')}>Ontem</S.DateButton>
      <S.DateButton onClick={() => handleOnClick('TODAY')}>Hoje</S.DateButton>
      <S.DateButton onClick={() => handleOnClick('LAST_7_DAYS')}>Últimos 7 dias</S.DateButton>
      <S.DateButton onClick={() => handleOnClick('LAST_15_DAYS')}>Últimos 15 dias</S.DateButton>
      <S.DateButton onClick={() => handleOnClick('LAST_30_DAYS')}>Últimos 30 dias</S.DateButton>
      <S.DateButton onClick={() => handleOnClick('LAST_3_MONTHS')}>Últimos 3 meses</S.DateButton>
      <S.DateButton onClick={() => handleOnClick('LAST_6_MONTHS')}>Últimos 6 meses</S.DateButton>
    </S.Wrapper>
  );
};

export default PreDefinedDates;
