import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useGetPixel } from '@store/common/pixel/GetPixelContext';
import { useAnalytics } from '@store/context/common/AnalyticsContext';

import EmptyData from '@components/common/utils/EmptyData';

import ListSkeletonLoading from '@components/common/utils/ListSkeletonLoading';
import Header from './Header';
import Body from './Body';

import * as S from './styles';

const Pixel: React.FC = () => {
  const navigate = useNavigate();
  const { analytics } = useAnalytics();
  const { accountId, checkoutId } = useParams();
  const { isLoadingPixels, isPixelsError, isEmptyPixels } = useGetPixel();

  const onEmptyDataButtonClick = (): void => {
    navigate(`/${accountId}/${checkoutId}/dashboard/marketing/pixel/new`);
  };

  React.useEffect(() => {
    analytics.track('Pixels Page Viewed');
  }, [analytics]);

  if (isLoadingPixels) {
    return <ListSkeletonLoading />;
  }

  if (isPixelsError && !isLoadingPixels) {
    return <div>Error...</div>;
  }

  if (isEmptyPixels) {
    return (
      <EmptyData
        title="Nenhum pixel cadastrado"
        description="Adicione pixels das principais plataformas para monitorar sua loja."
        buttonText="Novo pixel"
        onClick={onEmptyDataButtonClick}
      />
    );
  }
  return (
    <S.Wrapper>
      <Header />

      <Body />
    </S.Wrapper>
  );
};
export default Pixel;
