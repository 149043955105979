import React from 'react';

import { EHeadAlign } from '@domain/enums/components/common/ETable';

import * as S from './styles';

interface IHeadProps {
  align?: EHeadAlign;
  upperCase?: boolean;
  colSpan?: number;
  children?: React.ReactNode;
}

const Head: React.ForwardRefRenderFunction<HTMLTableCellElement, IHeadProps> = (
  { align = EHeadAlign.LEFT, upperCase, children, colSpan, ...rest },
  ref,
) => {
  return (
    <S.Head colSpan={colSpan} textAlign={align} upperCase={upperCase} ref={ref} {...rest}>
      {children}
    </S.Head>
  );
};

export default React.forwardRef(Head);
