import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { INewShippingProvider } from '@domain/interfaces/store/common/shipping/INewShippingProvider';

import { useErrorHandler } from '@store/context/common/ErrorHandlerContext';
import { useShippingService } from '@hooks/services/shipping/useShippingService';
import { useToast } from '@store/context/common/ToastContext';
import { useAnalytics } from '@store/context/common/AnalyticsContext';
import { useGetAccountStep } from '../home/GetAccountStepContext';

const NewShippingContext = React.createContext<INewShippingProvider | null>(null);

export const NewShippingProvider: React.FC = ({ children }) => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const { accountId, checkoutId } = useParams();
  const { handleError } = useErrorHandler();
  const { createShipping } = useShippingService();
  const { analytics } = useAnalytics();
  const { propertiesFirstStep, mutate } = useGetAccountStep();

  const isFirstTime = Boolean(!propertiesFirstStep?.FREIGHTS_CONFIGURED_AT);

  const [isCreatingShipping, setIsCreatingShipping] = React.useState<boolean>(false);
  const [isFreeShipping, setIsFreeShipping] = React.useState<boolean>(false);
  const [period, setPeriod] = React.useState<number>(1);

  const increasePeriod = React.useCallback(() => {
    setPeriod(state => state + 1);
  }, []);

  const decreasePeriod = React.useCallback(() => {
    if (period === 1) return;

    setPeriod(state => state - 1);
  }, [period]);

  const onChangePeriod = React.useCallback(event => {
    setPeriod(event.target.value);
  }, []);

  const createNewShipping = React.useCallback(
    async data => {
      if (data?.amount === 0 && !data?.is_free) {
        toast.error('O valor do frete não pode ser zero!');
        return;
      }

      const payload = { ...data, trigger: 'STANDARD' };

      setIsCreatingShipping(true);

      try {
        await createShipping({ accountId, checkoutId, payload });

        await new Promise(resolve => setTimeout(resolve, 3000));

        await mutate();

        analytics.track('Shipping Created', { is_free_shipping: Boolean(data.is_free) });

        setIsCreatingShipping(false);
        toast.success('Frete criado com sucesso!');
        if (isFirstTime) {
          navigate(`/${accountId}/${checkoutId}/dashboard/home`);
        } else {
          navigate(`/${accountId}/${checkoutId}/dashboard/checkout/shipping`);
        }
      } catch (error) {
        setIsCreatingShipping(false);
        handleError(error);
      }
    },
    [
      createShipping,
      handleError,
      toast,
      navigate,
      accountId,
      checkoutId,
      analytics,
      isFirstTime,
      mutate,
    ],
  );

  return (
    <NewShippingContext.Provider
      value={{
        createNewShipping,
        decreasePeriod,
        increasePeriod,
        onChangePeriod,
        setIsFreeShipping,
        isFreeShipping,
        period,
        isCreatingShipping,
      }}
    >
      {children}
    </NewShippingContext.Provider>
  );
};

export const useNewShipping = (): INewShippingProvider => {
  const context = React.useContext(NewShippingContext);

  if (!context) {
    throw new Error('useNewShipping must be used within provider');
  }

  return context;
};
