import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/common/EHeading';
import { EStoreOnboardingStep, EStoreSegment } from '@domain/enums/common/onboarding/EStore';

import { useConfig } from '@store/context/common/ConfigContext';
import { useStoreOnboarding } from '@store/context/onboarding/store/StoreOnboardingContext';

import { storeSegmentSchema } from '@helpers/validators/pages/onboarding/store/storeSegment';

import { getEnvironment } from '@utils/common/environment';

import Text from '@components/common/DataDisplay/Text';

import * as S from './styles';

const StoreSegment: React.FC = () => {
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(storeSegmentSchema),
  });

  const { handleStoreSegmentAnswer, handleStoreNameValue, handleOnboardingStep } =
    useStoreOnboarding();

  const { user } = useConfig();
  const isAbleToUseGlobal = user.is_able_to_use_global || getEnvironment() === 'dev';
  const isGlobalBetaEnabled =
    user.user.email === 'gabriel500morais@hotmail.com' ||
    user.user.email === 'orlandohuebc@gmail.com' ||
    user.user.email === 'vitornasman@gmail.com' ||
    user.user.email === 'ppmarketingltda@gmail.com';

  const storeSegmentRegister = register('store_segment');
  const storeSegmentValueRegister = register('store_segment_value');

  const onSubmit = React.useCallback(
    data => {
      handleStoreSegmentAnswer(data.store_segment);
      handleStoreNameValue(data.store_segment_value);
      handleOnboardingStep(EStoreOnboardingStep.SECOND_STEP);
    },
    [handleStoreSegmentAnswer, handleStoreNameValue, handleOnboardingStep],
  );

  watch(['store_segment']);

  const [storeSegmentValue] = getValues(['store_segment']);

  const isSubmitButtonDisabled = Boolean(!storeSegmentValue);
  const showStoreSegmentInput = storeSegmentValue === EStoreSegment.OTHER;

  return (
    <S.Wrapper onSubmit={handleSubmit(onSubmit)}>
      <S.QuestionText size={EHeadingSize.H6} fontWeight={EHeadingWeight.MEDIUM}>
        Qual seu tipo de negócio?
      </S.QuestionText>

      <S.TitleDescription>
        Escolha abaixo qual opção melhor representa o seu negócio.
      </S.TitleDescription>

      <S.OptionsWrapper>
        <S.Option htmlFor={EStoreSegment.DROPSHIPPING_INTERNATIONAL}>
          <S.RadioOption
            {...storeSegmentRegister}
            id={EStoreSegment.DROPSHIPPING_INTERNATIONAL}
            value={EStoreSegment.DROPSHIPPING_INTERNATIONAL}
          />
          <S.Text>Dropshipping Internacional</S.Text>
        </S.Option>

        <S.Option htmlFor={EStoreSegment.DROPSHIPPING_NATIONAL}>
          <S.RadioOption
            {...storeSegmentRegister}
            id={EStoreSegment.DROPSHIPPING_NATIONAL}
            value={EStoreSegment.DROPSHIPPING_NATIONAL}
          />
          <S.Text>Dropshipping Nacional</S.Text>
        </S.Option>

        {(isAbleToUseGlobal || isGlobalBetaEnabled) && (
          <S.Option htmlFor={EStoreSegment.DROPSHIPPING_GLOBAL}>
            <S.RadioOption
              {...storeSegmentRegister}
              id={EStoreSegment.DROPSHIPPING_GLOBAL}
              value={EStoreSegment.DROPSHIPPING_GLOBAL}
            />
            <S.Text>Dropshipping Global</S.Text>
          </S.Option>
        )}

        <S.Option htmlFor={EStoreSegment.INFOPRODUCT}>
          <S.RadioOption
            {...storeSegmentRegister}
            id={EStoreSegment.INFOPRODUCT}
            value={EStoreSegment.INFOPRODUCT}
          />
          <S.Text>Infoproduto</S.Text>
        </S.Option>

        <S.Option htmlFor={EStoreSegment.ECOMMERCE}>
          <S.RadioOption
            {...storeSegmentRegister}
            id={EStoreSegment.ECOMMERCE}
            value={EStoreSegment.ECOMMERCE}
          />
          <S.Text>E-commerce</S.Text>
        </S.Option>
      </S.OptionsWrapper>

      {showStoreSegmentInput && (
        <S.InputGroup>
          <S.Label>Qual plataforma?</S.Label>
          <S.Input {...storeSegmentValueRegister} type="text" placeholder="Informe o segmento" />
          {errors?.store_segment_value && (
            <Text isFeedbackError>{errors?.store_segment_value?.message}</Text>
          )}
        </S.InputGroup>
      )}

      <S.SubmitButton type="submit" disabled={isSubmitButtonDisabled}>
        Continuar
      </S.SubmitButton>
    </S.Wrapper>
  );
};

export default StoreSegment;
