import React from 'react';

import { useShopify } from '@store/common/shopify/ShopifyContext';

import AppSkeletonLoading from '@components/common/utils/AppSkeletonLoading';
import Body from '../Body';
import Header from '../Header';

import * as S from './styles';

const Wrapper: React.FC = () => {
  const { isLoadingShopify, isValidatingShopify } = useShopify();

  if (isLoadingShopify || isValidatingShopify) {
    return <AppSkeletonLoading />;
  }

  return (
    <S.Wrapper>
      <Header />

      <Body />
    </S.Wrapper>
  );
};

export default Wrapper;
