import { SUB_MENU_OPTIONS } from './subMenuOptions';

const filteredOptionss = SUB_MENU_OPTIONS.filter(option => option.name !== 'marketing-upsells');

export const INFOPRODUCT_SUB_MENU_OPTIONS = [
  ...filteredOptionss,
  {
    name: 'marketing-upsell-generator',
    parsedName: 'Gerador Upsell',
    path: '/marketing/upsells/generate',
    parent: 'marketing',
    requiredPermissions: ['upsell'],
  },
  {
    name: 'new-product',
    parsedName: 'Novo produto',
    path: '/new-product',
    parent: 'products',
    requiredPermissions: ['product'],
  },
];
