import styled from 'styled-components';
import TextBase from '@components/common/DataDisplay/Text';

export const CardTitle = styled(TextBase)`
  font-size: 1.8rem;
  color: ${({ theme }) => theme.colors.neutral.darkest};
  font-weight: ${({ theme }) => theme.typography.weights.semibold};
`;

export const PaymentMethodContainer = styled.section`
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lighter};
  background-color: ${({ theme }) => theme.colors.neutral.white};
  width: 345px;
  padding: 16px;
`;

export const Divider = styled.hr`
  background-color: ${({ theme }) => theme.colors.neutral.lightest};
  height: 1px;
  border: none;
  margin: 20px 0;
`;

export const DividerTitle = styled(Divider)`
  margin: 16px 0 20px 0;
`;

export const PaymentMethodItem = styled.article`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const PaymentMethodIconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const TextIcon = styled(TextBase)`
  font-weight: ${({ theme }) => theme.typography.weights.medium};
`;
export const Icons = styled.div``;

export const PaymentMethodDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const FeeGroup = styled.div``;

export const FeeAmount = styled(TextBase)`
  font-size: 1.6rem;
  font-weight: ${({ theme }) => theme.typography.weights.semibold};
  color: ${({ theme }) => theme.colors.primary.dark_green_60};
`;
export const FeeDescription = styled(TextBase)`
  font-size: 1.2rem;
  font-weight: ${({ theme }) => theme.typography.weights.regular};
  color: ${({ theme }) => theme.colors.neutral.dark};
`;

export const Description = styled(TextBase)`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.neutral.dark};
`;
