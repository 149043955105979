import React from 'react';

import { useTheme } from 'styled-components';

import { ETypographySize, ETypographyWeight } from '@domain/enums/components/common/ETypography';

import * as S from './styles';

interface ITutorialProps {
  title: string;
  video_url: string;
  image_url?: string;
}

const Tutorial: React.FC<ITutorialProps> = ({ title, video_url, image_url }) => {
  const theme = useTheme();

  const [isVideoModalOpen, setIsVideoModalOpen] = React.useState(false);

  const handleModalOpen = React.useCallback(() => {
    setIsVideoModalOpen(currentState => !currentState);
  }, []);

  return (
    <S.TutorialWrapper>
      <S.PlayWrapper onClick={handleModalOpen}>
        <S.TutorialPlayImage src={image_url} alt="Tutorial image" />
      </S.PlayWrapper>

      <S.TutorialBody>
        <S.TutorialChip
          backgroundColor={theme.colors.info.white_blue}
          textColor={theme.colors.info.darker}
        >
          Tutorial
        </S.TutorialChip>

        <S.TutorialTitle size={ETypographySize.h6_button_medium} weight={ETypographyWeight.medium}>
          {title}
        </S.TutorialTitle>

        <S.PlayWrapper onClick={handleModalOpen}>
          <S.TutorialText size={ETypographySize.medium_text} weight={ETypographyWeight.medium}>
            Assistir tutorial
          </S.TutorialText>
        </S.PlayWrapper>
      </S.TutorialBody>

      <S.VideoModal src={video_url} isOpen={isVideoModalOpen} toggle={handleModalOpen} />
    </S.TutorialWrapper>
  );
};

export default Tutorial;
