import styled from 'styled-components/macro';

import HeadingBase from '@components/common/DataDisplay/Heading';
import TextBase from '@components/common/DataDisplay/Text';
import InputGroupBase from '@components/common/Inputs/InputGroup';
import LabelBase from '@components/common/DataDisplay/Label';
import TextFieldBase from '@components/common/Inputs/TextField';
import SwitchBase from '@components/common/Inputs/Switch';
import ButtonBase from '@components/common/Inputs/Button';

interface IAdditionalInformationHeaderProps {
  backgroundColor: string;
}

export const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  gap: 24px;
`;

export const AdditionalInformationWrapper = styled.div`
  width: 100%;
  max-width: 438px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const AdditionalInformationContent = styled.div`
  width: 100%;
  max-width: 438px;
  display: flex;
  flex-direction: column;
`;

export const AdditionalInformationHeader = styled.div<IAdditionalInformationHeaderProps>`
  position: relative;
  width: 100%;
  height: 110px;
  background: ${({ backgroundColor }) => backgroundColor};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px 8px 0 0;
`;

export const AdditionalInformationBody = styled.div`
  background: #fff;
  width: 100%;
  height: 186px;
  border-radius: 0 0 8px 8px;
  display: flex;
  flex-direction: column;
  padding: 24px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
`;

export const Title = styled(HeadingBase)`
  color: ${({ theme }) => theme.colors.neutral.darker};
  font-size: 1.6rem;
  font-weight: 600;
  text-transform: capitalize;
`;

export const Description = styled(TextBase)`
  color: ${({ theme }) => theme.colors.neutral.dark};
  line-height: 170%;
`;

export const FullImg = styled.img`
  width: 100%;
  max-width: 91px;
`;

export const IconWrapper = styled.div`
  position: absolute;
  left: 24px;
  bottom: -18px;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.neutral.gray_white};
  padding: 8px 9px;
`;

export const Icon = styled.img`
  width: 100%;
  max-width: 20px;
`;

export const CredentialsWrapper = styled.div`
  width: 100%;
  background: #fff;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lighter};
  display: flex;
  flex-direction: column;
  padding: 24px;
  box-sizing: border-box;
`;

export const SwitchGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.colors.neutral.lightest};
  margin: 24px 0;
`;

export const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const SwitchText = styled(TextBase)`
  color: ${({ theme }) => theme.colors.neutral.darkest};
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
`;

export const SkipCartSwitch = styled(SwitchBase)``;

export const TitleAndSwitchWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

export const Switch = styled(SwitchBase)``;

export const InputGroup = styled(InputGroupBase)`
  margin-bottom: 24px;
`;

export const Label = styled(LabelBase)``;

export const Input = styled(TextFieldBase)``;

export const ReinstallCheckoutButton = styled(ButtonBase)`
  width: fit-content;
  background-color: ${({ theme }) => theme.colors.neutral.gray_white};

  & > span {
    font-size: 14px;
    color: ${({ theme }) => theme.colors.neutral.dark} !important;
  }
`;

export const DeleteCredentialButton = styled(ButtonBase)`
  background-color: transparent;
  border: none;

  > span {
    color: ${({ theme }) => theme.colors.negative.base};
    font-size: 1.6rem;
    font-weight: 500;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: space-between;
`;

export const Form = styled.form`
  width: 100%;
`;

export const MessageError = styled(TextBase)`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.negative.base};
`;
