import React from 'react';
import { format } from 'date-fns';
import { useTheme } from 'styled-components/macro';

import { EChipColor } from '@domain/enums/components/common/EChip';
import { IAbandonedCart } from '@domain/interfaces/common/AbandonedCart/IAbandonedCart';

import { ABANDONED_CART_STEPS } from '@constants/common/abandonedCart';

import { getStepsAbandonedCart } from '@utils/getStepsAbandonedCart';

import { formatCurrency } from '@utils/common/formatCurrency';

import { useUserAccount } from '@store/context/common/UserAccount';
import { useAnalytics } from '@store/context/common/AnalyticsContext';

import { useListAbandonedCart } from '@store/common/AbandonedCart/ListAbandonedCartContext';
import * as S from './styles';

interface ITableRowProps {
  abandonedCart: IAbandonedCart;
}

const TableRow: React.FC<ITableRowProps> = ({ abandonedCart }) => {
  const theme = useTheme();
  const { currentUserAccount } = useUserAccount();
  const { accountRecoveryTemplate } = useListAbandonedCart();
  const { analytics } = useAnalytics();

  const [isHovering, setIsHovering] = React.useState<boolean>(false);

  const onMouseEnter = (): void => setIsHovering(true);

  const onMouseLeave = (): void => setIsHovering(false);

  const sendWhatsAppMessage = React.useCallback(() => {
    if (!abandonedCart?.customer_phone) return;

    const recoveryTemplate = accountRecoveryTemplate.find(template => template.type === 'WHATSAPP');

    const text = recoveryTemplate?.message || '';

    const parsedText = text
      .replace(/\n/g, '%0a')
      .replace(/{{nome}}/g, abandonedCart?.customer_name)
      .replace(/{{link}}/g, abandonedCart?.url);

    window.open(`https://wa.me/55${abandonedCart?.customer_phone}?text=${parsedText}`, '_blank');

    analytics.track('Abandoned Cart Send Whatsapp Message Clicked');
  }, [accountRecoveryTemplate, abandonedCart, analytics]);

  const step = getStepsAbandonedCart(abandonedCart?.step);

  const currentStepInfo = ABANDONED_CART_STEPS.find(item => item.step === abandonedCart.step);
  const StepIcon = currentStepInfo && currentStepInfo.icon;
  const formatDate = format(new Date(abandonedCart.created_at), 'dd/MM/yyyy HH:mm');
  const totalAmount = abandonedCart.total_amount / 100;
  const totalAmountFormatted = formatCurrency(
    totalAmount,
    currentUserAccount?.language,
    abandonedCart.currency,
  );

  return (
    <S.Row borderBottom onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <S.Table.Data>
        <S.OrderIdAndDateWrapper>
          <S.OrderIdText to={`${abandonedCart.id}`}>{abandonedCart.id}</S.OrderIdText>
          <S.DateText>{formatDate}</S.DateText>
        </S.OrderIdAndDateWrapper>
      </S.Table.Data>

      <S.Table.Data>
        <S.CustomerName>{abandonedCart.customer_name}</S.CustomerName>
      </S.Table.Data>

      <S.Table.Data>
        <S.Chip backgroundColor={EChipColor.WHITE_BLUE} textColor={EChipColor.DARK_BLUE}>
          {StepIcon && <StepIcon color={theme.colors.info.darker} size={16} />}
          {step}
        </S.Chip>
      </S.Table.Data>

      <S.Table.Data>
        <S.TransactionAmount>{totalAmountFormatted}</S.TransactionAmount>
      </S.Table.Data>

      <S.Table.Data>
        <S.Actions>
          {isHovering && (
            <S.Tooltip>
              <S.TooltipTrigger>
                <S.TooltipContent>
                  <S.TooltipText>Enviar pelo WhatsApp</S.TooltipText>
                </S.TooltipContent>
                <S.WrapperIcon onClick={sendWhatsAppMessage}>
                  <S.WhatsAppIcon />
                </S.WrapperIcon>
              </S.TooltipTrigger>
            </S.Tooltip>
          )}
        </S.Actions>
      </S.Table.Data>
      <S.Table.Data />
    </S.Row>
  );
};

export default TableRow;
