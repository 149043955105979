import styled from 'styled-components/macro';

import CheckboxBase from '@components/common/Inputs/Checkbox';
import HeadingBase from '@components/common/DataDisplay/Heading';
import InputGroupBase from '@components/common/Inputs/InputGroup';
import TableBase from '@components/common/utils/Table';

export const Wrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  margin-top: 24px;
  max-width: 530px;
`;

export const UpdatePixelHeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  padding-bottom: 24px;
`;

export const TitleAndButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  width: 100%;
`;

export const TitleAndSubtitleWrapper = styled(HeadingBase)`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const TableWrapper = styled.div`
  max-width: 1024px;
  margin: 0 auto;
`;

export const Table = styled(TableBase)`
  th {
    padding-bottom: 16px !important;
  }
`;

export const TitleButton = styled.button`
  background-color: unset;
  border: unset;
  cursor: pointer;
  padding: unset;
`;

export const Title = styled(HeadingBase)`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const UpdatePixelBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-color: #fff;
  padding: 24px;
  border-radius: ${({ theme }) => theme.borderRadius.small};
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
`;

export const InputGroup = styled(InputGroupBase)``;

export const Checkbox = styled(CheckboxBase)``;

export const CheckboxGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
