import React from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import { INewRecoverySMSProvider } from '@domain/interfaces/store/common/recovery/INewRecoverySMSProvider';
import { IGetRecoveryTemplatesResponse } from '@domain/interfaces/hooks/services/recoveryTemplate/IRecoveryTemplateService';
import { IRecoveryTemplates } from '@domain/interfaces/common/recovery/IRecoveryTemplates';
import { ISwr } from '@domain/interfaces/common/swr/ISwr';

import { dashboardInstance } from '@services/common/dashboardInstance';
import { DASHBOARD_API_URL } from '@constants/services/apiUrl';

import { useErrorHandler } from '@store/context/common/ErrorHandlerContext';
import { useRecoveryTemplateService } from '@hooks/services/recovery/useRecoveryTemplateService';
import { useToast } from '@store/context/common/ToastContext';
import { useAnalytics } from '@store/context/common/AnalyticsContext';

const NewRecoverySMSContext = React.createContext<INewRecoverySMSProvider | null>(null);

export const NewRecoverySMSProvider: React.FC = ({ children }) => {
  const { toast } = useToast();
  const { analytics } = useAnalytics();
  const { handleError } = useErrorHandler();
  const { accountId, checkoutId } = useParams();
  const { updateRecoveryTemplateSMS, inactivateRecoveryTemplate, activateRecoveryTemplate } =
    useRecoveryTemplateService();

  const [recoveryTemplates, setRecoveryTemplates] = React.useState<IRecoveryTemplates>(
    {} as IRecoveryTemplates,
  );

  const [isCreatingRecoverySMS, setIsCreatingRecoverySMS] = React.useState<boolean>(false);
  const [isActive, setIsActive] = React.useState<boolean>(false);

  dashboardInstance.defaults.headers.common['x-zouti-account'] = accountId;

  const RECOVERY_TEMPLATES_URL = `${DASHBOARD_API_URL}/accounts/${accountId}/checkouts/${checkoutId}/recovery_templates`;

  const { data, isLoading, isValidating, error, mutate } = useSWR<
    ISwr<IGetRecoveryTemplatesResponse>
  >(RECOVERY_TEMPLATES_URL, dashboardInstance);

  React.useEffect(() => {
    if (error) {
      handleError(error);
    }

    if (data?.data) {
      const recoveryTemplatesSMS = data.data.object.find(item => item.type === 'SMS');
      if (recoveryTemplatesSMS) {
        const isInactivated = Object.prototype.hasOwnProperty.call(
          recoveryTemplatesSMS,
          'inactivated_at',
        );
        setRecoveryTemplates(recoveryTemplatesSMS);
        setIsActive(!isInactivated);
      }
    }
  }, [data, handleError, error]);

  const updateCurrentRecoverySMS = React.useCallback(
    async payload => {
      setIsCreatingRecoverySMS(true);

      try {
        await updateRecoveryTemplateSMS({
          templateId: recoveryTemplates.id,
          accountId,
          checkoutId,
          ...payload,
        });

        analytics.track('Recovery Template Updated', { type: 'SMS' });

        setIsCreatingRecoverySMS(false);
        toast.success('Mensagem enviada com sucesso!');
      } catch (err) {
        setIsCreatingRecoverySMS(false);
        handleError(err);
      } finally {
        setIsCreatingRecoverySMS(false);
      }
    },
    [
      updateRecoveryTemplateSMS,
      handleError,
      toast,
      recoveryTemplates.id,
      accountId,
      checkoutId,
      analytics,
    ],
  );

  const inactivateCurrentRecoveryTemplate = React.useCallback(
    async (templateId: string) => {
      try {
        await inactivateRecoveryTemplate({ templateId, accountId, checkoutId });

        analytics.track('Recovery Template Updated', { type: 'SMS' });

        await mutate();
      } catch (errorDeleteShipping) {
        toast.error('Erro ao inativar envio de mensagem!');
      }
    },
    [inactivateRecoveryTemplate, toast, mutate, accountId, checkoutId, analytics],
  );
  const activateCurrentRecoveryTemplate = React.useCallback(
    async (templateId: string) => {
      try {
        await activateRecoveryTemplate({ templateId, accountId, checkoutId });

        analytics.track('Recovery Template Updated', { type: 'SMS' });

        await mutate();
      } catch (errorDeleteShipping) {
        toast.error('Erro ao ativar envio de mensagem!');
      }
    },
    [activateRecoveryTemplate, toast, mutate, accountId, checkoutId, analytics],
  );

  const isLoadingRecoveryTemplates = isLoading || isValidating;
  const isRecoveryTemplatesError = Boolean(error);

  return (
    <NewRecoverySMSContext.Provider
      value={{
        isCreatingRecoverySMS,
        updateCurrentRecoverySMS,
        recoveryTemplates,
        isLoadingRecoveryTemplates,
        isRecoveryTemplatesError,
        isActive,
        setIsActive,
        inactivateCurrentRecoveryTemplate,
        activateCurrentRecoveryTemplate,
      }}
    >
      {children}
    </NewRecoverySMSContext.Provider>
  );
};

export const useNewRecoverySMS = (): INewRecoverySMSProvider => {
  const context = React.useContext(NewRecoverySMSContext);

  if (!context) {
    throw new Error('useNewRecoverySMS must be used within provider');
  }

  return context;
};
