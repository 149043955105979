import React from 'react';

import Content from '@components/pages/Dashboard/CheckoutCustomization/UpdateCheckoutCustomization';
import { EditCheckoutCustomizationProvider } from '@store/common/checkoutCustomization/EditCheckoutCustomizationContext';

const UpdateCheckoutCustomization: React.FC = () => {
  return (
    <EditCheckoutCustomizationProvider>
      <Content />
    </EditCheckoutCustomizationProvider>
  );
};

export default UpdateCheckoutCustomization;
