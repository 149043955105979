import { AxiosResponse } from 'axios';

import {
  ICreateGA4Props,
  IGA4Service,
  IActivateGA4Props,
  IInactivateGA4Props,
  IInactivateGA4Response,
  IActiveGA4Response,
  ICreateGA4CredentialResponse,
  IUpdateGA4Props,
} from '@domain/interfaces/hooks/services/GA4/IGA4Service';

import { dashboardInstance } from '@services/common/dashboardInstance';

export const useGA4Service = (): IGA4Service => {
  const createGA4 = async ({
    accountId,
    checkoutId,
    payload,
  }: ICreateGA4Props): Promise<AxiosResponse<ICreateGA4CredentialResponse>> => {
    return dashboardInstance.post(
      `/accounts/${accountId}/checkouts/${checkoutId}/integrations/ga4`,
      { ...payload },
    );
  };

  const updateGA4 = async ({
    accountId,
    checkoutId,
    ga4Id,
    payload,
  }: IUpdateGA4Props): Promise<AxiosResponse<ICreateGA4CredentialResponse>> => {
    return dashboardInstance.put(
      `/accounts/${accountId}/checkouts/${checkoutId}/integrations/ga4/${ga4Id}`,
      {
        ...payload,
      },
    );
  };

  const activateGA4 = async ({
    accountId,
    checkoutId,
    ga4Id,
  }: IActivateGA4Props): Promise<AxiosResponse<IActiveGA4Response>> => {
    return dashboardInstance.put(
      `accounts/${accountId}/checkouts/${checkoutId}/integrations/ga4/${ga4Id}/activate`,
    );
  };

  const inactivateGA4 = async ({
    accountId,
    checkoutId,
    ga4Id,
  }: IInactivateGA4Props): Promise<AxiosResponse<IInactivateGA4Response>> => {
    return dashboardInstance.put(
      `accounts/${accountId}/checkouts/${checkoutId}/integrations/ga4/${ga4Id}/inactivate`,
    );
  };

  return { createGA4, activateGA4, inactivateGA4, updateGA4 };
};
