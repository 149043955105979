import React from 'react';
import { Controller } from 'react-hook-form';

import Text from '@components/common/DataDisplay/Text';
import { useNewPixel } from '@store/common/pixel/NewPixelContext';

import * as S from './styles';

const FacebookConversionForm: React.FC = () => {
  const { register, control, errors } = useNewPixel();

  const pixelName = register('pixelName');
  const pixelId = register('pixelId');
  const accessToken = register('accessToken');

  return (
    <S.Wrapper>
      <S.InputGroup>
        <S.Label>Nome do Pixel</S.Label>
        <S.Input placeholder="Ex: Campanha de Remarketing" {...pixelName} type="text" />
        {errors?.pixelName && <Text isFeedbackError>{errors?.pixelName?.message}</Text>}
      </S.InputGroup>

      <S.InputGroup>
        <S.Label>ID do pixel</S.Label>
        <S.Input placeholder="Ex: 123456789012345" {...pixelId} type="text" />
        {errors?.pixelId && <Text isFeedbackError>{errors?.pixelId?.message}</Text>}
      </S.InputGroup>

      <S.InputGroup>
        <S.Label>Token de acesso</S.Label>
        <S.Input placeholder="Ex: EAAJZCkZB3UvIBALZAjVZBwZDZD" {...accessToken} type="text" />
        {errors?.accessToken && <Text isFeedbackError>{errors?.accessToken?.message}</Text>}
      </S.InputGroup>

      <S.CheckboxGroup>
        <Controller
          control={control}
          name="send_credit_card_purchase"
          render={({ field }) => (
            <S.Checkbox
              value={field?.value?.toString()}
              onChange={() => field?.onChange(!field.value)}
            />
          )}
        />
        <S.CheckboxLabel htmlFor="send_credit_card_purchase">
          Marcar compras no cartão
        </S.CheckboxLabel>
      </S.CheckboxGroup>

      <S.CheckboxGroup>
        <Controller
          control={control}
          name="send_pix_purchase"
          render={({ field }) => (
            <S.Checkbox
              value={field?.value?.toString()}
              onChange={() => field?.onChange(!field.value)}
            />
          )}
        />
        <S.CheckboxLabel htmlFor="send_pix_purchase">Marcar compras no pix</S.CheckboxLabel>
      </S.CheckboxGroup>

      <S.CheckboxGroup>
        <Controller
          control={control}
          name="send_boleto_purchase"
          render={({ field }) => (
            <S.Checkbox
              value={field?.value?.toString()}
              onChange={() => field?.onChange(!field.value)}
            />
          )}
        />
        <S.CheckboxLabel htmlFor="send_boleto_purchase">Marcar compras no boleto</S.CheckboxLabel>
      </S.CheckboxGroup>
    </S.Wrapper>
  );
};

export default FacebookConversionForm;
