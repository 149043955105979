import React from 'react';

import * as S from './styles';

interface ISelectProps {
  onChange: (value: any) => void;
  data: { type: string; message: string }[];
  defaultSelected?: string;
}

const Select: React.FC<ISelectProps> = ({ onChange, data, defaultSelected }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedAction, setSelectedAction] = React.useState<string | null>(null);

  const toggleDropdown = (): void => setIsOpen(!isOpen);

  const handleSelectOption = (value: any): void => {
    onChange(value);
    setSelectedAction(value);
    toggleDropdown();
  };

  React.useEffect(() => {
    if (defaultSelected !== '') {
      if (defaultSelected) {
        setSelectedAction(defaultSelected);
      }
    }
  }, [defaultSelected]);

  const selectedMessage = React.useMemo(
    () =>
      selectedAction || defaultSelected !== ''
        ? data.find(({ type }) => type === selectedAction)?.message
        : 'Selecione o comportamento',
    [selectedAction, data, defaultSelected],
  );

  return (
    <S.Dropdown open={isOpen} onOpenChange={toggleDropdown}>
      <S.DropdownTrigger>
        {selectedAction || defaultSelected !== '' ? (
          <S.SelectedActionText>{selectedMessage}</S.SelectedActionText>
        ) : (
          selectedMessage
        )}
      </S.DropdownTrigger>

      <S.DropdownContentWrapper>
        {data.map(({ type, message }) => (
          <S.DropdownContent key={type} onClick={() => handleSelectOption(type)}>
            <S.DropdownItem>{message}</S.DropdownItem>
          </S.DropdownContent>
        ))}
      </S.DropdownContentWrapper>
    </S.Dropdown>
  );
};

export default Select;
