import React from 'react';

import { StoreOnboardingProvider } from '@store/context/onboarding/store/StoreOnboardingContext';

import Wrapper from '@components/pages/Onboarding/Store/Wrapper';

const Store: React.FC = () => {
  return (
    <StoreOnboardingProvider>
      <Wrapper />
    </StoreOnboardingProvider>
  );
};

export default Store;
