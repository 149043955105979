import React from 'react';

import * as S from './styles';

interface IOptionProps {
  value: string | number;
  disabled?: boolean;
  selected?: boolean;
  hidden?: boolean;
}

const Option: React.FC<IOptionProps> = ({ disabled, value, children, ...rest }) => {
  return (
    <S.Option disabled={disabled} value={value} {...rest}>
      {children}
    </S.Option>
  );
};

export default Option;
