import React from 'react';

import { ListUpsellsProvider } from '@store/common/upsell/ListUpsellContext';

import ListUpsells from '@components/pages/Dashboard/Upsell/ListUpsells';

const Upsell: React.FC = () => {
  return (
    <ListUpsellsProvider>
      <ListUpsells />
    </ListUpsellsProvider>
  );
};

export default Upsell;
