import React from 'react';

import * as S from './styles';

export interface ILinearProgressProps {
  qtdStepDone: number;
  quantitySteps: number;
}

const LinearProgress: React.FC<ILinearProgressProps> = ({ qtdStepDone, quantitySteps }) => {
  const barWidth = 158;
  const barHeight = 6;
  const progressWidth = (qtdStepDone / quantitySteps) * barWidth;

  return (
    <S.Wrapper>
      <svg width={barWidth} height={barHeight}>
        <S.LinearBackground x="0" y={barHeight / 2 - 3} width={barWidth} height="6" rx="3" />
        <S.LinearProgress x="0" y={barHeight / 2 - 3} width={progressWidth} height="6" rx="3" />
      </svg>
    </S.Wrapper>
  );
};
export default LinearProgress;
