import React from 'react';

import { useConfigDomain } from '@store/common/domain/ConfigDomainContext';

import * as S from './styles';

const ProviderOption: React.FC<any> = ({ provider }) => {
  const { handleSelectedProvider } = useConfigDomain();
  const { icon, provider: providerName } = provider;

  return (
    <S.ProviderOption onClick={() => handleSelectedProvider({ providerName })}>
      <S.IconContainer>
        <S.ProviderIcon src={icon} />
      </S.IconContainer>
      <S.ProviderName>{providerName}</S.ProviderName>
    </S.ProviderOption>
  );
};

export default ProviderOption;
