import * as yup from 'yup';

export const generateCheckoutScheme = yup.object().shape({
  acceptOfferText: yup.string().required('Este campo é obrigatório.'),
  declineOfferText: yup.string().required('Este campo é obrigatório.'),
  colorButtton: yup.string().required('Este campo é obrigatório.'),
  colorTextButton: yup.string().required('Este campo é obrigatório.'),
  colorTextDecline: yup.string().required('Este campo é obrigatório.'),
  acceptUpsellAction: yup
    .string()
    .oneOf(['EXTERNAL', 'ZOUTI'], 'O valor deve ser EXTERNAL ou ZOUTI.')
    .required('Este campo é obrigatório.'),
  externalUpsellUrl: yup
    .string()
    .url('Insira uma URL válida')
    .when('acceptUpsellAction', (acceptUpsellAction, schema) => {
      return acceptUpsellAction[0] === 'EXTERNAL'
        ? schema.required('Este campo é obrigatório.')
        : schema.notRequired();
    }),
  declineUpsellAction: yup
    .string()
    .oneOf(['EXTERNAL', 'ZOUTI'], 'O valor deve ser EXTERNAL ou ZOUTI.')
    .required('Este campo é obrigatório.'),
  externalDeclineUrl: yup
    .string()
    .url('Insira uma URL válida')
    .when('declineUpsellAction', (declineUpsellAction, schema) => {
      return declineUpsellAction[0] === 'EXTERNAL'
        ? schema.required('Este campo é obrigatório.')
        : schema.notRequired();
    }),
});
