import styled from 'styled-components/macro';

import ButtonBase from '@components/common/Inputs/Button';

export const CancelButton = styled(ButtonBase)`
  span {
    color: ${({ theme }) => theme.colors.neutral.dark};
  }
`;

export const SaveButton = styled(ButtonBase)``;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  max-width: 1400px;
  width: 100%;
  justify-content: flex-end;
`;

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 56px;
  background-color: ${({ theme }) => theme.colors.primary.lemon_10};
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
`;
