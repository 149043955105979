import AWS from './aws.svg';
import CLOUDFLARE from './cloudFlare.svg';
import GODADDY from './goDaddy.svg';
import HOSTGATOR from './hostGator.svg';
import HOSTINGER from './hostinger.svg';
import OTHERS from './others.svg';
import SHOPIFY from './shopify.svg';
import SQUARESPACE from './squarespace.svg';

export default {
  AWS,
  CLOUDFLARE,
  GODADDY,
  HOSTGATOR,
  HOSTINGER,
  OTHERS,
  SHOPIFY,
  SQUARESPACE,
};
