import styled from 'styled-components/macro';

import TableBase from '@components/common/utils/Table';
import TextBase from '@components/common/DataDisplay/Text';
import Radio from '@components/common/Inputs/Radio';

export const Table = styled(TableBase)``;

export const RadioButton = styled(Radio)`
  width: 16px;
  height: 16px;
`;

export const TextAndSubTextWrapper = styled.div`
  display: grid;
  max-width: 320px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Text = styled(TextBase)`
  overflow: hidden;
  text-overflow: ellipsis;

  color: ${({ theme }) => theme.colors.neutral.darkest};
  font-size: 14px;
`;

export const ButtonActionsWrapper = styled.div`
  min-width: 79px;
  justify-content: right;
  display: flex;
  gap: 8px;
`;
