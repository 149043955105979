import styled from 'styled-components';

export const Wrapper = styled.div`
  background: #fff;
  width: 100%;
  max-width: 200px;
  border-radius: 8px;
  height: fit-content;
  padding: 14px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    filter: brightness(0.9);
  }

  &:active {
    filter: brightness(0.8);
  }
`;
