import React from 'react';

import Content from '@components/pages/Dashboard/Home';
import { ShopifyProvider } from '@store/common/shopify/ShopifyContext';

const Home: React.FC = () => {
  return (
    <ShopifyProvider>
      <Content />
    </ShopifyProvider>
  );
};

export default Home;
