import React from 'react';

import { useListOrders } from '@store/common/order/ListOrders';

import EmptyData from '@components/common/utils/EmptyData';
import BottomPagination from '@components/common/utils/BottomPagination';
import FilterOptions from '@components/common/utils/FilterOptions';

import { orderFilter } from '@constants/common/Filter/orderFilterBar';

import ListSkeletonLoading from '@components/common/utils/ListSkeletonLoading';
import Body from './Body';

import * as S from './styles';

const Wrapper: React.FC = () => {
  const {
    isLoadingOrders,
    isOrdersError,
    orders,
    totalOrders,
    totalPages,
    handlePageChange,
    page,
    pageLimit,
    handlePageLimit,
    handleFilter,
  } = useListOrders();

  const isEmpty = orders.length === 0;

  if (isLoadingOrders) {
    return <ListSkeletonLoading />;
  }

  if (isOrdersError && !isLoadingOrders) {
    return <div>Erro...</div>;
  }

  if (isEmpty) {
    return (
      <EmptyData
        title="Nenhum pedido no momento"
        description="Quando surgirem pedidos, você será capaz de controlá-los por aqui."
      />
    );
  }

  return (
    <S.Wrapper>
      <S.Header />

      {/* <FilterOptions filterData={orderFilter} onFilter={handleFilter} /> */}

      <Body />

      <BottomPagination
        totalItems={totalOrders}
        totalPages={totalPages}
        handlePageChange={handlePageChange}
        pageLimit={pageLimit}
        handlePageLimit={handlePageLimit}
        page={page}
      />
    </S.Wrapper>
  );
};

export default Wrapper;
