import React, { ChangeEvent } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { INewSocialProofProvider } from '@domain/interfaces/store/common/socialProof/INewSocialProofProvider';

import { createSocialProofSchema } from '@helpers/validators/pages/dashboard/socialProof/socialProofSchemaValidation';

import { useErrorHandler } from '@store/context/common/ErrorHandlerContext';
import { useSocialProofService } from '@hooks/services/socialProof/useSocialProofService';
import { useToast } from '@store/context/common/ToastContext';
import { useAnalytics } from '@store/context/common/AnalyticsContext';

const NewSocialProofContext = React.createContext<INewSocialProofProvider | null>(null);

interface IFormDataProps {
  name: string;
  testimony: string;
  image_url?: string;
  image_path?: string;
  qtd_stars: number;
  photo: File;
}

export const NewSocialProofProvider: React.FC = ({ children }) => {
  const { createSocialProof, uploadSocialProofImage } = useSocialProofService();
  const { toast } = useToast();
  const navigate = useNavigate();
  const { accountId, checkoutId } = useParams();
  const { handleError } = useErrorHandler();
  const { analytics } = useAnalytics();

  const [fileImage, setFileImage] = React.useState<File | undefined>(undefined);
  const [uploadedFileImage, setUploadedFileImage] = React.useState<
    Record<string, string> | undefined
  >(undefined);
  const [rating, setRating] = React.useState<number>(0);
  const [isCreatingSocialProof, setIsCreatingSocialProof] = React.useState<boolean>(false);

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IFormDataProps>({
    resolver: yupResolver(createSocialProofSchema),
    defaultValues: {
      qtd_stars: 0,
    },
  });

  const handleImageChange = React.useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files && e.target.files.length > 0) {
        const mappedFiles = Array.from(e.target.files).map(file => ({
          file,
          size: file.size,
          name: file.name,
          errors: [],
          preview: URL.createObjectURL(file),
        }));

        const { file } = mappedFiles[0];

        if (file) {
          setFileImage(file);
          setValue('photo', file);

          try {
            const formData = new FormData();
            formData.append('file', file, file.name);

            const { data } = await uploadSocialProofImage({
              accountId,
              checkoutId,
              payload: formData,
            });

            setUploadedFileImage(data.object);
          } catch (responseError: any) {
            handleError(responseError);
          }
        }
      }
    },
    [accountId, checkoutId, setValue, uploadSocialProofImage, handleError],
  );

  const onSubmit = React.useCallback(
    async (formData: FieldValues) => {
      if (!uploadedFileImage) return;

      const data = new FormData();

      data.append('name', formData.name);
      data.append('testimony', formData.testimony);
      data.append('image_path', uploadedFileImage.path);
      data.append('image_url', uploadedFileImage.url);
      data.append('qtd_stars', rating.toString());

      setIsCreatingSocialProof(true);

      try {
        await createSocialProof({ payload: data, accountId, checkoutId });

        analytics.track('Social Proof Created');

        setIsCreatingSocialProof(false);
        setRating(0);
        reset();
        toast.success('Prova social criada com sucesso');
        navigate(`/${accountId}/${checkoutId}/dashboard/checkout/social-proofs`);
      } catch (error) {
        setIsCreatingSocialProof(false);
        handleError(error);
      }
    },
    [
      navigate,
      createSocialProof,
      handleError,
      reset,
      rating,
      toast,
      accountId,
      checkoutId,
      uploadedFileImage,
      analytics,
    ],
  );

  return (
    <NewSocialProofContext.Provider
      value={{
        isCreatingSocialProof,
        rating,
        setRating,
        handleImageChange,
        fileImage,
        register,
        reset,
        handleSubmit,
        onSubmit,
        errors,
        setValue,
      }}
    >
      {children}
    </NewSocialProofContext.Provider>
  );
};

export const useNewSocialProof = (): INewSocialProofProvider => {
  const context = React.useContext(NewSocialProofContext);

  if (!context) {
    throw new Error('useNewSocialProof must be used within provider');
  }

  return context;
};
