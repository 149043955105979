import styled, { css } from 'styled-components/macro';

import { ESearchInputSize } from '@domain/enums/components/common/ESearchInput';

interface IInputWrapperProps {
  disabled?: boolean;
  size?: ESearchInputSize;
  value?: string;
  isFocused?: boolean;
}

export const InputWrapper = styled.div<IInputWrapperProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadius.small};
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  padding-left: 24px;
  padding-right: 24px;
  box-sizing: border-box;
  background-color: #fff;
  transition: all 0.3s;

  &:hover {
    border-color: ${({ theme }) => theme.colors.neutral.dark};
  }

  &:active {
    border-color: ${({ theme }) => theme.colors.neutral.black};
  }

  ${({ isFocused }) =>
    isFocused &&
    css`
      border-color: ${({ theme }) => theme.colors.primary.base};
    `}

  ${({ value }) =>
    value &&
    css`
      border-color: ${({ theme }) => theme.colors.neutral.light};
    `}

  ${({ size }) =>
    size === ESearchInputSize.SMALL &&
    css`
      height: 36px;
    `}

  ${({ size }) =>
    size === ESearchInputSize.MEDIUM &&
    css`
      height: 40px;
    `}

  ${({ size }) =>
    size === ESearchInputSize.LARGE &&
    css`
      height: 56px;
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${({ theme }) => theme.colors.neutral.lightest};
      border-color: ${({ theme }) => theme.colors.neutral.lightest};

      &:hover {
        border-color: ${({ theme }) => theme.colors.neutral.lightest};
      }

      &:active {
        border-color: ${({ theme }) => theme.colors.neutral.lightest};
      }
    `}
`;

export const Input = styled.input`
  height: 100%;
  width: 100%;
  color: ${({ theme }) => theme.colors.neutral.darker};
  font-family: 'Inter';
  font-weight: 400;
  font-size: 16px;

  &:active {
    outline: none;
  }

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.neutral.base};
    font-family: 'Inter';
    font-weight: 400;
    font-size: 14px;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.colors.neutral.lightest};
  }
`;

export const ClearValueButton = styled.button`
  margin-right: 24px;
  border: unset;
  padding: unset;
  background: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    filter: brightness(0.9);
  }

  &:active {
    filter: brightness(0.8);
  }
`;
