import React from 'react';

import { formatCurrencyParts } from '@utils/common/formatCurrency';

import { IBalanceProps } from '@domain/interfaces/hooks/services/statementTransaction/IStatementTransactionService';

import * as S from './styles';

interface IBalanceCardProps {
  balances: IBalanceProps;
}

const config = {
  available: {
    title: 'Saldo disponível',
    color: '#7FD4A3',
    icon: <S.CheckCircleIcon />,
  },
  pending: {
    title: 'Saldo a receber',
    color: '#FDEFCD',
    icon: <S.ClockCountDownIcon />,
  },
  reserved: {
    title: 'Reserva financeira',
    color: '#D5D5D5',
    icon: <S.PiggyBankIcon />,
  },
  total: {
    title: 'Saldo total',
    color: '#D5D5D5',
    icon: <S.CurrencyDollarSimpleIcon />,
  },
};

const order = ['available', 'pending', 'reserved', 'total'];

const BalanceCard: React.FC<IBalanceCardProps> = ({ balances }) => {
  return (
    <S.Wrapper>
      {order?.map(key => {
        const value = balances[key as keyof typeof balances];
        const { title, color, icon } = config[key as keyof typeof config];

        const { symbol, amount } = formatCurrencyParts(value / 100);
        const isNegative = amount?.includes('-');
        const formattedSymbol = isNegative ? `-${symbol}` : symbol;
        const formattedAmount = amount?.replace('-', '');

        return (
          <S.Card key={key}>
            <S.CardHeader>
              <S.IconContainer color={color}>{icon}</S.IconContainer>
              <S.CardTitle>{title}</S.CardTitle>
            </S.CardHeader>
            <S.CardContent>
              <S.CurrencySymbol isnegative={isNegative}>{formattedSymbol}</S.CurrencySymbol>
              <S.BalanceValue isnegative={isNegative}>{formattedAmount}</S.BalanceValue>
            </S.CardContent>
          </S.Card>
        );
      })}
    </S.Wrapper>
  );
};

export default BalanceCard;
