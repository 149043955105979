import React from 'react';

import { SignUpProvider } from '@store/context/public/signUp/SignUpContext';

import SignUpWrapper from '@components/pages/Public/SignUp/Wrapper';

const SignUp: React.FC = () => {
  return (
    <SignUpProvider>
      <SignUpWrapper />
    </SignUpProvider>
  );
};

export default SignUp;
