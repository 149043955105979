import styled from 'styled-components/macro';

import LabelBase from '@components/common/DataDisplay/Label';
import TextFieldBase from '@components/common/Inputs/TextField';
import InputGroupBase from '@components/common/Inputs/InputGroup';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  gap: 24px;
`;

export const Editor = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  padding: 24px;
  gap: 24px;
  background-color: #fff;
`;

export const PreviewWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #fff;
`;

export const Label = styled(LabelBase)`
  margin: 0;
  cursor: pointer;
`;

export const InputGroup = styled(InputGroupBase)`
  gap: 8px;
`;
export const TextField = styled(TextFieldBase)``;
