import React, { useRef } from 'react';
import { useTheme } from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';

import { useNewPayout } from '@store/common/Payout/NewPayoutContext';

import { currencyFormatter } from '@helpers/utils/common/number';
import { formatCurrencyParts } from '@utils/common/formatCurrency';

import PIX from '@components/icons/Pix';
import { banks } from '@constants/common/banks';

import { useGetPayouts } from '@store/common/Payout/GetPayoutsContext';
import InfoText from './InfoText';
import ProviderOption from '../../SelectBankAccount';

import * as S from './styles';

const Form: React.FC = () => {
  const navigate = useNavigate();
  const { accountId, checkoutId } = useParams();

  const checkboxRef = useRef<HTMLInputElement>(null);
  const radioRefTed = useRef<HTMLInputElement>(null);
  const radioRefPix = useRef<HTMLInputElement>(null);

  const theme = useTheme();
  const {
    reset,
    errors,
    isValid,
    setValue,
    resetField,
    setNextStep,
    handleSubmit,
    bankAccounts,
    setIsOpenModal,
    isLoadingSimulate,
    bankAccountSelected,
    transferValueRegister,
    setBankAccountSelected,
    createSimulatePayout,
  } = useNewPayout();
  const { balances } = useGetPayouts();
  const { amount } = formatCurrencyParts(balances?.available / 100);
  const [transferType, setTransferType] = React.useState<string | undefined>();

  const [isCheckedAvailableBalance, setIsCheckedAvailableBalance] = React.useState<boolean>(false);

  const onSimulatePayout = async (): Promise<void> => {
    await createSimulatePayout({ type: transferType });
  };

  const onPriceChange = React.useCallback(
    event => {
      setIsCheckedAvailableBalance(false);
      const formattedValue = currencyFormatter(event.target.value);

      event.target.value = formattedValue;

      transferValueRegister.onChange(event);
    },
    [transferValueRegister],
  );

  const checkHandle = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setTransferType(event.currentTarget.value);
      setValue('transfer_type', event.currentTarget.value, { shouldValidate: true });

      if (event.currentTarget.value === 'PIX') {
        resetField('bank_code');
        resetField('account_number');
        resetField('routing_number');
      }
    },
    [setValue, resetField],
  );

  const onChangeAvailableBalance = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsCheckedAvailableBalance(event.target.checked);
      if (event.target.checked) {
        setValue('transfer_value', amount, { shouldValidate: true });
      } else {
        resetField('transfer_value');
      }
    },
    [setValue, amount, resetField],
  );

  const handleWrapperClick = (radioRef: React.RefObject<HTMLInputElement>): void => {
    if (radioRef.current) {
      radioRef.current.click();
    }
  };

  const handleWrapperCheckboxClick = (): void => {
    if (checkboxRef.current) {
      checkboxRef.current.click();
    }
  };

  const handleCancel = (): void => {
    reset();
    setNextStep(0);
    setBankAccountSelected({ bank_code: '', account_number: '', routing_number: '', id: '' });
    setIsOpenModal(state => !state);
  };

  const currentProvider = bankAccounts?.find(
    provider => provider?.bank_code === bankAccountSelected?.bank_code,
  );

  const bankName = banks?.find(bank => bank.code?.toString() === currentProvider?.bank_code)?.name;

  const onNewBankAccountClick = (): void => {
    navigate(`/${accountId}/${checkoutId}/dashboard/bank-accounts/new`);
  };

  return (
    <S.Form onSubmit={handleSubmit(onSimulatePayout)}>
      <S.AvailableBalanceGroup>
        <S.Label>Selecione ou digite a quantia que deseja transferir:</S.Label>
        <S.CheckboxWrapper onClick={handleWrapperCheckboxClick} checked={isCheckedAvailableBalance}>
          <S.CheckboxGroup>
            <S.Checkbox
              id="available_balance"
              checked={isCheckedAvailableBalance}
              innerRef={checkboxRef}
              onChange={onChangeAvailableBalance}
            />
            <S.CheckboxLabel onClick={e => e.stopPropagation()} htmlFor="available_balance">
              Valor disponível
            </S.CheckboxLabel>
          </S.CheckboxGroup>
          <S.AvailableBalanceWrapper>
            <S.AvailableBalanceCurrencySymbol>R$</S.AvailableBalanceCurrencySymbol>
            <S.AvailableBalanceValue>{amount}</S.AvailableBalanceValue>
          </S.AvailableBalanceWrapper>
        </S.CheckboxWrapper>
        <S.InputGroup>
          <S.ValueWrapperCouple>
            <S.CurrencyLabel>R$</S.CurrencyLabel>
            <S.Input {...transferValueRegister} onChange={onPriceChange} type="text" />
          </S.ValueWrapperCouple>
          {errors?.transfer_value && (
            <S.MessageError isFeedbackError>{errors?.transfer_value.message}</S.MessageError>
          )}
        </S.InputGroup>
      </S.AvailableBalanceGroup>
      <S.RadioLabelAndRadioButtonWrapper>
        <S.Label>Como quer transferir?</S.Label>
        <S.InputGroup>
          <S.RadioContainer>
            <S.RadioWrapper
              onClick={() => handleWrapperClick(radioRefTed)}
              isSelected={transferType === 'BANK_ACCOUNT'}
            >
              <S.RadioGroup>
                <S.ContentIcon>
                  <S.BankIcon />
                </S.ContentIcon>
                <S.RadioLabel>Conta bancária</S.RadioLabel>
              </S.RadioGroup>
              <S.Radio
                ref={radioRefTed}
                name="transfer_type"
                value="BANK_ACCOUNT"
                onChange={checkHandle}
                checked={transferType === 'BANK_ACCOUNT'}
              />
            </S.RadioWrapper>
            <S.RadioWrapper
              onClick={() => handleWrapperClick(radioRefPix)}
              isSelected={transferType === 'PIX'}
            >
              <S.RadioGroup>
                <S.ContentIcon>
                  <PIX size="15" fillColor={theme.colors.neutral.darkest} />
                </S.ContentIcon>
                <S.RadioLabel>Chave Pix</S.RadioLabel>
              </S.RadioGroup>
              <S.Radio
                ref={radioRefPix}
                name="transfer_type"
                value="PIX"
                onChange={checkHandle}
                checked={transferType === 'PIX'}
              />
            </S.RadioWrapper>
          </S.RadioContainer>
          {errors?.transfer_type && (
            <S.MessageError isFeedbackError>{errors?.transfer_type.message}</S.MessageError>
          )}
        </S.InputGroup>
        {transferType === 'BANK_ACCOUNT' && (
          <S.InputGroup>
            <S.Dropdown>
              <S.DropdownTrigger isselectedbank={bankAccountSelected?.bank_code}>
                {bankAccountSelected?.bank_code ? (
                  <S.SelectedBankWrapper>
                    <S.BankName>{bankName}</S.BankName>
                    <S.SelectedBankAccountGroup>
                      <S.AccountNumber>CC: {currentProvider?.account_number} .</S.AccountNumber>
                      <S.RoutingNumber> Ag: {currentProvider?.routing_number}</S.RoutingNumber>
                    </S.SelectedBankAccountGroup>
                  </S.SelectedBankWrapper>
                ) : (
                  'Selecione a conta bancária'
                )}
              </S.DropdownTrigger>

              <S.DropdownContent align="center" sideOffset={11}>
                {bankAccounts?.map(provider => (
                  <ProviderOption provider={provider} key={provider.id} />
                ))}
                <S.AddNewBankAccount onClick={onNewBankAccountClick}>
                  <S.ContentBankIcon>
                    <S.BankIcon />
                  </S.ContentBankIcon>
                  <S.AddNewBankAccountText>Adicionar nova conta bancária</S.AddNewBankAccountText>
                  <S.CareRightIcon />
                </S.AddNewBankAccount>
              </S.DropdownContent>
            </S.Dropdown>
            {errors?.bank_code && (
              <S.MessageError isFeedbackError>{errors?.bank_code.message}</S.MessageError>
            )}
          </S.InputGroup>
        )}
        {(transferType === 'PIX' || transferType === 'BANK_ACCOUNT') && (
          <InfoText transferType={transferType} />
        )}
      </S.RadioLabelAndRadioButtonWrapper>
      <S.ModalAFooter>
        <S.ButtonsAction>
          <S.CancelButton type="button" onClick={handleCancel}>
            Cancelar
          </S.CancelButton>
          <S.SaveButton disabled={!isValid} isLoading={isLoadingSimulate} type="submit">
            Prosseguir
          </S.SaveButton>
        </S.ButtonsAction>
      </S.ModalAFooter>
    </S.Form>
  );
};

export default Form;
