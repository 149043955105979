import React from 'react';

import { useTheme } from 'styled-components';
import { CaretDoubleLeft, CaretDoubleRight, CaretLeft, CaretRight } from 'phosphor-react';

import ReactPaginate from 'react-paginate';

import * as S from './styles';

interface IPaginateProps {
  pageCount: number;
  forcePage?: number;
  onPageChange: (selectedItem: { selected: number }) => void;
}

const smallDevice = window.matchMedia('(max-width: 500px)').matches;

const Paginate: React.FC<IPaginateProps> = ({ pageCount, onPageChange, forcePage, ...rest }) => {
  const theme = useTheme();

  return (
    <S.PaginateWrapper {...rest}>
      <S.PreviousButton onClick={() => onPageChange({ selected: 0 })}>
        <CaretDoubleLeft size={16} color={theme.colors.neutral.darker} />
      </S.PreviousButton>

      <ReactPaginate
        forcePage={forcePage}
        pageCount={pageCount}
        previousLabel={<CaretLeft size={16} color={theme.colors.neutral.darker} weight="bold" />}
        nextLabel={<CaretRight size={16} color={theme.colors.neutral.darker} weight="bold" />}
        onPageChange={onPageChange}
        marginPagesDisplayed={smallDevice ? 1 : 2}
        pageRangeDisplayed={smallDevice ? 1 : 2}
        containerClassName="pagination"
        breakClassName="pagination-item"
        breakLinkClassName="page-link"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        disabledClassName="disabled"
        activeClassName="active"
        nextClassName="page-item"
        previousClassName="page-item"
        nextLinkClassName="page-link icon-prev-next"
        previousLinkClassName="page-link icon-prev-next"
      />

      <S.NextButton onClick={() => onPageChange({ selected: pageCount - 1 })}>
        <CaretDoubleRight size={16} color={theme.colors.neutral.darker} />
      </S.NextButton>
    </S.PaginateWrapper>
  );
};

export default React.memo(Paginate);
