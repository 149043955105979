import React, { useEffect } from 'react';
import { CaretDown } from 'phosphor-react';
import { useTheme } from 'styled-components/macro';

import { EButtonSize, EButtonVariant } from '@domain/enums/components/common/EButton';
import { EWebhookEvent } from '@domain/enums/common/webhook/EWebhook';

import { useEditWebhook } from '@store/common/webhook/EditWebhookContext';

import * as S from './styles';

interface IWebhookEventsSelectProps {
  onChange: (events: Array<EWebhookEvent>) => void;
}

const WebhookEventsSelect: React.FC<IWebhookEventsSelectProps> = ({ onChange }) => {
  const theme = useTheme();
  const { webhookEvents } = useEditWebhook();

  const [isSelectOpen, setIsSelectOpen] = React.useState<boolean>(false);
  const [selectedEvents, setSelectedEvents] = React.useState<Array<EWebhookEvent>>([]);
  const [selectAllChecked, setSelectAllChecked] = React.useState<boolean>(false);

  const onSelectOpen = React.useCallback((open: boolean) => {
    setIsSelectOpen(open);
  }, []);

  const onCheckboxChange = React.useCallback(
    event => {
      if (event.checked === undefined) return;

      if (event.checked) {
        setSelectedEvents(previousState => [...previousState, event.id]);
      } else {
        setSelectedEvents(previousState => previousState.filter(state => state !== event.id));
      }

      const totalSelected = selectedEvents.length + (event.checked ? 1 : -1);
      setSelectAllChecked(totalSelected === Object.values(EWebhookEvent).length);
    },
    [selectedEvents],
  );

  const onCancelClick = React.useCallback(() => {
    setSelectedEvents([]);
    onChange([]);
    setIsSelectOpen(false);
  }, [onChange]);

  const onConfirmClick = React.useCallback(() => {
    onChange(selectedEvents);
    setIsSelectOpen(false);
  }, [onChange, selectedEvents]);

  const handleSelectAllChange = React.useCallback(() => {
    if (!selectAllChecked) {
      setSelectAllChecked(true);
      setSelectedEvents([...Object.values(EWebhookEvent)]);
    } else {
      setSelectAllChecked(false);
      setSelectedEvents([]);
    }
  }, [selectAllChecked]);

  useEffect(() => {
    setSelectedEvents(webhookEvents);
  }, [webhookEvents]);

  const options: Record<EWebhookEvent, string> = {
    [EWebhookEvent.ORDER_UPDATED]: 'Pedido atualizado',
    [EWebhookEvent.ABANDONED_CART]: 'Carrinho abandonado',
    [EWebhookEvent.ORDER_CREATED]: 'Pedido criado',
    [EWebhookEvent.ORDER_PAID]: 'Pedido pago',
  };

  return (
    <S.Dropdown onOpenChange={onSelectOpen} open={isSelectOpen}>
      <S.Dropdown.Trigger>
        <S.Select>
          <>
            {selectedEvents.length > 0 ? (
              <>
                {selectedEvents?.slice(0, 1).map((event, index) => (
                  <S.SelectEventName key={index.toFixed()}>
                    <S.DefaultText>{options[event]}</S.DefaultText>
                    {selectedEvents.length > 1 && ` +${selectedEvents.length - 1}`}
                  </S.SelectEventName>
                ))}
              </>
            ) : (
              <S.DefaultText>Selecione os eventos</S.DefaultText>
            )}
          </>
          <CaretDown size={16} color={theme.colors.neutral.darker} />
        </S.Select>
      </S.Dropdown.Trigger>

      <S.Dropdown.Content>
        <S.Content>
          <S.CheckboxGroup htmlFor="select-all-checkbox">
            <S.Checkbox
              id="select-all-checkbox"
              name="select-all-checkbox"
              checked={selectedEvents.length === 4}
              onChange={handleSelectAllChange}
            />
            <S.CheckboxLabel>Selecionar todos</S.CheckboxLabel>
          </S.CheckboxGroup>
          <S.OptionsWrapper>
            {Object.values(EWebhookEvent).map((event, index) => (
              <S.Option key={index.toFixed()} htmlFor={event}>
                <S.Checkbox
                  id={event}
                  checked={selectedEvents.some(selected => selected === event)}
                  onChange={onCheckboxChange}
                />
                <S.OptionText>{options[event]}</S.OptionText>
              </S.Option>
            ))}
          </S.OptionsWrapper>

          <S.ButtonsWrapper>
            <S.CancelButton
              variant={EButtonVariant.SECONDARY}
              size={EButtonSize.SMALL}
              onClick={onCancelClick}
            >
              Limpar
            </S.CancelButton>

            <S.ConfirmButton size={EButtonSize.SMALL} onClick={onConfirmClick}>
              Aplicar
            </S.ConfirmButton>
          </S.ButtonsWrapper>
        </S.Content>
      </S.Dropdown.Content>
    </S.Dropdown>
  );
};

export default WebhookEventsSelect;
