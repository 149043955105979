import React from 'react';

import { useListWebhooks } from '@store/common/webhook/ListWebhooksContext';
import * as S from './styles';

// TODO: O componente de modal precisa ser ajustado para também ser um modal de delete
const CustomModal: React.FC = () => {
  const { setIsOpenModal, isOpenModal } = useListWebhooks();
  const isLoadingEditShipping = false;

  const onSuccess = (): void => {
    console.log('salvar');
  };

  return (
    <S.Modal
      title="Excluir Webhook"
      cancelText="Cancelar"
      successText="Salvar"
      isSuccessButtonLoading={isLoadingEditShipping}
      onSuccess={onSuccess}
      toggle={() => setIsOpenModal(state => !state)}
      isOpen={isOpenModal}
    />
  );
};

export default CustomModal;
