import React from 'react';

import { EHeadAlign } from '@domain/enums/components/common/ETable';
import { IStatementTransactions } from '@domain/interfaces/common/statementTransactions/IStatementTransactions';
import { EBalanceType } from '@domain/interfaces/store/common/statementTransactions/IGetStatementTransactionsProvider';

import { formatDateParts } from '@utils/common/date';
import { formatCurrency } from '@utils/common/formatCurrency';

import { EPayoutType } from '@domain/enums/common/payouts/EPayoutType';
import Badge from './Badge';

import * as S from './styles';

interface ITableRowProps {
  statementTransaction: IStatementTransactions;
  selectedBalance: EBalanceType;
}

const TableRow: React.FC<ITableRowProps> = ({ statementTransaction, selectedBalance }) => {
  const { parent_id, created_at, type, net, available_at, _payment, _anticipation, _metadata } =
    statementTransaction;

  const formatDate = formatDateParts(created_at);
  const availableAtFormatted = formatDateParts(available_at);
  const formattedAmount = formatCurrency(net / 100);
  const getPluralInstallments = (installments?: number): string =>
    installments === 1 ? 'parcela' : 'parcelas';

  const isNegative = formattedAmount?.includes('-');
  const payoutMethod =
    _metadata?.payout_method === EPayoutType.BANK_ACCOUNT ? 'Conta Bancária' : 'PIX';

  return (
    <S.Table.Row>
      <S.Table.Data>
        <S.Text>{parent_id}</S.Text>
      </S.Table.Data>

      {selectedBalance === EBalanceType.Pending && (
        <S.Table.Data>
          <S.Text>{formatDate}</S.Text>
        </S.Table.Data>
      )}

      <S.Table.Data>
        <S.Text>{availableAtFormatted}</S.Text>
      </S.Table.Data>

      <S.Table.Data align={EHeadAlign.CENTER}>
        <Badge status={type} />
      </S.Table.Data>

      <S.TableDataAmount align={EHeadAlign.RIGHT}>
        <S.TextAmount isnegative={isNegative}>{formattedAmount}</S.TextAmount>
        {_payment && (
          <S.TextInstallments>
            {_payment?.current_installment} de {_payment?.total_installments}{' '}
            {getPluralInstallments(_payment?.total_installments)}
          </S.TextInstallments>
        )}
        {_anticipation && (
          <S.TextInstallments>
            {_anticipation?.current_installment} de {_anticipation?.total_installments}{' '}
            {getPluralInstallments(_anticipation?.total_installments)}
          </S.TextInstallments>
        )}
        {_metadata && <S.TextInstallments>{payoutMethod}</S.TextInstallments>}
      </S.TableDataAmount>
    </S.Table.Row>
  );
};

export default TableRow;
