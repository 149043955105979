import React from 'react';

import PageHeader from '@components/common/utils/PageHeader';

import * as S from './styles';

const Header: React.FC = () => {
  return (
    <S.Wrapper>
      <PageHeader
        title="Gerar Upsell"
        description="Configure e gere o código a ser usado no Upsell"
      />
    </S.Wrapper>
  );
};
export default Header;
