import React, { ReactNode, useState } from 'react'; // Importe o React
// eslint-disable-next-line import/no-extraneous-dependencies
import PlatformOption, { IPlatform } from './plataform';
import * as S from './styles';

interface ISelectProps {
  onChange: any;
  data: Array<IPlatform>;
  defaultSelected?: {
    image?: string;
    product_images?: { url?: string };
    products?: { name?: string };
    name?: string;
  } | null;
}

const InputSelectImage: React.FC<ISelectProps> = ({
  onChange,
  data,
  defaultSelected,
}: ISelectProps): any => {
  const [selectedPlatform, setPlatformSelected]: any = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const closeDropdown = (): any => {
    setIsOpen(false);
  };

  const handleSelectedPlatform = (event: string): void => {
    const item: any = data.find(({ id }: IPlatform) => id === event);
    closeDropdown();
    setPlatformSelected(item);
    onChange(item);
  };

  return (
    <S.Dropdown open={isOpen} onOpenChange={() => setIsOpen(!isOpen)}>
      <S.DropdownTrigger isDropdownOpen>
        {defaultSelected || selectedPlatform !== null ? (
          <>
            <S.PlatformIcon
              src={
                defaultSelected?.image ||
                defaultSelected?.product_images?.url ||
                selectedPlatform?.image
              }
            />
            <S.PlatformName>
              {defaultSelected?.name || defaultSelected?.products?.name || selectedPlatform?.name}
            </S.PlatformName>
          </>
        ) : (
          'Selecione'
        )}
      </S.DropdownTrigger>

      <S.DropdownContent align="center" sideOffset={11}>
        {data.map(
          (option: IPlatform): ReactNode => (
            <PlatformOption
              platform={option}
              key={option.id}
              handleSelectedPlatform={handleSelectedPlatform}
            />
          ),
        )}
      </S.DropdownContent>
    </S.Dropdown>
  );
};

export default InputSelectImage;
