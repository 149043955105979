import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { Star, User, ImageSquare, Trash } from 'phosphor-react';

import { useUpdateSocialProof } from '@store/common/socialProof/UpdateSocialProofContext';
import { EButtonVariant } from '@domain/enums/components/common/EButton';

import Text from '@components/common/DataDisplay/Text';
import * as S from './styles';

const Body: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { accountId, checkoutId } = useParams();

  const {
    socialProof,
    handleSubmit,
    isUpdatingSocialProof,
    handleImageChange,
    fileImage,
    rating,
    setRating,
    register,
    setValue,
    onSubmit,
    errors,
    deleteCurrentSocialProof,
    selectedFileImage,
  } = useUpdateSocialProof();

  const { id, name, qtd_stars, testimony, image_path, photo } = socialProof;

  const onCancelButtonClick = (): void => {
    navigate(`/${accountId}/${checkoutId}/dashboard/checkout/social-proofs`);
  };

  React.useEffect(() => {
    setValue('name', name);
    setValue('testimony', testimony);
    setValue('qtd_stars', qtd_stars);
    setValue('image_path', image_path);
  }, [setValue, name, testimony, qtd_stars, image_path, photo]);

  const fileImagePreview = selectedFileImage ? URL.createObjectURL(selectedFileImage) : fileImage;

  return (
    <S.Form onSubmit={handleSubmit(onSubmit)}>
      <S.Wrapper>
        <S.HeaderCard>
          <S.AvatarInputWrapper>
            <S.IconWrapper>
              {!fileImagePreview ? (
                <User size={24} weight="bold" color={theme.colors.primary.dark_green_40} />
              ) : (
                <S.ImagePreview src={fileImagePreview} alt="Avatar preview" />
              )}
            </S.IconWrapper>
            <S.InputAndInfoWrapper>
              <S.CustomFileInput>
                <ImageSquare size={20} color={theme.colors.neutral.darker} />
                Trocar Foto
                <S.InputFile {...register('photo')} onChange={handleImageChange} type="file" />
              </S.CustomFileInput>
              <S.Info>Tamanho padrão: 150x150px.</S.Info>
              {errors?.photo && <Text isFeedbackError>{errors?.photo?.message}</Text>}
            </S.InputAndInfoWrapper>
          </S.AvatarInputWrapper>
          <S.StarsRatingGroup>
            <S.Label>Quantidade de estrelas</S.Label>
            <S.StarsRatingWrapper>
              {[...Array(5)].map((_star, index) => {
                const currentRate = index + 1;
                return (
                  <S.StarsRatingLabel key={index.toFixed()}>
                    <S.StarsRatingInput
                      {...register('qtd_stars')}
                      type="radio"
                      name="rating"
                      defaultValue={rating}
                      checked={currentRate === rating}
                      onChange={() => setRating(currentRate)}
                    />
                    <Star
                      size={28}
                      color={
                        currentRate <= rating
                          ? theme.colors.secondary.base
                          : theme.colors.neutral.lightest
                      }
                      weight="fill"
                    />
                  </S.StarsRatingLabel>
                );
              })}
            </S.StarsRatingWrapper>
            {errors?.qtd_stars && <Text isFeedbackError>{errors?.qtd_stars?.message}</Text>}
          </S.StarsRatingGroup>
        </S.HeaderCard>
        <S.InputGroup>
          <S.Label>Nome do cliente</S.Label>
          <S.Input
            defaultValue={name}
            {...register('name', { value: name })}
            placeholder="Digite o nome do cliente"
            type="text"
          />
          {errors?.name && <Text isFeedbackError>{errors?.name?.message}</Text>}
        </S.InputGroup>
        <S.InputGroup>
          <S.Textarea
            {...register('testimony', { value: testimony })}
            label="Depoimento"
            defaultValue={testimony}
            placeholder="Digite o depoimento do cliente"
          />
          {errors?.testimony && <Text isFeedbackError>{errors?.testimony?.message}</Text>}
        </S.InputGroup>
      </S.Wrapper>
      <S.ButtonsWrapper>
        <S.DeleteSocialProofButton onClick={() => deleteCurrentSocialProof(id)}>
          <Trash size={20} />
          Excluir prova social
        </S.DeleteSocialProofButton>
        <S.WrapperCancelAndSubmitButtons>
          <S.CancelButton onClick={onCancelButtonClick} variant={EButtonVariant.SECONDARY}>
            Cancelar
          </S.CancelButton>
          <S.SubmitButton isLoading={isUpdatingSocialProof} type="submit">
            Salvar
          </S.SubmitButton>
        </S.WrapperCancelAndSubmitButtons>
      </S.ButtonsWrapper>
    </S.Form>
  );
};

export default Body;
