import React from 'react';

import Content from '@components/pages/Dashboard/Upsell/NewUpsell';
import { GetProductProvider } from '@store/common/product/GetProductContext';
import { NewUpsellProvider } from '@store/common/upsell/NewUpsellContext';

const NewUpsell: React.FC = () => {
  return (
    <GetProductProvider>
      <NewUpsellProvider>
        <Content />
      </NewUpsellProvider>
    </GetProductProvider>
  );
};

export default NewUpsell;
