import React from 'react';

import { EBusinessModel } from '@domain/enums/common/onboarding/EUser';

import { useUserCheckout } from '@store/context/common/UserCheckout';

import CurrentTheme from './CurrentTheme';
import LibraryTheme from './ThemeLibrary';

import * as S from './styles';

const Theme: React.FC = () => {
  const { currentUserCheckout } = useUserCheckout();

  return (
    <S.Wrapper>
      <CurrentTheme />

      {currentUserCheckout?.business_type === EBusinessModel.DROPSHIPPING_GLOBAL && (
        <LibraryTheme />
      )}
    </S.Wrapper>
  );
};

export default Theme;
