import styled from 'styled-components/macro';
import { ArrowSquareOut, MonitorPlay } from 'phosphor-react';

import LabelBase from '@components/common/DataDisplay/Label';
import LinkBase from '@components/common/Navigation/Link';
import TextBase from '@components/common/DataDisplay/Text';
import DropdownBase from '@components/common/utils/Dropdown';

import selectArrow from '@assets/images/components/select/CaretDown.svg';

export interface IProviderSelectProps {
  isDropdownOpen: boolean;
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  background-color: ${({ theme }) => theme.colors.neutral.white};
`;
export const Title = styled(TextBase)`
  font-size: 1.6rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.neutral.darkest};
`;
export const Description = styled(TextBase)`
  padding: 12px 0 24px 0;
  font-size: 1.4rem;
`;

export const WrapperSelect = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

export const Label = styled(LabelBase)`
  font-size: 1.4rem;
  font-weight: 500;
`;

export const DropdownContent = styled(DropdownBase.Content)`
  overflow-y: visible;
  width: 549px;
  padding: 8px 0;
  z-index: 999999999;
`;

export const DropdownTrigger = styled(DropdownBase.Trigger)<IProviderSelectProps>`
  display: flex;
  align-items: center;
  gap: 8px;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.neutral.white};
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  padding: 8px 16px;
  position: relative;
  text-align: left;
  font-size: 1.4rem;
  transition: all 0.3s;
  width: 100%;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.neutral.base};

  &:after {
    content: url(${selectArrow});
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: ${({ theme }) => theme.spacing.small_16};
  }
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: ${({ theme }) => theme.borderRadius.small};
  background: ${({ theme }) => theme.colors.neutral.gray_white};
`;

export const ProviderIcon = styled.img``;

export const ProviderName = styled(TextBase)`
  font-size: 1.4rem;
`;

export const Dropdown = styled(DropdownBase)``;

export const ProviderAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const WrapperAccessLink = styled(LinkBase)`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.small_8};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  border: 1px solid ${({ theme }) => theme.colors.neutral.light};
  padding: 10px 16px;
  text-decoration: none !important;

  &:hover {
    filter: brightness(0.9);
  }

  &:active {
    filter: brightness(0.8);
  }
`;

export const WrapperTutorialLink = styled(LinkBase)`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.small_8};
  text-decoration: none !important;
`;

export const TutorialLinkText = styled(TextBase)`
  color: ${({ theme }) => theme.colors.primary.green_lemon};
  font-size: 1.6rem;
  font-weight: 400;
`;

export const AccessLinkText = styled(TextBase)`
  color: ${({ theme }) => theme.colors.neutral.darker};
  font-size: 1.6rem;
  font-weight: 500;
`;

export const ArrowSquareOutIcon = styled(ArrowSquareOut).attrs(props => ({
  size: 15,
  weight: 'bold',
  color: props.theme.colors.neutral.darker,
}))``;

export const MonitorPlayIcon = styled(MonitorPlay).attrs(props => ({
  size: 17,
  weight: 'bold',
  color: props.theme.colors.primary.green_lemon,
}))``;
