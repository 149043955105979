import React from 'react';

import * as TooltipPrimitive from '@radix-ui/react-tooltip';

import * as S from './styles';

const Trigger: React.FC<
  TooltipPrimitive.TooltipTriggerProps & React.RefAttributes<HTMLButtonElement>
> = ({ children, ...rest }) => {
  return (
    <S.TooltipTrigger type="button" {...rest}>
      {children}
    </S.TooltipTrigger>
  );
};

export default Trigger;
