import React from 'react';
import { CaretDown, MagnifyingGlass } from '@phosphor-icons/react';
import { useTheme } from 'styled-components/macro';
import { useParams } from 'react-router-dom';

import { IVariant } from '@domain/interfaces/common/variant/IVariant';
import {
  IOrderBumpOfferProduct,
  IOrderBumpTriggerProduct,
} from '@domain/interfaces/common/orderBump/IOrderBump';

import { useVariantService } from '@hooks/services/variant/useVariantService';
import { useDebounce } from '@hooks/common/useDebounce';

import * as S from './styles';

interface IVariantSelectProps {
  onVariantSelect: (variant: IVariant | string) => void;
  selectedProduct?: IOrderBumpOfferProduct | IOrderBumpTriggerProduct;
  defaultSelectedAllVariants?: boolean;
  defaultSelectedVariant?: IVariant;
}

const VariantSelect: React.FC<IVariantSelectProps> = ({
  onVariantSelect,
  selectedProduct,
  defaultSelectedAllVariants,
  defaultSelectedVariant,
}) => {
  const theme = useTheme();
  const { accountId, checkoutId } = useParams();
  const { listVariants } = useVariantService();

  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [variants, setVariants] = React.useState<Array<IVariant>>([]);
  const [isLoadingVariants, setIsLoadingVariants] = React.useState<boolean>(false);
  const [searchName, setSearchName] = React.useState<string>('');
  const [selectedVariant, setSelectedVariant] = React.useState<IVariant | undefined>(undefined);
  const [triggerWidth, setTriggerWidth] = React.useState<number>(0);
  const [hasSelectedAllVariants, setHasSelectedAllVariants] = React.useState<boolean>(false);

  const listVariantsRef = React.useRef(listVariants);
  const triggerRef = React.useRef<HTMLButtonElement>(null);

  const debouncedSearchName = useDebounce(searchName, 500);

  const onSearchName = React.useCallback(event => setSearchName(event.target.value), []);

  const loadVariants = React.useCallback(async () => {
    setIsLoadingVariants(true);

    if (!selectedProduct) return;

    try {
      const { data } = await listVariantsRef.current({
        accountId,
        checkoutId,
        productId: selectedProduct?.product.id,
      });

      setVariants(data.object);
    } catch {
      console.log('error');
    }

    setIsLoadingVariants(false);
  }, [accountId, checkoutId, selectedProduct]);

  const onSelectedVariantClick = React.useCallback(
    variant => {
      if (variant === 'all') {
        setHasSelectedAllVariants(true);
        setSelectedVariant(undefined);
        onVariantSelect('all');
        setIsOpen(false);
      } else {
        setSelectedVariant(variant);
        onVariantSelect(variant);
        setHasSelectedAllVariants(false);
        setIsOpen(false);
      }
    },
    [onVariantSelect],
  );

  const handleOpen = React.useCallback(state => {
    setIsOpen(state);
  }, []);

  React.useEffect(() => {
    if (defaultSelectedAllVariants) {
      setHasSelectedAllVariants(true);
    }
  }, [defaultSelectedAllVariants]);

  React.useEffect(() => {
    if (defaultSelectedVariant) {
      setSelectedVariant(defaultSelectedVariant);
    }
  }, [defaultSelectedVariant]);

  React.useLayoutEffect(() => {
    if (!triggerRef.current) return;

    setTriggerWidth(triggerRef.current.offsetWidth);
  }, []);

  React.useEffect(() => {
    if (isOpen) {
      loadVariants();
    }
  }, [isOpen, loadVariants]);

  const filteredVariants = variants.filter(variant =>
    variant.name.toLocaleLowerCase().includes(debouncedSearchName.toLowerCase()),
  );

  return (
    <S.Dropdown open={isOpen} onOpenChange={handleOpen}>
      <S.DropdownTrigger type="button" disabled={!selectedProduct} ref={triggerRef}>
        <S.TriggerContentWrapper>
          {hasSelectedAllVariants && <S.SelectVariantName>Todas as variantes</S.SelectVariantName>}

          {selectedVariant && <S.SelectVariantName>{selectedVariant.name}</S.SelectVariantName>}

          {!hasSelectedAllVariants && !selectedVariant && (
            <S.SelectVariantName>Selecione a variante</S.SelectVariantName>
          )}
        </S.TriggerContentWrapper>

        <CaretDown size={20} weight="bold" color={theme.colors.neutral.dark} />
      </S.DropdownTrigger>

      <S.DropdownContent width={triggerWidth} sideOffset={10}>
        <S.SearchInput
          onChange={onSearchName}
          type="text"
          icon={MagnifyingGlass}
          placeholder="Procurar variante"
        />

        <S.ScrollableContainer>
          {!variants.length && isLoadingVariants && <div>Loading...</div>}

          {variants.length > 0 && (
            <S.VariantWrapper type="button" onClick={() => onSelectedVariantClick('all')}>
              <S.VariantName>Selecionar todas as variantes</S.VariantName>
            </S.VariantWrapper>
          )}

          {filteredVariants.map(variant => {
            return (
              <S.VariantWrapper
                type="button"
                key={variant.id}
                onClick={() => onSelectedVariantClick(variant)}
              >
                <S.VariantName>{variant.name}</S.VariantName>
              </S.VariantWrapper>
            );
          })}
        </S.ScrollableContainer>
      </S.DropdownContent>
    </S.Dropdown>
  );
};

export default VariantSelect;
