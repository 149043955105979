export enum ELanguage {
  PT_BR = 'pt-BR',
  ES_ES = 'es-ES',
  ES_AR = 'es-AR',
  IT_IT = 'it-IT',
  FR_FR = 'fr-FR',
  DE_DE = 'de-DE',
  EN_US = 'en-US',
  NB_NO = 'nb-NO',
  NL_BE = 'nl-BE',
  PT_PT = 'pt-PT',
  RU_RU = 'ru-RU',
  BG_BG = 'bg-BG',
  EN_CA = 'en-CA',
  ES_CL = 'es-CL',
  ES_CO = 'es-CO',
  ES_MX = 'es-MX',
  ES_PE = 'es-PE',
  ES_PR = 'es-PR',
  EN_GB = 'en-GB',
  NL_NL = 'nl-NL',
  EN_NZ = 'en-NZ',
  SV_SE = 'sv-SE',
  TR_TR = 'tr-TR',
  ZH_CN = 'zh-CN',
  ZH_TW = 'zh-TW',
  JA_JP = 'ja-JP',
  KO_KR = 'ko-KR',
  VI_VN = 'vi-VN',
  TH_TH = 'th-TH',
  ID_ID = 'id-ID',
  AR_SA = 'ar-SA',
  AR_AE = 'ar-AE',
  HI_IN = 'hi-IN',
  DE_CH = 'de-CH',
}
