import React from 'react';
import useSWR from 'swr';
import { useNavigate, useParams } from 'react-router-dom';

import { ISwr } from '@domain/interfaces/common/swr/ISwr';
import { IPromocode } from '@domain/interfaces/common/promocode/IPromocode';
import { IUpdatePromocodeProvider } from '@domain/interfaces/store/common/promocode/IUpdatePromocodeProvider';
import { IGetPromocodeResponse } from '@domain/interfaces/hooks/services/promocode/IPromocodeService';
import { IProduct } from '@domain/interfaces/common/product/IProducts';

import { DASHBOARD_API_URL } from '@constants/services/apiUrl';
import { dashboardInstance } from '@services/common/dashboardInstance';

import { useErrorHandler } from '@store/context/common/ErrorHandlerContext';

import { usePromocodeService } from '@hooks/services/promocode/usePromocodeService';
import { useToast } from '@store/context/common/ToastContext';
import { useAnalytics } from '@store/context/common/AnalyticsContext';

const UpdatePromocodeContext = React.createContext<IUpdatePromocodeProvider | null>(null);

export const UpdatePromocodeProvider: React.FC = ({ children }) => {
  const { handleError } = useErrorHandler();
  const { updatePromocode, deletePromocode } = usePromocodeService();
  const navigate = useNavigate();
  const { toast } = useToast();
  const { couponId, accountId, checkoutId } = useParams();
  const { analytics } = useAnalytics();

  const [promocode, setPromocode] = React.useState<IPromocode>({} as IPromocode);
  const [isUpdatingPromocode, setIsUpdatingPromocode] = React.useState<boolean>(false);
  const [isLoadingCurr, setIsLoadingCurr] = React.useState<boolean>(true);
  const [selectedProductsUpdated, setSelectedProductsUpdated] = React.useState<IProduct[]>([]);
  const [startDate, setStartDate] = React.useState<Date>(new Date(''));
  const [endDate, setEndDate] = React.useState<Date>(new Date(''));

  const [selectedOfferProduct, setSelectedOfferProduct] =
    React.useState<IProduct[]>(selectedProductsUpdated);

  dashboardInstance.defaults.headers.common['x-zouti-account'] = accountId;

  const PROMOCODE_UPDATE_URL = `${DASHBOARD_API_URL}/accounts/${accountId}/checkouts/${checkoutId}/promocodes/${couponId}`;

  const { data, isLoading, isValidating, mutate, error } = useSWR<ISwr<IGetPromocodeResponse>>(
    PROMOCODE_UPDATE_URL,
    dashboardInstance,
  );

  React.useEffect(() => {
    if (error) {
      handleError(error);
    }

    if (data?.data) {
      const { products } = data?.data?.object;
      setPromocode(data.data?.object);
      setIsLoadingCurr(false);
      const transformedData: any = products?.map(item => ({
        id: item.product_id,
        name: item.name,
        images: [{ url: item.image_url }],
      }));
      setSelectedProductsUpdated(transformedData);
      setStartDate(new Date(data.data?.object.start_at));
      setEndDate(new Date(data.data?.object.end_at));
    }
  }, [data, handleError, error]);

  const isLoadingPromocode = isLoading || isValidating || isLoadingCurr;
  const isPromocodeError = Boolean(error);

  const updateCurrentPromocode = React.useCallback(
    async payload => {
      setIsUpdatingPromocode(true);

      try {
        await updatePromocode({ promocodeId: promocode.id, ...payload, accountId, checkoutId });

        analytics.track('Coupon Updated');

        toast.success('Cupom atualizado com sucesso!');
        setIsUpdatingPromocode(false);

        navigate(`/${accountId}/${checkoutId}/dashboard/marketing/coupon`);
      } catch (errorUpdating) {
        toast.error('Erro ao atualizar o cupom!');
        setIsUpdatingPromocode(false);
        handleError(errorUpdating);
      }
    },
    [updatePromocode, handleError, promocode, navigate, accountId, checkoutId, toast, analytics],
  );

  const deleteCurrentPromocode = React.useCallback(
    async deleteCouponId => {
      try {
        await deletePromocode({ promocodeId: deleteCouponId, accountId, checkoutId });

        analytics.track('Coupon Deleted');

        toast.success('Cupom deletado com sucesso!');
        navigate(`/${accountId}/${checkoutId}/dashboard/marketing/coupon`);
        await mutate();
      } catch (errorDeletePromocode) {
        toast.error('Erro ao deletar o cupom!');
      }
    },
    [deletePromocode, mutate, accountId, checkoutId, toast, navigate, analytics],
  );

  return (
    <UpdatePromocodeContext.Provider
      value={{
        promocode,
        isLoadingPromocode,
        isPromocodeError,
        mutate,
        updateCurrentPromocode,
        isUpdatingPromocode,
        selectedOfferProduct,
        setSelectedOfferProduct,
        deleteCurrentPromocode,
        selectedProductsUpdated,
        startDate,
        endDate,
        setStartDate,
        setEndDate,
      }}
    >
      {children}
    </UpdatePromocodeContext.Provider>
  );
};

export const useUpdatePromocode = (): IUpdatePromocodeProvider => {
  const context = React.useContext(UpdatePromocodeContext);

  if (!context) {
    throw new Error('useUpdatePromocode must be used within provider');
  }

  return context;
};
