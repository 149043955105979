import React from 'react';

import { useGetPixel } from '@store/common/pixel/GetPixelContext';
import TableRow from './TableRow';

import * as S from './styles';

const Body: React.FC = () => {
  const { pixels } = useGetPixel();

  return (
    <S.Wrapper>
      <S.Table>
        <S.Table.Header>
          <S.Table.Row>
            <S.Table.Head>Status</S.Table.Head>
            <S.Table.Head>Nome do Pixel</S.Table.Head>
            <S.Table.Head>Plataforma</S.Table.Head>
            <S.Table.Head />
          </S.Table.Row>
        </S.Table.Header>

        <S.Table.Body>
          {pixels.map(pixel => (
            <TableRow pixel={pixel} key={pixel.id} />
          ))}
        </S.Table.Body>
      </S.Table>
    </S.Wrapper>
  );
};

export default Body;
