import React from 'react';
import { useParams } from 'react-router-dom';
import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { parseToRawPhoneWithoutCountry, phone } from '@helpers/utils/common/phone';
import { cnpjFormatter, cnpjToNumber } from '@utils/common/cnpj';

import { useUpdateAccountData } from '@store/common/accountData/UpdateAccountData';

import { IUpdateAccountData } from '@domain/interfaces/hooks/services/accountData/IAccountDataService';
import { accountDataSchema } from '@helpers/validators/pages/dashboard/accountData/accountDataSchema';

import * as S from './styles';

const CompanyData: React.FC = () => {
  const { accountId } = useParams();
  const { accountData, currentUserCheckout, updateCurrentAccountData, isUpdatingAccountData } =
    useUpdateAccountData();
  const { cnpj, company_name } = accountData;
  const { name, website, contact } = currentUserCheckout;

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(accountDataSchema),
  });

  const onSubmit = async (formData: FieldValues): Promise<void> => {
    const data: IUpdateAccountData = {
      name: formData.name,
      website: formData.website,
      contact: parseToRawPhoneWithoutCountry(formData.contact),
      cnpj: cnpjToNumber(formData.cnpj),
    };

    await updateCurrentAccountData({ ...data, accountId });
  };

  React.useEffect(() => {
    setValue('name', name);
    setValue('contact', phone(contact));
    setValue('website', website);
    setValue('cnpj', cnpjFormatter(cnpj));
  }, [name, contact, website, setValue, cnpj]);

  const phoneNumber = register('contact');

  const onPhoneChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    phoneNumber.onChange(event);

    const { value } = event.target;

    event.target.value = phone(value);
  };

  const onCnpjChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const parsedCnpj = cnpjFormatter(event.target.value);

    event.target.value = parsedCnpj;
  };

  return (
    <S.Form onSubmit={handleSubmit(onSubmit)}>
      <S.BasicInfoSection>
        <S.Title>Informações básicas</S.Title>
        <S.InputGroup>
          <S.Label>Nome da loja</S.Label>
          <S.Input
            {...register('name')}
            defaultValue={name}
            placeholder="Digite o nome da loja"
            type="text"
          />
          {errors.name && <S.MessageError>{errors.name.message}</S.MessageError>}
        </S.InputGroup>
        <S.InputGroup>
          <S.Label>CNPJ</S.Label>
          <S.Input
            {...register('cnpj')}
            disabled
            onChange={onCnpjChange}
            placeholder="Digite o CNPJ"
            type="text"
          />
        </S.InputGroup>
        <S.InputGroup>
          <S.Label>Razão social</S.Label>
          <S.Input disabled value={company_name} placeholder="Digite a razão social" type="text" />
        </S.InputGroup>
        <S.Divider />
        <S.Title>Contato</S.Title>
        <S.InputGroup>
          <S.Label>Telefone do negócio</S.Label>
          <S.Input
            {...phoneNumber}
            onChange={onPhoneChange}
            defaultValue={contact}
            placeholder="Digite o telefone do negócio"
            type="text"
          />
          {errors.contact && <S.MessageError>{errors.contact.message}</S.MessageError>}
        </S.InputGroup>
        <S.InputGroup>
          <S.Label>Site</S.Label>
          <S.Input
            {...register('website')}
            defaultValue={website}
            placeholder="Digite o endereço do website"
            type="text"
          />

          {errors.website && <S.MessageError>{errors.website.message}</S.MessageError>}
        </S.InputGroup>
      </S.BasicInfoSection>

      <S.SubmitButton type="submit" isLoading={isUpdatingAccountData}>
        Salvar
      </S.SubmitButton>
    </S.Form>
  );
};

export default CompanyData;
