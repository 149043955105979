import React from 'react';

import { ChromePicker } from 'react-color';

import * as S from './styles';

interface IColorPickerProps {
  label: string;
  color: string;
  onChange: (color: string) => void;
  register: any;
}

const ColorPicker: React.FC<IColorPickerProps> = ({ label, color, onChange, register }) => {
  const [displayColorPicker, setDisplayColorPicker] = React.useState(false);

  const toggleColorPicker = (): void => setDisplayColorPicker(!displayColorPicker);

  const handleColorChange = (value: any): void => onChange(value.hex);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    onChange(event.target.value);
  };

  return (
    <S.InputGroup>
      <S.Label>{label}</S.Label>
      <S.ColorPickerWrapper>
        <S.PickerWrapper>
          <S.ColorPicker onClick={toggleColorPicker} color={color} />
        </S.PickerWrapper>
        <S.TextField {...register} type="text" value={color} onChange={handleInputChange} />
      </S.ColorPickerWrapper>
      {displayColorPicker ? (
        <>
          <S.DetectClickOutside onClick={toggleColorPicker} />

          <S.ChromePickerWrapper>
            <ChromePicker
              disableAlpha
              color={color}
              onChange={handleColorChange}
              onChangeComplete={handleColorChange}
            />
          </S.ChromePickerWrapper>
        </>
      ) : null}
    </S.InputGroup>
  );
};

export default ColorPicker;
