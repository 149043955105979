import React from 'react';

import FormHeader from '../FormHeader';
import FormContent from '../FormContent';

import * as S from './styles';

const Form: React.FC = () => {
  return (
    <S.Wrapper>
      <FormHeader />

      <FormContent />
    </S.Wrapper>
  );
};

export default Form;
