import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { INewPromocodeProvider } from '@domain/interfaces/store/common/promocode/INewPromocodeProvider';
import { IProduct } from '@domain/interfaces/common/product/IProducts';

import { usePromocodeService } from '@hooks/services/promocode/usePromocodeService';
import { useErrorHandler } from '@store/context/common/ErrorHandlerContext';
import { useAnalytics } from '@store/context/common/AnalyticsContext';

const NewPromocodeContext = React.createContext<INewPromocodeProvider | null>(null);

export const NewPromocodeProvider: React.FC = ({ children }) => {
  const { createPromocode } = usePromocodeService();
  const { handleError } = useErrorHandler();
  const { accountId, checkoutId } = useParams();
  const { analytics } = useAnalytics();
  const navigate = useNavigate();

  const [isCreatingPromocode, setIsCreatingPromocode] = React.useState<boolean>(false);

  const [selectedOfferProduct, setSelectedOfferProduct] = React.useState<IProduct[]>([]);

  const createNewPromocode = React.useCallback(
    async data => {
      setIsCreatingPromocode(true);

      try {
        await createPromocode({ ...data, accountId, checkoutId });

        analytics.track('Coupon Created');

        setIsCreatingPromocode(false);

        navigate(`/${accountId}/${checkoutId}/dashboard/marketing/coupon`);
      } catch (error) {
        setIsCreatingPromocode(false);
        handleError(error);
      }
    },
    [createPromocode, handleError, navigate, accountId, checkoutId, analytics],
  );

  return (
    <NewPromocodeContext.Provider
      value={{
        createNewPromocode,
        isCreatingPromocode,
        selectedOfferProduct,
        setSelectedOfferProduct,
      }}
    >
      {children}
    </NewPromocodeContext.Provider>
  );
};

export const useNewPromocode = (): INewPromocodeProvider => {
  const context = React.useContext(NewPromocodeContext);

  if (!context) {
    throw new Error('useNewPromocode must be used within provider');
  }

  return context;
};
