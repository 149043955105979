import React from 'react';

import Content from '@components/pages/Dashboard/Shipping/UpdateShipping';
import { UpdateShippingProvider } from '@store/common/shipping/UpdateShippingContext';

const UpdateShipping: React.FC = () => {
  return (
    <UpdateShippingProvider>
      <Content />
    </UpdateShippingProvider>
  );
};

export default UpdateShipping;
