import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  display: flex;
  gap: 24px;

  @media (max-width: 768px) {
    flex-direction: column;
    margin: 0 auto;
  }
`;
