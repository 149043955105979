import React from 'react';
import { useTheme } from 'styled-components/macro';

import Image from '@components/common/DataDisplay/Image';

import * as S from './styles';

interface ITooltipProps {
  children?: React.ReactNode;
  url: string;
  iconColor?: string;
  iconSize?: number;
  iconWeight?: 'thin' | 'light' | 'regular' | 'bold' | 'fill' | 'duotone';
}

const Tooltip: React.FC<ITooltipProps> = ({
  children,
  url,
  iconSize = 14,
  iconColor,
  iconWeight = 'bold',
}) => {
  const theme = useTheme();
  return (
    <S.Tooltip>
      <S.TooltipContent>
        <Image src={url} />
        <S.Tooltip.Arrow />
      </S.TooltipContent>
      <S.Wrapper>
        {children}
        <S.TooltipTrigger>
          <S.InfoIcon
            weight={iconWeight}
            size={iconSize}
            color={iconColor || theme.colors.neutral.darker}
          />
        </S.TooltipTrigger>
      </S.Wrapper>
    </S.Tooltip>
  );
};

export default Tooltip;
