import { AxiosResponse } from 'axios';

import { ICheckoutCustomization } from '@domain/interfaces/common/checkoutCustomization/ICheckoutCustomization';
import { EThemeCheckout } from '@domain/enums/common/checkoutCustomization/EThemeCheckout';

export enum EHeaderCustomizationTimerUnitOfTime {
  MINUTES = 'MINUTES',
  HOURS = 'HOURS',
}

export interface ICreateCheckoutCustomizationData extends ICreateConfigurationData {
  header_customization: ICreateHeaderCustomizationData;
  footer_customization: ICreateFooterCustomizationData;
  appearance_customization: ICreateAppearanceCustomizationData;
  advanced_customization: ICreateAdvancedCustomizationData;
  checkout_theme: ICreateThemeCustomizationData;
}

export interface ICreateConfigurationData {
  name: string;
  allow_credit_card: boolean;
  allow_pix: boolean;
  allow_boleto: boolean;
  credit_card_discount_amount?: number;
  pix_discount_amount?: number;
  boleto_discount_amount?: number;
  credit_card_discount_type: string;
  pix_discount_type: string;
  boleto_discount_type: string;
  show_coupon_input: boolean;
  enable_itens_quantity_change: boolean;
  show_time_shipping_estimate: boolean;
}

export type IUpdateCheckoutConfigurationData = ICreateConfigurationData;

export interface ICreateHeaderCustomizationData {
  background_hex_color: string;
  name_hex_color: string;
  secure_hex_color: string;
  show_timer: boolean;
  timer_amount: number;
  timer_unit_of_time: EHeaderCustomizationTimerUnitOfTime;
  timer_background_hex_color: string;
  timer_text_hex_color: string;
  timer_text: string;
  logo_url?: string;
  logo_path?: string;
  id?: string;
}

export interface ICreateFooterCustomizationData {
  footer_hex_color: string;
  text_hex_color: string;
  show_security_seal: boolean;
  show_account_name: boolean;
  show_account_address: boolean;
  show_account_cnpj_or_cpf: boolean;
  show_account_email: boolean;
  metadata?: {
    link_hex_color: string;
    policies: {
      devolution: {
        enabled: boolean;
        link: string;
      };
      privacy: {
        enabled: boolean;
        link: string;
      };
      terms: {
        enabled: boolean;
        link: string;
      };
    };
  };
  id?: string;
}

export interface ICreateAppearanceCustomizationData {
  primary_hex_color: string;
  title_hex_color: string;
  label_hex_color: string;
  chip_hex_color: string;
  button_hex_color: string;
  button_text_hex_color: string;
  title_font_family: string;
  is_title_uppercase: boolean;
  text_font_family: string;
  is_button_uppercase: boolean;
  metadata?: {
    button_font_family: string;
    is_text_uppercase: boolean;
  };
  id?: string;
}

export interface ICreateAdvancedCustomizationData {
  custom_css: string;
  favicon_url?: string;
  favicon_path?: string;
  id?: string;
}

export interface ICreateThemeCustomizationData {
  theme: EThemeCheckout;
  id?: string;
}

export interface IUploadCustomizationData {
  file: FormData;
  type: string;
}

export interface IUpdateCheckoutCustomizationData extends ICreateConfigurationData {
  header_customization: ICreateHeaderCustomizationData;
  footer_customization: ICreateFooterCustomizationData;
  appearance_customization: ICreateAppearanceCustomizationData;
  advanced_customization: ICreateAdvancedCustomizationData;
  checkout_theme: ICreateThemeCustomizationData;
}

export interface ICreateCheckoutCustomizationProps {
  accountId?: string;
  checkoutId?: string;
  payload: ICreateCheckoutCustomizationData;
}

export interface IUpdateCheckoutCustomizationProps {
  checkoutCustomizationId: string;
  accountId?: string;
  checkoutId?: string;
  payload: IUpdateCheckoutCustomizationData;
  checkout_theme?: any;
}

export interface IUploadCheckoutCustomizationProps {
  accountId?: string;
  checkoutId?: string;
  payload: FormData;
}

export interface IDeleteUploadCheckoutCustomizationProps {
  accountId?: string;
  checkoutId?: string;
  path: string;
}

export interface IDeleteCheckoutCustomizationProps {
  accountId?: string;
  checkoutId?: string;
  checkoutCustomizationId: string;
}

export interface IActivateCheckoutCustomizationProps {
  accountId?: string;
  checkoutId?: string;
  checkoutCustomizationId: string;
}

export interface IListCheckoutCustomizationResponse {
  object: {
    data: Array<ICheckoutCustomization>;
    total_pages: number;
    page: number;
    total: number;
  };
}

export interface IGetCheckoutCustomizationResponse {
  object: ICheckoutCustomization;
}

export interface ICreateCheckoutCustomizationResponse {
  object: {
    name: string;
    id: string;
    allow_boleto: boolean;
    allow_credit_card: boolean;
    allow_pix: boolean;
    pix_discount_amount: number;
    pix_discount_type: string;
    boleto_discount_amount: number;
    boleto_discount_type: string;
    credit_card_discount_amount: number;
    credit_card_discount_type: string;
    inactivated_at: string;
    account_id: string;
    created_at: string;
    updated_at: string;
  };
}

export interface IUpdateCheckoutCustomizationResponse {
  status: string;
  checkout_customization: ICheckoutCustomization;
}

export interface IUploadCheckoutCustomizationResponse {
  object: {
    url: string;
    path: string;
  };
}

export interface IDeleteCheckoutCustomizationResponse {
  status: string;
}

export interface IDeleteUploadCheckoutCustomizationResponse {
  status: string;
}

export interface ICheckoutCustomizationService {
  listCheckoutCustomization: () => Promise<AxiosResponse<IListCheckoutCustomizationResponse>>;
  createCheckoutCustomization: (
    props: ICreateCheckoutCustomizationProps,
  ) => Promise<AxiosResponse<ICreateCheckoutCustomizationResponse>>;
  updateCheckoutCustomization: (
    props: IUpdateCheckoutCustomizationProps,
  ) => Promise<AxiosResponse<IUpdateCheckoutCustomizationResponse>>;
  uploadCheckoutCustomization: (
    props: IUploadCheckoutCustomizationProps,
  ) => Promise<AxiosResponse<IUploadCheckoutCustomizationResponse>>;
  deleteUploadCheckoutCustomization: (
    props: IDeleteUploadCheckoutCustomizationProps,
  ) => Promise<AxiosResponse<IDeleteUploadCheckoutCustomizationResponse>>;
  deleteCheckoutCustomization: (
    props: IDeleteCheckoutCustomizationProps,
  ) => Promise<AxiosResponse<IDeleteCheckoutCustomizationResponse>>;
  activateCheckoutCustomization: (
    props: IActivateCheckoutCustomizationProps,
  ) => Promise<AxiosResponse<IUpdateCheckoutCustomizationResponse>>;
}
