import React from 'react';

import { useGetDomain } from '@store/common/domain/GetDomainContext';
import TableRow from './TableRow';

import * as S from './styles';
import DeleteDomainModal from '../DeleteDomainModal';

const Body: React.FC = () => {
  const { domains } = useGetDomain();
  return (
    <S.Wrapper>
      <DeleteDomainModal />
      <S.Table>
        <S.Table.Header>
          <S.Table.Row>
            <S.Table.Head>Tipo</S.Table.Head>
            <S.Table.Head>Domínio</S.Table.Head>
            <S.Table.Head>Status</S.Table.Head>
            <S.Table.Head />
          </S.Table.Row>
        </S.Table.Header>

        <S.Table.Body>
          {domains.map(domain => (
            <TableRow domain={domain} key={domain.id} />
          ))}
        </S.Table.Body>
      </S.Table>
    </S.Wrapper>
  );
};

export default Body;
