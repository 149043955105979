import React from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import { IGetRecoveryTemplatesResponse } from '@domain/interfaces/hooks/services/recoveryTemplate/IRecoveryTemplateService';
import { IRecoveryTemplates } from '@domain/interfaces/common/recovery/IRecoveryTemplates';
import { ISwr } from '@domain/interfaces/common/swr/ISwr';
import { IUpdateRecoveryTemplateWhatsAppProvider } from '@domain/interfaces/store/common/recovery/IUpdateRecoveryTemplateWhatsAppProvider';

import { DASHBOARD_API_URL } from '@constants/services/apiUrl';
import { dashboardInstance } from '@services/common/dashboardInstance';

import { useErrorHandler } from '@store/context/common/ErrorHandlerContext';
import { useRecoveryTemplateService } from '@hooks/services/recovery/useRecoveryTemplateService';
import { useToast } from '@store/context/common/ToastContext';
import { useAnalytics } from '@store/context/common/AnalyticsContext';

const UpdateRecoveryTemplateWhatsAppContext =
  React.createContext<IUpdateRecoveryTemplateWhatsAppProvider | null>(null);

export const UpdateRecoveryWhatsAppProvider: React.FC = ({ children }) => {
  const { toast } = useToast();
  const { analytics } = useAnalytics();
  const { handleError } = useErrorHandler();
  const { accountId, checkoutId } = useParams();
  const { updateRecoveryTemplateWhatsApp } = useRecoveryTemplateService();

  const [isUpdatingRecoveryWhatsApp, setIsUpdatingRecoveryWhatsApp] =
    React.useState<boolean>(false);
  const [recoveryTemplates, setRecoveryTemplates] = React.useState<IRecoveryTemplates>(
    {} as IRecoveryTemplates,
  );

  dashboardInstance.defaults.headers.common['x-zouti-account'] = accountId;

  const RECOVERY_TEMPLATES_URL = `${DASHBOARD_API_URL}/accounts/${accountId}/checkouts/${checkoutId}/recovery_templates`;

  const { data, isLoading, isValidating, error } = useSWR<ISwr<IGetRecoveryTemplatesResponse>>(
    RECOVERY_TEMPLATES_URL,
    dashboardInstance,
  );

  React.useEffect(() => {
    if (error) {
      handleError(error);
    }

    if (data?.data) {
      const recoveryTemplatesWHATSAPP = data.data.object.find(item => item.type === 'WHATSAPP');
      if (recoveryTemplatesWHATSAPP) {
        setRecoveryTemplates(recoveryTemplatesWHATSAPP);
      }
    }
  }, [data, handleError, error]);

  const updateCurrentRecoveryWhatsApp = React.useCallback(
    async payload => {
      setIsUpdatingRecoveryWhatsApp(true);

      try {
        await updateRecoveryTemplateWhatsApp({
          accountId,
          checkoutId,
          templateId: recoveryTemplates.id,
          ...payload,
        });

        analytics.track('Recovery Template Updated', { type: 'WHATSAPP' });

        setIsUpdatingRecoveryWhatsApp(false);
        toast.success('Mensagem enviada com sucesso!');
      } catch (err) {
        setIsUpdatingRecoveryWhatsApp(false);
        handleError(err);
      } finally {
        setIsUpdatingRecoveryWhatsApp(false);
      }
    },
    [
      updateRecoveryTemplateWhatsApp,
      handleError,
      toast,
      accountId,
      checkoutId,
      recoveryTemplates,
      analytics,
    ],
  );

  const isLoadingRecoveryTemplates = isLoading || isValidating;
  const isRecoveryTemplatesError = Boolean(error);

  return (
    <UpdateRecoveryTemplateWhatsAppContext.Provider
      value={{
        isUpdatingRecoveryWhatsApp,
        updateCurrentRecoveryWhatsApp,
        isLoadingRecoveryTemplates,
        isRecoveryTemplatesError,
        recoveryTemplates,
      }}
    >
      {children}
    </UpdateRecoveryTemplateWhatsAppContext.Provider>
  );
};

export const useUpdateRecoveryWhatsApp = (): IUpdateRecoveryTemplateWhatsAppProvider => {
  const context = React.useContext(UpdateRecoveryTemplateWhatsAppContext);

  if (!context) {
    throw new Error('useUpdateRecoveryWhatsApp must be used within provider');
  }

  return context;
};
