import React from 'react';
import { useNewPixel } from '@store/common/pixel/NewPixelContext';
import FacebookForm from './Facebook';
import FacebookConversionForm from './FacebookConversion';
import GoogleAdsForm from './GoogleAds';
import TaboolaForm from './Taboola';
import TikTokForm from './TikTok';

export const Forms: React.FC = () => {
  const { providerSelected } = useNewPixel();

  const renderForm = (): JSX.Element => {
    switch (providerSelected) {
      case 'FACEBOOK':
        return <FacebookForm />;

      case 'FACEBOOK_CONVERSIONS':
        return <FacebookConversionForm />;

      case 'GOOGLE':
        return <GoogleAdsForm />;

      case 'TABOOLA':
        return <TaboolaForm />;

      case 'TIKTOK':
        return <TikTokForm />;

      default:
        return <></>;
    }
  };

  return <>{renderForm()}</>;
};

export default Forms;
