import React from 'react';

import * as S from './styles';

interface ILabelProps {
  htmlFor?: string;
  onClick?: React.MouseEventHandler<HTMLLabelElement>;
}

const Label: React.FC<ILabelProps> = ({ children, htmlFor, ...rest }) => {
  return (
    <S.Label htmlFor={htmlFor} {...rest}>
      {children}
    </S.Label>
  );
};

export default Label;
