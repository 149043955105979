import React from 'react';

import ZoutiTutorialImage from '@assets/images/common/zouti/zouti-tutorial.svg';

import Tutorial from '@components/common/utils/Tutorial';
import Webhook from './Webhook';

import Credentials from './Credentials';

import * as S from './styles';

const Body: React.FC = () => {
  return (
    <S.Wrapper>
      <S.ZoutiPayAndTutorialWrapper>
        <Webhook />

        {/* <Tutorial
          image_url={ZoutiTutorialImage}
          title="Integração Webhook: Guia de configuração fácil"
          video_url="https://www.youtube.com/embed/f5_wn8mexmM?si=Ud_rqwSSYa_nU4az"
        /> */}
      </S.ZoutiPayAndTutorialWrapper>

      <Credentials />
    </S.Wrapper>
  );
};

export default Body;
