import styled from 'styled-components/macro';

import SearchInputBase from '@components/common/Inputs/SearchInput';
import FilterButtonBase from '@components/common/utils/FilterButton';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  margin-bottom: 32px;
`;

export const TitleAndResultsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 8px;
`;

export const InputAndFilterWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SearchInput = styled(SearchInputBase)`
  width: 100%;
  max-width: 414px;
`;

export const FilterButton = styled(FilterButtonBase)``;
