/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useTheme } from 'styled-components/macro';
import { Link } from 'phosphor-react';
import { useParams } from 'react-router-dom';

import { EHeadAlign } from '@domain/enums/components/common/ETable';
import { IVariant } from '@domain/interfaces/common/variant/IVariant';

import { numberToString } from '@helpers/utils/common/number';
import { formatCurrency } from '@utils/common/formatCurrency';

import { useVariantService } from '@hooks/services/variant/useVariantService';
import { useErrorHandler } from '@store/context/common/ErrorHandlerContext';
import { useListProducts } from '@store/common/product/ListProductsContext';
import { useToast } from '@store/context/common/ToastContext';
import { useAnalytics } from '@store/context/common/AnalyticsContext';
import { useUserAccount } from '@store/context/common/UserAccount';

import * as S from './styles';

interface IRowProps {
  variant: IVariant;
}

const Row: React.FC<IRowProps> = ({ variant }) => {
  const theme = useTheme();
  const { toast } = useToast();
  const { analytics } = useAnalytics();
  const { handleError } = useErrorHandler();
  const { selectedProduct } = useListProducts();
  const { accountId, checkoutId } = useParams();
  const { currentUserAccount } = useUserAccount();
  const { generateVariantCheckoutLink } = useVariantService();

  const [isGeneratingLink, setIsGeneratingLink] = React.useState<boolean>(false);
  const [generatedLink, setGeneratedLink] = React.useState<string | undefined>(undefined);

  const copyCheckoutLink = React.useCallback(async () => {
    if (!generatedLink) {
      toast.error('Nenhum link encontrado.');
      return;
    }

    try {
      await navigator.clipboard.writeText(generatedLink);

      toast.success('Link copiado com sucesso!');
    } catch (err) {
      toast.error('Erro ao copiar!');
    }
  }, [generatedLink, toast]);

  const onGenerateLinkClick = React.useCallback(async () => {
    setIsGeneratingLink(true);

    try {
      const { data } = await generateVariantCheckoutLink({
        accountId,
        checkoutId,
        productId: selectedProduct?.id,
        variantId: variant.id,
      });

      analytics.track('Checkout Variant Link Generated');

      setGeneratedLink(data?.object?.url);

      setIsGeneratingLink(false);
    } catch (error: any) {
      handleError(error);
      setIsGeneratingLink(false);
    }
  }, [
    handleError,
    accountId,
    checkoutId,
    variant,
    selectedProduct,
    generateVariantCheckoutLink,
    analytics,
  ]);

  const variantPrice = variant.price / 100;
  const formattedVariantPrice = formatCurrency(
    variantPrice,
    currentUserAccount?.language,
    variant?.currency,
  );

  return (
    <S.TableRow borderBottom>
      <S.TableData>
        <S.VariantNameContent>
          <S.EmptyImage />

          <S.VariantName>{variant.name}</S.VariantName>
        </S.VariantNameContent>
      </S.TableData>

      <S.TableData>{variant.sku ? variant.sku : '-'}</S.TableData>

      <S.TableData>{formattedVariantPrice}</S.TableData>

      <S.TableData align={EHeadAlign.RIGHT}>
        <S.LinkWrapper>
          {!generatedLink && !isGeneratingLink && (
            <S.CheckoutLinkTextWrapper onClick={onGenerateLinkClick}>
              Gerar link
              <Link size={16} color={theme.colors.primary.dark_green_60} />
            </S.CheckoutLinkTextWrapper>
          )}

          {isGeneratingLink && (
            <S.CheckoutLinkTextWrapper disabled>
              Gerando link
              <Link size={16} color={theme.colors.primary.dark_green_60} />
            </S.CheckoutLinkTextWrapper>
          )}

          {generatedLink && (
            <S.CheckoutLinkTextWrapper onClick={copyCheckoutLink}>
              Copiar link
              <Link size={16} color={theme.colors.primary.dark_green_60} />
            </S.CheckoutLinkTextWrapper>
          )}
        </S.LinkWrapper>
      </S.TableData>
    </S.TableRow>
  );
};

export default Row;
