import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FieldValues, Controller, useForm, useWatch } from 'react-hook-form';
import { Trash } from 'phosphor-react';

import { EButtonVariant } from '@domain/enums/components/common/EButton';
import { IUpdatePromocodeData } from '@domain/interfaces/hooks/services/promocode/IPromocodeService';

import { useUpdatePromocode } from '@store/common/promocode/UpdatePromocodeContext';
import { useUserAccount } from '@store/context/common/UserAccount';

import { currencyFormatter, currencyToNumberWithoutCharacter } from '@helpers/utils/common/number';
import { getCurrencySymbol } from '@utils/common/formatCurrency';

import * as S from './styles';

const Body: React.FC = () => {
  const navigate = useNavigate();
  const { accountId, checkoutId } = useParams();
  const { currentUserAccount } = useUserAccount();
  const {
    promocode,
    updateCurrentPromocode,
    isUpdatingPromocode,
    setSelectedOfferProduct,
    deleteCurrentPromocode,
    selectedProductsUpdated,
    endDate,
    startDate,
    setStartDate,
    setEndDate,
  } = useUpdatePromocode();

  const {
    amount_type,
    amount,
    code,
    description,
    end_at,
    free_shipment,
    id,
    max_quantity,
    min_value,
    start_at,
    type,
  } = promocode;

  // const [startDate, setStartDate] = React.useState<Date>(new Date(start_at));
  // const [endDate, setEndDate] = React.useState<Date>(new Date(end_at));

  // const amountType = free_shipment ? 'SHIPPING' : amount_type;

  const { register, handleSubmit, setValue, control } = useForm<IUpdatePromocodeData>({
    defaultValues: {
      amount_type,
      code,
      description,
      min_value: currencyFormatter(min_value.toString()),
      max_quantity,
      amount: currencyFormatter(amount.toString()),
      type,
      product_ids: [],
    },
  });

  // const promocodeType = useWatch({
  //   control,
  //   name: 'type',
  // });
  const promocodeAmountType = useWatch({
    control,
    name: 'amount_type',
  });

  // const showProducts = promocodeType === 'PRODUCT';

  const showAmountPrice = promocodeAmountType !== 'SHIPPING';

  const generateRandomCode = (length: number): string => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let randomCoupon = '';

    // eslint-disable-next-line
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomCoupon += characters.charAt(randomIndex);
    }

    return randomCoupon;
  };

  const onAmountChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    event.target.value = currencyFormatter(event.target.value);
  };

  const onSubmit = async (formData: FieldValues): Promise<void> => {
    const isFreeShipping = formData.amount_type === 'SHIPPING';

    const data: IUpdatePromocodeData = {
      amount_type: formData.amount_type,
      amount: isFreeShipping ? 0 : currencyToNumberWithoutCharacter(formData.amount),
      code: formData.code,
      description: formData.description,
      end_at: endDate.toISOString(),
      free_shipment: isFreeShipping,
      max_quantity: Number(formData.max_quantity),
      min_value: currencyToNumberWithoutCharacter(formData.min_value),
      start_at: startDate.toISOString(),
      product_ids: [],
      type: 'ORDER',
    };

    await updateCurrentPromocode({ promocodeId: id, payload: data });
  };

  const onChangeDate = React.useCallback(
    dates => {
      const [start, end] = dates;

      setStartDate(start);
      setEndDate(end);
    },
    [setStartDate, setEndDate],
  );

  const onCancelButtonClick = (): void => {
    navigate(`/${accountId}/${checkoutId}/dashboard/marketing/coupon`);
  };

  const onGenerateButtonClick = (): void => {
    setValue('code', generateRandomCode(13));
  };

  useEffect(() => {
    setValue('code', code);
    setValue('description', description);
    setValue('min_value', currencyFormatter(min_value.toString()));
    setValue('max_quantity', max_quantity);
    setValue('amount_type', amount_type);
    setValue('amount', currencyFormatter(amount.toString()));
  }, [code, description, amount, min_value, max_quantity, setValue, amount_type]);

  const currency = getCurrencySymbol(currentUserAccount?.language, currentUserAccount?.currency);

  return (
    <S.Wrapper>
      <S.Form onSubmit={handleSubmit(onSubmit)}>
        <S.Card>
          <S.InputGroup>
            <S.Label>Código</S.Label>
            <S.InputGroupButton>
              <S.Input
                {...register('code')}
                type="text"
                placeholder="Digite um código ou clique em gerar"
              />
              <S.GenerateCouponButton
                variant={EButtonVariant.SECONDARY}
                onClick={onGenerateButtonClick}
                type="button"
              >
                Gerar
              </S.GenerateCouponButton>
            </S.InputGroupButton>
          </S.InputGroup>

          <S.InputGroup>
            <S.Label>
              Descrição <S.LabelHelper>(opcional)</S.LabelHelper>
            </S.Label>
            <Controller
              name="description"
              control={control}
              defaultValue=""
              render={({ field }) => <S.TextArea {...field} />}
            />
          </S.InputGroup>

          <S.WrapperMinPriceAndMaxQuantity>
            <S.InputGroup>
              <S.Label>Valor mínimo da compra</S.Label>
              <S.ValueWrapper>
                <S.CurrencyLabel>{currency}</S.CurrencyLabel>
                <S.Input
                  {...register('min_value')}
                  onChange={onAmountChange}
                  type="text"
                  placeholder="Valor minímo"
                />
              </S.ValueWrapper>
            </S.InputGroup>

            <S.InputGroup>
              <S.Label>Nº de Cupons disponíveis</S.Label>
              <S.Input
                {...register('max_quantity')}
                type="text"
                placeholder="Insira a quantidade"
              />
            </S.InputGroup>
          </S.WrapperMinPriceAndMaxQuantity>

          <S.InputGroup>
            <S.Label>Data de expiração</S.Label>
            <S.DatePicker
              isShowPreDefinedDate={false}
              onChange={onChangeDate}
              endDate={endDate}
              startDate={startDate}
            />
          </S.InputGroup>
        </S.Card>
        <S.Card>
          {/* <S.InputGroup> */}
          {/*  <S.Label>Aplicar para</S.Label> */}
          {/*  <S.InputGroupWrapper> */}
          {/*    <S.Radio {...register('type')} label="Pedido inteiro" labelFor="type" value="ORDER" /> */}

          {/*    <S.Radio */}
          {/*      {...register('type')} */}
          {/*      label="Produto" */}
          {/*      labelFor="amountTypeProduct" */}
          {/*      value="PRODUCT" */}
          {/*    /> */}
          {/*  </S.InputGroupWrapper> */}
          {/* </S.InputGroup> */}

          {/* {showProducts && ( */}
          {/*  <ProductSelect */}
          {/*    setSelectedOfferProduct={setSelectedOfferProduct} */}
          {/*    selectedProductsUpdated={selectedProductsUpdated} */}
          {/*  /> */}
          {/* )} */}

          {showAmountPrice && (
            <>
              <S.InputGroup>
                <S.Label>Tipo de desconto</S.Label>
                <S.InputGroupWrapper>
                  <S.Radio
                    {...register('amount_type')}
                    label="Valor fixo"
                    labelFor="typeFixed"
                    value="FIXED"
                  />

                  <S.Radio
                    {...register('amount_type')}
                    label="Porcentagem"
                    labelFor="typePercentage"
                    value="PERCENTAGE"
                  />
                </S.InputGroupWrapper>
              </S.InputGroup>
              <S.InputGroup>
                <S.Label>Valor do desconto</S.Label>
                <S.ValueWrapper>
                  {promocodeAmountType === 'PERCENTAGE' ? (
                    <S.CurrencyLabel>%</S.CurrencyLabel>
                  ) : (
                    <S.CurrencyLabel>{currency}</S.CurrencyLabel>
                  )}
                  <S.Input
                    {...register('amount')}
                    onChange={onAmountChange}
                    type="text"
                    placeholder="Insira o valor do desconto"
                  />
                </S.ValueWrapper>
              </S.InputGroup>
            </>
          )}

          <S.CheckboxGroup>
            <Controller
              control={control}
              name="amount_type"
              render={({ field }) => (
                <S.Checkbox
                  id="amount_type"
                  value={field?.value?.toString()}
                  checked={promocodeAmountType === 'SHIPPING'}
                  onChange={() => {
                    field?.onChange(!field.value);
                    setValue('amount_type', !field.value ? 'SHIPPING' : '');
                  }}
                />
              )}
            />
            <S.CheckboxLabel htmlFor="amount_type">Aplicar frete grátis</S.CheckboxLabel>
          </S.CheckboxGroup>
        </S.Card>

        <S.ButtonsWrapper>
          <S.DeleteCouponButton type="button" onClick={() => deleteCurrentPromocode(id)}>
            <Trash size={16} />
            Excluir cupom
          </S.DeleteCouponButton>
          <S.WrapperCancelAndSubmitButton>
            <S.CancelButton
              onClick={onCancelButtonClick}
              variant={EButtonVariant.SECONDARY}
              type="submit"
            >
              Cancelar
            </S.CancelButton>
            <S.SubmitButton type="submit" isLoading={isUpdatingPromocode}>
              Salvar
            </S.SubmitButton>
          </S.WrapperCancelAndSubmitButton>
        </S.ButtonsWrapper>
      </S.Form>
    </S.Wrapper>
  );
};

export default Body;
