import React from 'react';

import { IBankAccountSelectedProps, useNewPayout } from '@store/common/Payout/NewPayoutContext';
import { banks } from '@constants/common/banks';

import * as S from './styles';

export interface IBankAccountOptionProps {
  provider: IBankAccountSelectedProps;
}

const BankAccountOption: React.FC<IBankAccountOptionProps> = ({ provider }) => {
  const { handleSelectedBankAccount } = useNewPayout();
  const { bank_code, account_number, routing_number, id } = provider;

  const bankName = banks?.find(bank => bank.code?.toString() === bank_code)?.name;

  return (
    <S.BankAccountOption
      onClick={() => handleSelectedBankAccount({ bank_code, account_number, routing_number, id })}
    >
      <S.BankAccountOptionContent>
        <S.BankAccountBankName>{bankName}</S.BankAccountBankName>
        <S.BankAccountBankAccountGroup>
          <S.AccountNumber>CC: {account_number} .</S.AccountNumber>
          <S.RoutingNumber> Ag: {routing_number}</S.RoutingNumber>
        </S.BankAccountBankAccountGroup>
      </S.BankAccountOptionContent>
    </S.BankAccountOption>
  );
};

export default BankAccountOption;
