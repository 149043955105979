import styled from 'styled-components/macro';

import ImageBase from '@components/common/DataDisplay/Image';
import LinkBase from '@components/common/Navigation/Link';
import TextBase from '@components/common/DataDisplay/Text';

export const Link = styled(LinkBase)`
  color: ${({ theme }) => theme.colors.primary.dark_green_40};
  text-decoration: none;
  font-weight: inherit;
  font-weight: 500;
  font-size: 1.4rem;
`;

export const Text = styled(TextBase)`
  text-align: center;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.neutral.darkest};
  font-size: 1.4rem;
`;

export const Body = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 28px;
`;

export const Logo = styled(ImageBase)`
  max-width: 192px;
`;

export const Wrapper = styled.div`
  padding: 30px;
  border-radius: 12px;
  max-width: 600px;
  max-height: 100vh;
  overflow: auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  @media only screen and (max-height: 678px) {
    margin-top: unset;
  }
`;

export const LogoIcon = styled.img``;

export const ImageBox = styled.div<{ image: string }>`
  min-height: 100vh;
  width: 100%;
  background: url(${({ image }) => image});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;

  @media (max-width: 912px) {
    display: none;
  }
`;

export const InfoWrapper = styled.div<{ align: 'start' | 'end' }>`
  display: inline-flex;
  padding: 40px 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 8px;
  background: rgba(255, 242, 242, 0.13);
  backdrop-filter: blur(26.349594116210938px);
  position: absolute;
  left: 5vh;
  top: ${({ align }) => (align === 'start' ? '5vh' : 'none')};
  bottom: ${({ align }) => (align === 'end' ? '5vh' : 'none')};
  max-width: 420px;
`;

export const BadgeCategory = styled.div`
  padding: 10.157px 13.543px;
  border-radius: 6px;
  background: ${({ theme }) => theme.colors.primary.lemon_10};
  color: ${({ theme }) => theme.colors.primary.dark_green_40};
  font-size: 1.2rem;
  font-family: ${({ theme }) => theme.fonts.text};
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const BadgeText = styled(TextBase)`
  font-size: 1.6rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.neutral.gray_white};
  line-height: 27.905px;
`;

export const Container = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
