import styled from 'styled-components/macro';

import HeadingBase from '@components/common/DataDisplay/Heading';
import TextBase from '@components/common/DataDisplay/Text';
import EmptyImageBase from '@components/common/utils/EmptyImage';
import ImageBase from '@components/common/DataDisplay/Image';

export const Image = styled(ImageBase)`
  min-width: 56px;
  max-width: 56px;
  min-height: 56px;
  max-height: 56px;
  border-radius: 4px;
`;

export const EmptyImage = styled(EmptyImageBase)`
  width: 56px;
`;

export const Value = styled(TextBase)`
  max-width: 264px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.neutral.darkest};
  font-size: 1.4rem;
`;

export const Divider = styled.hr`
  margin: 16px 0;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
`;

export const Label = styled(TextBase)`
  color: ${({ theme }) => theme.colors.neutral.dark};
  font-size: 1.4rem;
`;

export const TotalPriceWrapper = styled.div`
  max-width: 100px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const UnitPriceWrapper = styled.div`
  max-width: 100px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const QuantityWrapper = styled.div`
  max-width: 80px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const SKUWrapper = styled.div`
  max-width: 185px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const VariantWrapper = styled.div`
  max-width: 225px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const DescriptionWrapper = styled.div`
  max-width: 150px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ProductWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: space-between;
`;

export const ProductsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Title = styled(HeadingBase)`
  color: ${({ theme }) => theme.colors.neutral.black};
  display: inline-block;
`;

export const Wrapper = styled.div`
  padding: 24px;
  box-sizing: border-box;
  width: 100%;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
`;
