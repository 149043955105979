import styled from 'styled-components/macro';
import Skeleton from 'react-loading-skeleton';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  margin-top: 24px;
  gap: 24px;
  background-color: ${({ theme }) => theme.colors.neutral.white};
`;

export const Title = styled(Skeleton)`
  width: 20%;
  height: 15px;
`;
export const Switch = styled(Skeleton)`
  width: 6%;
  height: 23px;
  border-radius: 45%;
`;

export const Textarea = styled(Skeleton)`
  width: 100%;
  height: 170px;
`;
