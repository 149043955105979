import styled from 'styled-components/macro';

import * as TooltipPrimitive from '@radix-ui/react-tooltip';

export const TooltipArrow = styled(TooltipPrimitive.Arrow)`
  fill: ${({ theme }) => theme.colors.neutral.darkest};
  width: 12px;
  height: 6px;
  margin: 0 16px;
  border: unset;
`;
