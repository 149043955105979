export enum EUserOnboardingStep {
  FIRST_STEP = 'FIRST_STEP',
  SECOND_STEP = 'SECOND_STEP',
  THIRD_STEP = 'THIRD_STEP',
}

export enum EBusinessModel {
  DROPSHIPPING_NATIONAL = 'DROPSHIPPING_NATIONAL',
  DROPSHIPPING_INTERNATIONAL = 'DROPSHIPPING_INTERNATIONAL',
  DROPSHIPPING_GLOBAL = 'DROPSHIPPING_GLOBAL',
  INFOPRODUCT = 'INFOPRODUCT',
  ECOMMERCE = 'ECOMMERCE',
  OTHER = 'OTHER',
}

export enum EExperienceTime {
  LESS_THAN_SIX_MONTHS = 'LESS_THAN_SIX_MONTHS',
  BETWEEN_SIX_MONTHS_AND_A_YEAR = 'BETWEEN_SIX_MONTHS_AND_A_YEAR',
  BETWEEN_ONE_AND_TWO_YEARS = 'BETWEEN_ONE_AND_TWO_YEARS',
  BETWEEN_TWO_AND_THREE_YEARS = 'BETWEEN_TWO_AND_THREE_YEARS',
  MORE_THAN_THREE_YEARS = 'MORE_THAN_THREE_YEARS',
}

export enum EMonthlyRevenue {
  LESS_THAN_5_THOUSAND = 'LESS_THAN_5_THOUSAND',
  BETWEEN_5_AND_10_THOUSAND = 'BETWEEN_5_AND_10_THOUSAND',
  BETWEEN_10_AND_20_THOUSAND = 'BETWEEN_10_AND_20_THOUSAND',
  BETWEEN_20_AND_50_THOUSAND = 'BETWEEN_20_AND_50_THOUSAND',
  BETWEEN_50_AND_100_THOUSAND = 'BETWEEN_50_AND_100_THOUSAND',
  BETWEEN_100_AND_200_THOUSAND = 'BETWEEN_100_AND_200_THOUSAND',
  BETWEEN_200_AND_500_THOUSAND = 'BETWEEN_200_AND_500_THOUSAND',
  BETWEEN_500_AND_1_MILLION = 'BETWEEN_500_AND_1_MILLION',
  MORE_THAN_1_MILLION = 'MORE_THAN_1_MILLION',
}

export enum EWhereDidYouFindUs {
  ADS = 'ADS',
  GOOGLE = 'GOOGLE',
  RECOMMENDATION = 'RECOMMENDATION',
  EVENT = 'EVENT',
  PLATFORM_OR_CHECKOUT = 'PLATFORM_OR_CHECKOUT',
}
