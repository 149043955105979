import React from 'react';

import * as S from './styles';

interface ICheckboxProps {
  className?: string;
  checked?: boolean;
  innerRef?: any;
  name?: string;
  onClick?: (data: any) => void;
  disabled?: boolean;
  onChange?: (event: any) => void;
  id?: string;
  defaultChecked?: boolean;
  value?: string;
  isBigVariant?: boolean;
}

const Checkbox: React.InputHTMLAttributes<HTMLInputElement> & React.FC<ICheckboxProps> = ({
  className,
  innerRef,
  disabled,
  checked = false,
  onChange = () => null,
  defaultChecked,
  id,
  value,
  isBigVariant = false,
  ...rest
}) => {
  const checkboxRef = React.useRef<HTMLInputElement>(null);

  const [isChecked, setIsChecked] = React.useState<boolean>(checked);

  const handleIsChecked = React.useCallback(() => {
    if (!disabled) {
      setIsChecked(!isChecked);

      if (checkboxRef?.current) {
        checkboxRef.current.checked = !isChecked;

        onChange(checkboxRef.current);
      }
    }
  }, [isChecked, disabled, onChange]);

  React.useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  React.useEffect(() => {
    if (defaultChecked) {
      setIsChecked(defaultChecked);
    }
  }, [defaultChecked]);

  return (
    <S.Container className={className} onClick={handleIsChecked}>
      <S.CheckboxInput
        value={value}
        onChange={onChange}
        ref={innerRef || checkboxRef}
        checked={isChecked}
        id={id}
        {...rest}
      />

      <S.CheckboxDiv checked={isChecked} disabled={disabled} isBigVariant={isBigVariant}>
        <S.Icon viewBox="0 0 24 24">
          {isBigVariant ? (
            <polyline points="20 6 9 17 4 12" />
          ) : (
            <polyline points="20 6 9 17 4 12" />
          )}
        </S.Icon>
      </S.CheckboxDiv>
    </S.Container>
  );
};

export default React.memo(Checkbox);
