import styled from 'styled-components/macro';

import LinkBase from '@components/common/Navigation/Link';
import TextBase from '@components/common/DataDisplay/Text';
import { ArrowSquareOut, Warning } from 'phosphor-react';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Text = styled(TextBase)`
  font-size: 1.6rem;
  font-weight: 500;
  text-align: justify;
`;

export const ScopesCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  width: 100%;
  border-radius: 8px;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.warning.lighter_10};
`;

export const ScopeText = styled(TextBase)`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.warning.dark};
`;

export const WrapperIconAndScope = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const WrapperLinksActions = styled.div`
  display: flex;
  align-self: end;
  gap: 16px;
`;

export const AccessLinkText = styled(TextBase)`
  color: ${({ theme }) => theme.colors.neutral.black};
  font-size: 1.6rem;
  font-weight: 500;
`;

export const BaseAccessLink = styled(LinkBase)`
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  padding: 10px 16px;
  text-decoration: none !important;
`;

export const AccessLinkArticle = styled(BaseAccessLink)`
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
`;
export const AccessLinkShopify = styled(BaseAccessLink)`
  background-color: ${({ theme }) => theme.colors.primary.green_lemon};
`;

export const ArrowSquareOutIcon = styled(ArrowSquareOut).attrs(props => ({
  size: 20,
  weight: 'bold',
  color: props.theme.colors.neutral.black,
}))``;

export const WarningIcon = styled(Warning).attrs(props => ({
  size: 16,
  weight: 'bold',
  color: props.theme.colors.warning.dark,
}))``;
