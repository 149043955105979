import styled from 'styled-components/macro';

import HeadingBase from '@components/common/DataDisplay/Heading';
import TextBase from '@components/common/DataDisplay/Text';
import ImageBase from '@components/common/DataDisplay/Image';

export const Wrapper = styled.div`
  padding: 24px;
  height: 300px;
  box-sizing: border-box;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.neutral.white};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
`;

export const PaymentMethodIcon = styled(ImageBase)`
  width: 36px;
`;

export const PaymentMethodText = styled(TextBase)`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.neutral.dark};
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Divider = styled.hr`
  margin: 16px 0;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
`;

export const Value = styled(TextBase)`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.neutral.darkest};
`;

export const Label = styled(TextBase)`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.neutral.dark};
`;

export const LabelAndValue = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DateText = styled(TextBase)`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.neutral.dark};
`;

export const Title = styled(HeadingBase)`
  color: ${({ theme }) => theme.colors.neutral.black};
`;

export const IdText = styled(TextBase)`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.neutral.darker};
`;

export const TitleAndIdWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const TitleWrapper = styled.div`
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DetailPayment = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  gap: 16px;
`;
