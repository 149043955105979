import React from 'react';
import { CaretCircleLeft, Check, LockSimple, X } from 'phosphor-react';
import { ClockCountdown, PiggyBank } from '@phosphor-icons/react';

import { ETransactionsType } from '@domain/enums/common/statementTransactions/ETransactionsType';

import PIX from '@components/icons/Pix';
import * as S from './styles';

interface IBadgeProps {
  status: ETransactionsType;
}

const statusMap = {
  [ETransactionsType.Dispute]: {
    text: 'Em disputa',
    status: ETransactionsType.Dispute,
    icon: <LockSimple weight="bold" size={16} />,
  },
  [ETransactionsType.AnticipationDeposit]: {
    text: 'Antecipação liberada',
    status: ETransactionsType.AnticipationDeposit,
    icon: <ClockCountdown weight="bold" size={16} />,
  },
  [ETransactionsType.AnticipationWithdrawal]: {
    text: 'Antecipação',
    status: ETransactionsType.AnticipationWithdrawal,
    icon: <ClockCountdown weight="bold" size={16} />,
  },
  [ETransactionsType.Refund]: {
    text: 'Estorno',
    status: ETransactionsType.Refund,
    icon: <CaretCircleLeft weight="bold" size={16} />,
  },
  [ETransactionsType.Payment]: {
    text: 'Transação aprovada',
    status: ETransactionsType.Payment,
    icon: <Check weight="bold" size={16} />,
  },
  [ETransactionsType.Payout]: {
    text: 'Saque solicitado',
    status: ETransactionsType.Payout,
    icon: <PIX size="16" fillColor="#515151" />,
  },
  [ETransactionsType.BalanceReserveWithdrawal]: {
    text: 'Reserva financeira',
    status: ETransactionsType.BalanceReserveWithdrawal,
    icon: <PiggyBank weight="bold" size={16} />,
  },
  [ETransactionsType.PayoutFailure]: {
    text: 'Saque devolvido',
    status: ETransactionsType.PayoutFailure,
    icon: <X weight="bold" size={16} />,
  },
  [ETransactionsType.PayoutCancel]: {
    text: 'Saque cancelado',
    status: ETransactionsType.PayoutCancel,
    icon: <X weight="bold" size={16} />,
  },
  [ETransactionsType.BalanceReserveDeposit]: {
    text: 'Reserva liberada',
    status: ETransactionsType.BalanceReserveDeposit,
    icon: <PiggyBank weight="bold" size={16} />,
  },
  [ETransactionsType.BalanceAdjustment]: {
    text: 'Ajuste de saldo',
    status: ETransactionsType.BalanceAdjustment,
    icon: <X weight="bold" size={16} />,
  },
  [ETransactionsType.RefundFailure]: {
    text: 'Falha no estorno',
    status: ETransactionsType.RefundFailure,
    icon: <X weight="bold" size={16} />,
  },
  [ETransactionsType.ChargeBack]: {
    text: 'Chargeback',
    status: ETransactionsType.ChargeBack,
    icon: <X weight="bold" size={16} />,
  },
  [ETransactionsType.RdrFee]: {
    text: 'Taxa de prevenção',
    status: ETransactionsType.RdrFee,
    icon: <X weight="bold" size={16} />,
  },
  [ETransactionsType.BalanceReserveWithdrawalRefund]: {
    text: 'Reserva estornada',
    status: ETransactionsType.BalanceReserveWithdrawalRefund,
    icon: <CaretCircleLeft weight="bold" size={16} />,
  },
  [ETransactionsType.BalanceReserveDepositRefund]: {
    text: 'Reserva estornada',
    status: ETransactionsType.BalanceReserveDepositRefund,
    icon: <CaretCircleLeft weight="bold" size={16} />,
  },
  [ETransactionsType.BalanceReserveWithdrawalDispute]: {
    text: 'Reserva em chargeback',
    status: ETransactionsType.BalanceReserveWithdrawalDispute,
    icon: <CaretCircleLeft weight="bold" size={16} />,
  },
  [ETransactionsType.BalanceReserveDepositDispute]: {
    text: 'Reserva em chargeback',
    status: ETransactionsType.BalanceReserveDepositDispute,
    icon: <CaretCircleLeft weight="bold" size={16} />,
  },
};

const Badge: React.FC<IBadgeProps> = ({ status }) => {
  const {
    text,
    status: mappedStatus,
    icon,
  } = statusMap[status] || statusMap[ETransactionsType.Payout];

  return (
    <S.Badge status={mappedStatus}>
      {icon} {text}
    </S.Badge>
  );
};

export default Badge;
