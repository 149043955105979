import React from 'react';

import { useAnalytics } from '@store/context/common/AnalyticsContext';

import NetRevenue from '../Chart/NetRevenue';
import CheckoutConversion from '../Chart/CheckoutConversion';
import Overview from '../Chart/Overview';
import Metrics from '../Chart/Metrics';

import * as S from './styles';

const Body: React.FC = () => {
  const { analytics } = useAnalytics();

  React.useEffect(() => {
    analytics.track('General Reports Page Viewed');
  }, [analytics]);

  return (
    <S.Wrapper>
      <NetRevenue />
      <CheckoutConversion />
      <Metrics />
      <Overview />
    </S.Wrapper>
  );
};

export default Body;
