import styled from 'styled-components/macro';

import HeadingBase from '@components/common/DataDisplay/Heading';
import ImageBase from '@components/common/DataDisplay/Image';
import TextBase from '@components/common/DataDisplay/Text';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.neutral.white};
`;

export const Title = styled(HeadingBase)`
  color: ${({ theme }) => theme.colors.neutral.darkest};
`;

export const MiniImage = styled(ImageBase)`
  width: 80px;
  height: 56px;
`;

export const Logo = styled(ImageBase)`
  max-width: 51px;
  max-height: 17px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Preview = styled(ImageBase)`
  border-radius: 6px;
  padding: 48px 73px 0 73px;
  background-color: ${({ theme }) => theme.colors.neutral.lightest};
`;

export const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const ImageAndLogoWrapper = styled.div`
  width: 80px;
  position: relative;
`;

export const NameTheme = styled(TextBase)`
  font-weight: 500;
`;

export const AddDateTheme = styled(TextBase)`
  font-weight: 500;
  font-size: 1.2rem;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
