import React from 'react';
import toastBase, { DefaultToastOptions } from 'react-hot-toast';
import { X, CheckCircle, WarningOctagon, Warning, Info } from '@phosphor-icons/react';
import { useTheme } from 'styled-components/macro';

const ToastContext = React.createContext<any>(null);

interface ICustomToastProps {
  icon: React.ReactNode;
  type: 'success' | 'error' | 'warning' | 'info';
  message: string;
}

export const ToastProvider: React.FC = ({ children }): any => {
  const theme = useTheme();

  const defaultConfig: DefaultToastOptions = {
    duration: 6000,
    custom: {
      style: {
        cursor: 'pointer',
        alignSelf: 'flex-start',
      },
    },
    style: {
      background: '#fff3e8',
      padding: '16px',
      borderRadius: '5px',
      color: theme.colors.neutral.black,
      fontFamily: theme.fonts.text,
      display: 'flex',
      alignItems: 'center',
      minWidth: '288px',
      maxWidth: '420px',
      fontWeight: 500,
      fontSize: '1.6rem',
      gap: '16px',
      zIndex: '11111',
      boxShadow: '0px 16px 24px 0px rgba(0, 0, 0, 0.1)',
    },
  };

  const customStyles = {
    success: {
      background: theme.colors.positive.lightest_10,
      color: theme.colors.positive.base,
    },
    error: {
      background: theme.colors.negative.lightest,
      color: theme.colors.negative.base,
    },
    warning: {
      background: theme.colors.warning.lighter_10,
      color: theme.colors.warning.base,
    },
    info: {
      background: theme.colors.info.lightest_10,
      color: theme.colors.info.base,
    },
  };

  const CustomToast: React.FC<ICustomToastProps> = ({ message, icon, type }) => {
    return (
      <div style={{ ...defaultConfig.style, ...customStyles[type] }}>
        {icon}
        <span>{message}</span>
        <X
          style={{ ...defaultConfig.custom?.style }}
          onClick={() => toastBase.remove()}
          size={13}
          weight="bold"
          color={customStyles[type].color}
        />
      </div>
    );
  };

  const toast = {
    info: (message: string): string =>
      toastBase.custom(() => (
        <CustomToast
          message={message}
          icon={<Info size={18} weight="fill" color={theme?.colors.info.base} />}
          type="info"
        />
      )),
    success: (message: string): string =>
      toastBase.custom(() => (
        <CustomToast
          message={message}
          icon={<CheckCircle size={18} weight="fill" color={theme?.colors.positive.base} />}
          type="success"
        />
      )),
    error: (message: string, error: any | undefined): string => {
      if (message === 'Store does not exists' && error) {
        throw new Error(error);
      }

      if (message === 'Store does not exists') return '';

      return toastBase.custom(() => (
        <CustomToast
          message={message}
          icon={<WarningOctagon size={18} weight="fill" color={theme?.colors.negative.base} />}
          type="error"
        />
      ));
    },
    warning: (message: string): string =>
      toastBase.custom(() => (
        <CustomToast
          message={message}
          icon={<Warning size={18} weight="fill" color={theme?.colors.warning.base} />}
          type="warning"
        />
      )),
    error_long: (message: string, duration = 10000): string => {
      return toastBase.error(message, {
        ...defaultConfig,
        duration,
        style: {
          ...defaultConfig.style,
          ...customStyles.error,
        },
      });
    },
    loading: (message: string): string =>
      toastBase.loading(message, {
        ...defaultConfig,
        duration: undefined,
        style: { ...defaultConfig.style },
      }),
    dismiss: (toastId: string) => toastBase.dismiss(toastId),
    promise: (
      promise: any,
      loadingMessage: string,
      successMessage: string,
      errorMessage: string,
    ): any =>
      toastBase.promise(
        promise,
        {
          loading: loadingMessage,
          success: successMessage,
          error: errorMessage,
        },
        {
          style: {
            ...defaultConfig.style,
          },
        },
      ),
  };

  return <ToastContext.Provider value={{ toast }}>{children}</ToastContext.Provider>;
};

export const useToast = (): any => {
  const context = React.useContext(ToastContext);

  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }

  return context;
};
