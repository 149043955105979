import styled from 'styled-components/macro';

import ButtonBase from '@components/common/Inputs/Button';
import HeadingBase from '@components/common/DataDisplay/Heading';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  background-color: ${({ theme }) => theme.colors.neutral.white};
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  border-radius: 4px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;

  & > button {
    align-self: flex-end;
  }
`;

export const Title = styled(HeadingBase)``;

export const SubmitButton = styled(ButtonBase)``;
