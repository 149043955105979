import React from 'react';
import { Plus } from 'phosphor-react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components/macro';
import PageHeader from '@components/common/utils/PageHeader';

import { EButtonVariant } from '@domain/enums/components/common/EButton';

import * as S from './styles';

const Header: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { accountId, checkoutId } = useParams();

  const onNewButtonClick = (): void => {
    navigate(`/${accountId}/${checkoutId}/dashboard/bank-accounts/new`);
  };

  return (
    <S.Wrapper>
      <PageHeader
        title="Dados bancários"
        description="Gerencie todas as suas contas bancárias cadastradas"
      />

      <S.NewBankAccountButton variant={EButtonVariant.SECONDARY} onClick={onNewButtonClick}>
        <Plus size={12} weight="bold" color={theme.colors.neutral.black} /> Nova conta bancária
      </S.NewBankAccountButton>
    </S.Wrapper>
  );
};

export default Header;
