import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { FieldValues, useForm } from 'react-hook-form';

import { INewBankAccountProvider } from '@domain/interfaces/store/common/BankAccount/INewBankAccountProvider';

import { useToast } from '@store/context/common/ToastContext';
import { useErrorHandler } from '@store/context/common/ErrorHandlerContext';
import { useAnalytics } from '@store/context/common/AnalyticsContext';
import { useBankAccountService } from '@hooks/services/BankAccount/useBankAccountService';
import { bankAccountSchema } from '@helpers/validators/pages/dashboard/bankAccount/bankAccountSchema';
import { ICreateBankAccount } from '@domain/interfaces/hooks/services/bankAccount/IBankAccountService';

const NewBankAccountContext = React.createContext<INewBankAccountProvider | null>(null);

export const NewBankAccountProvider: React.FC = ({ children }) => {
  const { toast } = useToast();
  const navigation = useNavigate();
  const { analytics } = useAnalytics();
  const { handleError } = useErrorHandler();
  const { accountId, checkoutId } = useParams();
  const { createBankAccount } = useBankAccountService();

  const [isOpenModal, setIsOpenModal] = React.useState<boolean>(false);
  const [isCreatingBankAccount, setIsCreatingBankAccount] = React.useState<boolean>(false);
  const [selectedBank, setSelectedBank] = React.useState<{
    code: string;
    name: string;
    ispb: string;
  }>();
  const [bankAccountData, setBankAccountData] = React.useState<ICreateBankAccount | null>(null);

  const {
    handleSubmit,
    register,
    setValue,
    reset,
    formState: { errors, isValid },
  } = useForm({ resolver: yupResolver(bankAccountSchema) });

  const typeRegister = register('type');
  const bankCodeRegister = register('bankCode');
  const holderNameRegister = register('holderName');
  const routingNumberRegister = register('routingNumber');
  const accountDigitRegister = register('accountDigit');
  const accountNumberRegister = register('accountNumber');
  const holderDocumentRegister = register('holderDocument');

  const onSubmit = React.useCallback(
    (formData: FieldValues) => {
      const payload: ICreateBankAccount = {
        account_number: formData.accountNumber,
        account_digit: formData.accountDigit,
        bank_ispb: selectedBank?.ispb || '',
        bank_code: formData.bankCode,
        holder_document: formData?.holderDocument?.replace(/[./-]/g, ''),
        holder_name: formData.holderName,
        routing_number: formData.routingNumber,
        type: formData.type,
      };

      setBankAccountData(payload);
    },
    [selectedBank?.ispb],
  );

  const onCreateBankAccount = React.useCallback(
    async payload => {
      setIsCreatingBankAccount(true);
      const { account_digit, ...newPayload } = payload;
      const data = {
        ...newPayload,
        account_number: `${newPayload.account_number}-${account_digit}`,
      };
      try {
        await createBankAccount({ accountId, payload: data });

        analytics.track('Create Bank Account');

        setIsCreatingBankAccount(false);
        reset();
        setSelectedBank({ code: '', name: '', ispb: '' });
        setIsOpenModal(false);
        toast.success('Conta bancária criada com sucesso!');
        navigation(`/${accountId}/${checkoutId}/dashboard/bank-accounts`);
      } catch (err) {
        setIsCreatingBankAccount(false);
        handleError(err);
      } finally {
        setIsCreatingBankAccount(false);
      }
    },
    [createBankAccount, handleError, toast, accountId, checkoutId, analytics, reset, navigation],
  );

  return (
    <NewBankAccountContext.Provider
      value={{
        errors,
        isValid,
        onSubmit,
        setValue,
        handleSubmit,
        selectedBank,
        setSelectedBank,
        typeRegister,
        bankAccountData,
        bankCodeRegister,
        holderNameRegister,
        isCreatingBankAccount,
        routingNumberRegister,
        accountNumberRegister,
        accountDigitRegister,
        holderDocumentRegister,
        isOpenModal,
        setIsOpenModal,
        onCreateBankAccount,
      }}
    >
      {children}
    </NewBankAccountContext.Provider>
  );
};

export const useNewBankAccount = (): INewBankAccountProvider => {
  const context = React.useContext(NewBankAccountContext);

  if (!context) {
    throw new Error('useNewBankAccount must be used within NewBankAccountProvider');
  }

  return context;
};
