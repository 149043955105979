import React from 'react';

import { GetGeneralProvider } from '@store/common/general/GetGeneralContext';

import Content from '@components/pages/Dashboard/General';

const General: React.FC = () => {
  return (
    <GetGeneralProvider>
      <Content />
    </GetGeneralProvider>
  );
};

export default General;
