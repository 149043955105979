import styled from 'styled-components/macro';

import { WhatsappLogo } from 'phosphor-react';

import TableBase from '@components/common/utils/Table';
import ChipBase from '@components/common/DataDisplay/Chip';
import LinkBase from '@components/common/Navigation/Link';
import TextBase from '@components/common/DataDisplay/Text';
import CustomTooltipBase from '@components/common/utils/CustomTooltip';

export const Table = styled(TableBase)``;
export const Row = styled(TableBase.Row)`
  filter: brightness(1) !important;
`;

export const Actions = styled.div`
  width: 24px;
  cursor: pointer;
`;

export const Chip = styled(ChipBase)`
  h1 {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

export const TransactionAmount = styled(TextBase)``;

export const CustomerName = styled(TextBase)`
  max-width: 180px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const DateText = styled(TextBase)`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.neutral.dark};
`;

export const OrderIdText = styled(LinkBase)`
  font-size: 1.4rem;
  font-weight: 400;
`;

export const OrderIdAndDateWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const WrapperIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.info.white_blue};
  padding: 5px;
  border-radius: 50px;
`;

export const WhatsAppIcon = styled(WhatsappLogo).attrs({
  size: 18,
})`
  color: ${({ theme }) => theme.colors.neutral.darker};
  cursor: pointer;

  &:hover {
    ${WrapperIcon}
  }
`;

export const Tooltip = styled(CustomTooltipBase)``;
export const TooltipTrigger = styled(CustomTooltipBase.Trigger)``;

export const TooltipContent = styled(CustomTooltipBase.Content)``;

export const TooltipText = styled(TextBase)`
  color: ${({ theme }) => theme.colors.neutral.gray_white};
  font-size: 1.2rem;
`;
