import styled from 'styled-components/macro';

export const DateButton = styled.button`
  height: 42px;
  padding: 16px 24px;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.neutral.darker};
  font-family: ${({ theme }) => theme.fonts.text};
  background-color: unset;
  text-align: left;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => `${theme.colors.primary.green_lemon}33`};
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  justify-content: center;
  height: 350px;
`;
