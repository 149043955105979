import React from 'react';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import * as S from './styles';

const SkeletonLoading: React.FC = () => {
  return (
    <S.Wrapper>
      <S.Header>
        <S.TitleAndButtonWrapper>
          <Skeleton width="160px" height="40px" />
          <Skeleton width="140px" height="40px" />
        </S.TitleAndButtonWrapper>

        <Skeleton width="100%" height="25px" />
      </S.Header>

      <S.CustomerCard>
        <Skeleton width="20%" height="15px" />
        <S.ContentCard>
          {Array.from({ length: 3 }).map((_, index) => (
            <S.InfoGroup key={index.toFixed()}>
              <S.InfoWrapper>
                <S.LabelSkeleton />
                <S.TextValueSkeleton />
              </S.InfoWrapper>

              <S.InfoWrapper>
                <S.LabelSkeleton />
                <S.TextValueSkeleton />
              </S.InfoWrapper>
            </S.InfoGroup>
          ))}
        </S.ContentCard>
      </S.CustomerCard>
    </S.Wrapper>
  );
};

export default SkeletonLoading;
