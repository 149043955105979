import Icon from '../../assets/images/common/providers';

const { AWS, CLOUDFLARE, GODADDY, HOSTGATOR, OTHERS, SHOPIFY, SQUARESPACE, HOSTINGER } = Icon;

export const DOMAIN_PROVIDERS = [
  {
    id: 1,
    icon: AWS,
    provider: 'AWS',
    providerUrl: 'https://aws.amazon.com',
    providerTutorialUrl: 'https://www.youtube.com/watch?v=KX_ArCXZr_s',
  },
  {
    id: 2,
    icon: CLOUDFLARE,
    provider: 'CloudFlare',
    providerUrl: 'https://cloudflare.com',
    providerTutorialUrl: 'https://www.youtube.com/watch?v=KX_ArCXZr_s',
  },
  {
    id: 3,
    icon: GODADDY,
    provider: 'GoDaddy',
    providerUrl: 'https://godaddy.com',
    providerTutorialUrl: 'https://www.youtube.com/watch?v=KX_ArCXZr_s',
  },
  {
    id: 4,
    icon: HOSTINGER,
    provider: 'Hostinger',
    providerUrl: 'https://hostinger.com',
    providerTutorialUrl: 'https://www.youtube.com/watch?v=KX_ArCXZr_s',
  },
  {
    id: 5,
    icon: HOSTGATOR,
    provider: 'HostGator',
    providerUrl: 'https://hostgator.com',
    providerTutorialUrl: 'https://www.youtube.com/watch?v=KX_ArCXZr_s',
  },
  {
    id: 6,
    icon: SQUARESPACE,
    provider: 'Squarespace',
    providerUrl: 'https://squarespace.com',
    providerTutorialUrl: 'https://www.youtube.com/watch?v=KX_ArCXZr_s',
  },
  {
    id: 7,
    icon: SHOPIFY,
    provider: 'Shopify',
    providerUrl: 'https://shopify.com',
    providerTutorialUrl: 'https://www.youtube.com/watch?v=KX_ArCXZr_s',
  },
  {
    id: 8,
    icon: OTHERS,
    provider: 'Outros',
    providerUrl: 'https://others.com',
    providerTutorialUrl: 'https://www.youtube.com/watch?v=KX_ArCXZr_s',
  },
];
