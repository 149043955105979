import styled from 'styled-components/macro';
import { WhatsappLogo } from 'phosphor-react';

import HeadingBase from '@components/common/DataDisplay/Heading';
import TextBase from '@components/common/DataDisplay/Text';
import ButtonBase from '@components/common/Inputs/Button';
import LinkBase from '@components/common/Navigation/Link';
import CustomTooltipBase from '@components/common/utils/CustomTooltip';

export const Wrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  padding-bottom: 24px;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
`;

export const Description = styled(TextBase)``;

export const TitleAndSubtitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Subtitle = styled(TextBase)``;

export const Title = styled(HeadingBase)``;

export const TitleAndButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  width: 100%;
`;

export const SendMessageButton = styled(ButtonBase)`
  & > span {
    color: ${({ theme }) => theme.colors.tertiary.dark};
  }
`;

export const DateText = styled(TextBase)`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.neutral.dark};
`;

export const OrderIdText = styled(LinkBase)`
  font-size: 1.6rem;
  font-weight: 400;
`;

export const OrderIdAndDateWrapper = styled.div`
  display: flex;
  align-items: self-end;
  gap: 8px;
`;

export const WhatsAppIcon = styled(WhatsappLogo).attrs({
  size: 18,
})`
  color: ${({ theme }) => theme.colors.tertiary.dark};
`;

export const Tooltip = styled(CustomTooltipBase)``;
export const TooltipTrigger = styled(CustomTooltipBase.Trigger)``;

export const TooltipContent = styled(CustomTooltipBase.Content)``;

export const TooltipText = styled(TextBase)`
  color: ${({ theme }) => theme.colors.neutral.gray_white};
  font-size: 1.2rem;
`;
