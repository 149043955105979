import useSWR from 'swr';
import React, { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FileError } from 'react-dropzone';

import { ISwr } from '@domain/interfaces/common/swr/ISwr';
import { ICheckoutCustomization } from '@domain/interfaces/common/checkoutCustomization/ICheckoutCustomization';
import { IGetCheckoutCustomizationResponse } from '@domain/interfaces/hooks/services/checkoutCustomization/ICheckoutCustomizationService';
import { IUpdateCheckoutCustomizationProvider } from '@domain/interfaces/store/common/checkoutCustomization/IUpdateCheckoutCustomizationProvider';
import { EThemeCheckout } from '@domain/enums/common/checkoutCustomization/EThemeCheckout';

import { DASHBOARD_API_URL } from '@constants/services/apiUrl';
import { dashboardInstance } from '@services/common/dashboardInstance';

import { useToast } from '@store/context/common/ToastContext';
import { useErrorHandler } from '@store/context/common/ErrorHandlerContext';
import { useCheckoutCustomizationService } from '@hooks/services/checkoutCustomization/useCheckoutCustomizationService';
import { useAnalytics } from '@store/context/common/AnalyticsContext';

export interface IUploadAbleFile {
  file: File;
  errors: FileError[];
  url?: string;
  preview?: string;
}

interface IUploadProps {
  mappedAcc: IUploadAbleFile[];
  type: string;
}

interface IResponseUpload {
  url: string;
  path: string;
}

interface ISelectedThemeCheckout {
  created_at?: string;
  theme: EThemeCheckout;
  id?: string;
}

const UpdateCheckoutCustomizationContext =
  React.createContext<IUpdateCheckoutCustomizationProvider | null>(null);

export const EditCheckoutCustomizationProvider: React.FC = ({ children }) => {
  const navigate = useNavigate();
  const { analytics } = useAnalytics();
  const { accountId, checkoutId, checkoutCustomizationId } = useParams();
  const {
    updateCheckoutCustomization,
    deleteUploadCheckoutCustomization,
    uploadCheckoutCustomization,
  } = useCheckoutCustomizationService();
  const { toast } = useToast();
  const { handleError } = useErrorHandler();

  const [isUpdateCheckout, setIsUpdateCheckout] = React.useState<boolean>(false);
  const [checkoutCustomization, setCheckoutCustomization] = React.useState<ICheckoutCustomization>(
    {} as ICheckoutCustomization,
  );
  const [logoImage, setLogoImage] = React.useState<IUploadAbleFile[]>([]);
  const [faviconImage, setFaviconImage] = React.useState<IUploadAbleFile[]>([]);
  const [responseUploadLogo, setResponseUploadLogo] = React.useState<IResponseUpload>({
    url: '',
    path: '',
  } as IResponseUpload);
  const [responseUploadFavicon, setResponseUploadFavicon] = React.useState<IResponseUpload>({
    url: '',
    path: '',
  } as IResponseUpload);

  const [selectedTab, setSelectedTab] = React.useState<number>(0);
  const onChangeTab = React.useCallback(index => setSelectedTab(index), []);
  const [selectedThemeCheckout, setSelectedThemeCheckout] = React.useState<ISelectedThemeCheckout>({
    created_at: '',
    id: '',
    theme: EThemeCheckout.ZOUTI,
  } as ISelectedThemeCheckout);

  const bytesToMegaBytes = (bytes: number): string => (bytes / 1024).toFixed(2);
  const fileSizeKB = bytesToMegaBytes(logoImage[0]?.file?.size);

  dashboardInstance.defaults.headers.common['x-zouti-account'] = accountId;

  const CHECKOUT_CUSTOMIZATION_URL = `${DASHBOARD_API_URL}/accounts/${accountId}/checkouts/${checkoutId}/checkout_customizations/${checkoutCustomizationId}`;

  const {
    data,
    isLoading: isLoadingGetCheckoutCustomizationId,
    isValidating,
    error,
    mutate,
  } = useSWR<ISwr<IGetCheckoutCustomizationResponse>>(
    CHECKOUT_CUSTOMIZATION_URL,
    dashboardInstance,
  );

  React.useEffect(() => {
    if (error) {
      handleError(error);
    }

    if (data?.data) {
      setCheckoutCustomization(data.data.object);
      if (data.data.object.header_customization.logo_url) {
        setLogoImage([
          { preview: data.data.object.header_customization.logo_url },
        ] as IUploadAbleFile[]);
        setResponseUploadLogo({
          url: data.data.object.header_customization.logo_url,
          path: data.data.object.header_customization.logo_path || '',
        });
      }
      if (data.data.object.advanced_customization.favicon_url) {
        setFaviconImage([
          { preview: data.data.object.advanced_customization.favicon_url },
        ] as IUploadAbleFile[]);
        setResponseUploadFavicon({
          url: data.data.object.advanced_customization.favicon_url,
          path: data.data.object.advanced_customization.favicon_path || '',
        });
      }
      if (data.data.object.checkout_theme) {
        setSelectedThemeCheckout(data.data.object.checkout_theme);
      }
    }
  }, [data, handleError, error]);

  const increaseTime = React.useCallback(() => {
    setCheckoutCustomization(state => ({
      ...state,
      header_customization: {
        ...state.header_customization,
        timer_amount: state.header_customization.timer_amount + 1,
      },
    }));
  }, []);

  const decreaseTime = React.useCallback(() => {
    if (checkoutCustomization?.header_customization?.timer_amount === 0) return;

    setCheckoutCustomization(state => ({
      ...state,
      header_customization: {
        ...state.header_customization,
        timer_amount: state.header_customization.timer_amount - 1,
      },
    }));
  }, [checkoutCustomization?.header_customization?.timer_amount]);

  const updateCheckoutTheme = React.useCallback(
    async (theme: EThemeCheckout) => {
      if (!data?.data.object) return;

      setIsUpdateCheckout(true);

      try {
        await updateCheckoutCustomization({
          checkoutCustomizationId: checkoutCustomization?.id,
          payload: data.data.object,
          checkout_theme: {
            theme,
          },
          accountId,
          checkoutId,
        });

        toast.success('Tema atualizado com sucesso!');

        setIsUpdateCheckout(false);
      } catch (err) {
        setIsUpdateCheckout(true);
        handleError(err);
      }
    },
    [
      accountId,
      checkoutId,
      checkoutCustomization,
      data,
      handleError,
      toast,
      updateCheckoutCustomization,
    ],
  );

  const updateCurrentCheckout = React.useCallback(
    async currentCheckout => {
      try {
        setIsUpdateCheckout(true);
        await updateCheckoutCustomization({
          checkoutCustomizationId: checkoutCustomization?.id,
          ...currentCheckout,
          checkout_theme: { theme: selectedThemeCheckout.theme },
          accountId,
          checkoutId,
        });

        analytics.track('Checkout Customization Updated');

        toast.success('Customização do checkout atualizada com sucesso');
        await mutate();
        navigate(`/${accountId}/${checkoutId}/dashboard/checkout/customizations`);
      } catch (err) {
        setIsUpdateCheckout(false);
        handleError(err);
      } finally {
        setIsUpdateCheckout(false);
      }
    },
    [
      accountId,
      checkoutId,
      checkoutCustomization,
      navigate,
      toast,
      updateCheckoutCustomization,
      handleError,
      analytics,
      mutate,
      selectedThemeCheckout,
    ],
  );

  const handleImageChange = useCallback(
    async ({ mappedAcc, type }: IUploadProps): Promise<void> => {
      const formData = new FormData();
      const { file } = mappedAcc[0];

      if (file) {
        formData.append('type', type);
        formData.append('file', file, file.name);

        try {
          const response = await uploadCheckoutCustomization({
            payload: formData,
            accountId,
            checkoutId,
          });
          if (type === 'logo') {
            setResponseUploadLogo(response.data.object);
            setLogoImage(mappedAcc);
          } else {
            setResponseUploadFavicon(response.data.object);
            setFaviconImage(mappedAcc);
          }
        } catch (errorUpload) {
          handleError(errorUpload);
        }
      }
    },
    [uploadCheckoutCustomization, handleError, accountId, checkoutId],
  );

  const deleteUploadCheckout = React.useCallback(
    async (path: string, type: string) => {
      try {
        await deleteUploadCheckoutCustomization({ path, accountId, checkoutId });
        if (type === 'logo') {
          setLogoImage([]);
          setResponseUploadLogo({ url: '', path: '' });
        } else {
          setFaviconImage([]);
          setResponseUploadFavicon({ url: '', path: '' });
        }
        toast.success('Imagem deletada com sucesso!');
      } catch (errors) {
        handleError(errors);
      }
    },
    [deleteUploadCheckoutCustomization, handleError, toast, accountId, checkoutId],
  );

  const isLoadingCheckoutCustomization = isLoadingGetCheckoutCustomizationId || isValidating;
  const isCheckoutCustomizationError = Boolean(error);

  return (
    <UpdateCheckoutCustomizationContext.Provider
      value={{
        updateCurrentCheckout,
        deleteUploadCheckout,
        handleImageChange,
        responseUploadLogo,
        responseUploadFavicon,
        isLoadingCheckoutCustomization,
        isCheckoutCustomizationError,
        checkoutCustomization,
        setCheckoutCustomization,
        decreaseTime,
        increaseTime,
        logoImage,
        faviconImage,
        setLogoImage,
        setFaviconImage,
        fileSizeKB,
        isUpdateCheckout,
        selectedTab,
        onChangeTab,
        setSelectedThemeCheckout,
        selectedThemeCheckout,
        updateCheckoutTheme,
      }}
    >
      {children}
    </UpdateCheckoutCustomizationContext.Provider>
  );
};

export const useEditCheckout = (): IUpdateCheckoutCustomizationProvider => {
  const context = React.useContext(UpdateCheckoutCustomizationContext);

  if (!context) {
    throw new Error('useEditCheckout must be used within provider');
  }

  return context;
};
