export enum EUserRole {
  USER = 'USER',
  ADMIN = 'ADMIN',
  SUPPORT = 'SUPPORT',
  SELLER = 'SELLER',
}

export enum EUserType {
  DEMO = 'DEMO',
  STANDARD = 'STANDARD',
  ADMIN = 'ADMIN',
}
