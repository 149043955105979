export enum ETransactionsType {
  Payment = 'PAYMENT',
  Refund = 'REFUND',
  RefundFailure = 'REFUND_FAILURE',
  Dispute = 'DISPUTE',
  Payout = 'PAYOUT',
  PayoutFailure = 'PAYOUT_FAILURE',
  PayoutCancel = 'PAYOUT_CANCEL',
  AnticipationDeposit = 'ANTICIPATION_DEPOSIT',
  AnticipationWithdrawal = 'ANTICIPATION_WITHDRAWAL',
  BalanceReserveWithdrawal = 'BALANCE_RESERVE_WITHDRAWAL',
  BalanceReserveDeposit = 'BALANCE_RESERVE_DEPOSIT',
  BalanceAdjustment = 'BALANCE_ADJUSTMENT',
  ChargeBack = 'CHARGE_BACK',
  RdrFee = 'RDR_FEE',
  BalanceReserveWithdrawalRefund = 'BALANCE_RESERVE_WITHDRAWAL_REFUND',
  BalanceReserveDepositRefund = 'BALANCE_RESERVE_DEPOSIT_REFUND',
  BalanceReserveWithdrawalDispute = 'BALANCE_RESERVE_WITHDRAWAL_DISPUTE',
  BalanceReserveDepositDispute = 'BALANCE_RESERVE_DEPOSIT_DISPUTE',
}
