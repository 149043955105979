import styled from 'styled-components/macro';

import ButtonBase from '@components/common/Inputs/Button';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 8px;
  margin-bottom: 24px;
`;

export const NewWebhookButton = styled(ButtonBase)`
  & > span {
    color: ${({ theme }) => theme.colors.neutral.darker};
  }
`;
