import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { useListUpsells } from '@store/common/upsell/ListUpsellContext';
import { useAnalytics } from '@store/context/common/AnalyticsContext';

import EmptyData from '@components/common/utils/EmptyData';
import ListSkeletonLoading from '@components/common/utils/ListSkeletonLoading';
import Body from './Body';
import Header from './Header';

import * as S from './styles';

const ListUpsells: React.FC = () => {
  const { isLoadingUpsells, isUpsellsError, upsells } = useListUpsells();
  const { accountId, checkoutId } = useParams();
  const { analytics } = useAnalytics();
  const navigate = useNavigate();

  const onEmptyDataButtonClick = React.useCallback(() => {
    navigate(`/${accountId}/${checkoutId}/dashboard/marketing/upsells/new`);
  }, [navigate, accountId, checkoutId]);

  React.useEffect(() => {
    analytics.track('Upsells Page Viewed');
  }, [analytics]);

  const isEmpty = upsells.length === 0;

  if (isLoadingUpsells) {
    return <ListSkeletonLoading />;
  }

  if (isUpsellsError) {
    return <div>Error...</div>;
  }

  if (isEmpty) {
    return (
      <EmptyData
        title="Nenhum upsell cadastrado"
        description="Crie upsells para ofertar nas suas vendas."
        buttonText="Novo upsell"
        onClick={onEmptyDataButtonClick}
      />
    );
  }

  return (
    <S.Wrapper>
      <Header />
      <Body />
    </S.Wrapper>
  );
};

export default ListUpsells;
