import * as yup from 'yup';

import { EWhereDidYouFindUs } from '@domain/enums/common/onboarding/EUser';

export const whereDidYouFindUsSchema = yup.object().shape({
  where_did_you_find_us: yup.string(),
  where_did_you_find_us_value: yup.string().when('where_did_you_find_us', {
    is: (value: string) =>
      value === EWhereDidYouFindUs.EVENT ||
      value === EWhereDidYouFindUs.PLATFORM_OR_CHECKOUT ||
      value === EWhereDidYouFindUs.RECOMMENDATION,
    then: schema =>
      schema.required('É necessário informar esse campo.').min(2, 'Informe uma resposta válida.'),
  }),
});
