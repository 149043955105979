import React from 'react';
import { datadogRum } from '@datadog/browser-rum';
import Gleap from 'gleap';

// Please make sure to call this method only once!
import * as ReactDOMClient from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import App from './App';

Gleap.initialize('CjAr79yQlmhBg53IQ7HGr6lvM8xr7iU3');

if (process.env.REACT_APP_STAGE === 'prod') {
  datadogRum.init({
    applicationId: '2eabe6e9-34ba-4982-b004-4684dbb4ea88',
    clientToken: 'pub6b07ca678de54ef44d38244ed369e752',
    site: 'datadoghq.com',
    service: 'dashboard',
    env: process.env.REACT_APP_STAGE === 'prod' ? 'prod' : 'stage',
    version: process.env.REACT_APP_VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
  });
}

const container = document.getElementById('root');

const root = ReactDOMClient.createRoot(container as Element);

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
);
