import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { EHeadingSize } from '@domain/enums/components/common/EHeading';
// import ZoutiTutorialImage from '@assets/images/common/zouti/zouti-tutorial.svg';
import { firstStepValues } from '@constants/pages/dashboard/home';
import { useGetAccountStep } from '@store/common/home/GetAccountStepContext';
import LinearProgress from '../../LinearProgress';
import ActiveCheckoutModal from './ActiveCheckoutModal';
import CongratulationModal from './CongratulationModal';

import * as S from './styles';

const FirstStep: React.FC = () => {
  const navigate = useNavigate();
  const { accountId, checkoutId } = useParams();
  const {
    isConnectedStore,
    isEnabledCheckoutRow,
    propertiesFirstStep,
    countFirstStep,
    hoveredIndex,
    setHoveredIndex,
    setIsOpenModal,
    isOpenModal,
  } = useGetAccountStep();

  const onRowMouseEnter = React.useCallback(
    index => {
      setHoveredIndex(index);
    },
    [setHoveredIndex],
  );

  const onRowMouseLeave = React.useCallback(() => {
    setHoveredIndex(null);
  }, [setHoveredIndex]);

  const onclickConfiguration = React.useCallback(() => {
    navigate(`/${accountId}/${checkoutId}/dashboard/apps/shopify`);
  }, [navigate, accountId, checkoutId]);

  const stepValues = firstStepValues(accountId, checkoutId);

  return (
    <S.Wrapper>
      <ActiveCheckoutModal />
      <CongratulationModal />
      <S.CardFirstStep>
        <S.HeaderCardFirstStep>
          <S.WrapperIconAndTitle>
            <S.IconContainer>
              <S.HeaderIcon weight="thin" size={14} />
            </S.IconContainer>
            <S.HeaderTitle size={EHeadingSize.H6}>Primeiros Passos</S.HeaderTitle>
          </S.WrapperIconAndTitle>
          <S.WrapperLinearProgress>
            <S.TextStep>
              {countFirstStep} DE {stepValues?.length} ETAPAS
            </S.TextStep>
            <LinearProgress qtdStepDone={countFirstStep} quantitySteps={stepValues?.length} />
          </S.WrapperLinearProgress>
        </S.HeaderCardFirstStep>
        <S.WrapperRows>
          {stepValues?.map((item, index) => {
            const Row = (): JSX.Element => (
              <S.WrapperRow>
                {propertiesFirstStep[item.key] ? <S.CheckCircleIcon /> : <S.CircleIcon />}
                <S.WrapperText keyPath={item?.key} isEnabledCheckoutRow={isEnabledCheckoutRow}>
                  <S.Title>{item.title}</S.Title>
                  <S.Description>{item.description}</S.Description>
                </S.WrapperText>
              </S.WrapperRow>
            );

            const ConnectStoreRow = (): JSX.Element => (
              <S.WrapperConnectedStore
                isConnectedStore={isConnectedStore}
                onClick={onclickConfiguration}
              >
                <Row />
                {!isConnectedStore && (
                  <S.ConnectStoreButton type="button">Conectar loja</S.ConnectStoreButton>
                )}
              </S.WrapperConnectedStore>
            );

            const RedirectLinksRow = (): JSX.Element => (
              <S.Link to={item?.path || ''}>
                <Row />
                {hoveredIndex === index && (
                  <S.WrapperConfigAndArrowIcon>
                    <S.TextLink>Configurar</S.TextLink>
                    <S.ArrowUpRightIcon />
                  </S.WrapperConfigAndArrowIcon>
                )}
              </S.Link>
            );

            const EnableCheckoutRow = (): JSX.Element => (
              <S.WrapperRowEnableCheckout
                keyPath={item?.key}
                isEnabledCheckoutRow={isEnabledCheckoutRow}
                onClick={() =>
                  setIsOpenModal({ ...isOpenModal, activeCheckout: !isOpenModal.activeCheckout })
                }
              >
                <Row />
                {isEnabledCheckoutRow && (
                  <S.WrapperConfigAndArrowIcon>
                    <S.TextLink>Ativar checkout</S.TextLink>
                    <S.ArrowUpRightIcon />
                  </S.WrapperConfigAndArrowIcon>
                )}
              </S.WrapperRowEnableCheckout>
            );

            return (
              <S.CardRowFirstStep
                keyPath={item?.key}
                isEnabledCheckoutRow={isEnabledCheckoutRow}
                onMouseEnter={() => onRowMouseEnter(index)}
                onMouseLeave={onRowMouseLeave}
                key={index.toFixed()}
              >
                {item?.key === 'STORE_CONNECTED_AT' && <ConnectStoreRow />}
                {item?.path && <RedirectLinksRow />}
                {item?.key === 'CHECKOUT_ENABLED_AT' && <EnableCheckoutRow />}
              </S.CardRowFirstStep>
            );
          })}
        </S.WrapperRows>
      </S.CardFirstStep>
      {/* <S.Tutorial
          image_url={ZoutiTutorialImage}
          video_url="https://www.youtube.com/watch?v=Ll03QemJYDE&pp=ygUMbW90aXZhY2lvbmFs"
          title="WooCommerce Integration with Zouti: Quick Tutorial"
        /> */}
    </S.Wrapper>
  );
};

export default FirstStep;
