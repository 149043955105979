/* eslint-disable import/no-extraneous-dependencies */

import { FirebaseApp, FirebaseOptions, initializeApp } from 'firebase/app';
import { RemoteConfig, fetchAndActivate, getRemoteConfig } from 'firebase/remote-config';

const firebaseConfig: FirebaseOptions = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

export const app: FirebaseApp = initializeApp(firebaseConfig);
export const remoteConfig: RemoteConfig = getRemoteConfig(app);
remoteConfig.settings = {
  fetchTimeoutMillis: 60000,
  minimumFetchIntervalMillis: 1,
};

remoteConfig.defaultConfig = {
  app_super_awesome_feature: false,
};

export const fetchRemoteConfig = async (): Promise<void> => {
  await fetchAndActivate(remoteConfig);
};
