import styled from 'styled-components/macro';

import HeadingBase from '@components/common/DataDisplay/Heading';
import TextBase from '@components/common/DataDisplay/Text';

export const Wrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  margin-top: 24px;
`;

// export const Wrapper = styled.div`
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   max-width: 460px;
//   width: 100%;
// `;

export const Title = styled(HeadingBase)`
  margin-bottom: 24px;
  text-align: center;
`;

export const Description = styled(TextBase)`
  font-size: 1.6rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.neutral.base};
  text-align: center;
`;
