import React from 'react';

import EmpytImageSVG from '@assets/images/common/empytImage.svg';

import * as S from './styles';

const EmpytImage: React.FC = props => {
  return <S.Image {...props} src={EmpytImageSVG} alt="Zouti empyt image" />;
};

export default EmpytImage;
