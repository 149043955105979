import React from 'react';

import useSWR from 'swr';

import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { ISwr } from '@domain/interfaces/common/swr/ISwr';
import { IShopifyProvider } from '@domain/interfaces/store/common/shopify/IShopifyProvider';
import { IShopify } from '@domain/interfaces/common/shopify/IShopify';
import {
  ICreateShopifyCredentialData,
  IUpdateShopifyCredentialData,
} from '@domain/interfaces/hooks/services/shopify/IShopifyService';

import { DASHBOARD_API_URL } from '@constants/services/apiUrl';
import { dashboardInstance } from '@services/common/dashboardInstance';
import { shopifySchema } from '@helpers/validators/pages/dashboard/shopify/shopifySchema';

import { useToast } from '@store/context/common/ToastContext';
import { useErrorHandler } from '@store/context/common/ErrorHandlerContext';
import { useShopifyService } from '@hooks/services/shopify/useShopifyService';
import { useAnalytics } from '@store/context/common/AnalyticsContext';
import { useUserAccount } from '@store/context/common/UserAccount';
import { useUserSession } from '@store/context/common/UserSession';
import { EUserType } from '@domain/enums/common/user/EUser';
import { useGetAccountStep } from '../home/GetAccountStepContext';

const ShopifyContext = React.createContext<IShopifyProvider | null>(null);

export const ShopifyProvider: React.FC = ({ children }) => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const { user } = useUserSession();
  const { analytics } = useAnalytics();
  const { handleError } = useErrorHandler();
  const { currentUserAccount } = useUserAccount();
  const { accountId, checkoutId } = useParams();
  const permissions = React.useMemo(
    () => currentUserAccount?.scopes || [],
    [currentUserAccount?.scopes],
  );

  const isRequestShopify =
    permissions?.includes('admin') ||
    user?.user?.type === EUserType.ADMIN ||
    permissions?.some(scope => scope.startsWith('app:'));

  const {
    createShopifyCredential,
    updateShopifyCredential,
    syncShopifyProducts,
    inactivateShopifyCredential,
    activateShopifyCredential,
    reinstallShopifyCredential,
    deleteShopifyCredential,
  } = useShopifyService();

  const {
    propertiesFirstStep,
    mutate: mutateHome,
    setIsOpenModal,
    isOpenModal,
  } = useGetAccountStep();

  const {
    handleSubmit,
    register,
    setValue,
    reset,
    formState: { errors },
    watch,
  } = useForm({ mode: 'onBlur', reValidateMode: 'onChange', resolver: yupResolver(shopifySchema) });

  const isFirstTime = Boolean(!propertiesFirstStep?.STORE_CONNECTED_AT);

  const [shopifyCredential, setShopifyCredential] = React.useState<IShopify | undefined>(undefined);
  const [skipCart, setSkipCart] = React.useState<boolean>(false);
  const [checkoutInFrame, setCheckoutInFrame] = React.useState<boolean>(false);
  const [isSubmittingForm, setIsSubmittingForm] = React.useState<boolean>(false);
  const [isDeleteCredentialModalOpen, setIsDeleteCredentialModalOpen] =
    React.useState<boolean>(false);
  const [isLoadingActiveCheckout, setIsLoadingActiveCheckout] = React.useState<boolean>(false);
  const [isLoadingDeleteCredential, setIsLoadingDeleteCredential] = React.useState<boolean>(false);
  const [isReinstallingCredential, setIsReinstallingCredential] = React.useState<boolean>(false);
  const [isOpenErrorModal, setIsOpenErrorModal] = React.useState<{
    scope: boolean;
    currency: boolean;
  }>({
    scope: false,
    currency: false,
  });
  const [scopesList, setScopesList] = React.useState<string[]>([]);

  const isEnableShopifyApp = Boolean(shopifyCredential);

  const domainRegister = register('domain');
  const accessTokenRegister = register('access_token');
  const apiKeyRegister = register('api_key');
  const secretApiKeyRegister = register('secret_api_key');

  dashboardInstance.defaults.headers.common['x-zouti-account'] = accountId;

  const SHOPIFY_URL = `${DASHBOARD_API_URL}/accounts/${accountId}/checkouts/${checkoutId}/shopify/credentials`;

  const { data, isLoading, isValidating, error, mutate } = useSWR<ISwr>(
    isRequestShopify ? SHOPIFY_URL : null,
    dashboardInstance,
  );

  const onCreateShopifyCredential = React.useCallback(
    async formData => {
      setIsSubmittingForm(true);

      const sanitizedDomain = formData.domain.replace(/^(https?:\/\/)?(www\.)?/i, '');

      try {
        const payload: ICreateShopifyCredentialData = {
          ...formData,
          domain: sanitizedDomain,
          skip_cart: skipCart,
          checkout_in_frame: checkoutInFrame,
          account_id: accountId,
          checkout_id: checkoutId,
        };

        await createShopifyCredential({ accountId, checkoutId, payload });

        analytics.track('Has Created Shopify Credential', {
          skip_cart: skipCart,
          checkout_in_frame: checkoutInFrame,
        });

        toast.success('App instalado com sucesso');

        await syncShopifyProducts({ accountId, checkoutId });

        await mutate();

        await new Promise(resolve => setTimeout(resolve, 3000));

        await mutateHome();

        setIsSubmittingForm(false);

        if (isFirstTime) {
          navigate(`/${accountId}/${checkoutId}/dashboard/home`);
        }
      } catch (requestError: any) {
        const message = requestError?.response?.data?.message;
        const isScopeError = message.includes('Os seguintes escopos estão faltando');

        if (message === 'A moeda padrão configurada na loja não corresponde ao padrão da conta') {
          setIsOpenErrorModal(state => ({ ...state, currency: true }));
        }

        if (isScopeError) {
          const scopesMatch = message?.match(/:\s*([^`]+)/);
          const scopesStr = scopesMatch[1];
          const scopes = scopesStr?.split(',').map((scope: string) => scope.trim());
          setScopesList(scopes);
          setIsOpenErrorModal(state => ({ ...state, scope: true }));
        }

        setIsSubmittingForm(false);
        handleError(requestError);
      }
    },
    [
      accountId,
      checkoutId,
      toast,
      createShopifyCredential,
      handleError,
      skipCart,
      checkoutInFrame,
      mutate,
      syncShopifyProducts,
      analytics,
      isFirstTime,
      navigate,
      mutateHome,
    ],
  );

  const onCredentialStatusUpdate = React.useCallback(
    async formData => {
      setIsSubmittingForm(true);

      const sanitizedDomain = formData.domain.replace(/^(https?:\/\/)?(www\.)?/i, '');

      try {
        const payload: IUpdateShopifyCredentialData = {
          ...shopifyCredential,
          ...formData,
          domain: sanitizedDomain,
          skip_cart: skipCart,
          checkout_in_frame: checkoutInFrame,
        };

        await updateShopifyCredential({
          accountId,
          checkoutId,
          payload,
          shopifyCredentialId: shopifyCredential?.id,
        });

        analytics.track('Has Updated Shopify Credential', {
          skip_cart: skipCart,
          checkout_in_frame: checkoutInFrame,
        });

        toast.success('App atualizado com sucesso');

        setIsSubmittingForm(false);

        await mutate();
      } catch (requestError) {
        setIsSubmittingForm(false);
        handleError(requestError);
      }
    },
    [
      accountId,
      checkoutId,
      shopifyCredential,
      skipCart,
      checkoutInFrame,
      toast,
      updateShopifyCredential,
      handleError,
      mutate,
      analytics,
    ],
  );

  const handleSkipCart = React.useCallback(() => {
    setSkipCart(currentState => !currentState);
  }, []);

  const handleAddCheckoutInFrame = React.useCallback(() => {
    setCheckoutInFrame(currentState => !currentState);
  }, []);

  const onSubmit = React.useCallback(
    async formData => {
      if (data?.data.object) {
        await onCredentialStatusUpdate(formData);
      } else {
        await onCreateShopifyCredential(formData);
      }
    },
    [data, onCredentialStatusUpdate, onCreateShopifyCredential],
  );

  const onActivateShopifyCredential = React.useCallback(async () => {
    if (!shopifyCredential) return;
    setIsLoadingActiveCheckout(true);
    try {
      await activateShopifyCredential({
        accountId,
        checkoutId,
        shopifyCredentialId: shopifyCredential.id,
      });

      analytics.track('Shopify Credential Activated');
      setIsLoadingActiveCheckout(false);
      setIsOpenModal({
        ...isOpenModal,
        congratulation: !isOpenModal.congratulation,
        activeCheckout: !isOpenModal.activeCheckout,
      });

      await mutate();
    } catch (requestError) {
      handleError(requestError);
      setIsLoadingActiveCheckout(false);
    }
  }, [
    accountId,
    checkoutId,
    shopifyCredential,
    mutate,
    activateShopifyCredential,
    handleError,
    analytics,
    setIsOpenModal,
    isOpenModal,
  ]);

  const onInactivateShopifyCredential = React.useCallback(async () => {
    if (!shopifyCredential) return;

    try {
      await inactivateShopifyCredential({
        accountId,
        checkoutId,
        shopifyCredentialId: shopifyCredential.id,
      });

      await mutate();
    } catch (requestError) {
      handleError(requestError);
    }
  }, [accountId, checkoutId, shopifyCredential, mutate, inactivateShopifyCredential, handleError]);

  const onReinstallShopifyCredential = React.useCallback(async () => {
    setIsReinstallingCredential(true);

    try {
      await reinstallShopifyCredential({
        accountId,
        checkoutId,
        shopifyCredentialId: shopifyCredential?.id,
      });

      toast.success('Reinstalação completa!');

      setIsReinstallingCredential(false);
    } catch (requestError: any) {
      setIsReinstallingCredential(false);
      handleError(requestError);
    }
  }, [accountId, checkoutId, handleError, reinstallShopifyCredential, shopifyCredential, toast]);

  const deleteCurrentShopifyCredential = React.useCallback(async () => {
    setIsLoadingDeleteCredential(true);

    try {
      await deleteShopifyCredential({
        accountId,
        checkoutId,
        shopifyCredentialId: shopifyCredential?.id,
      });

      toast.success('App removido com sucesso!');

      setIsLoadingDeleteCredential(false);

      setShopifyCredential(undefined);
      setIsDeleteCredentialModalOpen(false);
      reset();

      await mutate();
    } catch (requestError) {
      setIsLoadingDeleteCredential(false);
      handleError(requestError);
    }
  }, [
    accountId,
    checkoutId,
    shopifyCredential,
    deleteShopifyCredential,
    handleError,
    mutate,
    toast,
    reset,
  ]);

  const verifyError = React.useCallback(() => {
    if (!error) return false;

    if (error?.response?.status === 404) return false;

    return Boolean(error);
  }, [error]);

  React.useEffect(() => {
    if (error) {
      handleError(error);
    }

    if (data?.data.object) {
      setShopifyCredential(data.data.object);
      setValue('domain', data.data.object.domain);
      setValue('access_token', data.data.object.access_token);
      setValue('api_key', data.data.object.apiKey);
      setValue('secret_api_key', data.data.object.secret_api_key);
      setSkipCart(data.data.object.skip_cart);
      setCheckoutInFrame(data.data.object.checkout_in_frame);
    }
  }, [data, error, handleError, setValue]);

  const isLoadingShopify = isLoading;
  const isValidatingShopify = isValidating;
  const isShopifyError = verifyError();

  return (
    <ShopifyContext.Provider
      value={{
        accessTokenRegister,
        apiKeyRegister,
        domainRegister,
        isLoadingShopify,
        isShopifyError,
        isSubmittingForm,
        isValidatingShopify,
        onSubmit,
        secretApiKeyRegister,
        shopifyCredential,
        errors,
        handleSubmit,
        skipCart,
        handleSkipCart,
        checkoutInFrame,
        handleAddCheckoutInFrame,
        watch,
        onActivateShopifyCredential,
        onInactivateShopifyCredential,
        onReinstallShopifyCredential,
        deleteCurrentShopifyCredential,
        isReinstallingCredential,
        isEnableShopifyApp,
        setIsOpenErrorModal,
        isOpenErrorModal,
        scopesList,
        isLoadingActiveCheckout,
        isLoadingDeleteCredential,
        isDeleteCredentialModalOpen,
        setIsDeleteCredentialModalOpen,
      }}
    >
      {children}
    </ShopifyContext.Provider>
  );
};

export const useShopify = (): IShopifyProvider => {
  const context = React.useContext(ShopifyContext);

  if (!context) {
    throw new Error('useShopify must be used within provider');
  }

  return context;
};
