import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { IResetPasswordContext } from '@domain/interfaces/store/context/public/resetPassword/IResetPassword';

import { resetPasswordSchema } from '@helpers/validators/pages/public/resetPassword';

import { useErrorHandler } from '@store/context/common/ErrorHandlerContext';
import { useQuery } from '@hooks/common/useQuery';
import { useAuthService } from '@hooks/services/auth/useAuthService';
import { useToast } from '@store/context/common/ToastContext';

const ResetPasswordContext = React.createContext<IResetPasswordContext | null>(null);

export const ResetPasswordProvider: React.FC = ({ children }) => {
  const query = useQuery();
  const { toast } = useToast();
  const { resetPassword } = useAuthService();
  const { handleError } = useErrorHandler();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ reValidateMode: 'onChange', resolver: yupResolver(resetPasswordSchema) });

  const token = query.get('token');

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [showSuccessMessage, setShowSuccessMessage] = React.useState<boolean>(false);

  const onSubmit = React.useCallback(
    async data => {
      if (!token) {
        toast.error('Token não encontrado!');
        return;
      }

      setIsLoading(true);

      try {
        const payload = {
          password: data.password,
          password_confirmation: data.password_confirmation,
          token,
        };

        await resetPassword({ payload });

        setIsLoading(false);
        setShowSuccessMessage(true);
      } catch (error: any) {
        setIsLoading(false);
        handleError(error);
      }
    },
    [handleError, token, toast, resetPassword],
  );

  return (
    <ResetPasswordContext.Provider
      value={{ errors, handleSubmit, register, onSubmit, isLoading, showSuccessMessage }}
    >
      {children}
    </ResetPasswordContext.Provider>
  );
};

export const useResetPassword = (): IResetPasswordContext => {
  const context = React.useContext(ResetPasswordContext);

  if (!context) {
    throw new Error('useResetPassword must be used within ResetPasswordProvider');
  }

  return context;
};
