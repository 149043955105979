import styled from 'styled-components/macro';
import TextBase from '@components/common/DataDisplay/Text';

export const LabelText = styled(TextBase)`
  margin-left: auto;
  width: 75px;
  color: ${({ theme }) => theme.colors.neutral.dark};
`;

export const Values = styled.div`
  color: ${({ color }) => color};
  font-family: ${({ theme }) => theme.fonts.text};
  font-size: 1.2rem;
  font-weight: 500;
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const TooltipCard = styled.div`
  padding: 16px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.neutral.white};
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
`;
