import styled from 'styled-components/macro';

import ButtonBase from '@components/common/Inputs/Button';
import InputGroupBase from '@components/common/Inputs/InputGroup';
import LabelBase from '@components/common/DataDisplay/Label';
import ModalBase from '@components/common/DataDisplay/Modal';
import OptionBase from '@components/common/Inputs/Select/Option';
import SelectBase from '@components/common/Inputs/Select';
import TextBase from '@components/common/DataDisplay/Text';
import TextFieldBase from '@components/common/Inputs/TextField';

export const Modal = styled(ModalBase)`
  background-color: ${({ theme }) => theme.colors.neutral.white};
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Text = styled(TextBase)`
  font-weight: 500;
  font-size: 1.6rem;
`;
export const Label = styled(LabelBase)`
  margin: 0;
  font-size: 1.2rem;
`;

export const Input = styled(TextFieldBase)``;

export const InputGroup = styled(InputGroupBase)`
  gap: 8px;
`;
export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Select = styled(SelectBase)`
  min-width: 100%;
`;
export const Option = styled(OptionBase)``;

export const WrapperInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
`;

export const CancelButton = styled(ButtonBase)`
  & > span {
    color: ${({ theme }) => theme.colors.neutral.dark};
  }
`;

export const SuccessButton = styled(ButtonBase)``;
