import styled from 'styled-components';

import Card from '@components/common/Surfaces/Card';
import TypographyBase from '@components/common/DataDisplay/Typography';

export const CardWrapper = styled.button`
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.neutral.white};
  box-sizing: border-box;
  transition: all 0.3s;

  &:hover {
    filter: brightness(0.9);
  }

  &:active {
    filter: brightness(0.8);
  }
`;

export const ContentWrapper = styled.div``;

export const Wrapper = styled(Card)`
  height: 100%;
  background: unset;
  padding: 14px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:hover {
    filter: unset;
  }

  &:active {
    filter: unset;
  }
`;

export const IconWrapper = styled.div`
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.neutral.gray_white};
  padding: 8px 9px;
  margin-bottom: 8px;
`;

export const Icon = styled.img`
  width: 100%;
  max-width: 20px;
`;

export const Title = styled(TypographyBase)`
  color: ${({ theme }) => theme.colors.neutral.darkest};
  margin-bottom: 8px;
  text-align: left;
`;

export const Description = styled(TypographyBase)`
  color: ${({ theme }) => theme.colors.neutral.dark};
  margin-bottom: 32px;
  text-align: left;
`;

export const InstallText = styled(TypographyBase)`
  color: ${({ theme }) => theme.colors.primary.dark_green_60};
  text-align: left;
`;
