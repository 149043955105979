import React from 'react';

import { EHeadAlign } from '@domain/enums/components/common/ETable';

import { useGetShipping } from '@store/common/shipping/GetShippingContext';

import TableRow from './TableRow';

import * as S from './styles';
import CustomModal from '../CustomModal';

const Body: React.FC = () => {
  const { shippings } = useGetShipping();

  return (
    <S.Wrapper>
      <CustomModal />
      <S.Table>
        <S.Table.Header>
          <S.Table.Row>
            <S.Table.Head>Status</S.Table.Head>
            <S.Table.Head>Nome</S.Table.Head>
            <S.Table.Head align={EHeadAlign.RIGHT}>Valor</S.Table.Head>
            <S.Table.Head>Prazo</S.Table.Head>
          </S.Table.Row>
        </S.Table.Header>

        <S.Table.Body>
          {shippings.map(shipping => (
            <TableRow shipping={shipping} key={shipping.id} />
          ))}
        </S.Table.Body>
      </S.Table>
    </S.Wrapper>
  );
};

export default Body;
