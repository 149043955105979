import React from 'react';
import { FieldValues, useForm } from 'react-hook-form';

import { useGetOrder } from '@store/common/order/GetOrder';

import { EStatusOrder } from '@domain/enums/common/order/EOrder';
import { EButtonVariant } from '@domain/enums/components/common/EButton';
import * as S from './styles';

const CustomModal: React.FC = () => {
  const { setIsOpenModal, isOpenModal } = useGetOrder();

  const { register, handleSubmit } = useForm();

  const onSubmit = (formData: FieldValues): void => {
    const data = {
      status: formData.status,
      tracking_code: formData.tracking_code,
    };

    console.log(data);
  };

  const options: Record<EStatusOrder, string> = {
    [EStatusOrder.Waiting]: 'Aguardando',
    [EStatusOrder.Traceable]: 'Rastreável',
    [EStatusOrder.InTransit]: 'Em trânsito',
    [EStatusOrder.Delivered]: 'Entregue',
    [EStatusOrder.Failed]: 'Falha',
  };

  return (
    <S.Modal title="Editar envio" isOpen={isOpenModal}>
      <S.Form onSubmit={handleSubmit(onSubmit)}>
        <S.Text>Insira o código de rastreio do envio para alterar o status.</S.Text>
        <S.WrapperInput>
          <S.InputGroup>
            <S.Label>Código de rastreio</S.Label>
            <S.Input
              {...register('tracking_code')}
              placeholder="Insira o código de rastreio!"
              type="text"
            />
          </S.InputGroup>
          <S.SelectWrapper>
            <S.Label>Status</S.Label>
            <S.Select {...register('status')} name="status">
              {Object.values(EStatusOrder).map(status => (
                <S.Option key={status} value={status}>
                  {options[status]}
                </S.Option>
              ))}
            </S.Select>
          </S.SelectWrapper>
        </S.WrapperInput>
        <S.ButtonsWrapper>
          <S.CancelButton
            onClick={() => setIsOpenModal(state => !state)}
            variant={EButtonVariant.SECONDARY}
          >
            Cancelar
          </S.CancelButton>

          <S.SuccessButton type="submit">Salvar</S.SuccessButton>
        </S.ButtonsWrapper>
      </S.Form>
    </S.Modal>
  );
};

export default CustomModal;
