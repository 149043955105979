import styled from 'styled-components/macro';

import HeadingBase from '@components/common/DataDisplay/Heading';
import TextBase from '@components/common/DataDisplay/Text';
import ButtonBase from '@components/common/Inputs/Button';
import ImageBase from '@components/common/DataDisplay/Image';

export const Image = styled(ImageBase)`
  max-width: 172px;
  display: block;
  margin: 32px auto;
`;

export const TitleDescription = styled(TextBase)`
  display: inline-block;
  margin-bottom: 24px;
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.neutral.dark};
  text-align: center;
`;

export const SubmitButton = styled(ButtonBase)`
  width: 100%;
`;

export const SkipButton = styled(ButtonBase)`
  width: 100%;

  & > span {
    color: ${({ theme }) => theme.colors.neutral.darker};
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 16px;
`;

export const Text = styled(TextBase)`
  font-weight: 500;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.neutral.darker};
`;

export const Title = styled(HeadingBase)`
  display: inline-block;
  text-align: center;
  margin-bottom: 16px;
  width: 100%;
`;

export const Wrapper = styled.div`
  margin-top: 32px;
`;
