import styled from 'styled-components/macro';

export const SwitchLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 42px;
  height: 24px;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.neutral.light};
  cursor: pointer;
  transition: all 0.4s;

  &::after {
    content: '';
    display: block;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    margin-top: 3px;
    margin-left: 4px;
    background-color: white;
    transition: 0.4s;
  }
`;

export const SwitchInput = styled.input`
  opacity: 0;
  z-index: ${({ theme }) => theme.zIndex.firstLayer};
  background-color: ${({ theme }) => theme.colors.primary.base};
  border-radius: 11px;
  width: 42px;
  height: 24px;

  &:checked + ${SwitchLabel} {
    background-color: ${({ theme }) => theme.colors.primary.green_lemon};

    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      margin-left: 20px;
      transition: 0.4s;
    }
  }

  &:disabled + ${SwitchLabel} {
    background-color: ${({ theme }) => theme.colors.neutral.lightest};
    cursor: not-allowed;

    &::after {
      background-color: ${({ theme }) => theme.colors.neutral.lighter};
    }
  }
`;

export const SwitchWrapper = styled.div`
  position: relative;
  width: 42px;
  height: 24px;
`;

export const Wrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  width: 42px;
`;
