import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { ISignUpContext } from '@domain/interfaces/store/context/public/signUp/ISignUpContext';
import { ICreateUserData } from '@domain/interfaces/common/auth/IAuth';

import { signUpSchema } from '@helpers/validators/pages/public/signUp';

import { useConfig } from '@store/context/common/ConfigContext';
import { useAuthService } from '@hooks/services/auth/useAuthService';
import { useErrorHandler } from '@store/context/common/ErrorHandlerContext';
import { useAnalytics } from '@store/context/common/AnalyticsContext';
import { useQuery } from '@hooks/common/useQuery';

import { parseToRawPhone } from '@helpers/utils/common/phone';

const SignUpContext = React.createContext<ISignUpContext | null>(null);

export const SignUpProvider: React.FC = ({ children }) => {
  const query = useQuery();
  const { createUser } = useAuthService();
  const { handleSessionToken } = useConfig();
  const { handleError } = useErrorHandler();
  const { analytics } = useAnalytics();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onChange',
    resolver: yupResolver(signUpSchema),
  });

  const isOldUser = query.get('is_old_user');

  const [isCreatingAccount, setIsCreatingAccount] = React.useState<boolean>(false);

  const onSubmit = React.useCallback(
    async data => {
      setIsCreatingAccount(true);

      try {
        const payload: ICreateUserData = {
          name: data.name,
          email: data.email,
          password: data.password,
          phone: parseToRawPhone(data.phone),
        };

        const { data: userData } = await createUser({ payload });

        analytics.identify(userData.object.user.id, {
          name: userData.object.user.name,
          email: userData.object.user.email,
          created_at: userData.object.user.created_at,
        });

        analytics.track('User Signed Up', { email: userData.object.user.email });

        handleSessionToken(`ZSID=${userData.object.token}`);

        setIsCreatingAccount(false);

        window.location.href = `${window.location.origin}/loading`;
      } catch (error) {
        setIsCreatingAccount(false);
        handleError(error);
      }
    },
    [createUser, handleSessionToken, handleError, analytics],
  );

  React.useEffect(() => {
    if (isOldUser !== 'true') {
      window.location.href = 'https://auth.zouti.com.br';
    }
  }, [isOldUser]);

  return (
    <SignUpContext.Provider value={{ errors, handleSubmit, onSubmit, register, isCreatingAccount }}>
      {children}
    </SignUpContext.Provider>
  );
};

export const useSignUp = (): ISignUpContext => {
  const context = React.useContext(SignUpContext);

  if (!context) {
    throw new Error('useSignUp must be used within SignUpProvider');
  }

  return context;
};
