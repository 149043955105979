import React from 'react';

import { ITextProps } from './styles';

import * as S from './styles';

const Text: React.FC<ITextProps> = ({ children, ...rest }) => {
  return <S.TextBase {...rest}>{children}</S.TextBase>;
};

export default Text;
