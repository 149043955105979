import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useNewPixel } from '@store/common/pixel/NewPixelContext';

import { PROVIDERS } from '@constants/common/pixelProviders';
import { EButtonVariant } from '@domain/enums/components/common/EButton';

import ProviderOption from './Option';

import Forms from './Forms';

import * as S from './styles';

const Body: React.FC = () => {
  const navigate = useNavigate();
  const { accountId, checkoutId } = useParams();
  const { providerSelected, isCreatingPixel, onSubmit, handleSubmit } = useNewPixel();

  const currentProvider = PROVIDERS.find(provider => provider.name === providerSelected);
  const onCancelButtonClick = (): void => {
    navigate(`/${accountId}/${checkoutId}/dashboard/marketing/pixels`);
  };

  return (
    <S.Wrapper>
      <S.Form onSubmit={handleSubmit(onSubmit)}>
        <S.Card>
          <S.InputGroup>
            <S.Label>Plataforma</S.Label>

            <S.Dropdown>
              <S.DropdownTrigger isDropdownOpen>
                {providerSelected ? (
                  <>
                    <S.ProviderIcon src={currentProvider?.icon} />
                    <S.ProviderName>{currentProvider?.text}</S.ProviderName>
                  </>
                ) : (
                  'Selecione...'
                )}
              </S.DropdownTrigger>

              <S.DropdownContent align="center" sideOffset={11}>
                {PROVIDERS.map(provider => (
                  <ProviderOption provider={provider} key={provider._id} />
                ))}
              </S.DropdownContent>
            </S.Dropdown>
          </S.InputGroup>

          <Forms />
        </S.Card>
        {currentProvider && (
          <S.ButtonsWrapper>
            <S.CancelButton onClick={onCancelButtonClick} variant={EButtonVariant.SECONDARY}>
              Cancelar
            </S.CancelButton>
            <S.SubmitButton type="submit" isLoading={isCreatingPixel}>
              Salvar
            </S.SubmitButton>
          </S.ButtonsWrapper>
        )}
      </S.Form>
    </S.Wrapper>
  );
};

export default Body;
