import styled from 'styled-components';

import SwitchBase from '@components/common/Inputs/Switch';
import TextBase from '@components/common/DataDisplay/Text';

export const AlertText = styled(TextBase)`
  color: #5a31f4;
  font-size: 1.3rem;
  font-weight: 500;
`;

export const AlertWrapper = styled.div`
  padding: 10px 16px;
  border-radius: 4px;
  background-color: #5a31f41a;
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const SwitchLabel = styled(TextBase)``;

export const Switch = styled(SwitchBase)``;

export const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Wrapper = styled.div`
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
