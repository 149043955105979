import React from 'react';

import { Funnel } from 'phosphor-react';

import * as S from './styles';

interface IFilterButtonProps {
  onClick?: () => void;
}

const FilterButton: React.FC<IFilterButtonProps> = ({ onClick }) => {
  return (
    <S.Wrapper onClick={onClick}>
      <Funnel size={16} color="#515151" />

      <S.FilterText>Filtrar</S.FilterText>
    </S.Wrapper>
  );
};

export default FilterButton;
