import styled, { css } from 'styled-components/macro';

import TableBase from '@components/common/utils/Table';
import TextBase from '@components/common/DataDisplay/Text';
import ImageBase from '@components/common/DataDisplay/Image';

export const EmptyImage = styled(ImageBase)`
  max-width: 200px;
`;

export const EmptyText = styled(TextBase)`
  font-size: 1.8rem;
  color: ${({ theme }) => theme.colors.neutral.darkest};
`;

export const EmptyContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

export const Table = styled(TableBase)``;

export const Text = styled(TextBase)`
  overflow: hidden;
  font-weight: 500;
  text-overflow: ellipsis;
`;

export const TextAmount = styled(TextBase)<{ isnegative?: boolean }>`
  overflow: hidden;
  font-weight: 700;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.positive.dark};

  ${({ theme, isnegative }) =>
    isnegative &&
    css`
      color: ${theme.colors.negative.dark};
    `}
`;
