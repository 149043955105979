import React from 'react';

import { useGA4Context } from '@store/common/GA4/GA4Context';
import AppSkeletonLoading from '@components/common/utils/AppSkeletonLoading';
import Body from '../Body';
import Header from '../Header';

import * as S from './styles';

const Wrapper: React.FC = () => {
  const { isLoadingGA4 } = useGA4Context();

  if (isLoadingGA4) {
    return <AppSkeletonLoading />;
  }

  return (
    <S.Wrapper>
      <Header />

      <Body />
    </S.Wrapper>
  );
};

export default Wrapper;
