import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';

import { ISwr } from '@domain/interfaces/common/swr/ISwr';
import { IConfigDomainProvider } from '@domain/interfaces/store/common/domain/IConfigDomainProvider';
import { IGetDomainByIdResponse } from '@domain/interfaces/hooks/services/domain/IDomainService';
import { IDomain } from '@domain/interfaces/common/domain/IDomain';

import { DASHBOARD_API_URL } from '@constants/services/apiUrl';
import { dashboardInstance } from '@services/common/dashboardInstance';

import { useDomainService } from '@hooks/services/domain/useDomainService';
import { useErrorHandler } from '@store/context/common/ErrorHandlerContext';
import { useToast } from '@store/context/common/ToastContext';
import { useAnalytics } from '@store/context/common/AnalyticsContext';

const ConfigDomainContext = React.createContext<IConfigDomainProvider | null>(null);

export const ConfigDomainProvider: React.FC = ({ children }) => {
  const navigate = useNavigate();
  const { handleError } = useErrorHandler();
  const { domainId, accountId, checkoutId } = useParams();
  const { toast } = useToast();
  const { verifyDomain, deleteDomain } = useDomainService();
  const { analytics } = useAnalytics();

  const [isLoadingVerifyDomain, setIsLoadingVerifyDomain] = React.useState<boolean>(false);
  const [isOpenModal, setIsOpenModal] = React.useState<boolean>(false);
  const [providerSelected, setProviderSelected] = React.useState<string>('');
  const [domain, setDomain] = React.useState<IDomain>({} as IDomain);

  const handleSelectedProvider = ({ providerName }: { providerName: string }): void => {
    document.dispatchEvent(new KeyboardEvent('keydown', { key: 'Escape' }));
    setProviderSelected(providerName);
  };

  dashboardInstance.defaults.headers.common['x-zouti-account'] = accountId;

  const DOMAIN_URL = `${DASHBOARD_API_URL}/accounts/${accountId}/checkouts/${checkoutId}/domains/${domainId}`;

  const {
    data: domainData,
    isLoading,
    isValidating,
    mutate,
    error,
  } = useSWR<ISwr<IGetDomainByIdResponse>>(DOMAIN_URL, dashboardInstance);

  React.useEffect(() => {
    if (error) {
      handleError(error);
    }

    if (domainData?.data?.object) {
      setDomain(domainData?.data?.object);
    }
  }, [domainData, handleError, error]);

  const verifyCurrentDomain = React.useCallback(async () => {
    setIsLoadingVerifyDomain(true);

    try {
      const { data: verifyDomainData } = await verifyDomain({ domainId, accountId, checkoutId });

      if (verifyDomainData?.object.status !== 'INVALID') {
        toast.success('Domínio validado com sucesso!');

        analytics.track('Domain Verified');

        mutate();

        setIsLoadingVerifyDomain(false);
      }
    } finally {
      setIsLoadingVerifyDomain(false);
    }
  }, [verifyDomain, accountId, checkoutId, domainId, mutate, toast, analytics]);

  const deleteCurrentDomain = React.useCallback(async () => {
    if (domainId !== undefined) {
      try {
        await deleteDomain({ domainId, checkoutId, accountId });

        analytics.track('Domain Deleted');

        toast.success('Domínio excluído com sucesso.');
        navigate(`/${accountId}/${checkoutId}/dashboard/checkout/domains`);
      } catch (err) {
        handleError(err);
      }
    }
  }, [domainId, deleteDomain, handleError, toast, navigate, accountId, checkoutId, analytics]);

  const isLoadingConfigDomain = isLoading || isValidating;
  const isConfigDomainError = Boolean(error);

  return (
    <ConfigDomainContext.Provider
      value={{
        domain,
        domainId,
        isLoadingVerifyDomain,
        isOpenModal,
        setIsOpenModal,
        verifyCurrentDomain,
        deleteCurrentDomain,
        handleSelectedProvider,
        providerSelected,
        isConfigDomainError,
        isLoadingConfigDomain,
      }}
    >
      {children}
    </ConfigDomainContext.Provider>
  );
};

export const useConfigDomain = (): IConfigDomainProvider => {
  const context = React.useContext(ConfigDomainContext);

  if (!context) {
    throw new Error('useConfigDomain must be used within provider');
  }

  return context;
};
