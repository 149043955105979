import React from 'react';

import Content from '@components/pages/Dashboard/Users';
import { TabsProvider } from '@store/context/components/Tab/tabsContext';
import { UsersProvider } from '@store/common/users/UsersContext';

const Users: React.FC = () => {
  return (
    <TabsProvider>
      <UsersProvider>
        <Content />
      </UsersProvider>
    </TabsProvider>
  );
};

export default Users;
