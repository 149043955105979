import React from 'react';
import { useTheme } from 'styled-components/macro';

import { EHeadingSize } from '@domain/enums/components/common/EHeading';
import { EChipColor } from '@domain/enums/components/common/EChip';
import { EBusinessModel } from '@domain/enums/common/onboarding/EUser';

import { useUserCheckout } from '@store/context/common/UserCheckout';
import { formatCurrency } from '@utils/common/formatCurrency';

import { useDetailAbandonedCart } from '@store/common/AbandonedCart/DetailAbandonedCartContext';

import * as S from './styles';

const Body: React.FC = () => {
  const theme = useTheme();
  const { currentUserCheckout } = useUserCheckout();
  const { customerData, stepType, StepIcon, products, detailAbandonedCart } =
    useDetailAbandonedCart();
  const isGlobalStore = currentUserCheckout?.business_type === EBusinessModel.DROPSHIPPING_GLOBAL;

  const { name, phone, email, document } = customerData;

  return (
    <S.Wrapper>
      <S.CustomerCard>
        <S.Title size={EHeadingSize.H6}>Cliente</S.Title>

        <S.InfoGroupWrapper>
          <S.InfoGroup>
            <S.Label>Nome</S.Label>
            <S.TextValue>{name}</S.TextValue>
          </S.InfoGroup>

          <S.InfoGroup>
            <S.Label>E-mail</S.Label>
            <S.TextValue>{email}</S.TextValue>
          </S.InfoGroup>

          {!isGlobalStore && (
            <>
              <S.InfoGroup>
                <S.Label>CPF</S.Label>
                <S.TextValue>{document}</S.TextValue>
              </S.InfoGroup>

              <S.InfoGroup>
                <S.Label>Telefone</S.Label>
                <S.TextValue>{phone}</S.TextValue>
              </S.InfoGroup>
            </>
          )}

          {/* <S.InfoGroup>
            <S.Label>IP da compra</S.Label>
            <S.TextValue>{ip_address}</S.TextValue>
          </S.InfoGroup> */}

          <S.InfoGroup>
            <S.Label>Abandonou em</S.Label>
            <S.Chip backgroundColor={EChipColor.WHITE_BLUE} textColor={EChipColor.DARK_BLUE}>
              {StepIcon && <StepIcon color={theme.colors.info.darker} size={16} />}
              {stepType}
            </S.Chip>
          </S.InfoGroup>
        </S.InfoGroupWrapper>
      </S.CustomerCard>

      <S.ProductCard>
        <S.Title size={EHeadingSize.H6}>Produtos</S.Title>
        {products?.map(item => {
          const itemAmount = item.amount / 100;
          const itemAmountFormatted = formatCurrency(
            itemAmount,
            currentUserCheckout?.language,
            detailAbandonedCart.currency,
          );

          return (
            <S.CardRow key={item.name}>
              {item.image_url ? <S.Image src={item.image_url} alt={item.name} /> : <S.EmptyImage />}

              <S.ItemInfoGroup maxWidth={280}>
                <S.Label>Descrição</S.Label>
                <S.TextValue>{item.description}</S.TextValue>
              </S.ItemInfoGroup>

              <S.ItemInfoGroup maxWidth={140}>
                <S.Label>Variação</S.Label>
                <S.TextValue>{item.name}</S.TextValue>
              </S.ItemInfoGroup>

              <S.ItemInfoGroup maxWidth={60}>
                <S.Label>Qtde</S.Label>
                <S.TextValue>{item.quantity}</S.TextValue>
              </S.ItemInfoGroup>

              <S.ItemInfoGroup maxWidth={120}>
                <S.Label>Preço un</S.Label>
                <S.TextValue>{itemAmountFormatted}</S.TextValue>
              </S.ItemInfoGroup>
            </S.CardRow>
          );
        })}
      </S.ProductCard>
    </S.Wrapper>
  );
};

export default Body;
