import { EThemeCheckout } from '@domain/enums/common/checkoutCustomization/EThemeCheckout';

import ShopifyTheme from '@assets/images/common/theme/ThemeLibrary/shopifyThemeLibrary.png';
import Shopify from '@assets/images/common/theme/current/shopify.png';
import MiniOttawaTheme from '@assets/images/common/theme/current/miniOttawaTheme.png';
import OttawaLogo from '@assets/images/common/theme/current/ottawaLogo.svg';

import ZoutiTheme from '@assets/images/common/theme/ThemeLibrary/zoutiThemeLibrary.png';
import Zouti from '@assets/images/common/theme/current/zouti.png';
import MiniZoutiTheme from '@assets/images/common/theme/current/miniThemeZouti.png';
import ZoutiLogo from '@assets/images/common/theme/current/zoutiLogo.svg';

export const CHECKOUT_THEMES = [
  {
    key: EThemeCheckout.ZOUTI,
    themeImage: ZoutiTheme,
    image: Zouti,
    miniTheme: MiniZoutiTheme,
    logo: ZoutiLogo,
    name: 'Zouti',
  },
  {
    key: EThemeCheckout.OTTAWA,
    themeImage: ShopifyTheme,
    image: Shopify,
    miniTheme: MiniOttawaTheme,
    logo: OttawaLogo,
    name: 'Ottawa',
  },
];
