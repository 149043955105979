import Facebook from '@assets/images/common/social/facebook.svg';
import GoogleAds from '@assets/images/common/social/ga.svg';
import Taboola from '@assets/images/common/social/taboola.svg';
import TikTok from '@assets/images/common/social/tiktok.svg';

export const PROVIDERS = [
  {
    _id: 1,
    name: 'FACEBOOK',
    text: 'Facebook',
    icon: Facebook,
  },
  {
    _id: 5,
    name: 'FACEBOOK_CONVERSIONS',
    text: 'Facebook (API de Conversão)',
    icon: Facebook,
  },
  {
    _id: 2,
    name: 'GOOGLE',
    text: 'Google Ads',
    icon: GoogleAds,
  },
  {
    _id: 3,
    name: 'TABOOLA',
    text: 'Taboola',
    icon: Taboola,
  },
  {
    _id: 4,
    name: 'TIKTOK',
    text: 'TikTok',
    icon: TikTok,
  },
];
