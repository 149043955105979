import useSWR from 'swr';
import { useForm } from 'react-hook-form';
import React, { ChangeEvent } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useParams } from 'react-router-dom';

import { ISwr } from '@domain/interfaces/common/swr/ISwr';
import { ISocialProof } from '@domain/interfaces/common/socialProof/ISocialProof';
import { IGetSocialProofResponse } from '@domain/interfaces/hooks/services/socialProof/ISocialProofService';
import { IUpdateSocialProofProvider } from '@domain/interfaces/store/common/socialProof/IUpdateSocialProofProvider';

import { DASHBOARD_API_URL } from '@constants/services/apiUrl';
import { dashboardInstance } from '@services/common/dashboardInstance';
import { updateSocialProofSchema } from '@helpers/validators/pages/dashboard/socialProof/socialProofSchemaValidation';

import { useToast } from '@store/context/common/ToastContext';
import { useErrorHandler } from '@store/context/common/ErrorHandlerContext';
import { useSocialProofService } from '@hooks/services/socialProof/useSocialProofService';
import { useAnalytics } from '@store/context/common/AnalyticsContext';

const UpdateSocialProofContext = React.createContext<IUpdateSocialProofProvider | null>(null);

export const UpdateSocialProofProvider: React.FC = ({ children }) => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const { handleError } = useErrorHandler();
  const { socialProofId, accountId, checkoutId } = useParams();
  const { updateSocialProof, deleteSocialProof, uploadSocialProofImage } = useSocialProofService();
  const { analytics } = useAnalytics();

  const [rating, setRating] = React.useState<number>(0);
  const [fileImage, setFileImage] = React.useState<string>('');
  const [socialProof, setSocialProof] = React.useState<ISocialProof>({} as ISocialProof);
  const [isUpdatingSocialProof, setIsUpdatingSocialProof] = React.useState<boolean>(false);
  const [isDeletingSocialProof, setIsDeletingSocialProof] = React.useState<boolean>(false);
  const [selectedFileImage, setSelectedFileImage] = React.useState<File | undefined>(undefined);
  const [uploadedFileImage, setUploadedFileImage] = React.useState<
    Record<string, string> | undefined
  >(undefined);

  const SOCIAL_PROOF_UPDATE_URL = `${DASHBOARD_API_URL}/accounts/${accountId}/checkouts/${checkoutId}/socials/${socialProofId}`;

  const { data, isLoading, isValidating, mutate, error } = useSWR<ISwr<IGetSocialProofResponse>>(
    SOCIAL_PROOF_UPDATE_URL,
    dashboardInstance,
  );

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(updateSocialProofSchema),
  });

  React.useEffect(() => {
    if (error) {
      handleError(error);
    }

    if (data?.data) {
      setSocialProof(data.data.object);
      setFileImage(data?.data.object.image_url);
      setRating(data.data.object.qtd_stars);
    }
  }, [data, error, handleError]);

  const handleImageChange = React.useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (!e?.target?.files || e.target.files.length <= 0) return;

      const mappedFiles = Array.from(e.target.files).map(file => ({
        file,
        size: file.size,
        name: file.name,
        errors: [],
        preview: URL.createObjectURL(file),
      }));

      const { file } = mappedFiles[0];

      if (file) {
        setSelectedFileImage(file);
        setValue('photo', file);

        try {
          const formData = new FormData();
          formData.append('file', file, file.name);

          const { data: uploadData } = await uploadSocialProofImage({
            accountId,
            checkoutId,
            payload: formData,
          });

          setUploadedFileImage(uploadData.object);
        } catch (responseError) {
          handleError(responseError);
        }
      }
    },
    [accountId, checkoutId, setValue, uploadSocialProofImage, handleError],
  );

  const deleteCurrentSocialProof = React.useCallback(
    async deleteSocialProofId => {
      setIsDeletingSocialProof(true);

      try {
        await deleteSocialProof({ socialProofId: deleteSocialProofId, accountId, checkoutId });

        analytics.track('Social Proof Deleted');

        await mutate();
        setIsDeletingSocialProof(false);
        toast.success('Prova social deletada com sucesso');
        navigate(`/${accountId}/${checkoutId}/dashboard/checkout/social-proofs`);
      } catch (err) {
        setIsDeletingSocialProof(false);
        handleError(err);
      } finally {
        setIsDeletingSocialProof(false);
      }
    },
    [deleteSocialProof, handleError, mutate, toast, accountId, checkoutId, navigate, analytics],
  );

  const onSubmit = React.useCallback(
    async currentSocialProof => {
      setIsUpdatingSocialProof(true);

      const payload = {
        name: currentSocialProof.name,
        testimony: currentSocialProof.testimony,
        image_path: uploadedFileImage?.path || socialProof.image_path,
        image_url: uploadedFileImage?.url || socialProof.image_url,
        qtd_stars: rating,
      };

      try {
        await updateSocialProof({
          socialProofId: socialProof.id,
          payload,
          accountId,
          checkoutId,
        });

        analytics.track('Social Proof Updated');

        setIsUpdatingSocialProof(false);
        toast.success('Prova social atualizada com sucesso');

        navigate(`/${accountId}/${checkoutId}/dashboard/checkout/social-proofs`);
      } catch (errorUpdating) {
        setIsUpdatingSocialProof(false);
        handleError(errorUpdating);
      }
    },
    [
      socialProof,
      rating,
      updateSocialProof,
      handleError,
      navigate,
      toast,
      accountId,
      checkoutId,
      uploadedFileImage,
      analytics,
    ],
  );

  const isLoadingSocialProof = isLoading || isValidating;
  const isSocialProofError = Boolean(error);

  return (
    <UpdateSocialProofContext.Provider
      value={{
        socialProof,
        isLoadingSocialProof,
        isSocialProofError,
        mutate,
        register,
        handleSubmit,
        errors,
        setValue,
        onSubmit,
        isUpdatingSocialProof,
        handleImageChange,
        rating,
        setRating,
        fileImage,
        deleteCurrentSocialProof,
        isDeletingSocialProof,
        selectedFileImage,
      }}
    >
      {children}
    </UpdateSocialProofContext.Provider>
  );
};

export const useUpdateSocialProof = (): IUpdateSocialProofProvider => {
  const context = React.useContext(UpdateSocialProofContext);

  if (!context) {
    throw new Error('useUpdateSocialProof must be used within provider');
  }

  return context;
};
