import styled from 'styled-components/macro';

import HeadingBase from '@components/common/DataDisplay/Heading';
import LabelBase from '@components/common/DataDisplay/Label';
import TextBase from '@components/common/DataDisplay/Text';
import ButtonBase from '@components/common/Inputs/Button';
import TextFieldBase from '@components/common/Inputs/TextField';
import InputGroupBase from '@components/common/Inputs/InputGroup';
import SelectInputBase from '@components/common/Inputs/Select';

export const Input = styled(TextFieldBase)``;

export const Select = styled(SelectInputBase)`
  width: 100%;
`;

export const Option = styled.option`
  &:disabled {
    color: ${({ theme }) => theme.colors.neutral.base};
  }
`;

export const Label = styled(LabelBase)``;

export const InputGroup = styled(InputGroupBase)`
  display: flex;
  flex-direction: column;

  gap: 20px;

  margin-bottom: 24px;
`;

export const SubmitButton = styled(ButtonBase)`
  width: 100%;
`;

export const Text = styled(TextBase)`
  font-weight: 500;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.neutral.darker};
`;

export const QuestionText = styled(HeadingBase)`
  display: inline-block;
  margin-bottom: 8px;
`;

export const Wrapper = styled.form`
  margin-top: 32px;
`;

export const TitleDescription = styled(TextBase)`
  display: inline-block;
  margin-bottom: 24px;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.neutral.dark};
`;
