import styled from 'styled-components/macro';
import { ArrowsCounterClockwise, Copy, CheckCircle, Info } from 'phosphor-react';

import ButtonBase from '@components/common/Inputs/Button';
import TextBase from '@components/common/DataDisplay/Text';
import HeadingBase from '@components/common/DataDisplay/Heading';

interface IStatusVerify {
  statusVerify: boolean;
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.small_24};
  padding: ${({ theme }) => theme.spacing.small_24};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  background-color: ${({ theme }) => theme.colors.neutral.white};
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.small_8};
`;

export const InfoText = styled(TextBase)<IStatusVerify>`
  font-weight: 400;
  color: ${({ theme, statusVerify }) =>
    statusVerify ? theme?.colors.positive.dark : theme.colors.warning.base};
`;

export const WrapperIconAndTextInfo = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.small_8};
`;

export const Divider = styled.hr`
  height: 1px;
  border: none;
  background-color: ${({ theme }) => theme.colors.neutral.lightest};
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const Title = styled(HeadingBase)`
  color: ${({ theme }) => theme.colors.neutral.darkest};
`;

export const Description = styled(TextBase)``;

export const CopyArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.primary.lemon_10};
`;

export const CopyAreaKey = styled(TextBase)`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.neutral.dark};
`;

export const CopyAreaValue = styled(TextBase)`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.neutral.darkest};
`;

export const CopyIcon = styled(Copy).attrs(props => ({
  size: 20,
  color: props.theme.colors.primary.dark_green_60,
}))`
  &:hover {
    transform: scale(1.2);
  }
  cursor: pointer;
`;

export const CheckCircleIcon = styled(CheckCircle).attrs(() => ({
  size: 18,
  weight: 'bold',
}))<IStatusVerify>`
  color: ${({ theme, statusVerify }) =>
    statusVerify ? theme.colors.positive.dark : theme.colors.secondary.dark};
`;

export const InfoIcon = styled(Info).attrs(() => ({
  size: 18,
  weight: 'bold',
}))<IStatusVerify>`
  color: ${({ theme, statusVerify }) =>
    statusVerify ? theme.colors.positive.dark : theme.colors.secondary.dark};
`;

export const ArrowsCounterClockwiseIcon = styled(ArrowsCounterClockwise).attrs(() => ({
  size: 15,
  weight: 'bold',
}))``;

export const CopyWrapperItens = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.small_8};
`;

export const VerifyDomainButton = styled(ButtonBase)`
  align-self: self-start;
  background-color: ${({ theme }) => theme.colors.neutral.white};
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  > span {
    color: ${({ theme }) => theme.colors.neutral.darker};
    font-size: 1.6rem;
    font-weight: 500;
  }
`;
