import styled from 'styled-components/macro';

import ButtonBase from '@components/common/Inputs/Button';
import TextBase from '@components/common/DataDisplay/Text';

export const TitleAndQuantityWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 8px;
`;

export const TitleAndFilterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
`;

export const Button = styled(ButtonBase)`
  & > span {
    font-size: 14px;
    color: ${({ theme }) => theme.colors.neutral.dark};
  }
`;

export const ModalContent = styled.div`
  min-width: 520px;
`;

export const ContainerDropzone = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 24px;
  border: 2px dashed ${({ theme }) => theme.colors.primary.dark_green_40};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  background-color: ${({ theme }) => theme.colors.primary.lemon_20};
  transition: border 0.24s ease-in-out;
  cursor: pointer;

  svg {
    color: ${({ theme }) => theme.colors.primary.dark_green_40};
  }
`;

export const TextDropzone = styled(TextBase)`
  font-weight: 500;
`;

export const AcceptFormat = styled(TextBase)`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.neutral.dark};
`;

export const DownloadTemplate = styled.div`
  display: flex;
  align-items: center;

  gap: 7px;

  margin-top: 18px;

  color: ${({ theme }) => theme.colors.primary.dark_green_80};

  & > span {
    font-family: ${({ theme }) => theme.fonts.typography};
    font-size: 1.8rem;
    font-weight: 500;
  }
`;

export const UploadedFileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;

  gap: 4px;

  background-color: ${({ theme }) => theme.colors.neutral.gray_white};

  padding: 7px 24px;

  border-radius: 4px;
`;

export const FileName = styled.div`
  font-family: ${({ theme }) => theme.fonts.typography};
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.neutral.darker};
  font-weight: 500;
`;

export const FileSize = styled.div`
  font-family: ${({ theme }) => theme.fonts.typography};
  color: ${({ theme }) => theme.colors.neutral.base};
  font-size: 1.4rem;
  font-weight: 500;
`;
