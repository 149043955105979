import React from 'react';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import * as S from './styles';

const SkeletonLoading: React.FC = () => {
  return (
    <S.Wrapper>
      <S.Header>
        <Skeleton width="20%" height="30px" />
        <Skeleton width="40%" height="10px" />
      </S.Header>
      <S.WrapperContent>
        <S.WrapperInput>
          <Skeleton width="20%" height="10px" />
          <Skeleton width="100%" height="30px" />
        </S.WrapperInput>
        <S.WrapperInput>
          <Skeleton width="20%" height="10px" />
          <Skeleton width="100%" height="30px" />
        </S.WrapperInput>
        <S.WrapperInput>
          <Skeleton width="20%" height="10px" />
          <Skeleton width="100%" height="30px" />
        </S.WrapperInput>
      </S.WrapperContent>
      <S.WrapperContent>
        <S.WrapperInput>
          <Skeleton width="20%" height="10px" />
          <Skeleton width="100%" height="30px" />
        </S.WrapperInput>
        <S.WrapperInput>
          <Skeleton width="20%" height="10px" />
          <Skeleton width="100%" height="30px" />
        </S.WrapperInput>
        <S.WrapperInput>
          <Skeleton width="20%" height="10px" />
          <Skeleton width="100%" height="30px" />
        </S.WrapperInput>
      </S.WrapperContent>
      <S.Button>
        <Skeleton width="250px" height="20px" />
      </S.Button>
    </S.Wrapper>
  );
};

export default SkeletonLoading;
