import React from 'react';

import { EButtonVariant } from '@domain/enums/components/common/EButton';

import { useDetailAbandonedCart } from '@store/common/AbandonedCart/DetailAbandonedCartContext';

import PageHeader from '@components/common/utils/PageHeader';

import * as S from './styles';

const Header: React.FC = () => {
  const { dateHeader, detailAbandonedCart, accountRecoveryTemplate } = useDetailAbandonedCart();

  const sendWhatsAppMessage = React.useCallback(() => {
    if (!detailAbandonedCart?.customer.phone) return;

    const recoveryTemplate = accountRecoveryTemplate.find(template => template.type === 'WHATSAPP');

    const text = recoveryTemplate?.message || '';

    const parsedText = text
      .replace(/\n/g, '%0a')
      .replace(/{{nome}}/g, detailAbandonedCart?.customer.name)
      .replace(/{{link}}/g, detailAbandonedCart?.url);

    window.open(
      `https://wa.me/55${detailAbandonedCart?.customer?.phone}?text=${parsedText}`,
      '_blank',
    );
  }, [accountRecoveryTemplate, detailAbandonedCart]);

  return (
    <S.Wrapper>
      <S.TitleAndButton>
        <PageHeader title="Dados do carrinho" goBackButton />

        <S.SendMessageButton onClick={sendWhatsAppMessage} variant={EButtonVariant.SECONDARY}>
          <S.WhatsAppIcon />
          Enviar mensagem
        </S.SendMessageButton>
      </S.TitleAndButton>

      <S.OrderIdAndDateWrapper>
        <S.Tooltip>
          <S.TooltipTrigger>
            <S.TooltipContent>
              <S.TooltipText>Ir para o carrinho</S.TooltipText>
            </S.TooltipContent>
            <S.OrderIdText to={detailAbandonedCart.url} target="_blank">
              {detailAbandonedCart.id}
            </S.OrderIdText>
          </S.TooltipTrigger>
        </S.Tooltip>
        <S.DateText>em {dateHeader}</S.DateText>
      </S.OrderIdAndDateWrapper>
    </S.Wrapper>
  );
};

export default Header;
