import { PlusCircle } from 'phosphor-react';
import React from 'react';
import { useTheme } from 'styled-components/macro';

import { EButtonVariant } from '@domain/enums/components/common/EButton';
import { EHeadingSize } from '@domain/enums/components/common/EHeading';

import { useNavigate, useParams } from 'react-router-dom';
import * as S from './styles';

const Header: React.FC = () => {
  const { accountId, checkoutId } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const createNew = (): void =>
    navigate(`/${accountId}/${checkoutId}/dashboard/marketing/upsells/new`);

  return (
    <S.Wrapper>
      <S.TitleAndButton>
        <S.Title size={EHeadingSize.H2}>Gerenciar Upsell</S.Title>

        <S.NewShippingButton variant={EButtonVariant.SECONDARY} onClick={createNew}>
          <PlusCircle size={20} weight="bold" color={theme.colors.neutral.darker} /> Novo upsell
        </S.NewShippingButton>
      </S.TitleAndButton>

      <S.Description>Crie e gerencie suas ofertas de upsell.</S.Description>
    </S.Wrapper>
  );
};

export default Header;
