import React from 'react';
import { WarningOctagon } from 'phosphor-react';
import { useTheme } from 'styled-components/macro';

import { ETypographySize } from '@domain/enums/components/common/ETypography';

import { useSignUpUserInvited } from '@store/context/public/signUpUserInvited/SignUpUserInvitedContext';

import * as S from './styles';

const CustomModal: React.FC = () => {
  const { isOpenModal, setIsOpenModal } = useSignUpUserInvited();
  const theme = useTheme();

  const onToggleModal = (): void => setIsOpenModal(false);

  setTimeout(() => {
    setIsOpenModal(false);
  }, 5000);

  return (
    <S.Modal
      isOpen={isOpenModal}
      toggle={onToggleModal}
      icon={<WarningOctagon size={24} weight="fill" color={theme?.colors.negative.base} />}
      title="Convite expirado"
    >
      <S.Content size={ETypographySize.h6_button_medium} weight="500">
        O seu convite parece ter expirado. Por favor, solicite um novo ao administrador.
      </S.Content>
    </S.Modal>
  );
};

export default CustomModal;
