import React from 'react';

import { GetBankAccountProvider } from '@store/common/BankAccount/GetBankAccountContext';

import Content from '@components/pages/Dashboard/BankAccount/GetBankAccount';

const GetBankAccount: React.FC = () => {
  return (
    <GetBankAccountProvider>
      <Content />
    </GetBankAccountProvider>
  );
};

export default GetBankAccount;
