import React from 'react';
import useSWR from 'swr';
import { useParams } from 'react-router-dom';

import { ISwr } from '@domain/interfaces/common/swr/ISwr';
import { IShipping } from '@domain/interfaces/common/shipping/IShipping';
import { IGetShippingProvider } from '@domain/interfaces/store/common/shipping/IGetShippingProvider';
import { IListShippingsResponse } from '@domain/interfaces/hooks/services/shipping/IShippingService';

import { DASHBOARD_API_URL } from '@constants/services/apiUrl';
import { dashboardInstance } from '@services/common/dashboardInstance';

import { useToast } from '@store/context/common/ToastContext';
import { useErrorHandler } from '@store/context/common/ErrorHandlerContext';
import { useShippingService } from '@hooks/services/shipping/useShippingService';
import { useAnalytics } from '@store/context/common/AnalyticsContext';

const GetShippingContext = React.createContext<IGetShippingProvider | null>(null);

export const GetShippingProvider: React.FC = ({ children }) => {
  const { accountId, checkoutId } = useParams();
  const { toast } = useToast();
  const { handleError } = useErrorHandler();
  const { deleteShipping, activateShipping, inactivateShipping } = useShippingService();
  const { analytics } = useAnalytics();

  const [isOpenModal, setIsOpenModal] = React.useState<boolean>(false);
  const [isLoadingDeleteShipping, setIsLoadingDeleteShipping] = React.useState<boolean>(false);
  const [shippingDeletedInfo, setShippingDeletedInfo] = React.useState({
    shippingId: '',
    name: '',
  });

  const [shippings, setShippings] = React.useState<Array<IShipping>>([]);
  const [page] = React.useState<number>(0);
  const [totalShippings, setTotalShippings] = React.useState<number>(0);
  const [totalPages] = React.useState<number>(1);

  dashboardInstance.defaults.headers.common['x-zouti-account'] = accountId;

  const SHIPPING_URL = `${DASHBOARD_API_URL}/accounts/${accountId}/checkouts/${checkoutId}/freights?page=${page}&limit=10`;

  const { data, isLoading, isValidating, mutate, error } = useSWR<ISwr<IListShippingsResponse>>(
    SHIPPING_URL,
    dashboardInstance,
  );

  React.useEffect(() => {
    if (error) {
      handleError(error);
    }

    if (data?.data) {
      setShippings(data.data.object);
      setTotalShippings(data.data.object.length);
    }
  }, [data, handleError, error]);

  const deleteCurrentShipping = React.useCallback(
    async shippingIdData => {
      setIsLoadingDeleteShipping(true);
      try {
        await deleteShipping({ shippingId: shippingIdData, accountId, checkoutId });

        analytics.track('Shipping Deleted');

        toast.success('Frete excluído com sucesso.');
        await mutate();
        setIsOpenModal(false);
      } catch (errorDeleteShipping) {
        setIsLoadingDeleteShipping(false);
        handleError(errorDeleteShipping);
      } finally {
        setIsLoadingDeleteShipping(false);
      }
    },
    [deleteShipping, handleError, toast, mutate, accountId, checkoutId, analytics],
  );

  const inactivateCurrentShipping = React.useCallback(
    async (shippingId: string) => {
      try {
        await inactivateShipping({ shippingId, accountId, checkoutId });

        analytics.track('Shipping Updated');

        await mutate();
      } catch (errorInactivateShipping) {
        handleError(errorInactivateShipping);
      }
    },
    [inactivateShipping, handleError, mutate, accountId, checkoutId, analytics],
  );
  const activateCurrentShipping = React.useCallback(
    async (shippingId: string) => {
      try {
        await activateShipping({ shippingId, accountId, checkoutId });

        analytics.track('Shipping Updated');

        await mutate();
      } catch (errorActivateShipping) {
        handleError(errorActivateShipping);
      }
    },
    [activateShipping, handleError, mutate, accountId, checkoutId, analytics],
  );

  const isLoadingShippings = isLoading || isValidating;
  const isShippingsError = Boolean(error);

  return (
    <GetShippingContext.Provider
      value={{
        shippings,
        isOpenModal,
        setIsOpenModal,
        isLoadingShippings,
        isShippingsError,
        mutate,
        page,
        totalPages,
        totalShippings,
        deleteCurrentShipping,
        isLoadingDeleteShipping,
        shippingDeletedInfo,
        setShippingDeletedInfo,
        inactivateCurrentShipping,
        activateCurrentShipping,
      }}
    >
      {children}
    </GetShippingContext.Provider>
  );
};

export const useGetShipping = (): IGetShippingProvider => {
  const context = React.useContext(GetShippingContext);

  if (!context) {
    throw new Error('useGetShippingContext must be used within provider');
  }

  return context;
};
