import styled from 'styled-components/macro';

import ImageBase from '@components/common/DataDisplay/Image';
import TextBase from '@components/common/DataDisplay/Text';
import HeadingBase from '@components/common/DataDisplay/Heading';
import ButtonBase from '@components/common/Inputs/Button';

export const Button = styled(ButtonBase)`
  width: 100%;
  max-width: 380px;
`;

export const Text = styled(TextBase)`
  text-align: center;
  font-size: 1.4rem;
  display: block;
  margin-bottom: 24px;
  max-width: 300px;
`;

export const Title = styled(HeadingBase)`
  text-align: center;
  display: block;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.colors.neutral.black};
`;

export const Body = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
`;

export const Logo = styled(ImageBase)`
  max-width: 192px;
  margin-bottom: 24px;
  display: block;
`;

export const Wrapper = styled.div`
  padding: 30px;
  border-radius: 12px;
  max-width: 600px;
  max-height: 100vh;
  overflow: auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-height: 678px) {
    margin-top: unset;
  }
`;
