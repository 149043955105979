import React from 'react';

import { ListOrderBumpsProvider } from '@store/common/orderBump/ListOrderBumpsContext';

import ListOrderBumps from '@components/pages/Dashboard/OrderBump/ListOrderBumps';

const OrderBump: React.FC = () => {
  return (
    <ListOrderBumpsProvider>
      <ListOrderBumps />
    </ListOrderBumpsProvider>
  );
};

export default OrderBump;
