import React from 'react';

import { EHeadingSize } from '@domain/enums/common/components/EHeading';

import logo from '@assets/images/common/logo.svg';
import logoIcon from '@assets/images/common/logoIcon.svg';

import Form from '../Form';

import * as S from './styles';

const Wrapper: React.FC = () => {
  const imgBackground = 'https://zouti-core-media.s3.amazonaws.com/dashboard/auth/sign_up.png';

  return (
    <S.Container>
      <S.ImageBox image={imgBackground}>
        <S.InfoWrapper align="end">
          <S.BadgeCategory>
            <img src={logoIcon} alt="" height="14px" /> Secure FinTech Solution
          </S.BadgeCategory>
          <S.BadgeText>
            Checkout e gateway integrados em uma plataforma inovadora, transparente e humanizada.
          </S.BadgeText>
        </S.InfoWrapper>
      </S.ImageBox>

      <S.Wrapper>
        <S.Box>
          <S.Logo src={logo} alt="Zouti" />

          <S.Title size={EHeadingSize.H2}>
            <b>Boas-vindas à Zouti</b>
          </S.Title>

          <S.Text>
            Revolucione sua operação e processe seus pagamentos com a melhor plataforma do mercado.
          </S.Text>
        </S.Box>

        <S.Body>
          <Form />

          <S.LinkText>
            Já possui conta? <S.Link to="/">Login</S.Link>
          </S.LinkText>
        </S.Body>
      </S.Wrapper>
    </S.Container>
  );
};

export default Wrapper;
