import React from 'react';

import { useListOrderBumps } from '@store/common/orderBump/ListOrderBumpsContext';

import TableRow from './TableRow';

import * as S from './styles';

const Body: React.FC = () => {
  const { orderBumps } = useListOrderBumps();

  return (
    <S.Wrapper>
      <S.Table>
        <S.Table.Header>
          <S.Table.Row>
            <S.Table.Head>Status</S.Table.Head>
            <S.Table.Head>Titulo</S.Table.Head>
            <S.Table.Head>Ao comprar o produto...</S.Table.Head>
            <S.Table.Head>Oferecer o produto...</S.Table.Head>
            <S.Table.Head />
          </S.Table.Row>
        </S.Table.Header>

        <S.Table.Body>
          {orderBumps.map(orderBump => (
            <TableRow orderBump={orderBump} key={orderBump.id} />
          ))}
        </S.Table.Body>
      </S.Table>
    </S.Wrapper>
  );
};

export default Body;
