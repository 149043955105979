import styled from 'styled-components/macro';

interface IWrapperProps {
  heightOffset: number;
}

export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Wrapper = styled.div<IWrapperProps>`
  max-width: 260px;
  width: 100%;
  height: ${({ heightOffset }) => `calc(100vh - 56px - ${heightOffset}px)`};
  background-color: ${({ theme }) => theme.colors.neutral.white};
  padding: 8px 8px 32px 8px;
  box-sizing: border-box;
  border-right: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
  position: sticky;
  top: ${({ heightOffset }) => `calc(56px + ${heightOffset}px)`};
  left: 0;
`;
