import React from 'react';

import { useEditWebhook } from '@store/common/webhook/EditWebhookContext';

import Header from './Header';
import Body from './Body';

import * as S from './styles';

const EditWebhook: React.FC = () => {
  const { isLoadingWebhook, isWebhookError, webhookName, webhookUrl } = useEditWebhook();

  if (isLoadingWebhook) {
    return <div>Loading...</div>;
  }

  if (isWebhookError) {
    return <div>Error...</div>;
  }

  return (
    <S.Wrapper>
      <Header />

      <Body />
    </S.Wrapper>
  );
};

export default EditWebhook;
