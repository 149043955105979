import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';

import { UserAccountProvider } from '@store/context/common/UserAccount';
import { UserSessionProvider } from '@store/context/common/UserSession';
import { UserCheckoutProvider } from '@store/context/common/UserCheckout';
import { AlertsProvider } from '@store/context/common/AlertsContext';
import { GetHopyTransactionsStatementsProvider } from '@store/common/hopyTransactionsStatements/GetHopyTransactionsStatements';

import { useConfig } from '@store/context/common/ConfigContext';
import { GetAccountStepProvider } from '@store/common/home/GetAccountStepContext';

import Sidebar from '@components/core/Utils/Sidebar';
import NavBar from '@components/common/utils/NavBar';
import Alerts from '@components/common/utils/Alerts';

import * as S from './styles';

const DashboardOutlet: React.FC = () => {
  const { user } = useConfig();

  const isAuthenticated = Boolean(user);

  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  return (
    <UserSessionProvider>
      <UserAccountProvider>
        <UserCheckoutProvider>
          <GetAccountStepProvider>
            <AlertsProvider>
              <GetHopyTransactionsStatementsProvider>
                <S.Wrapper>
                  <Alerts />

                  <NavBar />

                  <S.ContentWrapper>
                    <Sidebar />

                    <S.Content>
                      <Outlet />
                    </S.Content>
                  </S.ContentWrapper>
                </S.Wrapper>
              </GetHopyTransactionsStatementsProvider>
            </AlertsProvider>
          </GetAccountStepProvider>
        </UserCheckoutProvider>
      </UserAccountProvider>
    </UserSessionProvider>
  );
};

export default DashboardOutlet;
