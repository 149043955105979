import React from 'react';
import useSWR from 'swr';

import { IGetProfileResponse } from '@domain/interfaces/hooks/services/profile/IProfileService';
import { IProfile } from '@domain/interfaces/common/profile/IProfile';
import { ISwr } from '@domain/interfaces/common/swr/ISwr';
import { IUpdatePersonalDataProvider } from '@domain/interfaces/store/common/profile/IUpdatePersonalDataProvider';

import { DASHBOARD_API_URL } from '@constants/services/apiUrl';
import { dashboardInstance } from '@services/common/dashboardInstance';

import { useErrorHandler } from '@store/context/common/ErrorHandlerContext';
import { useProfileService } from '@hooks/services/profile/useProfileService';
import { useToast } from '@store/context/common/ToastContext';

const UpdatePersonalDataContext = React.createContext<IUpdatePersonalDataProvider | null>(null);

export const UpdatePersonalDataProvider: React.FC = ({ children }) => {
  const { toast } = useToast();
  const { handleError } = useErrorHandler();
  const { updateProfilePersonalData } = useProfileService();
  const [isUpdatingPersonalData, setIsUpdatingPersonalData] = React.useState<boolean>(false);
  const [profile, setProfile] = React.useState<IProfile>({} as IProfile);

  const GET_PROFILE_DATA_URL = `${DASHBOARD_API_URL}/accounts/:accountId/profile`;

  const { data, isLoading, isValidating, error } = useSWR<ISwr<IGetProfileResponse>>(
    GET_PROFILE_DATA_URL,
    dashboardInstance,
  );

  React.useEffect(() => {
    if (error) {
      handleError(error);
    }

    if (data?.data?.profile) {
      setProfile(data.data.profile);
    }
  }, [data, handleError, error]);

  const updateCurrentProfilePersonalData = React.useCallback(
    async personalData => {
      setIsUpdatingPersonalData(true);

      try {
        await updateProfilePersonalData({ profileId: profile.id, payload: personalData });
        setIsUpdatingPersonalData(false);
        toast.success('Dados pessoais atualizado com sucesso!');
      } catch (err) {
        setIsUpdatingPersonalData(false);
        handleError(err);
      } finally {
        setIsUpdatingPersonalData(false);
      }
    },
    [updateProfilePersonalData, handleError, toast, profile.id],
  );

  const isLoadingProfile = isLoading || isValidating;
  const isProfileError = Boolean(error);

  return (
    <UpdatePersonalDataContext.Provider
      value={{
        profile,
        isLoadingProfile,
        isProfileError,
        isUpdatingPersonalData,
        updateCurrentProfilePersonalData,
      }}
    >
      {children}
    </UpdatePersonalDataContext.Provider>
  );
};

export const useUpdatePersonalData = (): IUpdatePersonalDataProvider => {
  const context = React.useContext(UpdatePersonalDataContext);

  if (!context) {
    throw new Error('useProfilePersonalData must be used within provider');
  }

  return context;
};
