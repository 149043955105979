import { IChart } from '@domain/interfaces/store/common/dashboard/IOverview';

interface INewData {
  name: string | null;
  value: number;
}

const formatDate = (date: string): string => {
  const [year, month, day] = date.split('-');

  return `${day}/${month}/${year}`;
};

export const mapMetrics = (data: Array<IChart> | undefined): Array<INewData> => {
  if (!data) return [];

  const mappedData = data.map((value, index) => ({
    name: index === 0 || index === data.length - 1 ? formatDate(value.date) : null,
    value: value.value ? value.value / 100 : 0,
  }));

  return mappedData;
};
