import React from 'react';

import { ListWebhooksProvider } from '@store/common/webhook/ListWebhooksContext';

import Wrapper from '@components/pages/Dashboard/Webhook/ListWebhooks';

const ListWebhooks: React.FC = () => {
  return (
    <ListWebhooksProvider>
      <Wrapper />
    </ListWebhooksProvider>
  );
};

export default ListWebhooks;
