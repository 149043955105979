import styled from 'styled-components/macro';

import InputGroupBase from '@components/common/Inputs/InputGroup';
import LabelBase from '@components/common/DataDisplay/Label';
import TextFieldBase from '@components/common/Inputs/TextField';
import TypographyBase from '@components/common/DataDisplay/Typography';

export const Form = styled.form``;

export const Credentials = styled.div`
  width: 100%;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  background: #fff;
  display: flex;
  flex-direction: column;
  padding: 40px 24px;
  gap: 24px;
  align-self: flex-start;
`;

export const CredentialsTitle = styled(TypographyBase)`
  color: ${({ theme }) => theme.colors.neutral.darker};
`;

export const InputGroup = styled(InputGroupBase)``;

export const Label = styled(LabelBase)``;

export const Input = styled(TextFieldBase)``;
