import React from 'react';

import { NewPromocodeProvider } from '@store/common/promocode/NewPromocodeContext';

import Content from '@components/pages/Dashboard/Coupon/NewCoupon';

const NewCoupon: React.FC = () => {
  return (
    <NewPromocodeProvider>
      <Content />
    </NewPromocodeProvider>
  );
};

export default NewCoupon;
