import React from 'react';
import { Eye, EyeClosed, IconProps } from 'phosphor-react';
import { useTheme } from 'styled-components/macro';

import { ETextFieldSize } from '@domain/enums/components/common/ETextField';

import * as S from './styles';

interface ITextFieldProps {
  type: string;
  name?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  onKeyUp?: React.KeyboardEventHandler<HTMLInputElement> | undefined;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement> | undefined;
  placeholder?: string;
  disabled?: boolean;
  size?: ETextFieldSize;
  defaultValue?: string | number;
  value?: string | number;
  isError?: boolean;
  icon?: React.ForwardRefExoticComponent<IconProps & React.RefAttributes<SVGSVGElement>>;
}

const TextField: React.ForwardRefRenderFunction<HTMLInputElement, ITextFieldProps> = (
  {
    name,
    onChange,
    onKeyUp,
    onKeyDown,
    placeholder,
    disabled,
    type,
    size = ETextFieldSize.MEDIUM,
    defaultValue,
    value,
    isError,
    icon,
    ...rest
  },
  ref,
) => {
  const theme = useTheme();

  const [showPassword, setShowPassword] = React.useState<boolean>(false);

  const getIconColor = (): string => {
    if (isError) return theme.colors.negative.base;

    return theme.colors.neutral.base;
  };

  const getInputType = (): string => {
    if (type === 'password' && !showPassword) return 'password';

    if (type === 'password' && showPassword) return 'text';

    return type;
  };

  const Icon = icon;

  return (
    <S.InputWrapper {...rest} disabled={disabled} size={size} isError={isError}>
      {Icon && <Icon size={22} color={getIconColor()} />}

      <S.Input
        type={getInputType()}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        onKeyUp={onKeyUp}
        onKeyDown={onKeyDown}
        disabled={disabled}
        defaultValue={defaultValue}
        value={value}
        ref={ref}
      />

      {type === 'password' && (
        <>
          {showPassword ? (
            <Eye
              size={22}
              onClick={() => setShowPassword(!showPassword)}
              color={theme.colors.neutral.darker}
            />
          ) : (
            <EyeClosed
              size={22}
              onClick={() => setShowPassword(!showPassword)}
              color={theme.colors.neutral.darker}
            />
          )}
        </>
      )}
    </S.InputWrapper>
  );
};

export default React.forwardRef(TextField);
