import React from 'react';
import { useForm } from 'react-hook-form';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/common/EHeading';
import { EExperienceTime, EUserOnboardingStep } from '@domain/enums/common/onboarding/EUser';

import { useUserOnboarding } from '@store/context/onboarding/user/UserOnboardingContext';

import * as S from './styles';

const ExperienceTime: React.FC = () => {
  const { register, handleSubmit, watch, getValues } = useForm();
  const { handleExperienceTimeAnswer, handleOnboardingStep } = useUserOnboarding();

  const experienceTimeRegister = register('experience_time');

  const onSubmit = React.useCallback(
    data => {
      handleExperienceTimeAnswer(data.experience_time);
      handleOnboardingStep(EUserOnboardingStep.SECOND_STEP);
    },
    [handleExperienceTimeAnswer, handleOnboardingStep],
  );

  watch(['experience_time']);

  const [experienceTimeValue] = getValues(['experience_time']);

  const isSubmitButtonDisabled = Boolean(!experienceTimeValue);

  return (
    <S.Wrapper onSubmit={handleSubmit(onSubmit)}>
      <S.QuestionText size={EHeadingSize.H6} fontWeight={EHeadingWeight.MEDIUM}>
        Quanto tempo você tem de experiência no segmento da sua empresa?
      </S.QuestionText>

      <S.OptionsWrapper>
        <S.Option htmlFor={EExperienceTime.LESS_THAN_SIX_MONTHS}>
          <S.RadioOption
            {...experienceTimeRegister}
            id={EExperienceTime.LESS_THAN_SIX_MONTHS}
            value={EExperienceTime.LESS_THAN_SIX_MONTHS}
          />
          <S.Text>Menos de 6 meses</S.Text>
        </S.Option>

        <S.Option htmlFor={EExperienceTime.BETWEEN_SIX_MONTHS_AND_A_YEAR}>
          <S.RadioOption
            {...experienceTimeRegister}
            id={EExperienceTime.BETWEEN_SIX_MONTHS_AND_A_YEAR}
            value={EExperienceTime.BETWEEN_SIX_MONTHS_AND_A_YEAR}
          />
          <S.Text>Entre 6 meses e 1 ano</S.Text>
        </S.Option>

        <S.Option htmlFor={EExperienceTime.BETWEEN_ONE_AND_TWO_YEARS}>
          <S.RadioOption
            {...experienceTimeRegister}
            id={EExperienceTime.BETWEEN_ONE_AND_TWO_YEARS}
            value={EExperienceTime.BETWEEN_ONE_AND_TWO_YEARS}
          />
          <S.Text>Entre 1 e 2 anos</S.Text>
        </S.Option>

        <S.Option htmlFor={EExperienceTime.BETWEEN_TWO_AND_THREE_YEARS}>
          <S.RadioOption
            {...experienceTimeRegister}
            id={EExperienceTime.BETWEEN_TWO_AND_THREE_YEARS}
            value={EExperienceTime.BETWEEN_TWO_AND_THREE_YEARS}
          />
          <S.Text>Entre 2 e 3 anos</S.Text>
        </S.Option>

        <S.Option htmlFor={EExperienceTime.MORE_THAN_THREE_YEARS}>
          <S.RadioOption
            {...experienceTimeRegister}
            id={EExperienceTime.MORE_THAN_THREE_YEARS}
            value={EExperienceTime.MORE_THAN_THREE_YEARS}
          />
          <S.Text>Mais de 3 anos</S.Text>
        </S.Option>
      </S.OptionsWrapper>

      <S.SubmitButton type="submit" disabled={isSubmitButtonDisabled}>
        Continuar
      </S.SubmitButton>
    </S.Wrapper>
  );
};

export default ExperienceTime;
