import React from 'react';

import 'react-loading-skeleton/dist/skeleton.css';

import * as S from './styles';

const SkeletonLoading: React.FC = () => {
  return (
    <S.Wrapper>
      <S.SwitchSkeleton />
      <S.WrapperInput>
        <S.LabelSkeleton />
        <S.TextFieldSkeleton />
      </S.WrapperInput>
      <S.WrapperInput>
        <S.LabelSkeleton />
        <S.TextFieldSkeleton />
      </S.WrapperInput>
      <S.VerifyButtonSkeleton />
    </S.Wrapper>
  );
};

export default SkeletonLoading;
