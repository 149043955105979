import React from 'react';
import { format } from 'date-fns';

import boletoIcon from '@assets/images/common/paymentMethod/boleto.svg';
import pixIcon from '@assets/images/common/paymentMethod/pix.svg';

import { EHeadingSize } from '@domain/enums/components/common/EHeading';
import { EHeadingWeight } from '@domain/enums/common/components/EHeading';

import { numberToString } from '@helpers/utils/common/number';
import { getCreditCardIssuer } from '@utils/common/paymentMethod';
import { findTransactionError } from '@utils/common/transactionError';

import { useGetOrder } from '@store/common/order/GetOrder';

import * as S from './styles';

const PaymentCard: React.FC = () => {
  const { order } = useGetOrder();

  const declinedReason = order?.payment.decline_reason || '';

  const totalDiscountAmount = order?.discounts.reduce((acc, value) => acc + value.amount, 0);

  const transactionId = order?.payment?.processor?.transaction_id || '';
  const transactionDate = format(
    new Date(order?.order.created_at ? order.order.created_at : new Date()),
    "dd/MM/yyyy 'às' HH:mm:ss",
  );

  const productCostAmount = `R$ ${
    order?.order.amount_subtotal ? numberToString(order?.order.amount_subtotal / 100 || 0, 2) : 0
  }`;
  const discountAmount = `R$ ${
    totalDiscountAmount ? numberToString(totalDiscountAmount / 100 || 0, 2) : 0
  }`;
  const shippingAmount = `R$ ${
    order?.shippings[0]?.amount ? numberToString(order?.shippings[0]?.amount / 100 || 0, 2) : 0
  }`;
  const paymentMethod = order?.payment?.method;
  const cardIssuer = order?.card?.brand || '';
  const cardLastFourDigits = order?.card?.last_digits;
  const installments = order?.payment?.installments || 1;
  const transactionOwnerName = order?.customer.name;

  const getTotalValue = React.useCallback(() => {
    if (!order) return '';

    if (paymentMethod === 'CREDIT_CARD')
      return `${installments}x de R$ ${numberToString(
        order.order.amount_total / 100 / installments || 0,
        2,
      )}`;

    return `R$ ${numberToString(order?.order.amount_total / 100 || 0, 2)}`;
  }, [order, paymentMethod, installments]);

  const getPaymentValue = React.useCallback(() => {
    if (paymentMethod === 'PIX') {
      return (
        <S.PaymentMethodText>
          <S.PaymentMethodIcon src={pixIcon} />
          Pix
        </S.PaymentMethodText>
      );
    }

    if (paymentMethod === 'BOLETO') {
      return (
        <S.PaymentMethodText>
          <S.PaymentMethodIcon src={boletoIcon} />
          Boleto
        </S.PaymentMethodText>
      );
    }

    if (paymentMethod === 'CREDIT_CARD') {
      const cardIssuerIcon = getCreditCardIssuer[cardIssuer];

      return (
        <S.PaymentMethodText>
          <S.PaymentMethodIcon src={cardIssuerIcon} />
          (Crédito) **** {cardLastFourDigits}
        </S.PaymentMethodText>
      );
    }

    return <></>;
  }, [paymentMethod, cardIssuer, cardLastFourDigits]);

  return (
    <S.Wrapper>
      <S.TitleWrapper>
        <S.TitleAndIdWrapper>
          <S.Title size={EHeadingSize.H6} fontWeight={EHeadingWeight.BOLD}>
            Pagamento
          </S.Title>

          <S.IdText>#{transactionId}</S.IdText>
        </S.TitleAndIdWrapper>

        <S.DateText>{transactionDate}</S.DateText>
      </S.TitleWrapper>

      {Boolean(!declinedReason) && (
        <S.AmountDetailsWrapper>
          <S.LabelAndValue>
            <S.Label>Produtos</S.Label>
            <S.Value>{productCostAmount}</S.Value>
          </S.LabelAndValue>

          <S.LabelAndValue>
            <S.Label>Desconto</S.Label>
            <S.Value>{discountAmount}</S.Value>
          </S.LabelAndValue>

          <S.LabelAndValue>
            <S.Label>Frete</S.Label>
            <S.Value>{shippingAmount}</S.Value>
          </S.LabelAndValue>
        </S.AmountDetailsWrapper>
      )}

      <S.Spacer />

      <S.OverviewWrapper>
        <S.LabelAndValue>
          <S.Label>Forma de Pagamento</S.Label>
          {getPaymentValue()}
        </S.LabelAndValue>

        <S.LabelAndValue>
          <S.Label>Titular</S.Label>
          <S.Value>{transactionOwnerName}</S.Value>
        </S.LabelAndValue>

        <S.LabelAndValue>
          <S.Label>Total</S.Label>
          <S.Value>{getTotalValue()}</S.Value>
        </S.LabelAndValue>
      </S.OverviewWrapper>

      {Boolean(declinedReason) && (
        <>
          <S.Spacer />

          <S.RefusedInfoCard>
            <S.RefusedInfoText>
              {`${declinedReason}: ${findTransactionError(declinedReason)}`}
            </S.RefusedInfoText>
          </S.RefusedInfoCard>
        </>
      )}
    </S.Wrapper>
  );
};

export default PaymentCard;
