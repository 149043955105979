import React from 'react';

import Content from '@components/pages/Dashboard/AbandonedCart/ListAbandonedCart';
import { ListAbandonedCartProvider } from '@store/common/AbandonedCart/ListAbandonedCartContext';

const ListAbandonedCart: React.FC = () => {
  return (
    <ListAbandonedCartProvider>
      <Content />
    </ListAbandonedCartProvider>
  );
};

export default ListAbandonedCart;
