import styled from 'styled-components/macro';
import CustomTooltipBase from '@components/common/utils/CustomTooltip';
import { Info as Icon } from 'phosphor-react';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const Tooltip = styled(CustomTooltipBase)``;

export const TooltipTrigger = styled(CustomTooltipBase.Trigger)`
  height: 13px;
  width: 14px;
`;

export const TooltipContent = styled(CustomTooltipBase.Content)``;

export const InfoIcon = styled(Icon)``;
