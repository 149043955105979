import React from 'react';

import { ETypographySize } from '@domain/enums/components/common/ETypography';

import * as S from './styles';

interface IReinstallCheckoutModalProps {
  isOpen: boolean;
  toggle: () => void;
  isSuccessButtonLoading?: boolean;
  contentText?: string;
}

const ReinstallCheckoutModal: React.FC<IReinstallCheckoutModalProps> = ({
  isOpen,
  toggle,
  isSuccessButtonLoading,
  contentText,
}) => {
  return (
    <S.Modal
      isOpen={isOpen}
      toggle={toggle}
      title="Reinstalar checkout"
      cancelText="Cancelar"
      successButtonType="submit"
      successText="Sim, reinstalar"
      isSuccessButtonLoading={isSuccessButtonLoading}
    >
      <S.Content size={ETypographySize.h6_button_medium} weight="500">
        {contentText}
      </S.Content>
    </S.Modal>
  );
};

export default ReinstallCheckoutModal;
