import styled from 'styled-components/macro';
import Skeleton from 'react-loading-skeleton';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 443px;
  height: 376px;
  padding: 24px;
  background-color: ${({ theme }) => theme.colors.neutral.white};
`;

export const WrapperItems = styled.div`
  display: flex;
  align-self: center;
  gap: 8px;
`;

export const ChartBarWrapper = styled.div`
  position: relative;
`;

export const ChartBar = styled(Skeleton)``;

export const ContainerIcon = styled(Skeleton)`
  position: absolute;
  border-radius: 8px;
  z-index: 2;
  top: 10%;
  right: 63%;
`;

export const Text = styled(Skeleton)`
  position: absolute;
  z-index: 2;
  top: 85%;
  right: 50%;
`;

export const ChartBody = styled.div`
  position: absolute;
  display: flex;
  gap: 8px;
  align-items: flex-end;
  z-index: 2;
  top: 30%;
  right: 12%;
`;

export const Graphic = styled.div``;
