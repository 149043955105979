import styled from 'styled-components/macro';

import HeadingBase from '@components/common/DataDisplay/Heading';
import TextBase from '@components/common/DataDisplay/Text';
import ImageBase from '@components/common/DataDisplay/Image';

export const OverviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const PaymentMethodIcon = styled(ImageBase)`
  width: 36px;
`;

export const PaymentMethodText = styled(TextBase)`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.neutral.dark};
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Spacer = styled.hr`
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  margin: 16px 0;
`;

export const Value = styled(TextBase)`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.neutral.darkest};
`;

export const Label = styled(TextBase)`
  color: ${({ theme }) => theme.colors.neutral.dark};
  font-size: 1.4rem;
`;

export const LabelAndValue = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AmountDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const DateText = styled(TextBase)`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.neutral.dark};
`;

export const Title = styled(HeadingBase)`
  color: ${({ theme }) => theme.colors.neutral.black};
`;

export const IdText = styled(TextBase)`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.neutral.darker};
`;

export const TitleAndIdWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const TitleWrapper = styled.div`
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Wrapper = styled.div`
  padding: 24px;
  height: 300px;
  box-sizing: border-box;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.neutral.white};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
`;

export const RefusedInfoCard = styled.div`
  padding: 16px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.negative.lightest_10};
`;

export const RefusedInfoText = styled(TextBase)`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.negative.base};
`;
