import styled, { css } from 'styled-components/macro';

interface ITableStyleProps {
  isSecondary?: boolean;
}

export const Table = styled.table<ITableStyleProps>`
  width: 100%;
  min-width: max-content;
  border-collapse: collapse;

  ${({ isSecondary, theme }) =>
    isSecondary &&
    css`
      tr {
        background-color: #ffffff;
      }

      td {
        background-color: ${theme.colors.neutral.gray_white};
        border-color: #ffffff;
      }
    `}
`;

export const Container = styled.div`
  padding: 0;
  overflow-x: scroll;
  border-radius: 15px;

  &::-webkit-scrollbar {
    height: 6px;
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #fff;
    border-radius: 25px;
    margin-bottom: 4px;
  }
`;
