import styled from 'styled-components/macro';

import TableBase from '@components/common/utils/Table';

export const Wrapper = styled.div`
  max-width: 1024px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
`;

export const Table = styled(TableBase)`
  th {
    padding-bottom: 16px !important;
  }
`;
