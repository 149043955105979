import React from 'react';

import { useGetAccountStep } from '@store/common/home/GetAccountStepContext';
import { useUserSession } from '@store/context/common/UserSession';
import PageHeader from '@components/common/utils/PageHeader';

import * as S from './styles';

const Header: React.FC = () => {
  const { isFirstStepDone } = useGetAccountStep();
  const { user } = useUserSession();

  const userName = isFirstStepDone ? `Olá, ${user?.user?.name}` : `Olá, ${user?.user?.name} 👋`;
  const description = isFirstStepDone
    ? 'Hoje é o dia perfeito para começar a vender. 🚀'
    : 'Configure sua loja para começar a utilizar o checkout transparente.';

  return (
    <S.Wrapper>
      <PageHeader title={userName} description={description} />
    </S.Wrapper>
  );
};

export default Header;
