import React from 'react';
import Tab from '@components/common/utils/Tab';
import Option from '@components/common/utils/Tab/Option';
import { NewRecoverySMSProvider } from '@store/common/recovery/NewRecoverySMSContext';
import { UpdateRecoveryWhatsAppProvider } from '@store/common/recovery/UpdateRecoveryTemplateWhatsAppContext';
import SMS from '../Tabs/sms';
import WhatsApp from '../Tabs/whatsapp';

const Body: React.FC = () => {
  const [selectedTab, setSelectedTab] = React.useState<number>(0);
  const onChangeTab = React.useCallback(index => setSelectedTab(index), []);

  return (
    <>
      <Tab defaultTab={selectedTab} onChangeTab={onChangeTab}>
        <Option>Configurar SMS</Option>
        <Option>Configurar WhatsApp</Option>
      </Tab>
      {selectedTab === 0 && (
        <NewRecoverySMSProvider>
          <SMS />
        </NewRecoverySMSProvider>
      )}
      {selectedTab === 1 && (
        <UpdateRecoveryWhatsAppProvider>
          <WhatsApp />
        </UpdateRecoveryWhatsAppProvider>
      )}
    </>
  );
};

export default Body;
