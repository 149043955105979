import { ArrowLeft } from 'phosphor-react';
import React from 'react';
import { useTheme } from 'styled-components/macro';

import { EHeadingSize } from '@domain/enums/components/common/EHeading';

import { useNavigate, useParams } from 'react-router-dom';
import * as S from './styles';

const Header: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { accountId, checkoutId } = useParams();

  const navigateBack = (): void =>
    navigate(`/${accountId}/${checkoutId}/dashboard/marketing/order-bumps`);

  return (
    <S.Wrapper>
      <S.Title size={EHeadingSize.H2}>
        <ArrowLeft
          size={20}
          weight="bold"
          color={theme.colors.neutral.darkest}
          style={{ cursor: 'pointer' }}
          onClick={navigateBack}
        />{' '}
        Criar Order Bump
      </S.Title>

      <S.Description>
        Crie de forma simples um order bump para disponibilizar para seus clientes.
      </S.Description>
    </S.Wrapper>
  );
};

export default Header;
