import React from 'react';

import { useGroupsContext } from '@store/common/Group/NewGroupsContext';

import { TableRow } from './TableRow';
import DeleteUserModal from '../../DeleteModel';

import * as S from './styles';

const Groups: React.FC = () => {
  const {
    groups,
    isOpenDeleteModal,
    onToggleDeleteModel,
    deleteGroupData,
    deleteCurrentGroup,
    isDeleteGroupLoading,
  } = useGroupsContext();

  return (
    <S.Wrapper>
      <DeleteUserModal
        title="Remover grupo"
        handleDelete={deleteCurrentGroup}
        isOpen={isOpenDeleteModal}
        isSuccessButtonLoading={isDeleteGroupLoading}
        toggle={onToggleDeleteModel}
        contentText={`Você está prestes a remover o grupo ${deleteGroupData?.groupName}. Essa ação é irreversível. Deseja continuar?`}
      />
      <S.Table>
        <S.TableHeader>
          <S.Table.Row>
            {/* <S.TableHeaderCheckbox>
              <S.Checkbox />
            </S.TableHeaderCheckbox> */}
            <S.Table.Head>Grupo</S.Table.Head>
            <S.Table.Head>Qtd. usuários</S.Table.Head>
            <S.Table.Head />
          </S.Table.Row>
        </S.TableHeader>

        <S.Table.Body>
          {groups.map((group, index) => (
            <TableRow group={group} key={index.toFixed()} />
          ))}
        </S.Table.Body>
      </S.Table>
    </S.Wrapper>
  );
};

export default Groups;
