import React from 'react';
import useSWR from 'swr';
import { useParams } from 'react-router-dom';

import { ISwr } from '@domain/interfaces/common/swr/ISwr';
import { IUpsell } from '@domain/interfaces/common/upsell/IUpsell';
import { IListUpsellsProvider } from '@domain/interfaces/store/common/upsell/IListUpsellProvider';

import { DASHBOARD_API_URL } from '@constants/services/apiUrl';
import { dashboardInstance } from '@services/common/dashboardInstance';

import { useToast } from '@store/context/common/ToastContext';
import { useErrorHandler } from '@store/context/common/ErrorHandlerContext';
import { useUpsellService } from '@hooks/services/upsell/useUpsellService';
import { useAnalytics } from '@store/context/common/AnalyticsContext';

const ListUpsellsContext = React.createContext<IListUpsellsProvider | null>(null);

export const ListUpsellsProvider: React.FC = ({ children }) => {
  const { toast } = useToast();
  const { analytics } = useAnalytics();
  const { handleError } = useErrorHandler();
  const { accountId, checkoutId } = useParams();
  const { deleteUpsell, updateUpsell, activateUpsell, inactivateUpsell } = useUpsellService();

  const [upsells, setUpsells] = React.useState<Array<IUpsell>>([]);
  const [isRemovingUpsell, setIsRemovingUpsell] = React.useState<boolean>(false);

  dashboardInstance.defaults.headers.common['x-zouti-account'] = accountId;

  const UPSELL_URL = `${DASHBOARD_API_URL}/accounts/${accountId}/checkouts/${checkoutId}/upsells?page=1`;

  const { data, isLoading, isValidating, mutate, error } = useSWR<ISwr>(
    UPSELL_URL,
    dashboardInstance,
  );

  const updateUpsellStatus = React.useCallback(
    async (upsellId, payload) => {
      try {
        await updateUpsell({ upsellId, accountId, checkoutId, payload });
      } catch (responseError) {
        handleError(responseError);
      }
    },
    [accountId, checkoutId, handleError, updateUpsell],
  );

  const removeUpsell = React.useCallback(
    async (upsell: IUpsell) => {
      try {
        setIsRemovingUpsell(true);

        await deleteUpsell({ accountId, checkoutId, upsellId: upsell.id });

        analytics.track('Upsell Deleted');

        toast.success('Upsell excluído com sucesso!');

        setIsRemovingUpsell(false);

        await mutate();
      } catch (responseError) {
        setIsRemovingUpsell(false);
        handleError(responseError);
      }
    },
    [accountId, checkoutId, handleError, deleteUpsell, mutate, toast, analytics],
  );

  const activateSelectedUpsell = React.useCallback(
    async (upsellId: string | undefined) => {
      try {
        await activateUpsell({ accountId, checkoutId, upsellId });

        analytics.track('Upsell Updated');

        toast.success('Upsell desativado com sucesso!');
      } catch (responseError) {
        handleError(responseError);
      }
    },
    [accountId, checkoutId, activateUpsell, handleError, toast, analytics],
  );

  const inactivateSelectedUpsell = React.useCallback(
    async (upsellId: string | undefined) => {
      try {
        await inactivateUpsell({ accountId, checkoutId, upsellId });

        analytics.track('Upsell Updated');

        toast.success('Order bump desativado com sucesso!');
      } catch (responseError) {
        handleError(responseError);
      }
    },
    [accountId, checkoutId, inactivateUpsell, handleError, toast, analytics],
  );

  React.useEffect(() => {
    if (error) {
      handleError(error);
    }

    if (data?.data?.object) {
      setUpsells(data.data.object);
    }
  }, [data, handleError, error]);

  const isLoadingUpsells = isLoading || isValidating;
  const isUpsellsError = Boolean(error);

  return (
    <ListUpsellsContext.Provider
      value={{
        upsells,
        isLoadingUpsells,
        isRemovingUpsell,
        isUpsellsError,
        removeUpsell,
        updateUpsellStatus,
        mutate,
        activateSelectedUpsell,
        inactivateSelectedUpsell,
      }}
    >
      {children}
    </ListUpsellsContext.Provider>
  );
};

export const useListUpsells = (): IListUpsellsProvider => {
  const context = React.useContext(ListUpsellsContext);

  if (!context) {
    throw new Error('useListUpsells must be used within provider');
  }

  return context;
};
