import React from 'react';

import AppSkeletonLoading from '@components/common/utils/AppSkeletonLoading';
import Body from '../Body';
import Header from '../Header';

import * as S from './styles';

const Wrapper: React.FC = () => {
  const isLoadingReportana = false;
  const isValidationReportana = false;

  if (isLoadingReportana || isValidationReportana) {
    return <AppSkeletonLoading />;
  }

  return (
    <S.Wrapper>
      <Header />

      <Body />
    </S.Wrapper>
  );
};

export default Wrapper;
