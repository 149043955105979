import React from 'react';

import { useNavigate } from 'react-router-dom';

import { EButtonVariant } from '@domain/enums/components/common/EButton';

import { useGA4Context } from '@store/common/GA4/GA4Context';
import * as S from './styles';

const SaveForm: React.FC = () => {
  const navigate = useNavigate();
  const { isSubmittingForm } = useGA4Context();

  const onCancelClick = React.useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <S.Wrapper>
      <S.ButtonsWrapper>
        <S.CancelButton type="button" onClick={onCancelClick} variant={EButtonVariant.SECONDARY}>
          Cancelar
        </S.CancelButton>

        <S.SaveButton type="submit" isLoading={isSubmittingForm} disabled={isSubmittingForm}>
          Salvar
        </S.SaveButton>
      </S.ButtonsWrapper>
    </S.Wrapper>
  );
};

export default SaveForm;
