import styled from 'styled-components/macro';

import TableBase from '@components/common/utils/Table';
import TextBase from '@components/common/DataDisplay/Text';

export const Table = styled(TableBase)``;

export const TableRow = styled(Table.Row)`
  cursor: pointer;
  &:hover > td {
    background-color: ${({ theme }) => theme.colors.neutral.gray_dark};
  }
`;

export const Text = styled(TextBase)`
  overflow: hidden;
  font-weight: 500;
  text-overflow: ellipsis;
`;

export const TextTransferType = styled(Text)`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.neutral.dark};
`;

export const ContentIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  width: 32px;
  height: 32px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lighter};
`;

export const TransferValueData = styled(Table.Data)`
  display: grid;
  justify-content: start;
  align-items: center;
  grid-template-rows: auto auto;
  grid-template-columns: repeat(1fr, 3);
  gap: 8px;

  div:first-child {
    grid-column: 1;
    grid-row: 1 / span 2;
  }

  span:nth-child(2) {
    grid-column: 2 / span 2;
    grid-row: 1;
  }

  span:nth-child(3) {
    grid-column: 2 / span 2;
    grid-row: 2;
  }
`;
