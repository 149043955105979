import * as yup from 'yup';

export const signUpSchema = yup.object().shape({
  name: yup
    .string()
    .min(2, 'O nome precisa conter no mínimo 2 letras.')
    .max(80, 'O nome pode conter no máximo 80 letras.'),
  email: yup.string().email('Insira um e-mail válido.').required('Este campo é obrigatório.'),
  phone: yup.string().required('Este campo é obrigatório.'),
  password: yup
    .string()
    .required('Este campo é obrigatório')
    .min(6, 'A senha deve conter no mínimo 6 caracteres'),
  password_confirmation: yup
    .string()
    .oneOf(
      [yup.ref('password'), undefined],
      'A senha está diferente da senha informada no campo anterior.',
    ),
});

export const signUpUserInvitedSchema = signUpSchema.omit(['phone', 'password_confirmation']);
