import React from 'react';
import { CaretCircleLeft, Check, LockSimple, X } from 'phosphor-react';
import { ClockCountdown, PiggyBank } from '@phosphor-icons/react';

import { ETransactionsType } from '@domain/enums/common/statementTransactions/ETransactionsType';

import PIX from '@components/icons/Pix';
import * as S from './styles';

interface IBadgeProps {
  status?: string;
}

const statusMap = {
  BALANCE_MIGRATION: {
    text: 'Migração de Saldo',
    status: 'teste',
    icon: <PiggyBank weight="bold" size={16} />,
  },
};

const Badge: React.FC<IBadgeProps> = ({ status }) => {
  if (!status) {
    return <></>;
  }

  const getStatus = React.useCallback(() => {
    if (status === 'BALANCE_MIGRATION') {
      return {
        text: 'Migração de Saldo',
        status: 'BALANCE_MIGRATION',
        icon: <PiggyBank weight="bold" size={16} />,
      };
    }

    if (status === 'DISPUTE') {
      return {
        text: 'Em disputa',
        status: 'DISPUTE',
        icon: <LockSimple weight="bold" size={16} />,
      };
    }

    if (status === 'PAYMENT_DEPOSIT') {
      return {
        text: 'Depósito de Pagamento',
        status: 'PAYMENT_DEPOSIT',
        icon: <LockSimple weight="bold" size={16} />,
      };
    }

    if (status === 'RDR') {
      return {
        text: 'Taxa de prevenção',
        status: 'RDR',
        icon: <X weight="bold" size={16} />,
      };
    }

    if (status === 'REFUND') {
      return {
        text: 'Estorno',
        status: 'REFUND',
        icon: <CaretCircleLeft weight="bold" size={16} />,
      };
    }

    return {
      text: 'Outro',
      status: 'OTHER',
      icon: undefined,
    };
  }, [status]);

  const { text, status: mappedStatus, icon } = getStatus();

  return (
    <S.Badge status={mappedStatus}>
      {icon} {text}
    </S.Badge>
  );
};

export default Badge;
