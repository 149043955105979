import { EProvider } from '@domain/enums/common/provider/EProvider';

import { useShopifyService } from '@hooks/services/shopify/useShopifyService';

const { syncShopifyProducts } = useShopifyService();

export const STORE_PROVIDERS = [
  {
    id: EProvider.SHOPIFY,
    name: 'Shopify',
    syncProducts: syncShopifyProducts,
  },
];
