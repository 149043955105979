import React from 'react';
import Body from './Body';
import Header from './Header';
import * as S from './styles';

const NewOrderBump: React.FC = () => {
  return (
    <S.WrapperCouple>
      <S.Wrapper>
        <Header />
      </S.Wrapper>
      <S.WrapperCustom>
        <Body />
      </S.WrapperCustom>
    </S.WrapperCouple>
  );
};

export default NewOrderBump;
