import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { EWebhookEvent } from '@domain/enums/common/webhook/EWebhook';
import { INewWebhookProvider } from '@domain/interfaces/store/common/webhook/INewWebhookProvider';
import { ICreateWebhookData } from '@domain/interfaces/hooks/services/webhook/IWebhookService';

import { useErrorHandler } from '@store/context/common/ErrorHandlerContext';
import { useWebhookService } from '@hooks/services/webhook/useWebhookService';
import { useToast } from '@store/context/common/ToastContext';
import { useAnalytics } from '@store/context/common/AnalyticsContext';

const NewWebhookContext = React.createContext<INewWebhookProvider | null>(null);

export const NewWebhookProvider: React.FC = ({ children }) => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const { accountId, checkoutId } = useParams();
  const { handleError } = useErrorHandler();
  const { createWebhook } = useWebhookService();
  const { analytics } = useAnalytics();

  const [webhookName, setWebhookName] = React.useState<string>('');
  const [webhookUrl, setWebhookUrl] = React.useState<string>('');
  const [webhookEvents, setWebhookEvents] = React.useState<Array<EWebhookEvent>>([]);
  const [isCreatingWebhook, setIsCreatingWebhook] = React.useState<boolean>(false);

  const onWebhookNameChange = React.useCallback(value => {
    setWebhookName(value);
  }, []);

  const onWebhookUrlChange = React.useCallback(value => {
    setWebhookUrl(value);
  }, []);

  const onWebhookEventsChange = React.useCallback(events => {
    setWebhookEvents(events);
  }, []);

  const onSubmitClick = React.useCallback(async () => {
    const data: ICreateWebhookData = {
      name: webhookName,
      url: webhookUrl,
      event_types: webhookEvents,
    };

    setIsCreatingWebhook(true);

    try {
      await createWebhook({ accountId, payload: { ...data } });

      analytics.track('Webhook Created', { event_types: webhookEvents });

      toast.success('Webhook criado com sucesso!');

      setIsCreatingWebhook(false);

      navigate(`/${accountId}/${checkoutId}/dashboard/apps/webhooks`);
    } catch (error: any) {
      toast.error('Erro ao criar webhook!');
      setIsCreatingWebhook(false);
      handleError(error);
    }
  }, [
    webhookName,
    webhookUrl,
    webhookEvents,
    accountId,
    toast,
    createWebhook,
    navigate,
    handleError,
    analytics,
    checkoutId,
  ]);

  const onCancelClick = React.useCallback(() => {
    navigate(`/${accountId}/${checkoutId}/dashboard/apps/webhooks`);
  }, [navigate, accountId, checkoutId]);

  return (
    <NewWebhookContext.Provider
      value={{
        onWebhookEventsChange,
        onWebhookNameChange,
        onWebhookUrlChange,
        webhookEvents,
        webhookName,
        webhookUrl,
        isCreatingWebhook,
        onCancelClick,
        onSubmitClick,
      }}
    >
      {children}
    </NewWebhookContext.Provider>
  );
};

export const useNewWebhook = (): INewWebhookProvider => {
  const context = React.useContext(NewWebhookContext);

  if (!context) {
    throw new Error('useNewWebhook must be used within provider');
  }

  return context;
};
