import React from 'react';

import Content from '@components/pages/Dashboard/Coupon/GetCoupon';

import { GetPromocodeProvider } from '@store/common/promocode/GetPromocodeContext';

const Coupon: React.FC = () => {
  return (
    <GetPromocodeProvider>
      <Content />
    </GetPromocodeProvider>
  );
};

export default Coupon;
