import React from 'react';
import { useNewBankAccount } from '@store/common/BankAccount/NewBankAccountContext';

import { cnpjFormatter } from '@utils/common/cnpj';
import { EBankAccountType } from '@domain/interfaces/common/bankAccount/IBankAccount';

import { WarningCircle } from 'phosphor-react';
import * as S from './styles';

const ConfirmationModal: React.FC = () => {
  const {
    isCreatingBankAccount,
    isOpenModal,
    setIsOpenModal,
    onCreateBankAccount,
    bankAccountData,
    selectedBank,
  } = useNewBankAccount();

  const accountType =
    bankAccountData?.type === EBankAccountType.CHECKING ? 'Conta corrente' : 'Conta poupança';

  return (
    <S.Modal
      title="Confirme seus dados"
      toggle={() => setIsOpenModal(state => !state)}
      isOpen={isOpenModal}
    >
      <S.ModalWrapper>
        <S.ModalContent>
          <S.ModalContentTitle>Dados bancários</S.ModalContentTitle>
          <S.ModalContentLabelAndValueWrapper>
            <S.ModalContentLabelAndValueGroup>
              <S.ModalContentLabel>Banco</S.ModalContentLabel>
              <S.ModalContentValue>
                {selectedBank?.code} - {selectedBank?.name}
              </S.ModalContentValue>
            </S.ModalContentLabelAndValueGroup>
            <S.ModalContentLabelAndValueGroup>
              <S.ModalContentLabel>Titular</S.ModalContentLabel>
              <S.ModalContentValue>{bankAccountData?.holder_name}</S.ModalContentValue>
            </S.ModalContentLabelAndValueGroup>
            <S.ModalContentLabelAndValueGroup>
              <S.ModalContentLabel>Documento</S.ModalContentLabel>
              <S.ModalContentValue>
                {cnpjFormatter(bankAccountData?.holder_document)}
              </S.ModalContentValue>
            </S.ModalContentLabelAndValueGroup>
            <S.ModalContentLabelAndValueGroup>
              <S.ModalContentLabel>Agência</S.ModalContentLabel>
              <S.ModalContentValue>{bankAccountData?.routing_number}</S.ModalContentValue>
            </S.ModalContentLabelAndValueGroup>
            <S.ModalContentLabelAndValueGroup>
              <S.ModalContentLabel>{accountType}</S.ModalContentLabel>
              <S.ModalContentValue>
                {bankAccountData?.account_number}-{bankAccountData?.account_digit}
              </S.ModalContentValue>
            </S.ModalContentLabelAndValueGroup>
          </S.ModalContentLabelAndValueWrapper>
        </S.ModalContent>
        <S.ModalInfo>
          <WarningCircle weight="bold" color="#936802" size={20} />
          <S.ModalInfoText>
            Caso algum dado bancário esteja incorreto, o dinheiro solicitado para saque será
            devolvido à sua conta na Zouti.
          </S.ModalInfoText>
        </S.ModalInfo>
      </S.ModalWrapper>
      <S.ModalAction>
        <S.CancelButton type="button" onClick={() => setIsOpenModal(state => !state)}>
          Revisar dados
        </S.CancelButton>
        <S.SaveButton
          isLoading={isCreatingBankAccount}
          onClick={() => onCreateBankAccount(bankAccountData)}
        >
          Cadastrar conta
        </S.SaveButton>
      </S.ModalAction>
    </S.Modal>
  );
};

export default ConfirmationModal;
