import styled from 'styled-components/macro';

import ButtonBase from '@components/common/Inputs/Button';
import CheckboxBase from '@components/common/Inputs/CheckboxNew';
import DatePickerBase from '@components/common/utils/DatePicker';
import InputGroupBase from '@components/common/Inputs/InputGroup';
import LabelBase from '@components/common/DataDisplay/Label';
import RadioBase from '@components/common/Inputs/Radio';

import TextAreaBase from '@components/common/Inputs/TextArea';
import TextFieldBase from '@components/common/Inputs/TextField';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-color: ${({ theme }) => theme.colors.neutral.white};
  padding: 24px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;

export const Label = styled(LabelBase)``;

export const LabelHelper = styled.span`
  color: ${({ theme }) => theme.colors.neutral.base};
  font-size: 14px;
`;

export const InputGroup = styled(InputGroupBase)``;

export const Input = styled(TextFieldBase)`
  width: 100%;
`;

export const TextArea = styled(TextAreaBase)``;

export const CheckboxGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const CheckboxLabel = styled(LabelBase)`
  margin: 0;
  cursor: pointer;
`;

export const Checkbox = styled(CheckboxBase)``;

export const Radio = styled(RadioBase)``;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: space-between;
`;

export const SubmitButton = styled(ButtonBase)``;

export const CancelButton = styled(ButtonBase)`
  & > span {
    color: ${({ theme }) => theme.colors.neutral.darkest};
  }
`;

export const WrapperMinPriceAndMaxQuantity = styled.div`
  display: grid;
  grid-template-columns: 170px auto;
  gap: 16px;
`;

export const InputGroupWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const CurrencyLabel = styled.div`
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  font-family: ${({ theme }) => theme.fonts.text};
  font-weight: 700;
  color: ${({ theme }) => theme.colors.neutral.darkest};
`;

export const ValueWrapper = styled.div`
  display: flex;
  align-content: center;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  border-radius: ${({ theme }) => theme.borderRadius.small};

  & > div:nth-child(2) {
    border: unset;
    width: 100%;
    border-radius: unset;
    border-left: 1px solid ${({ theme }) => theme.colors.neutral.lightest};

    & > input {
      text-align: left;
    }
  }
`;

export const InputGroupButton = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 8px;
`;

export const GenerateCouponButton = styled(ButtonBase)``;

export const DatePicker = styled(DatePickerBase)`
  width: 100%;
`;

export const DeleteCouponButton = styled(ButtonBase)`
  background-color: transparent;
  border: none;

  > span {
    color: ${({ theme }) => theme.colors.negative.base};
    font-size: 1.6rem;
    font-weight: 500;
  }
`;

export const WrapperCancelAndSubmitButton = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
