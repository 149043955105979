import React from 'react';

import * as S from './styles.plataform';

export interface IPlatformSelectProps {
  isDropdownOpen: boolean;
}

export interface IPlatform {
  id: string;
  name: string;
  image: string;
}

export interface IPlatformOptionProps {
  platform: IPlatform;
  handleSelectedPlatform: any;
}

const PlatformOption: React.FC<IPlatformOptionProps> = ({ platform, handleSelectedPlatform }) => {
  const { image, name, id } = platform;

  return (
    <S.PlatformOption
      onClick={() => {
        handleSelectedPlatform(id);
      }}
      className="button-selected"
    >
      <S.PlatformIcon src={image} />
      <S.PlatformName>{name}</S.PlatformName>
    </S.PlatformOption>
  );
};

export default PlatformOption;
