import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { EButtonVariant } from '@domain/enums/components/common/EButton';
import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/common/EHeading';

import { useUserAccount } from '@store/context/common/UserAccount';
import { useNewBankAccount } from '@store/common/BankAccount/NewBankAccountContext';

import { cnpjFormatter } from '@utils/common/cnpj';

import BankSelect from '../BankSelect';
import ConfirmationModal from '../ConfirmationModal';

import * as S from './styles';

const Body: React.FC = () => {
  const navigate = useNavigate();
  const { accountId, checkoutId } = useParams();
  const { currentUserAccount } = useUserAccount();
  const {
    handleSubmit,
    onSubmit,
    errors,
    isValid,
    setValue,
    routingNumberRegister,
    holderNameRegister,
    accountNumberRegister,
    holderDocumentRegister,
    accountDigitRegister,
    typeRegister,
    setIsOpenModal,
  } = useNewBankAccount();

  const onCancelButtonClick = (): void => {
    navigate(`/${accountId}/${checkoutId}/dashboard/bank-accounts`);
  };

  const onOpenModal = (): void => {
    if (isValid) {
      setIsOpenModal(state => !state);
    }
  };

  React.useEffect(() => {
    setValue('holderDocument', cnpjFormatter(currentUserAccount?.cnpj));
  }, [currentUserAccount?.cnpj, setValue]);

  return (
    <S.Wrapper>
      <ConfirmationModal />
      <S.Form onSubmit={handleSubmit(onSubmit)}>
        <S.Card>
          <S.Title size={EHeadingSize.H4} fontWeight={EHeadingWeight.BOLD}>
            Dados bancários
          </S.Title>
          <div>
            <BankSelect />
            {errors?.bankCode && (
              <S.MessageError isFeedbackError>{errors?.bankCode.message}</S.MessageError>
            )}
          </div>
          <S.InputGroup>
            <S.Label>Agência (sem o dígito)</S.Label>
            <S.Input {...routingNumberRegister} type="text" />
            {errors?.routingNumber && (
              <S.MessageError isFeedbackError>{errors?.routingNumber.message}</S.MessageError>
            )}
          </S.InputGroup>
          <S.AccountWrapper>
            <S.InputGroup>
              <S.Label>Conta</S.Label>
              <S.Input {...accountNumberRegister} type="text" />
              {errors?.accountNumber && (
                <S.MessageError isFeedbackError>{errors?.accountNumber.message}</S.MessageError>
              )}
            </S.InputGroup>
            <S.InputGroup>
              <S.Label>Dígito</S.Label>
              <S.Input {...accountDigitRegister} type="text" />
              {errors?.accountDigit && (
                <S.MessageError isFeedbackError>{errors?.accountDigit.message}</S.MessageError>
              )}
            </S.InputGroup>
          </S.AccountWrapper>
          <S.InputGroup>
            <S.Label>Documento (CPF ou CNPJ)</S.Label>
            <S.Input
              disabled
              {...holderDocumentRegister}
              value={cnpjFormatter(currentUserAccount?.cnpj)}
              type="text"
            />
            {errors?.holderDocument && (
              <S.MessageError isFeedbackError>{errors?.holderDocument.message}</S.MessageError>
            )}
          </S.InputGroup>
          <S.InputGroup>
            <S.Label>Nome</S.Label>
            <S.Input {...holderNameRegister} type="text" />
            {errors?.holderName && (
              <S.MessageError isFeedbackError>{errors?.holderName.message}</S.MessageError>
            )}
          </S.InputGroup>

          <S.InputGroup>
            <S.Label>Tipo de desconto</S.Label>
            <S.InputGroupWrapper>
              <S.Radio
                {...typeRegister}
                label="Corrente"
                labelFor="typeChecking"
                value="CHECKING"
              />

              <S.Radio {...typeRegister} label="Poupança" labelFor="typeSavings" value="SAVINGS" />
            </S.InputGroupWrapper>
            {errors?.type && (
              <S.MessageError isFeedbackError>{errors?.type?.message}</S.MessageError>
            )}
          </S.InputGroup>
        </S.Card>
        <S.ButtonsWrapper>
          <S.CancelButton
            onClick={onCancelButtonClick}
            variant={EButtonVariant.SECONDARY}
            type="button"
          >
            Cancelar
          </S.CancelButton>
          <S.SubmitButton type="submit" onClick={onOpenModal}>
            Prosseguir
          </S.SubmitButton>
        </S.ButtonsWrapper>
      </S.Form>
    </S.Wrapper>
  );
};

export default Body;
