import styled from 'styled-components/macro';

import ButtonBase from '@components/common/Inputs/Button';
import HeadingBase from '@components/common/DataDisplay/Heading';
import InputColorBase from '@components/common/Inputs/InputColorCheckout';
import InputGroupBase from '@components/common/Inputs/InputGroup';
import TextBase from '@components/common/DataDisplay/Text';
import TextFieldBase from '@components/common/Inputs/TextField';

export const Form = styled.form``;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.neutral.white};
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  border-radius: 4px;
  padding: 24px;
`;

export const Divider = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.neutral.lightest};
  margin: 16px 0;
`;

export const Title = styled(HeadingBase)`
  color: ${({ theme }) => theme.colors.neutral.darkest};
`;

export const ColorSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const InputColorWrapper = styled.div`
  display: flex;
  gap: 16px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
export const InputColor = styled(InputColorBase)``;

export const InputGroup = styled(InputGroupBase)``;

export const LogoSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const TextInfoAndTooltipWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ContainerDropzone = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 24px;
  border: 2px dashed ${({ theme }) => theme.colors.primary.dark_green_40};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.primary.lemon_20};
  transition: border 0.24s ease-in-out;
  cursor: pointer;

  svg {
    color: ${({ theme }) => theme.colors.primary.dark_green_40};
  }
`;

export const TextDropzone = styled(TextBase)`
  font-weight: 500;
`;
export const AcceptFormat = styled(TextBase)`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.neutral.base};
`;

export const PreviewDropzone = styled.div`
  display: flex;
  align-self: center;
  justify-content: center;
  aspect-ratio: 10 / 3;
  width: 300px;
  height: 90px;
`;

export const PreviewCardInfo = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(2, 1fr);
  border-radius: 4px;
  padding: 5px 24px;
  background-color: ${({ theme }) => theme.colors.neutral.gray_white};

  & > span:nth-child(1) {
    grid-column: span 5;
    grid-row: 1;
  }

  & > span:nth-child(2) {
    grid-column: span 5;
    grid-row: 2;
  }

  & > svg {
    cursor: pointer;
    align-self: center;
    justify-self: end;
    padding: 5px;
    color: ${({ theme }) => theme.colors.primary.dark_green_40};
    grid-column: 6;
    grid-row: span 2;

    &:active {
      filter: brightness(0.9);
    }

    &:hover {
      background-color: ${({ theme }) => theme.colors.primary.lemon_20};
    }
  }
`;
export const FileName = styled(TextBase)`
  color: ${({ theme }) => theme.colors.neutral.darker};
  font-weight: 500;
`;

export const FileSize = styled(TextBase)`
  color: ${({ theme }) => theme.colors.neutral.base};
  font-weight: 500;
  font-size: 12px;
`;

export const Image = styled.img`
  width: 100%;
`;

export const TextInfo = styled(TextBase)``;

export const Input = styled(TextFieldBase)``;

export const ConfigInputColorWrapper = styled.div`
  display: flex;
  gap: 16px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
  gap: 16px;
  justify-content: flex-end;
`;

export const SubmitButton = styled(ButtonBase)``;

export const CancelButton = styled(ButtonBase)`
  & > span {
    color: ${({ theme }) => theme.colors.neutral.dark} !important;
  }
`;
