import styled from 'styled-components/macro';

import ModalBase from '@components/common/DataDisplay/Modal';
import TextBase from '@components/common/DataDisplay/Text';

export const Modal = styled(ModalBase)`
  background-color: ${({ theme }) => theme.colors.neutral.white};
  max-width: 520px;

  & > div {
    padding: 24px;
  }

  & > div:nth-child(3) {
    padding-top: 0;
  }
`;

export const Text = styled(TextBase)`
  font-size: 1.6rem;
`;
