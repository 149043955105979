import React from 'react';
import { useTheme } from 'styled-components';

import WebhookLogo from '@assets/images/common/webhook/webhook_logo.svg';
import WebhookIcon from '@assets/images/common/webhook/webhook_icon.svg';

import * as S from './styles';

const Webhook: React.FC = () => {
  const theme = useTheme();
  return (
    <S.AdditionalInformation>
      <S.AdditionalInformationHeader backgroundColor={theme.colors.tertiary.darkest}>
        <S.ShopifyFullImg src={WebhookLogo} alt="Zouti Webhook" />

        <S.IconWrapper>
          <S.Icon src={WebhookIcon} alt="Zouti Webhook" />
        </S.IconWrapper>
      </S.AdditionalInformationHeader>

      <S.AdditionalInformationBody>
        <S.TitleAndSwitchWrapper>
          <S.AditionalInformationTitle>Zouti Pay</S.AditionalInformationTitle>
        </S.TitleAndSwitchWrapper>

        <S.Description>
          Receba atualizações em tempo real de eventos importantes, conectando seu checkout via
          Webhooks.
        </S.Description>
      </S.AdditionalInformationBody>
    </S.AdditionalInformation>
  );
};

export default Webhook;
