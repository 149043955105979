import React from 'react';

import { EStoreOnboardingStep } from '@domain/enums/common/onboarding/EStore';

import { useStoreOnboarding } from '@store/context/onboarding/store/StoreOnboardingContext';

import StoreSegment from './StoreSegment';
import StoreName from './StoreName';
import Finished from './Finished';

import * as S from './styles';

const FormContent: React.FC = () => {
  const { onboardingStep } = useStoreOnboarding();

  return (
    <S.Wrapper>
      {onboardingStep === EStoreOnboardingStep.FIRST_STEP && <StoreSegment />}

      {onboardingStep === EStoreOnboardingStep.SECOND_STEP && <StoreName />}

      {onboardingStep === EStoreOnboardingStep.THIRD_STEP && <Finished />}
    </S.Wrapper>
  );
};

export default FormContent;
