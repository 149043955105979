import React from 'react';

import EmptyDataImage from '@assets/images/common/revenue-cuate.png';

import * as S from './styles';

interface IEmptyTableProps {
  colSpan: number;
  text: string;
}

const EmptyTable: React.FC<IEmptyTableProps> = ({ colSpan, text }) => {
  return (
    <S.Table.Row>
      <S.Table.Data colSpan={colSpan}>
        <S.EmptyContent>
          <S.EmptyImage src={EmptyDataImage} alt="Empty Table" />
          <S.EmptyText>{text}</S.EmptyText>
        </S.EmptyContent>
      </S.Table.Data>
    </S.Table.Row>
  );
};

export default EmptyTable;
