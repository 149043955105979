import React from 'react';

import { IBalanceProps } from '@domain/interfaces/hooks/services/statementTransaction/IStatementTransactionService';
import { IHopyBalance } from '@domain/interfaces/common/hopyTransactionsStatements/IHopyTransactionsStatements';

import { formatCurrencyParts } from '@utils/common/formatCurrency';

import { useGetStatementTransactions } from '@store/common/statementTransactions/GetStatementTransactionsContext';

import * as S from './styles';

interface ICardProps {
  id: string;
  reference: string;
  title: string;
  color: string;
  icon: JSX.Element;
  balances?: IBalanceProps | IHopyBalance;
}

const Card: React.FC<ICardProps> = ({ balances, id, reference, title, color, icon }) => {
  const { selectedBalance, handleSelectedBalance } = useGetStatementTransactions();

  const [isHovering, setIsHovering] = React.useState<boolean>(false);

  const onMouseEnter = React.useCallback(() => setIsHovering(true), []);

  const onMouseLeave = React.useCallback(() => setIsHovering(false), []);

  const onClick = React.useCallback(
    () => handleSelectedBalance(reference),
    [reference, handleSelectedBalance],
  );

  const value = balances ? balances[id as keyof typeof balances] : 0;

  const { symbol, amount } = formatCurrencyParts(value / 100);
  const isNegative = amount?.includes('-');
  const formattedSymbol = isNegative ? `-${symbol}` : symbol;
  const formattedAmount = amount?.replace('-', '');

  return (
    <S.Card
      isHovering={isHovering}
      isSelected={selectedBalance === reference}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
    >
      <S.CardHeader>
        <S.IconContainer color={color}>{icon}</S.IconContainer>
        <S.CardTitle>{title}</S.CardTitle>
      </S.CardHeader>
      <S.CardContent>
        <S.CurrencySymbol isnegative={isNegative}>{formattedSymbol}</S.CurrencySymbol>
        <S.BalanceValue isnegative={isNegative}>{formattedAmount}</S.BalanceValue>
      </S.CardContent>
    </S.Card>
  );
};

export default Card;
