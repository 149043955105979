import styled from 'styled-components';

import TypographyBase from '@components/common/DataDisplay/Typography';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const Title = styled(TypographyBase)`
  color: ${({ theme }) => theme.colors.neutral.dark};
`;

export const CardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
`;
