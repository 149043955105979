export enum EHeadingSize {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6',
}

export enum EHeadingWeight {
  REGULAR = '400',
  SEMI_BOLD = '600',
  BOLD = '700',
}
