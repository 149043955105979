import styled from 'styled-components/macro';
import { ETransactionsType } from '@domain/enums/common/statementTransactions/ETransactionsType';

interface IBadgeProps {
  status: ETransactionsType;
}

export const Badge = styled.span<IBadgeProps>`
  display: inline-flex;
  width: fit-content;
  gap: 7px;
  padding: 4px 8px;
  height: 23px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;

  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  font-family: ${({ theme }) => theme.fonts.text};
  text-transform: uppercase;

  ${({ theme, status }) => {
    if (status === ETransactionsType.Dispute || status === ETransactionsType.PayoutFailure) {
      return `
        color: ${theme.colors.negative.dark};
        background: ${theme.colors.negative.lightest};
      `;
    }
    if (
      status === ETransactionsType.AnticipationDeposit ||
      status === ETransactionsType.AnticipationWithdrawal
    ) {
      return `
        color: ${theme.colors.warning.darker};
        background: ${theme.colors.warning.lighter};
      `;
    }
    if (
      [
        ETransactionsType.Refund,
        ETransactionsType.BalanceReserveWithdrawalRefund,
        ETransactionsType.BalanceReserveDepositRefund,
        ETransactionsType.BalanceReserveWithdrawalDispute,
        ETransactionsType.BalanceReserveDepositDispute,
      ].includes(status)
    ) {
      return `
        color: ${theme.colors.info.dark};
        background: ${theme.colors.info.lightest};
      `;
    }
    if (status === ETransactionsType.Payment) {
      return `
        color: ${theme.colors.positive.darker};
        background: ${theme.colors.positive.lightest};
      `;
    }
    if (status === ETransactionsType.BalanceReserveWithdrawal) {
      return `
        color: ${theme.colors.neutral.darker};
        background: ${theme.colors.neutral.lightest};
      `;
    }
    if (status === ETransactionsType.BalanceReserveDeposit) {
      return `
        color: ${theme.colors.positive.darker};
        background: ${theme.colors.positive.lighter};
      `;
    }

    if (status === ETransactionsType.PayoutCancel || status === ETransactionsType.Payout) {
      return `
        color: ${theme.colors.neutral.darker};
        background: ${theme.colors.neutral.lighter};
      `;
    }

    return '';
  }}
`;
