import React from 'react';
import { FieldValues, useForm } from 'react-hook-form';

import { useEditWebhook } from '@store/common/webhook/EditWebhookContext';

import SaveHeaderBar from '@components/common/utils/SaveHeaderBar';
import WebhookEventsSelect from './WebhookEventsSelect';

import * as S from './styles';

const CardCredentials: React.FC = () => {
  const { register, handleSubmit, setValue } = useForm();
  const {
    webhookUrl,
    webhookName,
    webhookEvents,
    isUpdatingWebhook,
    onWebhookEventsChange,
    onWebhookNameChange,
    onWebhookUrlChange,
    onCancelClick,
    onSubmitClick,
  } = useEditWebhook();

  const nameRegister = register('name');
  const urlRegister = register('url');

  const onNameChange = React.useCallback(
    event => {
      onWebhookNameChange(event.target.value);
    },
    [onWebhookNameChange],
  );

  const onUrlChange = React.useCallback(
    event => {
      onWebhookUrlChange(event.target.value);
    },
    [onWebhookUrlChange],
  );

  const onSubmit = async (formData: FieldValues): Promise<void> => {
    console.log({ formData });
  };

  React.useEffect(() => {
    if (webhookUrl) {
      setValue('url', webhookUrl);
    }

    if (webhookName) {
      setValue('name', webhookName);
    }
  }, [webhookUrl, webhookName, setValue]);

  const showSaveHeaderBar = webhookName && webhookUrl && webhookEvents?.length;

  return (
    <S.Form onSubmit={handleSubmit(onSubmit)}>
      <S.Credentials>
        {Boolean(showSaveHeaderBar) && (
          <SaveHeaderBar
            onCancelClick={onCancelClick}
            onSubmitClick={onSubmitClick}
            isLoading={isUpdatingWebhook}
          />
        )}
        <S.CredentialsTitle>Credenciais</S.CredentialsTitle>

        <S.InputsWrapper>
          <S.InputGroup>
            <S.Label>Nome do Webhook</S.Label>
            <S.Input
              {...nameRegister}
              defaultValue={webhookName}
              onChange={onNameChange}
              type="text"
              placeholder="Insira o nome do Webhook"
            />
          </S.InputGroup>

          <S.InputGroup>
            <S.Label>URL</S.Label>
            <S.Input
              {...urlRegister}
              defaultValue={webhookUrl}
              onChange={onUrlChange}
              type="text"
              placeholder="Insira a URL"
            />
          </S.InputGroup>

          <S.InputGroup>
            <S.Label>Selecionar Eventos</S.Label>
            <WebhookEventsSelect onChange={onWebhookEventsChange} />
          </S.InputGroup>
        </S.InputsWrapper>
      </S.Credentials>
    </S.Form>
  );
};

export default CardCredentials;
