import { AxiosResponse } from 'axios';

import {
  ICheckoutService,
  ICreateCheckoutProps,
  ICreateCheckoutResponse,
  IListCheckoutResponse,
  IListCheckoutProps,
} from '@domain/interfaces/hooks/services/checkout/ICheckoutService';

import { dashboardInstance } from '@services/common/dashboardInstance';

export const useCheckoutService = (): ICheckoutService => {
  const listCheckouts = async ({
    account_id,
  }: IListCheckoutProps): Promise<AxiosResponse<IListCheckoutResponse>> => {
    return dashboardInstance.get(`/accounts/${account_id}/checkout`, {
      headers: {
        'x-zouti-account': account_id,
      },
    });
  };

  const createCheckout = async ({
    accountId,
    payload,
  }: ICreateCheckoutProps): Promise<AxiosResponse<ICreateCheckoutResponse>> => {
    return dashboardInstance.post(
      `/accounts/${accountId}/checkout`,
      { ...payload },
      { headers: { 'x-zouti-account': accountId } },
    );
  };

  const setToken = (token: string): void => {
    dashboardInstance.defaults.headers.common.access_key = token;
  };

  return { listCheckouts, createCheckout, setToken };
};
