import styled from 'styled-components/macro';

import CheckboxBase from '@components/common/Inputs/Checkbox';
import InputGroupBase from '@components/common/Inputs/InputGroup';
import LabelBase from '@components/common/DataDisplay/Label';
import TextFieldBase from '@components/common/Inputs/TextField';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-color: ${({ theme }) => theme.colors.neutral.white};
`;

export const Label = styled(LabelBase)``;

export const InputGroup = styled(InputGroupBase)``;

export const Input = styled(TextFieldBase)``;

export const Checkbox = styled(CheckboxBase)``;

export const CheckboxGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const CheckboxLabel = styled(LabelBase)`
  margin: 0;
`;
