import styled from 'styled-components/macro';
import Skeleton from 'react-loading-skeleton';

export const Wrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  margin-top: 24px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  padding-bottom: 24px;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
`;

export const TitleAndButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  width: 100%;
`;

export const CustomerCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.neutral.white};
`;

export const LabelSkeleton = styled(Skeleton)`
  width: 100px;
  height: 16px;
`;

export const TextValueSkeleton = styled(Skeleton)`
  width: 200px;
  height: 16px;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const InfoGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ContentCard = styled.div`
  display: flex;
  justify-content: space-between;
`;
