import styled from 'styled-components/macro';

import LabelBase from '@components/common/DataDisplay/Label';
import TextFieldBase from '@components/common/Inputs/TextField';
import InputGroupBase from '@components/common/Inputs/InputGroup';

export const Label = styled(LabelBase)`
  margin: 0;
  cursor: pointer;
`;

export const InputGroup = styled(InputGroupBase)`
  gap: 8px;
  position: relative;
`;
export const TextField = styled(TextFieldBase)`
  flex-grow: 1;
`;

export const PickerWrapper = styled.div`
  width: 40px;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: 1px solid #a1a1a1;
  border-radius: 4px;
`;

export const ColorPicker = styled.div<{ color: string }>`
  width: 32px;
  height: 32px;
  border-radius: 4px;
  background-color: ${({ color }) => color};
  cursor: pointer;
  border: 1px solid #a1a1a1;
`;

export const ColorPickerWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
`;

export const DetectClickOutside = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2000;
`;

export const ChromePickerWrapper = styled.div`
  position: absolute;
  top: 80px;
  left: 0px;
  z-index: 3000;
`;
