import React from 'react';
import { format } from 'date-fns';
import { CalendarBlank } from 'phosphor-react';
import { useTheme } from 'styled-components/macro';

import { useDatePicker } from '@store/context/components/DatePickerContext';

import Calendar from '../Calendar';
import PreDefinedDates from '../Calendar/PreDefinedDates';

import * as S from './styles';

interface IInputProps {
  showCalendarIcon?: boolean;
  isShowPreDefinedDate?: boolean;
  isDisabledDaysAfterToday?: boolean;
}

const Input: React.FC<IInputProps> = ({
  showCalendarIcon,
  isShowPreDefinedDate = true,
  isDisabledDaysAfterToday = false,
  ...rest
}) => {
  const theme = useTheme();
  const { selectedStartDate, selectedEndDate, isCalendarOpen, handleCalendarOpen } =
    useDatePicker();

  const formattedStartDate = format(selectedStartDate, 'dd/MM/yyyy');
  const formattedEndDate = format(selectedEndDate, 'dd/MM/yyyy');

  return (
    <S.Dropdown open={isCalendarOpen} onOpenChange={handleCalendarOpen}>
      <S.DropdownTrigger {...rest}>
        <S.DateText>{`${formattedStartDate} - ${formattedEndDate}`}</S.DateText>

        {showCalendarIcon && (
          <CalendarBlank size={16} color={theme.colors.neutral.dark} weight="bold" />
        )}
      </S.DropdownTrigger>

      <S.DropdownContent>
        {isShowPreDefinedDate && <PreDefinedDates />}

        <Calendar
          isDisabledDaysAfterToday={isDisabledDaysAfterToday}
          isShowPreDefinedDate={isShowPreDefinedDate}
        />
      </S.DropdownContent>
    </S.Dropdown>
  );
};

export default Input;
