import React from 'react';

import { useGetSocialProof } from '@store/common/socialProof/GetSocialProofContext';
import CardItem from './CardItem';

import * as S from './styles';

const Body: React.FC = () => {
  const { socialProofs } = useGetSocialProof();

  return (
    <S.Wrapper>
      {socialProofs?.map(item => (
        <CardItem items={item} key={item.id} />
      ))}
    </S.Wrapper>
  );
};

export default Body;
