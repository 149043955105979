import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { IRecoveryPasswordContext } from '@domain/interfaces/store/context/public/recoveryPassword/IRecoveryPassword';

import { recoveryPasswordSchema } from '@helpers/validators/pages/public/recoveryPassword';

import { useErrorHandler } from '@store/context/common/ErrorHandlerContext';
import { useAuthService } from '@hooks/services/auth/useAuthService';

const RecoveryPasswordContext = React.createContext<IRecoveryPasswordContext | null>(null);

export const RecoveryPasswordProvider: React.FC = ({ children }) => {
  const { handleError } = useErrorHandler();
  const { forgotPassword } = useAuthService();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ reValidateMode: 'onChange', resolver: yupResolver(recoveryPasswordSchema) });

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [showSuccessMessage, setShowSuccessMessage] = React.useState<boolean>(false);

  const handleShowSuccessMessage = React.useCallback(state => {
    setShowSuccessMessage(state);
  }, []);

  const onSubmit = React.useCallback(
    async data => {
      setIsLoading(true);

      try {
        const payload = {
          ...data,
        };

        await forgotPassword({ payload });

        setIsLoading(false);
        setShowSuccessMessage(true);
      } catch (error: any) {
        setIsLoading(false);
        handleError(error);
      }
    },
    [handleError, forgotPassword],
  );

  return (
    <RecoveryPasswordContext.Provider
      value={{
        errors,
        handleSubmit,
        register,
        onSubmit,
        isLoading,
        showSuccessMessage,
        handleShowSuccessMessage,
      }}
    >
      {children}
    </RecoveryPasswordContext.Provider>
  );
};

export const useRecoveryPassword = (): IRecoveryPasswordContext => {
  const context = React.useContext(RecoveryPasswordContext);

  if (!context) {
    throw new Error('useRecoveryPassword must be used within RecoveryPasswordProvider');
  }

  return context;
};
