import React from 'react';

import { useNewPayout } from '@store/common/Payout/NewPayoutContext';

import * as S from './styles';
import Form from './Form';
import TransferModalDetail from './Detail';

const TransferModal: React.FC = () => {
  const { isOpenModal, setIsOpenModal, setNextStep, reset, nextStep, setBankAccountSelected } =
    useNewPayout();

  const handleToggle = (): void => {
    reset();
    setNextStep(0);
    setBankAccountSelected({ bank_code: '', account_number: '', routing_number: '', id: '' });
    setIsOpenModal(state => !state);
  };

  return (
    <S.Modal title="Nova transferência" toggle={handleToggle} isOpen={isOpenModal}>
      {nextStep === 0 && <Form />}
      {nextStep === 1 && <TransferModalDetail />}
    </S.Modal>
  );
};

export default TransferModal;
