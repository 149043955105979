import { AxiosResponse } from 'axios';

import {
  ICreateGroupProps,
  IDeleteGroupProps,
  IDeleteGroupResponse,
  IGroupsService,
  IUpdateGroupProps,
} from '@domain/interfaces/hooks/services/groups/IGroupsService';

import { dashboardInstance } from '@services/common/dashboardInstance';

export const useGroupsService = (): IGroupsService => {
  const createGroup = async ({
    accountId,
    payload,
  }: ICreateGroupProps): Promise<AxiosResponse<any>> => {
    return dashboardInstance.post(`accounts/${accountId}/membership_groups`, {
      ...payload,
    });
  };
  const updateGroup = async ({
    payload,
    accountId,
    membershipGroupsId,
  }: IUpdateGroupProps): Promise<AxiosResponse<any>> => {
    return dashboardInstance.put(`accounts/${accountId}/membership_groups/${membershipGroupsId}`, {
      ...payload,
    });
  };

  const deleteGroup = async ({
    accountId,
    membershipGroupId,
  }: IDeleteGroupProps): Promise<AxiosResponse<IDeleteGroupResponse>> => {
    return dashboardInstance.delete(`accounts/${accountId}/membership_groups/${membershipGroupId}`);
  };

  return {
    createGroup,
    updateGroup,
    deleteGroup,
  };
};
