import styled from 'styled-components/macro';

import TextBase from '@components/common/DataDisplay/Text';
import ButtonBase from '@components/common/Inputs/Button';
import LinkBase from '@components/common/Navigation/Link';
import CustomTooltipBase from '@components/common/utils/CustomTooltip';
import ChipBase from '@components/common/DataDisplay/Chip';
import DropdownBase from '@components/common/utils/Dropdown';

export const Wrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  padding-bottom: 28px;
  width: 100%;
  margin: 0 auto;
`;

export const TitleAndChip = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const TitleAndButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  width: 100%;
`;

export const SendMessageButton = styled(ButtonBase)`
  & > span {
    color: ${({ theme }) => theme.colors.neutral.darker} !important;
    font-weight: 500;
    font-size: 1.4rem;
  }
`;

export const DotsThreeButton = styled(ButtonBase)``;

export const WrapperButtonHeader = styled.div`
  display: flex;
  gap: 16px;
`;

export const Dropdown = styled(DropdownBase)``;

export const DropdownTrigger = styled(DropdownBase.Trigger)`
  padding: 0 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
`;

export const DropdownContent = styled(DropdownBase.Content)`
  display: flex;
  min-width: unset;
  width: 240px;
  flex-direction: column;
  margin-right: 44px;
  padding: 12px 16px;
  gap: 8px;
  color: ${({ theme }) => theme.colors.neutral.darker};
`;

export const DropdownItem = styled(DropdownBase.Item)`
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  font-family: ${({ theme }) => theme.fonts.text};
  font-weight: 400;
  padding: 8px;
  border-radius: 4px;
  z-index: 99999;
  cursor: pointer;
`;

export const DateText = styled(TextBase)`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.neutral.dark};
`;

export const OrderIdText = styled(LinkBase)`
  font-size: 1.6rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.neutral.darkest};
`;

export const OrderIdAndDateWrapper = styled.div`
  display: flex;
  align-items: self-end;
  gap: 4px;
`;

export const Tooltip = styled(CustomTooltipBase)``;
export const TooltipTrigger = styled(CustomTooltipBase.Trigger)``;

export const TooltipContent = styled(CustomTooltipBase.Content)``;

export const TooltipText = styled(TextBase)`
  color: ${({ theme }) => theme.colors.neutral.gray_white};
  font-size: 1.2rem;
`;

export const Chip = styled(ChipBase)`
  h1 {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;
