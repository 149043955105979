import React from 'react';

import { GetSocialProofProvider } from '@store/common/socialProof/GetSocialProofContext';

import Content from '@components/pages/Dashboard/SocialProof/GetSocialProof';

const GetSocialProof: React.FC = () => {
  return (
    <GetSocialProofProvider>
      <Content />
    </GetSocialProofProvider>
  );
};

export default GetSocialProof;
