import React from 'react';
import { useTheme } from 'styled-components/macro';
import { ClassicSpinner } from 'react-spinners-kit';

import { EButtonVariant, EButtonSize } from '@domain/enums/components/common/EButton';
import { IButtonProps } from './styles';

import * as S from './styles';

const Button: React.FC<IButtonProps> = ({
  children,
  disabled = false,
  isLoading = false,
  variant = EButtonVariant.PRIMARY,
  size = EButtonSize.MEDIUM,
  ...rest
}) => {
  const theme = useTheme();

  return (
    <S.ButtonBase disabled={isLoading || disabled} variant={variant} size={size} {...rest}>
      <S.Text isLoading={isLoading}>{children}</S.Text>

      {isLoading && (
        <S.LoadingWrapper>
          <ClassicSpinner size={15} color={theme.colors.neutral.darkest} loading />
        </S.LoadingWrapper>
      )}
    </S.ButtonBase>
  );
};

export default Button;
