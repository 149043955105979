import styled, { css } from 'styled-components/macro';

interface ICheckbox {
  checked?: boolean;
  disabled?: boolean;
  isBigVariant?: boolean;
}

export const Container = styled.div`
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
`;

export const Icon = styled.svg`
  fill: none;
  stroke: ${({ theme }) => theme.colors.neutral.gray_white};
  stroke-width: 3px;
  position: absolute;
  width: 15px;
  top: 0;
  left: 0;
`;

export const CheckboxInput = styled.input.attrs({
  type: 'checkbox',
})`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const CheckboxDiv = styled.div<ICheckbox>`
  width: 16px;
  height: 16px;
  background: ${({ checked, theme }) =>
    checked ? theme.colors.primary.green_lemon : 'transparent'};
  border-radius: 2px;
  border: 1px solid
    ${({ checked, theme }) =>
      checked ? theme.colors.primary.green_lemon : theme.colors.neutral.base};
  transition: all 180ms;
  box-sizing: border-box;
  position: relative;

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.neutral.darkest};
  }

  ${({ checked, theme }) =>
    checked &&
    css`
      &:hover {
        background: ${theme.colors.primary.dark_green_40};
        border-color: ${theme.colors.primary.dark_green_40};
      }
    `};

  ${({ disabled, theme }) =>
    disabled &&
    css`
      cursor: not-allowed;
      border: 1px solid ${theme.colors.neutral.lighter};
      background: ${theme.colors.neutral.lighter};

      ${Icon} {
        stroke: ${theme.colors.neutral.lighter};
      }
    `};

  ${CheckboxInput}:hover + & {
    transition: all 180ms;
  }

  ${Icon} {
    visibility: ${({ checked }) => (checked ? 'visible' : 'hidden')};
  }
`;
