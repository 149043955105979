import styled from 'styled-components/macro';

import HeadingBase from '@components/common/DataDisplay/Heading';

export const Header = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.lighter};
  padding: 0 0 15px 18px;
  margin-bottom: 31px;
`;

export const Title = styled(HeadingBase)`
  color: ${({ theme }) => theme.colors.neutral.darkest};
`;
