import styled, { css, keyframes } from 'styled-components/macro';

import ButtonBase from '@components/common/Inputs/Button';
import Typography from '@components/common/DataDisplay/Typography';

const backgroundAnimation = keyframes`
  0% {
    opacity: 0;
    backdrop-filter: blur(0px);
  }
  100% {
    opacity: 1;
    backdrop-filter: blur(2px);
  }
`;

const modalAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.1);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
`;

export const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: ${({ theme }) => theme.zIndex.sixthLayer};
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
  animation: ${backgroundAnimation} 0.3s ease-in-out;
`;

export const ModalContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${({ theme }) => theme.colors.neutral.white};
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 6px 18px 1px rgba(0, 0, 0, 0.06);
  z-index: ${({ theme }) => theme.zIndex.sixthLayer};
  animation: ${modalAnimation} 0.2s ease-in-out;
`;

export const Heading = styled(Typography)`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 16px 32px;
  border-radius: 8px 8px 0 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  width: 100%;
  gap: 24px;
`;

export const Title = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 10px;
  color: ${({ theme }) => theme.colors.neutral.darkest};
`;

export const Content = styled.div`
  width: 100%;
  padding: 24px 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
  padding: 16px 32px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  background-color: ${({ theme }) => theme.colors.neutral.gray_white};
`;

export const CancelButton = styled(ButtonBase)`
  & > span {
    color: ${({ theme }) => theme.colors.neutral.darker} !important;
  }
`;

export const SuccessButton = styled(ButtonBase)<{
  colorSuccessButton?: string;
  colorSuccessButtonText?: string;
}>`
  ${({ colorSuccessButton, colorSuccessButtonText }) =>
    colorSuccessButton &&
    css`
      color: ${colorSuccessButtonText};
      background-color: ${colorSuccessButton};
      border: none;
    `}

  ${({ colorSuccessButtonText }) =>
    colorSuccessButtonText &&
    css`
      & > span {
        color: ${colorSuccessButtonText};
      }
    `}
`;

export const CloseModalButton = styled.button`
  background: unset;
  border: unset;
  padding: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
