import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { useAnalytics } from '@store/context/common/AnalyticsContext';
import { useGetShipping } from '@store/common/shipping/GetShippingContext';

import EmptyData from '@components/common/utils/EmptyData';
import Header from './Header';
import Body from './Body';
import SkeletonLoading from './SkeletonLoading';

import * as S from './styles';

const Shipping: React.FC = () => {
  const navigate = useNavigate();
  const { isLoadingShippings, isShippingsError, shippings } = useGetShipping();
  const { accountId, checkoutId } = useParams();
  const { analytics } = useAnalytics();

  const isEmpty = shippings.length === 0;

  const onButtonClick = React.useCallback(() => {
    navigate(`/${accountId}/${checkoutId}/dashboard/checkout/shipping/new`);
  }, [navigate, accountId, checkoutId]);

  React.useEffect(() => {
    analytics.track('Shippings Page Viewed');
  }, [analytics]);

  if (isLoadingShippings) {
    return <SkeletonLoading />;
  }

  if (isShippingsError && !isLoadingShippings) {
    return <div>Error...</div>;
  }

  if (isEmpty) {
    return (
      <EmptyData
        title="Nenhum frete cadastrado"
        description="Adicione as informações e valores de frete para sua loja."
        buttonText="Novo frete"
        onClick={onButtonClick}
      />
    );
  }

  return (
    <S.Wrapper>
      <Header />

      <Body />
    </S.Wrapper>
  );
};

export default Shipping;
