import React from 'react';
import { useNavigate } from 'react-router-dom';

import { EButtonVariant } from '@domain/enums/components/common/EButton';
import { EUpsellType } from '@domain/enums/common/upsell/EUpsellType';

import { useUpdateUpsell } from '@store/common/upsell/UpdateUpsellContext';
import { useToast } from '@store/context/common/ToastContext';
import { useUserAccount } from '@store/context/common/UserAccount';

import { currencyFormatter, currencyToNumber } from '@helpers/utils/common/number';
import { getCurrencySymbol } from '@utils/common/formatCurrency';

import InputColor from '@components/common/Inputs/InputColor';
import Text from '@components/common/DataDisplay/Text';
import ProductSelect from '../../ProductSelect';
import VariantSelect from '../../VariantSelect';

import * as S from './styles';

const Body: React.FC = () => {
  const navigate = useNavigate();
  const { currentUserAccount } = useUserAccount();
  const { toast } = useToast();
  const {
    updateCurrentUpsell,
    isUpdatingUpsell,
    selectedOfferProduct,
    selectedTriggerProduct,
    handleSelectedVariant,
    handleSelectedOfferProduct,
    handleSelectedTriggerProduct,
    selectedVariant,
    descriptionRegister,
    nameRegister,
    priceRegister,
    titleRegister,
    selectedPrimaryColor,
    selectedTextColor,
    promotionalPriceRegister,
    handleSelectedPrimaryColor,
    handleSelectedTextColor,
    handleSubmit,
    errors,
    hasSelectedAllVariants,
  } = useUpdateUpsell();

  const onPrimaryColorChange = React.useCallback(
    event => {
      handleSelectedPrimaryColor(event.target.value);
    },
    [handleSelectedPrimaryColor],
  );

  const onTextColorChange = React.useCallback(
    event => {
      handleSelectedTextColor(event.target.value);
    },
    [handleSelectedTextColor],
  );

  const onPriceChange = React.useCallback(
    event => {
      const formattedValue = currencyFormatter(event.target.value);

      event.target.value = formattedValue;

      priceRegister.onChange(event);
    },
    [priceRegister],
  );

  const onPromotionalPriceChange = React.useCallback(
    event => {
      const formattedValue = currencyFormatter(event.target.value);

      event.target.value = formattedValue;

      promotionalPriceRegister.onChange(event);
    },
    [promotionalPriceRegister],
  );

  const onSubmit = React.useCallback(
    async formData => {
      if (!selectedTriggerProduct) {
        toast.error('Selecione o produto que será adquirido');
        return;
      }

      if (!selectedOfferProduct) {
        toast.error('Selecione o produto a ser oferecido');
        return;
      }

      if (!hasSelectedAllVariants && !selectedVariant) {
        toast.error('Selecione a variante');
        return;
      }

      if (!selectedPrimaryColor) {
        toast.error('Selecione a cor primária.');
        return;
      }

      if (!selectedTextColor) {
        toast.error('Selecione a cor do texto');
        return;
      }

      const payload = {
        name: formData.name,
        price: Math.round(currencyToNumber(formData.price) * 100),
        promotional_price: Math.round(currencyToNumber(formData.promotional_price) * 100),
        title: formData.title,
        description: formData.description,
        primary_hex_color: selectedPrimaryColor,
        text_hex_color: selectedTextColor,
        type: hasSelectedAllVariants ? EUpsellType.ALL_VARIANTS : EUpsellType.ONLY_ONE,
        variant_id: selectedVariant?.id,
        trigger_product_id: selectedTriggerProduct.id || selectedTriggerProduct.product?.id,
        offer_product_id: selectedOfferProduct.id || selectedOfferProduct.product?.id,
      };

      await updateCurrentUpsell(payload);
    },
    [
      hasSelectedAllVariants,
      selectedPrimaryColor,
      selectedTextColor,
      selectedVariant,
      selectedTriggerProduct,
      selectedOfferProduct,
      toast,
      updateCurrentUpsell,
    ],
  );

  const currency = getCurrencySymbol(currentUserAccount?.language, currentUserAccount?.currency);

  return (
    <S.Form onSubmit={handleSubmit(onSubmit)}>
      <S.Wrapper>
        <S.InputGroup>
          <S.Label>Nome do upsell</S.Label>
          <S.Input {...nameRegister} type="text" />
          {errors?.name && <Text isFeedbackError>{errors?.name.message}</Text>}
        </S.InputGroup>

        <S.WrapperSelect>
          <S.CoupleSelect>
            <S.Label>Oferecer o produto...</S.Label>
            <ProductSelect
              defaultProduct={selectedOfferProduct}
              onProductSelect={handleSelectedOfferProduct}
            />
          </S.CoupleSelect>
          {selectedOfferProduct?.id && (
            <S.CoupleSelect>
              <S.Label>Variante</S.Label>
              <VariantSelect
                selectedProduct={selectedOfferProduct}
                onVariantSelect={handleSelectedVariant}
                defaultSelectedAllVariants={hasSelectedAllVariants}
                defaultSelectedVariant={selectedVariant}
              />
            </S.CoupleSelect>
          )}
        </S.WrapperSelect>

        <S.InputGroupFlex>
          <S.ValueInputWrapperCouple>
            <S.Label>Preço da venda</S.Label>
            <S.ValueWrapperCouple>
              <S.CurrencyLabel>{currency}</S.CurrencyLabel>
              <S.Input {...priceRegister} onChange={onPriceChange} type="text" />
            </S.ValueWrapperCouple>
            {errors?.price && <Text isFeedbackError>{errors?.price.message}</Text>}
          </S.ValueInputWrapperCouple>

          <S.ValueInputWrapperCouple>
            <S.Label>Preço promocional</S.Label>
            <S.ValueWrapperCouple>
              <S.CurrencyLabel>{currency}</S.CurrencyLabel>
              <S.Input
                {...promotionalPriceRegister}
                onChange={onPromotionalPriceChange}
                type="text"
              />
            </S.ValueWrapperCouple>
            {errors?.promotional_price && (
              <Text isFeedbackError>{errors?.promotional_price.message}</Text>
            )}
          </S.ValueInputWrapperCouple>
        </S.InputGroupFlex>

        <S.InputGroup>
          <S.Label>Ao comprar o produto...</S.Label>
          <ProductSelect
            defaultProduct={selectedTriggerProduct}
            onProductSelect={handleSelectedTriggerProduct}
          />
        </S.InputGroup>

        <S.InputGroup>
          <S.Label>Título da oferta</S.Label>
          <S.Input {...titleRegister} type="text" />
          {errors?.title && <Text isFeedbackError>{errors?.title.message}</Text>}
        </S.InputGroup>

        <S.InputGroup>
          <S.Label>Descrição</S.Label>
          <S.TextAreaEditor {...descriptionRegister} />
          {errors?.description && <Text isFeedbackError>{errors?.description.message}</Text>}
        </S.InputGroup>

        <S.InputGroupFlex>
          {selectedPrimaryColor && (
            <InputColor defaultColor={selectedPrimaryColor} change={onPrimaryColorChange}>
              Cor primária
            </InputColor>
          )}

          {selectedTextColor && (
            <InputColor defaultColor={selectedTextColor} change={onTextColorChange}>
              Cor do texto
            </InputColor>
          )}
        </S.InputGroupFlex>
      </S.Wrapper>

      <S.ButtonsWrapper>
        <S.CancelButton
          type="button"
          onClick={() => navigate(-1)}
          variant={EButtonVariant.SECONDARY}
        >
          Cancelar
        </S.CancelButton>
        <S.SubmitButton isLoading={isUpdatingUpsell}>Salvar upsell</S.SubmitButton>
      </S.ButtonsWrapper>
    </S.Form>
  );
};

export default Body;
