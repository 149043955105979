import React from 'react';

import { ETransactionalEmail } from '@domain/enums/common/transactionalEmail/ETransactionalEmail';

import * as S from './styles';

interface IBadgeProps {
  emailType: string;
  icon: React.ElementType;
}

const Badge: React.FC<IBadgeProps> = ({ emailType, icon: Icon }) => {
  const emailTypeMappings: Record<ETransactionalEmail, string> = {
    [ETransactionalEmail.WAITING_PAYMENT]: 'AGUARDANDO PAGAMENTO',
    [ETransactionalEmail.CANCELED]: 'CANCELADO',
    [ETransactionalEmail.IN_TRANSPORT]: 'EM TRÂNSITO',
    [ETransactionalEmail.ABANDONED_CART]: 'CARRINHO ABANDONADO',
    [ETransactionalEmail.PAYMENT_APPROVED]: 'PAGAMENTO APROVADO',
    [ETransactionalEmail.PAYMENT_REFUSED]: 'PAGAMENTO NÃO APROVADO',
  };

  const mappedEmailType = (emailType as ETransactionalEmail) || ETransactionalEmail.WAITING_PAYMENT;

  return (
    <S.Badge emailType={mappedEmailType}>
      {Icon && <Icon size={13} />}
      {emailTypeMappings[mappedEmailType]}
    </S.Badge>
  );
};
export default Badge;
