import React from 'react';
import { useNavigate } from 'react-router-dom';

import { EButtonVariant } from '@domain/enums/components/common/EButton';
import { EOrderBumpType } from '@domain/enums/common/orderBump/EOrderBumpType';

import { useNewOrderBump } from '@store/common/orderBump/NewOrderBumpContext';
import { useToast } from '@store/context/common/ToastContext';
import { useUserAccount } from '@store/context/common/UserAccount';

import { currencyFormatter, currencyToNumber } from '@helpers/utils/common/number';
import { getCurrencySymbol } from '@utils/common/formatCurrency';

import InputColor from '@components/common/Inputs/InputColor';
import Text from '@components/common/DataDisplay/Text';
import ProductSelect from '../../ProductSelect';
import VariantSelect from '../../VariantSelect';
import Preview from '../../../../../common/DataDisplay/Preview';

import * as S from './styles';

const Body: React.FC = () => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const { currentUserAccount } = useUserAccount();
  const {
    createNewOrderBump,
    isCreatingOrderBump,
    selectedOfferProduct,
    selectedTriggerProduct,
    selectedVariant,
    handleSelectedOfferProduct,
    handleSelectedTriggerProduct,
    handleSelectedVariant,
    ctaTextRegister,
    descriptionRegister,
    handleSubmit,
    nameRegister,
    priceRegister,
    promotionalPriceRegister,
    titleRegister,
    handleSelectedPrimaryColor,
    handleSelectedTextColor,
    selectedPrimaryColor,
    selectedTextColor,
    errors,
    hasSelectedAllVariants,
  } = useNewOrderBump();

  const onPrimaryColorChange = React.useCallback(
    event => {
      handleSelectedPrimaryColor(event.target.value);
    },
    [handleSelectedPrimaryColor],
  );

  const onTextColorChange = React.useCallback(
    event => {
      handleSelectedTextColor(event.target.value);
    },
    [handleSelectedTextColor],
  );

  const onPriceChange = React.useCallback(
    event => {
      const formattedValue = currencyFormatter(event.target.value);

      event.target.value = formattedValue;

      priceRegister.onChange(event);
    },
    [priceRegister],
  );

  const onPromotionalPriceChange = React.useCallback(
    event => {
      const formattedValue = currencyFormatter(event.target.value);

      event.target.value = formattedValue;

      promotionalPriceRegister.onChange(event);
    },
    [promotionalPriceRegister],
  );

  const onSubmit = React.useCallback(
    async formData => {
      if (!selectedTriggerProduct) {
        toast.error('Selecione o produto que será adquirido');
        return;
      }

      if (!selectedOfferProduct) {
        toast.error('Selecione o produto a ser oferecido');
        return;
      }

      if (!hasSelectedAllVariants && !selectedVariant) {
        toast.error('Selecione a variante');
        return;
      }

      const payload = {
        name: formData.name,
        price: Math.round(currencyToNumber(formData.price) * 100),
        promotional_price: Math.round(currencyToNumber(formData.promotional_price) * 100),
        title: formData.title,
        description: formData.description,
        cta_text: formData.cta_text,
        primary_hex_color: selectedPrimaryColor,
        text_hex_color: selectedTextColor,
        type: hasSelectedAllVariants ? EOrderBumpType.ALL_VARIANTS : EOrderBumpType.ONLY_ONE,
        variant_id: selectedVariant?.id,
        trigger_product_id: selectedTriggerProduct.id || selectedTriggerProduct.product?.id,
        offer_product_id: selectedOfferProduct.id || selectedOfferProduct.product?.id,
      };

      await createNewOrderBump(payload);
    },
    [
      hasSelectedAllVariants,
      selectedPrimaryColor,
      selectedTextColor,
      selectedVariant,
      selectedTriggerProduct,
      selectedOfferProduct,
      toast,
      createNewOrderBump,
    ],
  );

  const currency = getCurrencySymbol(currentUserAccount?.language, currentUserAccount?.currency);

  return (
    <>
      <S.Form onSubmit={handleSubmit(onSubmit)}>
        <S.Wrapper>
          <S.InputGroup>
            <S.Label>Nome do order bump</S.Label>
            <S.Input {...nameRegister} type="text" />
            {errors?.name && <Text isFeedbackError>{errors?.name.message}</Text>}
          </S.InputGroup>

          <S.InputGroup>
            <S.ValueWrapperCoupleSelect>
              <S.CoupleSelect>
                <S.Label>Oferecer o produto...</S.Label>
                <ProductSelect onProductSelect={handleSelectedOfferProduct} />
              </S.CoupleSelect>

              <S.CoupleSelect>
                <S.Label>Variante</S.Label>
                <VariantSelect
                  selectedProduct={selectedOfferProduct}
                  onVariantSelect={handleSelectedVariant}
                />
              </S.CoupleSelect>
            </S.ValueWrapperCoupleSelect>
          </S.InputGroup>

          <S.InputGroupFlex>
            <S.ValueInputWrapperCouple>
              <S.Label>Preço da venda</S.Label>
              <S.ValueWrapperCouple>
                <S.CurrencyLabel>{currency}</S.CurrencyLabel>
                <S.Input {...priceRegister} onChange={onPriceChange} type="text" />
              </S.ValueWrapperCouple>
              {errors?.price && <Text isFeedbackError>{errors?.price.message}</Text>}
            </S.ValueInputWrapperCouple>

            <S.ValueInputWrapperCouple>
              <S.Label>Preço promocional</S.Label>
              <S.ValueWrapperCouple>
                <S.CurrencyLabel>{currency}</S.CurrencyLabel>
                <S.Input
                  {...promotionalPriceRegister}
                  onChange={onPromotionalPriceChange}
                  type="text"
                />
              </S.ValueWrapperCouple>
              {errors?.promotional_price && (
                <Text isFeedbackError>{errors?.promotional_price.message}</Text>
              )}
            </S.ValueInputWrapperCouple>
          </S.InputGroupFlex>

          <S.InputGroup>
            <S.Label>Ao comprar o produto...</S.Label>
            <ProductSelect onProductSelect={handleSelectedTriggerProduct} />
          </S.InputGroup>

          <S.InputGroup>
            <S.Label>Texto do CTA</S.Label>
            <S.Input {...ctaTextRegister} type="text" />
            {errors?.cta_text && <Text isFeedbackError>{errors?.cta_text.message}</Text>}
          </S.InputGroup>

          <S.InputGroup>
            <S.Label>Título da oferta</S.Label>
            <S.Input {...titleRegister} type="text" />
            {errors?.title && <Text isFeedbackError>{errors?.title.message}</Text>}
          </S.InputGroup>

          <S.InputGroup>
            <S.Label>Descrição</S.Label>
            <S.TextAreaEditor {...descriptionRegister} />
            {errors?.description && <Text isFeedbackError>{errors?.description.message}</Text>}
          </S.InputGroup>

          <S.InputGroupFlex>
            <InputColor defaultColor={selectedPrimaryColor} change={onPrimaryColorChange}>
              Cor primária
            </InputColor>

            <InputColor defaultColor={selectedTextColor} change={onTextColorChange}>
              Cor do texto
            </InputColor>
          </S.InputGroupFlex>
        </S.Wrapper>

        <S.ButtonsWrapper>
          <S.EndButtons>
            <S.CancelButton
              type="button"
              onClick={() => navigate(-1)}
              variant={EButtonVariant.SECONDARY}
            >
              Cancelar
            </S.CancelButton>
            <S.SubmitButton isLoading={isCreatingOrderBump}>Criar</S.SubmitButton>
          </S.EndButtons>
        </S.ButtonsWrapper>
      </S.Form>

      <Preview />
    </>
  );
};

export default Body;
