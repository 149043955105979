import styled from 'styled-components/macro';
import { Info } from 'phosphor-react';

import TextBase from '@components/common/DataDisplay/Text';
import ButtonBase from '@components/common/Inputs/Button';
import HeadingBase from '@components/common/DataDisplay/Heading';

interface ICopyAreaProps {
  success?: boolean;
}

export const CopyArea = styled.div<ICopyAreaProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 24px;
  border-radius: 8px;
  background-color: ${({ theme, success }) =>
    success ? theme.colors.positive.lightest_10 : theme.colors.warning.lighter_10};
`;

export const IconWrapper = styled.div<ICopyAreaProps>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 14px;
  top: 14px;
  padding: 6px;
  border-radius: 50%;
  background-color: ${({ theme, success }) =>
    success ? theme.colors.positive.lightest : theme.colors.warning.lighter};
`;

export const CopyAreaKey = styled(TextBase)`
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.neutral.dark};
`;

export const CopyAreaValue = styled(TextBase)<ICopyAreaProps>`
  font-size: 1.6rem;
  color: ${({ theme, success }) =>
    success ? theme.colors.positive.darker : theme.colors.warning.darker};
  word-wrap: break-word;
  overflow: auto;
`;

export const CopyWrapperItems = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 8px;

  & > svg {
    min-width: 20px;
    height: 20px;
    cursor: pointer;
  }
`;

export const DeleteCouponButton = styled(ButtonBase)`
  background-color: transparent;
  border: none;

  > span {
    color: ${({ theme }) => theme.colors.negative.base};
    font-size: 1.6rem;
    font-weight: 500;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const VerifyEmailButton = styled(ButtonBase)`
  align-self: self-start;

  & > span {
    color: ${({ theme }) => theme.colors.neutral.darker};
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const WrapperIconAndTextInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const InfoIcon = styled(Info).attrs(() => ({
  size: 18,
  weight: 'bold',
}))`
  color: ${({ theme }) => theme.colors.secondary.dark};
`;

export const InfoText = styled(TextBase)`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.warning.base};
`;

export const Divider = styled.hr`
  height: 1px;
  border: none;
  background-color: ${({ theme }) => theme.colors.neutral.lightest};
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const Title = styled(HeadingBase)`
  color: ${({ theme }) => theme.colors.neutral.darkest};
`;

export const Description = styled(TextBase)``;
