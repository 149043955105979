import styled from 'styled-components/macro';
import Skeleton from 'react-loading-skeleton';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

export const Header = styled(Skeleton)`
  display: flex;
  align-self: flex-start;
`;

export const HeaderWrapper = styled.div`
  width: 100%;
`;

export const FirstCard = styled.div`
  position: relative;
  width: 100%;
  height: 130px;
`;

export const ContainerSecondCard = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
`;
export const WrapperItems = styled.div`
  display: flex;
  align-self: center;
  gap: 8px;
`;

export const SecondCard = styled.div`
  position: relative;
  width: 328px;
  height: 130px;
`;

export const ContainerIcon = styled(Skeleton)`
  position: absolute;
  border-radius: 8px;
  z-index: 2;
  top: 10%;
  left: 2%;
`;

export const Text = styled(Skeleton)`
  position: absolute;
  z-index: 2;
  top: 15%;
  left: 9%;
`;

export const TextSecondCard = styled(Text)`
  top: 15%;
  left: 15%;
`;

export const Description = styled(Skeleton)`
  position: absolute;
  z-index: 2;
  top: 50%;
`;
