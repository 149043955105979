/* eslint-disable react/react-in-jsx-scope */
import { InputHTMLAttributes, forwardRef } from 'react';

import * as S from './styles';

type RadioValue = string | ReadonlyArray<string> | number;

export interface IRadioProps extends InputHTMLAttributes<HTMLInputElement> {
  onCheck?: (value?: RadioValue) => void;
  label?: string;
  labelColor?: 'white' | 'black';
  labelFor?: string;
  value?: RadioValue;
}

const Radio = forwardRef<HTMLInputElement, IRadioProps>(
  ({ label, onCheck, labelColor = 'white', labelFor = '', value, ...props }, ref) => {
    const onChange = (): void => {
      !!onCheck && onCheck(value);
    };

    return (
      <S.Wrapper>
        <S.Input
          id={labelFor}
          type="radio"
          value={value}
          onChange={onChange}
          {...props}
          ref={ref}
        />
        {!!label && (
          <S.Label labelColor={labelColor} htmlFor={labelFor}>
            {label}
          </S.Label>
        )}
      </S.Wrapper>
    );
  },
);

export default Radio;
