import React from 'react';

import { useGetMyRatesContext } from '@store/common/myRates/GetMyRatesContext';
import Header from './Header';
import Body from './Body';

import * as S from './styles';
import SkeletonLoading from './SkeletonLoading';

const MyRates: React.FC = () => {
  const { isLoadingGetMyRates } = useGetMyRatesContext();

  if (isLoadingGetMyRates) {
    return <SkeletonLoading />;
  }
  return (
    <S.Wrapper>
      <Header />

      <Body />
    </S.Wrapper>
  );
};

export default MyRates;
