import React from 'react';

import { useGetAccountStep } from '@store/common/home/GetAccountStepContext';

import * as S from './styles';

const CongratulationModal: React.FC = () => {
  const { setIsOpenModal, isOpenModal, mutate } = useGetAccountStep();

  const onReloadHomePage = async (): Promise<void> => {
    await mutate();
    setIsOpenModal({ ...isOpenModal, congratulation: !isOpenModal.congratulation });
  };

  return (
    <S.Modal
      title="Pronta para ser lançada! 🎉"
      toggle={onReloadHomePage}
      isOpen={isOpenModal.congratulation}
    >
      <S.Wrapper>
        <S.Text>
          Parabéns! Sua loja está pronta para ser lançada. Você já fez um ótimo trabalho até aqui.
          Agora, é hora de começar a vender!
        </S.Text>
        <S.Text>Aqui estão algumas dicas extras para ajudá-lo a ter sucesso:</S.Text>
        <S.TipsCard>
          <S.WrapperTextAndIcon>
            <S.HeadsetIcon />
            <S.TipsText>Ofereça um ótimo atendimento ao cliente.</S.TipsText>
          </S.WrapperTextAndIcon>
          <S.WrapperTextAndIcon>
            <S.TruckIcon />
            <S.TipsText>Mantenha os códigos de rastreio atualizados na plataforma.</S.TipsText>
          </S.WrapperTextAndIcon>
          <S.WrapperTextAndIcon>
            <S.MetaLogoIcon />
            <S.TipsText>Promova sua loja nas mídias sociais e em canais de marketing.</S.TipsText>
          </S.WrapperTextAndIcon>
          <S.WrapperTextAndIcon>
            <S.TagIcon />
            <S.TipsText>Adicione novos produtos e ofertas regularmente.</S.TipsText>
          </S.WrapperTextAndIcon>
        </S.TipsCard>
        <S.Text>Desejamos a você muito sucesso em seus negócios.</S.Text>
        <S.TeamText>Equipe Zouti</S.TeamText>
      </S.Wrapper>
      <S.Button onClick={onReloadHomePage}>Começar a vender</S.Button>
    </S.Modal>
  );
};

export default CongratulationModal;
