import React from 'react';
import { useTheme } from 'styled-components/macro';

import { Trash, User } from 'phosphor-react';

import { EHeadAlign } from '@domain/enums/components/common/ETable';

import { IUsersProps, useUsersContext } from '@store/common/users/UsersContext';

import * as S from './styles';

interface ITableRowProps {
  user: IUsersProps;
}

export const TableRow: React.FC<ITableRowProps> = ({ user }) => {
  const theme = useTheme();
  const { onToggleDeleteModel, setDeleteUserData } = useUsersContext();

  const [isVisibleActions, setIsVisibleActions] = React.useState<boolean>(false);

  const onRowMouseEnter = React.useCallback(() => {
    setIsVisibleActions(true);
  }, []);

  const onRowMouseLeave = React.useCallback(() => {
    setIsVisibleActions(false);
  }, []);

  const userName = user.name;
  const userEmail = user.email;
  const userGroup = user.group_name;
  // const userLastLogin = new Date(user.lastLogin).toLocaleDateString('pt-BR', {
  //   day: 'numeric',
  //   month: 'long',
  //   year: 'numeric',
  // });

  const onDeleteButtonClick = (): void => {
    onToggleDeleteModel();
    setDeleteUserData({ userName, membershipId: user?.id });
  };

  return (
    <S.TableRow onMouseEnter={onRowMouseEnter} onMouseLeave={onRowMouseLeave}>
      {/* <S.Table.Data>
        <S.Checkbox />
      </S.Table.Data> */}

      <S.TableDataName>
        <S.IconContainer>
          <User size={13} weight="bold" color={theme.colors.primary.dark_green} />
        </S.IconContainer>
        <S.Text>{userName}</S.Text>
      </S.TableDataName>

      <S.Table.Data>
        <S.Text>{userEmail}</S.Text>
      </S.Table.Data>

      <S.Table.Data>
        <S.Text>{userGroup}</S.Text>
      </S.Table.Data>

      {/* <S.Table.Data>
        <S.Text>{userLastLogin}</S.Text>
      </S.Table.Data> */}

      <S.TableDataAction align={EHeadAlign.RIGHT}>
        <S.Dropdown>
          <S.DropdownTrigger>
            <S.TableAction>
              {isVisibleActions && (
                <S.DotsThreeIcon color={theme.colors.neutral.darker} size={18} weight="bold" />
              )}
            </S.TableAction>
          </S.DropdownTrigger>
          <S.DropdownContent>
            {/* <S.DropdownItem>
              <PencilSimple color={theme.colors.neutral.darker} size={18} weight="bold" />
              Editar
            </S.DropdownItem> */}
            <S.DropdownItem onClick={onDeleteButtonClick}>
              <Trash />
              Excluir
            </S.DropdownItem>
          </S.DropdownContent>
        </S.Dropdown>
      </S.TableDataAction>
    </S.TableRow>
  );
};
