import React from 'react';
import { UpdatePersonalDataProvider } from '@store/common/profile/UpdatePersonalDataContext';
import Tab from '@components/common/utils/Tab';
import Option from '@components/common/utils/Tab/Option';
import { UpdatePasswordProvider } from '@store/common/profile/UpdatePasswordContext';
import PersonalData from '../Tabs/PersonalData';
import Password from '../Tabs/Password';

const Body: React.FC = () => {
  const [selectedTab, setSelectedTab] = React.useState<number>(0);
  const onChangeTab = React.useCallback(index => setSelectedTab(index), []);

  return (
    <>
      <Tab defaultTab={selectedTab} onChangeTab={onChangeTab}>
        <Option>Dados Pessoais</Option>
        <Option>Senha</Option>
      </Tab>
      {selectedTab === 0 && (
        <UpdatePersonalDataProvider>
          <PersonalData />
        </UpdatePersonalDataProvider>
      )}
      {selectedTab === 1 && (
        <UpdatePasswordProvider>
          <Password />
        </UpdatePasswordProvider>
      )}
    </>
  );
};

export default Body;
