import React from 'react';

import { useListAbandonedCart } from '@store/common/AbandonedCart/ListAbandonedCartContext';

import PageHeader from '@components/common/utils/PageHeader';

import * as S from './styles';

const Header: React.FC = () => {
  const { abandonedCartsTotal } = useListAbandonedCart();

  const quantityText =
    abandonedCartsTotal === 1 ? '1 resultado' : `${abandonedCartsTotal} resultados`;

  return (
    <S.Wrapper>
      <PageHeader title="Carrinhos abandonados" quantityText={quantityText} />
    </S.Wrapper>
  );
};

export default Header;
