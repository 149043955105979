import React from 'react';
import { CaretLeft, CaretRight } from 'phosphor-react';
import { useTheme } from 'styled-components/macro';

import * as S from './styles';

const MONTHS = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

const Header: React.FC<any> = ({ date, decreaseMonth, increaseMonth }: any) => {
  const theme = useTheme();

  return (
    <S.HeaderWrapper>
      <S.PreviousMonthButton type="button" onClick={decreaseMonth}>
        <CaretLeft size={20} weight="bold" color={theme.colors.neutral.darker} />
      </S.PreviousMonthButton>

      <S.Text>{`${MONTHS[date.getMonth()]} ${date.getFullYear()}`}</S.Text>

      <S.NextMonthButton type="button" onClick={increaseMonth}>
        <CaretRight size={20} weight="bold" color={theme.colors.neutral.darker} />
      </S.NextMonthButton>
    </S.HeaderWrapper>
  );
};

export default Header;
