import React from 'react';
import { useTheme } from 'styled-components/macro';
import { PencilSimple, DotsThree, Trash } from 'phosphor-react';
import { EHeadAlign } from '@domain/enums/components/common/ETable';
import { useNavigate, useParams } from 'react-router-dom';
import { useListCheckout } from '@store/common/checkoutCustomization/ListCheckoutCustomizationsContext';

import * as S from './styles';

type CheckoutCustomizationProps = {
  id: string;
  name: string;
};

interface ITableRowProps {
  items: CheckoutCustomizationProps;
}

const TableRow: React.FC<ITableRowProps> = ({ items }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { deleteCurrentCheckoutCustomization, activateCheckout } = useListCheckout();
  const { accountId, checkoutId } = useParams();

  const isInactivated = Object.prototype.hasOwnProperty.call(items, 'inactivated_at');
  const checkHandle = (checkoutCustomizationId: string): void => {
    activateCheckout(checkoutCustomizationId);
  };

  const onEditButtonClick = (): void => {
    navigate(`/${accountId}/${checkoutId}/dashboard/checkout/customizations/${items.id}`);
  };

  return (
    <S.Table.Row borderBottom>
      <S.Table.Data>
        <S.RadioButton
          name="radioButton"
          checked={!isInactivated}
          value={items.id}
          onChange={() => checkHandle(items.id)}
        />
      </S.Table.Data>

      <S.Table.Data align={EHeadAlign.LEFT}>{items.name}</S.Table.Data>

      <S.Table.Data align={EHeadAlign.RIGHT}>
        <S.ButtonActionsWrapper>
          <S.Table.Action onClick={onEditButtonClick}>
            <PencilSimple color={theme.colors.neutral.darker} size={18} weight="bold" />
          </S.Table.Action>
          <S.Dropdown>
            <S.DropdownTrigger>
              <S.Table.Action>
                <DotsThree color={theme.colors.neutral.darker} size={18} weight="bold" />
              </S.Table.Action>
            </S.DropdownTrigger>
            <S.DropdownContent>
              <S.DropdownItem onClick={() => deleteCurrentCheckoutCustomization(items.id)}>
                <Trash />
                Excluir
              </S.DropdownItem>
            </S.DropdownContent>
          </S.Dropdown>
        </S.ButtonActionsWrapper>
      </S.Table.Data>
    </S.Table.Row>
  );
};

export default TableRow;
