import React from 'react';
import { useTheme } from 'styled-components/macro';
import { useNavigate, useParams } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { FieldValues, useForm } from 'react-hook-form';
import { Trash, UploadSimple } from 'phosphor-react';

import { EButtonVariant } from '@domain/enums/components/common/EButton';
import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/common/EHeading';
import { ICreateCheckoutCustomizationData } from '@domain/interfaces/hooks/services/checkoutCustomization/ICheckoutCustomizationService';

import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-css';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/webpack-resolver';

import { useNewCheckout } from '@store/common/checkoutCustomization/NewCheckoutCustomizationContext';

import Tooltip from '@components/pages/Dashboard/CheckoutCustomization/Tooltip';
import * as S from './styles';

const Advanced: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { accountId, checkoutId } = useParams();
  const {
    createNewCheckout,
    isCreatingCheckout,
    checkoutCustomizationData,
    setCheckoutCustomizationData,
    faviconImage,
    responseUploadFavicon,
    deleteUploadCheckout,
    handleImageChange,
    selectedThemeCheckout,
  } = useNewCheckout();

  const {
    name,
    allow_credit_card,
    boleto_discount_type,
    credit_card_discount_type,
    pix_discount_type,
    allow_pix,
    allow_boleto,
    credit_card_discount_amount,
    pix_discount_amount,
    boleto_discount_amount,
    show_coupon_input,
    enable_itens_quantity_change,
    header_customization,
    footer_customization,
    appearance_customization,
    advanced_customization,
    show_time_shipping_estimate,
  } = checkoutCustomizationData;

  const { reset, handleSubmit, setValue, watch } = useForm({});

  const onSubmit = async (formData: FieldValues): Promise<void> => {
    const data: ICreateCheckoutCustomizationData = {
      name,
      allow_credit_card,
      allow_pix,
      allow_boleto,
      boleto_discount_type,
      credit_card_discount_type,
      pix_discount_type,
      credit_card_discount_amount,
      pix_discount_amount,
      boleto_discount_amount,
      show_coupon_input,
      enable_itens_quantity_change,
      show_time_shipping_estimate,
      header_customization: { ...header_customization },
      footer_customization: { ...footer_customization },
      appearance_customization: { ...appearance_customization },
      advanced_customization: {
        custom_css: formData.custom_css,
        favicon_url: responseUploadFavicon.url,
        favicon_path: responseUploadFavicon.path,
      },
      checkout_theme: { theme: selectedThemeCheckout },
    };

    await createNewCheckout({ payload: data });
  };

  React.useEffect(() => {
    return () => {
      const fieldValues = watch();
      setCheckoutCustomizationData(prevData => {
        return {
          ...prevData,
          advanced_customization: {
            custom_css: fieldValues.custom_css,
            favicon_url: responseUploadFavicon.url,
            favicon_path: responseUploadFavicon.path,
          },
        };
      });
    };
  }, [setCheckoutCustomizationData, responseUploadFavicon, watch]);

  const onDeleteFavicon = (path: string): void => {
    deleteUploadCheckout(path, 'favicon');
  };
  const onCancelButtonClick = (): void => {
    reset();
    navigate(`/${accountId}/${checkoutId}/dashboard/checkout/customizations`);
  };

  const onDrop = React.useCallback(
    (accFiles: File[]) => {
      const mappedAcc = accFiles.map(file => ({
        file,
        size: file.size,
        name: file.name,
        errors: [],
        preview: URL.createObjectURL(file),
      }));
      handleImageChange({ mappedAcc, type: 'favicon' });
    },
    [handleImageChange],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    maxSize: 104857600,
  });

  return (
    <S.Form onSubmit={handleSubmit(onSubmit)}>
      <S.Wrapper>
        <S.EditorSection>
          <S.Title size={EHeadingSize.H4} fontWeight={EHeadingWeight.BOLD}>
            CSS Customizado
          </S.Title>
          <S.StyleEditor>
            <AceEditor
              mode="css"
              theme="github"
              name="code-editor"
              height="271px"
              width="100%"
              defaultValue={advanced_customization?.custom_css}
              onChange={value => {
                setValue('custom_css', value);
              }}
            />
          </S.StyleEditor>
        </S.EditorSection>
        <S.Divider />
        <S.FaviconSection>
          <S.Title size={EHeadingSize.H4} fontWeight={EHeadingWeight.BOLD}>
            Favicon
          </S.Title>
          {faviconImage?.length === 0 ? (
            <>
              <S.ContainerDropzone {...getRootProps()}>
                <input {...getInputProps()} />
                <UploadSimple weight="bold" size={13} />
                <S.TextDropzone>Arraste e solte seu arquivo aqui</S.TextDropzone>
                <S.AcceptFormat>Formato recomendado: PNG</S.AcceptFormat>
              </S.ContainerDropzone>
              <S.TextInfoAndTooltipWrapper>
                <S.TextInfo>Tamanho padrão: 32x32px.</S.TextInfo>
                {/* <Tooltip
                  iconColor={theme.colors.neutral.dark}
                  iconWeight="regular"
                  url="https://images.freeimages.com/images/small-previews/aed/three-bees-on-sunflower-1337029.jpg"
                /> */}
              </S.TextInfoAndTooltipWrapper>
            </>
          ) : (
            faviconImage?.map((item, index) => (
              <>
                <S.PreviewCardInfo key={index.toFixed()}>
                  <S.Image src={item.preview} alt={`Preview ${item.file.name || 'favicon.ico'}`} />
                  <S.FileName>{item.file.name || 'favicon.ico'}</S.FileName>
                  <Trash onClick={() => onDeleteFavicon(responseUploadFavicon?.path)} size={28} />
                </S.PreviewCardInfo>
              </>
            ))
          )}
        </S.FaviconSection>
      </S.Wrapper>
      <S.ButtonsWrapper>
        <S.CancelButton
          type="button"
          onClick={onCancelButtonClick}
          variant={EButtonVariant.SECONDARY}
        >
          Cancelar
        </S.CancelButton>
        <S.SubmitButton type="submit" isLoading={isCreatingCheckout}>
          Salvar
        </S.SubmitButton>
      </S.ButtonsWrapper>
    </S.Form>
  );
};

export default Advanced;
