import Select from 'react-select';
import styled from 'styled-components/macro';

import LabelBase from '@components/common/DataDisplay/Label';
import DropdownBase from '@components/common/utils/Dropdown';
import TextBase from '@components/common/DataDisplay/Text';

import selectArrow from '@assets/images/components/select/CaretDown.svg';

export const Dropdown = styled(DropdownBase)`
  width: 100%;
`;

export const DropdownTrigger = styled(DropdownBase.Trigger)`
  display: flex;
  align-items: center;
  gap: 8px;
  height: 40px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  padding: 8px 16px;
  position: relative;
  text-align: left;
  font-size: 1.4rem;
  transition: all 0.3s;
  width: 100%;
  cursor: pointer;
  margin-right: 5px;

  color: ${({ theme }) => theme.colors.neutral.base};

  &:after {
    content: url(${selectArrow});
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: ${({ theme }) => theme.spacing.small_16};
  }
`;

export const DropdownContentWrapper = styled(DropdownBase.Content)`
  min-width: 411px;
  max-height: 240px;
  z-index: 999999999;
  padding: 8px 0px;
`;

export const DropdownContent = styled.div`
  display: flex;
  align-items: center;
  transition: all 0.3s;
  height: 42px;
  padding: 16px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary.lemon_20};

    & > * {
      color: #96a516;
    }
  }
`;

export const DropdownItem = styled(TextBase)`
  font-size: 1.4rem;
`;

export const SelectedActionText = styled(TextBase)`
  font-size: 1.6rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.neutral.darker};
`;
