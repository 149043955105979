import React from 'react';
import { PlusCircle } from 'phosphor-react';

import { EHeadingSize } from '@domain/enums/components/common/EHeading';
import { EButtonVariant } from '@domain/enums/components/common/EButton';

import * as S from './styles';

interface IEmptyDataProps {
  title: string;
  description: string;
  buttonText?: string;
  onClick?: () => void;
}

const EmptyData: React.FC<IEmptyDataProps> = ({ title, description, buttonText, onClick }) => {
  return (
    <S.Wrapper>
      <S.Title size={EHeadingSize.H2}>{title}</S.Title>

      <S.Description>{description}</S.Description>

      {buttonText && (
        <S.Button onClick={onClick} variant={EButtonVariant.SECONDARY}>
          <PlusCircle />
          {buttonText}
        </S.Button>
      )}
    </S.Wrapper>
  );
};

export default EmptyData;
