/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { fetchRemoteConfig, remoteConfig } from '@services/firebase';
import { getValue } from 'firebase/remote-config';
import { CircleSpinner } from 'react-spinners-kit';
import { useTheme } from 'styled-components/macro';

import * as S from '@components/pages/Loading/Wrapper/styles';

interface IFeatureToggleContext {
  isEnable: (featureToggle: string) => boolean;
}

export const FeatureToggleContext = React.createContext<IFeatureToggleContext | null>(null);

export const FeatureToggleProvider: React.FC = ({ children }) => {
  const theme = useTheme();

  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    fetchRemoteConfig().then(() => setIsLoading(false));
  }, []);

  const isEnable = (featureToggle: string): boolean => {
    const result = getValue(remoteConfig, featureToggle).asBoolean();
    return result;
  };

  if (isLoading) {
    return (
      <S.Wrapper>
        <CircleSpinner size={64} color={theme.colors.primary.dark_green_40} />
      </S.Wrapper>
    );
  }

  return (
    <FeatureToggleContext.Provider value={{ isEnable }}>{children}</FeatureToggleContext.Provider>
  );
};

export const useFeatureToggle = (): IFeatureToggleContext => {
  const context = React.useContext(FeatureToggleContext);

  if (!context) {
    throw new Error('useFeatureToggle must be used within FeatureToggleProvider');
  }

  return context;
};
