import React from 'react';

import { useTheme } from 'styled-components/macro';
import { MagnifyingGlass } from 'phosphor-react';

import Paginate from '../Paginate';

import * as S from './styles';

interface IBottomPaginationProps {
  totalItems: number;
  totalPages: number;
  handlePageChange: (selected: number) => void;
  handlePageLimit?: (newPageLimit: number) => void;
  pageLimit?: number;
  page: number;
}

const BottomPagination: React.FC<IBottomPaginationProps> = ({
  totalItems,
  totalPages,
  pageLimit,
  page,
  handlePageChange,
  handlePageLimit,
}) => {
  const theme = useTheme();

  const [pageToGo, setPageToGo] = React.useState<number>(0);

  const onSelectLimitChange = React.useCallback(
    event => {
      const limit = event.target.value;

      handlePageLimit?.(limit);
    },
    [handlePageLimit],
  );

  const onPageChange = React.useCallback(
    (selectedItem: { selected: number }) => {
      handlePageChange(selectedItem.selected);
    },
    [handlePageChange],
  );

  const onGoToPageInputChange = React.useCallback(event => {
    const newPage = event.target.value;

    setPageToGo(newPage);
  }, []);

  const onGoToPageButtonClick = React.useCallback(() => {
    handlePageChange(pageToGo - 1);
  }, [pageToGo, handlePageChange]);

  const quantityItemText = `Exibindo 1-${pageLimit} de ${totalItems}`;

  const isGoToPageButtonDisabled = pageToGo > totalPages;

  return (
    <S.Wrapper>
      <S.ShowQuantityItemsText>{quantityItemText}</S.ShowQuantityItemsText>

      <Paginate pageCount={totalPages} onPageChange={onPageChange} forcePage={page} />

      <S.SelectAndInputGroupWrapper>
        {/* <S.ItemsLimitSelect
          name="items-limit-select"
          value={String(pageLimit)}
          onChange={onSelectLimitChange}
        >
          <S.Option value="10">10</S.Option>
          <S.Option value="12">12</S.Option>
          <S.Option value="24">24</S.Option>
          <S.Option value="32">32</S.Option>
        </S.ItemsLimitSelect> */}

        <S.GoToPageInputGroup>
          <S.GoToPageInput
            type="text"
            placeholder="Ir para página"
            onChange={onGoToPageInputChange}
          />

          <S.GoToPageButton onClick={onGoToPageButtonClick} disabled={isGoToPageButtonDisabled}>
            <MagnifyingGlass size={16} color={theme.colors.neutral.black} />
          </S.GoToPageButton>
        </S.GoToPageInputGroup>
      </S.SelectAndInputGroupWrapper>
    </S.Wrapper>
  );
};

export default BottomPagination;
