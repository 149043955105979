import React from 'react';
import { EPayoutStatus } from '@domain/enums/common/payouts/EPayoutStatus';

import { formatCurrency } from '@utils/common/formatCurrency';
import { formatDateParts } from '@utils/common/date';
import Badge from '../../Badge';

import * as S from './styles';

interface IPayoutsSummaryProps {
  amount: number;
  status: EPayoutStatus;
  transferId: string;
  fee: number;
  createdAt: string;
  updatedAt: string;
}

export const PayoutsSummary: React.FC<IPayoutsSummaryProps> = ({
  amount,
  status,
  transferId,
  fee,
  createdAt,
  updatedAt,
}) => {
  const created_at = formatDateParts(createdAt);
  const updated_at = formatDateParts(updatedAt);

  const transferredValueFormatted = formatCurrency(amount / 100);
  const rateValueFormatted = formatCurrency(fee / 100);

  return (
    <S.SummaryContainer>
      <S.SummaryRow>
        <S.SummaryItem>
          <S.Label>Valor</S.Label>
          <S.ValueAmount>{transferredValueFormatted}</S.ValueAmount>
        </S.SummaryItem>
        <S.SummaryItemStatus>
          <S.Label>Status</S.Label>
          <Badge status={status} />
        </S.SummaryItemStatus>
      </S.SummaryRow>
      <S.SummaryRow>
        <S.SummaryItem>
          <S.Label>ID da transferência</S.Label>
          <S.Value>{transferId}</S.Value>
        </S.SummaryItem>
        <S.SummaryItem>
          <S.Label>Taxa</S.Label>
          <S.Value>{rateValueFormatted}</S.Value>
        </S.SummaryItem>
      </S.SummaryRow>
      <S.SummaryRow>
        <S.SummaryItem>
          <S.Label>Criada em</S.Label>
          <S.Value>{created_at}</S.Value>
        </S.SummaryItem>
        <S.SummaryItem>
          <S.Label>Última atualização</S.Label>
          <S.Value>{updated_at}</S.Value>
        </S.SummaryItem>
      </S.SummaryRow>
    </S.SummaryContainer>
  );
};
