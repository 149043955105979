import React from 'react';

import Content from '@components/pages/Dashboard/Payouts';
import { NewPayoutProvider } from '@store/common/Payout/NewPayoutContext';
import { GetPayoutsProvider } from '@store/common/Payout/GetPayoutsContext';

const Payout: React.FC = () => {
  return (
    <GetPayoutsProvider>
      <NewPayoutProvider>
        <Content />
      </NewPayoutProvider>
    </GetPayoutsProvider>
  );
};

export default Payout;
