import styled from 'styled-components/macro';

import ButtonBase from '@components/common/Inputs/Button';
import InputGroupBase from '@components/common/Inputs/InputGroup';
import ImageBase from '@components/common/DataDisplay/Image';
import LabelBase from '@components/common/DataDisplay/Label';
import LinkBase from '@components/common/Navigation/Link';
import TextFieldBase from '@components/common/Inputs/TextField';
import TextBase from '@components/common/DataDisplay/Text';

export const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.neutral.white};
  padding: 40px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  max-width: 380px;
  width: 100%;
`;

export const Logo = styled(ImageBase)`
  max-width: 142px;
  margin: 0 auto;
  margin-bottom: 24px;
  display: block;
`;

export const Box = styled.div`
  text-align: center;
  margin-bottom: 24px;
  max-width: 380px;
`;

export const BoxDescription = styled(TextBase)`
  font-size: 1.6rem;
  font-weight: 500;
`;

export const Link = styled(LinkBase)<{ isLinkForm?: boolean }>`
  color: ${({ theme, isLinkForm }) =>
    isLinkForm ? theme.colors.primary.dark_green_60 : theme.colors.neutral.darkest};
  font-size: 1.4rem;
  font-weight: inherit;
  text-align: end;
  text-decoration: none;
`;

export const SubmitButton = styled(ButtonBase)`
  margin-top: 20px;
`;

export const TextField = styled(TextFieldBase)``;

export const Text = styled(TextBase)`
  text-align: center;
  font-size: 1.3rem;
  color: ${({ theme }) => theme.colors.neutral.darkest};
`;

export const ErrorMessageText = styled(TextBase)``;

export const Label = styled(LabelBase)``;

export const InputGroup = styled(InputGroupBase)`
  margin-bottom: 16px;
`;
