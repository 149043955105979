import React, { InputHTMLAttributes, forwardRef } from 'react';

import * as S from './styles';

interface ICheckboxNewProps extends InputHTMLAttributes<HTMLInputElement> {
  text?: string;
  label?: JSX.Element;
}

const CheckboxNew = forwardRef<HTMLInputElement, ICheckboxNewProps>(
  ({ label, ...rest }: ICheckboxNewProps, ref) => {
    return (
      <S.Wrapper>
        <S.CheckboxInputWrapper>
          <S.CheckboxInput type="checkbox" {...rest} ref={ref} />
          <S.CheckboxMark />
        </S.CheckboxInputWrapper>
        {label && <S.CheckboxLabelWrapper>{label}</S.CheckboxLabelWrapper>}
      </S.Wrapper>
    );
  },
);

export default CheckboxNew;
