import React from 'react';

import Content from '@components/pages/Dashboard/Domains/GetDomains';
import { GetDomainProvider } from '@store/common/domain/GetDomainContext';

const GetDomains: React.FC = () => {
  return (
    <GetDomainProvider>
      <Content />
    </GetDomainProvider>
  );
};

export default GetDomains;
