import React from 'react';
import { format } from 'date-fns';

import { EHeadingSize } from '@domain/enums/components/common/EHeading';
import { EHeadingWeight } from '@domain/enums/common/components/EHeading';

import { formatCurrency } from '@utils/common/formatCurrency';
import { getCreditCardIssuer } from '@utils/common/paymentMethod';

import { useGetOrder } from '@store/common/order/GetOrder';

import * as S from './styles';

const PaymentCard: React.FC = () => {
  const { order } = useGetOrder();

  const totalDiscountAmount = order?.discounts.reduce((acc, value) => acc + value.amount, 0);

  const transactionId = order?.payment?.processor?.transaction_id || '';
  const transactionDate = format(
    new Date(order?.order.created_at ? order.order.created_at : new Date()),
    "dd/MM/yyyy 'às' HH:mm:ss",
  );

  // const discountAmount = totalDiscountAmount
  //   ? globalCurrency(totalDiscountAmount / 100 || 0, order?.language, order?.order.currency)
  //   : `${getCurrencySymbol(order?.order.currency || 'USD')}`;
  const discountAmount = totalDiscountAmount ? totalDiscountAmount / 100 : 0;
  const discountAmountFormatted = formatCurrency(
    discountAmount,
    order?.language,
    order?.order.currency,
  );

  const shippingAmount = order?.shippings[0]?.amount ? order?.shippings[0]?.amount / 100 : 0;
  const shippingAmountFormatted = formatCurrency(
    shippingAmount,
    order?.language,
    order?.order.currency,
  );

  // const shippingAmount = order?.shippings[0]?.amount
  //   ? globalCurrency(order?.shippings[0]?.amount / 100 || 0, order?.language, order?.order.currency)
  //   : `${getCurrencySymbol(order?.order.currency || 'USD')}`;

  const cardIssuer = order?.card?.brand || '';
  const cardLastFourDigits = order?.card?.last_digits;
  const transactionOwnerName = order?.customer.name;

  const getTotalValue = React.useCallback(() => {
    if (!order) return '';

    return formatCurrency(
      order?.order.amount_total / 100 || 0,
      order?.language,
      order?.order.currency,
    );
  }, [order]);

  const getPaymentValue = React.useCallback(() => {
    const cardIssuerIcon = getCreditCardIssuer[cardIssuer];

    return (
      <S.PaymentMethodText>
        <S.PaymentMethodIcon src={cardIssuerIcon} />
        (Crédito) **** {cardLastFourDigits}
      </S.PaymentMethodText>
    );
  }, [cardIssuer, cardLastFourDigits]);

  return (
    <S.Wrapper>
      <S.TitleWrapper>
        <S.TitleAndIdWrapper>
          <S.Title size={EHeadingSize.H5} fontWeight={EHeadingWeight.BOLD}>
            Pagamento
          </S.Title>

          <S.IdText>#{transactionId}</S.IdText>
        </S.TitleAndIdWrapper>

        <S.DateText>{transactionDate}</S.DateText>
      </S.TitleWrapper>

      <S.Divider />

      <S.LabelAndValue>
        <S.Label>Forma de Pagamento</S.Label>
        {getPaymentValue()}
      </S.LabelAndValue>

      <S.DetailPayment>
        <S.LabelAndValue>
          <S.Label>Titular</S.Label>
          <S.Value>{transactionOwnerName}</S.Value>
        </S.LabelAndValue>

        <S.LabelAndValue>
          <S.Label>Frete</S.Label>
          <S.Value>{shippingAmountFormatted}</S.Value>
        </S.LabelAndValue>

        <S.LabelAndValue>
          <S.Label>Desconto</S.Label>
          <S.Value>{discountAmountFormatted}</S.Value>
        </S.LabelAndValue>

        <S.LabelAndValue>
          <S.Label>Total</S.Label>
          <S.Value>{getTotalValue()}</S.Value>
        </S.LabelAndValue>
      </S.DetailPayment>
    </S.Wrapper>
  );
};

export default PaymentCard;
