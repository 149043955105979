import styled from 'styled-components/macro';

import ButtonBase from '@components/common/Inputs/Button';
import CheckboxBase from '@components/common/Inputs/CheckboxNew';
import InputGroupBase from '@components/common/Inputs/InputGroup';
import LabelBase from '@components/common/DataDisplay/Label';
import TextBase from '@components/common/DataDisplay/Text';
import TextFieldBase from '@components/common/Inputs/TextField';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;

export const Label = styled(LabelBase)``;

export const Input = styled(TextFieldBase)``;

export const InputGroup = styled(InputGroupBase)`
  padding: 24px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  background-color: ${({ theme }) => theme.colors.neutral.white};
`;

export const Checkbox = styled(CheckboxBase)``;

export const CheckboxGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const CheckboxLabel = styled(LabelBase)`
  margin: 0;
  cursor: pointer;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: flex-end;
`;

export const SubmitButton = styled(ButtonBase)``;

export const CancelButton = styled(ButtonBase)`
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  & > span {
    color: ${({ theme }) => theme.colors.neutral.dark} !important;
  }
`;

export const InputGroupButton = styled.div`
  display: flex;
  gap: 8px;
`;

export const WrapperInputAndTextError = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const MessageError = styled(TextBase)`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.negative.base};
`;
