import React, { TextareaHTMLAttributes, forwardRef } from 'react';

import * as S from './styles';

interface ITextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
}

const TextArea = forwardRef<HTMLTextAreaElement, ITextAreaProps>(
  ({ label, ...rest }: ITextAreaProps, ref): React.ReactElement => {
    return (
      <S.Wrapper>
        {label && <S.Label>{label}</S.Label>}
        <S.TextArea {...rest} ref={ref} />
      </S.Wrapper>
    );
  },
);

export default TextArea;
