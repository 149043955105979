import styled from 'styled-components/macro';
import { DotsThree, Trash } from 'phosphor-react';

import TableBase from '@components/common/utils/Table';
import TextBase from '@components/common/DataDisplay/Text';
import DropdownBase from '@components/common/utils/Dropdown';

export const Table = styled(TableBase)``;

export const Text = styled(TextBase)`
  overflow: hidden;
  font-weight: 500;
  text-overflow: ellipsis;
`;

export const ButtonActionsWrapper = styled.div`
  min-width: 79.5px;
  justify-content: right;
  display: flex;
  gap: 8px;
`;

export const TableAction = styled(Table.Action)`
  min-width: 29px;
  &:hover {
    background-color: ${({ theme }) => theme.colors.neutral.gray_darker};
  }
`;
export const TableDataAction = styled(Table.Data)`
  width: 79px;
  align-self: self-end;
`;

export const DotsThreeIcon = styled(DotsThree)`
  cursor: pointer;
`;

export const TrashIcon = styled(Trash)``;

export const Dropdown = styled(DropdownBase)``;

export const DropdownTrigger = styled(DropdownBase.Trigger)`
  padding: 0 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
`;

export const DropdownContent = styled(DropdownBase.Content)`
  display: flex;
  position: absolute;
  min-width: unset;
  width: 180px;
  flex-direction: column;
  right: -10px;
  padding: 16px;
  gap: ${({ theme }) => theme.spacing.small_8};
  color: ${({ theme }) => theme.colors.neutral.darker};

  & > div:last-child {
    color: ${({ theme }) => theme.colors.negative.base};
  }
`;

export const DropdownItem = styled(DropdownBase.Item)`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.small_8};
  padding: ${({ theme }) => theme.spacing.small_8};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.neutral.gray_white};
  }
`;
