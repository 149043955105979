import React from 'react';

import { NewShippingProvider } from '@store/common/shipping/NewShippingContext';

import Content from '@components/pages/Dashboard/Shipping/NewShipping';

const NewShipping: React.FC = () => {
  return (
    <NewShippingProvider>
      <Content />
    </NewShippingProvider>
  );
};

export default NewShipping;
