import styled from 'styled-components/macro';

import HeaderBase from './Header';

export const Wrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 1200px;
`;

export const Header = styled(HeaderBase)`
  margin-bottom: 24px;
`;
