import React from 'react';
import { useNavigate } from 'react-router-dom';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/common/EHeading';
import { EButtonVariant } from '@domain/enums/components/common/EButton';

import { useStoreOnboarding } from '@store/context/onboarding/store/StoreOnboardingContext';

import onboardingFinished from '@assets/images/common/onboarding/onboarding-finished.png';

import * as S from './styles';

const Finished: React.FC = () => {
  const navigate = useNavigate();
  const { account, checkout } = useStoreOnboarding();

  const onSkipClick = React.useCallback(() => {
    navigate(`/${account?.object?.id}/${checkout?.object.id}/dashboard/home`);
  }, [navigate, account, checkout]);

  const onDemoStoreClick = React.useCallback(() => {
    navigate(`/acc_2x57cai1iu2g07w24e9eaz/dashboard/home`);
  }, [navigate]);

  return (
    <S.Wrapper>
      <S.Image src={onboardingFinished} alt="Zouti Onboarding" />
      <S.Title size={EHeadingSize.H5} fontWeight={EHeadingWeight.MEDIUM}>
        Parabéns! Sua loja foi criada com sucesso.
      </S.Title>

      <S.TitleDescription>
        Agora, a escolha é sua: Pule diretamente para sua nova loja ou explore nossa Loja Demo e
        descubra as possibilidades.
      </S.TitleDescription>

      {/* <S.TitleDescription>
        Explore e configure todas as possibilidades com a sua loja dentro da Zouti.
      </S.TitleDescription> */}

      <S.ButtonsWrapper>
        <S.SkipButton onClick={onSkipClick}>Acessar minha loja</S.SkipButton>
        {/* <S.SubmitButton onClick={onDemoStoreClick}>Ir para loja demo</S.SubmitButton> */}
        {/* <S.SubmitButton onClick={onSkipClick}>Acessar minha loja</S.SubmitButton> */}
      </S.ButtonsWrapper>
    </S.Wrapper>
  );
};

export default Finished;
