import React from 'react';
import { PlusCircle } from 'phosphor-react';
import { useTheme } from 'styled-components/macro';

import { EButtonVariant } from '@domain/enums/components/common/EButton';

import { useGetDomain } from '@store/common/domain/GetDomainContext';
import PageHeader from '@components/common/utils/PageHeader';
import AddDomainModal from '../../AddDomainModal';

import * as S from './styles';

const Header: React.FC = () => {
  const theme = useTheme();

  const { totalResultDomains, setIsOpenAddDomainModal } = useGetDomain();
  const text =
    totalResultDomains > 1 ? `${totalResultDomains} resultados` : `${totalResultDomains} resultado`;

  const onNewButtonClick = (): void => {
    setIsOpenAddDomainModal(state => !state);
  };

  return (
    <S.Wrapper>
      <AddDomainModal />
      <S.TitleAndButtonWrapper>
        <PageHeader
          title="Domínio"
          description="Utilize seu próprio domínio no checkout para aumentar a credibilidade."
          quantityText={text}
        />

        <S.NewDomainButton variant={EButtonVariant.SECONDARY} onClick={onNewButtonClick}>
          <PlusCircle size={20} weight="bold" color={theme.colors.neutral.darker} /> Adicionar
        </S.NewDomainButton>
      </S.TitleAndButtonWrapper>
    </S.Wrapper>
  );
};

export default Header;
