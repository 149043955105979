import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import * as S from './styles';

const SkeletonLoading: React.FC = () => {
  return (
    <S.Wrapper>
      <S.HeaderWrapper>
        <S.Header width="300px" height="25px" />
        <Skeleton width="410px" height="15px" />
      </S.HeaderWrapper>
      <S.FirstCard>
        <Skeleton width="100%" height="100%" />
        <S.WrapperItems>
          <S.ContainerIcon baseColor="#FBFBFB" width="28px" height="28px" />
          <S.Text baseColor="#FBFBFB" width="180px" height="12px" />
          <S.Description baseColor="#FBFBFB" width="350px" height="12px" />
        </S.WrapperItems>
      </S.FirstCard>
      <S.ContainerSecondCard>
        {Array.from({ length: 4 }).map((_, index) => (
          <S.SecondCard key={index.toFixed()}>
            <Skeleton width="100%" height="100%" />
            <S.WrapperItems>
              <S.ContainerIcon baseColor="#FBFBFB" width="28px" height="28px" />
              <S.TextSecondCard baseColor="#FBFBFB" width="100px" height="12px" />
              <S.Description baseColor="#FBFBFB" width="150px" height="12px" />
            </S.WrapperItems>
          </S.SecondCard>
        ))}
      </S.ContainerSecondCard>
    </S.Wrapper>
  );
};
export default SkeletonLoading;
