import styled from 'styled-components/macro';
import { ArrowUpRight, CheckCircle, Storefront, Circle } from 'phosphor-react';

import ButtonBase from '@components/common/Inputs/Button';
import HeadingBase from '@components/common/DataDisplay/Heading';
import LinkBase from '@components/common/Navigation/Link';
import TextBase from '@components/common/DataDisplay/Text';
import TutorialBase from '@components/common/utils/Tutorial';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 24px;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

export const CardFirstStep = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  padding: 24px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  background-color: ${({ theme }) => theme.colors.neutral.white};
`;

export const HeaderCardFirstStep = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  padding-bottom: 16px;
`;

export const HeaderTitle = styled(HeadingBase)`
  font-weight: 600;
`;

export const HeaderIcon = styled(Storefront)`
  color: ${({ theme }) => theme.colors.primary.dark_green_40};
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.primary.lemon_20};
`;

export const WrapperIconAndTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const WrapperLinearProgress = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const WrapperText = styled.div<{ keyPath: string; isEnabledCheckoutRow: boolean }>`
  display: flex;
  flex-direction: column;
  opacity: ${({ isEnabledCheckoutRow, keyPath }) =>
    !isEnabledCheckoutRow && keyPath === 'CHECKOUT_ENABLED_AT' ? 0.4 : 1};
`;

export const CheckCircleIcon = styled(CheckCircle).attrs({
  weight: 'fill',
})`
  min-width: 24px;
  min-height: 24px;
  color: ${({ theme }) => theme.colors.positive.base};
`;

export const Title = styled(TextBase)`
  font-weight: 500;
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.neutral.darkest};
`;

export const Description = styled(TextBase)`
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.neutral.dark};
`;

export const ConnectStoreButton = styled(ButtonBase)``;

export const CardRowFirstStep = styled.div<{ keyPath?: string; isEnabledCheckoutRow?: boolean }>`
  padding: 8px 16px;
  cursor: ${({ isEnabledCheckoutRow, keyPath }) =>
    !isEnabledCheckoutRow && keyPath === 'CHECKOUT_ENABLED_AT' ? 'not-allowed' : 'pointer'};
`;

export const WrapperRows = styled.div`
  display: flex;
  flex-direction: column;

  ${CardRowFirstStep}:hover {
    background-color: ${({ theme }) => theme.colors.neutral.gray_white};
    border-radius: 8px;
  }
`;

export const WrapperRow = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;
`;

export const WrapperRowEnableCheckout = styled.div<{
  keyPath: string;
  isEnabledCheckoutRow: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  pointer-events: ${({ isEnabledCheckoutRow, keyPath }) =>
    !isEnabledCheckoutRow && keyPath === 'CHECKOUT_ENABLED_AT' ? 'none' : 'auto'};
`;

export const WrapperConnectedStore = styled.div<{ isConnectedStore: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  pointer-events: ${({ isConnectedStore }) => (isConnectedStore ? 'none' : 'auto')};
`;

export const WrapperConfigAndArrowIcon = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const Link = styled(LinkBase)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  font-size: 1.6rem;
  font-weight: 500;
  text-decoration: none !important;
`;

export const TextLink = styled(TextBase)`
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.primary.green_lemon};
`;

export const ArrowUpRightIcon = styled(ArrowUpRight).attrs({
  weight: 'bold',
  width: 16,
  height: 16,
})`
  color: ${({ theme }) => theme.colors.primary.green_lemon};
`;

export const CircleIcon = styled(Circle).attrs({})`
  min-width: 24px;
  min-height: 24px;
  color: ${({ theme }) => theme.colors.neutral.lighter};
`;

export const TextStep = styled(TextBase)`
  font-size: 1rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.neutral.darker};
`;

export const Tutorial = styled(TutorialBase)``;
