import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components/macro';
import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDropzone } from 'react-dropzone';
import { Trash, UploadSimple } from 'phosphor-react';
import { EButtonVariant } from '@domain/enums/components/common/EButton';
import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/common/EHeading';
import {
  EHeaderCustomizationTimerUnitOfTime,
  ICreateCheckoutCustomizationData,
} from '@domain/interfaces/hooks/services/checkoutCustomization/ICheckoutCustomizationService';

import { useEditCheckout } from '@store/common/checkoutCustomization/EditCheckoutCustomizationContext';
import headerCheckoutCustomizationSchema from '@helpers/validators/pages/dashboard/checkoutCustomizations/headerCheckoutCustomizationSchema';

import Tooltip from '@components/pages/Dashboard/CheckoutCustomization/Tooltip';

import * as S from './styles';

const HeaderOttawa: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { accountId, checkoutId } = useParams();
  const {
    updateCurrentCheckout,
    logoImage,
    deleteUploadCheckout,
    fileSizeKB,
    checkoutCustomization,
    setCheckoutCustomization,
    isUpdateCheckout,
    handleImageChange,
    responseUploadLogo,
    selectedThemeCheckout,
  } = useEditCheckout();

  const {
    id,
    boleto_discount_type,
    credit_card_discount_type,
    pix_discount_type,
    name,
    allow_credit_card,
    allow_pix,
    allow_boleto,
    credit_card_discount_amount,
    pix_discount_amount,
    boleto_discount_amount,
    show_coupon_input,
    enable_itens_quantity_change,
    header_customization,
    footer_customization,
    appearance_customization,
    advanced_customization,
    show_time_shipping_estimate,
  } = checkoutCustomization;

  const { register, reset, watch, handleSubmit, setValue } = useForm({
    resolver: yupResolver(headerCheckoutCustomizationSchema),
  });

  const onSubmit = async (formData: FieldValues): Promise<void> => {
    const data: ICreateCheckoutCustomizationData = {
      name,
      allow_credit_card,
      allow_pix,
      allow_boleto,
      credit_card_discount_amount,
      pix_discount_amount,
      boleto_discount_amount,
      credit_card_discount_type,
      pix_discount_type,
      boleto_discount_type,
      enable_itens_quantity_change,
      show_coupon_input,
      show_time_shipping_estimate,
      header_customization: {
        background_hex_color: formData.background_hex_color,
        name_hex_color: formData.name_hex_color,
        secure_hex_color: formData.secure_hex_color,
        timer_background_hex_color: formData.timer_background_hex_color,
        timer_text_hex_color: formData.timer_text_hex_color,
        timer_text: formData.timer_tex,
        show_timer: formData.show_timer,
        timer_unit_of_time: EHeaderCustomizationTimerUnitOfTime.MINUTES,
        timer_amount: header_customization?.timer_amount,
        logo_path: responseUploadLogo.path,
        logo_url: responseUploadLogo.url,
        id,
      },
      footer_customization: { ...footer_customization },
      appearance_customization: { ...appearance_customization },
      advanced_customization: { ...advanced_customization },
      checkout_theme: { theme: selectedThemeCheckout?.theme },
    };

    await updateCurrentCheckout({ checkoutCustomizationId: id, payload: data });
  };

  const onCancelButtonClick = (): void => {
    reset();
    navigate(`/${accountId}/${checkoutId}/dashboard/checkout/customizations`);
  };

  register('secure_hex_color', { value: header_customization?.secure_hex_color });
  register('show_timer', { value: header_customization?.show_timer });

  React.useEffect(() => {
    return () => {
      const fieldValues = watch();
      setCheckoutCustomization(prevData => {
        return {
          ...prevData,
          header_customization: {
            background_hex_color: fieldValues.background_hex_color,
            name_hex_color: fieldValues.name_hex_color,
            secure_hex_color: fieldValues.secure_hex_color,
            timer_background_hex_color: fieldValues.timer_background_hex_color,
            timer_text_hex_color: fieldValues.timer_text_hex_color,
            timer_text: fieldValues.timer_text,
            show_timer: fieldValues.show_timer,
            timer_unit_of_time: fieldValues.timer_unit_of_time,
            timer_amount: prevData?.header_customization?.timer_amount,
            logo_path: responseUploadLogo.path,
            logo_url: responseUploadLogo.url,
            id,
          },
        };
      });
    };
  }, [setCheckoutCustomization, responseUploadLogo, watch, id]);

  const onDrop = React.useCallback(
    (accFiles: File[]) => {
      const mappedAcc = accFiles.map(file => ({
        file,
        size: file.size,
        name: file.name,
        errors: [],
        preview: URL.createObjectURL(file),
      }));
      handleImageChange({ mappedAcc, type: 'logo' });
    },
    [handleImageChange],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    maxSize: 104857600,
  });

  const onDeleteLogo = (path: string): void => {
    deleteUploadCheckout(path, 'logo');
  };

  return (
    <S.Form onSubmit={handleSubmit(onSubmit)}>
      <S.Wrapper>
        <S.ColorSection>
          <S.Title size={EHeadingSize.H4} fontWeight={EHeadingWeight.SEMI_BOLD}>
            Cores
          </S.Title>
          <S.InputColorWrapper>
            <S.InputColor
              {...register('background_hex_color', {
                value: header_customization?.background_hex_color,
              })}
              value={header_customization?.background_hex_color}
              onChange={event => {
                setValue('background_hex_color', event.target.value);
              }}
              placeholder="Digite o código da cor"
            >
              {/* <Tooltip url="https://images.freeimages.com/images/small-previews/aed/three-bees-on-sunflower-1337029.jpg"> */}
              Cor de fundo do cabeçalho
              {/* </Tooltip> */}
            </S.InputColor>

            <S.InputColor
              {...register('name_hex_color', { value: header_customization?.name_hex_color })}
              value={header_customization?.name_hex_color}
              onChange={event => {
                setValue('name_hex_color', event.target.value);
              }}
              placeholder="Digite o código da cor"
            >
              {/* <Tooltip url="https://images.freeimages.com/images/small-previews/aed/three-bees-on-sunflower-1337029.jpg"> */}
              Cor do nome da loja
              {/* </Tooltip> */}
            </S.InputColor>
          </S.InputColorWrapper>
        </S.ColorSection>
        <S.Divider />
        <S.LogoSection>
          <S.Title size={EHeadingSize.H4} fontWeight={EHeadingWeight.SEMI_BOLD}>
            Logo
          </S.Title>
          {logoImage?.length === 0 ? (
            <>
              <S.ContainerDropzone {...getRootProps()}>
                <input {...getInputProps()} />
                <UploadSimple weight="bold" size={13} />
                <S.TextDropzone>Arraste e solte seu arquivo aqui</S.TextDropzone>
                <S.AcceptFormat>JPEG ou PNG</S.AcceptFormat>
              </S.ContainerDropzone>
              <S.TextInfoAndTooltipWrapper>
                <S.TextInfo>Resolução recomendada: 300x90px.</S.TextInfo>
                {/* <Tooltip
                  iconColor={theme.colors.neutral.dark}
                  iconWeight="regular"
                  url="https://images.freeimages.com/images/small-previews/aed/three-bees-on-sunflower-1337029.jpg"
                /> */}
              </S.TextInfoAndTooltipWrapper>
            </>
          ) : (
            logoImage?.map((item, index) => (
              <>
                <S.PreviewDropzone key={index.toFixed()}>
                  <S.Image src={item.preview} alt="Preview logo" />
                </S.PreviewDropzone>
                <S.PreviewCardInfo key={index.toFixed()}>
                  <S.FileName>{item?.file?.name || 'logo'}</S.FileName>
                  <S.FileSize>{fileSizeKB}KB de 2MB</S.FileSize>
                  <Trash onClick={() => onDeleteLogo(responseUploadLogo.path)} size={28} />
                </S.PreviewCardInfo>
              </>
            ))
          )}
        </S.LogoSection>
      </S.Wrapper>
      <S.ButtonsWrapper>
        <S.CancelButton
          onClick={onCancelButtonClick}
          type="button"
          variant={EButtonVariant.SECONDARY}
        >
          Cancelar
        </S.CancelButton>
        <S.SubmitButton type="submit" isLoading={isUpdateCheckout}>
          Salvar
        </S.SubmitButton>
      </S.ButtonsWrapper>
    </S.Form>
  );
};

export default HeaderOttawa;
