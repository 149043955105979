import React from 'react';
import PageHeader from '@components/common/utils/PageHeader';

import * as S from './styles';

const Header: React.FC = () => {
  return (
    <S.Wrapper>
      <PageHeader
        goBackButton
        title="Editar frete"
        description="Crie de forma simples um frete para disponibilizar para seus clientes."
      />
    </S.Wrapper>
  );
};

export default Header;
