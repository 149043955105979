import * as Yup from 'yup';

export const couponSchema = Yup.object().shape({
  amount_type: Yup.string().required('Este campo é obrigatório!'),
  amount: Yup.string(),
  code: Yup.string().required('Este campo é obrigatório!'),
  description: Yup.string(),
  end_at: Yup.string(),
  free_shipment: Yup.boolean().required('Este campo é obrigatório!'),
  max_quantity: Yup.string().required('Este campo é obrigatório!'),
  min_value: Yup.string().required('Este campo é obrigatório!'),
  product_ids: Yup.array(Yup.string()),
  start_at: Yup.string(),
  type: Yup.string(),
});
