import styled from 'styled-components/macro';

import ButtonBase from '@components/common/Inputs/Button';
import TextBase from '@components/common/DataDisplay/Text';

export const Wrapper = styled.div`
  width: 100%;
`;

export const Card = styled.div`
  padding: 16px;
  border-radius: 4px;
  margin-bottom: 85px;
  margin-top: 16px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
`;

export const Title = styled(TextBase)`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.neutral.dark};
`;

export const Divider = styled.hr`
  height: 1px;
  margin: 16px 0;
  background-color: ${({ theme }) => theme.colors.neutral.lightest};
`;

export const TextGroup = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TextLabel = styled(TextBase)`
  font-weight: 500;
`;
export const TextValue = styled(TextBase)`
  font-weight: 500;
`;

export const AmountReceivedTextValue = styled(TextBase)`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.positive.base};
`;

export const ModalAFooter = styled.div`
  display: flex;
  padding: 16px 32px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  align-self: flex-end;
  gap: 16px;
  background-color: ${({ theme }) => theme.colors.neutral.gray_dark};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

export const ButtonsAction = styled.div`
  display: flex;
  position: relative;
  gap: 16px;
  margin-left: auto;
`;

export const SaveButton = styled(ButtonBase)``;

export const CancelButton = styled(ButtonBase)`
  background-color: ${({ theme }) => theme.colors.neutral.white};
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  > span {
    color: ${({ theme }) => theme.colors.neutral.darker};
  }
`;
