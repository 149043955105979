import styled from 'styled-components/macro';

import TableBase from '@components/common/utils/Table';
import SwitchBase from '@components/common/Inputs/Switch';
import TextBase from '@components/common/DataDisplay/Text';

export const ButtonActionsWrapper = styled.div`
  width: 56px;
  justify-content: right;
  display: flex;
  gap: 8px;
`;

export const TableAction = styled(TableBase.Action)``;

export const TableData = styled(TableBase.Data)``;

export const TableRow = styled(TableBase.Row)`
  & > td:first-child {
    width: 42px;
  }

  & > td:nth-child(3) {
    width: 256px;
  }

  & > td:last-child {
    width: 56px;
  }
`;

export const Switch = styled(SwitchBase)``;

export const Text = styled(TextBase)`
  color: ${({ theme }) => theme.colors.neutral.darkest};
  font-size: 1.4rem;
`;
