import styled from 'styled-components/macro';

import HeadingBase from '@components/common/DataDisplay/Heading';
import TextBase from '@components/common/DataDisplay/Text';

export const Wrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  padding-bottom: 24px;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
`;

export const Description = styled(TextBase)``;

export const TitleAndSubtitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Subtitle = styled(TextBase)``;

export const Title = styled(HeadingBase)``;
