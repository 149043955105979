import React from 'react';
import { useTheme } from 'styled-components/macro';

import noProduct from '@assets/images/common/no-product.svg';

import { useNewOrderBump } from '@store/common/orderBump/NewOrderBumpContext';
import { useUserAccount } from '@store/context/common/UserAccount';

import { formatCurrency } from '@utils/common/formatCurrency';
import { currencyToNumber } from '@helpers/utils/common/number';

import * as S from './styles';

const Preview: React.FC = () => {
  const theme = useTheme();
  const { currentUserAccount } = useUserAccount();
  const {
    selectedPrimaryColor,
    selectedTextColor,
    watch,
    selectedOfferProduct,
    hasSelectedAllVariants,
  } = useNewOrderBump();

  const [priceValue, promotionalPriceValue, ctaTextValue, titleValue, descriptionValue] = watch([
    'price',
    'promotional_price',
    'cta_text',
    'title',
    'description',
  ]);

  const [isDesktopVersion, setIsDesktopVersion] = React.useState<boolean>(true);

  const variantsOptions =
    selectedOfferProduct?.variants && hasSelectedAllVariants ? selectedOfferProduct.variants : [];

  const priceAmount = currencyToNumber(priceValue);
  const promotionalPriceAmount = currencyToNumber(promotionalPriceValue);
  const priceAmountFormatted = formatCurrency(
    priceAmount,
    currentUserAccount?.language,
    currentUserAccount?.currency,
  );
  const promotionalPriceAmountFormatted = formatCurrency(
    promotionalPriceAmount,
    currentUserAccount?.language,
    currentUserAccount?.currency,
  );

  return (
    <S.PreviewModel style={{ width: isDesktopVersion ? '100%' : '60%' }}>
      <S.TitleArea>
        <S.TitleAreaText>
          <h2>Preview do order bump</h2>
        </S.TitleAreaText>
        <S.IconsArea>
          <S.DesktopIcon
            size={25}
            color={theme.colors.neutral.darker}
            onClick={() => setIsDesktopVersion(true)}
          />
          <S.MobileIcon
            size={25}
            color={theme.colors.neutral.darker}
            onClick={() => setIsDesktopVersion(false)}
          />
        </S.IconsArea>
      </S.TitleArea>

      <S.PreviewDataShow color={selectedPrimaryColor}>
        <S.PreviewHead>
          <S.TitleHead color={selectedPrimaryColor}>{titleValue}</S.TitleHead>

          <S.SubTitleHead color={selectedTextColor}>{descriptionValue}</S.SubTitleHead>
        </S.PreviewHead>
        <S.PreviewBody>
          <S.PreviewProduct>
            <S.ProductInfo>
              {selectedOfferProduct && selectedOfferProduct.images.length > 0 ? (
                <S.ImageProduct
                  src={selectedOfferProduct?.images[0].url}
                  alt="Order Bump Product"
                />
              ) : (
                <S.ImageProduct src={noProduct} alt="No Product" />
              )}

              <S.InfoProductDetails>
                <S.ProductName color={selectedTextColor}>
                  {selectedOfferProduct?.product.name || 'Nome do produto'}
                </S.ProductName>

                <S.Priced>
                  <S.PriceProduct color={selectedTextColor}>{priceAmountFormatted}</S.PriceProduct>
                  <S.EndPrice color={selectedTextColor}>
                    {promotionalPriceAmountFormatted}
                  </S.EndPrice>
                </S.Priced>
              </S.InfoProductDetails>
            </S.ProductInfo>

            {variantsOptions.length > 0 && (
              <S.Select>
                {variantsOptions.map(variant => (
                  <S.Option value={variant.id} key={variant.id}>
                    {variant.name}
                  </S.Option>
                ))}
              </S.Select>
            )}

            <S.CtaAreaPreview color={selectedPrimaryColor}>
              <S.Checkbox type="checkbox" />

              <S.CtaText color={selectedPrimaryColor}>{ctaTextValue}</S.CtaText>
            </S.CtaAreaPreview>
          </S.PreviewProduct>
        </S.PreviewBody>
      </S.PreviewDataShow>
    </S.PreviewModel>
  );
};

export default Preview;
