import styled from 'styled-components/macro';

import LabelBase from '@components/common/DataDisplay/Label';
import TextFieldBase from '@components/common/Inputs/TextField';
import InputGroupBase from '@components/common/Inputs/InputGroup';
import SelectBase from '@components/common/Inputs/Select';
import OptionBase from '@components/common/Inputs/Select/Option';
import InputSelectImageBase from '@components/common/Inputs/InputSelectImage';
import ButtonBase from '@components/common/Inputs/Button';

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  gap: 24px;
  background-color: #fff;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  padding: 24px;
  gap: 24px;
`;

export const Label = styled(LabelBase)`
  margin: 0;
  cursor: pointer;
`;

export const InputGroup = styled(InputGroupBase)`
  gap: 8px;
  position: relative;
`;
export const TextField = styled(TextFieldBase)`
  flex-grow: 1;
`;

export const Select = styled(SelectBase)`
  width: 100%;
`;

export const Option = styled(OptionBase)`
  background-color: red;
`;

export const Selele = styled(InputSelectImageBase)``;

export const Button = styled(ButtonBase)`
  max-width: 217px;
  align-self: flex-end;
  font-size: 1rem;
  font-weight: 500;
`;

export const span = styled.span`
  font-size: 1.4rem;
  font-weight: 500;
`;
