import React, { useRef } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { IUpdateRecoveryWhatsAppData } from '@domain/interfaces/hooks/services/recoveryTemplate/IRecoveryTemplateService';
import { recoverySchemaWhatsApp } from '@helpers/validators/pages/dashboard/recovery/recoverySchemaWhatsApp';
import { useUpdateRecoveryWhatsApp } from '@store/common/recovery/UpdateRecoveryTemplateWhatsAppContext';

import { yupResolver } from '@hookform/resolvers/yup';

import SkeletonLoading from '@components/pages/Dashboard/recovery/Tabs/SkeletonLoading';
import { EHeadingSize } from '@domain/enums/components/common/EHeading';
import TextAreaEditor from '../../TextAreaEditor';

import * as S from './styles';

const WhatsApp: React.FC = () => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const insertedTextRef = useRef<string>('');

  const [countStringLength, setCountStringLength] = React.useState<number>(0);
  const {
    recoveryTemplates,
    isLoadingRecoveryTemplates,
    updateCurrentRecoveryWhatsApp,
    isUpdatingRecoveryWhatsApp,
  } = useUpdateRecoveryWhatsApp();

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(recoverySchemaWhatsApp),
  });

  const messageRegister = register('message', { value: recoveryTemplates?.message });

  const onSubmit = async (formData: FieldValues): Promise<void> => {
    const data: IUpdateRecoveryWhatsAppData = {
      message: textAreaRef.current?.value || formData.message,
    };
    await updateCurrentRecoveryWhatsApp({
      templateId: recoveryTemplates?.id || '',
      payload: data,
    });
  };

  React.useEffect(() => {
    setValue('message', recoveryTemplates?.message);
    setCountStringLength(recoveryTemplates?.message?.length || 0);
  }, [setValue, recoveryTemplates]);

  return (
    <>
      {isLoadingRecoveryTemplates ? (
        <SkeletonLoading />
      ) : (
        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <S.Wrapper>
            <S.Title size={EHeadingSize.H4}>Mensagem de envio manual WhatsApp</S.Title>
            <TextAreaEditor
              {...messageRegister}
              maxLength={110}
              maxCount={110}
              errorMessage={errors.message && errors?.message?.message?.toString()}
              countCharacters={countStringLength}
              label="Mensagem"
              onChange={event => {
                setValue('message', event.target.value);
                setCountStringLength(event.target.value.length);
              }}
              defaultValue={recoveryTemplates?.message}
              insertedTextRef={insertedTextRef}
              textAreaRef={textAreaRef}
            />
          </S.Wrapper>
          <S.SubmitButton isLoading={isUpdatingRecoveryWhatsApp} type="submit">
            Salvar
          </S.SubmitButton>
        </S.Form>
      )}
    </>
  );
};

export default WhatsApp;
