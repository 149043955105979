import React from 'react';

import { useTheme } from 'styled-components';
import { MagnifyingGlass, X as ClearValueIcon } from 'phosphor-react';

import { ESearchInputSize } from '@domain/enums/components/common/ESearchInput';

import * as S from './styles';

interface ISearchInputProps {
  name?: string;
  placeholder?: string;
  disabled?: boolean;
  size?: ESearchInputSize;
  value?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  onClear?: () => void;
}

const SearchInput: React.FC<ISearchInputProps> = ({
  name,
  onChange,
  placeholder,
  disabled,
  size = ESearchInputSize.SMALL,
  value,
  onClear,
  ...rest
}) => {
  const theme = useTheme();

  const [isFocused, setIsFocused] = React.useState<boolean>(false);

  const handleInputFocus = React.useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = React.useCallback(() => {
    setIsFocused(false);
  }, []);

  return (
    <S.InputWrapper {...rest} disabled={disabled} size={size} value={value} isFocused={isFocused}>
      <S.Input
        value={value}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        type="text"
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        disabled={disabled}
      />

      {value && (
        <S.ClearValueButton onClick={onClear}>
          <ClearValueIcon size={16} color={theme.colors.neutral.darkest} />
        </S.ClearValueButton>
      )}

      <MagnifyingGlass size={16} color="#515151" />
    </S.InputWrapper>
  );
};

export default SearchInput;
