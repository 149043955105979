import React from 'react';

import { ETypographyWeight } from '@domain/enums/components/common/ETypography';

import { EChipColor } from '@domain/enums/components/common/EChip';

import * as S from './styles';

interface IChipProps {
  backgroundColor: EChipColor | string;
  textColor?: string;
}

const Chip: React.FC<IChipProps> = ({ children, backgroundColor, textColor, ...rest }) => {
  return (
    <S.Wrapper backgroundColor={backgroundColor} {...rest}>
      <S.ChipText size="10px" weight={ETypographyWeight.bold} textColor={textColor}>
        {children}
      </S.ChipText>
    </S.Wrapper>
  );
};

export default Chip;
