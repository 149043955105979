import React from 'react';

import { IButtonProps } from './styles';

import * as S from './styles';

const ActionButton: React.FC<IButtonProps> = ({ children, ...rest }) => {
  return (
    <S.ButtonBase {...rest}>
      <S.Text>{children}</S.Text>
    </S.ButtonBase>
  );
};

export default ActionButton;
