import React from 'react';

import { EHeadingSize } from '@domain/enums/components/common/EHeading';
import { EHeadingWeight } from '@domain/enums/common/components/EHeading';

import { cpfFormatter } from '@helpers/utils/common/cpf';
import { phoneFormatter } from '@utils/common/phone';

import { useGetOrder } from '@store/common/order/GetOrder';
import { useToast } from '@store/context/common/ToastContext';
import { useAnalytics } from '@store/context/common/AnalyticsContext';

import whatsAppIcon from '@assets/images/common/dashboard/orders/whatsapp-icon.svg';

import * as S from './styles';

const CustomerCard: React.FC = () => {
  const { toast } = useToast();
  const { analytics } = useAnalytics();
  const { order } = useGetOrder();
  const dropdownRef = React.useRef<HTMLDivElement>(null);

  const [isDropdownVisible, setDropdownVisible] = React.useState(false);

  const generateWhatsAppLink = React.useCallback(() => {
    const text = JSON.stringify(
      `Olá ${order?.customer.name}! Obrigado por realizar uma compra conosco!`,
    );

    return `https://wa.me/55${order?.customer?.phone}?text=${text}`;
  }, [order]);

  const copyTextToClipboard = async (text: string): Promise<void> => {
    try {
      await navigator.clipboard.writeText(text);
      toast.success('Copiado com sucesso!');
    } catch (err) {
      toast.error('Erro ao copiar!');
    }
  };

  const customerName = order?.customer?.name;
  const customerEmail = order?.customer?.email;
  const customerDocument = cpfFormatter(order?.customer?.document);
  const customerPhone = phoneFormatter(order?.customer?.phone);
  // const customerIp = generateRandomIP();
  const whatsAppLink = generateWhatsAppLink();

  const handleDocumentClick = (event: MouseEvent): void => {
    if (dropdownRef.current && !dropdownRef.current?.contains(event.target as Node)) {
      setDropdownVisible(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener('click', handleDocumentClick);
  }, []);

  return (
    <S.Wrapper>
      <S.Title size={EHeadingSize.H6} fontWeight={EHeadingWeight.BOLD}>
        Cliente
      </S.Title>

      <S.NameAndEmailWrapper>
        <S.LabelAndValue>
          <S.Label>Nome</S.Label>
          <S.Value>{customerName}</S.Value>
        </S.LabelAndValue>
        <S.WrapperDropdownAndEmail>
          <S.LabelAndValue onMouseEnter={() => setDropdownVisible(true)}>
            <S.Label>E-mail</S.Label>
            <S.Value>{customerEmail}</S.Value>
          </S.LabelAndValue>
          {isDropdownVisible && (
            <S.DropdownCard ref={dropdownRef}>
              {customerEmail}
              <S.CopyIcon onClick={() => copyTextToClipboard(customerEmail || '')} />
            </S.DropdownCard>
          )}
        </S.WrapperDropdownAndEmail>
      </S.NameAndEmailWrapper>

      <S.DocumentAndPhoneWrapper>
        <S.LabelAndValue>
          <S.Label>CPF</S.Label>
          <S.Value>{customerDocument}</S.Value>
        </S.LabelAndValue>

        <S.LabelAndValue>
          <S.Label>Telefone</S.Label>
          <S.Value>{customerPhone}</S.Value>
        </S.LabelAndValue>
      </S.DocumentAndPhoneWrapper>

      <S.LabelAndSendMessage>
        {/* <S.LabelAndValue>
          <S.Label>IP da compra</S.Label>
          <S.Value>{customerIp}</S.Value>
        </S.LabelAndValue> */}

        <S.SendMessage
          to={whatsAppLink}
          target="_blank"
          onClick={() => analytics.track('Order Send Whatsapp Message Clicked')}
        >
          <S.WhatsappLogo src={whatsAppIcon} alt="Zouti Whatsapp" />
          Entrar em contato
        </S.SendMessage>
      </S.LabelAndSendMessage>
    </S.Wrapper>
  );
};

export default CustomerCard;
