import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { IVariant } from '@domain/interfaces/common/variant/IVariant';
import { INewOrderBumpProvider } from '@domain/interfaces/store/common/orderBump/INewOrderBumpProvider';
import {
  IOrderBumpOfferProduct,
  IOrderBumpTriggerProduct,
} from '@domain/interfaces/common/orderBump/IOrderBump';

import { orderBumpSchema } from '@helpers/validators/pages/dashboard/orderBump/orderBumpSchema';

import { useErrorHandler } from '@store/context/common/ErrorHandlerContext';
import { useOrderBumpService } from '@hooks/services/orderBump/useOrderBumpService';
import { useAnalytics } from '@store/context/common/AnalyticsContext';

const NewOrderBumpContext = React.createContext<INewOrderBumpProvider | null>(null);

export const NewOrderBumpProvider: React.FC = ({ children }) => {
  const navigate = useNavigate();
  const { analytics } = useAnalytics();
  const { handleError } = useErrorHandler();
  const { accountId, checkoutId } = useParams();
  const { createOrderBump } = useOrderBumpService();

  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(orderBumpSchema),
    defaultValues: {
      name: '',
      price: '',
      promotional_price: '',
      cta_text: 'Texto do CTA',
      description: 'Texto de descrição escolhido para a oferta',
      title: 'Título da Oferta',
    },
  });

  const nameRegister = register('name');
  const priceRegister = register('price');
  const promotionalPriceRegister = register('promotional_price');
  const ctaTextRegister = register('cta_text');
  const titleRegister = register('title');
  const descriptionRegister = register('description');

  const [isCreatingOrderBump, setIsCreatingOrderBump] = React.useState<boolean>(false);
  const [selectedOfferProduct, setSelectedOfferProduct] = React.useState<
    IOrderBumpOfferProduct | undefined
  >(undefined);
  const [selectedTriggerProduct, setSelectedTriggerProduct] = React.useState<
    IOrderBumpTriggerProduct | undefined
  >(undefined);
  const [selectedVariant, setSelectedVariant] = React.useState<IVariant | undefined>(undefined);
  const [selectedPrimaryColor, setSelectedPrimaryColor] = React.useState<string>('#3F198C');
  const [selectedTextColor, setSelectedTextColor] = React.useState<string>('#282828');
  const [hasSelectedAllVariants, setHasSelectedAllVariants] = React.useState<boolean>(false);

  const handleSelectedOfferProduct = React.useCallback(
    offerProduct => setSelectedOfferProduct(offerProduct),
    [],
  );

  const handleSelectedTriggerProduct = React.useCallback(
    triggerProduct => setSelectedTriggerProduct(triggerProduct),
    [],
  );

  const handleSelectedVariant = React.useCallback(variant => {
    if (variant === 'all') {
      setHasSelectedAllVariants(true);
      setSelectedVariant(undefined);
    } else {
      setHasSelectedAllVariants(false);
      setSelectedVariant(variant);
    }
  }, []);

  const handleSelectedPrimaryColor = React.useCallback(
    primaryColor => setSelectedPrimaryColor(primaryColor),
    [],
  );

  const handleSelectedTextColor = React.useCallback(
    textColor => setSelectedTextColor(textColor),
    [],
  );

  const createNewOrderBump = React.useCallback(
    async data => {
      setIsCreatingOrderBump(true);

      try {
        await createOrderBump({ payload: data, accountId, checkoutId });

        analytics.track('Order Bump Created');

        setIsCreatingOrderBump(false);

        navigate(`/${accountId}/${checkoutId}/dashboard/marketing/order-bumps`);
      } catch (responseError: any) {
        setIsCreatingOrderBump(false);
        handleError(responseError);
      }
    },
    [createOrderBump, handleError, navigate, accountId, checkoutId, analytics],
  );

  return (
    <NewOrderBumpContext.Provider
      value={{
        createNewOrderBump,
        isCreatingOrderBump,
        handleSelectedOfferProduct,
        handleSelectedTriggerProduct,
        handleSelectedVariant,
        selectedOfferProduct,
        selectedTriggerProduct,
        selectedVariant,
        ctaTextRegister,
        descriptionRegister,
        handleSubmit,
        nameRegister,
        priceRegister,
        promotionalPriceRegister,
        titleRegister,
        watch,
        handleSelectedPrimaryColor,
        handleSelectedTextColor,
        selectedPrimaryColor,
        selectedTextColor,
        hasSelectedAllVariants,
        errors,
      }}
    >
      {children}
    </NewOrderBumpContext.Provider>
  );
};

export const useNewOrderBump = (): INewOrderBumpProvider => {
  const context = React.useContext(NewOrderBumpContext);

  if (!context) {
    throw new Error('useNewOrderBump must be used within provider');
  }

  return context;
};
