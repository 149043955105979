import React from 'react';

import { DatePickerProvider } from '@store/context/components/DatePickerContext';

import Input from './Input';

interface IDatePickerProps {
  startDate: Date;
  endDate: Date;
  onChange: (dates: [Date, Date]) => void;
  showCalendarIcon?: boolean;
  isShowPreDefinedDate?: boolean;
  isDisabledDaysAfterToday?: boolean;
}

const DatePicker: React.FC<IDatePickerProps> = ({
  startDate,
  endDate,
  onChange,
  isShowPreDefinedDate,
  isDisabledDaysAfterToday,
  ...rest
}) => {
  return (
    <DatePickerProvider startDate={startDate} endDate={endDate} onChange={onChange}>
      <Input
        isDisabledDaysAfterToday={isDisabledDaysAfterToday}
        isShowPreDefinedDate={isShowPreDefinedDate}
        {...rest}
      />
    </DatePickerProvider>
  );
};

export default DatePicker;
