import React from 'react';
import { format } from 'date-fns';
import { PencilSimple, Trash } from 'phosphor-react';
import { useTheme } from 'styled-components/macro';
import { useNavigate, useParams } from 'react-router-dom';

import { IWebhook } from '@domain/interfaces/common/webhook/IWebhook';

import { useListWebhooks } from '@store/common/webhook/ListWebhooksContext';

import * as S from './styles';

interface IRowProps {
  webhook: IWebhook;
}

const Row: React.FC<IRowProps> = ({ webhook }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { deleteSelectedWebhook } = useListWebhooks();
  const { accountId, checkoutId } = useParams();

  const [isHoveringRow, setIsHoveringRow] = React.useState<boolean>(false);

  const onMouseEnter = React.useCallback(() => setIsHoveringRow(true), []);

  const onMouseLeave = React.useCallback(() => setIsHoveringRow(false), []);

  const onEditClick = React.useCallback(() => {
    navigate(`/${accountId}/${checkoutId}/dashboard/apps/webhooks/${webhook.id}`);
  }, [navigate, webhook, accountId, checkoutId]);

  const webhookName = webhook.name;
  const webhookCreatedAt = format(new Date(webhook.created_at), 'dd/MM/yyyy');

  return (
    <S.TableRow borderBottom onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <S.TableData />
      <S.TableData>{webhookName}</S.TableData>

      <S.TableData>{webhookCreatedAt}</S.TableData>

      <S.TableData>
        {isHoveringRow ? (
          <S.ButtonActionsWrapper>
            <S.TableAction onClick={onEditClick}>
              <PencilSimple size={18} color={theme.colors.neutral.darker} weight="bold" />
            </S.TableAction>
            <S.TableAction onClick={() => deleteSelectedWebhook(webhook.id)}>
              <Trash size={18} color={theme.colors.neutral.darker} weight="bold" />
            </S.TableAction>
          </S.ButtonActionsWrapper>
        ) : (
          <S.ButtonActionsWrapper />
        )}
      </S.TableData>
    </S.TableRow>
  );
};

export default Row;
