import styled from 'styled-components/macro';

import ButtonBase from '@components/common/Inputs/Button';
import HeadingBase from '@components/common/DataDisplay/Heading';

export const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 24px;
`;

export const TitleAndButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const InviteUserButton = styled(ButtonBase)`
  & > span {
    font-size: 1.4rem;
    color: ${({ theme }) => theme.colors.neutral.darker} !important;
  }
`;

export const CreateGroupButton = styled(InviteUserButton)``;

export const Title = styled(HeadingBase)``;
