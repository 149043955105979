import React from 'react';
import useSWR from 'swr';
import { useParams } from 'react-router-dom';

import { ISwr } from '@domain/interfaces/common/swr/ISwr';
import { IUsersProvider } from '@domain/interfaces/store/common/users/IUsersProvider';
import { IListUsersInvitedResponse } from '@domain/interfaces/hooks/services/users/IUsersService';

import { DASHBOARD_API_URL } from '@constants/services/apiUrl';
import { dashboardInstance } from '@services/common/dashboardInstance';

import { useErrorHandler } from '@store/context/common/ErrorHandlerContext';
import { useToast } from '@store/context/common/ToastContext';
import { useUsersService } from '@hooks/services/users/useUsersService';
import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { inviteUsersSchema } from '@helpers/validators/pages/dashboard/users/inviteUsersSchema';
import { IMembership } from '@domain/interfaces/common/users/IMembership';
import { useAnalytics } from '@store/context/common/AnalyticsContext';

const UsersContext = React.createContext<IUsersProvider | null>(null);

export interface IUsersProps {
  id: string;
  name: string;
  email: string;
  group_name: string;
}

interface IGroupsProps {
  id: string;
  name: string;
  qtdUsers: number;
}

export const UsersProvider: React.FC = ({ children }) => {
  const { handleError } = useErrorHandler();
  const { accountId } = useParams();
  const { analytics } = useAnalytics();
  const { toast } = useToast();
  const { inviteUsers, deleteUserInvited } = useUsersService();

  const [users, setUsers] = React.useState<IUsersProps[]>([]);
  const [email, setEmail] = React.useState<string[]>([]);
  const [isOpenModal, setIsOpenModal] = React.useState<boolean>(false);
  const [isDeleteUserLoading, setIsDeleteUserLoading] = React.useState<boolean>(false);
  const [isOpenDeleteModal, setIsOpenDeleteModel] = React.useState<boolean>(false);
  const [deleteUserData, setDeleteUserData] = React.useState<{
    userName: string;
    membershipId: string;
  }>({
    userName: '',
    membershipId: '',
  });
  const [groups, setGroups] = React.useState<IGroupsProps[]>([]);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [inviteUserIsLoading, setInviteUserIsLoading] = React.useState(false);

  const GET_MEMBERSHIP_URL = `${DASHBOARD_API_URL}/accounts/${accountId}/memberships`;
  const GET_MEMBERSHIP_GROUPS_URL = `${DASHBOARD_API_URL}/accounts/${accountId}/membership_groups`;

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(inviteUsersSchema),
  });

  const {
    data: dataGroup,
    isLoading: isLoadingGroup,
    isValidating: isValidatingGroup,
    error: errorGroup,
  } = useSWR<ISwr>(GET_MEMBERSHIP_GROUPS_URL, dashboardInstance);

  const { data, isLoading, isValidating, mutate, error } = useSWR<ISwr<IListUsersInvitedResponse>>(
    GET_MEMBERSHIP_URL,
    dashboardInstance,
  );

  React.useEffect(() => {
    if (error) {
      handleError(error);
    }

    if (errorGroup) {
      handleError(errorGroup);
    }

    if (data?.data?.object) {
      const newUsers = data.data.object.map((item: IMembership) => ({
        id: item?.id,
        name: item.user?.name,
        email: item.user?.email,
        group_name: item.membership_group.name,
        // last_login: item.user?.last_login,
      }));

      setUsers(newUsers);
    }

    if (dataGroup?.data?.object) {
      const newGroups = dataGroup.data.object.map((item: any) => ({
        id: item?.id,
        name: item?.name,
        qtdUsers: item?.users?.length,
      }));
      setGroups(newGroups);
    }
  }, [data, handleError, error, dataGroup, errorGroup]);

  const onSubmit = React.useCallback(
    async (formData: FieldValues): Promise<void> => {
      const payload = {
        emails: email,
      };

      if (email.length === 0) {
        toast.error('Pelo menos um e-mail deve ser fornecido.');
        return;
      }

      setInviteUserIsLoading(true);

      try {
        await inviteUsers({ accountId, membershipGroupId: formData.membership_group_id, payload });
        toast.success('Convite enviado com sucesso!');
        setIsOpenModal(false);
        setEmail([]);
        setInviteUserIsLoading(false);
        reset();
      } catch (errorInvite) {
        handleError(errorInvite);
        setInviteUserIsLoading(false);
      }
    },
    [inviteUsers, accountId, handleError, toast, email, reset],
  );

  const deleteCurrentUser = React.useCallback(async () => {
    setIsDeleteUserLoading(true);

    try {
      await deleteUserInvited({ accountId, membershipId: deleteUserData?.membershipId });

      analytics.track('Has Deleted user from group');

      toast.success('Usuário removido com sucesso!');
      await mutate();
      setIsDeleteUserLoading(false);
      setIsOpenDeleteModel(false);
    } catch (errorDeleteGroup) {
      toast.error('Erro ao deletar usuário do grupo!');
      setIsDeleteUserLoading(false);
      handleError(errorDeleteGroup);
    }
  }, [deleteUserInvited, deleteUserData, handleError, mutate, accountId, toast, analytics]);

  const handleOpenModal = (): void => {
    setIsOpenModal(true);
  };

  const onToggleDeleteModel = (): void => {
    setIsOpenDeleteModel(state => !state);
  };

  const isLoadingMemberships = isLoading || isValidating;

  const filteredUsers = React.useMemo(() => {
    return users?.filter(
      user =>
        user.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.group_name?.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  }, [users, searchTerm]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchTerm(event.target.value);
  };

  return (
    <UsersContext.Provider
      value={{
        isLoadingMemberships,
        users: filteredUsers,
        handleSearchChange,
        inviteUserIsLoading,
        setIsOpenDeleteModel,
        isOpenDeleteModal,
        isDeleteUserLoading,
        groups,
        onSubmit,
        isOpenModal,
        setIsOpenModal,
        handleOpenModal,
        onToggleDeleteModel,
        register,
        handleSubmit,
        errors,
        email,
        setEmail,
        deleteUserData,
        setDeleteUserData,
        deleteCurrentUser,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
};

export const useUsersContext = (): IUsersProvider => {
  const context = React.useContext(UsersContext);

  if (!context) {
    throw new Error('useUsersContext must be used within provider');
  }

  return context;
};
