import styled from 'styled-components/macro';

import TableBase from '@components/common/utils/Table';

export const Wrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  height: 100%;
`;

export const Table = styled(TableBase)``;
