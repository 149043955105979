import styled from 'styled-components/macro';
import Skeleton from 'react-loading-skeleton';

import TableBase from '@components/common/utils/Table';

export const Wrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  margin-top: 24px;
`;

export const SwitchSkeleton = styled(Skeleton)`
  border-radius: 16px;
  height: 20px;
  width: 50px;
`;

export const GetCheckoutHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  padding-bottom: 24px;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
`;

export const TitleAndButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  width: 100%;
`;

export const TableWrapper = styled.div`
  max-width: 1024px;
  margin: 0 auto;
`;

export const Table = styled(TableBase)`
  th {
    padding-bottom: 16px !important;
  }
`;
