import styled from 'styled-components/macro';

import TextBase from '@components/common/DataDisplay/Text';

export const ProviderIcon = styled.img`
  width: 24px;
  height: 24px;
`;
export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: ${({ theme }) => theme.borderRadius.small};
  background: ${({ theme }) => theme.colors.neutral.gray_white};
`;

export const ProviderName = styled(TextBase)`
  font-size: 1.6rem;
  font-weight: 500;
`;

export const ProviderOption = styled.button`
  display: flex;
  align-items: center;
  background: unset;
  cursor: pointer;
  flex-direction: row;
  gap: 8px;
  padding: 16px;
  transition: all 0.3s;
  width: 100%;

  &:hover {
    background-color: ${({ theme }) => `${theme.colors.primary.lemon_20}`};
  }
`;
