import styled from 'styled-components/macro';

import ButtonBase from '@components/common/Inputs/Button';

export const ExitButton = styled(ButtonBase)`
  position: absolute;
  top: 24px;
  right: 60px;

  & > span {
    color: ${({ theme }) => theme.colors.neutral.darker};
  }
`;

export const Wrapper = styled.div`
  position: relative;
  max-width: 1024px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 64px 32px;
`;
