import styled from 'styled-components';

import TextBase from '@components/common/DataDisplay/Text';

export const SummaryContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const SummaryRow = styled.div`
  display: flex;
  gap: 8px;
`;

export const SummaryItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const SummaryItemStatus = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Label = styled(TextBase)`
  color: ${({ theme }) => theme.colors.neutral.dark};
  font-size: 1.6rem;
`;

export const Value = styled(TextBase)`
  color: ${({ theme }) => theme.colors.neutral.darkest};
  font-size: 1.6rem;
`;

export const ValueAmount = styled(TextBase)`
  color: ${({ theme }) => theme.colors.neutral.black};
  font-size: 1.8rem;
  font-weight: 700;
`;
