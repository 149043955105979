import React from 'react';
import { PlusCircle } from 'phosphor-react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components/macro';
import PageHeader from '@components/common/utils/PageHeader';

import { EButtonVariant } from '@domain/enums/components/common/EButton';
import { useGetPromocode } from '@store/common/promocode/GetPromocodeContext';

import * as S from './styles';

const Header: React.FC = () => {
  const { totalPromocodes } = useGetPromocode();
  const theme = useTheme();
  const navigate = useNavigate();
  const { accountId, checkoutId } = useParams();

  const pluralText = totalPromocodes > 1 ? 's' : '';
  const quantityText = `${totalPromocodes} resultado${pluralText}`;

  const onNewButtonClick = (): void => {
    navigate(`/${accountId}/${checkoutId}/dashboard/marketing/coupon/new`);
  };

  return (
    <S.Wrapper>
      <PageHeader title="Cupons" description="Gerencie seus cupons" quantityText={quantityText} />

      <S.NewCouponButton variant={EButtonVariant.SECONDARY} onClick={onNewButtonClick}>
        <PlusCircle size={20} weight="bold" color={theme.colors.neutral.darker} /> Criar cupom
      </S.NewCouponButton>
    </S.Wrapper>
  );
};

export default Header;
