import React from 'react';

import { EBusinessModel } from '@domain/enums/common/onboarding/EUser';

import { useUpdateAccountData } from '@store/common/accountData/UpdateAccountData';
import { useUserCheckout } from '@store/context/common/UserCheckout';

import PageHeader from '@components/common/utils/PageHeader';
import Tab from '@components/common/utils/Tab';
import Option from '@components/common/utils/Tab/Option';

import * as S from './styles';

const Header: React.FC = () => {
  const { selectedTab, onChangeTab } = useUpdateAccountData();
  const { currentUserCheckout } = useUserCheckout();

  const showCurrencyAndLanguage =
    currentUserCheckout?.business_type === EBusinessModel.DROPSHIPPING_GLOBAL ||
    currentUserCheckout?.business_type === EBusinessModel.INFOPRODUCT;

  return (
    <S.Wrapper>
      <PageHeader title="Dados do negócio" description="Informações essenciais da sua empresa." />

      {showCurrencyAndLanguage ? (
        <Tab defaultTab={selectedTab} onChangeTab={onChangeTab}>
          <Option>Empresa</Option>
          <Option>Endereço</Option>
          <Option>Idioma e Moeda</Option>
        </Tab>
      ) : (
        <Tab defaultTab={selectedTab} onChangeTab={onChangeTab}>
          <Option>Empresa</Option>
          <Option>Endereço</Option>
        </Tab>
      )}
    </S.Wrapper>
  );
};

export default Header;
