import React from 'react';
import { useTheme } from 'styled-components/macro';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { CreditCard } from 'phosphor-react';
import PIX from '@components/icons/Pix';
import BankSlip from '@components/icons/BankSlip';

import { EButtonVariant } from '@domain/enums/components/common/EButton';
import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/common/EHeading';
import { ICreateCheckoutCustomizationData } from '@domain/interfaces/hooks/services/checkoutCustomization/ICheckoutCustomizationService';

import { useNewCheckout } from '@store/common/checkoutCustomization/NewCheckoutCustomizationContext';
import configurationCheckoutCustomizationSchema from '@helpers/validators/pages/dashboard/checkoutCustomizations/configurationCheckoutCustomizationSchema';

import Tooltip from '@components/pages/Dashboard/CheckoutCustomization/Tooltip';
import * as S from './styles';

const Configuration: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { accountId, checkoutId } = useParams();
  const {
    setCheckoutCustomizationData,
    checkoutCustomizationData,
    createNewCheckout,
    isCreatingCheckout,
    selectedThemeCheckout,
  } = useNewCheckout();
  const {
    header_customization,
    footer_customization,
    appearance_customization,
    advanced_customization,
  } = checkoutCustomizationData;

  const {
    register,
    reset,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    watch,
  } = useForm<ICreateCheckoutCustomizationData>({
    resolver: yupResolver(configurationCheckoutCustomizationSchema),
  });

  const onSubmit = async (formData: FieldValues): Promise<void> => {
    const data: ICreateCheckoutCustomizationData = {
      name: formData.name,
      allow_credit_card: formData.allow_credit_card,
      allow_pix: formData.allow_pix,
      allow_boleto: formData.allow_boleto,
      credit_card_discount_amount: formData.credit_card_discount_amount,
      pix_discount_amount: formData.pix_discount_amount,
      boleto_discount_amount: formData.boleto_discount_amount,
      credit_card_discount_type: 'PERCENTAGE',
      pix_discount_type: 'PERCENTAGE',
      boleto_discount_type: 'PERCENTAGE',
      enable_itens_quantity_change: formData.enable_itens_quantity_change,
      show_coupon_input: formData.show_coupon_input,
      show_time_shipping_estimate: formData.show_time_shipping_estimate,
      header_customization: { ...header_customization },
      footer_customization: { ...footer_customization },
      appearance_customization: { ...appearance_customization },
      advanced_customization: { ...advanced_customization },
      checkout_theme: { theme: selectedThemeCheckout },
    };

    await createNewCheckout({ payload: data });
  };

  const onCancelButtonClick = (): void => {
    reset();
    navigate(`/${accountId}/${checkoutId}/dashboard/checkout/customizations`);
  };

  React.useEffect(() => {
    setValue('enable_itens_quantity_change', false);
  }, [setValue]);

  React.useEffect(() => {
    return () => {
      const fieldValues = watch();
      setCheckoutCustomizationData(prevData => {
        return {
          ...prevData,
          name: fieldValues.name,
          allow_credit_card: fieldValues.allow_credit_card,
          allow_pix: fieldValues.allow_pix,
          allow_boleto: fieldValues.allow_boleto,
          credit_card_discount_amount: fieldValues.credit_card_discount_amount,
          pix_discount_amount: fieldValues.pix_discount_amount,
          boleto_discount_amount: fieldValues.boleto_discount_amount,
          credit_card_discount_type: 'PERCENTAGE',
          pix_discount_type: 'PERCENTAGE',
          boleto_discount_type: 'PERCENTAGE',
          inactivated_at: '',
          enable_itens_quantity_change: fieldValues.enable_itens_quantity_change,
          show_coupon_input: fieldValues.show_coupon_input,
          show_time_shipping_estimate: fieldValues.show_time_shipping_estimate,
        };
      });
    };
  }, [setCheckoutCustomizationData, watch]);

  return (
    <S.Form onSubmit={handleSubmit(onSubmit)}>
      <S.Wrapper>
        <S.NameSection>
          <S.Title size={EHeadingSize.H4} fontWeight={EHeadingWeight.BOLD}>
            Nome
          </S.Title>
          <S.InputGroup>
            <S.Label>Digite um nome para identificar a customização</S.Label>
            <S.Input
              defaultValue={checkoutCustomizationData?.name}
              isError={!!errors?.name}
              {...register('name')}
              placeholder="Digite um nome"
              type="text"
            />
            {errors?.name && <S.MessageError>{errors?.name?.message}</S.MessageError>}
            {/* <Tooltip
              iconColor={theme.colors.neutral.dark}
              iconWeight="regular"
              url="https://images.freeimages.com/images/small-previews/aed/three-bees-on-sunflower-1337029.jpg"
            > */}
            <S.TextInfo>Apenas você pode visualizar essa informação</S.TextInfo>
            {/* </Tooltip> */}
          </S.InputGroup>
        </S.NameSection>
        <S.Divider />
        <S.MethodPaymentSection>
          <S.Title size={EHeadingSize.H4} fontWeight={EHeadingWeight.BOLD}>
            Métodos de pagamento
          </S.Title>
          <S.CheckboxAndTooltipWrapper>
            {/* <Tooltip url="https://images.freeimages.com/images/small-previews/aed/three-bees-on-sunflower-1337029.jpg"> */}
            <S.Text>Marque os métodos que deseja ativar</S.Text>
            {/* </Tooltip> */}
            <S.CheckboxWrapper>
              <S.CheckboxGroup>
                <Controller
                  control={control}
                  defaultValue={checkoutCustomizationData?.allow_credit_card}
                  name="allow_credit_card"
                  render={({ field }) => (
                    <S.Checkbox
                      id="allow_credit_card"
                      checked={checkoutCustomizationData?.allow_credit_card}
                      value={field?.value?.toString()}
                      onChange={() => field?.onChange(!field.value)}
                    />
                  )}
                />
                <S.CheckboxLabel htmlFor="allow_credit_card">Cartão de crédito</S.CheckboxLabel>
              </S.CheckboxGroup>
              <S.CheckboxGroup>
                <Controller
                  control={control}
                  name="allow_pix"
                  defaultValue={checkoutCustomizationData?.allow_pix}
                  render={({ field }) => (
                    <S.Checkbox
                      id="allow_pix"
                      checked={checkoutCustomizationData?.allow_pix}
                      value={field?.value?.toString()}
                      onChange={() => field?.onChange(!field.value)}
                    />
                  )}
                />
                <S.CheckboxLabel htmlFor="allow_pix">Pix</S.CheckboxLabel>
              </S.CheckboxGroup>
              <S.CheckboxGroup>
                <Controller
                  control={control}
                  name="allow_boleto"
                  defaultValue={checkoutCustomizationData?.allow_boleto}
                  render={({ field }) => (
                    <S.Checkbox
                      id="allow_boleto"
                      checked={checkoutCustomizationData?.allow_boleto}
                      value={field?.value?.toString()}
                      onChange={() => field?.onChange(!field.value)}
                    />
                  )}
                />
                <S.CheckboxLabel htmlFor="allow_boleto">Boleto</S.CheckboxLabel>
              </S.CheckboxGroup>
            </S.CheckboxWrapper>
          </S.CheckboxAndTooltipWrapper>
        </S.MethodPaymentSection>
        <S.Divider />
        <S.DiscountSection>
          <S.Title size={EHeadingSize.H4} fontWeight={EHeadingWeight.BOLD}>
            Descontos
          </S.Title>
          <S.InputWrapper>
            <S.ValueInputWrapperCouple>
              <S.LabelAndIcon>
                <CreditCard size={20} />
                <S.Label>Cartão de crédito</S.Label>
              </S.LabelAndIcon>
              <S.ValueWrapperCouple>
                <S.CurrencyLabel>%</S.CurrencyLabel>
                <S.Input
                  defaultValue={checkoutCustomizationData?.credit_card_discount_amount}
                  {...register('credit_card_discount_amount')}
                  placeholder="Digite um valor"
                  type="text"
                />
              </S.ValueWrapperCouple>
            </S.ValueInputWrapperCouple>
            <S.ValueInputWrapperCouple>
              <S.LabelAndIcon>
                <PIX />
                <S.Label>Pix</S.Label>
              </S.LabelAndIcon>
              <S.ValueWrapperCouple>
                <S.CurrencyLabel>%</S.CurrencyLabel>
                <S.Input
                  defaultValue={checkoutCustomizationData?.pix_discount_amount}
                  {...register('pix_discount_amount')}
                  placeholder="Digite um valor"
                  type="text"
                />
              </S.ValueWrapperCouple>
            </S.ValueInputWrapperCouple>

            <S.ValueInputWrapperCouple>
              <S.LabelAndIcon>
                <BankSlip />
                <S.Label>Boleto</S.Label>
              </S.LabelAndIcon>
              <S.ValueWrapperCouple>
                <S.CurrencyLabel>%</S.CurrencyLabel>
                <S.Input
                  defaultValue={checkoutCustomizationData?.boleto_discount_amount}
                  {...register('boleto_discount_amount')}
                  placeholder="Digite um valor"
                  type="text"
                />
              </S.ValueWrapperCouple>
            </S.ValueInputWrapperCouple>
          </S.InputWrapper>
        </S.DiscountSection>
        <S.Divider />
        <S.FinalOptionsWrapper>
          <S.CheckboxGroup>
            <Controller
              control={control}
              name="show_coupon_input"
              defaultValue={checkoutCustomizationData?.show_coupon_input}
              render={({ field }) => (
                <S.Checkbox
                  id="show_coupon_input"
                  checked={checkoutCustomizationData?.show_coupon_input}
                  value={field?.value?.toString()}
                  onChange={() => field?.onChange(!field.value)}
                />
              )}
            />
            <S.CheckboxLabel htmlFor="show_coupon_input">
              Exibir campo de cupom de desconto
            </S.CheckboxLabel>
          </S.CheckboxGroup>
          {/* <S.CheckboxGroup>
            <Controller
              control={control}
              name="enable_itens_quantity_change"
              defaultValue={checkoutCustomizationData?.enable_itens_quantity_change}
              render={({ field }) => (
                <S.Checkbox
                  id="enable_itens_quantity_change"
                  checked={checkoutCustomizationData?.enable_itens_quantity_change}
                  value={field?.value?.toString()}
                  onChange={() => field?.onChange(!field.value)}
                />
              )}
            />
            <S.CheckboxLabel htmlFor="enable_itens_quantity_change">
              Permitir mudança de quantidade dos itens
            </S.CheckboxLabel>
          </S.CheckboxGroup> */}
          <S.CheckboxGroup>
            <Controller
              control={control}
              name="show_time_shipping_estimate"
              defaultValue={checkoutCustomizationData?.show_time_shipping_estimate}
              render={({ field }) => (
                <S.Checkbox
                  id="show_time_shipping_estimate"
                  checked={checkoutCustomizationData?.show_time_shipping_estimate}
                  value={field?.value?.toString()}
                  onChange={() => field?.onChange(!field.value)}
                />
              )}
            />
            <S.CheckboxLabel htmlFor="show_time_shipping_estimate">
              Mostrar o tempo de entrega do frete
            </S.CheckboxLabel>
          </S.CheckboxGroup>
        </S.FinalOptionsWrapper>
      </S.Wrapper>
      <S.ButtonsWrapper>
        <S.CancelButton onClick={onCancelButtonClick} variant={EButtonVariant.SECONDARY}>
          Cancelar
        </S.CancelButton>
        <S.SubmitButton type="submit" isLoading={isCreatingCheckout}>
          Salvar
        </S.SubmitButton>
      </S.ButtonsWrapper>
    </S.Form>
  );
};

export default Configuration;
