import React, { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FileError } from 'react-dropzone';

import { INewCheckoutCustomizationProvider } from '@domain/interfaces/store/common/checkoutCustomization/INewCheckoutCustomizationProvider';
import {
  EHeaderCustomizationTimerUnitOfTime,
  ICreateCheckoutCustomizationData,
} from '@domain/interfaces/hooks/services/checkoutCustomization/ICheckoutCustomizationService';

import { useToast } from '@store/context/common/ToastContext';
import { useErrorHandler } from '@store/context/common/ErrorHandlerContext';
import { useCheckoutCustomizationService } from '@hooks/services/checkoutCustomization/useCheckoutCustomizationService';
import { useAnalytics } from '@store/context/common/AnalyticsContext';
import { EThemeCheckout } from '@domain/enums/common/checkoutCustomization/EThemeCheckout';

export interface IUploadAbleFile {
  file: File;
  errors: FileError[];
  url?: string;
  preview?: string;
}

interface IUploadProps {
  mappedAcc: IUploadAbleFile[];
  type: string;
}

interface IResponseUpload {
  url: string;
  path: string;
}

const NewCheckoutCustomizationContext =
  React.createContext<INewCheckoutCustomizationProvider | null>(null);

export const NewCheckoutCustomizationProvider: React.FC = ({ children }) => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const { accountId, checkoutId } = useParams();
  const { handleError } = useErrorHandler();
  const { analytics } = useAnalytics();
  const {
    createCheckoutCustomization,
    uploadCheckoutCustomization,
    deleteUploadCheckoutCustomization,
  } = useCheckoutCustomizationService();

  const [time, setTime] = React.useState<number>(0);
  const [logoImage, setLogoImage] = React.useState<IUploadAbleFile[]>([]);
  const [faviconImage, setFaviconImage] = React.useState<IUploadAbleFile[]>([]);
  const [responseUploadLogo, setResponseUploadLogo] = React.useState<IResponseUpload>({
    url: '',
    path: '',
  } as IResponseUpload);
  const [responseUploadFavicon, setResponseUploadFavicon] = React.useState<IResponseUpload>({
    url: '',
    path: '',
  } as IResponseUpload);
  const [isCreatingCheckout, setIsCreatingCheckout] = React.useState<boolean>(false);
  const [selectedThemeCheckout, setSelectedThemeCheckout] = React.useState<EThemeCheckout>(
    EThemeCheckout.ZOUTI,
  );

  const [checkoutCustomizationData, setCheckoutCustomizationData] =
    React.useState<ICreateCheckoutCustomizationData>({
      name: '',
      allow_credit_card: true,
      allow_pix: true,
      allow_boleto: true,
      credit_card_discount_amount: 0,
      pix_discount_amount: 0,
      boleto_discount_amount: 0,
      show_coupon_input: true,
      enable_itens_quantity_change: true,
      show_time_shipping_estimate: true,
      header_customization: {
        background_hex_color: '#FFFFFF',
        name_hex_color: '#1773B0',
        logo_url: responseUploadLogo.url,
        logo_path: responseUploadLogo.path,
        secure_hex_color: '#29B765',
        timer_background_hex_color: '#1773B0',
        timer_text_hex_color: '#FFFFFF',
        timer_text: '',
        show_timer: false,
        timer_unit_of_time: EHeaderCustomizationTimerUnitOfTime.MINUTES,
        timer_amount: 0,
      },
      footer_customization: {
        footer_hex_color: '#FFFFFF',
        text_hex_color: '#A1A1A1',
        show_security_seal: true,
        show_account_name: true,
        show_account_address: true,
        show_account_cnpj_or_cpf: true,
        show_account_email: true,
        metadata: {
          link_hex_color: '#FF5F00',
          policies: {
            devolution: {
              enabled: true,
              link: '',
            },
            privacy: {
              enabled: true,
              link: '',
            },
            terms: {
              enabled: true,
              link: '',
            },
          },
        },
      },
      appearance_customization: {
        primary_hex_color: '#1773B0',
        title_hex_color: '#282828',
        label_hex_color: '#282828',
        chip_hex_color: '#1773B0',
        button_hex_color: '#1773B0',
        button_text_hex_color: '#FFFFFF',
        title_font_family: 'Inter',
        text_font_family: 'Inter',
        is_title_uppercase: false,
        is_button_uppercase: false,
        metadata: {
          button_font_family: 'SF Pro Display',
          is_text_uppercase: false,
        },
      },
      advanced_customization: {
        custom_css: '',
        favicon_url: responseUploadFavicon.url,
        favicon_path: responseUploadFavicon.path,
      },
      checkout_theme: {
        theme: selectedThemeCheckout,
      },
    } as ICreateCheckoutCustomizationData);

  const [selectedTab, setSelectedTab] = React.useState<number>(0);

  const onChangeTab = React.useCallback(index => setSelectedTab(index), []);

  const bytesToMegaBytes = (bytes: number): string => (bytes / 1024).toFixed(2);
  const fileSizeKB = bytesToMegaBytes(logoImage[0]?.file?.size);

  const increaseTime = React.useCallback(() => {
    setTime(time + 1);
  }, [time]);

  const decreaseTime = React.useCallback(() => {
    if (time === 0) return;

    setTime(time - 1);
  }, [time]);

  const createNewCheckout = React.useCallback(
    async payload => {
      if (!payload?.payload?.name) {
        toast.error('O campo nome da customização do checkout é obrigatório!');
        return;
      }
      try {
        setIsCreatingCheckout(true);

        await createCheckoutCustomization({ ...payload, accountId, checkoutId });

        analytics.track('Checkout Customization Created');

        toast.success('Checkout criado com sucesso');

        navigate(`/${accountId}/${checkoutId}/dashboard/checkout/customizations`);
      } catch (error: any) {
        setIsCreatingCheckout(false);
        handleError(error);
      } finally {
        setIsCreatingCheckout(false);
      }
    },
    [handleError, toast, navigate, analytics, createCheckoutCustomization, accountId, checkoutId],
  );

  const handleImageChange = useCallback(
    async ({ mappedAcc, type }: IUploadProps): Promise<void> => {
      const formData = new FormData();
      const { file } = mappedAcc[0];

      if (file) {
        formData.append('type', type);
        formData.append('file', file, file.name);

        try {
          const response = await uploadCheckoutCustomization({
            payload: formData,
            accountId,
            checkoutId,
          });
          if (type === 'logo') {
            setResponseUploadLogo(response.data.object);
            setLogoImage(mappedAcc);
          } else {
            setResponseUploadFavicon(response.data.object);
            setFaviconImage(mappedAcc);
          }
        } catch (error) {
          handleError(error);
        }
      }
    },
    [uploadCheckoutCustomization, handleError, accountId, checkoutId],
  );

  const deleteUploadCheckout = React.useCallback(
    async (path: string, type: string) => {
      try {
        await deleteUploadCheckoutCustomization({ path, accountId, checkoutId });
        if (type === 'logo') {
          setLogoImage([]);
        } else {
          setFaviconImage([]);
        }
        toast.success('Imagem deletada com sucesso!');
      } catch (error) {
        handleError(error);
      }
    },
    [deleteUploadCheckoutCustomization, handleError, toast, accountId, checkoutId],
  );

  return (
    <NewCheckoutCustomizationContext.Provider
      value={{
        createNewCheckout,
        deleteUploadCheckout,
        checkoutCustomizationData,
        setCheckoutCustomizationData,
        handleImageChange,
        decreaseTime,
        increaseTime,
        time,
        setTime,
        logoImage,
        faviconImage,
        setLogoImage,
        setFaviconImage,
        fileSizeKB,
        isCreatingCheckout,
        responseUploadLogo,
        responseUploadFavicon,
        selectedTab,
        onChangeTab,
        selectedThemeCheckout,
        setSelectedThemeCheckout,
      }}
    >
      {children}
    </NewCheckoutCustomizationContext.Provider>
  );
};

export const useNewCheckout = (): INewCheckoutCustomizationProvider => {
  const context = React.useContext(NewCheckoutCustomizationContext);

  if (!context) {
    throw new Error('useNewCheckout must be used within provider');
  }

  return context;
};
