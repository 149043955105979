import React from 'react';
import { banks } from '@constants/common/banks';
import { cnpjFormatter } from '@utils/common/cnpj';
import { EBankAccountType } from '@domain/interfaces/common/bankAccount/IBankAccount';
import * as S from './styles';

interface IRecipientInfoProps {
  name: string;
  document: string;
  bank: string;
  agency: string;
  account: string;
  accountType: string;
  payoutType: string;
}

export const RecipientInfo: React.FC<IRecipientInfoProps> = ({
  name,
  document,
  bank,
  agency,
  account,
  payoutType,
  accountType,
}) => {
  const bankName = banks?.find(item => item.code?.toString() === bank)?.name;
  const cnpjFormatted = cnpjFormatter(document);
  const account_type_name =
    accountType === EBankAccountType?.CHECKING ? 'Conta corrente' : 'Conta poupança';

  return (
    <>
      {payoutType === 'BANK_ACCOUNT' ? (
        <S.RecipientContainer>
          <S.RecipientHeader>
            <S.ContainerIcon>
              <S.BankIcon />
            </S.ContainerIcon>
            <S.RecipientDetails>
              <S.RecipientName>{name}</S.RecipientName>
              <S.RecipientDocument>{cnpjFormatted}</S.RecipientDocument>
            </S.RecipientDetails>
          </S.RecipientHeader>
          <S.BankInfo>
            <S.BankInfoRow>
              <S.BankInfoItem>
                <S.Label>Banco</S.Label>
                <S.Value>{bankName}</S.Value>
              </S.BankInfoItem>
              <S.BankInfoItem>
                <S.Label>Agência</S.Label>
                <S.Value>{agency}</S.Value>
              </S.BankInfoItem>
            </S.BankInfoRow>
            <S.BankInfoRow>
              <S.BankInfoItem>
                <S.Label>Conta</S.Label>
                <S.Value>{account}</S.Value>
              </S.BankInfoItem>
              <S.BankInfoItem>
                <S.Label>Tipo da conta</S.Label>
                <S.Value>{account_type_name}</S.Value>
              </S.BankInfoItem>
            </S.BankInfoRow>
          </S.BankInfo>
        </S.RecipientContainer>
      ) : (
        <S.RecipientContainer>
          <S.RecipientHeader>
            <S.ContainerIcon>
              <S.PIXIcon />
            </S.ContainerIcon>
            <S.RecipientDetails>
              <S.RecipientName>Chave Pix</S.RecipientName>
              <S.RecipientDocument>{cnpjFormatted}</S.RecipientDocument>
            </S.RecipientDetails>
          </S.RecipientHeader>
        </S.RecipientContainer>
      )}
    </>
  );
};
