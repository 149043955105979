import React from 'react';
import { Check } from 'phosphor-react';

import * as S from './styles';

interface IStepProps {
  step: number;
  isCurrentStep: boolean;
  isStepFinished: boolean;
}

const Step: React.FC<IStepProps> = ({ step, isCurrentStep, isStepFinished }) => {
  return (
    <S.Wrapper isCurrentStep={isCurrentStep} isStepFinished={isStepFinished}>
      {isStepFinished ? <Check size={18} color="#fff" weight="bold" /> : <>{step}</>}
    </S.Wrapper>
  );
};

export default Step;
