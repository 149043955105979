import React from 'react';
import * as DropdownPrimitive from '@radix-ui/react-dropdown-menu';

import Content from './Content';
import Item from './Item';
import Label from './Label';
import Separator from './Separator';
import Trigger from './Trigger';
import Arrow from './Arrow';

import * as S from './styles';

interface IDropdown {
  Content: typeof Content;
  Item: typeof Item;
  Label: typeof Label;
  Separator: typeof Separator;
  Trigger: typeof Trigger;
  Arrow: typeof Arrow;
}

const Dropdown: IDropdown & React.FC<DropdownPrimitive.DropdownMenuProps> = ({
  children,
  ...rest
}) => {
  return <S.Wrapper {...rest}>{children}</S.Wrapper>;
};

Dropdown.Content = Content;
Dropdown.Item = Item;
Dropdown.Label = Label;
Dropdown.Separator = Separator;
Dropdown.Trigger = Trigger;
Dropdown.Arrow = Arrow;

export default Dropdown;
