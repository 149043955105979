import React from 'react';
import { SignOut } from '@phosphor-icons/react';
import { useTheme } from 'styled-components/macro';

import { EButtonVariant } from '@domain/enums/components/common/EButton';

import { useConfig } from '@store/context/common/ConfigContext';

import Form from '../Form';

import * as S from './styles';

const Wrapper: React.FC = () => {
  const theme = useTheme();
  const { handleLogout } = useConfig();

  return (
    <S.Wrapper>
      <S.ExitButton variant={EButtonVariant.SECONDARY} onClick={handleLogout}>
        <SignOut size={20} color={theme.colors.neutral.darker} /> Sair
      </S.ExitButton>

      <Form />
    </S.Wrapper>
  );
};

export default Wrapper;
