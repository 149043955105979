import React from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { useUpdatePromocode } from '@store/common/promocode/UpdatePromocodeContext';

import EmptyData from '@components/common/utils/EmptyData';

import SkeletonLoading from './SkeletonLoading';

import Header from './Header';
import Body from './Body';

import * as S from './styles';

const Coupon: React.FC = () => {
  const { isLoadingPromocode, isPromocodeError } = useUpdatePromocode();
  const isEmpty = false;

  const navigate = useNavigate();
  const { accountId, checkoutId } = useParams();

  const onEmptyDataButtonClick = (): void => {
    navigate(`/${accountId}/${checkoutId}/dashboard/marketing/coupon/new`);
  };

  if (isLoadingPromocode) {
    return <SkeletonLoading />;
  }

  if (isPromocodeError && !isLoadingPromocode) {
    return <div>Error...</div>;
  }

  if (isEmpty) {
    return (
      <EmptyData
        title="Nenhum cupom cadastrado"
        description="Adicione os cupons que deseja distribuir para seus clientes"
        buttonText="Novo promocode"
        onClick={onEmptyDataButtonClick}
      />
    );
  }

  return (
    <S.Wrapper>
      <Header />
      <Body />
    </S.Wrapper>
  );
};

export default Coupon;
